import React, { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import LoginModal from "../../../components/Login_modal/loginModal";
import Footer from "../../../components/Footer";
import Popup2 from "../../non_auth/new_popup/popup2";
import CustomNav from "../../../components/header/Navbar";
import "./common.css";

Modal.setAppElement("#root");

const allPoliceData = [
  {
    id: 1,
    data1: "UPSC CSE (for IPS)",
    data2: "February 02-22 2022",
    data3: "	June 05, 2022",
  },
  {
    id: 2,
    data1: "Rajasthan Police",
    data2: "November 10-December 03, 2021",
    data3: "	May 13,14,15, 16 and July 02, 2022",
  },
  {
    id: 3,
    data1: "UPSC CAPF Exam",
    data2: "April 20-May 10, 2022",
    data3: "August 07, 2022",
  },
  {
    id: 4,
    data1: "Delhi Police Head Constable",
    data2: "May 17-June 16, 2022",
    data3: "October 27-28, 2022",
  },
  {
    id: 5,
    data1: "SSC CPO Exam",
    data2: "August 10-30, 2022",
    data3: "	November 2022",
  },
  {
    id: 6,
    data1: "Delhi Police MTS Exam",
    data2: " October-November 2022",
    data3: "February 2023",
  },
  {
    id: 7,
    data1: "SSC GD Exam",
    data2: "December 10, 2022-To be Announced",
    data3: "	March-April 2023",
  },
  {
    id: 8,
    data1: "Delhi Police Exam",
    data2: "To be Announced",
    data3: "To be Announced",
  },
  {
    id: 9,
    data1: "Bihar Police Exam",
    data2: "To be Announced",
    data3: "To be Announced",
  },
  {
    id: 10,
    data1: "UP Police Exam",
    data2: "To be Announced",
    data3: "To be Announced",
  },
  {
    id: 11,
    data1: "BSF Exam",
    data2: "To be Announced",
    data3: "To be Announced",
  },
  {
    id: 12,
    data1: " CRPF GD Constable Exam",
    data2: " November 24 to December 31, 2023",
    data3: "20th to 29th February 2024 and 1st to 12th March 2024",
  },
  {
    id: 13,
    data1: " CISF Head Constable Ministerial  Exam",
    data2: "To be Announced",
    data3: "To be Announced",
  },
  {
    id: 14,
    data1: " Indian Army Agniveer  Exam",
    data2: "To be Announced",
    data3: "To be Announced",
  },
];
const cdsData = [
  {
    id: 1,
    data1: "Official Website",
    data2: "www.upsc.gov.in",
  },
  {
    id: 2,
    data1: "Name of the Exam	",
    data2: "Combined Defence Services Examination",
  },
  {
    id: 3,
    data1: "Conducting Body	",
    data2: "Union Public Service Commission (UPSC)",
  },
  {
    id: 4,
    data1: "Exam Level",
    data2: "National",
  },
  {
    id: 5,
    data1: "Exam Frequency",
    data2: "Twice a year",
  },
  {
    id: 6,
    data1: "CDS 2 Vacancy	",
    data2: "457",
  },
  {
    id: 7,
    data1: "Mode of Application	",
    data2: "Online",
  },
  {
    id: 8,
    data1: "Registration Date	",
    data2: "20th December 2023 to 9th January 2024",
  },
  {
    id: 9,
    data1: "CDS 1 2024 Exam Date	",
    data2: "21st April 2024",
  },
  {
    id: 10,
    data1: "Selection Process ",
    data2: "Written Test-Interview – Medical Examination	",
  },
  {
    id: 11,
    data1: "Number of Sections	",
    data2:
      "Indian Military Academy (IMA), Indian Naval Academy (INA) and Air Force Academy (AFA) – ThreeOfficers’ Training Academy (OTA) – Two ",
  },
  {
    id: 12,
    data1: "Language of Written Exam	",
    data2: "Bilingual",
  },
  {
    id: 13,
    data1: "Mode of Examination		",
    data2: "Offline",
  },
  {
    id: 14,
    data1: "Type of Question	",
    data2: "Multiple – Choice Questions",
  },
  {
    id: 15,
    data1: "Eligibility	",
    data2: "Unmarried Males & Females",
  },
  {
    id: 16,
    data1: "CDS Exam Duration	",
    data2: "2 Hours (each paper)",
  },
  {
    id: 17,
    data1: "Total marks of the test 	",
    data2: "IMA, INA and AFA – 300OTA - 200",
  },
  {
    id: 18,
    data1: "Making Scheme	",
    data2: "1/3 for each incorrect answer",
  },
  {
    id: 19,
    data1: "Job Location	",
    data2: "All Over India",
  },
];

function PoliceServices() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section style={{ width: "100%", marginBottom: "3%" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              width: "80%",
              marginTop: "3%",
              height: "auto",
            }}
          >
            <h1 style={{ fontSize: 24, color: "#dc3545", fontWeight: 600 }}>
              Police Services Exam
            </h1>

            <p
              style={{
                fontSize: 16,
                marginTop: "1%",
                fontWeight: 500,
                lineHeight: "1.6",
              }}
            >
              Various governmental bodies administer police exams to recruit
              candidates for different positions within the Police Departments.
              Eligibility criteria vary based on the specific posts, requiring
              aspirants to carefully review the individual requirements. While
              most exams are held annually, certain vacancies might be announced
              separately to fulfill specific needs. Aspirants can find the most
              recent updates on police recruitment exams here. Below are some of
              the prominent police exams.
            </p>

            <div>
              <div
                style={{
                  marginBottom: "2%",
                  marginTop: "2%",
                  overflowX: "auto",
                }}
              >
                <table style={{ width: "100%", margin: "0 auto" }}>
                  <tr>
                    <th style={{ textAlign: "center" }} className="heade">
                      Exam Name
                    </th>
                    <th style={{ textAlign: "center" }} className="heade">
                      Application Dates
                    </th>
                    <th style={{ textAlign: "center" }} className="heade">
                      Exam Dates
                    </th>
                  </tr>
                  {allPoliceData.map((data) => {
                    return (
                      <tr>
                        <td style={{ textAlign: "center", fontWeight: "600" }}>
                          {data.data1}
                        </td>
                        <td style={{ textAlign: "center" }}>{data.data2}</td>
                        <td style={{ textAlign: "center" }}>{data.data3}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: "2%",
                justifyContent: "end",
              }}
            >
              <a
                target="_blank"
                href="https://therightguru-website.s3.ap-south-1.amazonaws.com/Police+Exams+2023-+Check+Police+Exams+Calendar+/Police+Exams+2023-+Check+Police+Exams+Calendar+Here+(1).pdf"
              >
                <div className="book-btn">Know more...</div>
              </a>
            </div>

            <h2 style={{ fontSize: 18, fontWeight: 600, marginTop: "2%" }}>
              Achieve Your Goals with THE RIGHT GURU Tuition Centre
            </h2>
            <p style={{ fontSize: 16, marginTop: "1%", lineHeight: "1.6" }}>
              Join us on a journey of growth, learning, and achievement. Whether
              you're aiming for top grades, entrance exams, or a bright future,
              we are here to provide the guidance and support you need to
              succeed.
            </p>
            <h2 style={{ fontSize: 16, marginTop: "1%" }}>
              For any query, Email us at:{" "}
              <span>
                <a href="mailto:contact@therightguru.com">
                  contact@therightguru.com
                </a>
              </span>
            </h2>
            <p style={{ fontSize: 16, marginTop: "1%", lineHeight: "1.6" }}>
              For a more personalized and detailed understanding, we encourage
              you to visit our centers and consult our dedicated counselors.
            </p>
          </div>
        </div>
      </section>

      <section>
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default PoliceServices;
