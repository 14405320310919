import axios from "axios";
import { toAPIURL } from "../../../utils";
import { GET_TEACHER, DELETE_TEACHER } from "./types";

// GET TEACHER
export const getTeacher = (user_data, history) => dispatch => {
    axios
        .get(toAPIURL("api/teacher/" + user_data.email))
        .then(res => {
            dispatch({
                type: GET_TEACHER,
                payload: res.data
            });
            history.replace("/teacher-dashboard")
        })
        .catch(err => console.log(err));
}

// DELETE TEACHER
export const deleteTeacher = teacherId => ({
    type: DELETE_TEACHER,
    payload: teacherId
})