import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import moment from 'moment';
import { FaRegQuestionCircle, FaCalendarAlt, FaFileAlt, FaChartPie, FaStickyNote, FaPowerOff } from "react-icons/fa";
import { MdAssignment, MdDashboard, MdNotificationsNone, MdSchool } from "react-icons/md";
import { BiSupport, BiHistory } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { AiTwotoneBank } from "react-icons/ai";
import line_5_1_2x from "./img/line-5-1@2x.svg";
import line_6_1_2x from "./img/line-6-1@2x.svg";
import a58341_2x from "./img/5834-1@2x.png";
import ellipse_2_2x from "./img/ellipse-2@2x.svg";
import vector_3_2x from "./img/vector-3@2x.svg";
import vector_2_2x from "./img/vector-2@2x.png";
import rectangle_7_1x from "./img/rectangle-7@1x.png";
import line_5_2x from "./img/line-5@2x.svg";
import line_6_2x from "./img/line-6@2x.svg";
import a58341_1_2x from "./img/5834-1-1@2x.png";
import Popup from '../../teacher/popup/popup';
import { rmIssueList } from "../../../crud/rm.crud"
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from '../../../utils';
import { deleteRm } from '../../../redux/actions/rm';
import { WebSocketContext } from '../../../websocket2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import pic_2 from "../../student/dashboard/img/pic_2.png";
import pic_3 from "../../student/dashboard/img/pic_3.png";
import SideDrawerRm from '../../../UI/sideDrawer/sideDrawerRm';

function Relationalmanagerdashboard(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const user = useSelector((state) => state.rmDetails.rm);
  const reduxIssues = useSelector(state => state.rmDetails.issues);

  const [issues, setIssues] = useState([])
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [newIssuesLength, setNewIssuesLength] = useState(0);
  const [handleFilter, setHandleFilter] = useState('');

  useEffect(() => {
    if (reduxIssues && reduxIssues.length > newIssuesLength) {
      toast.info(`You have a new issue by ${reduxIssues[reduxIssues.length - 1].created_by}. Please open the issue to connect.`, {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setNewIssuesLength(prevState => prevState + 1);
    }
  }, [reduxIssues])

  const sideDrawerToggler = () => {
    setDisplaySideDrawer(previousState => !previousState);
  };

  const ws = useContext(WebSocketContext);

  const joinRoom = userId => {
    if (ws.socket.readyState === WebSocket.OPEN) {
      ws.sendMessage({
        'type': 'JOIN_CHANNEL',
        'group': userId,
        'user': user.rm_id,
        'message': 'RM joined rm_student specific group.'
      });
    } else {
      ws.socket.onopen = () => {
        ws.sendMessage({
          'type': 'JOIN_CHANNEL',
          'group': userId,
          'user': user.rm_id,
          'message': 'RM joined rm_student specific group.'
        });
      }
    }
  }

  useEffect(() => {
    rmIssueList(user && user.rm_id)
      .then(res => {
        if (res.hasOwnProperty("message")) {
          setIssues(false)
        } else {
          console.log(res);
          setIssues(res)
        };
      })
      .catch(err => console.log(err))
  }, [])

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.rmDetails.rm);
  const handleLogout = () => {
    console.log("1111");
    dispatch(deleteRm(reduxUser.rm_id))
    removeToken();
  }


  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="relational-manager-dashboard"
      />
      <div className="container-center-horizontal">
        <div className="relational-manager-dashboard screen">
          <div className="overlap-group1-C61RwL-10-1">
            <div className="rectangle-1-RH0WJ5-10">
              <Link to="/" onClick={() => handleLogout()}>
                <div className="logout-RH0WJ5">Logout</div>
              </Link>
              <div className="component-1-RH0WJ5">
                <img
                  alt="image"
                  src={pic_2}
                  style={{ height: "120px" }}
                />
              </div>
              <Link to="/rm-dashboard">
                <div className="dashboard-RH0WJ5" style={{ color: "white" }}>
                  Dashboard
                </div>
              </Link>
              <Link to="/rm-list">
                <div className="students-teachers-RH0WJ5">
                  Students/Teachers
                </div>
              </Link>
              {/* <Link to="/rm-payments"><div className="payments-RH0WJ5">Payments</div></Link> */}
              <Link to="/rm-ticket-list">
                <div
                  className="tickets-RH0WJ5"
                  style={{ color: "rgba(179,179,179,1.0)" }}
                >
                  Tickets
                </div>
              </Link>
              <Link to="/rm-statistics">
                <div className="analytics-RH0WJ5">Analytics</div>
              </Link>
              <div className="school-24px-1-RH0WJ5">
                <MdSchool size={24} color="rgba(179,179,179,1.0)" />
              </div>
              <div className="vector-RH0WJ5">
                <MdDashboard size={24} color="#dc3545" />
              </div>
              <Link to="/rm-issue-list">
                <div className="previous-issues-RH0WJ5">Previous Issues</div>
              </Link>
              <div className="historytoggleoff-24px-1-RH0WJ5">
                <div className="group-YrhztS">
                  <BiHistory size={24} color="rgba(179,179,179,1.0)" />
                </div>
              </div>
              <div className="vector-s3i1G8">
                <FaPowerOff size={24} color="rgba(179,179,179,1.0)" />
              </div>
              <div className="help-24px-1-RH0WJ5">
                <FaRegQuestionCircle size={24} color="rgba(179,179,179,1.0)" />
              </div>
              <div className="analytics-24px-3-RH0WJ5">
                <FaChartPie size={24} color="rgba(179,179,179,1.0)" />
              </div>
              {/* <div className="accountbalance-24px-1-RH0WJ5">
                <AiTwotoneBank size={24} color="rgba(179,179,179,1.0)" />
              </div> */}
            </div>
          </div>
          <div className="flex-col-C61RwL">
            <div className="flex-row-0xy0vn">
              <a href="rm-statistics.html">
                <div className="group-14-GyJUNH">
                  <div className="rectangle-30-Uqx0kx"></div>
                  {/* <div className="your-have-5-pen-mergency-issues-Uqx0kx">
                    <span className="span0-nfPVjr">You have </span><span className="span1-nfPVjr">{issues && issues.length}</span
                    ><span className="span2-nfPVjr"> pending issues.<br />You have </span><span className="span3-nfPVjr">{issues && issues.filter(issue => issue.category == "Emergency").length}</span
                    ><span className="span4-nfPVjr"> emergency issues.</span>
                  </div> */}
                  <div className="welcome-back-biba-Uqx0kx">
                    <span className="span0-70fs2O">Welcome back, </span>
                    <span
                      className="span1-70fs2O"
                      style={{ marginLeft: "49px" }}
                    >
                      {user && user.first_name}!
                    </span>
                  </div>
                  <img className="x5834-1-Uqx0kx" src={a58341_2x} />
                </div>
              </a>
              <div className="notifications-24px-2-1-GyJUNH">
                <div className="vector-40xw7g">
                  <MdNotificationsNone size={28} />
                </div>
                <img className="ellipse-2-RYyMFx" src={ellipse_2_2x} />
              </div>
              <div className="group-22-GyJUNH">
                <div className="biba-sobti-vgli2x">
                  {user && user.first_name} {user && user.last_name}
                </div>
                <div className="bibasobtigmailcom-vgli2x">
                  {user && user.emailID}
                </div>
              </div>
              <img className="vector-GyJUNH" src={vector_3_2x} />
            </div>
            <div className="list-of-current-issues-0xy0vn-001">
              <div style={{ width: "430px" }}>
                <span className="span0-mQtxrF">List of</span>
                <span className="span1-mQtxrF"> Current Issues</span>
              </div>
              <input
                type="text"
                placeholder="Filter List by Student ID"
                style={{ width: "210px" }}
                onChange={(e) => setHandleFilter(e.target.value)}
              />
            </div>
            <div className="overlap-group2-0xy0vn">
              <div className="dashboard-24px-1-52Wjwg">
                <img className="vector-cr1ESP" src={vector_2_2x} />
              </div>
              <div className="group-45-52Wjwg">
                <div className="rectangle-20-WxqicF-inm-0920">
                  <div className="roboto-medium-white-20px">S. No.</div>
                  <div className="roboto-medium-white-20px">Student</div>
                  <div className="roboto-medium-white-20px">Issue Status</div>
                  <div className=" roboto-medium-white-20px">Date</div>
                  <div className="roboto-medium-white-20px">Category</div>
                </div>
              </div>
            </div>
            <div class="overlap-group-0xy0vn">
              <img class="rectangle-7-y1HRxC" src={rectangle_7_1x} />
              <div class="dashboard-24px-1-y1HRxC">
                <img class="vector-8OGum8" src={vector_2_2x} />
              </div>
              <div class="rectangle-30-y1HRxC-12-02">
                <div className="akljs-awjon-da1">
                  {issues.length > 0 ? (
                    issues
                      .filter((val) => {
                        if ((val.status != "Resolved" && val.status != "Resolved by RM") && handleFilter == "") {
                          return val;
                        } else if (
                          (val.status != "Resolved" && val.status != "Resolved by RM") &&
                          val.object_id
                            .toLowerCase()
                            .includes(handleFilter.toLowerCase())
                        ) {
                          return val;
                        }
                      })
                      .map((issue, index) => {
                        return (
                          <Link
                            to={"/rm-issue-detail/" + issue.id}
                            onClick={() => joinRoom(issue.object_id)}
                            style={{ color: "#000" }}
                          >
                            <div
                              className="group-56-y1HRxC-02-33"
                              style={{ fontFamily: "'Montserrat', Helvetica" }}
                            >
                              <div>{index + 1}</div>
                              <div>{issue.object_id}</div>
                              <div>{issue.status}</div>
                              <div>
                                {moment(issue.date).format("DD-MM-YYYY")}
                              </div>
                              <div>{issue.category}</div>
                            </div>
                          </Link>
                        );
                      })
                  ) : (
                    <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                      No issues raised for you.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-center-horizontal">
        <div className="relational-manager-dashboard-mobile screen">
          <div className="flex-row-C61RwL">
            <div className="component-1-dXBp89">
              <img
                alt="image"
                src={pic_3}
                style={{ height: "115px", marginTop: "0%", marginLeft: "14%" }}
              />
            </div>

            <SideDrawerRm
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />

            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>
          {/* <div className="overlap-group-C61RwL">
            <a href="rm-statistics.html">
              <div className="group-14-4eduM0">
                <div className="rectangle-30-Yw5tgq"></div>
                <div className="your-have-5-pen-mergency-issues-Yw5tgq">
                  <span className="span0-8s1sGx">Your have </span><span className="span1-8s1sGx">5</span
                  ><span className="span2-8s1sGx"> pending issues.<br />You have </span><span className="span3-8s1sGx">0 </span
                  ><span className="span4-8s1sGx">emergency issues.</span>
                </div>
              </div>
            </a>
            <img className="x5834-1-4eduM0" src={a58341_1_2x} />
            <div className="welcome-back-biba-4eduM0 montserrat-medium-blue-zodiac-18px">
              <span className="span0-I9MZRq">Welcome back, </span>
              <span className="span1-I9MZRq" style={{ marginLeft: "38px" }}>
                {user && user.first_name}!
              </span>
            </div>
          </div> */}
          <div
            href="relational-manager-issue-detail.html"
            className="align-self-flex-end"
          >
            <div className="group-127-C61RwL" style={{margin: "90px auto"}}>
              <div className="list-of-current-issues-2zqKZg montserrat-medium-blue-zodiac-20px">
                List of Current Issues
              </div>
              <input
                type="text"
                placeholder="Filter List by Student ID"
                style={{ width: "200px", marginLeft: "10px", marginTop: "5px" }}
                onChange={(e) => setHandleFilter(e.target.value)}
              />
              <div className="overlap-group2-2zqKZg">
                <div className="dashboard-24px-1-Rbw72r">
                  <img className="vector-IrPvqm" src={vector_2_2x} />
                </div>
                <div className="group-45-Rbw72r">
                  <div className="rectangle-20-fx556c-020-20">
                    <div className="roboto-medium-white-14px-10-1">S.No.</div>
                    <div className="roboto-medium-white-14px-10-1">Student</div>
                    <div className="roboto-medium-white-14px-10-1">
                      Issue Status
                    </div>
                    <div className="roboto-medium-white-14px-10-1">Date</div>
                    <div className="roboto-medium-white-14px-10-1">
                      Category
                    </div>
                  </div>
                </div>
              </div>
              <div className="overlap-group1-2zqKZg">
                <div className="rectangle-30-759J8x-10-1">
                  <div className="akljs-awjon-da1">
                    {issues.length > 0 ? (
                      issues
                        .filter((val) => {
                          if ((val.status != "Resolved" && val.status != "Resolved by RM") && handleFilter == "") {
                          return val;
                        } else if (
                          (val.status != "Resolved" && val.status != "Resolved by RM") &&
                          val.object_id
                            .toLowerCase()
                            .includes(handleFilter.toLowerCase())
                        ) {
                          return val;
                        }
                        })
                        .map((issue, index) => {
                          return (
                            <Link
                              to={"/rm-issue-detail/" + issue.id}
                              style={{ color: "#000" }}
                            >
                              <div
                                className="group-56-759J8x0-1919"
                                style={{
                                  fontFamily: "'Montserrat', Helvetica",
                                  fontSize: "10px",
                                }}
                              >
                                <div>{index + 1}</div>
                                <div>{issue.object_id}</div>
                                <div>{issue.status}</div>
                                <div>
                                  {moment(issue.date_raised).format(
                                    "DD-MM-YYYY"
                                  )}
                                </div>
                                <div>{issue.category}</div>
                              </div>
                            </Link>
                          );
                        })
                    ) : (
                      <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                        No issues raised by you.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup />
      <ToastContainer />
    </>
  );
}

export default Relationalmanagerdashboard;