import React, { useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../../components/Footer";
import pic_5 from "../../student/dashboard/img/pic_5.png";

function TrgToppers() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <section
        style={{
          width: "100%",
          backgroundColor: "#dc3545",
          display: "flex",
          justifyContent: "center",
          paddingBottom: "1%",
        }}
      >
        <Link to="/">
          <img style={{ height: "150px" }} src={pic_5} alt="" />
        </Link>
      </section>

      <section id="footer">
        <Footer />
      </section>
    </div>
  );
}

export default TrgToppers;
