import React, { useEffect, useState, useLayoutEffect } from "react";
// import { Button } as BootButton from "react-bootstrap";
import "./list_style.css";
import Popup from "../../teacher/popup/popup";
import { Link } from "react-router-dom";
// import { Dropdown } from "semantic-ui-react";
import ReactTooltip from "react-tooltip";
import { Button } from "semantic-ui-react";
import S3FileUpload from "react-s3";
import { S3doubtsconfig } from "../../../s3/s3doubtsconfig.js";
import {
  FaRegQuestionCircle,
  FaCalendarAlt,
  FaFileAlt,
  FaChartPie,
  FaStickyNote,
  FaPowerOff,
} from "react-icons/fa";
import { MdAssignment, MdDashboard } from "react-icons/md";
// import { BiSupport } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteStudent } from "../../../redux/actions/student";
import DropdownOption from "../../higher_committee/dropdown/dropdown2";
import DropdownOption2 from "../../higher_committee/dropdown/dropdown_status";

import { doubtList, creadeDoubt } from "../../../crud/student.crud";
import pic_2 from "../dashboard/img/pic_2.png";
// images
// import line5_2x from "./img/line-5@2x.png";
// import line5_1_2x from "./img/line-5-1@2x.png";
// import line6_2x from "./img/line-6@2x.png";
// import line6_1_2x from "./img/line-6-1@2x.png";
import vector_2x from "./img/vector@2x.png";
import vector_1_2x from "./img/vector-1@2x.png";
import vector_3_2x from "./img/vector-3@2x.png";
import vector_5_2x from "./img/vector-5@2x.png";
import vector_7_2x from "./img/vector-7@2x.png";
// import vector_9_2x from "./img/vector-9@2x.png";
// import vector_11_2x from "./img/vector-11@2x.png";
// import vector_23_2x from "./img/vector-23@2x.png";
// import vector_25_2x from "./img/vector-25@2x.png";
import ellipse_4_1_2x from "./img/ellipse-4-1@2x.png";
// import ellipse_2_2x from "./img/ellipse-2@2x.png";
// import component_32_2x from "./img/component-32@2x.png";
// import component_5 from "./img/component-5@1x.png";
// import rectangle_7 from "./img/rectangle-7@1x.png";
// import rectangle_40 from "./img/rectangle-40@2x.png";
// import pic_2 from "../dashboard/img/pic_2";
import SideDrawerStudent from "../../../UI/sideDrawer/sideDrawerStudent";

function Studentdoubts(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const user = useSelector((state) => state.studentDetails.student);
  const [doubts, setDoubts] = useState([]);
  const [isCreated, setIsCreated] = useState(false);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [doubtText, setDoubtText] = useState("");
  const [doubtGrade, setDoubtGrade] = useState("");
  const [doubtSubject, setDoubtSubject] = useState("");
  // const [doubtStatus, setDoubtStatus] = useState("");
  const [doubtsUrl, setDoubtsUrl] = useState("");
  const [subject_id, setSubjectName] = useState("");
  const [grade_id, setGradeName] = useState("");
  const [status, setStatus] = useState("");

  const [mapDoubt, setMapDoubt] = useState([]);
  const [allDoubt, setAllDoubt] = useState(true);

  const handleGradeSelect = (value) => {
    setGradeName(value);
  };

  const handleSubjectSelect = (value) => {
    setSubjectName(value);
  };

  const handleStatusSelect = (value) => {
    setStatus(value);
  };

  const handleClick = () => {
    setAllDoubt(true);
    setStatus("");
    setMapDoubt([]);
  };
  const handleClick1 = () => {
    setAllDoubt(false);
  };

  let attClassInAll = ["Div-all-sdj32", "Class_giving_border"];
  let attClassInAll2 = ["Div-all-sdj32"];

  if (!allDoubt) {
    attClassInAll2 = ["Div-all-sdj32", "Class_giving_border"];
    attClassInAll = ["Div-all-sdj32"];
  }

  const triggerFilter = () => {
    setAllDoubt(false);
    setMapDoubt(
      doubts.filter((res) => {
        if (status === "" && subject_id === "" && grade_id === "") {
          return res;
        } else if (
          res.doubt.doubt_status.toLowerCase().includes(status.toLowerCase()) &&
          res.doubt.grade_id.toLowerCase().includes(grade_id.toLowerCase()) &&
          res.doubt.subject_id.toLowerCase().includes(subject_id.toLowerCase())
        ) {
          return res;
        }
      })
    );
  };

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  useEffect(() => {
    doubtList(user.enrolled_student_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setDoubts(false);
        } else {
          console.log(res);
          setDoubts(res);
        }
      })
      .catch((err) => console.log(err));
  }, [isCreated]);

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteStudent(reduxUser.enrolled_student_id));
    removeToken();
  };

  function upload(e) {
    S3FileUpload.uploadFile(e.target.files[0], S3doubtsconfig)
      .then((data) => {
        setDoubtsUrl(data.location);
      })
      .catch((err) => {
        alert(err);
      });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    creadeDoubt({
      id: "DBT_" + user.enrolled_student_id + "_" + new Date().toISOString(),
      date: new Date().toISOString(),
      doubt_text: doubtText,
      studentID: user.enrolled_student_id,
      doubt_status: "Active",
      grade_id: doubtGrade,
      subject_id: doubtSubject,
      doubt_url: doubtsUrl,
    })
      .then((res) => {
        alert("Doubt Created Successfully!");
        setIsCreated(!isCreated);
        setDoubtText("");
        setDoubtSubject("");
        setDoubtGrade("");
        setDoubtsUrl("");
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <form
        className="student-doubts-list-mobile screen"
        name="form4"
        action="form4"
        method="post"
      >
        <div className="component-1-C61RwL">
          {/* <img className="line-5-9p2GBv" src={line5_2x} /> */}
          <img
            alt="image"
            src={pic_2}
            style={{ height: "70px", marginTop: "-8%" }}
          />
        </div>

        {/* This is the hamburger icon  */}
        <SideDrawerStudent
          open={displaySideDrawer}
          closeSideDrawer={sideDrawerToggler}
        />

        <div className="component-6-C61RwL" onClick={sideDrawerToggler}>
          <div className="rectangle-48-5mfU7d"></div>
          <div className="rectangle-49-5mfU7d"></div>
          <div className="rectangle-50-5mfU7d"></div>
        </div>
        <div className="solve-doubts-C61RwL-10">
          <span className="span0-z3pWM6">Solve </span>
          <span className="span1-z3pWM6">Doubts</span>
        </div>
        <div className="group-72-C61RwL">
          <div className="rectangle-40-gqbUX1"></div>
          <div className="component-29-gqbUX1">
            <div className="assignment-24px-3-lxV7ix">
              <img className="vector-YDNU6K" src={vector_2x} />
              <img className="vector-hGGfYL" src={vector_1_2x} />
            </div>
          </div>
          <div className="assignment-1-gqbUX1 montserrat-semi-bold-blue-zodiac-10px">
            ASSIGNMENT 1
          </div>
          <div className="mathematics-dr-yashna-sharma-gqbUX1 montserrat-medium-gray-7px">
            Mathematics (Dr. Yashna Sharma)
          </div>
          <div className="submitted-gqbUX1 montserrat-semi-bold-niagara-8px">
            Submitted
          </div>
        </div>
        <div className="group-73-C61RwL">
          <div className="rectangle-40-ql7mN0"></div>
          <div className="component-29-ql7mN0">
            <img className="ellipse-4-MxHjgv" src={ellipse_4_1_2x} />
            <div className="assignment-24px-3-MxHjgv">
              <img className="vector-x8YWNM" src={vector_2x} />
              <img className="vector-UwmKsc" src={vector_3_2x} />
            </div>
          </div>
          <div className="assignment-1-ql7mN0 montserrat-semi-bold-blue-zodiac-10px">
            ASSIGNMENT 1
          </div>
          <div className="mathematics-dr-yashna-sharma-ql7mN0 montserrat-medium-gray-7px">
            Mathematics (Dr. Yashna Sharma)
          </div>
          <div className="submitted-ql7mN0 montserrat-semi-bold-niagara-8px">
            Submitted
          </div>
        </div>
        <div className="group-74-C61RwL">
          <div className="rectangle-40-aCi14x"></div>
          <div className="component-29-aCi14x">
            <div className="assignment-24px-3-DVuPOa">
              <img className="vector-Z87Q8p" src={vector_2x} />
              <img className="vector-5tYqrB" src={vector_5_2x} />
            </div>
          </div>
          <div className="assignment-1-aCi14x montserrat-semi-bold-blue-zodiac-10px">
            ASSIGNMENT 1
          </div>
          <div className="mathematics-dr-yashna-sharma-aCi14x montserrat-medium-gray-7px">
            Mathematics (Dr. Yashna Sharma)
          </div>
          <div className="submitted-aCi14x montserrat-semi-bold-niagara-8px">
            Submitted
          </div>
        </div>
        <div className="group-75-C61RwL">
          <div className="rectangle-40-sDLNVS"></div>
          <div className="component-29-sDLNVS">
            <div className="assignment-24px-3-z4xZZj">
              <img className="vector-hyPzor" src={vector_2x} />
              <img className="vector-McuNxD" src={vector_7_2x} />
            </div>
          </div>
          <div className="assignment-1-sDLNVS montserrat-semi-bold-blue-zodiac-10px">
            ASSIGNMENT 1
          </div>
          <div className="mathematics-dr-yashna-sharma-sDLNVS montserrat-medium-gray-7px">
            Mathematics (Dr. Yashna Sharma)
          </div>
          <div className="submitted-sDLNVS montserrat-semi-bold-niagara-8px">
            Submitted
          </div>
        </div>
        <div className="group-99-C61RwL-10">
          <DropdownOption2
            handleGradeSelect={handleGradeSelect}
            handleSubjectSelect={handleSubjectSelect}
            handleStatusSelect={handleStatusSelect}
          />
        </div>
        {/* <div className="group-100-C61RwL-10">
          <input
            className="component-30-lvfzGr border-1px-black-2"
            placeholder="Status"
            onChange={(e) => setStatus(e.target.value)}
          />
          
        </div> */}
        <div
          className="group-100-VMr6Om-10"
          style={{ backgroundColor: "#dc3545" }}
          onClick={triggerFilter}
        >
          Filter
        </div>
        <div className="group-103-C61RwL-10">
          <DropdownOption
            handleGradeSelect={(v) => setDoubtGrade(v)}
            handleSubjectSelect={(v) => setDoubtSubject(v)}
          />
        </div>
        <div className="group-104-C61RwL">
          {/* <input className="choose-subject-i63353722371261-pI3smF montserrat-medium-gray-10px"  />
            
          </div> */}
        </div>
        <Button
          className="group-105-C61RwL-10-up"
          style={{ backgroundColor: "#dc3545" }}
          onClick={(e) => handleSubmit(e)}
        >
          Create Doubt
        </Button>

        <div className="doubt-text-C61RwL">Doubt Text</div>
        <textarea
          className="rectangle-71-C61RwL"
          name="6335404"
          placeholder=""
          type="text"
          onChange={(e) => setDoubtText(e.target.value)}
          required
        ></textarea>

        <div className="upload-in-lec-notes">
          <label for="file-input-desktop">+ Upload File</label>

          <input
            id="file-input-desktop"
            type="file"
            onChange={(e) => upload(e)}
            style={{ display: "none" }}
          />
        </div>

        <div className="bhsks-snfasa-sd2-02">
          <div className={attClassInAll.join(" ")} onClick={handleClick}>
            All
          </div>
          <div className={attClassInAll2.join(" ")} onClick={handleClick1}>
            {" "}
            Searched
          </div>
        </div>

        <div className="now-is-dix-c-09sL">
          {allDoubt === true ? (
            doubts && doubts.length > 0 ? (
              doubts.map((doubt) => {
                return <DoubtCard key={doubt.id} doubt={doubt} user={user} />;
              })
            ) : (
              <div className="vatsal-agarwal-B6ZBuj">
                No doubts present at the moment.
              </div>
            )
          ) : mapDoubt && mapDoubt.length > 0 ? (
            mapDoubt.map((doubt) => {
              return <DoubtCard key={doubt.id} doubt={doubt} user={user} />;
            })
          ) : (
            <div className="vatsal-agarwal-B6ZBuj">
              No doubts present at the moment.
            </div>
          )}
        </div>
      </form>
      <form className="student-doubts screen" name="form8">
        <div className="student-doubts-col">
          <div className="student-re-flex">
            <div className="component-1-C61RwLz-3">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "115px", marginTop: "-8%" }}
              />
            </div>
            <div className="group-22-GyJUNH-student-dash">
              <div className="flex-col-vgli2x-student-dash">
                <div className="biba-sobti-h8V8ZR-student-dash">
                  {user.first_name + " " + user.last_name}
                </div>
                <div className="bibasobtigmailcom-h8V8ZR-student-dash">
                  {user && user.email}
                </div>
              </div>
              <img className="vector-vgli2x-student-dash" src={vector_5_2x} />
            </div>
          </div>

          <div className="doubt-row-1">
            <div className="doubt-text-C61RwL montserrat-medium-black-18px">
              Doubt Text
            </div>
            <textarea
              className="rectangle-71-C61RwL"
              onChange={(e) => setDoubtText(e.target.value)}
              name="6335261"
              placeholder="Enter your Doubt here"
              type="text"
              required
            ></textarea>

            <div className="upload-in-lec-notes">
              <label for="file-input">+ Upload File</label>
              <input
                id="file-input"
                type="file"
                onChange={(e) => upload(e)}
                style={{ display: "none" }}
              />
            </div>
          </div>

          <div className="doubt-row-2">
            <div className="newdrop2">
              <DropdownOption
                handleGradeSelect={(v) => setDoubtGrade(v)}
                handleSubjectSelect={(v) => setDoubtSubject(v)}
              />
            </div>
            <Button
              onClick={(e) => handleSubmit(e)}
              className="group-161-C61RwL"
              style={{ backgroundColor: "#dc3545" }}
            >
              <div className="create-doubt-j57GY0">Create Doubt</div>
            </Button>
          </div>

          <div className="solve-doubts-C61RwL-10">
            <span className="span0-z3pWM6">Solve</span>
            <span className="span1-z3pWM6"> Doubts</span>
          </div>

          <div className="doubt-row-3">
            <div className="newdrop1">
              <DropdownOption2
                handleGradeSelect={handleGradeSelect}
                handleSubjectSelect={handleSubjectSelect}
                handleStatusSelect={handleStatusSelect}
              />
            </div>

            {/* <div className="doubt-status">
              <div className="status-C61RwL-10 montserrat-medium-black-18px">
                Status
              </div>
              <input
                className="rectangle-70-C61RwL-10"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              />
            </div> */}
            <div
              className="group-53-C61RwL-10"
              style={{ backgroundColor: "#dc3545" }}
              onClick={triggerFilter}
            >
              <div className="filter-VAhwTv">Filter</div>
            </div>
          </div>

          {/* <div className="status-VMr6Om montserrat-medium-black-18px">Status</div>
        <input className="rectangle-74-C61RwL" onChange={e => setDoubtStatus(e.target.value)} /> */}

          <div className="bhsks-snfasa-sd2-02">
            <div className={attClassInAll.join(" ")} onClick={handleClick}>
              All
            </div>
            <div className={attClassInAll2.join(" ")} onClick={handleClick1}>
              {" "}
              Searched
            </div>
          </div>

          <div className="group-220-C61RwL-10x">
            {allDoubt === true ? (
              doubts && doubts.length > 0 ? (
                doubts.map((doubt) => {
                  return <DoubtCard key={doubt.id} doubt={doubt} user={user} />;
                })
              ) : (
                <div className="vatsal-agarwal-B6ZBuj">
                  No doubts present at the moment.
                </div>
              )
            ) : mapDoubt && mapDoubt.length > 0 ? (
              mapDoubt.map((doubt) => {
                return <DoubtCard key={doubt.id} doubt={doubt} user={user} />;
              })
            ) : (
              <div className="vatsal-agarwal-B6ZBuj">
                No doubts present at the moment.
              </div>
            )}
          </div>
        </div>
        <div className="rectangle-24-C61RwL-stu-s1">
          <Link to="/student-dashboard">
            <div data-tip="Dashboard">
              <MdDashboard size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-date-calendar">
            <div data-tip="Date Calendar">
              <FaCalendarAlt size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-assignment-list">
            <div data-tip="Assignments">
              <MdAssignment size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-quiz-list">
            <div data-tip="Quizzes">
              <RiQuestionnaireFill size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-curriculum-list">
            <div data-tip="Curriculums">
              <FaFileAlt size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-doubt-list">
            <div data-tip="Doubts">
              <FaRegQuestionCircle size={24} color="#dc3545" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-performance" data-tip="Performance">
            <div>
              <FaChartPie size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-notes-list">
            <div data-tip="Lecture Notes">
              <FaStickyNote size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/" onClick={() => handleLogout()}>
            <FaPowerOff
              size={24}
              data-tip="Logout"
              color="rgba(179,179,179,1.0)"
            />
            <ReactTooltip />
          </Link>
        </div>
      </form>
      <Popup />
    </>
  );
}

function DoubtCard({ doubt, user }) {
  return (
    <div className="doubt-card">
      <div className="component-4-B6ZBuj">
        <div className="doubt-name-status">
          <div className="vatsal-agarwal-B6ZBuj2131">
            {doubt.student[0].first_name} {doubt.student[0].last_name}
          </div>
          <div className="active-B6ZBuj">{doubt.doubt.doubt_status}</div>
        </div>
        <div className="doubt-text-expand">
          <div className="what-is-euclid-lemma-B6ZBuj montserrat-medium-black-18px">
            {doubt.doubt.doubt_text}
          </div>
          <Link to={`/student-doubt-detail/${doubt.doubt.id}`}>
            <div className="expand-B6ZBuj">Expand</div>
          </Link>
        </div>
      </div>
      {/* <div className="biba-sobti-B6ZBuj">{user && `${user.first_name} ${user.last_name}`}</div>
      <Input placeholder='Solve this doubt' />
      <Button primary className="doubt-post-button">Post</Button> */}
    </div>
  );
}

export default Studentdoubts;
