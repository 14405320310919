import React, { useState, useLayoutEffect } from 'react'
import { Link, useHistory } from "react-router-dom";
import Modal from 'react-modal';
import { Button, Card, Container, Row, Col } from 'react-bootstrap';
import { Icon, Image } from 'semantic-ui-react'
import { Form, Message } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { getStudent } from "../../../redux/actions/student";
import { login } from "../../../crud/auth.crud";
import { setToken } from "../../../utils";
import LoginModal from "../../../components/Login_modal/loginModal";
import pic_5 from "../../student/dashboard/img/pic_5.png";

import "./style.css";
import CustomNav from "../../../components/header/Navbar";
import Footer from '../../../components/Footer';

// images here


Modal.setAppElement('#root');

function Facultydetail() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  // const closeModal = () => setIsModalOpen(false);
  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  const handleLogin = () => {
    setLoading(true);
    setIsErrorShown(false);

    if (!email || !password) {
      setLoading(false);
      setIsErrorShown(true);
    } else {
      login(email, password)
        .then(({ data: { access, refresh, email, user_type } }) => {
          setLoading(false);
          dispatch(getStudent({ access, email, user_type }), history);
          setToken(access, refresh);
          // history.replace("/student-dashboard");
        })
        .catch(() => {
          setLoading(false);
          setIsErrorShown(true);
        })
    }
  }

  const [cards, setCards] = useState([
    {
      id: 1,

      name: "MD. Sameer",
      description:
        "Welcome students, I'm Md.Sameer and I am educator of Economics. I will spark your interest in Economics and we together will master all the concepts.",
      subject: "Accounts and Economics",
    },
    {
      id: 2,
      name: "Pooja",
      // description:
      //   "Hello students. I'm S. Bisht English Faculty of TRG. We will together  master all the basic as well as advance topics of English . I'll ensure a friendly and interactive environment to make your concepts crystal clear.",
      subject: "PGT Geography",
    },
    {
      id: 3,
      name: "Europa",
      // description:
      //   "Hello everyone, I am Priya Jaiswal. TRGs Faculty of Science, I am sure science will no longer be a nightmare for you. Clarity of concept, clearing doubts, and also helping you retain the topic, are my top priorities.",
      subject: "PGT Biology",
    },
    {
      id: 4,
      name: "Manthan",
      // description:
      //   "Hello students, I am Kamini. We will together  explore maths through simplest ways. I will help you to to understand maths at core level to make you  confident regarding challenging questions.",
      subject: "TGT Mathematics",
    },
    {
      id: 5,
      name: "Abhishek",
      // description:
      //   "Hello Everyone. I will impart the knowledge of business studies. Along with Hardwork we will do smart study by learning new and funny acronyms to remember various terms. I can assure that all the concepts will be crystal clear to you.",
      subject: "Political Science",
    },
    {
      id: 6,
      name: "Anjali",
      // description:
      //   "Welcome students, I'm Md.Sameer and I am educator of Economics. I will spark your interest in Economics and we together will master all the concepts.",
      subject: "PRT all subjects",
    },
    {
      id: 7,
      name: "Rohit",
      // description:
      //   "Hello students, I'll equip you with the knowledge our ancient heritages, modern politics and perpetual Earth. I'll spark your interest in S.St. So come and learn via New dimension.",
      subject: "TGT Science",
    },
    {
      id: 8,
      name: "Ritu",
      // description:
      //   "Hello Everyone, my name is Shah Nawaz and I'm teacher of Economics. I'll impart you all the theories and practical concepts in simplest manner. I will be with you on the way to achieve  perfect score in this subject.",
      subject: "PGT Mathematics",
    },
    {
      id: 9,
      name: "Manisha",
      // description:
      //   "Hello Students. I'm Porus. I will keep you updated with latest events and news happening around us, in short, I will equip you with current affairs that will match your pace with the fast running era. I can assure that you will get maximum knowledge in precise and simple manner at one place.",
      subject: "PGT Biology",
    },
    {
      id: 10,
      name: "Ravi",
      // description:
      //   "Hello learners, I am Prajakta, Let me give you idea of all the basic concepts.",
      subject: "PGT Physics",
    },
    {
      id: 11,
      name: "Vanshika",
      subject: "PGT English"
    }

  ]);


  return (
    <div className="faculty-details-1">
      {/* <CustomNav setIsModalOpen={setIsModalOpen} /> */}
      <section
        style={{
          width: "100%",
          backgroundColor: "#dc3545",
          display: "flex",
          justifyContent: "center",
          paddingBottom: "1%",
        }}
      >
        <Link to="/">
          <img style={{ height: "150px" }} src={pic_5} alt="" />
        </Link>
      </section>
      <section id="teachers">
        <h1
          style={{
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: "700",
            fontSize: "x-large",
            textAlign: "center",
            margin: "5% 0",
          }}
        >
          OUR GURUS
        </h1>
        <div className="crdbody" style={{ padding: "40px" }}>
          {cards.map((task) => (
            // <Container fluid>
            //   <Row className="justify-content-around">
            //     <Col md={3} className="teacher-card">
            //       <Card
            //         style={{
            //           fontFamily: "'Montserrat', Arial, Helvetica, sans-serif",
            //           margin: "auto",
            //         }}
            //       >
            //         {/* <Card.Img variant="top" src={task.img} /> */}
            //         <Card.Body>
            //           <Card.Title>{task.name}</Card.Title>
            //           <Card.Text className="card-text-personal"></Card.Text>
            //         </Card.Body>
            //         <Card.Footer className="text-muted">
            //           {task.subject}
            //         </Card.Footer>
            //       </Card>
            //     </Col>

            //   </Row>

            // </Container>
            <div className="crd">
              <Card
                style={{
                  fontFamily: "'Montserrat', sans-serif",
                  width: "100%",
                }}
              >
                {/* <Card.Img variant="top" src={} /> */}
                <Card.Body>
                  <Card.Title>{task.name}</Card.Title>
                  <Card.Text className="detail-card">{task.description}</Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted">
                  {task.subject}
                </Card.Footer>
              </Card>
            </div>
          ))}
        </div>
      </section>

      <section id="footer">
        <Footer />
      </section>

      {/* <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          className="login-modal"
        >
          <div className="login-modal-title montserrat-semi-bold-blue-zodiac-18px">
            Student Login
          </div>
          <Form error style={{ width: "70%", margin: "auto" }}>
            {isErrorShown ? (
              <Message
                error
                header="Wrong Credentials"
                content="You entered a wrong email or password."
              />
            ) : (
              <div />
            )}
            <Form.Input
              name="email"
              label="Email"
              placeholder="kunal@therightguru.com"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Input
              label="Password"
              type="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              variant="danger"
              onClick={() => handleLogin()}
              disabled={loading}
            >
              Login
            </Button>
          </Form>
        </Modal> */}
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
}

export default Facultydetail;