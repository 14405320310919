import React, { useState } from 'react';
import './loginModal.css';
import BackDrop from "../../UI/Backdrop/Backdrop";
import { Form, Message } from "semantic-ui-react";
import { Divider } from 'antd';

import { getStudent } from "../../redux/actions/student";
import { login } from "../../crud/auth.crud";
import { setToken } from "../../utils";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button } from 'react-bootstrap';



const LoginModal = (props) => {
    const [isErrorShown, setIsErrorShown] = useState(false);
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const history = useHistory();
    const dispatch = useDispatch();

    let attclasses = ["login-modal-2-01", "Close-Landing"];

    if (props.isModalOpen) {
        attclasses = ["login-modal-2-01", "Open-Landing"];
    }

    const handleLogin = () => {
        setLoading(true);
        setIsErrorShown(false);

        if (!email || !password) {
            setLoading(false);
            setIsErrorShown(true);
        } else {
            login(email, password)
                .then(({ data: { access, refresh, email, user_type } }) => {
                    setLoading(false);
                    dispatch(getStudent({ access, email, user_type }, history));
                    setToken(access, refresh, email);
                    // history.replace("/student-dashboard");
                })
                .catch(() => {
                    setLoading(false);
                    setIsErrorShown(true);
                })
        }
    }
    return (
        <div className="login_modal_layout_100">
            <BackDrop open={props.isModalOpen} clicked={props.closeModal} />
            <div
                // isOpen={isModalOpen}
                // onRequestClose={closeModal}
                className={attclasses.join(' ')}
            >
                <div className="login-modal-title-2-10x">Student LogIn</div>
                <Form className="form-login-modal-2" error>
                    {
                        isErrorShown ?
                            <Message
                                error
                                header='Wrong Credentials'
                                content='You entered a wrong email or password.'
                                style={{ fontSize: '10px', width: '80%', textAlign: 'center' }}
                            /> :
                            <p style={{ margin: '0px', padding: '0px' }} />
                    }

                    <Form.Input
                        fluid icon='user'
                        iconPosition='left'
                        name="email"
                        placeholder='Email Address'
                        className="form-input-login-modal-2"
                        onChange={e => setEmail(e.target.value)}
                    />

                    <Form.Input
                        fluid icon='lock'
                        iconPosition='left'
                        placeholder="Password"
                        type='password'
                        name='password'
                        className="form-input-login-modal-2"
                        onChange={e => setPassword(e.target.value)}
                    />


                    <Button
                        className="button-login-modal"
                        variant="danger"
                        onClick={() => handleLogin()}
                        disabled={loading}
                    >
                        Log in
                        </Button>

                    <Link className="message-2-land-log" to="/forgot-password">
                        <p>Forgot Password?</p>
                    </Link>

                    <Divider style={{ margin: '5px 5px' }} />

                    <p className="message-2-land-log">Not Registered? <Link to='/trial-student-form'><span style={{ color: '#e3241d' }} onClick={props.closeModal}> Book A Free Trial Class</span></Link></p>
                </Form>

            </div>
        </div>
    );
}

export default LoginModal;