import React, { useState, useEffect, useLayoutEffect } from "react";
import "./style.css";
import ReactTooltip from "react-tooltip";
import { NavLink } from "react-router-dom";
import moment from "moment";
import line_5_2x from "./img/line-5@2x.svg";
import line_6_2x from "./img/line-6@2x.svg";
import line_5_1_2x from "./img/line-5-1@2x.svg";
import line_6_1_2x from "./img/line-6-1@2x.svg";
import vector_1_2x from "./img/vector-1@2x.svg";
import rectangle_7_1x from "./img/rectangle-7@1x.png";
import vector_5_2x from "./img/vector-5@2x.png";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

import DashboardIcon from "@material-ui/icons/Dashboard";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SchoolIcon from "@material-ui/icons/School";
import SideDrawerAccounts from "../../../UI/sideDrawer/sideDrawerAccounts";
import { removeToken } from "../../../utils";
import { deleteAccounts } from "../../../redux/actions/accounts";
import { useSelector, useDispatch } from "react-redux";
import { AccountStudent } from "../../../crud/accounts.crud";
import pic_2 from "../../student/dashboard/img/pic_2.png";
function AccountStudents(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [myStudents, setMyStudents] = useState([]);
  const user = useSelector((state) => state.accountsDetails.accounts);
  const [handleFilter, setHandleFilter] = useState("");
  const [payments, setPayments] = useState({});
  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(deleteAccounts(user.accounts_employee_id));
    removeToken();
  };

  useEffect(() => {
    AccountStudent()
      .then((res) => {
        setPayments(res);
        console.log(payments);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="my_students-list"
      />
      <div className="container-center-horizontal">
        <div
          className="my_students-list-mobile screen"
          style={{ height: "787px" }}
        >
          <div className="flex-row-C61RwL">
            <div className="component-1-dXBp89">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "90px", marginTop: "-8%" }}
              />
            </div>
            <SideDrawerAccounts
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />

            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>
          <div className="group-127-C61RwL">
            <div className="list-of-students-2zqKZg">List of Students</div>
            <input
              type="text"
              placeholder="Filter List"
              style={{ width: "200px", marginLeft: "10px", marginTop: "5px" }}
              onChange={(e) => setHandleFilter(e.target.value)}
            />

            <div className="overlap-group-2zqKZg-0-i">
              <div className="rectangle-30-y1HRxC-mobile-10">
                <div className="nfkjbdajhbjd-fajdn3-accounts-student">
                  {payments.length > 0 ? (
                    payments
                      .filter((res) => {
                        const test_temp = res.trial_student_id;
                        if (handleFilter === "") {
                          return res;
                        } else if (
                          test_temp
                            .toLowerCase()
                            .includes(handleFilter.toLowerCase())
                        ) {
                          return res;
                        }
                      })
                      .map((item, index) => {
                        return (
                          <div className="group-56-y1HRxC-mobile-10">
                            <div className="group-45-759J8x-1-1-1">
                              <div className="rectangle-20-aIq8ji-7-3-accounts-student">
                                <div className="roboto-bold-white-10px-2">
                                  Transaction ID
                                </div>
                                <div className="roboto-bold-white-10px-2">
                                  Trial Student ID
                                </div>
                                <div className="roboto-bold-white-10px-2">
                                  Trial Class Count
                                </div>
                                <div className="roboto-bold-white-10px-2">
                                  Date
                                </div>
                                <div className="roboto-bold-white-10px-2">
                                  Time
                                </div>
                                <div className="roboto-bold-white-10px-2">
                                  Amount
                                </div>
                                <div className="roboto-bold-white-10px-2">
                                  Payment-Mode
                                </div>
                              </div>
                            </div>
                            <div className="payga4wk-z-bshl-Sgr7kw-mob-10 montserrat-semi-bold-blue-zodiac-10px-accounts-student">
                              {item && item.student_transaction_id}
                            </div>
                            <div className="x499900-Sgr7kw-mob-10 montserrat-semi-bold-blue-zodiac-10px-accounts-student">
                              {item && item.trial_student_id.length <= 17
                                ? item.trial_student_id
                                : item.trial_student_id.slice(0, 17) + "..."}
                            </div>
                            <div className="x07-dec-2020-Sgr7kw-mob-10 montserrat-semi-bold-blue-zodiac-10px-accounts-student">
                              {item && item.payment_for}
                            </div>
                            <div className="x014009-pm-Sgr7kw-mob-10 montserrat-semi-bold-blue-zodiac-10px-accounts-student">
                              {item && moment(item.created_on).format("LL")}
                            </div>
                            <div className="andb586-Sgr7kw-mob-10 montserrat-semi-bold-blue-zodiac-10px-accounts-student">
                              {item && moment(item.created_on).format("LT")}
                            </div>
                            <div className="andb586-Sgr7kw-mob-10 montserrat-semi-bold-blue-zodiac-10px-accounts-student">
                              {item && item.amount}
                            </div>
                            <div className="andb586-Sgr7kw-mob-10 montserrat-semi-bold-blue-zodiac-10px-accounts-student">
                              {item && item.method}
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                      No occupied students
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-center-horizontal">
        <div className="my_students-list screen">
          <div className="sider-SalesStatistics-detail">
            <span style={{ color: "white" }}>
              <div className="sider-hc-teacher-list-in-wdt-80">
                <NavLink to="/accounts-dashboard">
                  <DashboardIcon
                    data-tip="Dashboard"
                    className="icon-in-hc-color"
                  />
                  <ReactTooltip />
                </NavLink>
                <NavLink to="/accounts-student">
                  {" "}
                  <SchoolIcon
                    data-tip="Student"
                    className="icon-in-hc-color"
                    style={{ color: "#dc3545" }}
                  />
                  <ReactTooltip />
                </NavLink>
                <NavLink to="/accounts-employees">
                  <SupervisorAccountIcon
                    data-tip="Employees"
                    className="icon-in-hc-color"
                  />
                  <ReactTooltip />
                </NavLink>

                {/* <NavLink to="/rm-statistics">
                  <FaRegQuestionCircle
                    data-tip="Help Center"
                    className="icon-in-hc-color"
                  />
                  <ReactTooltip />
                </NavLink> */}
                <NavLink to="/" onClick={() => handleLogout()}>
                  <PowerSettingsNewIcon
                    data-tip="Logout"
                    className="icon-in-hc-color"
                  />
                </NavLink>
              </div>
            </span>
          </div>
          <div className="flex-col-C61RwL">
            <div className="flex-row-0xy0vn">
              <div className="component-1-GyJUNH">
                <img
                  alt="image"
                  src={pic_2}
                  style={{ height: "100px", marginTop: "-8%" }}
                />
              </div>
              <div className="group-22-GyJUNH" style={{ marginLeft: "42rem" }}>
                <div className="flex-col-vgli2x">
                  <div className="biba-sobti-h8V8ZR">
                    {user && user.first_name} {user && user.last_name}
                  </div>
                  <div className="bibasobtigmailcom-h8V8ZR">
                    {user && user.emailID}
                  </div>
                </div>
                <img className="vector-vgli2x" src={vector_5_2x} alt="line" />
              </div>
            </div>
            <div className="flex-row-oV5UrE">
              <div className="overlap-group-QkTteQ">
                <img
                  className="rectangle-7-dlP8Ul"
                  src={rectangle_7_1x}
                  alt="line"
                />
                <div className="group-203-dlP8Ul">
                  <div className="list-of-students-5GLwEc-accounts-student">
                    <span>List of Students</span>
                    <input
                      type="text"
                      placeholder="Filter List"
                      style={{
                        width: "210px",
                        marginRight: "5px",
                        color: "black",
                      }}
                      onChange={(e) => setHandleFilter(e.target.value)}
                    />
                  </div>
                  <div className="group-45-5GLwEc-ly">
                    <div className="rectangle-20-Cd7ClN-in-accounts-student">
                      <div className="roboto-medium-white-20px-accounts-student">
                        Transaction ID.
                      </div>
                      <div className="roboto-medium-white-20px-accounts-student">
                        Trial Student ID
                      </div>
                      <div className="roboto-medium-white-20px-accounts-student">
                        Payment for
                      </div>
                      <div className="roboto-medium-white-20px-accounts-student">
                        Date
                      </div>
                      <div className="roboto-medium-white-20px-accounts-student">
                        Time
                      </div>
                      <div className="roboto-medium-white-20px-accounts-student">
                        Amount
                      </div>
                      <div className="roboto-medium-white-20px-accounts-student">
                        Payment-Mode
                      </div>
                    </div>
                  </div>
                  <div className="overlap-group1-5GLwEc-in-my">
                    <div className="rectangle-30-y1HRxC-10-c-accounts-student">
                      <div className="nfkjbdajhbjd-fajdn3">
                        {payments.length > 0 ? (
                          payments
                            .filter((res) => {
                              const test_temp = res.trial_student_id;
                              if (handleFilter === "") {
                                return res;
                              } else if (
                                test_temp
                                  .toLowerCase()
                                  .includes(handleFilter.toLowerCase())
                              ) {
                                return res;
                              }
                            })
                            .map((item) => {
                              return (
                                <div
                                  key={item && item.student_transaction_id}
                                  className="group-56-y1HRxC-9-ew-accounts-student"
                                >
                                  <div className="payga4wk-z-bshl-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px-accounts-student">
                                    {item.student_transaction_id}
                                  </div>
                                  <div className="x499900-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px-accounts-student">
                                    {item && item.trial_student_id.length <= 17
                                      ? item.trial_student_id
                                      : item.trial_student_id.slice(0, 17) +
                                        "..."}
                                  </div>
                                  <div className="x07-dec-2020-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px-accounts-student">
                                    {item && item.payment_for}
                                  </div>
                                  <div className="x014009-pm-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px-accounts-student">
                                    {item && item.created_on}
                                  </div>
                                  <div className="andb586-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px-accounts-student">
                                    {item && item.created_on}
                                  </div>
                                  <div className="andb586-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px-accounts-student">
                                    {item && item.amount}
                                  </div>
                                  <div className="andb586-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px-accounts-student">
                                    {item && item.method}
                                  </div>
                                </div>
                              );
                            })
                        ) : (
                          <div
                            style={{ fontFamily: "'Montserrat', Helvetica" }}
                          >
                            No occupied students
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Popup /> */}
    </>
  );
}

export default AccountStudents;
