import axios from "axios";
import { toAPIURL } from "../utils";

export async function rmStatistics(rmId) {
  try {
    const res = await axios.get(toAPIURL("api/rm-statistics/" + rmId));
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function rmIssueList(rmId) {
  try {
    const res = await axios.get(toAPIURL("api/get-issue-list/" + rmId));
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function rmIssueDetail(issueId) {
  try {
    const res = await axios.get(toAPIURL("api/issue-detail/" + issueId));
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function rmTicketList(rmId) {
  try {
    const res = await axios.get(toAPIURL("api/ticket-list/" + rmId));
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function rmManageList(rmId) {
  try {
    const res = await axios.get(toAPIURL("api/rm-manage-list/" + rmId));
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function getSingleClass(date, time, teacherId) {
  try {
    const res = await axios.get(
      toAPIURL(`api/class-detail/${date}/${time}/${teacherId}`)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function getSingleTrialClass(date, time, teacherId) {
  try {
    const res = await axios.get(
      toAPIURL(`api/trial-class-detail/${date}/${time}/${teacherId}`)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function resolveIssue(issue) {
  try {
    const res = await axios.put(
      toAPIURL("api/issue-detail/" + issue.id),
      issue
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function resolveTicket(ticket) {
  try {
    const res = await axios.put(
      toAPIURL("api/ticket-detail-rm/" + ticket.id),
      ticket
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function rmNewTicket(ticketDetails) {
  try {
    const res = await axios.put(
      toAPIURL("api/ticket-detail-rm/0"),
      ticketDetails
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function cancelClass(classDetails) {
  try {
    const res = await axios.put(
      toAPIURL("api/cancel-class/" + classDetails.id),
      classDetails
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function cancelClassWithSlot(classDetails) {
  try {
    const res = await axios.put(
      toAPIURL("api/cancel-class-and-slot/" + classDetails.id),
      classDetails
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function cancelTrialClass(classDetails) {
  try {
    const res = await axios.put(
      toAPIURL("api/cancel-trial-class/" + classDetails.id),
      classDetails
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function deleteSlot(slotId) {
  try {
    const res = await axios.delete(toAPIURL("api/delete-slot/" + slotId));
    return res.data;
  } catch (err) {
    console.error(err);
  }
}
