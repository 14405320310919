import React from 'react';
import classes from './Backdrop.module.css';

const backdrop=(props)=>{
    
    let dispbackdrop = [classes.Backdrop, classes.Close];

    if(props.open){
        dispbackdrop = [classes.Backdrop, classes.Open];
    }
    
    return(
        <div className={dispbackdrop.join(' ')} onClick={props.clicked}></div>
    );
};

export default backdrop;