import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import axios from "axios";

function Corosal() {
  const [data, setData] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.therightguru.com/api/carousel-entry/');
      setData(response.data);
      console.log(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div
          className="col-lg-8"
          style={{
            width: "1200px",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center content horizontally
          }}
        >
          <div
            id="carouselExampleInterval"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div
              className="carousel-inner"
              style={{
                border: "5px solid #dc3545",
                borderRadius: "5px",
              }}
            >
              {data && data.map((item, index) => (
                index === 1 ? (
                  <div className="carousel-item active" data-bs-interval="5000" key={index}>
                    <a href={item.redirectLink}>
                      <img src={item.imgUrl} className="d-block w-100" alt={item.name} />
                    </a>
                  </div>
                ) : (
                  <div className="carousel-item" data-bs-interval="5000" key={index}>
                    <a href={item.redirectLink}>
                      <img src={item.imgUrl} className="d-block w-100" alt={item.name} />
                    </a>
                  </div>
                )
              ))}
              {/* Add other carousel items here */}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleInterval"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleInterval"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                ariahidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "5%",
            }}
          >
            <Link to="/trial-student-form">
              <Button
                className="nav-login shadow"
                style={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: "600",
                  fontSize: "16px", // Increase font size for readability on small screens
                  padding: "12px", // Reduce padding for better use of space
                }}
                variant="danger"
              >
                Book FREE Trial Class
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Corosal;
