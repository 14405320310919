import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import "./style.css";
import { Link, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Select from 'react-dropdown-select';
import { FaRegQuestionCircle, FaCalendarAlt, FaFileAlt, FaChartPie, FaStickyNote, FaPowerOff } from "react-icons/fa";
import { MdAssignment, MdDashboard, MdNotificationsNone, MdSchool } from "react-icons/md";
import { BiSupport, BiHistory } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { AiTwotoneBank } from "react-icons/ai";
import line_5_2x from "./img/line-5@2x.svg";
import line_6_2x from "./img/line-6@2x.svg";
import ellipse_2_1_2x from "./img/ellipse-2-1@2x.svg";
import vector_2x from "./img/vector@2x.svg";
import vector_6_2x from "./img/vector-6@2x.svg";
import rectangle_48_2x from "./img/rectangle-48@2x.svg";
import rectangle_7_1x from "./img/rectangle-7@1x.png";
import rectangle_49_2x from "./img/rectangle-49@2x.svg";
import vector_3_2x from "./img/vector-3@2x.svg";
import vector_4_2x from "./img/vector-4@2x.svg";
import line_5_1_2x from "./img/line-5-1@2x.svg";
import line_6_1_2x from "./img/line-6-1@2x.svg";
import vector_2xp from "./img/vector@2x.png";
import rectangle_46_1_2x from "./img/rectangle-46-1@2x.svg";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import rectangle_49_1_2x from "./img/rectangle-49-1@2x.svg";
import Popup from '../../teacher/popup/popup';
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from '../../../utils';
import { deleteRm } from '../../../redux/actions/rm';
import SideDrawerRm from '../../../UI/sideDrawer/sideDrawerRm';
import { rmIssueDetail, rmNewTicket, resolveIssue } from '../../../crud/rm.crud';
import { teacherDetail, editTeacherDetail } from '../../../crud/teacher.crud';
import { enrolledStudentDetail, editEnrolledStudentDetail } from '../../../crud/student.crud';
import moment from "moment";
import { Button } from "react-bootstrap";
import { WebSocketContext } from '../../../websocket2';
import RmIssueList from '../table_dash/rm_table';
import pic_2 from "../../student/dashboard/img/pic_2.png";

const countryOptions = [
  {
    value: "India",
    label: "India",
  },
  {
    value: "United Kingdom",
    label: "United Kingdom",
  }
]

const gradeOptions = [
  {
    value: "-1",
    label: "Nursery",
  },
  {
    value: "0",
    label: "KG",
  },
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
  {
    value: "6",
    label: "6",
  },
  {
    value: "7",
    label: "7",
  },
  {
    value: "8",
    label: "8",
  },
  {
    value: "9",
    label: "9",
  },
  {
    value: "10",
    label: "10",
  },
  {
    value: "11",
    label: "11",
  },
  {
    value: "12",
    label: "12",
  },
]

const selectStyles = {
  width: "100%",
  paddingLeft: "10px",
  borderRadius: "5px",
  border: "2px solid rgba(217, 83, 79, 0.4)",
  fontSize: "15px",
  fontWeight: 400,
  fontFamily: "'Montserrat', Helvetica",
}


function Relationalmanagerissuedetail(props) {
  useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, [])
    
  const { id } = useParams();
  const user = useSelector((state) => state.rmDetails.rm);
  const [userDetails, setUserDetails] = useState({});
  const [statusChange, setStatusChange] = useState(false);

  const [issue, setIssue] = useState(null);

  useEffect(() => {
    rmIssueDetail(id)
      .then(res => {
        setIssue(res);
      })
      .catch(err => console.log(err))
  }, [statusChange])

  const [show_ticket, setShowTicket] = useState(false);
  const show_ticketDiv = () => {
    setShowTicket(previousState => !previousState);
  };
  let ticketClasses = "Show-div-08";
  if (show_ticket) {
    ticketClasses = "card_1";
  }

  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);

  const sideDrawerToggler = () => {
    setDisplaySideDrawer(previousState => !previousState);
  };

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.rmDetails.rm);
  const handleLogout = () => {
    dispatch(deleteRm(reduxUser.rm_id))
    removeToken();
  }

  const show_editProfile = async () => {
    if (issue.object_id.includes("ES_")) {
      try {
        let details = await enrolledStudentDetail(issue.object_id);
        setUserDetails(details)
        setFirstName(details.first_name)
        setLastName(details.last_name)
        setMobileNumber(details.mobile_number)
        setAddress(details.address)
        setCity(details.city)
        setEmail(details.email)
        setFatherName(details.father_name)
        setFatherNumber(details.father_number)
        setLandmark(details.landmark)
        setLastMarks(details.last_marks)
        setPostalCode(details.postal_code)
        setSchool(details.current_school)
        setStudentState(details.state)
      } catch (error) {
        console.log(error)
      }
      document.getElementById("edit_profile").classList.toggle("edit_profile");
      document.getElementById("bot_mar").classList.toggle("increase_height");
    } else {
        try {
          // get and set teacher details
          let details = await teacherDetail(issue.object_id);
          setUserDetails(details)
          setTcFirstName(details.first_name)
          setTcLastName(details.last_name)
          setTcContact(details.contact_number)
          setTcAddress(details.address)
          setTcCity(details.city)
          setTcState(details.state)
          setTcEmail(details.emailID)
          setTcAlterContact(details.alternate_contact_number)
          setTcLandmark(details.landmark)
          setTcCountry(details.country)
          setTcPostalCode(details.postal_code)
          setTcPancard(details.pancard_number)
          setTcAddressProofType(details.address_proof_type)
          setTcAddressProofNum(details.address_proof_number)
          setTcIdCardNum(details.identity_card_number)
          setIdCard(details.identity_card_type)
        } catch (error) {
          console.log(error)
        }
        document.getElementById("teacher-modal").classList.toggle("edit_profile");
        document.getElementById("bot_mar").classList.toggle("increase_height");
    }
  }

  const [ticketRaisedFor, setTicketRaisedFor] = useState("");
  const [ticketComments, setTicketComments] = useState("");

  const createTicket = () => {
    rmNewTicket({
      raised_by: reduxUser.rm_id,
      raised_for: ticketRaisedFor,
      issue_id: issue.id,
      category: issue.category,
      ticket_status: "In Progress",
      comments: ticketComments,
      date_raised: (new Date()).toISOString()
    })
      .then(res => {
        alert("New ticket raised successfully.")
        setShowTicket(false);
        setTicketComments("");
        setTicketRaisedFor("");
      })
      .catch(err => console.log(err))
  }

  // edit profile state - student
  const [first_name, setFirstName] = useState("")
  const [last_name, setLastName] = useState("")
  const [mobile_number, setMobileNumber] = useState("")
  const [father_name, setFatherName] = useState("")
  const [father_number, setFatherNumber] = useState("")
  const [email, setEmail] = useState("")
  const [country, setCountry] = useState("")
  const [state, setStudentState] = useState("")
  const [postal_code, setPostalCode] = useState("")
  const [city, setCity] = useState("")
  const [address, setAddress] = useState("")
  const [landmark, setLandmark] = useState("")
  const [school, setSchool] = useState("")
  const [lastMarks, setLastMarks] = useState(0)
  const [current_grade, setCurrentGrade] = useState(-2)

  const editUserProfile = () => {
    if (issue.object_id.includes("ES_")) {
     editEnrolledStudentDetail({
      ...userDetails,
      mobile_number,
      father_number,
      country,
      state,
      postal_code,
      city,
      address,
      landmark,
      current_school: school,
      last_marks: parseFloat(lastMarks),
      current_grade: current_grade
    })
      .then(res => {
        alert("Profile changed successfully!")
        show_editProfile();
      }) 
    } else {
      editTeacherDetail({
        ...userDetails,
        address: tcAddress,
        contact_number: tcContact,
        alternate_contact_number: tcAlterContact,
        address_proof_number: tcAddressProofNum,
        address_proof_type: tcAddressProofType,
        postal_code: tcPostalCode,
        landmark: tcLandmark,
        city: tcCity,
        state: tcState,
        country: tcCountry,
      })
        .then(res => {
          alert("Profile changed successfully!")
          show_editProfile();
        }) 
    }
  }

  // edit profile state - teacher
  const [tcFirstName, setTcFirstName] = useState("")
  const [tcLastName, setTcLastName] = useState("")
  const [tcEmail, setTcEmail] = useState("")
  const [tcContact, setTcContact] = useState("")
  const [tcAlterContact, setTcAlterContact] = useState("")
  const [tcCity, setTcCity] = useState("")
  const [tcState, setTcState] = useState("")
  const [tcCountry, setTcCountry] = useState("")
  const [tcAddress, setTcAddress] = useState("")
  const [tcLandmark, setTcLandmark] = useState("")
  const [tcPostalCode, setTcPostalCode] = useState("")
  const [tcPancard, setTcPancard] = useState("")
  const [tcAddressProofType, setTcAddressProofType] = useState("")
  const [tcAddressProofNum, setTcAddressProofNum] = useState("")
  const [tcIdCard, setIdCard] = useState("")
  const [tcIdCardNum, setTcIdCardNum] = useState("")



  const ws = useContext(WebSocketContext);

  const removeGroup = userId => {
    if (ws.socket.readyState === WebSocket.OPEN) {
      ws.sendMessage({
        'type': 'LEAVE_CHANNEL',
        'user': userId,
        'message': 'RM left rm_student specific group.'
      });
    } else {
      ws.socket.onopen = () => {
        ws.sendMessage({
          'type': 'LEAVE_CHANNEL',
          'user': userId,
          'message': 'RM left rm_student specific group.'
        });
      }
    }
  }

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="relational-manager-issue-detail"
      />
      <div className="container-center-horizontal">
        <form
          className="relational-manager-issue-detail screen"
          id="bot_mar"
          name="form3"
          action="form3"
          method="post"
        >
          <div className="group-209-C61RwL-10-1">
            <Link
              to="/rm-dashboard"
              onClick={() => removeGroup(issue.object_id)}
            >
              <MdDashboard
                size={24}
                data-tip="Dashboard"
                color="rgba(179,179,179,1.0)"
              />
              <ReactTooltip />
            </Link>
            <div className="historytoggleoff-24px-1-D0BVcB">
              <div className="group-rAjf8W">
                <Link
                  to="/rm-issue-list"
                  onClick={() => removeGroup(issue.object_id)}
                >
                  {" "}
                  <BiHistory
                    data-tip="Previous Issues"
                    size={24}
                    color="#dc3545"
                  />
                  <ReactTooltip />
                </Link>
              </div>
            </div>
            <div className="school-24px-1-D0BVcB">
              <Link to="/rm-list" onClick={() => removeGroup(issue.object_id)}>
                <MdSchool
                  data-tip="My Students"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
            <div className="help-24px-2-D0BVcB">
              <Link
                to="/rm-ticket-list"
                onClick={() => removeGroup(issue.object_id)}
              >
                <FaRegQuestionCircle
                  data-tip="Tickets"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
            <div className="analytics-24px-4-D0BVcB">
              <Link
                to="/rm-statistics"
                onClick={() => removeGroup(issue.object_id)}
              >
                <FaChartPie
                  data-tip="Analytics"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
            {/* <div className="accountbalance-24px-1-D0BVcB">
              <Link to="/rm-payments" onClick={() => removeGroup(issue.object_id)}><AiTwotoneBank data-tip="Payments" size={24} color="rgba(179,179,179,1.0)" /><ReactTooltip /></Link>
            </div> */}
            <div className="vector-Ayxm2x">
              <Link
                to="/"
                onClick={() => {
                  handleLogout();
                  removeGroup(issue.object_id);
                }}
              >
                <FaPowerOff
                  data-tip="Logout"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
          </div>
          <div className="flex-col-C61RwL-10-1">
            <div className="flex-row-0xy0vn">
              <div className="component-1-GyJUNH">
                <img src={pic_2} style={{ height: "100px" }} />
              </div>
              <div className="notifications-24px-2-1-GyJUNH">
                <div className="vector-40xw7g">
                  <MdNotificationsNone size={28} />
                </div>
                <img className="ellipse-2-RYyMFx" src={ellipse_2_1_2x} />
              </div>
              <div className="group-22-GyJUNH">
                <div className="flex-col-vgli2x">
                  <div className="biba-sobti-h8V8ZR">
                    {reduxUser.first_name + " " + reduxUser.last_name}
                  </div>
                  <div className="bibasobtigmailcom-h8V8ZR">
                    {reduxUser.emailID}
                  </div>
                </div>
                <img className="vector-vgli2x" src={vector_2x} />
              </div>
            </div>
            <div className="flex-row-oV5UrE">
              <div className="flex-col-QkTteQ">
                <div className="overlap-group1-Fjgz1C">
                  <div className="group-47-1UTYTe">
                    {/* <a href="javascript:history.back()" className="align-self-flex-start">
                <div className="arrowback-24px-1-Fjgz1C">
                    <img className="vector-xxy9YX" src={vector_2x} />
                    <img className="vector-H1IJdQ" src={vector_6_2x} /></div>
                </a> */}
                    <a href="javascript:history.back()">
                      <div className="rectangle-40-pjV5k8"></div>
                      <div className="vector-H1IJdQ">
                        <ArrowBackIcon style={{ color: "var(--gray)" }} />
                      </div>
                    </a>
                    <div className="vatsal-agarwal-pjV5k8 montserrat-semi-bold-blue-zodiac-18px">
                      {issue && issue.object_id.toUpperCase()}
                    </div>
                  </div>
                  <div className="sos-1UTYTe">{issue && issue.status}</div>
                </div>
                <div className="overlap-group-Fjgz1C">
                  <div className="rectangle-40-PXwdVf"></div>
                  <div className="issue-text-desc-ibing-the-issue-PXwdVf">
                    {issue && issue.issue_text}
                  </div>
                  <div className="x23022021-PXwdVf montserrat-semi-bold-gray-12px">
                    {issue && moment(issue.date).format("DD/MM/YYYY")}
                  </div>
                  <div className="issue-category-PXwdVf montserrat-semi-bold-gray-12px">
                    {issue && issue.category}
                  </div>
                </div>
                <div className="flex-row-Fjgz1C">
                  <Link to={issue && "/rm-date-calendar/" + issue.object_id}>
                    <Button
                      disabled={
                        issue &&
                        (issue.status === "Resolved" ||
                          issue.status === "Resolved by RM")
                      }
                      className="overlap-group4-A6SpMB"
                      variant="outline-info"
                    >
                      Change Class
                    </Button>
                  </Link>
                  <Button
                    disabled={
                      issue &&
                      (issue.status === "Resolved" ||
                        issue.status === "Resolved by RM")
                    }
                    className="overlap-group5-A6SpMB"
                    variant="outline-info"
                    onClick={show_editProfile}
                  >
                    Edit Profile
                  </Button>
                  <div className="flex-col-A6SpMB">
                    <Button
                      disabled={
                        issue &&
                        (issue.status === "Resolved" ||
                          issue.status === "Resolved by RM")
                      }
                      className="overlap-group2-wvWlxZ"
                      variant="outline-info"
                      onClick={show_ticketDiv}
                    >
                      Create Ticket
                    </Button>
                  </div>
                  <Button
                    disabled={
                      issue &&
                      (issue.status === "Resolved" ||
                        issue.status === "Resolved by RM")
                    }
                    className="overlap-group3-A6SpMB"
                    variant={
                      issue && issue.status === "Resolved"
                        ? "success"
                        : "outline-success"
                    }
                    onClick={() => {
                      resolveIssue({ ...issue, status: "Resolved by RM" });
                      setStatusChange(!statusChange);
                      removeGroup(issue.object_id);
                    }}
                  >
                    {issue && issue.status == "Resolved"
                      ? "Resolved"
                      : "Resolve"}
                  </Button>
                </div>

                {/* <div className="card">
                    <form action="">
                        <div className="row">
                          
                            <div className="col-lg-4">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Category </span>
                                    </div>
                                    <span className="form-control">{curr_ticket && curr_ticket.ticket && curr_ticket.ticket.category} </span> */}
                {/* <input type="text" className="form-control" /> */}
                {/* </div>
                            </div>
                        </div>
                        <div style={{ marginBottom: "40px" }}>
                            <h2 className="mb-3 text">Comments</h2>
                            <textarea disabled rows="10" className="form-control mb-3" style={{ height: "50px" }}>{curr_ticket && curr_ticket.ticket && curr_ticket.ticket.comments}</textarea>
                        </div>

                        <div className="submit">
                            <button type="submit" className="btn btn-danger">Submit</button>
                        </div>
                    </form>
                </div> */}

                <div className={ticketClasses}>
                  <div className="ticket-card-body">
                    <div className="input-group mb-3">
                      <span
                        className="input-group-text"
                        id="basic-addon"
                        style={{ fontFamily: "'Montserrat', Helvetica" }}
                      >
                        Raise for
                      </span>
                      <select
                        className="form-control"
                        onChange={(e) => setTicketRaisedFor(e.target.value)}
                      >
                        <option value="">Select Department</option>
                        <option value="hc">Higher Committee</option>
                        <option value="accounts">Accounts</option>
                      </select>
                    </div>
                    <div className="in">
                      <span
                        className="inpu"
                        style={{ fontSize: "18px" }}
                        style={{ fontFamily: "'Montserrat', Helvetica" }}
                      >
                        Comment
                      </span>
                      <textarea
                        className="form-control"
                        aria-label="With textarea"
                        onChange={(e) => setTicketComments(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="submit_button_1">
                      <Button
                        disabled={ticketRaisedFor == "" || ticketComments == ""}
                        variant="danger"
                        className="btn_1"
                        onClick={createTicket}
                        style={{ fontFamily: "'Montserrat', Helvetica" }}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>

                <div
                  id="edit_profile"
                  className="card edit_profile"
                  style={{ width: "750px" }}
                >
                  <div className="row">
                    <div className="col-sm-4">
                      <input
                        type="text"
                        placeholder="Student's First Name"
                        value={first_name}
                        className="text-muted"
                        disabled
                      />
                    </div>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        placeholder="Student's Last Name"
                        value={last_name}
                        className="text-muted"
                        disabled
                      />
                    </div>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        placeholder="Father's Name"
                        value={father_name}
                        className="text-muted"
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder="Primary Email Address"
                        value={email}
                        disabled
                        className="text-muted input2"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder="Current School"
                        value={school}
                        onChange={(e) => setSchool(e.target.value)}
                        className="text-muted input2"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <input
                        type="text"
                        placeholder="Mobile number"
                        value={mobile_number}
                        onChange={(e) => setMobileNumber(e.target.value)}
                        className="text-muted"
                      />
                    </div>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        placeholder="Parent's Mobile Number"
                        value={father_number}
                        onChange={(e) => setFatherNumber(e.target.value)}
                        className="text-muted input2"
                      />
                    </div>
                    <div className="col-sm-4">
                      <Select
                        className="text-muted"
                        values={[
                          {
                            value:
                              userDetails &&
                              parseInt(userDetails.current_grade),
                            label:
                              userDetails &&
                              parseInt(userDetails.current_grade),
                          },
                        ]}
                        options={gradeOptions}
                        onChange={(value) => setCurrentGrade(value[0].value)}
                        placeholder="Student's Current Grade"
                        searchable
                        color="#ec3545"
                        style={selectStyles}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Select
                        className="text-muted"
                        values={[
                          {
                            value: userDetails && userDetails.country,
                            label: userDetails && userDetails.country,
                          },
                        ]}
                        options={countryOptions}
                        onChange={(value) => setCountry(value[0].value)}
                        placeholder="Select Country"
                        searchable
                        color="#ec3545"
                        style={selectStyles}
                      />
                    </div>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        placeholder="State"
                        value={state}
                        onChange={(e) => setStudentState(e.target.value)}
                        className="text-muted"
                      />
                    </div>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        placeholder="Postal Code"
                        value={postal_code}
                        onChange={(e) => setPostalCode(e.target.value)}
                        className="text-muted"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 smtext">
                      <input
                        type="text"
                        placeholder="Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className="text-muted input2"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder="City"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        className="text-muted input2"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 smtext">
                      <input
                        type="text"
                        placeholder="Landmark"
                        value={landmark}
                        onChange={(e) => setLandmark(e.target.value)}
                        className="text-muted input2"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="number"
                        placeholder="Percentage scored in previous class"
                        value={lastMarks}
                        onChange={(e) => setLastMarks(e.target.value)}
                        className="text-muted input2"
                      />
                    </div>
                  </div>

                  <button
                    type="button"
                    className="btn btn-danger submit"
                    onClick={editUserProfile}
                    style={{
                      fontFamily: "'Montserrat', Helvetica",
                      fontWeight: "600",
                      minHeight: "40px",
                    }}
                  >
                    Submit
                  </button>
                </div>

                <div
                  id="teacher-modal"
                  className="teacher-modal card edit_profile"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder="First Name"
                        value={tcFirstName}
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder="Last Name"
                        value={tcLastName}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <input
                        type="text"
                        placeholder="Email ID"
                        value={tcEmail}
                        disabled
                      />
                    </div>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        placeholder="Contact No"
                        value={tcContact}
                        onChange={(e) => setTcContact(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        placeholder="Alternate Contact No"
                        value={tcAlterContact}
                        onChange={(e) => setTcAlterContact(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <input
                        type="text"
                        placeholder="Address"
                        value={tcAddress}
                        onChange={(e) => setTcAddress(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <input
                        type="text"
                        placeholder="Landmark"
                        value={tcLandmark}
                        onChange={(e) => setTcLandmark(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        placeholder="City"
                        value={tcCity}
                        onChange={(e) => setTcCity(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        placeholder="State"
                        value={tcState}
                        onChange={(e) => setTcState(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <input
                        type="text"
                        placeholder="Country"
                        value={tcCountry}
                        onChange={(e) => setTcCountry(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        placeholder="Postal Code"
                        value={tcPostalCode}
                        onChange={(e) => setTcPostalCode(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        placeholder="Date of Joining"
                        value={userDetails.date_of_joining}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <input
                        type="text"
                        placeholder="Pancard No"
                        value={tcPancard}
                        disabled
                      />
                    </div>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        placeholder="Address Proof Type"
                        value={tcAddressProofType}
                        onChange={(e) => setTcAddressProofType(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        placeholder="Address Proof No"
                        value={tcAddressProofNum}
                        onChange={(e) => setTcAddressProofNum(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder="Identity Card Type"
                        value={tcIdCard}
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        placeholder="Identity Card No"
                        value={tcIdCardNum}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <input type="text" placeholder="Account No" />
                    </div>
                    <div className="col-sm-4">
                      <input type="text" placeholder="Account User Name" />
                    </div>
                    <div className="col-sm-4">
                      <input type="text" placeholder="Bank" />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <input type="text" placeholder="Branch" />
                    </div>
                    <div className="col-sm-4">
                      <input type="text" placeholder="IFSC Code" />
                    </div>
                    <div className="col-sm-4">
                      <input type="text" placeholder="Type of Account" />
                    </div>
                  </div>

                  <button
                    type="button"
                    className="btn btn-danger submit"
                    onClick={editUserProfile}
                    style={{
                      fontFamily: "'Montserrat', Helvetica",
                      fontWeight: "600",
                      minHeight: "40px",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="group-202-QkTteQ">
                <RmIssueList userState={user} />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="container-center-horizontal">
        <form
          className="relational-manager-issue-detail-mobile screen"
          name="form6"
          action="form6"
          method="post"
        >
          <div className="flex-row-C61RwL">
            <div className="flex-col-dXBp89">
              <div className="component-1-0PZcXW">
                <img src={pic_2} style={{ height: "100px", margin: "-8%" }} />
              </div>
              <a href="javascript:history.back()">
                <div className="arrowback-24px-1-0PZcXW">
                  <img className="vector-FTTxjh" src={vector_2xp} />
                  <img className="vector-8OHlvz" src={vector_6_2x} />
                </div>
              </a>
            </div>
            <SideDrawerRm
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />

            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>
          <div className="group-212-C61RwL">
            <div className="group-47-jlZH0x">
              <div className="rectangle-40-43xqCp"></div>
              <div
                className="vatsal-agarwal-43xqCp montserrat-semi-bold-blue-zodiac-18px"
                style={{ fontSize: "11px" }}
              >
                {issue && issue.object_id.toUpperCase()}
              </div>
            </div>
            <div className="sos-jlZH0x-0920" style={{ fontSize: "10px" }}>
              {issue && issue.status}
            </div>
          </div>
          <div className="group-213-C61RwL">
            <div className="rectangle-40-3oLdax"></div>
            <div className="issue-text-desc-ibing-the-issue-3oLdax">
              {issue && issue.issue_text}
            </div>
            <div className="x23022021-3oLdax montserrat-semi-bold-gray-12px">
              {issue && moment(issue.date).format("DD/MM/YYYY")}
            </div>
            <div className="issue-category-3oLdax montserrat-semi-bold-gray-12px">
              {issue && issue.category}
            </div>
          </div>
          <div className="flex-row-VMr6Om">
            <Link to={issue && "/rm-date-calendar/" + issue.object_id}>
              <Button
                disabled={
                  issue &&
                  (issue.status === "Resolved" ||
                    issue.status === "Resolved by RM")
                }
                className="overlap-group-BdpSxK"
                variant="outline-info"
              >
                Change Class
              </Button>
            </Link>
            <Button
              disabled={
                issue &&
                (issue.status === "Resolved" ||
                  issue.status === "Resolved by RM")
              }
              className="overlap-group1-BdpSxK"
              variant="outline-info"
              onClick={show_editProfile}
            >
              Edit Profile
            </Button>
          </div>
          <div className="flex-row-mzXdH9">
            <Button
              disabled={
                issue &&
                (issue.status === "Resolved" ||
                  issue.status === "Resolved by RM")
              }
              className="overlap-group2-NwNIwt"
              variant="outline-info"
              onClick={show_ticketDiv}
            >
              Create Ticket
            </Button>
            <Button
              disabled={
                issue &&
                (issue.status === "Resolved" ||
                  issue.status === "Resolved by RM")
              }
              className="overlap-group3-NwNIwt"
              variant={
                issue && issue.status === "Resolved"
                  ? "success"
                  : "outline-success"
              }
              onClick={() => {
                resolveIssue({ ...issue, status: "Resolved by RM" });
                setStatusChange(!statusChange);
              }}
            >
              {issue &&
              (issue.status === "Resolved" || issue.status === "Resolved by RM")
                ? "Resolved"
                : "Resolve"}
            </Button>
          </div>
          <div className="group-210-C61RwL">
            <RmIssueList userState={user} />
          </div>
        </form>
      </div>
      <Popup issueUser={issue && issue.object_id} />
    </>
  );
}

export default Relationalmanagerissuedetail;