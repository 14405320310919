import axios from "axios";
import { toAPIURL } from "../utils";

export async function AccountStudent() {
  try {
    const res = await axios.get(toAPIURL("api/all-incoming/"));
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function AccountEmployee() {
  try {
    const res = await axios.get(toAPIURL("api/all-outgoing/"));
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function accountStudentTotal(start, end) {
  try {
    const res = await axios.get(toAPIURL(`api/total-incoming/${start}/${end}`));
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function accountEmployeeTotal(start, end, employee) {
  try {
    const res = await axios.get(toAPIURL(`api/total-outgoing/${start}/${end}/${employee}`));
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function getEmployeePayment(employee, start, end) {
  console.log(employee, start, end);
  try {
    const res = await axios.get(toAPIURL(`api/salary-calculation/${employee}/${start}/${end}`));
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function recordNewPayment(details) {
  try {
    const res = await axios.put(
      toAPIURL(`api/all-outgoing/`),
      details
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}