import React, { useState, useEffect, useLayoutEffect } from "react";
import "./teacher_quiz_detail_style.css";
import { NavLink, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Button } from "react-bootstrap";
import pic_2 from "../../student/dashboard/img/pic_2.png";
import { MdSchool } from "react-icons/md";
import { RiQuestionnaireFill } from "react-icons/ri";
import vector_2x from "./img/vector@2x.svg";
import vector_2xp from "./img/vector@2x.png";
import vector_6_2x from "./img/vector-6@2x.svg";
import line_8_1x from "./img/line-8@1x.svg";
import line_8_1_2x from "./img/line-8-1@2x.svg";
import Popup from "../popup/popup";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteTeacher } from "../../../redux/actions/teacher";
import SideDrawer from "../../../UI/sideDrawer/sideDrawer";
import { studentQuizDetails, submitQuiz } from "../../../crud/student.crud";

import CalenderStatus from "../dashboard/CalenderComponent/calanderComponent";

import DashboardIcon from "@material-ui/icons/Dashboard";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SchoolIcon from "@material-ui/icons/School";
import DescriptionIcon from "@material-ui/icons/Description";
import HelpIcon from "@material-ui/icons/Help";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import NotesIcon from "@material-ui/icons/Notes";

function Teacherquizdetail(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const user = useSelector((state) => state.teacherDetails.teacher);
  const [quiz, setQuiz] = useState(null);
  const [quizMarks, setQuizMarks] = useState(0);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [graded, setGraded] = useState(false);

  useEffect(() => {
    studentQuizDetails(id)
      .then((res) => {
        setQuiz(res);
      })
      .catch((err) => console.log(err));
  }, [graded]);

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteTeacher(reduxUser.teacher_id));
    removeToken();
  };

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  const gradeQuiz = () => {
    if (parseInt(quizMarks) > quiz.quiz_group.total_marks) {
      alert("Cannot give more than total marks.");
    } else {
      submitQuiz({
        ...quiz.student_response,
        marks: quizMarks,
        status: "Graded",
      })
        .then((res) => {
          alert("Quiz graded successfully.");
          setGraded(!graded);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="teacher-quiz-detail"
      />
      <div className="container-center-horizontal">
        <form
          className="teacher-quiz-detail screen"
          name="form3"
          action="form3"
          method="post"
        >
          <div className="sider-teacher-general-div">
            <div className="sider-teacher-in-general-80">
              <div style={{ marginTop: "120px" }} />
              <NavLink to="/teacher-dashboard" exact>
                <DashboardIcon
                  data-tip="Dashboard"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-date-calendar" exact>
                <EventIcon data-tip="Scheduler" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-previous-classes">
                <ScheduleIcon
                  data-tip="Previous Class"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-students">
                <SchoolIcon
                  data-tip="Enrolled Student"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-curriculum-list">
                <DescriptionIcon
                  data-tip="Curriculum"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-doubt-list">
                <HelpIcon data-tip="Doubts" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-analytics">
                <AssessmentIcon
                  data-tip="Analytics"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-assignments">
                <AssignmentIcon
                  data-tip="Assignments"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-quizzes">
                <LibraryBooksIcon
                  data-tip="Quizzes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-lecture-notes">
                <NotesIcon
                  data-tip="Lecture Notes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              {/* <NavLink to="/teacher-payments"><AccountBalanceIcon data-tip="Payment" className="icon-in-hc-color" /><ReactTooltip /></NavLink> */}
              <div style={{ marginTop: "10px" }} />
              <NavLink to="/" onClick={() => handleLogout()}>
                {" "}
                <PowerSettingsNewIcon
                  data-tip="Logout"
                  className="icon-in-hc-color"
                />{" "}
              </NavLink>
              <div style={{ marginTop: "1px" }} />
            </div>
          </div>
          <div className="flex-col-C61RwL">
            <div className="flex-row-0xy0vn">
              <div className="component-1-GyJUNH">
                <img
                  alt="image"
                  src={pic_2}
                  style={{ height: "115px", marginTop: "-8%" }}
                />
              </div>

              <div className="group-22-GyJUNH">
                <div className="flex-col-vgli2x">
                  <div className="biba-sobti-h8V8ZR">
                    {user.first_name + " " + user.last_name}
                  </div>
                  <div className="bibasobtigmailcom-h8V8ZR">{user.emailID}</div>
                </div>
                <img className="vector-vgli2x" src={vector_2x} />
              </div>
            </div>
            <div className="flex-row-oV5UrE">
              <div className="flex-col-QkTteQ">
                <a href="javascript:history.back()">
                  <div className="arrowback-24px-1-Fjgz1C">
                    <img className="vector-xxy9YX" src={vector_2xp} />
                    <img className="vector-H1IJdQ" src={vector_6_2x} />
                  </div>
                </a>
                <div className="overlap-group1-Fjgz1C">
                  <div className="group-47-1UTYTe">
                    <div className="rectangle-40-pjV5k8"></div>
                    <div className="vatsal-agarwal-pjV5k8 montserrat-semi-bold-blue-zodiac-18px">
                      {quiz && quiz.student_response.enrolled_student_id}
                    </div>
                  </div>
                  <div className="group-167-1UTYTe">
                    <div className="component-29-DBfxBL"></div>
                    <div className="vector-DBfxBL">
                      <MdSchool size={24} color="white" />
                    </div>
                  </div>
                </div>
                <div className="overlap-group-Fjgz1C">
                  <div className="group-46-PXwdVf">
                    <div className="rectangle-40-eRDTVr"></div>
                    <div className="component-29-eRDTVr">
                      <div className="assignment-24px-3-wx3HpW">
                        <div className="vector-Z4cObr">
                          {" "}
                          <RiQuestionnaireFill size={22} color="white" />{" "}
                        </div>
                        {/* <img className="vector-KeQs0d" src="img/vector@2x.png" />
                      <img className="vector-Z4cObr" src="img/vector-4@2x.svg" /> */}
                      </div>
                    </div>
                    <div className="quiz-1-eRDTVr montserrat-semi-bold-blue-zodiac-18px">
                      {quiz && quiz.quiz_group.quiz_name}
                    </div>
                    <div className="grade-quiz-eRDTVr">Grade quiz</div>
                    <div className="x10-marks-eRDTVr">
                      {quiz && quiz.quiz_group.total_marks} Marks
                    </div>
                    <div className="mathematics-dr-yashna-sharma-eRDTVr">
                      {quiz && quiz.meta.subject_id} (
                      {quiz && quiz.meta.teacher_id})
                    </div>
                    <div className="ungraded-eRDTVr">
                      {quiz && quiz.student_response.status}
                    </div>
                    {/* <a href="javascript:SubmitForm('form3')"><div className="view-quiz-eRDTVr">View Quiz</div></a> */}
                    <a href={quiz && quiz.quiz_group.quiz_url} target="_blank">
                      <Button
                        variant="outline-danger"
                        className="view-quiz-eRDTVr"
                        style={{
                          fontFamily: "'Montserrat', Helvetica",
                          fontSize: 10,
                          fontWeight: 600,
                        }}
                      >
                        View Quiz
                      </Button>{" "}
                    </a>
                    <a
                      href={quiz && quiz.student_response.solution_url}
                      target="_blank"
                    >
                      <Button
                        disabled={
                          quiz && quiz.student_response.status == "Assigned"
                        }
                        variant="danger"
                        className="rectangle-44-eRDTVr"
                        style={{
                          fontFamily: "'Montserrat', Helvetica",
                          fontSize: 10,
                          fontWeight: 600,
                        }}
                      >
                        View Submission
                      </Button>
                    </a>
                    <Button
                      disabled={quizMarks == ""}
                      variant="danger"
                      className="rectangle-43-eRDTVr"
                      style={{
                        fontFamily: "'Montserrat', Helvetica",
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                      onClick={gradeQuiz}
                    >
                      Submit
                    </Button>
                  </div>
                  <img className="line-8-PXwdVf" src={line_8_1x} />
                  <input
                    className="component-18-PXwdVf border-1px-blue-zodiac"
                    name="6611742"
                    placeholder="Enter Marks"
                    type="number"
                    onChange={(e) => setQuizMarks(e.target.value)}
                  />
                </div>
              </div>
              <div className="group-114-QkTteQ">
                <div className="group-113-eLYKxH">
                  <CalenderStatus />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="container-center-horizontal">
        <form
          className="teacher-quiz-detail-mobile screen"
          name="form6"
          action="form6"
          method="post"
        >
          <div className="flex-row-C61RwL">
            <div className="component-1-dXBp89">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "90px", marginTop: "-8%" }}
              />
            </div>

            {/* This is the Navbar */}
            <SideDrawer
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />

            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>
          <div className="overlap-group2-C61RwL">
            <a href="javascript:history.back()">
              <div className="arrowback-24px-1-Fjgz1C">
                <img className="vector-xxy9YX" src={vector_2xp} />
                <img className="vector-H1IJdQ" src={vector_6_2x} />
              </div>
            </a>
            <div className="group-46-4a9k2Y">
              <div className="rectangle-40-6x6k20"></div>
              <div className="vatsal-agarwal-6x6k20">
                {quiz && quiz.student_response.enrolled_student_id}
              </div>
            </div>
            <div className="group-184-4a9k2Y">
              <div className="component-29-3nzxAx"></div>
              <div className="vector-3nzxAx">
                <MdSchool size={16} color="white" />
              </div>
            </div>
          </div>
          <div className="overlap-group1-C61RwL">
            <div className="group-156-RH0WJ5">
              <div className="group-155-bB7yjR">
                <div className="rectangle-40-VBdSDa"></div>
                <div className="quiz-1-VBdSDa">
                  {quiz && quiz.quiz_group.quiz_name}
                </div>
                <div className="grade-quiz-VBdSDa">
                  Grade
                  <br />
                  quiz
                </div>
                <div className="x10-marks-VBdSDa">
                  {quiz && quiz.quiz_group.total_marks} Marks
                </div>
                <div className="mathematics-dr-yashna-sharma-VBdSDa">
                  {quiz && quiz.meta.subject_id} ({quiz && quiz.meta.teacher_id}
                  )
                </div>
                <div className="submitted-VBdSDa">
                  {quiz && quiz.student_response.status}
                </div>
                {/* <a href="javascript:SubmitForm('form6')">
                  <div className="view-quiz-VBdSDa">View Quiz</div></a> */}
                <a href={quiz && quiz.quiz_group.quiz_url} target="_blank">
                  <Button
                    variant="outline-danger"
                    className="view-quiz-VBdSDa"
                    style={{
                      fontFamily: "'Montserrat', Helvetica",
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                  >
                    View Quiz
                  </Button>
                </a>
                <a
                  href={quiz && quiz.student_response.solution_url}
                  target="_blank"
                >
                  <Button
                    variant="danger"
                    className="rectangle-44-VBdSDa"
                    style={{
                      fontFamily: "'Montserrat', Helvetica",
                      fontSize: 10,
                      fontWeight: 600,
                    }}
                  >
                    View Submission
                  </Button>
                </a>
                <Button
                  disabled={quizMarks == ""}
                  variant="danger"
                  className="rectangle-43-VBdSDa"
                  style={{
                    fontFamily: "'Montserrat', Helvetica",
                    fontSize: 12,
                    fontWeight: 600,
                  }}
                  onClick={gradeQuiz}
                >
                  Submit
                </Button>
              </div>
              <img className="line-8-bB7yjR" src={line_8_1_2x} />
            </div>
            <div className="group-177-RH0WJ5">
              <div className="component-29-r38EO5"></div>
              <div className="group-r38EO5">
                <div className="group-EXxxX0">
                  <div className="vector-LiCmgR">
                    {" "}
                    <RiQuestionnaireFill size={20} color="white" />{" "}
                  </div>
                  {/* <img className="vector-QkzUeN" src="img/vector-35@2x.svg" />
                <img className="vector-LiCmgR" src="img/vector-36@2x.svg" /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-row-VMr6Om">
            <CalenderStatus />
          </div>
        </form>
      </div>
      <Popup />
    </>
  );
}

export default Teacherquizdetail;
