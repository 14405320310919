import React, { useLayoutEffect, useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import LoginModal from "../../../components/Login_modal/loginModal";
import CustomNav from "../../../components/header/Navbar";
import Background from "./bgcanvas.png";
import Footer from "../../../components/Footer";
import pic_5 from "../../student/dashboard/img/pic_5.png";
import Popup2 from "../../non_auth/new_popup/popup2";
import "./common.css";
Modal.setAppElement("#root");

function Dsssb() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section style={{ width: "100%" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "80%", marginTop: "5%" }}>
            <div
              style={{
                color: "#dc3545",
                fontSize: 24,
                fontWeight: 700,
                lineHeight: "1.6",
                textAlign: "left",
              }}
            >
              DSSSB Recruitment 2023
            </div>

            <div style={{ marginBottom: "2%" }}>
              <p
                style={{
                  fontSize: 16,
                  marginTop: "1%",
                  fontWeight: 500,
                  lineHeight: "1.6",
                }}
              >
                The Delhi Subordinate Services Selection Board (DSSSB) is
                responsible for conducting the annual state-level recruitment
                exam in Delhi. This exam aims to select qualified candidates for
                a range of important government positions, including TGT, PGT,
                Assistant Teacher (Primary and Nursery), Junior Secretariat
                Assistant (LDC), Counselor, Patwari, Head Clerk, and various
                others.
              </p>
            </div>

            <div
              style={{
                marginBottom: "2%",
                borderStyle: "solid",
                borderWidth: 5,
                borderColor: "#dc3545",
                padding: 10,
                borderRadius: 8,
              }}
            >
              <h2
                style={{
                  color: "#56A5F5",
                  fontWeight: 600,
                  fontSize: 20,
                  marginTop: "1%",
                }}
              >
                About ‘TRG’ DSSSB 3 Months Programme
              </h2>
              <p
                style={{
                  fontSize: 16,
                  marginTop: "1%",
                  fontWeight: 500,
                  lineHeight: "1.6",
                }}
              >
                Since 2021, The Right Guru (TRG) has been a prominent player in
                the field of quality education, particularly for competitive
                exams like the SSC CGL, CHSL, MTS, CUET, DSSSB, CLAT, and also
                catering to CBSE/ICSE and other board examinations. With a
                dedicated team and a commitment to excellence, TRG understands
                the unique requirements of aspirants striving to excel in these
                competitive exams and contribute to their career growth.
                <br />
                <br />
                Introducing the TRG DSSSB 6-months Classroom Programme, our aim
                is to address the specific needs of aspirants preparing for the
                esteemed DSSSB examination. This programme is meticulously
                designed to equip students with a strong foundational knowledge
                and advanced skills, ensuring their readiness for all stages of
                the DSSSB examination, from the initial tier to subsequent
                rounds and skill tests.
                <br />
                <br /> Our pedagogical approach is thoughtfully tailored to
                facilitate a progressive learning journey, commencing with
                fundamental concepts and advancing to more complex topics,
                closely aligned with the DSSSB exam syllabus. Through a
                well-structured schedule, we ensure timely syllabus coverage and
                allocate ample time for comprehensive revision. Our programme
                not only emphasizes academic excellence but also instills
                discipline, dedication, and resilience in our students.
              </p>
            </div>

            <div
              style={{
                marginTop: "2%",
                borderStyle: "solid",
                borderWidth: "5px",
                borderColor: "#dc3545",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "1%",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 5 }}>
                    Date of Commencement
                  </div>
                  <div style={{ fontWeight: 600 }}>1 January 2024</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>Mode</div>
                  <div style={{ fontWeight: 600 }}>Offline/Online</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>
                    Duration
                  </div>
                  <div style={{ fontWeight: 600 }}>3 Months</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>Fee</div>
                  <div style={{ fontWeight: 600 }}>Rs. 24,999/- (3 Month)</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>
                    Admission
                  </div>
                  <div style={{ fontWeight: 600 }}>Open</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>
                    Class Timing
                  </div>
                  <div style={{ fontWeight: 600 }}>8:00 AM to 8: PM PM</div>
                </div>
              </div>

              <div
                style={{ display: "flex", marginLeft: 20, marginRight: 20 }}
                className="addrs"
              >
                <div
                  style={{
                    borderStyle: "solid",
                    borderWidth: 3,
                    borderColor: "#000",
                    padding: 20,
                    width: "60%",
                    marginTop: "2%",
                    marginBottom: "2%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "-42px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#1d74ca",
                        width: 200,
                        padding: 8,
                        borderStyle: "solid",
                        borderWidth: 3,
                        borderColor: "#000",
                      }}
                    >
                      <h2
                        style={{
                          color: "#fff",
                          fontWeight: 600,
                          fontSize: 20,

                          textAlign: "center",
                        }}
                      >
                        Location
                      </h2>
                    </div>
                  </div>
                  <br />
                  <div style={{ fontWeight: 600 }}>
                    311 Vardhman Sunder Plaza, Plot No. 12 MLU Sec - 12 Dwarka,
                    New Delhi 110078
                  </div>
                  <br />
                  <div style={{ fontWeight: 600 }}>
                    42 Second Floor, Omaxe Galleria, Sector 14, Jhajjar Road,
                    Bahadurgarh, Haryana - 124-507
                  </div>
                </div>

                <div
                  style={{
                    borderStyle: "solid",
                    borderWidth: 3,
                    borderColor: "#000",
                    padding: 20,

                    marginTop: "2%",
                    marginBottom: "2%",
                    marginLeft: "5%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "-42px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#1d74ca",
                        width: 150,
                        padding: 8,
                        borderStyle: "solid",
                        borderWidth: 3,
                        borderColor: "#000",
                      }}
                    >
                      <h2
                        style={{
                          color: "#fff",
                          fontWeight: 600,
                          fontSize: 20,

                          textAlign: "center",
                        }}
                      >
                        Contact Us
                      </h2>
                    </div>
                  </div>
                  <br />
                  <div style={{ fontWeight: 600 }}>9570-424242</div>
                  <br />
                  <div style={{ fontWeight: 600 }}>9647-787878</div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "2%",
                  }}
                >
                  <Link to="/trial-student-form">
                    <div className="book-btn">Book Free Trial</div>
                  </Link>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "2%",
                    marginLeft: 10,
                  }}
                >
                  <a href="tel:+919570424242">
                    <div className="cons-btn">Call for Free Consultation</div>
                  </a>
                </div>
              </div>
            </div>
            <div style={{ marginBottom: "2%" }}>
              <h2
                style={{
                  color: "#56A5F5",
                  fontWeight: 600,
                  fontSize: 20,
                  marginTop: "3%",
                }}
              >
                What ‘TRG’ Provide
              </h2>
              <p
                style={{
                  fontSize: 16,
                  marginTop: "1%",
                  fontWeight: 500,
                  lineHeight: "1.6",
                }}
              >
                The TRG DSSSB 3-months Programme is meticulously designed to
                provide aspiring candidates with a comprehensive and highly
                effective preparation experience for the DSSSB examination. We
                also cater to related examinations within the DSSSB spectrum.
                Herein, we highlight the distinctive features that underscore
                the excellence of our programme:
              </p>
              <div style={{ marginTop: "2%" }}>
                <table style={{ width: "100%" }}>
                  <tr>
                    <td className="heade">Duration and Frequency</td>
                    <td>
                      Each class in our programme is scheduled for 2 hours, and
                      classes are conducted every 5 days a week, ensuring
                      consistent and regular learning.
                    </td>
                  </tr>
                  <tr>
                    <td className="heade">Experienced Instructors</td>
                    <td>
                      Our programme features teaching delivered by subject
                      specialists who are well-versed in the DSSSB exam
                      syllabus, ensuring students receive expert guidance and
                      instruction.
                    </td>
                  </tr>
                  <tr>
                    <td className="heade">Comprehensive Coverage</td>
                    <td>
                      The programme includes 60 Classroom Lectures, ensuring
                      that all relevant topics are thoroughly covered.
                    </td>
                  </tr>
                  <tr>
                    <td className="heade">Study Material</td>
                    <td>
                      To aid in focused study, we provide a set of Books that
                      comprehensively cover General Studies, Mathematics,
                      English subjects for both Tier I Prelims to the Tier II
                      Descriptive Paper and the subsequent skill tests syllabus.
                    </td>
                  </tr>
                  <tr>
                    <td className="heade">Current Affairs</td>
                    <td>
                      Students receive monthly Current Affairs Magazines, either
                      in hard copy or PDF format, to stay updated with the
                      latest events and developments.
                    </td>
                  </tr>
                  <tr>
                    <td className="heade">Test Series</td>
                    <td>
                      We offer Tests Series for both Tier I Prelims to the Tier
                      II Descriptive Paper and current affairs, enabling
                      students to practice and assess their progress.
                    </td>
                  </tr>
                  <tr>
                    <td className="heade">Student Portal Access</td>
                    <td>
                      Our student portal provides access to classes, objective
                      and descriptive tests, supplemental study materials, daily
                      current affairs, articles, class schedules, and
                      announcements, all in one place.
                    </td>
                  </tr>
                  <tr>
                    <td className="heade">Mentorship</td>
                    <td>
                      For those who qualify the Tier I Prelims Test, we offer
                      mentorship
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div style={{ marginBottom: "2%" }}>
              <h2
                style={{
                  color: "#56A5F5",
                  fontWeight: 600,
                  fontSize: 20,
                  marginTop: "3%",
                }}
              >
                Why Choose the TRG DSSSB 3-months Programme?
              </h2>
              <p
                style={{
                  fontSize: 16,
                  marginTop: "1%",
                  fontWeight: 500,
                  lineHeight: "1.6",
                }}
              >
                Candidates aiming to excel in the DSSSB examination and similar
                competitive tests should contemplate our programme for the
                following compelling reasons:
              </p>

              <div style={{ marginTop: "2%" }}>
                <table style={{ width: "100%" }}>
                  <tr>
                    <td className="heade">Clear Understanding of Syllabus</td>
                    <td>
                      Our programme ensures aspirants thoroughly understand the
                      DSSSB syllabus, leaving no room for confusion.
                    </td>
                  </tr>
                  <tr>
                    <td className="heade">Efficient Preparation</td>
                    <td>
                      With a well-structured curriculum and regular classes,
                      students can efficiently prepare for both Tier I Prelims
                      and Tier II Descriptive Paper.
                    </td>
                  </tr>
                  <tr>
                    <td className="heade">Strong Foundation</td>
                    <td>
                      We focus on building a solid foundation in essential
                      subjects, making it easier for aspirants to grasp advanced
                      concepts.
                    </td>
                  </tr>
                  <tr>
                    <td className="heade">Discipline and Dedication</td>
                    <td>
                      Our programme instills discipline and dedication through
                      regular assessments and a rigorous schedule.
                    </td>
                  </tr>
                  <tr>
                    <td className="heade">Expert Guidance</td>
                    <td>
                      Our experienced instructors guide students at every step
                      of their preparation journey, from the basics to advanced
                      topics.
                    </td>
                  </tr>
                  <tr>
                    <td className="heade">Determination</td>
                    <td>
                      We motivate students to stay determined and committed to
                      their goals, even during challenging times.
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div style={{ marginBottom: "2%" }}>
              <h2
                style={{
                  color: "#56A5F5",
                  fontWeight: 600,
                  fontSize: 20,
                  marginTop: "3%",
                }}
              >
                Who Should Join the TRG DSSSB 3-months Programme?
              </h2>
              <p
                style={{
                  fontSize: 16,
                  marginTop: "1%",
                  fontWeight: 500,
                  lineHeight: "1.6",
                }}
              >
                <span style={{ fontWeight: 600 }}>
                  Our programme is tailored for:
                </span>
                <br />
                <br />
                Graduates and post-graduates preparing for the DSSSB exam in the
                near future. Aspirants focused on establishing a solid footing
                for Prelims and Mains. Individuals striving to enhance their
                prospects for success in the DSSSB exam.
              </p>
            </div>
            <div style={{ marginBottom: "2%" }}>
              <h2
                style={{
                  color: "#56A5F5",
                  fontWeight: 600,
                  fontSize: 20,
                  marginTop: "3%",
                }}
              >
                How will the TRG DSSSB 3-months Programme Help You?
              </h2>
              <p
                style={{
                  fontSize: 16,
                  marginTop: "1%",
                  fontWeight: 500,
                  lineHeight: "1.6",
                }}
              >
                Ensuring timely and comprehensive syllabus coverage.
                <br /> Providing high-quality lectures delivered by expert
                teachers.
                <br /> Offering focused study material in an easy-to-understand
                language.
                <br /> Cultivating the 4Ds: Desire, Dedication, Discipline, and
                Determination, essential for success in competitive exams.
                <br />
                <br />
                Every year, the DSSSB announces a multitude of job vacancies
                across different posts through official notifications. Aspirants
                looking for detailed information about the DSSSB Recruitment
                2023 can find essential details about the application process,
                exam date, admit card, answer key, syllabus, result, and more in
                the following article.
              </p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginBottom: "5%",
              }}
            >
              <a
                target="_blank"
                href="https://therightguru-website.s3.ap-south-1.amazonaws.com/DSSSB+1.pdf"
              >
                <div className="book-btn">Know more...</div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section>
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default Dsssb;
