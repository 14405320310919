import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import ReactPlayer from "react-player/lazy";
import myVideo from "./intro_trg.mp4";
import "./welcome.css";

const videoStyles = {
  border: "10px solid #dc3545",
};

function Welcome2() {
  return (
    <Container fluid style={{ maxHeight: "600px", maxWidth: "85%" }}>
      <Row
        className="d-flex flex-md-row justify-content-center"
        style={{ marginTop: "50px" }}
      >
        <Col
          md={6}
          className="d-flex justify-content-center align-items-center"
        >
          <ReactPlayer
            loop={true}
            playing={false}
            controls={true}
            volume={0.6}
            url={myVideo}
            width="100%"
            height="100%"
            style={videoStyles}
          />
        </Col>
      </Row>
      
    </Container>
  );
}

export default Welcome2;
