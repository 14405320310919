import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Button } from "react-bootstrap";

import AatishYadav from "../../non_auth/toppers_photo/AatishYadav.png";
import Anshu from "../../non_auth/toppers_photo/Anshu.png";
import KashishKhantwal from "../../non_auth/toppers_photo/KashishKhantwal.png";
import Nidhi from "../../non_auth/toppers_photo/Nidhi.png";
import Nitin from "../../non_auth/toppers_photo/Nitin.png";
import RishabhTanwar from "../../non_auth/toppers_photo/RishabhTanwar.png";
import Swastika from "../../non_auth/toppers_photo/Swastika.png";
import SumitKumar from "../../non_auth/toppers_photo/SumitKumar.png";
import Saksham from "../../non_auth/toppers_photo/Saksham.png";
import ShresthDagar from "../../non_auth/toppers_photo/ShresthDagar.png";

function OurToppers() {
  return (
    <Container fluid style={{ width: "80%" }}>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            marginTop: "2%",
          }}
        >
          {/* Photo */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <img
              style={{
                width: "150px",
                borderRadius: "50%",
                border: "5px solid #FFD700",
                maxWidth: "100%",
              }}
              src={Anshu}
            />
            <p
              style={{ marginTop: "10px", color: "#FFFFFF", fontWeight: "600" }}
            >
              Anshu (12th)
            </p>
            <p
              style={{ marginTop: "10px", color: "#FFFFFF", fontWeight: "600" }}
            >
              98% - (2022-23)
            </p>
          </div>
          {/* Photo */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <img
              style={{
                width: "150px",
                borderRadius: "50%",
                border: "5px solid #FFD700",
                maxWidth: "100%",
              }}
              src={KashishKhantwal}
            />
            <p
              style={{ marginTop: "10px", color: "#FFFFFF", fontWeight: "600" }}
            >
              Kashish Khantwal (12th)
            </p>
            <p
              style={{ marginTop: "10px", color: "#FFFFFF", fontWeight: "600" }}
            >
              91% - (2022-23)
            </p>
          </div>
          {/* Photo */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <img
              style={{
                width: "150px",
                borderRadius: "50%",
                border: "5px solid #FFD700",
                maxWidth: "100%",
              }}
              src={RishabhTanwar}
            />
            <p
              style={{ marginTop: "10px", color: "#FFFFFF", fontWeight: "600" }}
            >
              Rishabh Tanwar (12th)
            </p>
            <p
              style={{ marginTop: "10px", color: "#FFFFFF", fontWeight: "600" }}
            >
              84% - (2022-23)
            </p>
          </div>
          {/* Photo */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <img
              style={{
                width: "150px",
                borderRadius: "50%",
                border: "5px solid #FFD700",
                maxWidth: "100%",
              }}
              src={SumitKumar}
            />
            <p
              style={{ marginTop: "10px", color: "#FFFFFF", fontWeight: "600" }}
            >
              Sumit Kumar (12th)
            </p>
            <p
              style={{ marginTop: "10px", color: "#FFFFFF", fontWeight: "600" }}
            >
              82% - (2022-23)
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <img
              style={{
                width: "150px",
                borderRadius: "50%",
                border: "5px solid #FFD700",
                maxWidth: "100%",
              }}
              src={Nidhi}
            />
            <p
              style={{ marginTop: "10px", color: "#FFFFFF", fontWeight: "600" }}
            >
              Nidhi (12th)
            </p>
            <p
              style={{ marginTop: "10px", color: "#FFFFFF", fontWeight: "600" }}
            >
              71% - (2022-23)
            </p>
          </div>
        </div>
      </Row>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            marginTop: "2%",
          }}
        >
          {/* Photo */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <img
              style={{
                width: "150px",
                borderRadius: "50%",
                border: "5px solid #FFD700",
                maxWidth: "100%",
              }}
              src={Nitin}
            />
            <p
              style={{ marginTop: "10px", color: "#FFFFFF", fontWeight: "600" }}
            >
              Nitin (10th)
            </p>
            <p
              style={{ marginTop: "10px", color: "#FFFFFF", fontWeight: "600" }}
            >
              95% - (2022-23)
            </p>
          </div>
          {/* Photo */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <img
              style={{
                width: "150px",
                borderRadius: "50%",
                border: "5px solid #FFD700",
                maxWidth: "100%",
              }}
              src={ShresthDagar}
            />
            <p
              style={{ marginTop: "10px", color: "#FFFFFF", fontWeight: "600" }}
            >
              Shresth Dagar (10th)
            </p>
            <p
              style={{ marginTop: "10px", color: "#FFFFFF", fontWeight: "600" }}
            >
              94% - (2022-23)
            </p>
          </div>
          {/* Photo */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <img
              style={{
                width: "150px",
                borderRadius: "50%",
                border: "5px solid #FFD700",
                maxWidth: "100%",
              }}
              src={Saksham}
            />
            <p
              style={{ marginTop: "10px", color: "#FFFFFF", fontWeight: "600" }}
            >
              Saksham (10th)
            </p>
            <p
              style={{ marginTop: "10px", color: "#FFFFFF", fontWeight: "600" }}
            >
              92% - (2022-23)
            </p>
          </div>
          {/* Photo */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <img
              style={{
                width: "150px",
                borderRadius: "50%",
                border: "5px solid #FFD700",
                maxWidth: "100%",
              }}
              src={Swastika}
            />
            <p
              style={{ marginTop: "10px", color: "#FFFFFF", fontWeight: "600" }}
            >
              Swastika (10th)
            </p>
            <p
              style={{ marginTop: "10px", color: "#FFFFFF", fontWeight: "600" }}
            >
              88% - (2022-23)
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            <img
              style={{
                width: "150px",
                borderRadius: "50%",
                border: "5px solid #FFD700",
                maxWidth: "100%",
              }}
              src={AatishYadav}
            />
            <p
              style={{ marginTop: "10px", color: "#FFFFFF", fontWeight: "600" }}
            >
              Aatish Yadav (10th)
            </p>
            <p
              style={{ marginTop: "10px", color: "#FFFFFF", fontWeight: "600" }}
            >
              80% - (2022-23)
            </p>
          </div>
        </div>
      </Row>
      {/* <Row className="justify-content-center" style={{ marginTop: "5%" }}>
        <Button
          variant="danger"
          style={{
            fontFamily: "'Montserrat', Arial, Helvetica, sans-serif",
            fontWeight: "600",
            fontSize: "14px",
            padding: "14px",
            width: "144px",
            border: "2px solid #eee",
          }}
        >
          <Link to="/trg-toppers">
            <div style={{ color: "white" }}>SEE ALL</div>
          </Link>
        </Button>
      </Row> */}
    </Container>
  );
}

export default OurToppers;
