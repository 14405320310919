import axios from "axios";
import { toAPIURL } from "../../../utils";
import { GET_STUDENT, DELETE_STUDENT } from "./types";

// GET STUDENT
export const getStudent = (user_data, history) => dispatch => {
    axios
        .get(toAPIURL("api/enrolled-student/" + user_data.email))
            .then(res => {
                dispatch({
                    type: GET_STUDENT,
                    payload: res.data
                });
                history.replace("/student-dashboard")
            })
            .catch(err => console.log(err));
}

// DELETE STUDENT
export const deleteStudent = studentId => ({
    type: DELETE_STUDENT,
    payload: studentId
})
