import React, { useEffect, useState, useLayoutEffect } from "react";
import { Button } from "react-bootstrap";
import "./detail_style.css";
import { Link, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {
  FaRegQuestionCircle,
  FaCalendarAlt,
  FaFileAlt,
  FaChartPie,
  FaStickyNote,
  FaPowerOff,
} from "react-icons/fa";
import { MdAssignment, MdDashboard, MdNotificationsNone } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import {
  studentQuizDetails,
  dashboardDoubts,
  submitQuiz,
} from "../../../crud/student.crud";
import Popup from "../../teacher/popup/popup";
// images
import line5_2x from "./img/line-5@2x.png";
import line5_1_2x from "./img/line-5-1@2x.png";
import line6_2x from "./img/line-6@2x.png";
import line6_1_2x from "./img/line-6-1@2x.png";
import line8 from "./img/line-8@1x.png";
import line8_1 from "./img/line-8-1@2x.png";
import rectangle_7 from "./img/rectangle-7@1x.png";
import ellipse_2_2x from "./img/ellipse-2@2x.png";
import vector_3_2x from "./img/vector-3@2x.png";
import vector_4_2x from "./img/vector-4@2x.png";
import vector_191_2x from "./img/vector-191@2x.png";
import vector_192_2x from "./img/vector-192@2x.png";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteStudent } from "../../../redux/actions/student";
import pic_2 from "../dashboard/img/pic_2.png";

import SideDrawerStudent from "../../../UI/sideDrawer/sideDrawerStudent";
import { S3sqconfig } from "../../../s3/s3sqconfig.js";
import S3FileUpload from "react-s3";
import { uploadFile } from "react-s3";

function Studentquizdetail(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const user = useSelector((state) => state.studentDetails.student);
  const { id } = useParams();
  const [quiz, setQuiz] = useState(null);
  const [doubts, setDoubts] = useState([]);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [quizUrl, setQuizUrl] = useState("");

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  useEffect(() => {
    studentQuizDetails(id)
      .then((res) => {
        setQuiz(res);
      })
      .catch((err) => console.log(err));
  }, [isSubmitted]);

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteStudent(reduxUser.enrolled_student_id));
    removeToken();
  };

  useEffect(() => {
    dashboardDoubts(user.enrolled_student_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setDoubts(false);
        } else setDoubts(res);
      })
      .catch((err) => console.log(err));
  }, []);

  function upload(e) {
    console.log(e.target.files[0]);
    S3FileUpload.uploadFile(e.target.files[0], S3sqconfig)
      .then((data) => {
        setQuizUrl(data.location);
        console.log(data.location);
      })
      .catch((err) => {
        alert(err);
      });
  }

  const handleSubmit = () => {
    if (quiz.student_response.status == "Submitted") {
      submitQuiz({ ...quiz.student_response, solution_url: quizUrl })
        .then((res) => {
          alert("Quiz Solution Updated Successfully!");
          setIsSubmitted(!isSubmitted);
        })
        .catch((err) => console.log(err));
    } else {
      submitQuiz({
        ...quiz.student_response,
        solution_url: quizUrl,
        status: "Submitted",
      })
        .then((res) => {
          alert("Quiz Submitted Successfully!");
          setIsSubmitted(!isSubmitted);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <div className="student-quiz-detail screen">
        <div className="student-re-flex">
          <div className="component-1-C61RwLz-3">
            <img
              alt="image"
              src={pic_2}
              style={{ height: "115px", marginTop: "-8%" }}
            />
          </div>
          <div className="group-22-GyJUNH-student-dash">
            <div className="flex-col-vgli2x-student-dash">
              <div className="biba-sobti-h8V8ZR-student-dash">
                {user.first_name + " " + user.last_name}
              </div>
              <div className="bibasobtigmailcom-h8V8ZR-student-dash">
                {user && user.email}
              </div>
            </div>
            <img className="vector-vgli2x-student-dash" src={vector_3_2x} />
          </div>
        </div>

        <img className="rectangle-7-C61RwL" src={rectangle_7} />
        {/* <div className="notifications-24px-2-1-C61RwL">
          <div className="vector-CLdsug"><MdNotificationsNone size={28} /></div>
          <img className="ellipse-2-OSIBbx" src={ellipse_2_2x} />
        </div> */}
        <div className="group-46-C61RwL">
          <div className="rectangle-40-AvtzuR"></div>
          <a href={quiz && quiz.quiz_group.quiz_url} target="_blank">
            <Button
              variant="outline-danger"
              className="rectangle-41-AvtzuR"
              style={{
                fontFamily: "'Montserrat', Helvetica",
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              View Quiz
            </Button>
          </a>
          {/* <div className="rectangle-42-AvtzuR"></div> */}
          <div className="rectangle-42-AvtzuR-001">
            <label for="File-Input">+ Add Your Work</label>
            <input
              id="File-Input"
              style={{ display: "none" }}
              // className="rectangle-42-AvtzuR x-add-work-AvtzuR montserrat-semi-bold-blue-zodiac-10px" style={{ color: "#dc3545" }}
              type="file"
              onChange={(e) => upload(e)}
            />
          </div>

          <Button
            disabled={quiz && quiz.student_response.status == "Graded"}
            onClick={() => handleSubmit()}
            variant="danger"
            className="rectangle-43-AvtzuR"
            style={{
              fontFamily: "'Montserrat', Helvetica",
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            Submit
          </Button>
          <div
            className="quiz-2-AvtzuR montserrat-semi-bold-blue-zodiac-18px"
            style={{ color: "#dc3545" }}
          >
            {quiz && quiz.quiz_group.quiz_name.toUpperCase()}
          </div>
          <div
            className="your-work-AvtzuR montserrat-semi-bold-blue-zodiac-18px"
            style={{ color: "#dc3545" }}
          >
            Your work
          </div>
          <div className="x10-marks-AvtzuR" style={{ color: "#dc3545" }}>
            {quiz && quiz.quiz_group.total_marks} Marks
          </div>
          <div className="your-marks-na-AvtzuR">
            <span className="span0-F71xPZ">Your marks:</span>
            <span className="span1-F71xPZ">&nbsp;</span>
            <span className="span2-F71xPZ">
              {quiz && quiz.student_response.marks}
            </span>
          </div>
          <div className="mathematics-dr-yashna-sharma-AvtzuR">
            {quiz && quiz.meta.subject_id} ({quiz && quiz.meta.teacher_id})
          </div>
          <div className="assigned-AvtzuR">
            {quiz && quiz.student_response.status}
          </div>
          {/* <div className="x-add-work-AvtzuR montserrat-semi-bold-blue-zodiac-10px" style={{color: "#dc3545"}}>+ Add work</div> */}
        </div>
        <a href="javascript:history.back()">
          <div className="my-quiz-C61RwL">
            <span className="span0-RD25Gb" style={{ color: "#dc3545" }}>
              My
            </span>
            <span className="span1-RD25Gb" style={{ color: "#dc3545" }}>
              {" "}
              Quiz
            </span>
          </div>
        </a>
        <div className="x14-C61RwL">14</div>
        <div className="rectangle-43-C61RwL">
          <div className="doubt-forum-C61RwL montserrat-semi-bold-blue-zodiac-18px">
            Doubt Forum
          </div>
          <Link to="/student-doubt-list">
            <div className="view-all-C61RwL">View all</div>
          </Link>
          {doubts.length > 0 ? (
            doubts.slice(0, 4).map((doubt) => {
              return (
                <div className="doubt-row">
                  <FaRegQuestionCircle size={30} />
                  <Link>
                    <div className="montserrat-semi-bold-blue-zodiac-18px">
                      {doubt.doubt_text.length > 31
                        ? doubt.doubt_text.substring(0, 27) + "..."
                        : doubt.doubt_text}
                    </div>
                  </Link>
                  {/* <div className="view-forum-C61RwL montserrat-semi-bold-gray-16px">View</div> */}
                </div>
              );
            })
          ) : (
            <div />
          )}
        </div>

        <img className="line-8-C61RwL" src={line8} />
        <a href="javascript:history.back()">
          <div className="arrowback-24px-1-C61RwL">
            <img className="vector-ec7mDZ" src={vector_4_2x} />
            <img className="vector-wEJYwt" src={vector_191_2x} />
          </div>
        </a>
        <div className="group-67-C61RwL">
          <div className="component-29-gW6vdi"></div>
          <div className="group-64-gW6vdi">
            <img className="vector-Bd1Smh" src={vector_192_2x} />
          </div>
        </div>
        <div className="rectangle-24-C61RwL-stu-s1">
          <Link to="/student-dashboard">
            <div data-tip="Dashboard">
              <MdDashboard size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-date-calendar">
            <div data-tip="Date Calendar">
              <FaCalendarAlt size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-assignment-list">
            <div data-tip="Assignments">
              <MdAssignment size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-quiz-list">
            <div data-tip="Quizzes">
              <RiQuestionnaireFill size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-curriculum-list">
            <div data-tip="Curriculums">
              <FaFileAlt size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-doubt-list">
            <div data-tip="Doubts">
              <FaRegQuestionCircle size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-performance" data-tip="Performance">
            <div>
              <FaChartPie size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-notes-list">
            <div data-tip="Lecture Notes">
              <FaStickyNote size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/" onClick={() => handleLogout()}>
            <FaPowerOff
              size={24}
              data-tip="Logout"
              color="rgba(179,179,179,1.0)"
            />
            <ReactTooltip />
          </Link>
        </div>
      </div>
      <form
        className="student-quiz-detail-mobile screen"
        name="form3"
        action="form3"
        method="post"
      >
        <div className="component-1-C61RwL">
          <img
            alt="image"
            src={pic_2}
            style={{ height: "90px", marginTop: "-8%" }}
          />
        </div>

        {/* This is the Hamburger icon  */}
        <SideDrawerStudent
          open={displaySideDrawer}
          closeSideDrawer={sideDrawerToggler}
        />
        <div className="component-6-C61RwL" onClick={sideDrawerToggler}>
          <div className="rectangle-48-5mfU7d"></div>
          <div className="rectangle-49-5mfU7d"></div>
          <div className="rectangle-50-5mfU7d"></div>
        </div>

        <div className="group-85-C61RwL">
          <div className="rectangle-44-rFX9Nh">
            {doubts.length > 0 ? (
              doubts.slice(0, 4).map((doubt) => {
                return (
                  <div className="doubt-row">
                    <FaRegQuestionCircle size={30} />
                    <Link>
                      <div className="montserrat-semi-bold-blue-zodiac-18px">
                        {doubt.doubt_text.length > 31
                          ? doubt.doubt_text.substring(0, 27) + "..."
                          : doubt.doubt_text}
                      </div>
                    </Link>
                    {/* <div className="view-forum-C61RwL montserrat-semi-bold-gray-16px">View</div> */}
                  </div>
                );
              })
            ) : (
              <div />
            )}
          </div>
          <div className="doubt-forum-rFX9Nh">Doubt Forum</div>
          <Link to="/student-doubt-list">
            <div className="view-all-rFX9Nh">View all</div>
          </Link>
        </div>
        <div className="my-quiz-C61RwL">
          <span className="span0-RD25Gb">My</span>
          <span className="span1-RD25Gb"> Quiz</span>
        </div>
        <div className="group-98-C61RwL">
          <div className="group-46-royatl">
            <div className="rectangle-40-EGQFV0"></div>
            <div className="rectangle-42-EGQFV0">
              <label for="2-in-input">+ Add Your Work</label>
              <input
                id="2-in-input"
                style={{ display: "none" }}
                // className="rectangle-42-EGQFV0" style={{ color: "#dc3545" }}
                type="file"
                onChange={(e) => upload(e)}
              />
            </div>

            <Button
              disabled={quiz && quiz.student_response.status == "Graded"}
              onClick={() => handleSubmit()}
              variant="danger"
              className="rectangle-43-EGQFV0-00101"
              style={{
                fontFamily: "'Montserrat', Helvetica",
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              Submit
            </Button>
            <div className="quiz-2-EGQFV0-0001" style={{ color: "#dc3545" }}>
              {quiz && quiz.quiz_group.quiz_name.toUpperCase()}
            </div>
            <div
              className="your-work-EGQFV0 montserrat-semi-bold-blue-zodiac-18px"
              style={{ color: "#dc3545" }}
            >
              Your work
            </div>
            <div className="x10-marks-EGQFV0-0001" style={{ color: "#dc3545" }}>
              {quiz && quiz.quiz_group.total_marks} Marks
            </div>

            <div className="your-marks-na-AvtzuR">
              <span className="span0-F71xPZ">Your Marks:</span>
              <span className="span1-F71xPZ">&nbsp;</span>
              <span className="span2-F71xPZ">
                {quiz && quiz.student_response.marks}
              </span>
            </div>

            <div className="mathematics-dr-yashna-sharma-EGQFV0">
              {quiz && quiz.meta.subject_id} ({quiz && quiz.meta.teacher_id})
            </div>
            <div className="assigned-EGQFV0">
              {quiz && quiz.student_response.status}
            </div>
            <div className="group-97-EGQFV0">
              <a href={quiz && quiz.quiz_group.quiz_url} target="_blank">
                <Button
                  variant="outline-danger"
                  className="rectangle-41-kiZmGh"
                  style={{
                    fontFamily: "'Montserrat', Helvetica",
                    fontSize: 12,
                    fontWeight: 600,
                  }}
                >
                  View Quiz
                </Button>
              </a>
              {/* <div className="rectangle-41-kiZmGh"></div>
              <div className="view-quiz-kiZmGh montserrat-semi-bold-blue-zodiac-10px" style={{color: "#dc3545"}}>View Quiz</div> */}
            </div>
            {/* <div className="x-add-work-EGQFV0 montserrat-semi-bold-blue-zodiac-10px" style={{color: "#dc3545"}}>+ Add work</div> */}
          </div>
          <img className="line-8-royatl" src={line8_1} />
        </div>
        <div className="group-65-C61RwL">
          <div className="component-29-87p3Hy"></div>
          <div className="group-64-87p3Hy"></div>
        </div>
      </form>
      <Popup />
    </>
  );
}

export default Studentquizdetail;
