import React, { useState, useEffect, useLayoutEffect } from "react";
import "./suggest_rm.css";
import { NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from '../../../utils';
import { deleteHc } from '../../../redux/actions/hc'; import Popup from "../../teacher/popup/popup"
import DashboardIcon from "@material-ui/icons/Dashboard";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SchoolIcon from "@material-ui/icons/School";
import SideDrawerHc from "../../../UI/sideDrawer/sideDrawerHc";
import ClassSharpIcon from '@material-ui/icons/ClassSharp';
import { HCSuggestRM, HCModifyRM } from "../../../crud/highercommittee.crud";
import { enrolledStudentDetail } from "../../../crud/student.crud";
import pic_2 from "../../student/dashboard/img/pic_2.png";
import {
  MenuOutlined,
  HomeOutlined,
  UserOutlined,
} from "@ant-design/icons";

function SuggestRm(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [rm, setRM] = useState({});
  const [isModified, setIsModified] = useState(false);
  const [enrolled_student_id, setEnrolledStudentId] = useState("");
  const [rm_id1, setRmId1] = useState("");
  const user = useSelector((state) => state.hcDetails.hc);

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(deleteHc(user.hc_id))
    removeToken();
  }
  const handleModify = (e) => {
    e.preventDefault();
    enrolledStudentDetail(enrolled_student_id)
      .then((res) => {
        HCModifyRM({
          rm_id1,
          rm_id2: rm.rm_id,
          enrolled_student_id,
          first_name: res.first_name,
          last_name: res.last_name,
          mobile_number: res.mobile_number,
          father_name: res.father_name,
          father_number: res.father_number,
          email: res.email,
          state: res.state,
          country: res.country,
          postal_code: res.postal_code,
          current_grade: res.current_grade,
          address: res.address,
          landmark: res.landmark,
          city: res.city,
          current_school: res.current_school,
          last_marks: res.last_marks,
          trial_student_id: res.trial_student_id,
          rm_id: rm.rm_id,
        })
          .then((res) => {
            if (res.student) {
              alert("RM Modified Successfully!");
              setIsModified(!isModified);              
            } else {
              alert("Error in updating RM")
            }

          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    HCSuggestRM("Student")
      .then((res) => {
        setRM(res);
      })
      .catch((err) => console.log(err));
  };

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  return (
    // Its css in teacher_list and suggest_rm css
    <div className="Hc_detail_layout">
      {/* its css in UI Navbar */}
      <div className="header-wdt-100">
        <header className="Header">
          <HomeOutlined className="logo" />
          <span className="heder-men-not">
            <SideDrawerHc
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />
            <MenuOutlined className="menu" onClick={sideDrawerToggler} />
          </span>
        </header>
      </div>
      {/* This is the sider of the */}
      <div className="sider-hc-teacher-list-2">
        <div className="sider-hc-teacher-list-2-in-wdt-90">
          <div style={{ marginTop: "120px" }} />
          <NavLink to="/hc-dashboard">
            <DashboardIcon data-tip="Dashboard" className="icon-in-hc-color" />
            <ReactTooltip />
          </NavLink>
          <NavLink to="/hc-teachers-list">
            {" "}
            <SchoolIcon data-tip="Teacher" className="icon-in-hc-color" />
            <ReactTooltip />
          </NavLink>
          <NavLink to="/hc-suggest-rm">
            <SupervisorAccountIcon
              data-tip="Relational Manager"
              className="icon-in-hc-color"
              style={{ color: "#dc3545" }}
            />
            <ReactTooltip />
          </NavLink>
          <NavLink to="/hc-ticket-list">
            <AssignmentIcon
              data-tip="Ticket List"
              className="icon-in-hc-color"
            />
            <ReactTooltip />
          </NavLink>
          <NavLink to="/hc-payments">
            <AccountBalanceIcon
              data-tip="Payments"
              className="icon-in-hc-color"
            />
            <ReactTooltip />
          </NavLink>
          <NavLink to="/hc-classroom">
            <ClassSharpIcon data-tip="Classroom" className="icon-in-hc-color" />
            <ReactTooltip />
          </NavLink>
          <NavLink to="/" onClick={() => handleLogout()}>
            <PowerSettingsNewIcon
              data-tip="Logout"
              className="icon-in-hc-color"
            />
          </NavLink>
          <div style={{ marginTop: "1px" }} />
        </div>
      </div>

      <div className="content-in-hc-layout-sug-rm">
        <div className="content-in-hc-layout-in-wdt-90">
          {/* Its Css in sales SalesStatistics */}
          {/* <div className="logo-totalStudent-in" /> */}
          <img
            alt="image"
            src={pic_2}
            style={{ height: "100px", marginLeft: "-70%" }}
          />
          <div className="hc-teacher-list-out-view-2-suggest">
            <button
              className="butt-in-hc-form-4px"
              onClick={(e) => handleSubmit(e)}
            >
              Suggest RM
            </button>
            <div className="Suggest_rm-border"> {rm.rm_id} </div>
          </div>
        </div>

        <div className="content-in-hc-layout-in-wdt-90-2">
          {/* Its css in teacher dashboard */}
          <div className="calender-hc-in-user-dis">
            <div></div>
            <div className="User-info">
              <div className="user-info-in">
                <div
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#dc3545",
                  }}
                >
                  {user && user.first_name} {user && user.last_name}
                </div>
                <div>{user && user.email}</div>
              </div>
              <div>
                <UserOutlined className="User-Icon" />
              </div>
            </div>
          </div>

          <div className="hc-teacher-list-out-view-1">
            <form className="hc-form-in-wdt-align">
              <input
                type="text"
                name="student_ID"
                placeholder="Student ID"
                onChange={(e) => setEnrolledStudentId(e.target.value)}
              />
              <input
                type="text"
                name="current_rm_ID"
                placeholder="Current RM ID"
                onChange={(e) => setRmId1(e.target.value)}
              />
              <input
                type="text"
                name="new_rm_ID"
                placeholder="New Rm ID"
                value={rm.rm_id}
              />
              <button
                className="butt-in-hc-form-4px"
                onClick={(e) => handleModify(e)}
              >
                Modify RM
              </button>
            </form>
          </div>
        </div>
      </div>
      {/* <Popup /> */}
    </div>
  );
}

export default SuggestRm;
