import React, { useState, useEffect, useLayoutEffect } from "react";
import "./detail_style.css";
import { NavLink, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import pic_2 from "../../student/dashboard/img/pic_2.png";

// images
import component_32 from "./img/component-32@1x.png";
import vector_2x from "./img/vector@2x.png";
import vector_1_2x from "./img/vector-1@2x.png";
import vector_5_2x from "./img/vector-5@2x.png";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteTeacher } from "../../../redux/actions/teacher";
import {
  doubtDetail,
  createDoubtResponse,
  doubtLike,
} from "../../../crud/student.crud";

import SideDrawer from "../../../UI/sideDrawer/sideDrawer";
import Popup from "../popup/popup";

import DashboardIcon from "@material-ui/icons/Dashboard";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SchoolIcon from "@material-ui/icons/School";
import DescriptionIcon from "@material-ui/icons/Description";
import HelpIcon from "@material-ui/icons/Help";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import NotesIcon from "@material-ui/icons/Notes";

function Studentdoubtsdetail(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const user = useSelector((state) => state.teacherDetails.teacher);
  const [doubt, setDoubt] = useState(null);
  const [doubtResponse, setDoubtResponse] = useState("");
  const [commented, setCommented] = useState(false);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [displayLikeButton, setDisplayLikeButton] = useState(false);
  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(deleteTeacher(user.teacher_id));
    removeToken();
  };
  //   const [displayLikeButton, setDisplayLikeButton] = useState(false);
  //   let like_button_class = ["like_button", "Color_grey"];
  //   if (displayLikeButton) {
  //     like_button_class = ["like_button", "Color_dc3545"];
  //   }

  const handleLikeClick = (doubt_response_id) => {
    doubtLike({
      doubt_response_id,
      object_id: user.teacher_id,
      isLike: "true",
    })
      .then((res) => {
        alert("Response Liked Successfully");
        setIsLiked(!isLiked);
        setDisplayLikeButton((previousState) => !previousState);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    doubtDetail(id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setDoubt(false);
        } else setDoubt(res);
      })
      .catch((err) => console.log(err));
  }, [commented, isLiked]);

  // Get doubt responses and keep dependent on "commented"

  const handleResponseSubmit = () => {
    createDoubtResponse({
      userId: user.teacher_id,
      doubtId: doubt.doubt.id,
      text: doubtResponse,
    })
      .then((res) => {
        setCommented(!commented);
        setDoubtResponse("");
        document.getElementById("commentInput").value = "";
        document.getElementById("commentInputMobile").value = "";
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <form
        className="student-doubts-detail-mobile screen"
        name="form1"
        action="form1"
        method="post"
      >
        <div className="component-1-C61RwL">
          <img
            alt="image"
            src={pic_2}
            style={{ height: "90px", marginTop: "-8%" }}
          />
        </div>

        {/* This is tha hamburder icon  */}
        <SideDrawer
          open={displaySideDrawer}
          closeSideDrawer={sideDrawerToggler}
        />

        <div className="component-6-C61RwL" onClick={sideDrawerToggler}>
          <div className="rectangle-48-5mfU7d"></div>
          <div className="rectangle-49-5mfU7d"></div>
          <div className="rectangle-50-5mfU7d"></div>
        </div>
        <a href="javascript:history.back()">
          <div className="solve-doubts-C61RwL">
            <span className="span0-z3pWM6" style={{ color: "#dc3545" }}>
              Solve{" "}
            </span>
            <span className="span1-z3pWM6" style={{ color: "#dc3545" }}>
              Doubts
            </span>
          </div>
        </a>
        <img className="component-32-C61RwL" src={component_32} />
        <div className="component-33-C61RwL"></div>
        <div className="active-C61RwL">{doubt && doubt.doubt.doubt_status}</div>
        <div className="vatsal-agarwal-C61RwL" style={{ color: "#dc3545" }}>
          {doubt && doubt.enrolled_student}
        </div>
        <div className="group-57-C61RwL099">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              top: "-10px",
              left: "0px",
              position: "absolute",
              height: "300px",
              overflowX: "hidden",
            }}
          >
            {doubt &&
            doubt.doubt_response &&
            doubt.doubt_response.length > 0 ? (
              doubt.doubt_response.map((res) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      width: "360px",
                      marginTop: "10px",
                      marginBottom: "10px",
                      height: "auto",
                    }}
                  >
                    <div
                      className="kunal-kashyap-C61RwL montserrat-semi-bold-blue-zodiac-20px"
                      style={{
                        marginRight: 0,
                        maxWidth: "360px",
                        paddingRight: "5px",
                        wordBreak: "break-all",
                      }}
                    >
                      {res.each_doubt_response.object_id}
                    </div>
                    <div
                      className="it-is-from-unit-1-zA4C22-0001-2 montserrat-medium-black-18px"
                      style={{ marginRight: 0, width: "1000px" }}
                    >
                      {res.each_doubt_response.doubt_response_text}
                    </div>
                    {res.likes_data.filter(
                      (each_like) =>
                        each_like.object_id == user.teacher_id &&
                        each_like.isLike
                    ).length > 0 ? (
                      <div className="like_button Color_dc3545">
                        <ThumbUpIcon
                          onClick={() =>
                            handleLikeClick(res.each_doubt_response.id)
                          }
                        />{" "}
                        <p style={{ marginLeft: "5px" }}>
                          {res.each_doubt_response.like}
                        </p>
                      </div>
                    ) : (
                      <div className="like_button Color_grey">
                        <ThumbUpIcon
                          onClick={() =>
                            handleLikeClick(res.each_doubt_response.id)
                          }
                        />{" "}
                        <p style={{ marginLeft: "5px" }}>
                          {res.each_doubt_response.like}
                        </p>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div>No Doubt</div>
            )}
          </div>
        </div>
        <input
          id="commentInputMobile"
          className="group-78-C61RwL"
          name="6392265"
          placeholder="Solve this doubt"
          onChange={(e) => setDoubtResponse(e.target.value)}
          type="text"
        />
        {/* <div className="kunal-kashyap-C61RwL montserrat-semi-bold-blue-zodiac-14px" style={{ color: "#000" }}>Kunal Kashyap</div>
                <div className="vatsal-agarwal-VMr6Om montserrat-semi-bold-blue-zodiac-14px" style={{ color: "#000" }}>Vatsal Agarwal</div>
                <div className="kunal-kashyap-VMr6Om montserrat-semi-bold-blue-zodiac-14px" style={{ color: "#000" }}>Kunal Kashyap</div> */}
        <div
          className="biba-sobti-C61RwL montserrat-semi-bold-blue-zodiac-14px"
          style={{ color: "#000" }}
        >
          {user && user.first_name} {user && user.last_name}
        </div>
        <div className="what-is-euclid-lemma-C61RwL">
          {doubt && doubt.doubt.doubt_text}
        </div>
        <Button
          variant="outline-danger"
          className="rectangle-41-PXwdVf p-0"
          style={{
            fontFamily: "'Montserrat', Helvetica",
            fontSize: 12,
            fontWeight: 600,
          }}
          onClick={() => window.open(doubt && doubt.doubt.doubt_url, "_blank")}
        >
          View
        </Button>
        <Button
          disabled={doubtResponse === ""}
          onClick={() => handleResponseSubmit()}
          className="group-55-C61RwL"
          style={{ backgroundColor: "#dc3545" }}
        >
          <div className="post-4W6TZx">Post</div>
        </Button>
        <a href="javascript:history.back()">
          <div className="arrowback-24px-1-C61RwL">
            <img className="vector-ec7mDZ" src={vector_2x} />
            <img className="vector-wEJYwt" src={vector_1_2x} />
          </div>
        </a>
        <div className="grade-subject-C61RwL">
          {doubt && doubt.doubt.grade_id} - {doubt && doubt.doubt.subject_id}
        </div>
        <div className="date-C61RwL">
          {doubt && moment(doubt.doubt.date).format("MMMM Do, YYYY")}
        </div>
      </form>
      <form
        className="student-doubts-detail-t screen"
        name="form2"
        action="form2"
        method="post"
      >
        <div className="col-1212">
          <div className="flex-row-0xy0vn">
            <div className="component-1-GyJUNH">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "115px", marginTop: "-8%" }}
              />
            </div>

            <div className="group-22-GyJUNH">
              <div className="flex-col-vgli2x">
                <div className="biba-sobti-h8V8ZR">
                  {user.first_name + " " + user.last_name}
                </div>
                <div className="bibasobtigmailcom-h8V8ZR">{user.emailID}</div>
              </div>
              <img className="vector-vgli2x" src={vector_5_2x} />
            </div>
          </div>

          <div className="solve-doubts-C61RwL">
            <span className="span0-z3pWM6" style={{ color: "#dc3545" }}>
              Solve
            </span>
            <span className="span1-z3pWM6" style={{ color: "#dc3545" }}>
              {" "}
              Doubts
            </span>
          </div>

          <div className="row-sub-date">
            <div className="grade-subject-C61RwL">
              {doubt && doubt.doubt.grade_id} -{" "}
              {doubt && doubt.doubt.subject_id}
            </div>
            <div className="date-C61RwL">
              {doubt && moment(doubt.doubt.date).format("MMMM Do, YYYY")}
            </div>
          </div>

          <div className="row-card-main">
            <div className="row-card-1">
              <div className="row-name-status">
                <div
                  className="vatsal-agarwal-C61RwL"
                  style={{ color: "#dc3545" }}
                >
                  {doubt && doubt.enrolled_student}
                </div>
                <div className="active-C61RwL">
                  {doubt && doubt.doubt.doubt_status}
                </div>
              </div>
              <div className="what-is-euclid-lemma-C61RwL montserrat-medium-black-18px">
                {doubt && doubt.doubt.doubt_text}
              </div>
            </div>

            <Button
              variant="outline-danger"
              className="rectangle-41-PXwdVf"
              style={{
                fontFamily: "'Montserrat', Helvetica",
                fontSize: 12,
                fontWeight: 600
              }}
              onClick={() =>
                window.open(doubt && doubt.doubt.doubt_url, "_blank")
              }
            >
              View
            </Button>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                height: "300px",
                overflow: "auto",
                width: "97%",
                margin: "20px auto",
              }}
            >
              {doubt &&
              doubt.doubt_response &&
              doubt.doubt_response.length > 0 ? (
                doubt.doubt_response.map((res) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "auto",
                        marginBottom: "24px",
                        marginTop: "24px",
                        width: "90%",
                      }}
                    >
                      <div
                        className="kunal-kashyap-C61RwL montserrat-semi-bold-blue-zodiac-20px"
                        style={{ color: "#000" }}
                      >
                        {res.each_doubt_response.object_id}
                      </div>
                      <div>
                        <div
                          className="it-is-from-unit-1-zA4C22-0001-2 montserrat-medium-black-18px"
                          style={{
                            marginRight: 0,
                            width: "90%",
                            paddingRight: "10px",
                            wordBreak: "break-all",
                          }}
                        >
                          {res.each_doubt_response.doubt_response_text}
                        </div>
                      </div>

                      {res.likes_data.filter(
                        (each_like) =>
                          each_like.object_id == user.teacher_id &&
                          each_like.isLike
                      ).length > 0 ? (
                        <div className="like_button Color_dc3545">
                          <ThumbUpIcon
                            onClick={() =>
                              handleLikeClick(res.each_doubt_response.id)
                            }
                          />{" "}
                          <p style={{ marginLeft: "5px" }}>
                            {res.each_doubt_response.like}
                          </p>
                        </div>
                      ) : (
                        <div className="like_button Color_grey">
                          <ThumbUpIcon
                            onClick={() =>
                              handleLikeClick(res.each_doubt_response.id)
                            }
                          />{" "}
                          <p style={{ marginLeft: "5px" }}>
                            {res.each_doubt_response.like}
                          </p>
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <div>No Doubt</div>
              )}
            </div>
            <div
              className="biba-sobti-C61RwL montserrat-semi-bold-blue-zodiac-20px"
              style={{ color: "#000" }}
            >
              {user && user.first_name} {user && user.last_name}
            </div>
            <input
              id="commentInput"
              className="group-50-C61RwL"
              name="6392265"
              placeholder="Solve this doubt"
              onChange={(e) => setDoubtResponse(e.target.value)}
              type="text"
            />

            <Button
              disabled={doubtResponse === ""}
              onClick={() => handleResponseSubmit()}
              className="group-55-C61RwL"
              style={{ backgroundColor: "#dc3545" }}
            >
              <div className="post-4W6TZx">Post</div>
            </Button>
          </div>
        </div>

        <div className="sider-teacher-general-div">
          <div className="sider-teacher-in-general-80">
            <div style={{ marginTop: "120px" }} />
            <NavLink to="/teacher-dashboard" exact>
              <DashboardIcon
                data-tip="Dashboard"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/teacher-date-calendar" exact>
              <EventIcon data-tip="Scheduler" className="icon-in-hc-color" />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/teacher-previous-classes">
              <ScheduleIcon
                data-tip="Previous Class"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/teacher-students">
              <SchoolIcon
                data-tip="Enrolled Student"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/teacher-curriculum-list">
              <DescriptionIcon
                data-tip="Curriculum"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/teacher-doubt-list">
              <HelpIcon data-tip="Doubts" className="icon-in-hc-color" />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/teacher-analytics">
              <AssessmentIcon
                data-tip="Analytics"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/teacher-assignments">
              <AssignmentIcon
                data-tip="Assignments"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/teacher-quizzes">
              <LibraryBooksIcon
                data-tip="Quizzes"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/teacher-lecture-notes">
              <NotesIcon
                data-tip="Lecture Notes"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            {/* <NavLink to="/teacher-payments"><AccountBalanceIcon data-tip="Payment" className="icon-in-hc-color" /><ReactTooltip /></NavLink> */}
            <div style={{ marginTop: "10px" }} />
            <NavLink to="/" onClick={() => handleLogout()}>
              {" "}
              <PowerSettingsNewIcon
                data-tip="Logout"
                className="icon-in-hc-color"
              />{" "}
            </NavLink>
            <div style={{ marginTop: "1px" }} />
          </div>
        </div>
      </form>
      <Popup />
    </>
  );
}

export default Studentdoubtsdetail;
