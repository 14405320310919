import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Modal from "react-modal";
import moment from "moment";
import "./style.css";
import {
  FaRegQuestionCircle,
  FaCalendarAlt,
  FaFileAlt,
  FaChartPie,
  FaStickyNote,
  FaPowerOff,
  FaArrowLeft,
} from "react-icons/fa";
import { MdAssignment, MdDashboard } from "react-icons/md";
// import { BiSupport } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import Popup from "../../teacher/popup/popup";
import {
  getSlots,
  studentBookClass,
  updateSlotStatus,
} from "../../../crud/student.crud";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteStudent } from "../../../redux/actions/student";
import pic_2 from "../dashboard/img/pic_2.png";

// images
// import line_5_2x from "./img/line-5@2x.png";
// import line_5_1_2x from "./img/line-5-1@2x.png";
// import line_6_2x from "./img/line-6@2x.png";
// import line_6_1_2x from "./img/line-6-1@2x.png";
import vector_143_2x from "./img/vector-143@2x.png";
// import orange_ellipse from "./img/ellipse-2@2x.png";
import { Button } from "semantic-ui-react";
import SideDrawerStudent from "../../../UI/sideDrawer/sideDrawerStudent";

function Studenttimecalendar(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const user = useSelector((state) => state.studentDetails.student);
  const { date, teacher } = useParams();
  const [penAssigns, setPenAssigns] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [slots, setSlots] = useState([]);
  const [selSlot, setSelSlot] = useState({});
  const [selTime, setSelTime] = useState("");
  const [selDate, setSelDate] = useState("");
  const [stuClasses, setStuClasses] = useState([]);
  const [isBooked, setIsBooked] = useState(false);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteStudent(reduxUser.enrolled_student_id));
    removeToken();
  };

  useEffect(() => {
    getSlots(user.enrolled_student_id, teacher, date)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setSlots(false);
        } else {
          setSlots(res.teacher_slots);
          setStuClasses(res.student_classes);
        }
      })
      .catch((err) => console.log(err));
  }, [isBooked]);

  const closeModal = () => setIsModalOpen(false);

  const bookClass = (slot) => {
    var datetime = moment.utc(slot.date + " " + slot.time).format();
    var local = moment.utc(datetime).local().format("YYYY-MM-DD HH:mm:ss");

    studentBookClass({
      teacher_id: slot.teacher_id,
      status: "Scheduled",
      class_type: "One-One",
      datetime: moment(
        `${moment(datetime).format("YYYY-MM-DD")} ${moment(datetime).format(
          "HH:mm:ss"
        )}`,
        "YYYY-MM-DDTHH:mm:ssZ"
      ).format(),
      student_id: user.enrolled_student_id,
    })
      .then((res) => {
        updateSlotStatus({ ...slot, status: "Booked" }).then((slotRes) => {
          setIsModalOpen(false);
          setIsBooked(!isBooked);
          alert("Class booked succesfully!");
        });
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="student-calendar-time-mobile screen">
        <div className="component-1-C61RwL">
          <img
            alt="image"
            src={pic_2}
            style={{ height: "90px", marginTop: "-8%" }}
          />
        </div>
        <SideDrawerStudent
          open={displaySideDrawer}
          closeSideDrawer={sideDrawerToggler}
        />
        <div className="component-6-C61RwL" onClick={sideDrawerToggler}>
          <div className="rectangle-48-5mfU7d"></div>
          <div className="rectangle-49-5mfU7d"></div>
          <div className="rectangle-50-5mfU7d"></div>
        </div>
        {/* <div className="group-86-C61RwL">
          <div className="pending-assignments-cPi90N">Pending Assignments</div>
          <div className="view-all-cPi90N montserrat-semi-bold-gray-12px">
            View all
          </div>
          <div className="rectangle-44-cPi90N"></div>
          <div className="rectangle-52-cPi90N"></div>
        </div> */}
        <div className="component-4-C61RwL">
          <div className="sections-teachi-g-i631221653102-p4wM3n">
            Total
            <br />
            Classes
          </div>
          <div className="x4-i631221653103-p4wM3n">1</div>
        </div>
        <div className="component-5-C61RwL">
          <div className="sections-teachi-g-i631221753102-9wWKr1">
            Completed <br />
            Classes
          </div>
          <div className="x4-i631221753103-9wWKr1">0</div>
        </div>
        {/* <div className="group-87-C61RwL">
        <div className="component-7-D1n0rW">
          <div className="x4-i631221953103-j2Gv3j montserrat-semi-bold-blue-zodiac-64px"></div>
        </div>
        <div className="teacher-name-D1n0rW montserrat-medium-blue-zodiac-20px">Teacher Name</div>
        <div className="course-D1n0rW montserrat-medium-blue-zodiac-20px">Course</div>
        <div className="subject-D1n0rW montserrat-medium-blue-zodiac-20px">Subject</div>
        <div className="grade-D1n0rW montserrat-medium-blue-zodiac-20px">Grade</div>
      </div> */}
        <div className="choose-time-for-booking-C61RwL montserrat-medium-blue-zodiac-20px">
          Choose time for booking
        </div>
        <div className="go-back-C61RwL montserrat-semi-bold-blue-zodiac-18px">
          Go Back
        </div>
        <div className="feb-18-2021-C61RwL">
          {moment(date).format("MMM DD, YYYY")}
        </div>

        <a href="javascript:history.back()">
          <div className="arrowback-24px-1-C61RwL">
            <FaArrowLeft />
          </div>
        </a>

        <div className="group-112-C61RwL">
          <div className="rectangle-31-Ajm6uA-00s">
            {slots.length > 0 &&
            slots.filter((slot) => slot.status != "Booked").length > 0 ? (
              slots
                .filter((slot) => slot.status != "Booked")
                .map((slot) => {
                  var datetime = moment
                    .utc(slot.date + " " + slot.time)
                    .format();
                  var local = moment
                    .utc(datetime)
                    .local()
                    .format("YYYY-MM-DD HH:mm:ss");
                  var local_date = moment(local).format("YYYY-MM-DD");
                  var local_time = moment(local).format("HH:mm:ss");
                  if (local_date == date) {
                    return (
                      <div className="rectangle-40-Ajm6uA">
                        <div className="x12pm-Ajm6uA roboto-medium-blue-zodiac-14px">
                          {moment(local).format("HH:mm:ss")}
                        </div>
                        <div className="completed-Ajm6uA roboto-medium-blue-zodiac-14px">
                          {slot.status}
                        </div>
                        <button
                          onClick={() => {
                            setIsModalOpen(true);
                            setSelSlot(slot);
                            setSelTime(local_time);
                            setSelDate(local_date);
                          }}
                          style={{ border: "none", background: "none" }}
                        >
                          <div className="delete-C61RwL roboto-medium-niagara-14px">
                            Add
                          </div>
                        </button>
                      </div>
                    );
                  }
                })
            ) : (
              <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                No slots of {teacher} available for the selected date({date}).
              </div>
            )}
          </div>
          <div className="status-Ajm6uA roboto-bold-gray-16px">Status</div>
          <div className="time-Ajm6uA roboto-bold-gray-16px">Time</div>
          <div className="add-delete-Ajm6uA roboto-bold-gray-16px">Book</div>
        </div>
      </div>
      <div className="student-time-calendar screen">
        <div className="student-re-flex">
          <div className="component-1-C61RwLz-3">
            <img
              alt="image"
              src={pic_2}
              style={{ height: "115px", marginTop: "-8%" }}
            />
          </div>

          <div className="group-22-GyJUNH-student-dash">
            <div className="flex-col-vgli2x-student-dash">
              <div className="biba-sobti-h8V8ZR-student-dash">
                {user.first_name + " " + user.last_name}
              </div>
              <div className="bibasobtigmailcom-h8V8ZR-student-dash">
                {user && user.email}
              </div>
            </div>
            <img className="vector-vgli2x-student-dash" src={vector_143_2x} />
          </div>
        </div>
        {/* <img
          alt="image"
          className="rectangle-7-C61RwL"
          src="img/rectangle-7@1x.png"
        /> */}
        {/* <div className="notifications-24px-2-1-C61RwL">
        <div className="vector-CLdsug">
          <MdNotificationsNone size={28} />
        </div>
        <img alt="image" className="ellipse-2-OSIBbx" src={orange_ellipse} />
      </div> */}
        {/* <div className="view-all-C61RwL montserrat-semi-bold-gray-12px">View all</div>
      <div className="x14-C61RwL">14</div>
      <div className="pending-assignments-C61RwL montserrat-semi-bold-blue-zodiac-18px">Pending Assignments</div>
      <div className="rectangle-32-C61RwL"></div>
      <div className="rectangle-33-C61RwL"></div> */}

        {/* <div
          className="pending-assignments-C61RwL montserrat-semi-bold-blue-zodiac-18px"
          style={{ color: "#dc3545" }}
        >
          Pending Assignments
        </div> */}
        {/* <div className="rectangle-32-C61RwL">
        <MdAssignment size={30} color="rgba(19,37,71,1.0)" />
        <div className="assignment-rowCol">
          <div className="montserrat-semi-bold-blue-zodiac-18px">{penAssigns[0].assignment_name}</div>
          <div className="montserrat-semi-bold-gray-12px">Marks: {penAssigns[0].total_marks}</div>
        </div>
      </div> */}
        {penAssigns.length > 0 ? (
          <div className="rectangle-32-C61RwL">
            <MdAssignment size={30} color="rgba(126,126,126,1.0)" />
            <div className="assignment-rowCol">
              <div
                className="montserrat-semi-bold-blue-zodiac-18px"
                style={{ color: "rgba(126,126,126,1.0)" }}
              >
                {penAssigns[0].assignment_name}
              </div>
              <div className="montserrat-semi-bold-gray-12px">
                Marks: {penAssigns[0].total_marks}
              </div>
            </div>
          </div>
        ) : (
          <div>
            {" "}
            <div>
              {" "}
              {/* <div className="rectangle-32-C61RwL">
                <div className="montserrat-semi-bold-blue-zodiac-18px">
                  You have no pending assignment.
                </div>
              </div> */}
              {/* <div className="rectangle-42-C61RwL">
                <div className="you-have-no-upcoming-tests-C61RwL">
                  You have no upcoming tests.
                </div>
              </div> */}
              <div className="component-4-C61RwL">
                <div className="sections-teachi-g-i631213753102-p4wM3n">
                  Total
                  <br />
                  Classes
                </div>
                <div className="x4-i631213753103-p4wM3n montserrat-semi-bold-blue-zodiac-64px">
                  1
                </div>
              </div>
              <div className="component-6-C61RwL">
                <div className="x4-i631213853103-5mfU7d montserrat-semi-bold-blue-zodiac-64px"></div>
              </div>
              <div className="component-5-C61RwL">
                <div className="sections-teachi-g-i631213953102-9wWKr1">
                  Completed <br />
                  Classes
                </div>
                <div className="x4-i631213953103-9wWKr1">0</div>
              </div>
              {/* <div className="component-1-C61RwL">
                  <img
                    alt="image"
                    src={pic_2}
                    style={{ height: "115px", marginTop: "-8%" }}
                  />
                </div> */}
              <div className="choose-time-for-booking-C61RwL">
                Choose Time For Booking
              </div>
              <div className="teacher-name-C61RwL montserrat-medium-blue-zodiac-24px">
                {teacher}
              </div>
            </div>
            <div className="rectangle-31-C61RwL">
              <div
                style={{
                  width: "87%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="roboto-medium-gray-20px">Status</div>
                <div className="roboto-medium-gray-20px">Time</div>
                <div className="roboto-medium-gray-20px">Book</div>
              </div>

              {slots.length > 0 &&
              slots.filter((slot) => slot.status != "Booked").length > 0 ? (
                slots
                  .filter((slot) => slot.status != "Booked")
                  .map((slot) => {
                    var datetime = moment
                      .utc(slot.date + " " + slot.time)
                      .format();
                    var local = moment
                      .utc(datetime)
                      .local()
                      .format("YYYY-MM-DD HH:mm:ss");
                    var local_date = moment(local).format("YYYY-MM-DD");
                    var local_time = moment(local).format("HH:mm:ss");
                    if (local_date == date) {
                      return (
                        <div key={slot.id} className="rectangle-40-C61RwL">
                          <div className="x12pm-C61RwL roboto-medium-blue-zodiac-14px">
                            {moment(local).format("HH:mm:ss")}
                          </div>
                          <div className="completed-C61RwL roboto-medium-blue-zodiac-14px">
                            {slot.status}
                          </div>
                          <button
                            onClick={() => {
                              setIsModalOpen(true);
                              setSelSlot(slot);
                              setSelTime(local_time);
                              setSelDate(local_date);
                            }}
                            style={{
                              border: "none",
                              background: "none",
                            }}
                          >
                            <div className="delete-C61RwL roboto-medium-niagara-14px">
                              Add
                            </div>
                          </button>
                        </div>
                      );
                    }
                  })
              ) : (
                <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                  No slots of {teacher} available for the selected date({date}
                  ).
                </div>
              )}
            </div>
            <a href="javascript:history.back()">
              <div className="go-back-C61RwL">Go back</div>
            </a>
            <div className="feb-18-2021-C61RwL">
              {moment(date).format("MMM DD, YYYY")}
            </div>
            <a href="javascript:history.back()">
              <div className="arrowback-24px-1-C61RwL">
                <FaArrowLeft />
              </div>
            </a>
            {/* <div className="rectangle-24-C61RwL"></div>
            <div className="vector-C61RwL">
              <Link to="/" onClick={() => handleLogout()}>
                <FaPowerOff
                  size={24}
                  data-tip="Logout"
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
            <Link to="/student-curriculum-list">
              <div className="description-24px-1-C61RwL" data-tip="Curriculums">
                <FaFileAlt size={24} color="rgba(179,179,179,1.0)" />
              </div>
              <ReactTooltip />
            </Link>
            <Link to="/student-date-calendar">
              <div className="vector-VMr6Om" data-tip="Date Calendar">
                <FaCalendarAlt size={24} color="#dc3545" />
              </div>
              <ReactTooltip />
            </Link>
            <Link to="/student-dashboard">
              <div className="vector-mzXdH9" data-tip="Dashboard">
                <MdDashboard size={24} color="rgba(179,179,179,1.0)" />
              </div>{" "}
              <ReactTooltip />
            </Link>
            <Link to="/student-doubt-list">
              <div className="help-24px-1-C61RwL" data-tip="Doubts">
                <FaRegQuestionCircle size={24} color="rgba(179,179,179,1.0)" />
              </div>
              <ReactTooltip />
            </Link>
            <Link to="/student-assignment-list">
              <div className="assignment-24px-1-C61RwL" data-tip="Assignments">
                <MdAssignment size={24} color="rgba(179,179,179,1.0)" />
              </div>{" "}
              <ReactTooltip />
            </Link>
            <Link to="/">
              <div className="supportagent-24px-1-C61RwL">
                <BiSupport
                  size={24}
                  color="rgba(179,179,179,1.0)"
                  data-tip="Support Desk"
                />
              </div>
              <ReactTooltip />
            </Link>
            <Link to="/student-performance" data-tip="Performance">
              <div className="analytics-24px-3-C61RwL">
                <FaChartPie size={24} color="rgba(179,179,179,1.0)" />
              </div>{" "}
              <ReactTooltip />
            </Link>
            <div className="group-C61RwL">
              <Link to="/student-quiz-list">
                <div className="group-NOXmfT" data-tip="Quizzes">
                  <RiQuestionnaireFill
                    size={24}
                    color="rgba(179,179,179,1.0)"
                  />
                </div>{" "}
                <ReactTooltip />
              </Link>
            </div>
            <div className="group-160-C61RwL">
              <Link to="/student-notes-list">
                <div
                  className="stickynote2-24px-1-Cp4Be6"
                  data-tip="Lecture Notes"
                >
                  <FaStickyNote size={24} color="rgba(179,179,179,1.0)" />
                </div>
                <ReactTooltip />
              </Link>
            </div> */}
            <div className="rectangle-24-C61RwL-stu-s1">
              <Link to="/student-dashboard">
                <div data-tip="Dashboard">
                  <MdDashboard size={24} color="rgba(179,179,179,1.0)" />
                </div>{" "}
                <ReactTooltip />
              </Link>

              <Link to="/student-date-calendar">
                <div data-tip="Date Calendar">
                  <FaCalendarAlt size={24} color="rgba(179,179,179,1.0)" />
                </div>
                <ReactTooltip />
              </Link>

              <Link to="/student-assignment-list">
                <div data-tip="Assignments">
                  <MdAssignment size={24} color="rgba(179,179,179,1.0)" />
                </div>{" "}
                <ReactTooltip />
              </Link>

              <Link to="/student-quiz-list">
                <div data-tip="Quizzes">
                  <RiQuestionnaireFill
                    size={24}
                    color="rgba(179,179,179,1.0)"
                  />
                </div>{" "}
                <ReactTooltip />
              </Link>

              <Link to="/student-curriculum-list">
                <div data-tip="Curriculums">
                  <FaFileAlt size={24} color="rgba(179,179,179,1.0)" />
                </div>
                <ReactTooltip />
              </Link>

              <Link to="/student-doubt-list">
                <div data-tip="Doubts">
                  <FaRegQuestionCircle
                    size={24}
                    color="rgba(179,179,179,1.0)"
                  />
                </div>
                <ReactTooltip />
              </Link>

              <Link to="/student-performance" data-tip="Performance">
                <div>
                  <FaChartPie size={24} color="rgba(179,179,179,1.0)" />
                </div>
                <ReactTooltip />
              </Link>

              <Link to="/student-notes-list">
                <div data-tip="Lecture Notes">
                  <FaStickyNote size={24} color="rgba(179,179,179,1.0)" />
                </div>
                <ReactTooltip />
              </Link>

              <Link to="/" onClick={() => handleLogout()}>
                <FaPowerOff
                  size={24}
                  data-tip="Logout"
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              className="slot-modal"
            >
              <div className="slot-modal-title montserrat-semi-bold-blue-zodiac-18px">
                Confirm Class Booking
              </div>
              <div className="slot-modal-body">
                {/* <div className="slot-details">
                  Date: {moment(selSlot.date).format("DD-MM-YYYY")} | Time:{" "}
                  {selSlot.time} | Teacher: {selSlot.teacher_id}
                </div> */}
                <div className="slot-details">
                  Date: {selDate} | Time:{" "}
                  {selTime} | Teacher: {selSlot.teacher_id}
                </div>
                <div className="warning">
                  You won't be able to change this booking later. Please contact
                  your RM if any emergency.
                </div>
                <Button positive onClick={() => bookClass(selSlot)}>
                  Confirm
                </Button>
              </div>
            </Modal>
          </div>
        )}
      </div>
      <Popup />
    </>
  );
}

export default Studenttimecalendar;
