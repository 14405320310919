import React, { useState, useEffect, useLayoutEffect } from "react";
import "./list_style.css";
import { Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {
  FaRegQuestionCircle,
  FaCalendarAlt,
  FaFileAlt,
  FaChartPie,
  FaStickyNote,
  FaPowerOff,
} from "react-icons/fa";
import { MdAssignment, MdDashboard, MdNotificationsNone } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import Popup from "../../teacher/popup/popup";
import { studentQuizzes, dashboardDoubts } from "../../../crud/student.crud";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteStudent } from "../../../redux/actions/student";
import pic_2 from "../dashboard/img/pic_2.png";

// images
import vector_3_2x from "./img/vector-3@2x.png";
import vector_192_2x from "./img/vector-192@2x.png";
import vector_208_2x from "./img/vector-208@2x.png";
import vector_209_2x from "./img/vector-209@2x.png";
import vector_360_2x from "./img/vector-360@2x.png";
import rectangle_7 from "./img/rectangle-7@1x.png";
import ellipse_2_2x from "./img/ellipse-2@2x.png";
import vector_4_2x from "./img/vector-4@2x.png";
import rectangle_40 from "./img/rectangle-40@1x.png";
import line5_2x from "./img/line-5@2x.png";
import line5_1_2x from "./img/line-5-1@2x.png";
import line6_2x from "./img/line-6@2x.png";
import line6_1_2x from "./img/line-6-1@2x.png";

import SideDrawerStudent from "../../../UI/sideDrawer/sideDrawerStudent";

function Studentquiz(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const user = useSelector((state) => state.studentDetails.student);
  const [quizzes, setQuizzes] = useState([]);
  const [doubts, setDoubts] = useState([]);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteStudent(reduxUser.enrolled_student_id));
    removeToken();
  };

  useEffect(() => {
    studentQuizzes(user.enrolled_student_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setQuizzes(false);
        } else setQuizzes(res);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    dashboardDoubts(user.enrolled_student_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setDoubts(false);
        } else setDoubts(res);
      })
      .catch((err) => console.log(err));
  }, []);

  const subjectOptions = [
    {
      key: "_1",
      text: "English",
      value: "English",
    },
    {
      key: "_2",
      text: "Hindi",
      value: "Hindi",
    },
    {
      key: "_3",
      text: "Science",
      value: "Science",
    },
    {
      key: "_4",
      text: "Maths",
      value: "Maths",
    },
    {
      key: "_5",
      text: "Social Studies",
      value: "Social Studies",
    },
    {
      key: "_6",
      text: "English",
      value: "English",
    },
    {
      key: "_7",
      text: "Hindi",
      value: "Hindi",
    },
    {
      key: "_8",
      text: "Science",
      value: "Science",
    },
    {
      key: "_9",
      text: "Maths",
      value: "Maths",
    },
    {
      key: "_10",
      text: "Social Studies",
      value: "Social Studies",
    },
  ];

  const stateOptions = [
    {
      key: "_1",
      text: "English",
      value: "English",
    },
    {
      key: "_2",
      text: "Hindi",
      value: "Hindi",
    },
    {
      key: "_3",
      text: "Science",
      value: "Science",
    },
    {
      key: "_4",
      text: "Maths",
      value: "Maths",
    },
    {
      key: "_5",
      text: "Social Studies",
      value: "Social Studies",
    },
    {
      key: "_6",
      text: "English",
      value: "English",
    },
    {
      key: "_7",
      text: "Hindi",
      value: "Hindi",
    },
    {
      key: "_8",
      text: "Science",
      value: "Science",
    },
    {
      key: "_9",
      text: "Maths",
      value: "Maths",
    },
    {
      key: "_10",
      text: "Social Studies",
      value: "Social Studies",
    },
  ];

  return (
    <>
      <form
        className="student-quiz screen"
        name="form1"
        action="form1"
        method="post"
      >
        <div className="student-re-flex">
          <div className="component-1-C61RwLz-3">
            <img
              alt="image"
              src={pic_2}
              style={{ height: "115px", marginTop: "-8%" }}
            />
          </div>

          <div className="group-22-GyJUNH-student-dash">
            <div className="flex-col-vgli2x-student-dash">
              <div className="biba-sobti-h8V8ZR-student-dash">
                {user.first_name + " " + user.last_name}
              </div>
              <div className="bibasobtigmailcom-h8V8ZR-student-dash">
                {user && user.email}
              </div>
            </div>
            <img className="vector-vgli2x-student-dash" src={vector_3_2x} />
          </div>
        </div>
        <img className="rectangle-7-C61RwL" src={rectangle_7} />
        {/* <div className="notifications-24px-2-1-C61RwL">
          <div className="vector-CLdsug"><MdNotificationsNone size={28} /></div>
          <img className="ellipse-2-OSIBbx" src={ellipse_2_2x} />
        </div> */}
        <div className="group-46-C61RwL-eexs">
          {quizzes.length > 0 ? (
            quizzes.map((quiz) => {
              return (
                <Link to={`/student-quiz-detail/${quiz.student_response.id}`}>
                  <div className="rectangle-40-AvtzuR">
                    <div className="component-29-AvtzuR">
                      <div className="assignment-24px-3-BZ3lUO">
                        <div className="vector-Pdmq9p">
                          <RiQuestionnaireFill color="white" />
                        </div>
                      </div>
                    </div>
                    <div className="school-24px-1-AvtzuR">
                      <img className="vector-wGsDUK" src={vector_4_2x} />
                    </div>
                    <div className="quizTile-col">
                      <div className="montserrat-semi-bold-blue-zodiac-14px">
                        {quiz.quiz_data.quiz_name.toUpperCase()}
                      </div>
                      <div className="montserrat-medium-gray-10px">
                        {quiz.meta.subject_id} ({quiz.meta.teacher_id})
                      </div>
                    </div>
                    <div className="graded-AvtzuR">
                      {quiz.student_response.status}
                    </div>
                  </div>
                </Link>
              );
            })
          ) : (
            <div className="montserrat-semi-bold-blue-zodiac-18px">
              You have no quizzes.
            </div>
          )}
        </div>
        <div className="my-quiz-C61RwL">
          <span className="span0-RD25Gb">My</span>
          <span className="span1-RD25Gb"> Quiz</span>
        </div>
        <div className="rectangle-43-C61RwL">
          <div className="doubt-forum-C61RwL montserrat-semi-bold-blue-zodiac-18px">
            Doubt Forum
          </div>
          <Link to="/student-doubt-list">
            <div className="view-all-C61RwL montserrat-semi-bold-gray-12px">
              View all
            </div>
          </Link>

          {doubts.length > 0 ? (
            doubts.slice(0, 4).map((doubt) => {
              return (
                <div className="doubt-row">
                  <FaRegQuestionCircle size={30} />
                  <Link>
                    <div
                      className="montserrat-semi-bold-blue-zodiac-18px"
                      style={{ color: "rgba(126,126,126,1.0)" }}
                    >
                      {doubt.doubt_text.length > 31
                        ? doubt.doubt_text.substring(0, 27) + "..."
                        : doubt.doubt_text}
                    </div>
                  </Link>
                  {/* <div className="view-forum-C61RwL montserrat-semi-bold-gray-16px">View</div> */}
                </div>
              );
            })
          ) : (
            <div />
          )}
        </div>

        {/* <div className="what-is-the-si-unit-of-current-C61RwL montserrat-semi-bold-blue-zodiac-18px">
        What is the SI unit of Current?
      </div>
      <div className="how-to-solve-this-C61RwL montserrat-semi-bold-blue-zodiac-18px">How to solve this?</div>
      <div className="what-is-the-sol-f-this-question-C61RwL montserrat-semi-bold-blue-zodiac-18px">
        What is the solution of this question?
      </div>
      <div className="view-forum-C61RwL montserrat-semi-bold-gray-16px">View Forum</div>
      <div className="view-forum-VMr6Om montserrat-semi-bold-gray-16px">View Forum</div>
      <div className="view-forum-mzXdH9 montserrat-semi-bold-gray-16px">View Forum</div> */}
        {/* <div className="join-4-C61RwL">
        <div className="group-Ie5dUU">
          <FaRegQuestionCircle size={30} />
        </div>
      </div>
      <div className="join-5-C61RwL">
        <div className="group-Zmqz7u">
          <FaRegQuestionCircle size={30} />
        </div>
      </div>
      <div className="join-6-C61RwL">
        <div className="group-KsMB6y">
          <FaRegQuestionCircle size={30} />
        </div>
      </div> */}
        {/* <div className="please-check-my-approach-C61RwL montserrat-semi-bold-blue-zodiac-18px">Please check my approach.</div>
      <div className="view-forum-QxM5SU montserrat-semi-bold-gray-16px">View Forum</div> */}
        {/* <div className="join-7-C61RwL">
        <div className="group-mnqxdV">
          <FaRegQuestionCircle size={30} />
        </div>
      </div> */}

        {/* <div className="component-30-C61RwL border-1px-black">
          <div className="choose-subject-i63336282371261-02ks1v montserrat-medium-gray-10px">Choose subject</div>
          <div className="expandmore-24px-1-02ks1v">
            <img className="vector-wGeoOk" src={vector_4_2x} />
            <img className="vector-5FRq6D" src={vector_192_2x} />
          </div>
        </div> */}

        {/* <div className="component-30-C61RwL">
          <Dropdown
            placeholder="Choose Subject"
            fluid
            selection
            options={subjectOptions}
          />
        </div> */}

        {/* <div className="component-31-C61RwL border-1px-black">
          <div className="choose-subject-i63336292371261-ftWxNG montserrat-medium-gray-10px">Choose status</div>
          <div className="expandmore-24px-1-ftWxNG">
            <img className="vector-N78QUN" src={vector_4_2x} />
            <img className="vector-HTuX41" src={vector_192_2x} />
          </div>
        </div> */}

        {/* <div className="component-31-C61RwL">
          <Dropdown
            placeholder="Choose Status"
            fluid
            selection
            options={stateOptions}
          />
        </div>

        <a href="javascript:SubmitForm('form1')">
          <div className="rectangle-41-C61RwL">
            <div className="apply-filters-C61RwL">Apply filters</div>
          </div>
        </a> */}
        {/* <div className="apply-filters-C61RwL">Apply filters</div> */}
        <div className="rectangle-24-C61RwL-stu-s1">
          <Link to="/student-dashboard">
            <div data-tip="Dashboard">
              <MdDashboard size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-date-calendar">
            <div data-tip="Date Calendar">
              <FaCalendarAlt size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-assignment-list">
            <div data-tip="Assignments">
              <MdAssignment size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-quiz-list">
            <div data-tip="Quizzes">
              <RiQuestionnaireFill size={24} color="#dc3545" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-curriculum-list">
            <div data-tip="Curriculums">
              <FaFileAlt size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-doubt-list">
            <div data-tip="Doubts">
              <FaRegQuestionCircle size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-performance" data-tip="Performance">
            <div>
              <FaChartPie size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-notes-list">
            <div data-tip="Lecture Notes">
              <FaStickyNote size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/" onClick={() => handleLogout()}>
            <FaPowerOff
              size={24}
              data-tip="Logout"
              color="rgba(179,179,179,1.0)"
            />
            <ReactTooltip />
          </Link>
        </div>

        {/* <div className="component-1-C61RwL">
          <img
            alt="image"
            src={pic_2}
            style={{ height: "115px", marginTop: "-8%" }}
          />
        </div> */}
      </form>
      <form
        className="student-quiz-list-mobile screen"
        name="form2"
        action="form2"
        method="post"
      >
        <div className="component-1-C61RwL">
          <img
            alt="image"
            src={pic_2}
            style={{ height: "90px", marginTop: "-8%" }}
          />
        </div>

        {/* This is the Hamburger icon  */}
        <SideDrawerStudent
          open={displaySideDrawer}
          closeSideDrawer={sideDrawerToggler}
        />
        <div className="component-6-C61RwL" onClick={sideDrawerToggler}>
          <div className="rectangle-48-5mfU7d"></div>
          <div className="rectangle-49-5mfU7d"></div>
          <div className="rectangle-50-5mfU7d"></div>
        </div>

        <div className="group-85-C61RwL">
          <div className="rectangle-44-rFX9Nh">
            {doubts.length > 0 ? (
              doubts.slice(0, 4).map((doubt) => {
                return (
                  <div className="doubt-row">
                    <FaRegQuestionCircle size={30} />
                    <Link>
                      <div className="montserrat-semi-bold-blue-zodiac-18px">
                        {doubt.doubt_text.length > 31
                          ? doubt.doubt_text.substring(0, 27) + "..."
                          : doubt.doubt_text}
                      </div>
                    </Link>
                    {/* <div className="view-forum-C61RwL montserrat-semi-bold-gray-16px">View</div> */}
                  </div>
                );
              })
            ) : (
              <div />
            )}
          </div>
          <div className="doubt-forum-rFX9Nh">Doubt Forum</div>
          <Link to="/student-doubt-list">
            <div className="view-all-rFX9Nh montserrat-semi-bold-gray-12px">
              View all
            </div>
          </Link>
        </div>
        {/* <a href="javascript:SubmitForm('form2')">
          <div className="group-95-C61RwL">
            <div className="group-94-IUHrKR">
              <div className="rectangle-41-g6la27"></div>
              <div className="apply-filters-g6la27">Apply filters</div>
            </div>
          </div>
        </a> */}
        <div className="my-quiz-C61RwL">
          <span className="span0-RD25Gb">My</span>
          <span className="span1-RD25Gb"> Quiz</span>
        </div>

        <div className="group-96-C61Rw-L-0101">
          {/* <div className="rectangle-40-3U5qE3"></div> */}
          {quizzes.length > 0 ? (
            quizzes.map((quiz) => {
              return (
                <Link to={`/student-quiz-detail/${quiz.student_response.id}`}>
                  <div className="rectangle-40-3U5qE3-0001-2">
                    <div className="component-29-AvtzuR">
                      <div className="assignment-24px-3-BZ3lUO">
                        <div className="vector-Pdmq9p">
                          <RiQuestionnaireFill color="white" />
                        </div>
                      </div>
                    </div>
                    {/* <div className="school-24px-1-AvtzuR"><img className="vector-wGsDUK" src={vector_4_2x} /></div> */}
                    <div className="quizTile-col">
                      <div className="montserrat-semi-bold-blue-zodiac-14px">
                        {quiz.quiz_data.quiz_name.toUpperCase()}
                      </div>
                      <div className="montserrat-medium-gray-10px">
                        {quiz.meta.subject_id} ({quiz.meta.teacher_id})
                      </div>
                    </div>
                    <div className="graded-AvtzuR">
                      {quiz.student_response.status}
                    </div>
                  </div>
                </Link>
              );
            })
          ) : (
            <div className="montserrat-semi-bold-blue-zodiac-18px">
              You have no quizzes.
            </div>
          )}
        </div>

        {/* <div className="group-99-C61RwL"> */}
          {/* <div className="component-30-ZnSQrh border-1px-black">
            <div className="choose-subject-i63333002371261-iR8muk montserrat-medium-gray-10px">Choose subject</div>
            <div className="expandmore-24px-1-iR8muk">
              <img className="vector-xVlxUo" src={vector_4_2x} />
              <img className="vector-eMfSFf" src={vector_360_2x} />
            </div>
          </div>
          <div className="component-31-ZnSQrh border-1px-black">
            <div className="choose-subject-i63333012371261-DRd40k montserrat-medium-gray-10px">Choose status</div>
            <div className="expandmore-24px-1-DRd40k">
              <img className="vector-VOHrmv" src={vector_4_2x} />
              <img className="vector-xsv7LI" src={vector_360_2x} />
            </div>
          </div> */}

          {/* <div className="component-30-ZnSQrh-01-1">
            <Dropdown
              placeholder="Choose Subject"
              fluid
              selection
              options={subjectOptions}
            />
          </div>

          <div className="component-31-ZnSQrh">
            <Dropdown
              placeholder="Choose Status"
              fluid
              selection
              options={stateOptions}
            />
          </div>
        </div> */}

        {/* <div className="group-65-C61RwL">
          <div className="component-29-87p3Hy"></div>
          <div className="group-64-87p3Hy"></div>
        </div>
        <div className="group-102-C61RwL">
          <div className="component-29-eYqyUC"></div>
          <div className="group-64-eYqyUC"></div>
        </div>
        <div className="group-101-C61RwL">
          <div className="component-29-wAIKMX"></div>
          <div className="group-64-wAIKMX"></div>
        </div>
        <div className="group-100-C61RwL">
          <div className="component-29-lvfzGr"></div>
          <div className="group-64-lvfzGr"></div>
        </div> */}
      </form>
      <Popup />
    </>
  );
}

export default Studentquiz;
