import React, { createContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SERVER } from "./utils";
import { addIssue } from './redux/actions/rm';

const WebSocketContext = createContext(null)

export { WebSocketContext }

// eslint-disable-next-line
export default ({ children }) => {
  let socket;
  let ws;

  const dispatch = useDispatch();

  const loggedInUser = useSelector(state => state);
  const userToken = localStorage.getItem("access");

  const sendMessage = (message) => {
    const payload = message
    // ExSocket.get().send(JSON.stringify(payload));
    socket.send(JSON.stringify(payload))
  }

  if (!socket) {
    if (userToken) {
        let rm = ""
        if (loggedInUser.studentDetails.student) {
            rm = loggedInUser.studentDetails.student.rm_id
        } else if (loggedInUser.teacherDetails.teacher) {
            rm = loggedInUser.teacherDetails.teacher.rm_id
        } else if (loggedInUser.rmDetails.rm) {
            rm = loggedInUser.rmDetails.rm.rm_id
        } else rm = "NaN"

    var loc = window.location;
    var wsStart = 'ws://';
    if (loc.protocol === 'https:') {
        wsStart = 'wss://'
    }

      socket = new WebSocket(
        wsStart
        + SERVER.split("//", 2)[1]
        + 'ws/chat/'
        + rm.split("@")[0]
        + `/?token=${userToken}`
      );
  
      socket.onmessage = msg => {
        const payload = JSON.parse(msg.data);
        if (payload.type === "issue_created") {
          if (loggedInUser.rmDetails.rm) {
            dispatch(addIssue(payload));
          }
        } else if (payload.type === "chat_message") {
            console.log(payload.message);
        }
      };
  
      ws = {
        socket: socket,
        sendMessage
      }
    } 
  }

  return (
    <WebSocketContext.Provider value={ws}>
      {children}
    </WebSocketContext.Provider>
  )
} 