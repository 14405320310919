import React from "react";
import "./sider.css";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../utils";
import { deleteTeacher } from "../../redux/actions/teacher";
import pic_3 from "../../pages/student/dashboard/img/pic_3.png";
import { NavLink } from "react-router-dom";

import DashboardIcon from "@material-ui/icons/Dashboard";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SchoolIcon from "@material-ui/icons/School";
import DescriptionIcon from "@material-ui/icons/Description";
import HelpIcon from "@material-ui/icons/Help";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import NotesIcon from "@material-ui/icons/Notes";

function Sider() {
  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteTeacher(reduxUser.teacher_id));
    removeToken();
  };

  return (
    <div className="sider-disp-center">
      <div className="sider">
        {/* <div style={{ width: '70%', height: '70px', border: '1px solid #F4F4F4'}}></div> */}
        <img
          alt="image"
          src={pic_3}
          style={{ height: "115px", marginTop: "0%", marginLeft: "14%" }}
        />

        <NavLink to="/teacher-dashboard" exact>
          <div className="sider-ele">
            <DashboardIcon style={{ color: "#dc3545" }} />
            <span className="sider-ele-tag" style={{ color: "white" }}>
              Dashboard
            </span>
          </div>
        </NavLink>
        <NavLink to="/teacher-date-calendar" exact>
          <div className="sider-ele">
            <EventIcon />
            <span className="sider-ele-tag">Scheduler</span>
          </div>
        </NavLink>
        <NavLink to="/teacher-previous-classes">
          <div className="sider-ele">
            <ScheduleIcon />
            <span className="sider-ele-tag">Previous Class</span>
          </div>
        </NavLink>
        <NavLink to="/teacher-students">
          <div className="sider-ele">
            <SchoolIcon />
            <span className="sider-ele-tag">Enrolled Student</span>
          </div>
        </NavLink>
        <NavLink to="/teacher-curriculum-list">
          <div className="sider-ele">
            <DescriptionIcon />
            <span className="sider-ele-tag">Curriculum</span>
          </div>
        </NavLink>
        <NavLink to="/teacher-doubt-list">
          <div className="sider-ele">
            <HelpIcon />
            <span className="sider-ele-tag">Doubts</span>
          </div>
        </NavLink>
        <NavLink to="/teacher-analytics">
          <div className="sider-ele">
            <AssessmentIcon />
            <span className="sider-ele-tag">Analytics</span>
          </div>
        </NavLink>
        <NavLink to="/teacher-assignments">
          <div className="sider-ele">
            <AssignmentIcon />
            <span className="sider-ele-tag">Assignments</span>
          </div>
        </NavLink>
        <NavLink to="/teacher-quizzes">
          <div className="sider-ele">
            <LibraryBooksIcon />
            <span className="sider-ele-tag">Quiz</span>
          </div>
        </NavLink>
        <NavLink to="/teacher-lecture-notes">
          <div className="sider-ele">
            <NotesIcon />
            <span className="sider-ele-tag">Lecture Notes</span>
          </div>
        </NavLink>
        {/* <NavLink to="/teacher-payments">
                        <div className="sider-ele">
                            <AccountBalanceIcon />
                            <span className="sider-ele-tag">Payments</span>
                        </div>
                    </NavLink> */}
        <div style={{ padding: "8px" }}></div>
        <NavLink onClick={handleLogout} to="/">
          <div className="sider-ele">
            <PowerSettingsNewIcon />
            <span className="sider-ele-tag">Logout</span>
          </div>
        </NavLink>
      </div>
    </div>
  );
}

export default Sider;
