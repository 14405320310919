import React, { useState } from "react";
import classes from './index.module.css';

import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form, Message, Dropdown } from 'semantic-ui-react';
import DropdownOption from "../higher_committee/dropdown/dropdown2";
import pic_2 from "../student/dashboard/img/pic_2.png";
import { AddNewFolder } from "../../crud/highercommittee.crud";

import { S3materialconfig } from "../../s3/s3materialconfig.js";
import S3FileUpload from "react-s3";
// import { normalizeUnits } from "moment";


function AddFolder(props) {
    const [isErrorShown, setIsErrorShown] = useState(false);
    const [loading, setLoading] = useState(false);

    function upload(e) {
        S3FileUpload.uploadFile(e.target.files[0], S3materialconfig)
        .then((data) => {
            setUrl(data.location);
        })
        .catch((err) => {
            alert(err);
        });
    }

    const materialTypeOptions = [
        {
            'key': 'Books',
            'text': 'Books',
            'value': 'Books'
        },
        {
            'key': 'Sample Papers',
            'text': 'Sample Papers',
            'value': 'SamplePapers'
        },
        {
            'key': 'Previous Year Papers',
            'text': 'Previous Year Papers',
            'value': 'PreviousYearPapers'
        },
        {
            'key': 'Syllabus',
            'text': 'Syllabus',
            'value': 'Syllabus'
        },
        {
            'key': 'Lecture Notes',
            'text': 'Lecture Notes',
            'value': 'LectureNotes'
        },
        {
            'key': 'Support Material',
            'text': 'Support Material',
            'value': 'SupportMaterial'
        },
    ]

    const [folder_name, setName] = useState("");
    const [folder_type, setType] = useState("");
    const [grade_id, setGrade] = useState("");
    const [subject_id, setSubject] = useState("");
    const [folder_url, setUrl] = useState("");

    const handleMaterialTypeSelect = val => setType(val)

    const onSubmit = () => {
        if (folder_name == "" || folder_type == "" || folder_url == "" || grade_id == "" || subject_id == "") {
            alert("Please fill all fields and choose a file to upload.")
        } else {
            // call api to store data 
            AddNewFolder({
                folder_name,
                folder_type,
                grade_id,
                subject_id,
                folder_url,
                id: `${folder_name}_${grade_id}_${subject_id}`
            })
                .then(res => {
                    alert("Folder uploaded successfully.")
                })
                .catch(err => console.log(err))
            }
    }

    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <div className={classes.Login_outer}>
            <div className={classes.Login_Layout}>
                <img className={classes.Logo_Margin} src={pic_2} width="208px" height="185px" alt="TheRightGuru Logo" />
                <p className={classes.Login_Page_Heading}>Add New Folder</p>
                <Form className={classes.Login_Form_Handler}>
                    <Form.Input
                        fluid icon='file'
                        iconPosition='left'
                        placeholder='Folder Name'
                        onChange={e => setName(e.target.value)}
                        className={classes.Login_Form_Input}
                    />
                    <Dropdown
                        placeholder="Choose Type"
                        fluid
                        search
                        selection
                        options={materialTypeOptions}
                        onChange={(e, { value }) => handleMaterialTypeSelect(value)}
                        className={classes.Login_Form_Input}
                    />
                     <Form.Field style={{marginTop: "20px", width: "100%"}}>
                    <DropdownOption
                        handleGradeSelect={(v) => setGrade(v)}
                        handleSubjectSelect={(v) => setSubject(v)}
                    />
                    </Form.Field>
                    <Form.Input
                        placeholder='Folder URL'
                        onChange={e => setUrl(e.target.value)}
                        className={classes.Login_Form_Input}
                    />
                    {/* <label for="File-Input" style={{ cursor: "pointer" }}>
                        Choose File
                    </label>
                    <input
                        id="File-Input"
                        style={{ display: "none" }}
                        type="file"
                        onChange={(e) => upload(e)}
                    /> */}
                    <button
                        onClick={onSubmit}
                        e='password'
                        // onChange={e => setPassword(e.target.value)}
                        disabled={loading}
                        className={classes.Login_Butt_Sub}
                    >
                        Submit
                    </button>
                </Form>
            </div>
        </div>
    )
}

export default AddFolder;