import React, { Fragment } from "react";
import "./sideDrawer.css";

import { NavLink } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../utils";
import { deleteStudent } from "../../redux/actions/student";

import DashboardIcon from "@material-ui/icons/Dashboard";
import EventIcon from "@material-ui/icons/Event";
import DescriptionIcon from "@material-ui/icons/Description";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import NotesIcon from "@material-ui/icons/Notes";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import PieChartIcon from "@material-ui/icons/PieChart";

import Backdrop from "../Backdrop/Backdrop";

const SideDrawerStudent = (props) => {
  let attclasses = ["sideDrawer", "Close_SideD"];

  if (props.open) {
    attclasses = ["sideDrawer", "Open_SideD"];
  }
  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteStudent(reduxUser.enrolled_student_id));
    removeToken();
  };

  return (
    <Fragment>
      <Backdrop open={props.open} clicked={props.closeSideDrawer} />
      <div className={attclasses.join(" ")}>
        <div
          style={{
            width: "70%",
            height: "70px",
            border: "1px solid #F4F4F4",
            marginLeft: "30px",
          }}
        ></div>

        <NavLink to="/student-dashboard" exact>
          <div className="sider-ele-mob">
            <DashboardIcon />
            <span className="sider-ele-mob-tag">Dashboard</span>
          </div>
        </NavLink>
        <NavLink to="/student-date-calendar" exact>
          <div className="sider-ele-mob">
            <EventIcon />
            <span className="sider-ele-mob-tag">Calender</span>
          </div>
        </NavLink>
        <NavLink to="/student-assignment-list" exact>
          <div className="sider-ele-mob">
            <AssignmentIcon />
            <span className="sider-ele-mob-tag">Assignments</span>
          </div>
        </NavLink>
        <NavLink to="/student-quiz-list" exact>
          <div className="sider-ele-mob">
            <LibraryBooksIcon />
            <span className="sider-ele-mob-tag">Quiz</span>
          </div>
        </NavLink>
        <NavLink to="/student-curriculum-list" exact>
          <div className="sider-ele-mob">
            <DescriptionIcon />
            <span className="sider-ele-mob-tag">Curriculum</span>
          </div>
        </NavLink>
        <NavLink to="/student-doubt-list" exact>
          <div className="sider-ele-mob">
            <HelpOutlineIcon />
            <span className="sider-ele-mob-tag">Doubts</span>
          </div>
        </NavLink>
        <NavLink to="/student-performance" exact>
          <div className="sider-ele-mob">
            <PieChartIcon />
            <span className="sider-ele-mob-tag">Performance</span>
          </div>
        </NavLink>

        <NavLink to="/student-notes-list" exact>
          <div className="sider-ele-mob">
            <NotesIcon />
            <span className="sider-ele-mob-tag">Lecture Notes</span>
          </div>
        </NavLink>
        <NavLink to="/" exact onClick={() => handleLogout()}>
          <div className="sider-ele-mob">
            <PowerSettingsNewIcon />
            <span className="sider-ele-mob-tag">Logout</span>
          </div>
        </NavLink>
      </div>
    </Fragment>
  );
};

export default SideDrawerStudent;
