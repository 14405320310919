import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Modal from 'react-modal';
import Select from 'react-dropdown-select';
import validator from 'validator';
import generator from 'generate-password';
import { Button } from 'react-bootstrap';
import { Form, Message } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { getStudent } from "../../../redux/actions/student";
import { login, sendOtp, verifyOtp } from "../../../crud/auth.crud";
import { bookTrialClass } from "../../../crud/student.crud";
import { setToken } from "../../../utils";

import "./App.css";
import CustomNav from "../../../components/header/Navbar";
import { Dropdown } from 'semantic-ui-react';

import { ReactTransliterate } from "react-transliterate";
import "react-transliterate/dist/index.css";
import LoginModal from '../../../components/Login_modal/loginModal';
import DropdownOption from '../../higher_committee/dropdown/dropdown_grade';
import DropdownSubjectOption from '../../higher_committee/dropdown/dropdown_subject';

import OtpInput from 'react-otp-input';
import Backdrop from '../../../UI/Backdrop/Backdrop';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

Modal.setAppElement('#root');

const countryOptions = [
  {
    value: "India",
    text: "India",
    key: "India"
  },
  {
    value: "United Kingdom",
    text: "United Kingdom",
    key: "UnitedKingdom"
  },
  {
    value: "USA",
    text: "USA",
    key: "USA"
  },
  {
    value: "Canada",
    text: "Canada",
    key: "Canada"
  },
  {
    value: "New Zealand",
    text: "New Zealand",
    key: "NewZealand"
  },
  {
    value: "Australia",
    text: "Australia",
    key: "Australia"
  }
];

const usaStateOptions = [
  {
    value: "Alabama",
    text: "Alabama",
    key: "ALB"
  },
  {
    value: "Alaska",
    text: "Alaska",
    key: "ALS"
  },
  {
    value: "Arizona",
    text: "Arizona",
    key: "AR"
  },
  {
    value: "Arkansas",
    text: "Arkansas",
    key: "ARK"
  },
  {
    value: "California",
    text: "California",
    key: "CA"
  },
  {
    value: "Colorado",
    text: "Colorado",
    key: "CO"
  },
  {
    value: "Connecticut",
    text: "Connecticut",
    key: "CT"
  },
  {
    value: "Delaware",
    text: "Delware",
    key: "DE"
  },
  {
    value: "Florida",
    text: "Florida",
    key: "FL"
  },
  {
    value: "Georgia",
    text: "Georgia",
    key: "GA"
  },
  {
    value: "Hawaii",
    text: "Hawaii",
    key: "HA"
  },
  {
    value: "Idaho",
    text: "Idaho",
    key: "ID"
  },
  {
    value: "Illinois",
    text: "Illinois",
    key: "IL"
  },
  {
    value: "Indiana",
    text: "Indiana",
    key: "IN"
  },
  {
    value: "Iowa",
    text: "Iowa",
    key: "IO"
  },
  {
    value: "Kansas",
    text: "Kansas",
    key: "KA"
  },
  {
    value: "Kentucky",
    text: "Kentucky",
    key: "KT"
  },
  {
    value: "Louisiana",
    text: "Louisiana",
    key: "LO"
  },
  {
    value: "Maine",
    text: "Maine",
    key: "MN"
  },
  {
    value: "Maryland",
    text: "Maryland",
    key: "MR"
  },
  {
    value: "Massachusetts",
    text: "Massachusetts",
    key: "MA"
  },
  {
    value: "Michigan",
    text: "Michigan",
    key: "MI"
  },
  {
    value: "Minnesota",
    text: "Minnesota",
    key: "MNS"
  },
  {
    value: "Mississippi",
    text: "Mississippi",
    key: "MSP"
  },
  {
    value: "Missouri",
    text: "Missouri",
    key: "MS"
  },
  {
    value: "Montana",
    text: "Montana",
    key: "MO"
  },
  {
    value: "Nebraska",
    text: "Nebraska",
    key: "NB"
  },
  {
    value: "Nevada",
    text: "Nevada",
    key: "NE"
  },
  {
    value: "New Hampshire",
    text: "New Hampshire",
    key: "NH"
  },
  {
    value: "New Jersey",
    text: "New Jersey",
    key: "NJ"
  },
  {
    value: "New Mexico",
    text: "New Mexico",
    key: "NM"
  },
  {
    value: "New York",
    text: "New York",
    key: "NY"
  },
  {
    value: "North Carolina",
    text: "North Carolina",
    key: "NC"
  },
  {
    value: "North Dakota",
    text: "North Dakota",
    key: "ND"
  },
  {
    value: "Ohio",
    text: "Ohio",
    key: "OH"
  },
  {
    value: "Oklahoma",
    text: "Oklahoma",
    key: "OK"
  },
  {
    value: "Oregon",
    text: "Oregon",
    key: "OR"
  },
  {
    value: "Pennsylvania",
    text: "Pennsylvania",
    key: "PA"
  },
  {
    value: "Rhode Island",
    text: "Rhode Island",
    key: "RI"
  },
  {
    value: "South Carolina",
    text: "South Carolina",
    key: "SC"
  },
  {
    value: "South Dakota",
    text: "South Dakota",
    key: "SD"
  },
  {
    value: "Tennessee",
    text: "Tennessee",
    key: "TN"
  },
  {
    value: "Texas",
    text: "Texas",
    key: "TA"
  },
  {
    value: "Utah",
    text: "Utah",
    key: "UT"
  },
  {
    value: "Vermont",
    text: "Vermont",
    key: "VE"
  },
  {
    value: "Virginia",
    text: "Virginia",
    key: "VA"
  },
  {
    value: "Washington",
    text: "Washington",
    key: "WA"
  },
  {
    value: "West Virginia",
    text: "West Virginia",
    key: "WV"
  },
  {
    value: "Wisconsin",
    text: "Wisconsin",
    key: "WI"
  },
  {
    value: "Wyoming",
    text: "Wyoming",
    key: "WY"
  },
]

const ausStateOptions = [
  {
    value: "New South Wales",
    text: "New South Wales",
    key: "NSW"
  },
  {
    value: "Queensland",
    text: "Queensland",
    key: "QN"
  },
  {
    value: "South Australia",
    text: "South Australia",
    key: "SA"
  },
  {
    value: "Tasmania",
    text: "Tasmania",
    key: "TA"
  },
  {
    value: "Victoria",
    text: "Victoria",
    key: "VI"
  },
  {
    value: "Western Australia",
    text: "Western Australia",
    key: "WA"
  },
  {
    value: "Australian Capital Territory",
    text: "Australian Capital Territory",
    key: "ACT"
  },
  {
    value: "Northern Territory",
    text: "Northern Territory",
    key: "NT"
  },
]

const stateOptions = [
  {
    value: "Andaman and Nicobar Islands",
    text: "Andaman and Nicobar Islands",
    key: "AN"
  },
  {
    value: "Andhra Pradesh",
    text: "Andhra Pradesh",
    key: "AP"
  },
  {
    value: "Arunachal Pradesh",
    text: "Arunachal Pradesh",
    key: "AR"
  },
  {
    value: "Assam",
    text: "Assam",
    key: "AS"
  },
  {
    value: "Bihar",
    text: "Bihar",
    key: "BR"
  },
  {
    value: "Chandigarh",
    text: "Chandigarh",
    key: "CG"
  },
  {
    value: "Chhattisgarh",
    text: "Chhattisgarh",
    key: "CH"
  },
  {
    value: "Dadra and Nagar Haveli",
    text: "Dadra and Nagar Haveli",
    key: "DH"
  },
  {
    value: "Daman and Diu",
    text: "Daman and Diu",
    key: "DD"
  },
  {
    value: "Delhi",
    text: "Delhi",
    key: "DL"
  },
  {
    value: "Goa",
    text: "Goa",
    key: "GA"
  },
  {
    value: "Gujarat",
    text: "Gujarat",
    key: "GJ"
  },
  {
    value: "Haryana",
    text: "Haryana",
    key: "HR"
  },
  {
    value: "Himachal Pradesh",
    text: "Himachal Pradesh",
    key: "HP"
  },
  {
    value: "Jammu and Kashmir",
    text: "Jammu and Kashmir",
    key: "JK"
  },
  {
    value: "Jharkhand",
    text: "Jharkhand",
    key: "JH"
  },
  {
    value: "Karnataka",
    text: "Karnataka",
    key: "KA"
  },
  {
    value: "Kerala",
    text: "Kerala",
    key: "KL"
  },
  {
    value: "Lakshadweep",
    text: "Lakshadweep",
    key: "LD"
  },
  {
    value: "Madhya Pradesh",
    text: "Madhya Pradesh",
    key: "MP"
  },
  {
    value: "Maharashtra",
    text: "Maharashtra",
    key: "MH"
  },
  {
    value: "Manipur",
    text: "Manipur",
    key: "MN"
  },
  {
    value: "Meghalaya",
    text: "Meghalaya",
    key: "ML"
  },
  {
    value: "Mizoram",
    text: "Mizoram",
    key: "MZ"
  },
  {
    value: "Nagaland",
    text: "Nagaland",
    key: "NL"
  },
  {
    value: "Odisha",
    text: "Odisha",
    key: "OR"
  },
  {
    value: "Puducherry",
    text: "Puducherry",
    key: "PY"
  },
  {
    value: "Punjab",
    text: "Punjab",
    key: "PB"
  },
  {
    value: "Rajasthan",
    text: "Rajasthan",
    key: "RZ"
  },
  {
    value: "Sikkim",
    text: "Sikkim",
    key: "SK"
  },
  {
    value: "Tamil Nadu",
    text: "Tamil Nadu",
    key: "TN"
  },
  {
    value: "Telangana",
    text: "Telangana",
    key: "TS"
  },
  {
    value: "Tripura",
    text: "Tripura",
    key: "TR"
  },
  {
    value: "Uttar Pradesh",
    text: "Uttar Pradesh",
    key: "UP"
  },
  {
    value: "Uttarakhand",
    text: "Uttarakhand",
    key: "UK"
  },
  {
    value: "West Bengal",
    text: "West Bengal",
    key: "WB"
  }
];

const selectStyles = {
  width: "100%",
  borderRadius: "5px",
  border: "2px solid rgba(217, 83, 79, 0.4)",
  fontSize: "15px",
  fontWeight: 400,
  fontFamily: "'Montserrat', Helvetica",
}

function Landing() {
  const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [login_email, setLoginEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  const [grade_id, setGradeName] = useState("");
  const [subject_id, setSubjectName] = useState("");

  const handleGradeSelect = (value) => {
    setGradeName(value);
  };

  const handleSubjectSelect = (value) => {
    setSubjectName(value);
  };

  const [submitOtpStatus, setSubmitOtpStatus] = useState(true);
  const [isErrorShownOTP, setIsErrorShownOTP] = useState(false);
  const [otpValue, setOtpValue] = useState('');
  const [userOtpInput, setUserOtpInput] = useState('');
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);

  let attclasses = ["div-class-otp-modal-outline", "Close"];

  if (showOtpModal) {
    attclasses = ["div-class-otp-modal-outline", "Open"];
  }

  const handleResendClick = () => {
    const newOtp = generator.generate({
      length: 4,
      numbers: true,
      lowercase: false,
      uppercase: false
    })
    sendOtp({
      newOtp,
      "number": father_number
    })
      .then(res => {
        if (res.return) {
          setShowOtpModal(true);
          setOtpValue(newOtp);
          toast.info(`OTP sent successfully to ${father_number}`, {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(`Error in sending OTP. Please try again later.`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch(err => {
        console.log(err)
        toast.error(`Error in sending OTP. Please try again later.`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      })
  }

  const handleSendClick = () => {

    if (!validator.isEmail(email)) {
      alert("Please enter a valid email address.");
    } else if (
      country == "" 
    ) {
      alert("Please select country before submitting for otp verification.");
    } else {
      const newOtp = generator.generate({
        length: 4,
        numbers: true,
        lowercase: false,
        uppercase: false,
      });  

    sendOtp({
      newOtp,
      number: father_number,
      country
    })
      .then((res) => {
        if (res.return) {
          setShowOtpModal(true);
          setOtpValue(newOtp);
          toast.info(`OTP sent successfully to ${father_number}`, {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(`Error in sending OTP. Please try again later.`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Error in sending OTP. Please try again later.`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      });      
    }
  }
  
  const handleSubmitClick = () => {
    verifyOtp({
      otp: userOtpInput,
      number: father_number,
      country
    })
      .then(res => {
        console.log(res)
        if (res.verified) {
          setOtpVerified(true)
          setShowOtpModal(false);
          toast.success(`OTP verification successful.`, {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          setIsErrorShownOTP(false);
        } else {
          setIsErrorShownOTP(true);
          setUserOtpInput("");
        }
      })
  }
  const handleOtpSubmission = (value) => {
    setUserOtpInput(value);
  }
  useEffect(() => {
    if (otpValue.length > 3) {
      setSubmitOtpStatus(false);
    }
    else {
      setSubmitOtpStatus(true);
    }
  }, [otpValue])


  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  const closeModal = () => setIsModalOpen(previousState => !previousState);

  const handleLogin = () => {
    setLoading(true);
    setIsErrorShown(false);

    if (!login_email || !password) {
      setLoading(false);
      setIsErrorShown(true);
    } else {
      login(login_email, password)
        .then(({ data: { access, refresh, email, user_type } }) => {
          setLoading(false);
          dispatch(getStudent({ access, email, user_type }), history);
          setToken(access, refresh);
          // history.replace("/student-dashboard");
        })
        .catch(() => {
          setLoading(false);
          setIsErrorShown(true);
        })
    }
  }

  //form state
  const [first_name, setFirstName] = useState("")
  const [last_name, setLastName] = useState("")
  const [mobile_number, setMobileNumber] = useState("")
  const [father_name, setFatherName] = useState("")
  const [father_number, setFatherNumber] = useState("")
  const [email, setEmail] = useState("")
  const [country, setCountry] = useState("")
  const [state, setStudentState] = useState("")
  const [postal_code, setPostalCode] = useState("")
  const [current_grade, setCurrentGrade] = useState("")
  const [preferred_subject, setPreferredSubject] = useState("")


  const handleSubmit = () => {
    console.log({first_name, last_name, father_name, mobile_number, father_number, country, state, postal_code, grade_id, subject_id})
    if (!validator.isEmail(email)) {
      alert("Please enter a valid email address.")
    } else if (first_name == "" || last_name == "" || father_name == "" || father_number == "" || mobile_number == "" ||
      country == "" || state == "" || postal_code == "" || grade_id == "" || subject_id == "") {
      alert("Please fill all fields before submitting.")
    } else if (!otpVerified) {
      alert("Please complete OTP verification before submitting. Press on SEND OTP.")
    } else {
      bookTrialClass({
        first_name,
        last_name,
        mobile_number,
        father_name,
        father_number,
        email,
        country,
        state,
        postal_code,
        current_grade: grade_id,
        preferred_subject: subject_id
      })
        .then(() => {
          alert("Trial class booked successfully. We will soon contact you soon. \n Happy Learning!")
          history.replace("/");
        })
        .catch(() => {
          alert("There was an error in booking your trial class.\nPlease try again later")
        })
    }
  }

  return (
    <>
      <CustomNav Open={closeModal} />
      <div className="card-011-1">
        <div className="row">
          <div className="col-sm-4">
            {/* <ReactTransliterate placeholder="Student's First Name" value={first_name} onChangeText={newText => setFirstName(newText)} lang="hi" /> */}
            <input
              className="bot-mar"
              style={{ height: "45px" }}
              type="text"
              placeholder="Student's First Name"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="col-sm-4">
            <input
              className="bot-mar"
              style={{ height: "45px" }}
              type="text"
              placeholder="Student's Last Name"
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="col-sm-4">
            <input
              className="bot-mar"
              style={{ height: "45px" }}
              type="text"
              placeholder="Parent/Guardian Name"
              onChange={(e) => setFatherName(e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4 dropdown1">
            {/* <Select
              options={countryOptions}
              placeholder="Select Country"
              searchable
              onChange={value => setCountry(value[0].value)}
              color="#ec3545"
              style={selectStyles}
            /> */}
            <Dropdown
              placeholder="Select Country"
              search
              selection
              onChange={(e, c) => setCountry(c.value)}
              options={countryOptions}
              style={selectStyles}
            />
          </div>
          <div className="col-sm-4">
            {country != "India" ? (
              country == "USA" ? (
                <Dropdown
                  placeholder="State"
                  search
                  selection
                  onChange={(e, properties) =>
                    setStudentState(properties.value)
                  }
                  style={selectStyles}
                  className="dropdown1"
                  options={usaStateOptions}
                />
              ) : country == "Australia" ? (
                <Dropdown
                  placeholder="State"
                  search
                  selection
                  onChange={(e, properties) =>
                    setStudentState(properties.value)
                  }
                  style={selectStyles}
                  className="dropdown1"
                  options={ausStateOptions}
                />
              ) : (
                <input
                  type="text"
                  placeholder="State"
                  onChange={(e) => setStudentState(e.target.value)}
                />
              )
            ) : (
              <Dropdown
                placeholder="State"
                search
                selection
                onChange={(e, properties) => setStudentState(properties.value)}
                style={selectStyles}
                className="dropdown1"
                options={stateOptions}
              />
            )}
          </div>
          <div className="col-sm-4">
            <input
              style={{ height: "45px" }}
              type="text"
              placeholder="Postal Code"
              onChange={(e) => setPostalCode(e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 smtext">
            <input
              style={{ height: "45px" }}
              type="text"
              placeholder="Primary Email Address"
              className="input2"
              onChange={(e) => setEmail(e.target.value)}
            />
            <p style={{ fontSize: "14px", paddingTop: "3px" }}>
              *Link for your trial class will be sent on this email address
            </p>
          </div>
          <div className="col-md-6">
            <input
              style={{ width: "71%", height: "45px" }}
              type="text"
              placeholder="Parent's Mobile Number"
              className="input2"
              onChange={(e) => setFatherNumber(e.target.value)}
            />

            <button
              type="button"
              className="btn btn-danger send_otp"
              onClick={handleSendClick}
              disabled={father_number.length != 10}
            >
              SEND OTP
            </button>
            <p style={{ fontSize: "14px", paddingTop: "1px" }}>
              *Please enter 10 digit mobile number without any country code or
              '0'
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4">
            <input
              className="bot-mar"
              style={{ height: "45px" }}
              type="text"
              placeholder="Other Mobile number"
              onChange={(e) => setMobileNumber(e.target.value)}
            />
            <p style={{ fontSize: "12px", paddingTop: "3px" }}>
              Please repeat the number used for OTP if no other number available
            </p>
          </div>
          <div className="col-sm-4 dropdown1">
            <DropdownOption handleGradeSelect={handleGradeSelect} />
          </div>
          <div className="col-sm-4">
            {/* <input type="text" placeholder="Preferred Subject" className="input2" onChange={e => setPreferredSubject(e.target.value)} /> */}
            <DropdownSubjectOption handleSubjectSelect={handleSubjectSelect} />
          </div>
        </div>

        {/* <div className="row">
                <div className="col-md-6" >
                  <input type="text" placeholder="Preferred Date" />
                </div>
                <div className="col-md-6">
                  <input type="text" placeholder="Preferred Time Slot" />
                </div>
              </div> */}

        <button
          type="button"
          onClick={() => handleSubmit()}
          className="btn btn-danger submit"
          style={{
            fontFamily: "'Montserrat', Helvetica",
            fontWeight: "600",
            display: "block",
          }}
        >
          Submit
        </button>
      </div>

      <div style={{ height: "10px" }}></div>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />

      <Backdrop open={showOtpModal} />
      <div className={attclasses.join(" ")}>
        <div className="div-class-otp-modal">
          <div className="display-otp-inp-cont">
            <div className="enter-otp-indic23">Enter OTP </div>
            {isErrorShownOTP ? (
              <div className="wrong-otp-error">Wrong OTP</div>
            ) : null}
            <OtpInput
              value={userOtpInput}
              onChange={handleOtpSubmission}
              numInputs={4}
              isInputNum
              separator={<span>-</span>}
              inputStyle={{ width: "2.2em", padding: "0px", margin: "0px" }}
            />
          </div>
          <div className="margin-Sjdf21">
            <button className="Display-but-reSend" onClick={handleResendClick}>
              Resend OTP
            </button>
            <button
              className="Display-but-SendOtp"
              disabled={submitOtpStatus}
              onClick={handleSubmitClick}
            >
              Submit OTP
            </button>
          </div>
        </div>
      </div>
      <ToastContainer
        style={{
          fontFamily: "'Montserrat', Helvetica",
          fontWeight: 500,
          padding: "10px",
        }}
      />
    </>
  );
}

export default Landing
