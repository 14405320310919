import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import { Container, Row, Col } from "react-bootstrap";

function OurServices_Courses() {
  return (
    <div style={{ width: "100%" }}>
      <Container fluid style={{ width: "80%" }}>
        <Row>
          <Col md={4}>
            <a href="https://materials.therightguru.com" target="_blank">
              <div className="card1">
                <h3>STUDY MATERIALS</h3>
                <p className="small">
                  The Right Guru offers meticulously crafted subject notes from
                  grades 6 to 12, designed by subject matter experts to align
                  with the latest syllabus.
                </p>
                <div className="go-corner" href="#">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </a>
          </Col>

          <Col md={4}>
            <Link to="/cbse-icse">
              <div className="card1">
                <h3>CBSE, ICSE & OTHER BOARDS</h3>
                <p className="small">
                  Access top-notch study materials and class options tailored to
                  CBSE, ICSE, and other educational boards, ensuring your
                  academic success with ease.
                </p>
                <div className="go-corner" href="#">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </Link>
          </Col>
          <Col md={4}>
            <Link to="/ssc">
              <div className="card1">
                <h3>SSC</h3>
                <p className="small">
                  One-stop destination for SSC exam preparation, offering
                  comprehensive study materials and expert guidance, both online
                  and offline, to help you achieve your government job
                  aspirations.
                </p>
                <div className="go-corner" href="#">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </Link>
          </Col>
          <Col md={4}>
            <Link to="/cuet">
              <div className="card1">
                <h3>CUET </h3>
                <p className="small">
                  Get ready for the Common University Entrance Test with our
                  inclusive study materials and class choices, guaranteeing your
                  preparation is convenient and effective.
                </p>
                <div className="go-corner" href="#">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </Link>
          </Col>

          <Col md={4}>
            <Link to="/clat">
              <div className="card1">
                <h3>CLAT</h3>
                <p className="small">
                  Excel in your law entrance exams with our dedicated resources
                  and flexible class formats, empowering you to realize your
                  legal career dreams.
                </p>
                <div className="go-corner" href="#">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </Link>
          </Col>
          <Col md={4}>
            <Link to="/dsssb">
              <div className="card1">
                <h3>DSSSB</h3>
                <p className="small">
                  Prepare for Delhi Subordinate Services Selection Board (DSSSB)
                  exams with our tailored study aids and class options that suit
                  your schedule and preferences.
                </p>
                <div className="go-corner" href="#">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </Link>
          </Col>
          <Col md={4}>
            <Link to="/ndacds">
              <div className="card1">
                <h3>NDA & CDS</h3>
                <p className="small">
                  Excel in defense examinations with our NDA & CDS course. Get
                  expert guidance and comprehensive preparation to join the
                  National Defence Academy or Combined Defence Services.
                </p>
                <div className="go-corner" href="#">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </Link>
          </Col>
          <Col md={4}>
            <Link to="/acturial-science">
              <div className="card1">
                <h3>ACTURIAL SCIENCE</h3>
                <p className="small">
                  Master risk assessment and financial modeling with our
                  Actuarial Science course. Gain practical insights and unlock
                  opportunities in insurance, finance, and statistical analysis.
                </p>
                <div className="go-corner" href="#">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </Link>
          </Col>
          <Col md={4}>
            <Link to="/gre-gmat-sat">
              <div className="card1">
                <h3>GRE, GMAT & SAT</h3>
                <p className="small">
                  Achieve your academic goals with our GRE, GMAT & SAT
                  preparation course. Receive personalized study plans and
                  proven strategies to excel in graduate or undergraduate
                  admissions tests.
                </p>
                <div className="go-corner" href="#">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </Link>
          </Col>
          <Col md={4}>
            <Link to="/police-services">
              <div className="card1">
                <h3>POLICE SERVICES</h3>
                <p className="small">
                  Prepare for a rewarding career in law enforcement with our All
                  Police Services course. Receive expert guidance and hands-on
                  training for state and national police recruitment exams.
                </p>
                <div className="go-corner" href="#">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </Link>
          </Col>
          <Col md={4}>
            <Link to="/english-speaking">
              <div className="card1">
                <h3>ENGLISH SPEAKING COURSE</h3>
                <p className="small">
                  Enhance your language skills with our English speaking course,
                  available in online and offline modes, to boost your
                  confidence in communication.
                </p>
                <div className="go-corner" href="#">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </Link>
          </Col>

          <Col md={4}>
            <Link to="/other-cs">
              <div className="card1">
                <h3>OTHER COMPETITIVE EXAMS</h3>
                <p className="small">
                  Prepare for a wide range of competitive exams with our
                  extensive resources, covering various boards and disciplines,
                  and choose between online or offline classes to suit your
                  learning preferences.
                </p>
                <div className="go-corner" href="#">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </Link>
          </Col>

          <Col md={4}>
            <Link to="/health-recreation">
              <div className="card1">
                <h3>HEALTH & RECREATION</h3>
                <p className="small">
                  Stay fit and active with our health and recreation resources,
                  providing a balanced lifestyle approach through a blend of
                  online and offline programs.
                </p>
                <div className="go-corner" href="#">
                  <div className="go-arrow">→</div>
                </div>
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default OurServices_Courses;
