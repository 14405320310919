import React, { Fragment } from "react";
import "./sideDrawer.css";

import { NavLink } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../utils";
import { deleteStudent } from "../../redux/actions/student";

import DashboardIcon from "@material-ui/icons/Dashboard";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SchoolIcon from "@material-ui/icons/School";
import DescriptionIcon from "@material-ui/icons/Description";
import HelpIcon from "@material-ui/icons/Help";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import NotesIcon from "@material-ui/icons/Notes";

import Backdrop from "../Backdrop/Backdrop";

const SideDrawer = (props) => {
  let attclasses = ["sideDrawer", "Close_SideD"];

  if (props.open) {
    attclasses = ["sideDrawer", "Open_SideD"];
  }
  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteStudent(reduxUser.enrolled_student_id));
    removeToken();
  };

  return (
    <Fragment>
      <Backdrop open={props.open} clicked={props.closeSideDrawer} />
      <div className={attclasses.join(" ")}>
        <div
          style={{
            width: "70%",
            height: "70px",
            border: "1px solid #F4F4F4",
            marginLeft: "30px",
          }}
        ></div>

        <NavLink to="/teacher-dashboard" exact>
          <div className="sider-ele-mob">
            <DashboardIcon />
            <span className="sider-ele-mob-tag">Dashboard</span>
          </div>
        </NavLink>
        <NavLink to="/teacher-date-calendar" exact>
          <div className="sider-ele-mob">
            <EventIcon />
            <span className="sider-ele-mob-tag">Scheduler</span>
          </div>
        </NavLink>
        <NavLink to="/teacher-previous-classes" exact>
          <div className="sider-ele-mob">
            <ScheduleIcon />
            <span className="sider-ele-mob-tag">Previous Class</span>
          </div>
        </NavLink>
        <NavLink to="/teacher-students" exact>
          <div className="sider-ele-mob">
            <SchoolIcon />
            <span className="sider-ele-mob-tag">Enrolled Student</span>
          </div>
        </NavLink>
        <NavLink to="/teacher-curriculum-list" exact>
          <div className="sider-ele-mob">
            <DescriptionIcon />
            <span className="sider-ele-mob-tag">Curriculum</span>
          </div>
        </NavLink>
        <NavLink to="/teacher-doubt-list" exact>
          <div className="sider-ele-mob">
            <HelpIcon />
            <span className="sider-ele-mob-tag">Doubts</span>
          </div>
        </NavLink>
        <NavLink to="/teacher-analytics" exact>
          <div className="sider-ele-mob">
            <AssessmentIcon />
            <span className="sider-ele-mob-tag">Analytics</span>
          </div>
        </NavLink>
        <NavLink to="/teacher-assignments" exact>
          <div className="sider-ele-mob">
            <AssignmentIcon />
            <span className="sider-ele-mob-tag">Assignments</span>
          </div>
        </NavLink>
        <NavLink to="/teacher-quizzes" exact>
          <div className="sider-ele-mob">
            <LibraryBooksIcon />
            <span className="sider-ele-mob-tag">Quiz</span>
          </div>
        </NavLink>
        <NavLink to="/teacher-lecture-notes" exact>
          <div className="sider-ele-mob">
            <NotesIcon />
            <span className="sider-ele-mob-tag">Lecture Notes</span>
          </div>
        </NavLink>
        <NavLink to="/" exact onClick={() => handleLogout()}>
          <div className="sider-ele-mob">
            <PowerSettingsNewIcon />
            <span className="sider-ele-mob-tag">Logout</span>
          </div>
        </NavLink>
      </div>
    </Fragment>
  );
};

export default SideDrawer;
