import axios from "axios";
import { toAPIURL } from "../utils";

export async function trialStudentList() {
  try {
    const res = await axios.get(toAPIURL("api/trial-student-list"));
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function pendingTrialStudentList() {
  try {
    const res = await axios.get(toAPIURL("api/sales-pending-student-list/1"));
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function salesStudentDetail(studentID) {
  try {
    const res = await axios.get(
      toAPIURL("api/sales-student-detail/" + studentID)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function salesTrialStudent(trial_student_id, sales_employee_id) {
  try {
    const res = await axios.get(
      toAPIURL(
        `api/get-sales-trial-record/${trial_student_id}/${sales_employee_id}`
      )
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function salesMyStudent(salesID) {
  try {
    const res = await axios.get(toAPIURL("api/sales-student-list/" + salesID));
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function occupySalesStudent(studentDetails) {
  try {
    const res = await axios.put(
      toAPIURL("api/sales-pending-student-list/0"),
      studentDetails
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function salesHCComments(commentDetail) {
  try {
    const res = await axios.put(
      toAPIURL("api/sales-hc-discussion/0"),
      commentDetail
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function salesAllocateRM(studentDetail) {
  try {
    const res = await axios.put(
      toAPIURL("api/finalize-rm/" + studentDetail.enrolled_student_id),
      studentDetail
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function salesAllocateTeacher(EnrollmentDetail) {
  try {
    const res = await axios.put(
      toAPIURL("api/final-teacher/" + EnrollmentDetail.teacher_id),
      EnrollmentDetail
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function salesAllocateClassroom(EnrollmentDetail) {
  try {
    const res = await axios.put(
      toAPIURL("api/final-classroom/" + EnrollmentDetail.classroomID),
      EnrollmentDetail
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function salesStudentDecision(SalesTrialDetail) {
  try {
    const res = await axios.put(
      toAPIURL("api/student-decision/" + SalesTrialDetail.pk),
      SalesTrialDetail
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function bookTrialClass(ClassDetail, class_count) {
  try {
    if (class_count == 0) {
      const res = await axios.put(
        toAPIURL("api/trial-class-booking/0"),
        ClassDetail
      );
      console.log(res)
      return res.data;
    } else {
      const res = await axios.put(
        toAPIURL("api/trial-class-booking/" + ClassDetail.students),
        ClassDetail
      );
      console.log(res)
      return res.data;
    }
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function getESId(trial_student_id) {
  try {
    const res = await axios.get(toAPIURL("api/get-es/" + trial_student_id));
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function salesAnalytics(salesID) {
  try {
    const res = await axios.get(toAPIURL("api/sales-performance/" + salesID));
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function getTeacherClassroom(classroomID) {
  try {
    const res = await axios.get(
      toAPIURL("api/get-teacher-classroom/" + classroomID)
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function salesBookTrialClass(studentDetails) {
  try {
    const res = await axios.put(
      toAPIURL("api/sales-trial-class-form/0/" + studentDetails.salesId),
      studentDetails
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}
