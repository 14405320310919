import React, { useLayoutEffect, useState, useRef } from "react";
import Footer from "../../../components/Footer";
import Modal from "react-modal";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Nav2 from "../../../components/header/Nav2";
import LoginModal from "../../../components/Login_modal/loginModal";
import Popup2 from "../new_popup/popup2";
import CustomNav from "../../../components/header/Navbar";
import TextField from "@mui/material/TextField";
import axios from "axios";
import './footer.css'
Modal.setAppElement("#root");

function TrgFranchise() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);

  const [name, setName] = useState('');
  const [emaill,setEmaill] = useState('');
  const [phoneno, setPhoneno] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const formRef = useRef(null);

  const getFormData = async(e) => {
    e.preventDefault();
    try {
      await axios.post(`https://api.therightguru.com/api/franchise-form/`, {
        "name": name,
        "email": emaill,
        "phone": phoneno,
        "city": city,
        "state": state
      });

      // Action to take when the form is submitted successfully
      alert("Form submitted successfully! We will contact you in the next 48 hours.");
      setName('');
      setEmaill('');
      setPhoneno('');
      setCity('');
      setState('');
      // Reset the form using the ref
      formRef.current.reset();
    } catch (err) {
      alert("Something went wrong. Please carefully fill out the form again.")
      console.log(err);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <section>
        <CustomNav Open={closeModal} />
      </section>
      <section
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <div className="trg-franchise-wrapper">
          <div style={{ fontSize: 24, fontWeight: "600" }}>
            Partner with The Right Guru Education Franchise
          </div>
          <div style={{ lineHeight: 2, marginTop: "2%" }}>
            <p>
              <span style={{ fontWeight: "600" }}>
                1. Diverse Course Portfolio:
              </span>
              We offer a wide range of courses to meet various educational
              needs.
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>2. Rapid ROI: </span>
              Our proven business model ensures a quick return on your
              investment.
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>3. Strong Reputation: </span>
              Benefit from our respected legacy in the education industry.
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>4. Marketing Support: </span>
              Access comprehensive marketing assistance for online and offline
              campaigns.
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>5. Technological Edge: </span>
              Stay ahead with advanced tools for business intelligence and
              monitoring.
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>6. Ongoing Guidance: </span>
              We provide continuous support in setting goals and action plans.
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>7. Study Materials: </span>
              Access to quality study materials to enhance student learning.
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>
                8. Teacher Recruitment Support:
              </span>
              Assistance in recruiting qualified educators.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3%",
            }}
          >
            <table>
              <tr>
                <th>Frachise Running</th>
                <th>Present across states of Inida</th>
                <th>Franchise present in Aborad</th>
              </tr>
              <tr>
                <td>4</td>
                <td>4</td>
                <td>0</td>
              </tr>
            </table>
          </div>
          <div>
            <div
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: 600,
                marginTop: "3%",
                marginBottom: "3%",
              }}
            >
              Book Your Appointment
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                className="trg-franchise-from-wrapper"
              >
                <form ref={formRef} onSubmit={getFormData}>
                  <TextField
                    style={{ marginBottom: "30px" }}
                    id="outlined-basic"
                    label="Name"
                    type="text"
                    variant="outlined"
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    required
                  />
                  <TextField
                    style={{ marginBottom: "30px" }}
                    id="outlined-basic"
                    type="email"
                    label="Email"
                    variant="outlined"
                    onChange={(e) => setEmaill(e.target.value)}
                    fullWidth
                    required
                  />
                  <TextField
                    style={{ marginBottom: "30px" }}
                    type="number"
                    id="outlined-basic"
                    label="Phone number"
                    variant="outlined"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    onChange={(e) => setPhoneno(e.target.value)}
                    fullWidth
                    required
                  />
                  <TextField
                    style={{ marginBottom: "30px" }}
                    id="outlined-basic"
                    label="City"
                    type="text"
                    variant="outlined"
                    onChange={(e) => setCity(e.target.value)}
                    fullWidth
                    required
                  />
                  <TextField
                    style={{ marginBottom: "30px" }}
                    id="outlined-basic"
                    label="State"
                    type="text"
                    variant="outlined"
                    onChange={(e) => setState(e.target.value)}
                    fullWidth
                    required
                  />
                  <button
                    type="submit"
                    style={{
                      backgroundColor: "#dc3545",
                      cursor: "pointer",
                      borderRadius: 5,
                      width: "100%",
                      borderWidth: "0",
                      padding: 15,
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        fontWeight: 600,
                        fontSize: 20,
                      }}
                    >
                      Submit
                    </p>
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div style={{ fontSize: 24, fontWeight: "600", marginTop: "3%" }}>
            Partner with The Right Guru Education Franchise
          </div>
          <div style={{ lineHeight: 2, marginTop: "2%" }}>
            <p>
              <span style={{ fontWeight: "600" }}>
                1. Educational Excellence: 
              </span>
              With over years of experience, we're committed to helping students
              succeed.
            </p>
            <p>
              <span style={{ fontWeight: "600" }}> 2. Proven Model: </span>
              Our centralized R&D and technology support give you a competitive
              edge.
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>3. Impressive Results: </span>
              We consistently deliver top results with audited transparency.
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>4. Diverse Courses: </span>
              From foundational education to specialized exams, we cover it all.
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>5. Holistic Solutions: </span>
              We support students at every stage of their academic journey.
            </p>
          </div>
          <p style={{ marginTop: "2%", marginBottom: "2%", lineHeight: "normal" }}>
            Join us at The Right Guru and make an impact on education and
            entrepreneurship. Unlock the potential of the next generation and
            help them achieve their dreams.
          </p>
          <p style={{ lineHeight: "normal" }}>
            Collaborate with The Right Guru, a trusted name in the field of
            education, and be part of our mission to transform lives through
            quality learning. Join hands with us as a franchise partner and
            contribute to the future success of students while securing your
            own.
          </p>
        </div>
      </section>
      <section id="footer">
        <Footer />
      </section>

      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default TrgFranchise;
