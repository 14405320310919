import React, { useState } from 'react';
import "./style.css";
import { Form, Message } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";

import { updatePassword } from "../../crud/auth.crud";

const ForgetPassword = () => {
    let history = useHistory();
    const goToPreviousPath = () => {
        history.goBack()
    }

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [rePassword, setRePassword] = useState("")

    const handleSubmit = () => {
        if (password != rePassword) {
            alert("Password do not match, Please type again.");
            setPassword("");
            setRePassword("");
        } else {
            updatePassword(email, password)
                .then(res => {
                  if (res.hasOwnProperty("message")) {
                    alert(res.message);
                    setPassword("");
                    setRePassword("");
                  } else {
                    alert("Password changed successfully.\n Please login with new password.");
                    history.goBack();
                  }
              })
                .catch(res => { alert("No user found with this email address."); setPassword(""); setRePassword("");})
        }
    }

    return (
        <div className="forget_outer">
            <div className="forget_layout">
                <p className="forget_Page_Heading">Reset your Password</p>
                <Form className="forget_Form_Handler">
                    <Form.Input
                        fluid icon='user'
                        iconPosition='left'
                        placeholder='Email Address'
                        className="forget_Form_Input"
                        onChange={e => setEmail(e.target.value)}
                    />
                    <Form.Input
                        fluid icon='lock'
                        iconPosition='left'
                        placeholder='Password'
                        type='password'
                        className="forget_Form_Input"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <Form.Input
                        fluid icon='lock'
                        iconPosition='left'
                        placeholder='Confirm Password'
                        type='password'
                        value={rePassword}
                        className="forget_Form_Input"
                        onChange={e => setRePassword(e.target.value)}
                    />

                    <button
                        e='password'
                        className="forget_Butt_Sub"
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                    <div style={{width: "100%"}}>
                        <div onClick={goToPreviousPath} className="forget_back">
                            <i className="angle left icon"></i>
                            <p className="forget_Message">Go Back</p>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default ForgetPassword;
