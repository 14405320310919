import React, { useLayoutEffect, useState, useRef } from "react";
import Footer from "../../../components/Footer";
import Modal from "react-modal";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Nav2 from "../../../components/header/Nav2";
import LoginModal from "../../../components/Login_modal/loginModal";
import Popup2 from "../new_popup/popup2";
import CustomNav from "../../../components/header/Navbar";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import './footer.css'
import axios from "axios";

const position = [
  {
    value: "Teacher",
    label: "Teacher",
  },
  {
    value: "Graphic Designing",
    label: "Graphic Designing",
  },
  {
    value: "Content Design",
    label: "Content Design",
  },
  {
    value: "Video Editor",
    label: "Video Editor",
  },
  {
    value: "Customer Service",
    label: "Customer Service",
  },
  {
    value: "Logistics",
    label: "Logistics",
  },
  {
    value: "Technology",
    label: "Technology",
  },
  {
    value: "Sales",
    label: "Sales",
  },
  {
    value: "Tele-call Marketing",
    label: "Tele-call Marketing",
  },
];

const exams = [
  {
    value: "IIT JEE",
    label: "IIT JEE",
  },
  {
    value: "NEET UG",
    label: "NEET UG",
  },
  {
    value: "CUET",
    label: "CUET",
  },
  {
    value: "SSC",
    label: "SSC",
  },
  {
    value: "CLAT",
    label: "CLAT",
  },
  {
    value: "DSSSB",
    label: "DSSSB",
  },
  {
    value: "Defence Exams",
    label: "Defence Exams",
  },
  {
    value: "State PSC exams",
    label: "State PSC exams",
  },
  {
    value: "NEET PG",
    label: "NEET PG",
  },
  {
    value: "Others",
    label: "Others",
  },
];

const languages = [
  {
    value: "Hinglish",
    label: "Hinglish",
  },
  {
    value: "Hindi",
    label: "Hindi",
  },
  {
    value: "English",
    label: "English",
  },
  {
    value: "Others",
    label: "Others",
  },
];
const qualifications = [
  {
    value: "Class 12 / Diploma",
    label: "Class 12 / Diploma",
  },
  {
    value: "UnderGraduate",
    label: "UnderGraduate",
  },
  {
    value: "Post Graduate",
    label: "Post Graduate",
  },
  {
    value: "PhD / Doctorate",
    label: "PhD / Doctorate",
  },
];
const experiences = [
  {
    value: "None",
    label: "None",
  },
  {
    value: "0-1 Years",
    label: "0-1 Years",
  },
  {
    value: "1-3 Years",
    label: "1-3 Years",
  },
  {
    value: "3-5 Years",
    label: "3-5 Years",
  },
  {
    value: ">5 Years",
    label: ">5 Years",
  },
];

Modal.setAppElement("#root");

function Intpro() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [emaill, setEmaill] = useState("");
  const [address, setAddress] = useState("");
  const [positionSelected, setPositionSelected] = useState("");
  const [exam, setExam] = useState("");
  const [language, setLanguage] = useState("");
  const [qualification, setQualification] = useState("");
  const [subject, setSubject] = useState("");
  const [experience, setExperience] = useState("");
  const [demolink, setDemoLink] = useState("");
  const [youtubelink, setYoutubeLink] = useState("");
  const formRef = useRef(null);

  const submitForm = async(e) => {
    e.preventDefault();
    try {
      await axios.post(`https://api.therightguru.com/api/internship-form/`, {
        "name": name,
        "phone": mobile,
        "email": emaill,
        "address": address,
        "position": positionSelected,
        "exam": exam,
        "language": language,
        "qualification": qualification,
        "subject": subject,
        "experience": experience,
        "demolink": demolink,
        "youtubelink": youtubelink
      });

      // Action to take when the form is submitted successfully
      alert("Form submitted successfully! We will contact you in the next 48 hours.");
      setName("");
      setEmaill(""); setMobile("");
      setAddress(""); 
      setPositionSelected(""); setExam(""); setLanguage("");
      setQualification(""); setSubject(""); setExperience("");
      setDemoLink(""); setYoutubeLink("");
      // Reset the form using the ref
      formRef.current.reset();
    } catch (err) {
      alert("Something went wrong. Please carefully fill out the form again.")
      console.log(err);
    }
  };

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div style={{ width: "100%" }}>
      <section>
        <CustomNav Open={closeModal} />
      </section>
      <section
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <div className="internship-page-wrapper">
          <div
            style={{
              textAlign: "center",
              fontSize: 20,
              fontWeight: 600,
              color: "#dc3545",
              marginBottom: "2%",
            }}
          >
            Ready to improve your Skillset? Start your Exciting journey with
            The Right Guru! 
          </div>

          {/* <div
            style={{
              textAlign: "center",
              fontSize: 20,
              fontWeight: 600,
              color: "#dc3545",
              marginBottom: "2%",
            }}
          >
            " Start your Exciting journey with The Right Guru! "
          </div> */}
          <div
            style={{
              textAlign: "center",
              fontSize: 18,
              fontWeight: 500,
              marginBottom: "2%",
            }}
          >
            "Fast-track your career aspirations with The Right Guru Internship
            Programme, leveraging the strength of India's top Learning
            Platform."
          </div>

          <div style={{ textAlign: "center", fontSize: 30, fontWeight: 600 }}>
            Interested in Joining ?
          </div>

          <div style={{ fontSize: 20, margin: "24px 0px", color: "#dc3545" }}>
            Fill this form :-
          </div>

          <div
            className="internship-form-wrapper"
          >
            <form ref={formRef} onSubmit={submitForm}>
            <div>
              <p style={{ fontWeight: 500 }}>
                <span>1. </span>Please enter your name (as per Govt. Id proof) :
              </p>
              <TextField
                style={{ marginLeft: 20, width: "calc(100% - 20px)" }}
                id="standard-basic"
                label="Full Name"
                variant="standard"
                  fullWidth
                  onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div style={{ marginTop: 30 }}>
              <p style={{ fontWeight: 500 }}>
                <span>2. </span>Please enter your Contact Number :
              </p>
              <TextField
                style={{ marginLeft: 20,  width: "calc(100% - 20px)" }}
                id="standard-basic"
                label="Phone Number"
                variant="standard"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  fullWidth
                  onChange={(e) => setMobile(e.target.value)}
                required
              />
            </div>

            <div style={{ marginTop: 30 }}>
              <p style={{ fontWeight: 500 }}>
                <span>3. </span>Please enter your Email Id :
              </p>
              <TextField
                style={{ marginLeft: 20, width: "calc(100% - 20px)" }}
                id="standard-basic"
                label="Email Id"
                variant="standard"
                  fullWidth
                  onChange={(e) => setEmaill(e.target.value)}
                required
              />
            </div>
            <div style={{ marginTop: 30 }}>
              <p style={{ fontWeight: 500 }}>
                <span>4. </span>Please enter your current residential address :
              </p>
              <TextField
                style={{ marginLeft: 20, width: "calc(100% - 20px)" }}
                id="standard-basic"
                label="Address"
                variant="standard"
                  fullWidth
                  onChange={(e) => setAddress(e.target.value)}
                required
              />
            </div>
            <div style={{ marginTop: 30 }}>
              <p style={{ fontWeight: 500 }}>
                <span>5. </span>Please choose desired position :
              </p>
              <TextField
                style={{ marginLeft: 20, marginTop: 10, width: "calc(100% - 20px)" }}
                id="standard-select-position"
                select
                label="Select"
                  variant="standard"
                  value={positionSelected}
                  fullWidth
                  onChange={(e) => setPositionSelected(e.target.value)}
                required
              >
                {position.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div style={{ marginTop: 30 }}>
              <p style={{ fontWeight: 500 }}>
                <span>6. </span>Which exam are you interested in teaching :
              </p>
              <TextField
                style={{ marginLeft: 20, marginTop: 10, width: "calc(100% - 20px)" }}
                id="standard-select-position"
                select
                  label="Select"
                  value={exam}
                  variant="standard"
                  helperText="Required only if 'Teacher' is selected as the desired position."
                  fullWidth
                  onChange={(e) => setExam(e.target.value)}
              >
                {exams.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div style={{ marginTop: 30 }}>
              <p style={{ fontWeight: 500 }}>
                <span>7. </span>Please choose your preferred teaching language :
              </p>
              <TextField
                style={{ marginLeft: 20, marginTop: 10, width: "calc(100% - 20px)" }}
                id="standard-select-position"
                select
                  label="Select"
                  value={language}
                  variant="standard"
                  helperText="Required only if 'Teacher' is selected as the desired position."
                  fullWidth
                  onChange={(e) => setLanguage(e.target.value)}
              >
                {languages.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              </div>
              
            <div style={{ marginTop: 30 }}>
              <p style={{ fontWeight: 500 }}>
                <span>9. </span>Please metion the subject you want to teach :
              </p>
              <TextField
                style={{ marginLeft: 20, width: "calc(100% - 20px)" }}
                id="standard-basic"
                label="Subject Name"
                variant="standard"
                  fullWidth
                  onChange={(e) => setSubject(e.target.value)}
                  helperText="Required only if 'Teacher' is selected as the desired position."
              />
            </div>

            <div style={{ marginTop: 30 }}>
              <p style={{ fontWeight: 500 }}>
                <span>8. </span>Please mention your highest educational
                qualification :
              </p>
              <TextField
                style={{ marginLeft: 20, marginTop: 10, width: "calc(100% - 20px)" }}
                id="standard-select-position"
                select
                  label="Select"
                  value={qualification}
                variant="standard"
                  fullWidth
                  onChange={(e) => setQualification(e.target.value)}
                required
              >
                {qualifications.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div style={{ marginTop: 30 }}>
              <p style={{ fontWeight: 500 }}>
                <span>10. </span>Please specify the duration of your relevant experience:
              </p>
              <TextField
                style={{ marginLeft: 20, marginTop: 10, width: "calc(100% - 20px)" }}
                id="standard-select-position"
                select
                label="Select"
                  variant="standard"
                  value={experience}
                  fullWidth
                  onChange={(e) => setExperience(e.target.value)}
                required
              >
                {experiences.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div style={{ marginTop: 30 }}>
              <p style={{ fontWeight: 500 }}>
                <span>11. </span>Please provide the link of your demo video :
              </p>
              <TextField
                style={{ marginLeft: 20, width: "calc(100% - 20px)"  }}
                id="standard-basic"
                label="Enter Link"
                variant="standard"
                  fullWidth
                  onChange={(e) => setDemoLink(e.target.value)}
                  helperText="Any type of media is acceptable to showcase your skills."
              />
            </div>

            <div style={{ marginTop: 30 }}>
              <p style={{ fontWeight: 500 }}>
                <span>12. </span>Please provide the link of your YouTube
                Channel(if any ) :
              </p>
              <TextField
                style={{ marginLeft: 20, width: "calc(100% - 20px)" }}
                id="standard-basic"
                label="Enter Link"
                variant="standard"
                  fullWidth
                  onChange={(e) => setYoutubeLink(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 50,
              }}
            >
              <button type='submit'
              style={{
              backgroundColor: "#dc3545",
              padding: 10,
              borderRadius: 8,
              border: "none",
              outline: "none",
              width: 200,
              cursor: "pointer",
              color: "#fff",
              fontSize: 20,
              fontWeight: 600,
              textAlign: "center",
            }}
            >Submit</button>
            </div>
            </form>
          </div>

          <div style={{ marginTop: "3%" }}>
            <div
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: 600,
                textDecoration: "underline",
                marginBottom: "2%",
              }}
            >
              What's your benefits in it?
            </div>

            <div style={{ lineHeight: 2 }}>
              <p>
                <span style={{ fontWeight: "600" }}>1. Experience:</span>
                Overcome the experience barrier by gaining firsthand, practical
                experience that complements your Skill.
              </p>
              <p>
                <span style={{ fontWeight: "600" }}>
                  2. Skill Development:{" "}
                </span>
                Acquire relevant skills to enhance your resume, making you more
                competitive in job applications
              </p>
              <p>
                <span style={{ fontWeight: "600" }}>
                  3. Professional Network:
                </span>
                Expand your network by interacting with mentors and colleagues
                in the workplace, opening doors to valuable advice and potential
                job offers.
              </p>
              <p>
                <span style={{ fontWeight: "600" }}>
                  4. Real-World Application:
                </span>
                Apply classroom knowledge to real-world scenarios, fostering
                professionalism, accountability, and initiative.
              </p>
              <p>
                <span style={{ fontWeight: "600" }}>5. Career Discovery: </span>
                Explore various aspects of your field during your internship,
                helping you discover your career path and whether it aligns with
                your skills and interests.
              </p>
              <p>
                <span style={{ fontWeight: "600" }}>6. Self-Discovery: </span>
                Gain insights into your preferences and strengths, potentially
                leading to unexpected but fulfilling career choices.
              </p>
            </div>

            <div
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: 600,
                textDecoration: "underline",
                marginBottom: "2%",
                marginTop: "3%",
              }}
            >
              Our Internship Application Process
            </div>

            <div style={{ lineHeight: 2 }}>
              <p>
                <span style={{ fontWeight: "600" }}>1. Application:</span>
                Kickstart your journey by completing our application form.
                Provide your contact details and other relevant information.
              </p>
              <p>
                <span style={{ fontWeight: "600" }}>2. Resume/CV: </span>
                Upload your up-to-date resume or CV to showcase your
                qualifications and experiences.
              </p>
              <p>
                <span style={{ fontWeight: "600" }}>3. Interview: </span>
                If your application meets our criteria, we may invite you for an
                interview to learn more about your skills and ambitions.
              </p>
              <p>
                <span style={{ fontWeight: "600" }}>4. Selection: </span>
                Selected candidates will be notified about the next steps,
                bringing you closer to a rewarding internship experience with
                The Right Guru.
              </p>
            </div>

            <div
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: 600,
                textDecoration: "underline",
                marginBottom: "2%",
                marginTop: "3%",
              }}
            >
              Standard Intership terms for all fields
            </div>

            <div style={{ lineHeight: 2 }}>
              <p>
                <span style={{ fontWeight: "600" }}>1. </span>
                Fixed 3-month internship.
              </p>
              <p>
                <span style={{ fontWeight: "600" }}>2. </span>
                Performance-based extension opportunities.
              </p>
              <p>
                <span style={{ fontWeight: "600" }}>3. </span>
                Fixed stipend for all interns.
              </p>
              <p>
                <span style={{ fontWeight: "600" }}>4. </span>
                Standard incentives and rewards apply across all fields.
              </p>
            </div>

            <div
              style={{
                textAlign: "center",
                fontSize: 16,
                fontWeight: 600,

                marginBottom: "2%",
                marginTop: "3%",
              }}
            >
              For any query, Email us at:{" "}
              <span style={{ color: "#dc3545" }}>
                <a
                  href="mailto:contact@therightguru.com"
                  style={{ color: "#dc3545" }}
                >
                  contact@therightguru.com
                </a>
              </span>
            </div>
            <p>
              For a more personalized and detailed understanding, we encourage
              you to visit our centers and consult our dedicated counselors.
            </p>
          </div>
        </div>
      </section>
      <section id="footer">
        <Footer />
      </section>

      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default Intpro;
