import React, { useState, useEffect, useLayoutEffect } from "react";
import "./live.css";
import { AiTwotoneBank } from "react-icons/ai";
import { Link, useHistory, useParams } from "react-router-dom";
import pic_2 from "../../student/dashboard/img/pic_2.png";

import {
  FaRegQuestionCircle,
  FaCalendarAlt,
  FaFileAlt,
  FaChartPie,
  FaStickyNote,
  FaPowerOff,
} from "react-icons/fa";
import {
  MdAssignment,
  MdDashboard,
  MdNotificationsNone,
  MdSchool,
} from "react-icons/md";
import { BiSupport, BiHistory } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import ReactTooltip from "react-tooltip";
import { PieChart } from "react-minimal-pie-chart";
import vector_1_2x from "./img/vector-1@2x.png";
import line6_2x from "./img/line-6@2x.png";
import line5_2x from "./img/line-5@2x.png";
import vector_3_2x from "./img/vector-3@2x.png";
import ReactStars from "react-rating-stars-component";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteTeacher } from "../../../redux/actions/teacher";
import { trialClassRating } from "../../../crud/student.crud";

function TeacherLiveClassRating() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const { teacherId, class_details } = useParams();
  
  const user = useSelector((state) => state.teacherDetails.teacher);
  const [rating, setResponseText] = useState({
    questionId: "6",
    text: "No comments",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);

  const handleLogout = () => {
    dispatch(deleteTeacher(reduxUser.teacher_id));
    removeToken();
  };
  const handleSubmit = () => {
    trialClassRating({
      formId: "TCTF",
      userId: teacherId,
      receiverId: class_details,
      answers: [rating],
    })
      .then((res) => {
        alert("Rating added successfully");
        setIsSubmitted(!isSubmitted);
        history.replace("/teacher-dashboard");
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div>
        <div className="component-1-C61RwL">
          <img alt="image" src={pic_2} style={{ height: "115px", marginTop: "-8%" }} />

        </div>
        <div className="group-22-C61RwL">
          {/* <img className="vector-VdQhAS" src={vector_3_2x} /> */}
          <div className="biba-sobti-VdQhAS">
            {user.first_name + " " + user.last_name}
          </div>
          <div className="bibasobtigmailcom-VdQhAS">{user.emailID}</div>
        </div>
        <div
          className="card"
          style={{
            width: "70%",
            height: "325px",
            top: "200px",
            left: "25px",
            margin: "auto"
          }}
        >
          <div className="card-body" style={{ padding: "-0.75 rem" }}>
            <div
              classname="feedback_title"
              style={{
                fontSize: "20px",
                margin: "auto",
                fontWeight: "bold",
                paddingBottom: "20px"
              }}
            >
              {" "}
              <strong>
                Please comment about students interest, aims or any other
                relevant information you obtained during live Class that may
                help sales team.
              </strong>
            </div>

            <textarea
              class="form-control"
              onChange={(e) =>
                setResponseText({ questionId: "6", text: e.target.value })
              }
            ></textarea>

            <button
              type="button"
              className="btn btn-danger"
              style={{ marginLeft: "44%", marginTop: "4%" }}
              onClick={() => handleSubmit()}
            >
              Submit
            </button>
          </div>
        </div>

        <div className="group-165-C61RwL">
          <div className="group-163-L8UHM8"></div>
          <div className="group-164-L8UHM8">
            <div className="group-162-odnTUH">
              <Link to="/teacher-dashboard">
                {" "}
                <MdDashboard
                  data-tip="Dashboard"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
              <div className="historytoggleoff-24px-1-KSgdom">
                <div className="group-FHHSuW">
                  <Link to="/teacher-date-calendar">
                    {" "}
                    <FaCalendarAlt
                      data-tip="Scheduler"
                      size={24}
                      color="rgba(179,179,179,1.0)"
                    />
                    <ReactTooltip />
                  </Link>
                </div>
              </div>
              <div className="historytoggleoff-24px-1-D0BVcB">
                <Link to="/teacher-previous-classes">
                  {" "}
                  <BiHistory
                    data-tip="Previous Classes"
                    size={24}
                    color="rgb(220, 53, 69)"
                  />
                  <ReactTooltip />
                </Link>
              </div>
              <div className="school-24px-1-KSgdom">
                <Link to="/teacher-students">
                  <MdSchool
                    data-tip="My Students"
                    size={24}
                    color="rgba(179,179,179,1.0)"
                  />
                  <ReactTooltip />
                </Link>
              </div>
              <div className="description-24px-1-KSgdom">
                <Link to="/teacher-curriculum-list">
                  <FaFileAlt
                    data-tip="Curriculums"
                    size={24}
                    color="rgba(179,179,179,1.0)"
                  />
                  <ReactTooltip />
                </Link>
              </div>
              <div className="help-24px-1-KSgdom">
                <Link to="/teacher-doubt-list">
                  <FaRegQuestionCircle
                    data-tip="Doubts"
                    size={24}
                    color="rgba(179,179,179,1.0)"
                  />
                  <ReactTooltip />
                </Link>
              </div>
              <div className="analytics-24px-3-KSgdom">
                <Link to="/teacher-analytics">
                  <FaChartPie
                    data-tip="Analytics"
                    size={24}
                    color="rgba(179,179,179,1.0)"
                  />
                  <ReactTooltip />
                </Link>
              </div>
              <div className="assignment-24px-1-KSgdom">
                <Link to="/teacher-assignments">
                  <MdAssignment
                    data-tip="Assignments"
                    size={24}
                    color="rgba(179,179,179,1.0)"
                  />
                  <ReactTooltip />
                </Link>
              </div>
              <div className="quiz-24px-1-KSgdom">
                <Link to="/teacher-quizzes">
                  <RiQuestionnaireFill
                    data-tip="Quizzes"
                    size={24}
                    color="rgba(179,179,179,1.0)"
                  />
                  <ReactTooltip />
                </Link>
              </div>
              <div className="stickynote2-24px-1-rw4mxC">
                <Link to="/teacher-lecture-notes">
                  <FaStickyNote
                    data-tip="Lecture Notes"
                    size={24}
                    color="rgba(179,179,179,1.0)"
                  />
                  <ReactTooltip />
                </Link>
              </div>
              {/* <div className="accountbalance-24px-1-KSgdom">
                <Link to="/teacher-payments">
                  <AiTwotoneBank
                    data-tip="Payments"
                    size={24}
                    color="rgba(179,179,179,1.0)"
                  />
                  <ReactTooltip />
                </Link>
              </div> */}
              <div className="poweroff-24px-1-KSgdom">
                <Link to="/" onClick={() => handleLogout()}>
                  <FaPowerOff
                    size={24}
                    data-tip="Logout"
                    color="rgba(179,179,179,1.0)"
                  />
                  <ReactTooltip />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TeacherLiveClassRating;
