import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import ReactPlayer from "react-player/lazy";

import "./welcome.css";
import myVideo from "./intro_trg.mp4"
import Welcome2 from "./Welcome2";

const videoStyles = {
  border: "10px solid #dc3545",
};

function Welcome(props) {
  return (
    <Container fluid style={{ maxHeight: "600px" }}>
      {/* <Row className="justify-content-center pl-md-5 pr-md-5">
        <Col md={12} className="text-center hero">
          <ReactPlayer
            loop={true}
            playing={false}
            controls={true}
            volume={0.6}
            url={myVideo}
            width="100%"
            height="100%"
            style={videoStyles}
          />
        </Col>
      </Row> */}
      <Welcome2/>
    </Container>
  );
}

export default Welcome;
