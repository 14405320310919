import axios from "axios";
import { toAPIURL } from "../utils";

export async function HCteacherList(subject_name, grade_name) {
  try {
    const res = await axios.get(
      toAPIURL(`api/teacher-options/${subject_name}/${grade_name}`)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function HCClassroomList(course_id, grade_id, subject_id) {
  console.log("HCClassroomList");
  console.log(course_id);
  console.log(subject_id);
  try {
    const res = await axios.get(
      toAPIURL(`api/hc-get-classroom/${course_id}/${grade_id}/${subject_id}`)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function teacherDetail(teacherID) {
  try {
    const res = await axios.get(toAPIURL("api/teacher-analytics/" + teacherID));
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function teacherPersonalDetail(teacherID) {
  try {
    const res = await axios.get(
      toAPIURL("api/teacher-details-id/" + teacherID)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function teacherSubGrade(teacherID) {
  try {
    const res = await axios.get(toAPIURL("api/sub-grade/" + teacherID));
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function ticketDetail(ticketID) {
  try {
    const res = await axios.get(toAPIURL("api/ticket-detail-hc/" + ticketID));
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function HcStudentDetail(studentID) {
  try {
    const res = await axios.get(
      toAPIURL("api/sales-student-detail/" + studentID)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function allTicketList(ticket_status) {
  try {
    const res = await axios.get(
      toAPIURL("api/all-ticket-list/" + ticket_status)
    );
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function HCSuggestRM(type) {
  try {
    const res = await axios.get(toAPIURL("api/suggest-rm/" + type));
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function HCModifyRM(RMDetails) {
  try {
    const res = await axios.put(
      toAPIURL("api/modify-rm/" + RMDetails.enrolled_student_id),
      RMDetails
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function HCModifyTeacher(TeacherDetails) {
  try {
    const res = await axios.put(
      toAPIURL("api/modify-teacher/" + TeacherDetails.new_teacher_id),
      TeacherDetails
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function HCCreateClassroom(ClassroomDetails) {
  try {
    // console.log(ClassroomDetails);
    const res = await axios.put(
      toAPIURL("api/hc-create-classroom/0"),
      ClassroomDetails
    );
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function HCGetTransactions(email) {
  try {
    const res = await axios.get(toAPIURL("api/student-transactions/" + email));
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function HCNewStudentList() {
  try {
    const res = await axios.get(toAPIURL("api/hc-new-student"));
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function AddNewMaterial(details) {
  try {
    const res = await axios.put(
      toAPIURL("api/upload-material/0"),
      details
    );
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function AddNewFolder(details) {
  try {
    const res = await axios.put(
      toAPIURL("api/upload-material-folder/0"),
      details
    );
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}