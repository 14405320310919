import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import './CalenderUserComponent.css';
import moment from "moment";
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import TimePicker from 'react-time-picker';
import { Button } from "react-bootstrap";
import { getSlots, openSlot } from "../../../../crud/teacher.crud";
// import pic_2 from "../../student/dashboard/img/pic_2.png";
import pic_2 from "../../../student/dashboard/img/pic_2.png";


import {
    BellOutlined,
    UserOutlined,
} from '@ant-design/icons';

function CalenderUserStatus(props) {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    
    const date = moment().format('YYYY-MM-DD');
    // console.log(date);
    const user = useSelector((state) => state.teacherDetails.teacher);
    const dispatch = useDispatch();
    const [selTime, setSelTime] = useState("");
    const [opened, setOpened] = useState(false);
    const [slots, setSlots] = useState([]);


    useEffect(() => {


        getSlots(user.teacher_id, date)
            .then(res => {
                if (res.hasOwnProperty("message")) {
                    setSlots(false)
                } else {
                    setSlots(res);
                }
            })
            .catch(err => console.log(err))
    }, [opened])

    const handleSubmit = () => {
        openSlot({ time: selTime, status: "Opened" }, user.teacher_id, date)
            .then(res => {
                alert(`Slot opened successfully.`)
                setOpened(!opened)
                setSelTime("")
            })
            .catch(err => console.log(err))
    }
    return (
        <div className="calender-status-display">

            <div className="calender-user-dis">
                <div>
                    
                </div>
                <div className="User-info">
                    <div className="user-info-in">
                        <div style={
                            {
                                fontSize: '24px',
                                fontWeight: 'bold',
                                color: '#dc3545'
                            }
                        }>{user && user.first_name} {user && user.last_name}</div>
                        <div>{user && user.emailID}</div>
                    </div>
                    <div >
                        <UserOutlined className="User-Icon" />
                    </div>
                </div>
            </div>


            <div className="content-teacher-head">
                <span className="content-teacher-op">
                    Open Calender
                </span>

                <span className="content-right-date">
                    {moment().format('MMM DD, YYYY')}
                    {/* {April 24, 2021} */}
                </span>
            </div>

            <div className="status4">
                <div className="calender-status">
                    <span className="calender-items-time">Time</span>
                    <span className="calender-items-data">Status</span>

                </div>
                <div className="dic-ins-s-e">
                    {
                        slots.length > 0 ? slots.map(slot => {
                            return (
                                <div key={slot.id} className="status-2">
                                    {/* <div className="status-2-in"> */}
                                    <span className="calender-items-time">{slot.time}</span>
                                    <span className="calender-items-data">{slot.status}</span>
                                </div>
                            )
                        })
                            : <div style={{ fontFamily: "'Montserrat', Helvetica" }}>No slots opened by you for the selected date({date}).</div>
                    }
                </div>
                {/* <div className="classTime-select-comp">
                    <TimePicker
                        // use12Hours format="h:mm a"
                        onChange={setSelTime}
                        disableClock
                    />
                    <Button variant="danger" onClick={handleSubmit} disabled={selTime == ""} style={{ fontFamily: "'Montserrat', Helvetica", fontWeight: '600', fontSize: '14px' }}>
                        Open New Slot
                    </Button>
                </div> */}
            </div>
        </div>
    );
};

export default CalenderUserStatus;