import React, { useState, useEffect, useLayoutEffect } from "react";
import "./form1.css";
import logo from "./img/vector-8@2x.png";
import image from "./img/vector-3@2x.svg";
import { MdDashboard, MdNotificationsNone, MdSchool } from "react-icons/md";
import { useParams } from "react-router-dom";
import { AiTwotoneBank } from "react-icons/ai";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Card, Form, Dropdown, Button } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import moment from "moment";
// import DatePicker from "react-date-picker";
import { DatePicker } from "@material-ui/pickers";
import ReactTooltip from "react-tooltip";
import Popup from "../../teacher/popup/popup";
import { removeToken } from "../../../utils";
import { deleteSales } from "../../../redux/actions/sales";
import pic_2 from "../../student/dashboard/img/pic_2.png";
import DropdownOption from "../../higher_committee/dropdown/dropdown";
import {
  salesStudentDetail,
  salesHCComments,
  salesAllocateRM,
  salesAllocateTeacher,
  getESId,
  salesAllocateClassroom,
  bookTrialClass,
  salesTrialStudent,
  getTeacherClassroom,
  salesStudentDecision,
} from "../../../crud/sales.crud";
import { NavLink } from "react-router-dom";
import {
  FaRegQuestionCircle,
  FaCalendarAlt,
  FaFileAlt,
  FaChartPie,
  FaStickyNote,
  FaPowerOff,
} from "react-icons/fa";

import DashboardIcon from "@material-ui/icons/Dashboard";
import { useSelector, useDispatch } from "react-redux";

import AssessmentIcon from "@material-ui/icons/Assessment";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SchoolIcon from "@material-ui/icons/School";
import SideDrawerSales from "../../../UI/sideDrawer/sideDrawerSales";
import { Divider } from "antd";
import {
  enrolledStudentDetail,
  trialStudentDetails,
} from "../../../crud/student.crud";

function Form1() {
  useLayoutEffect(() => {
      window.scrollTo(0, 0);
  }, [])
  
  const { studentID } = useParams();
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [stdetails, setStDetails] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isStudentStatus, setIsStudentStatus] = useState(false);
  const [isAllocatedRM, setIsAllocatedRM] = useState(false);
  const [isAllocatedTeacher, setIsAllocatedTeacher] = useState(false);
  const [Esid, setEsid] = useState([]);
  const [isAllocatedClassroom, setIsAllocatedClassroom] = useState(false);
  const [isClass, setIsClass] = useState(false);
  const [rm_id, setRmId] = useState("");
  const [teacher_id, setTeacherId] = useState("");
  const [classroomID, setClassroomID] = useState("");
  const [response_text, setResponseText] = useState("");
  const [trial_subject, setTrialSubject] = useState("");
  const [trial_teacher, setTrialTeacher] = useState("");
  const [trial_time, setTrialTime] = useState("");
  const [trial_date, onChange] = useState(new Date());
  const [studentFinalStatus, setStudentFinalStatus] = useState("");
  const [lost_reason, setLostReason] = useState("");
  const [lost_expected_date, setLostExpectedDate] = useState(new Date());
  const user = useSelector((state) => state.salesDetails.sales);
  const [course_id, setCourseName] = useState("");
  const [subject_id, setSubjectName] = useState("");
  const [grade_id, setGradeName] = useState("");
  
  const handleCourseSelect = (value) => {
    setCourseName(value);
  };

  const handleGradeSelect = (value) => {
    setGradeName(value);
  };

  const handleSubjectSelect = (value) => {
    setSubjectName(value);
  };

  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  useEffect(() => {
    salesStudentDetail(studentID)
      .then((res) => {
        setStDetails(res);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmitES = () => {
    getESId(studentID)
      .then((res) => {
        setEsid(res);
      })
      .catch((err) => console.log(err));
  };

  const handleChangeStatus = (e) => {
    e.preventDefault();
    salesTrialStudent(studentID, user.sales_employee_id).then((res) => {
      salesStudentDecision({
        pk: res.id,
        salesID: res.salesID,
        trial_studentID: res.trial_studentID,
        teacherID: res.teacherID,
        date: res.date,
        trial_class_status: res.trial_class_status,
        stage: studentFinalStatus,
        lost_reason,
        lost_expected_date,
      })
        .then((res) => {
          if (res.id) {
            alert("Stage Updated Successfully!");
            setIsStudentStatus(!isStudentStatus);            
          } else {
            alert("Error! Status not updated properly.")
          }

        })
        .catch((err) => console.log(err));
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    salesTrialStudent(studentID, user.sales_employee_id).then((res) => {
      salesHCComments({
        sales_trial_id: res.id,
        date: new Date().toISOString(),
        response_text,
        object_id: user.sales_employee_id,
        trial_student_id: studentID,
      })
        .then((res) => {
          alert("Comment Posted Successfully!");
          setIsSubmitted(!isSubmitted);
        })
        .catch((err) => console.log(err));
    });
  };

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(deleteSales(user.sales_id));
    removeToken();
  };

  const handleSubmitRM = (e) => {
    e.preventDefault();
    enrolledStudentDetail(Esid.enrolled_student_id).then((res) => {
      salesAllocateRM({
        first_name: res.first_name,
        last_name: res.last_name,
        mobile_number: res.mobile_number,
        father_name: res.father_name,
        father_number: res.father_number,
        email: res.email,
        state: res.state,
        country: res.country,
        postal_code: res.postal_code,
        current_grade: res.current_grade,
        grade_id: res.grade_id,
        address: res.address,
        landmark: res.landmark,
        city: res.city,
        current_school: res.current_school,
        last_marks: res.last_marks,
        trial_student_id: res.trial_student_id,
        rm_id,
        enrolled_student_id: Esid.enrolled_student_id,
      })
        .then((res) => {
          if(res.student){
            alert("RM Allocated Successfully!");
            setIsAllocatedRM(!isAllocatedRM);
          } else {
            alert("Error. Check if student is enrolled and Click on Get ES Id First.")
          }

        })
        .catch((err) => console.log(err));
    });
  };

  const handleSubmitTeacher = (e) => {
    e.preventDefault();
    salesAllocateTeacher({
      student_id: Esid.enrolled_student_id,
      teacher_id,
      subject_id,
      course_id,
      grade_id,
      date: new Date().toISOString(),
    })
      .then((res) => {
        if (res.stc) {
          alert("Teacher Allocated Successfully!");
          setIsAllocatedTeacher(!isAllocatedTeacher);          
        } else {
          alert("Error! Check all details are properly filled.")
        }

      })
      .catch((err) => console.log(err));
  };

  const handleSubmitClassroom = () => {
    getTeacherClassroom(classroomID).then((res) => {
      salesAllocateClassroom({
        classroomID,
        student_id: Esid.enrolled_student_id,
        teacher_id: res[0].teacher_id,
        subject_id,
        course_id,
        grade_id,
        date: new Date().toISOString(),
      })
        .then((res) => {
          if (res.sts) {
            alert("Classroom Allocated Successfully!");
            setIsAllocatedClassroom(!isAllocatedClassroom);            
          } else {
            alert("Error! Check all details are properly filled.")
          }

        })
        .catch((err) => console.log(err));
    });
  };

  const handleBookClass = (e) => {
    e.preventDefault();
    trialStudentDetails(studentID).then((res) => {    
    var local_datetime = moment(
      moment(trial_date).format("YYYY-MM-DD") + " " + trial_time
    ).format();
    var gmt = moment.utc(local_datetime).format("YYYY-MM-DD HH:mm:ss");
    var gmt_date = moment(gmt).format("YYYY-MM-DD");
      var gmt_time = moment(gmt).format("HH:mm:ss");
      bookTrialClass(
        {
          students: studentID,
          teacher_id: trial_teacher,
          date: gmt_date,
          time: gmt_time,
          status: "Booked",
          datetime: moment(
            moment(trial_date).format("YYYY-MM-DD") + " " + trial_time
          ),
          subject_id: trial_subject,
          booking_date: moment(trial_date).format("YYYY-MM-DD"),
          booking_time: trial_time,
        },
        res.trial_class_count
      )
        .then((res) => {
          //console.log(res)
          if (!res.id) {
            alert(
              "Error in booking trial class. Please check details carefully."
            );
          } else {
            alert("Trial Class booked Successfully!");
            setIsClass(!isClass);
          }
        })
        .catch((err) => console.log(err));
    });
  };

  return (
    <div className="sales-student-details-1">
      <div className="sider-SalesStatistics-detail">
        <span style={{ color: "white" }}>
          <div className="sider-hc-teacher-list-in-wdt-80">
            <NavLink to="/sales-dashboard">
              <DashboardIcon
                data-tip="Dashboard"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/sales-my-students">
              {" "}
              <SchoolIcon data-tip="My Students" className="icon-in-hc-color" />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/sales-statistics">
              <AssessmentIcon
                data-tip="Analytics"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/sales-trial-student-form/:salesId">
              <AccountBalanceIcon
                data-tip="Trial Student Form"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            {/* <NavLink to="/rm-statistics">
              <FaRegQuestionCircle
                data-tip="Help Center"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink> */}
            <NavLink to="/" onClick={() => handleLogout()}>
              <PowerSettingsNewIcon
                data-tip="Logout"
                className="icon-in-hc-color"
              />
            </NavLink>
          </div>
        </span>
      </div>

      <header>
        <div className="head">
          <img src={pic_2} style={{ height: "115px" }} />
          {/* <img style={{ maxHeight: "100px" }} src={logo} alt="logo" /> */}
          <SideDrawerSales
            open={displaySideDrawer}
            closeSideDrawer={sideDrawerToggler}
          />

          <span
            className="fa fa-bars mob_nav"
            onClick={sideDrawerToggler}
          ></span>
          <img
            className="mobile_nav"
            style={{ float: "right", margin: "0 15px 0 20px" }}
            src={image}
            alt="profile"
          />

          <div
            className="mobile_nav"
            style={{ float: "right", textAlign: "right" }}
          >
            <h3 className="user-name" style={{ color: "#dc3545" }}>
              {user.first_name + " " + user.last_name}
            </h3>
            <p
              className="user-email"
              style={{ fontSize: "20px", color: "black" }}
            >
              {user && user.emailID}
            </p>
          </div>
        </div>
      </header>

      <Card
        style={{
          borderRadius: "10px",
          boxShadow: "0 0 5px rgba(0,0,0,0.2)",
          margin: "50px 50px 100px 130px",
          paddingBottom: "100px",
        }}
        className="card-ex"
      >
        <Card.Body style={{ width: "100%" }}>
          <div className="card-heading" style={{ marginBottom: "35px" }}>
            <p>Student Form Data of Trial Class</p>
          </div>
          <Form style={{ paddingLeft: "10px" }}>
            <Row>
              <Col>
                <Form.Group>
                  <div className="label">Student Name</div>
                  <Form.Control
                    type="name"
                    placeholder="Student Name"
                    value={
                      stdetails &&
                      stdetails.student &&
                      stdetails.student.first_name
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <div className="label">Father's Name</div>
                  <Form.Control
                    type="name"
                    placeholder="Father's Name"
                    value={
                      stdetails &&
                      stdetails.student &&
                      stdetails.student.father_name
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <div className="label">E-mail Address</div>
                  <Form.Control
                    type="name"
                    placeholder="Primary Email Address"
                    value={
                      stdetails && stdetails.student && stdetails.student.email
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <div className="label">Parent's Contact No.</div>
                  <Form.Control
                    type="name"
                    placeholder="Parent's Mobile Number"
                    value={
                      stdetails &&
                      stdetails.student &&
                      stdetails.student.father_number
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <div className="label">Other Contact No.</div>
                  <Form.Control
                    type="name"
                    placeholder="Other Mobile Number"
                    value={
                      stdetails &&
                      stdetails.student &&
                      stdetails.student.mobile_number
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <div className="label">Child's Current Grade</div>
                  <Form.Control
                    type="name"
                    placeholder="Child's Current Grade"
                    value={
                      stdetails &&
                      stdetails.student &&
                      stdetails.student.current_grade
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <div className="label">Country</div>
                  <Form.Control
                    type="name"
                    placeholder="Country"
                    value={
                      stdetails &&
                      stdetails.student &&
                      stdetails.student.country
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <div className="label">State</div>
                  <Form.Control
                    type="name"
                    placeholder="State"
                    value={
                      stdetails && stdetails.student && stdetails.student.state
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <div className="label">Postal Code</div>
                  <Form.Control
                    type="name"
                    placeholder="Postal Code"
                    value={
                      stdetails &&
                      stdetails.student &&
                      stdetails.student.postal_code
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <div className="label">Preferred Subject</div>
                  <Form.Control
                    type="name"
                    placeholder="Preferred Subject"
                    value={
                      stdetails &&
                      stdetails.student &&
                      stdetails.student.preferred_subject
                    }
                  />
                </Form.Group>
              </Col>
              {/* <Col>
                <Form.Group>
                  <div className="label">Preferred Timeslot</div>
                  <Form.Control type="name" placeholder="Preferred Time Slot" />
                </Form.Group>
              </Col> */}
            </Row>
          </Form>

          <div className="card-heading">
            <p>Student's Feedback</p>
          </div>
          <div className="heading_line">
            <p>
              On a scale of 1-10, where 1 being the lowest and 10 being the
              highest, how would you rate your learning experience?
            </p>
          </div>

          {stdetails && stdetails.responses && (
            <div classname="rating" style={{ marginLeft: "25px" }}>
              {" "}
              <ReactStars
                value={parseFloat(stdetails.responses[0].answer)}
                edit={false}
                count={10}
                size={30}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                style={"margin-left: 40px"}
                activeColor="#dc3545"
              />
            </div>
          )}

          <div className="heading_line">
            <p>
              On a scale of 1-10, where 1 being the lowest and 10 being the
              highest, how would you rate our teaching platform?
            </p>
          </div>
          {stdetails && stdetails.responses && (
            <div classname="rating" style={{ marginLeft: "25px" }}>
              {" "}
              <ReactStars
                value={parseFloat(stdetails.responses[1].answer)}
                edit={false}
                count={10}
                size={30}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                style={"margin-left: 40px"}
                activeColor="#dc3545"
              />
            </div>
          )}

          <div className="heading_line">
            <p>
              On a scale of 1-10, where 1 being the lowest and 10 being the
              highest, how would you rate our content of teaching?
            </p>
          </div>
          {stdetails && stdetails.responses && (
            <div classname="rating" style={{ marginLeft: "25px" }}>
              {" "}
              <ReactStars
                value={parseFloat(stdetails.responses[2].answer)}
                edit={false}
                count={10}
                size={30}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                style={"margin-left: 40px"}
                activeColor="#dc3545"
              />
            </div>
          )}

          <div className="heading_line">
            <p>
              On a scale of 1-10, where 1 being the lowest and 10 being the
              highest, how would you rate the teaching manner of our teacher?
            </p>
          </div>
          {stdetails && stdetails.responses && (
            <div classname="rating" style={{ marginLeft: "25px" }}>
              {" "}
              <ReactStars
                value={parseFloat(stdetails.responses[3].answer)}
                edit={false}
                count={10}
                size={30}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                style={"margin-left: 40px"}
                activeColor="#dc3545"
              />
            </div>
          )}

          <div className="heading_line">
            <p>Any other comments/reviews/suggestions?</p>
          </div>
          <input
            type="text"
            className="comment"
            value={
              stdetails && stdetails.responses && stdetails.responses[4].answer
            }
          />

          <div className="card-heading" style={{ marginTop: "60px" }}>
            <p>Teacher's Feedback</p>
          </div>
          <div className="heading_line">
            <p>
              Please comment about students interest, aims or any other relevant
              information you obtained during live Class that may help sales
              team.
            </p>
          </div>
          <textarea
            style={{ height: "300px", width: "90%" }}
            type="text"
            rows="10"
            cols="30"
            className="comment"
            value={stdetails && stdetails.teacher_response}
          ></textarea>

          {stdetails ? (
            stdetails.saleshc_discussion ? (
              stdetails.saleshc_discussion.length > 0 ? (
                stdetails.saleshc_discussion.map((discuss) => {
                  return (
                    <div>
                      <div className="heading_line">
                        <p>
                          {discuss.object_id.includes("SA") == true
                            ? "Sales"
                            : discuss.object_id.includes("RM") == true
                            ? "Relational Manager"
                            : discuss.object_id.includes("AC") == true
                            ? "Account"
                            : "Higher Committee"}
                        </p>
                      </div>
                      <input
                        type="text"
                        className="comment_2"
                        placeholder="He wants one-one class of mathematics."
                        value={discuss.response_text}
                      />
                    </div>
                  );
                })
              ) : (
                <div />
              )
            ) : (
              <div />
            )
          ) : (
            <div />
          )}
        </Card.Body>

        <div className="card-heading">
          <p>Sales Higher Committee Discussion</p>
        </div>

        <div className="rm-teacher-allocation-div" style={{ margin: "25px" }}>
          <textarea
            style={{
              padding: "10px 0 0 10px",
              borderStyle: "none",
              boxShadow: "1px 1px 10px rgba(0, 0, 0, 0.2)",
            }}
            className="input-rm-teacher-all"
            cols="100"
            rows="4"
            placeholder="Type your comments here"
            onChange={(e) => setResponseText(e.target.value)}
          ></textarea>
          <Button
            variant="danger"
            className="button-rm-teach-all"
            onClick={(e) => handleSubmit(e)}
          >
            Post
          </Button>
        </div>

        <div className="card-heading" style={{ marginTop: "40px" }}>
          <p>Trial Student</p>
        </div>

        <div className="trial_student">
          <Form.Group>
            <Form.Row>
              <Form.Label className="trial_student_label" column lg={2}>
                Teacher ID
              </Form.Label>
              <Col>
                <input
                  type="text"
                  className="input-rm-teacher-all"
                  onChange={(e) => setTrialTeacher(e.target.value)}
                />
              </Col>
            </Form.Row>
            <br />
            <Form.Row>
              <Form.Label className="trial_student_label" column lg={2}>
                Subject ID
              </Form.Label>
              <Col>
                <input
                  type="text"
                  className="input-rm-teacher-all"
                  onChange={(e) => setTrialSubject(e.target.value)}
                />
              </Col>
            </Form.Row>
            <br />
            <Form.Row>
              <Form.Label className="trial_student_label" column lg={2}>
                Time
              </Form.Label>
              <Col lg={7}>
                <input
                  style={{ maxWidth: "96%" }}
                  placeholder="hh:mm"
                  type="text"
                  className="input-rm-teacher-all"
                  onChange={(e) => setTrialTime(e.target.value)}
                />
              </Col>
              <Col lg={3} style={{ display: "flex", alignItems: "center" }}>
                <div className="warning-msg">
                  <p
                    style={{
                      color: "#ec3545",
                      fontSize: "13px",
                      fontWeight: "550",
                    }}
                  >
                    ! Please Enter time in specified Format
                  </p>
                </div>
              </Col>
            </Form.Row>
            <br />
            <Form.Row>
              <Form.Label className="trial_student_label" column lg={2}>
                Date
              </Form.Label>
              <Col
                style={{
                  paddingLeft: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <DatePicker className="picker-dimensions2" onChange={onChange} value={trial_date} /> */}
                <DatePicker
                  value={trial_date}
                  onChange={onChange}
                  animateYearScrolling
                  format="ll"
                />
              </Col>
            </Form.Row>
          </Form.Group>
          <Form.Row>
            {" "}
            <Button
              onClick={(e) => handleBookClass(e)}
              variant="danger"
              className="button-rm-teach-all"
            >
              Book Trial Class
            </Button>
          </Form.Row>
        </div>

        <div className="card-heading" style={{ margin: "30px 0" }}>
          <p>Enroll Student</p>
        </div>

        <div className="rm-teacher-allocation-div">
          <input
            type="text"
            name="TSID"
            placeholder="TS ID"
            className="input-rm-teacher-all"
            value={Esid && Esid.enrolled_student_id}
          />
          <Button
            onClick={() => handleSubmitES()}
            variant="danger"
            className="button-rm-teach-all"
          >
            Get ES ID
          </Button>
          {/* <Button variant="danger" className="button-rm-teach-all">Allocate RM</Button> */}
        </div>

        <div className="rm-teacher-allocation-div">
          <input
            type="text"
            name="RmID"
            placeholder="Rm ID"
            className="input-rm-teacher-all"
            onChange={(e) => setRmId(e.target.value)}
          />
          <Button
            onClick={(e) => handleSubmitRM(e)}
            variant="danger"
            className="button-rm-teach-all"
          >
            Allocate RM
          </Button>
          {/* <Button variant="danger" className="button-rm-teach-all">Allocate RM</Button> */}
        </div>

        <div className="bottom-content">
          <div className="bottom-content-1">
            <DropdownOption
              handleCourseSelect={handleCourseSelect}
              handleGradeSelect={handleGradeSelect}
              handleSubjectSelect={handleSubjectSelect}
            />
          </div>

          <div className="bottom-content-2">
            <h3 style={{ fontSize: "20px", fontWeight: "500", margin: "10px" }}>
              If Student is joining for one-to-one classes
            </h3>
            <input
              className="input-rm-teacher-all"
              type="text"
              placeholder="teacher ID"
              onChange={(e) => setTeacherId(e.target.value)}
            />
            <Button
              onClick={(e) => handleSubmitTeacher(e)}
              variant="danger"
              className="button-rm-teach-all"
            >
              Allocate Teacher
            </Button>

            <div className="warning-msg-2">
              <p
                style={{
                  color: "#ec3545",
                  fontSize: "13px",
                  fontWeight: "550",
                }}
              >
                ! Please Fetch the ES ID First
              </p>
            </div>
            {/* <Button className="button-rm-teach-all" variant="danger" >Allocate Teacher</Button> */}
          </div>

          <div className="bottom-content-3">
            <h3 style={{ fontSize: "20px", fontWeight: "500", margin: "10px" }}>
              If Student is joining for a classroom program
            </h3>
            <input
              className="input-rm-teacher-all"
              type="text"
              placeholder="Classroom ID"
              onChange={(e) => setClassroomID(e.target.value)}
            />
            <Button
              onClick={() => handleSubmitClassroom()}
              variant="danger"
              className="button-rm-teach-all"
            >
              Allocate Classroom
            </Button>

            <div className="warning-msg-2">
              <p
                style={{
                  color: "#ec3545",
                  fontSize: "13px",
                  fontWeight: "550",
                }}
              >
                ! Please Fetch the ES ID First
              </p>
            </div>
          </div>

          <div className="card-heading" style={{ marginTop: "60px" }}>
            <p>Final Status</p>
          </div>

          <Form.Row className="drop2">
            <Form.Label
              style={{ fontSize: "18px", fontWeight: "500" }}
              column
              lg={9}
            >
              Update final status (only after final decision of student)
            </Form.Label>
            <Col>
              <Dropdown onSelect={(e) => setStudentFinalStatus(e)}>
                <Dropdown.Toggle
                  className="dropdown_text"
                  variant="danger"
                  id="dropdown-basic"
                >
                  Select
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="Converted">Converted</Dropdown.Item>
                  <Dropdown.Item eventKey="Lost Expected">
                    Lost Expected
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="Lost Intent">
                    Lost Intent
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="Lost Price">
                    Lost Price
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Form.Row>

          <div style={{ margin: "30px 0 0 15px" }}>
            <Form.Row>
              <Form.Label className="trial_student_label" column lg={2}>
                Date to Contact Again
              </Form.Label>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "20px",
                }}
              >
                <DatePicker
                  value={lost_expected_date}
                  onChange={setLostExpectedDate}
                  animateYearScrolling
                  format="ll"
                />
              </Col>
            </Form.Row>
            <br />
            <Form.Row>
              <Form.Label className="trial_student_label" column lg={2}>
                Reason
              </Form.Label>
              <Col>
                <input
                  type="text"
                  className="input-rm-teacher-all"
                  onChange={(e) => setLostReason(e.target.value)}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              {" "}
              <Button
                onClick={(e) => handleChangeStatus(e)}
                variant="danger"
                className="button-rm-teach-all"
              >
                Final Status
              </Button>
            </Form.Row>
          </div>
        </div>
      </Card>

      <div style={{ marginTop: "100px" }}>.</div>
      {/* <Popup /> */}
    </div>
  );
}

export default Form1;
