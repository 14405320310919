import React, { useState, useEffect, useLayoutEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import line_5_2x from "./img/line-5@2x.svg";
import line_6_2x from "./img/line-6@2x.svg";
import { FaRegQuestionCircle, FaCalendarAlt, FaFileAlt, FaChartPie, FaStickyNote, FaPowerOff } from "react-icons/fa";
import { MdAssignment, MdDashboard, MdNotificationsNone, MdSchool } from "react-icons/md";
import { BiSupport, BiHistory } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { AiTwotoneBank } from "react-icons/ai";
import vector_6_2x from "./img/vector-6@2x.svg";
import line_5_1_2x from "./img/line-5-1@2x.svg";
import line_6_1_2x from "./img/line-6-1@2x.svg";
import vector_1_2x from "./img/vector-1@2x.svg";
import vector_2x from "./img/vector@2x.png";
import vector_22_2x from "./img/vector-22@2x.svg";
import vector_23_2x from "./img/vector-23@2x.svg";
import ellipse_2_1_2x from "./img/ellipse-2-1@2x.svg";
import { rmTicketList } from "../../../crud/rm.crud";
import SideDrawerRm from '../../../UI/sideDrawer/sideDrawerRm';
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from '../../../utils';
import { deleteRm } from '../../../redux/actions/rm';
import Popup from '../../teacher/popup/popup';
import RmIssueList from '../table_dash/rm_table';
import pic_2 from "../../student/dashboard/img/pic_2.png";
function Relationalmanagerticketlist(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const user = useSelector((state) => state.rmDetails.rm);
  const [tickets, setTickets] = useState([])
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [handleFilter, setHandleFilter] = useState('');

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.rmDetails.rm);
  const handleLogout = () => {
    dispatch(deleteRm(reduxUser.rm_id))
    removeToken();
  }

  const sideDrawerToggler = () => {
    setDisplaySideDrawer(previousState => !previousState);
  };

  useEffect(() => {
    rmTicketList(user && user.rm_id)
      .then(res => {
        if (res.hasOwnProperty("message")) {
          setTickets(false)
        } else setTickets(res);
      })
      .catch(err => console.log(err))
  }, [])

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="relational-manager-ticket-list"
      />
      <div className="container-center-horizontal">
        <div className="relational-manager-ticket-mobile-10 screen">
          <div className="flex-row-C61RwL">
            <div className="component-1-dXBp89">
              <img src={pic_2} style={{ height: "100px", margin: "-8%" }} />
            </div>
            <SideDrawerRm
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />
            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>
          <div className="group-127-C61RwL-10-11">
            <div className="my-tickets-2zqKZg">
              <span className="span0-6gnXOk">My</span>
              <span className="span1-6gnXOk">&nbsp;</span>
              <span className="span2-6gnXOk">Tickets</span>
            </div>
            <input
              type="text"
              placeholder="Filter List"
              style={{ width: "200px", marginLeft: "10px", marginTop: "5px" }}
              onChange={(e) => setHandleFilter(e.target.value)}
            />
            <div className="overlap-group1-2zqKZg">
              <div className="dashboard-24px-1-759J8x">
                <img className="vector-L9XHu9" src="img/vector@2x.png" />
              </div>
              <div className="group-45-759J8x">
                <div className="rectangle-20-aIq8ji">
                  <div className="roboto-bold-white-14px">Issue ID</div>
                  <div className="roboto-bold-white-14px">Ticket Status</div>
                  <div className="roboto-bold-white-14px">Date</div>
                  <div className="roboto-bold-white-14px">Category</div>
                </div>
              </div>
            </div>
            <div className="overlap-group-2zqKZg">
              <div className="class-name-eVSctS roboto-medium-white-20px">
                Class Name
              </div>
              <div className="s-no-eVSctS roboto-medium-white-20px">S. No.</div>
              <div className="student-name-eVSctS roboto-medium-white-20px">
                Student Name
              </div>
              <div className="date-eVSctS roboto-medium-white-20px">Date</div>
              <div className="time-eVSctS roboto-medium-white-20px">Time</div>
              <div className="rectangle-30-eVSctS-10-1-002">
                <div className="akljs-awjon-da1">
                  {tickets.length > 0 ? (
                    tickets
                      .filter((val) => {
                        if (handleFilter == "") {
                          return val;
                        } else if (
                          val.issue_id
                            .toString()
                            .toLowerCase()
                            .includes(handleFilter.toLowerCase())
                        ) {
                          return val;
                        }
                      })
                      .map((ticket) => {
                        return (
                          <div
                            className="group-56-eVSctS"
                            style={{ fontFamily: "'Montserrat', Helvetica" }}
                          >
                            <div>{ticket.issue_id}</div>
                            <div>{ticket.ticket_status}</div>
                            <div>
                              {moment(ticket.date_raised).format("DD-MM-YYYY")}
                            </div>
                            <div>{ticket.category}</div>
                          </div>
                        );
                      })
                  ) : (
                    <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                      No tickets raised by you.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="group-210-C61RwL-10-101-1">
            <RmIssueList userState={user} />
          </div>
        </div>
      </div>
      <div className="container-center-horizontal">
        <div className="relational-manager-ticket-list screen">
          <div className="group-209-C61RwL-101-0">
            <Link to="/rm-dashboard">
              <MdDashboard
                size={24}
                data-tip="Dashboard"
                color="rgba(179,179,179,1.0)"
              />
              <ReactTooltip />
            </Link>
            <div className="historytoggleoff-24px-1-D0BVcB">
              <div className="group-rAjf8W">
                <Link to="/rm-issue-list">
                  {" "}
                  <BiHistory
                    data-tip="Previous Issues"
                    size={24}
                    color="rgba(179,179,179,1.0)"
                  />
                  <ReactTooltip />
                </Link>
              </div>
            </div>
            <div className="school-24px-1-D0BVcB">
              <Link to="/rm-list">
                <MdSchool
                  data-tip="My Students"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
            <div className="help-24px-2-D0BVcB">
              <Link to="/rm-ticket-list">
                <FaRegQuestionCircle
                  data-tip="Tickets"
                  size={24}
                  color="#dc3545"
                />
                <ReactTooltip />
              </Link>
            </div>
            <div className="analytics-24px-4-D0BVcB">
              <Link to="/rm-statistics">
                <FaChartPie
                  data-tip="Analytics"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
            {/* <div className="accountbalance-24px-1-D0BVcB">
              <Link to="/rm-payments"><AiTwotoneBank data-tip="Payments" size={24} color="rgba(179,179,179,1.0)" /><ReactTooltip /></Link>
            </div> */}
            <div className="vector-Ayxm2x">
              <Link to="/" onClick={() => handleLogout()}>
                <FaPowerOff
                  data-tip="Logout"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
          </div>
          <div className="flex-col-C61RwL-wwa">
            <div className="flex-row-0xy0vn">
              <div className="component-1-GyJUNH">
                <img alt="image" src={pic_2} style={{ height: "100px" }} />
              </div>
              <div className="notifications-24px-2-1-GyJUNH">
                <div className="vector-40xw7g">
                  <MdNotificationsNone size={28} />
                </div>
                <img className="ellipse-2-RYyMFx" src={ellipse_2_1_2x} />
              </div>
              <div className="group-22-GyJUNH">
                <div className="biba-sobti-vgli2x">
                  {user && user.first_name} {user && user.last_name}
                </div>
                <div className="bibasobtigmailcom-vgli2x">
                  {user && user.emailID}
                </div>
              </div>
              <img className="vector-GyJUNH" src={vector_1_2x} />
            </div>
            <div className="flex-row-oV5UrE">
              <div className="flex-col-QkTteQ">
                <div className="my-tickets-Fjgz1C-0012">
                  <div>
                    <span className="span0-xITcw8">My</span>
                    <span className="span1-xITcw8"> Tickets</span>
                  </div>
                  <input
                    type="text"
                    placeholder="Filter List"
                    style={{ width: "210px", marginRight: "5px" }}
                    onChange={(e) => setHandleFilter(e.target.value)}
                  />
                </div>
                <div className="overlap-group1-Fjgz1C">
                  <div className="dashboard-24px-1-1UTYTe">
                    <img className="vector-NlXsxj" src={vector_2x} />
                  </div>
                  <div className="group-45-1UTYTe">
                    <div className="rectangle-20-fqPQjE-kunal-8K">
                      <div className=" roboto-medium-white-20px">Issue ID</div>
                      <div className=" roboto-medium-white-20px">
                        Ticket Status
                      </div>
                      <div className=" roboto-medium-white-20px">Date</div>
                      <div className=" roboto-medium-white-20px">Category</div>
                    </div>
                  </div>
                </div>
                <div className="overlap-group-Fjgz1C">
                  <img
                    className="rectangle-7-PXwdVf"
                    src="img/rectangle-7@1x.png"
                  />
                  <div className="dashboard-24px-1-PXwdVf">
                    <img className="vector-OUzaf1" src="img/vector@2x.png" />
                  </div>
                  <div className="rectangle-30-PXwdVf-10-1">
                    <div className="akljs-awjon-da1">
                      {tickets.length > 0 ? (
                        tickets
                          .filter((val) => {
                            if (handleFilter == "") {
                              return val;
                            } else if (
                              val.issue_id
                                .toString()
                                .toLowerCase()
                                .includes(handleFilter.toLowerCase())
                            ) {
                              return val;
                            }
                          })
                          .map((ticket) => {
                            return (
                              <div
                                className="group-56-PXwdVf-kunal"
                                style={{
                                  fontFamily: "'Montserrat', Helvetica",
                                }}
                              >
                                <div>{ticket.issue_id}</div>
                                <div>{ticket.ticket_status}</div>
                                <div>
                                  {moment(ticket.date_raised).format(
                                    "DD-MM-YYYY"
                                  )}
                                </div>
                                <div>{ticket.category}</div>
                              </div>
                            );
                          })
                      ) : (
                        <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                          No tickets raised by you.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-202-QkTteQ">
                <RmIssueList userState={user} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup />
    </>
  );
}

export default Relationalmanagerticketlist;