import React, { useEffect, useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import pic_2 from "../dashboard/img/pic_2.png";

import {
  FaRegQuestionCircle,
  FaCalendarAlt,
  FaFileAlt,
  FaChartPie,
  FaStickyNote,
  FaPowerOff,
} from "react-icons/fa";
import { MdAssignment, MdDashboard } from "react-icons/md";

import { BiSupport } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import ReactTooltip from "react-tooltip";
import { PieChart } from "react-minimal-pie-chart";
import "./style.css";
import Popup from "../../teacher/popup/popup";
import { studentPerformance } from "../../../crud/student.crud";
import { useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteStudent } from "../../../redux/actions/student";
// images

import vector_1_2x from "./img/vector-1@2x.png";
import vector_51_2x from "./img/vector-51@2x.png";
import ellipse_5_4 from "./img/ellipse-5-4@2x.png";
import ellipse_6_4 from "./img/ellipse-6-4@2x.png";
import ellipse_8_2x from "./img/ellipse-8@2x.png";
import ellipse_7_2_2x from "./img/ellipse-7-2@2x.png";
import ellipse_7_2x from "./img/ellipse-7@2x.png";
import ellipse_6_5_2x from "./img/ellipse-6-5@2x.png";
import ellipse_5_5_2x from "./img/ellipse-5-5@2x.png";
import ellipse_7_1 from "./img/ellipse-7-1@2x.png";
import line5_1_2x from "./img/line-5-1@2x.png";
import line6_1_2x from "./img/line-6-1@2x.png";
import ellipse_5_2x from "./img/ellipse-5@2x.png";
import ellipse_6_2x from "./img/ellipse-6@2x.png";
import ellipse_2_2x from "./img/ellipse-2@2x.png";
import vector_13_2x from "./img/vector-13@2x.png";
import rectangle_7_1x from "./img/rectangle-7@1x.png";
import vector_11_2x from "./img/vector-11@2x.png";
import SideDrawerStudent from "../../../UI/sideDrawer/sideDrawerStudent";

import BackDrop from "../../../UI/Backdrop/Backdrop";

import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";

function Studentperformance(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const user = useSelector((state) => state.studentDetails.student);
  const [perfData, setPerfData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const ModalTogglerHandler = (e) => {
    e.preventDefault();
    setOpenModal((per) => !per);
  };

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteStudent(reduxUser.enrolled_student_id));
    removeToken();
  };

  let atClass = ["Modal_in_student_per", "Close"];

  if (openModal) {
    atClass = ["Modal_in_student_per", "Open"];
  }

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  useEffect(() => {
    studentPerformance(user.enrolled_student_id)
      .then((res) => {
        setPerfData(res);
        setIsLoaded(true);
      })
      .catch((err) => console.log(err));
  }, []);

  let classesData = [];
  let doubtsData = [];
  let assignData = [];
  let quizData = [];

  isLoaded
    ? perfData.classes
      ? (classesData = [
          {
            title: "Completed",
            name: "Completed",
            value: perfData.classes.completed_classes,
            color: "#6F58FF",
          },
          {
            title: "Booked",
            name: "Booked",
            value: perfData.classes.booked_classes,
            color: "#46C9B8",
          },
          {
            title: "Cancelled",
            name: "Cancelled",
            value: perfData.classes.cancelled_classes,
            color: "#F76775",
          },
        ])
      : (classesData = [])
    : (classesData = []);

  isLoaded
    ? perfData.classes
      ? (doubtsData = [
          {
            title: "Asked",
            name: "Asked",
            value: perfData.doubts.asked,
            color: "#6F58FF",
          },
          {
            title: "Responded",
            name: "Responded",
            value: perfData.doubts.answered,
            color: "#46C9B8",
          },
        ])
      : (doubtsData = [])
    : (doubtsData = []);

  isLoaded
    ? perfData.classes
      ? (assignData = [
          {
            title: "Assigned",
            name: "Assigned",
            value: perfData.assignments.assigned,
            color: "#6F58FF",
          },
          {
            title: "Submitted",
            name: "Submitted",
            value: perfData.assignments.submitted,
            color: "#46C9B8",
          },
        ])
      : (assignData = [])
    : (assignData = []);

  isLoaded
    ? perfData.classes
      ? (quizData = [
          {
            title: "Assigned",
            name: "Assigned",
            value: perfData.quizzes.assigned,
            color: "#6F58FF",
          },
          {
            title: "Submitted",
            name: "Submitted",
            value: perfData.quizzes.submitted,
            color: "#46C9B8",
          },
        ])
      : (quizData = [])
    : (quizData = []);

  const data = [
    {
      name: "Completed",
      da: 75,
    },
    {
      name: "Booked",
      da: 100,
    },
    {
      name: "Cancelled ",
      da: 20,
    },
  ];

  return (
    <>
      <form
        className="student-performance screen"
        name="form1"
        action="form1"
        method="post"
      >
        <div className="student-re-flex">
          <div className="component-1-C61RwLz-3">
            <img
              alt="image"
              src={pic_2}
              style={{ height: "115px", marginTop: "-8%" }}
            />
          </div>
          <div className="group-22-GyJUNH-student-dash">
            <div className="flex-col-vgli2x-student-dash">
              <div className="biba-sobti-h8V8ZR-student-dash">
                {user.first_name + " " + user.last_name}
              </div>
              <div className="bibasobtigmailcom-h8V8ZR-student-dash">
                {user && user.email}
              </div>
            </div>
            <img className="vector-vgli2x-student-dash" src={vector_11_2x} />
          </div>
        </div>

        <img className="rectangle-7-C61RwL" src={rectangle_7_1x} />
        {/* <div className="notifications-24px-2-1-C61RwL">
          <img className="vector-OSIBbx" src={vector_1_2x} />
          <img className="vector-CLdsug" src={vector_13_2x} />
          <img className="ellipse-2-OSIBbx" src={ellipse_2_2x} />
        </div> */}

        <div className="my-performance-C61RwL">
          <span className="span1-3EE9bF">
            <span style={{ fontWeight: "400" }}>My</span> Performance
          </span>

          <button className="showModalTogler" onClick={ModalTogglerHandler}>
            Show BarGraph
          </button>
        </div>

        <div className="group-69-C61RwL">
          <div className="group-57-8FQU39"></div>

          <div className="classes-8FQU39 montserrat-medium-blue-zodiac-18px">
            Classes
          </div>
          {isLoaded ? (
            <PieChart
              className="donut-chart-1-8FQU39"
              lineWidth={30}
              rounded
              label={({ dataEntry }) => dataEntry.value}
              labelStyle={(index) => ({
                fill: classesData[index].color,
                fontSize: "5px",
                fontFamily: "sans-serif",
              })}
              labelPosition={60}
              data={classesData}
            />
          ) : (
            "Loading..."
          )}
          <img className="ellipse-5-8FQU39" src={ellipse_5_2x} />
          <img className="ellipse-6-8FQU39" src={ellipse_6_2x} />
          <img className="ellipse-7-8FQU39" src={ellipse_7_2x} />
          <div className="completed-8FQU39 montserrat-semi-bold-blue-zodiac-10px">
            Completed
          </div>
          <div className="booked-8FQU39 montserrat-semi-bold-blue-zodiac-10px">
            Booked
          </div>
          <div className="cancelled-8FQU39 montserrat-semi-bold-blue-zodiac-10px">
            Cancelled
          </div>
          <div className="attendance-75-8FQU39">
            Attendance:{" "}
            {isLoaded
              ? perfData.classes
                ? perfData.classes.attendance
                : "--"
              : "--"}
          </div>
        </div>

        <div className="group-71-C61RwL">
          <div className="group-57-R3ERnh"></div>

          <div className="assignments-R3ERnh montserrat-medium-blue-zodiac-18px">
            Assignments
          </div>
          {isLoaded ? (
            <PieChart
              className="donut-chart-1-R3ERnh"
              lineWidth={30}
              rounded
              label={({ dataEntry }) => dataEntry.value}
              labelStyle={(index) => ({
                fill: assignData[index].color,
                fontSize: "5px",
                fontFamily: "sans-serif",
              })}
              labelPosition={60}
              data={assignData}
            />
          ) : (
            "Loading..."
          )}
          <img className="ellipse-5-R3ERnh" src={ellipse_5_2x} />
          <img className="ellipse-6-R3ERnh" src={ellipse_6_2x} />
          <div className="assigned-R3ERnh montserrat-semi-bold-blue-zodiac-10px">
            Assigned
          </div>
          <div className="submitted-R3ERnh montserrat-semi-bold-blue-zodiac-10px">
            Submitted
          </div>
        </div>
        <div className="group-76-C61RwL">
          <div className="group-57-Z1JSTw"></div>
          <div className="quizzes-Z1JSTw montserrat-medium-blue-zodiac-18px">
            Quizzes
          </div>
          {isLoaded ? (
            <PieChart
              className="donut-chart-1-Z1JSTw"
              lineWidth={30}
              rounded
              label={({ dataEntry }) => dataEntry.value}
              labelStyle={(index) => ({
                fill: quizData[index].color,
                fontSize: "5px",
                fontFamily: "sans-serif",
              })}
              labelPosition={60}
              data={quizData}
            />
          ) : (
            "Loading..."
          )}
          <img className="ellipse-5-Z1JSTw" src={ellipse_5_2x} />
          <img className="ellipse-6-Z1JSTw" src={ellipse_6_2x} />
          <div className="assigned-Z1JSTw montserrat-semi-bold-blue-zodiac-10px">
            Assigned
          </div>
          <div className="submitted-Z1JSTw montserrat-semi-bold-blue-zodiac-10px">
            Submitted
          </div>
        </div>
        <div className="group-70-C61RwL">
          <div className="group-57-kber4s"></div>
          <div className="doubts-kber4s montserrat-medium-blue-zodiac-18px">
            Doubts
          </div>
          {isLoaded ? (
            <PieChart
              className="donut-chart-1-kber4s"
              lineWidth={30}
              rounded
              label={({ dataEntry }) => dataEntry.value}
              labelStyle={(index) => ({
                fill: doubtsData[index].color,
                fontSize: "5px",
                fontFamily: "sans-serif",
              })}
              labelPosition={60}
              data={doubtsData}
            />
          ) : (
            "Loading..."
          )}
          <img className="ellipse-5-kber4s" src={ellipse_5_2x} />
          <img className="ellipse-6-kber4s" src={ellipse_6_2x} />
          <div className="asked-kber4s montserrat-semi-bold-blue-zodiac-10px">
            Asked
          </div>
          <div className="responded-kber4s montserrat-semi-bold-blue-zodiac-10px">
            Responded
          </div>
        </div>
        {/* <div className="group-72-C61RwL">
          <div className="rectangle-40-gqbUX1"></div>
          <div className="component-29-gqbUX1">
            <div className="assignment-24px-3-lxV7ix">
              <img className="vector-YDNU6K" src={vector_1_2x} />
              <img className="vector-hGGfYL" src={vector_15_2x} />
            </div>
          </div>
          <div className="assignment-1-gqbUX1 montserrat-semi-bold-blue-zodiac-10px">ASSIGNMENT 1</div>
          <div className="mathematics-dr-yashna-sharma-gqbUX1 montserrat-medium-gray-7px">
            Mathematics (Dr. Yashna Sharma)
      </div>
          <div className="submitted-gqbUX1 montserrat-semi-bold-niagara-8px">Submitted</div>
        </div> */}
        {/* <div className="group-77-C61RwL">
          <div className="rectangle-40-2osFvC"></div>
          <div className="component-29-2osFvC">
            <div className="assignment-24px-3-kNZYuA">
              <img className="vector-hMWl3h" src={vector_1_2x} />
              <img className="vector-o8aFZp" src={vector_15_2x} />
            </div>
          </div>
          <div className="quiz-1-2osFvC montserrat-semi-bold-blue-zodiac-10px">QUIZ 1</div>
          <div className="mathematics-dr-yashna-sharma-2osFvC montserrat-medium-gray-7px">
            Mathematics (Dr. Yashna Sharma)
      </div>
          <div className="submitted-2osFvC montserrat-semi-bold-niagara-8px">Submitted</div>
        </div> */}
        {/* <div className="group-73-C61RwL">
          <div className="rectangle-40-ql7mN0"></div>
          <div className="component-29-ql7mN0">
            <div className="assignment-24px-3-MxHjgv">
              <img className="vector-x8YWNM" src={vector_1_2x} />
              <img className="vector-UwmKsc" src={vector_15_2x} />
            </div>
          </div>
          <div className="assignment-1-ql7mN0 montserrat-semi-bold-blue-zodiac-10px">ASSIGNMENT 1</div>
          <div className="mathematics-dr-yashna-sharma-ql7mN0 montserrat-medium-gray-7px">
            Mathematics (Dr. Yashna Sharma)
      </div>
          <div className="submitted-ql7mN0 montserrat-semi-bold-niagara-8px">Submitted</div>
        </div> */}
        {/* <div className="group-78-C61RwL">
          <div className="rectangle-40-hlxJRL"></div>
          <div className="component-29-hlxJRL">
            <div className="assignment-24px-3-jHyK8g">
              <img className="vector-Ojow4P" src={vector_1_2x} />
              <img className="vector-9Sr3Gx" src={vector_15_2x} />
            </div>
          </div>
          <div className="quiz-1-hlxJRL montserrat-semi-bold-blue-zodiac-10px">QUIZ 1</div>
          <div className="mathematics-dr-yashna-sharma-hlxJRL montserrat-medium-gray-7px">
            Mathematics (Dr. Yashna Sharma)
      </div>
          <div className="submitted-hlxJRL montserrat-semi-bold-niagara-8px">Submitted</div>
        </div> */}
        {/* <div className="group-74-C61RwL">
          <div className="rectangle-40-aCi14x"></div>
          <div className="component-29-aCi14x">
            <div className="assignment-24px-3-DVuPOa">
              <img className="vector-Z87Q8p" src={vector_1_2x} />
              <img className="vector-5tYqrB" src={vector_15_2x} />
            </div>
          </div>
          <div className="assignment-1-aCi14x montserrat-semi-bold-blue-zodiac-10px">ASSIGNMENT 1</div>
          <div className="mathematics-dr-yashna-sharma-aCi14x montserrat-medium-gray-7px">
            Mathematics (Dr. Yashna Sharma)
      </div>
          <div className="submitted-aCi14x montserrat-semi-bold-niagara-8px">Submitted</div>
        </div> */}
        {/* <div className="group-79-C61RwL">
          <div className="rectangle-40-sbe5OJ"></div>
          <div className="component-29-sbe5OJ">
            <div className="assignment-24px-3-iPxDjX">
              <img className="vector-MX8ksh" src={vector_1_2x} />
              <img className="vector-Z3Homl" src={vector_15_2x} />
            </div>
          </div>
          <div className="quiz-1-sbe5OJ montserrat-semi-bold-blue-zodiac-10px">QUIZ 1</div>
          <div className="mathematics-dr-yashna-sharma-sbe5OJ montserrat-medium-gray-7px">
            Mathematics (Dr. Yashna Sharma)
      </div>
          <div className="submitted-sbe5OJ montserrat-semi-bold-niagara-8px">Submitted</div>
        </div> */}
        {/* <div className="group-75-C61RwL">
          <div className="rectangle-40-sDLNVS"></div>
          <div className="component-29-sDLNVS">
            <div className="assignment-24px-3-z4xZZj">
              <img className="vector-hyPzor" src={vector_1_2x} />
              <img className="vector-McuNxD" src={vector_15_2x} />
            </div>
          </div>
          <div className="assignment-1-sDLNVS montserrat-semi-bold-blue-zodiac-10px">ASSIGNMENT 1</div>
          <div className="mathematics-dr-yashna-sharma-sDLNVS montserrat-medium-gray-7px">
            Mathematics (Dr. Yashna Sharma)
      </div>
          <div className="submitted-sDLNVS montserrat-semi-bold-niagara-8px">Submitted</div>
        </div> */}
        {/* <div className="group-80-C61RwL">
          <div className="rectangle-40-CX7m3G"></div>
          <div className="component-29-CX7m3G">
            <div className="assignment-24px-3-Ll6NDG">
              <img className="vector-xjYbuu" src={vector_1_2x} />
              <img className="vector-oLJRUl" src={vector_15_2x} />
            </div>
          </div>
          <div className="quiz-1-CX7m3G montserrat-semi-bold-blue-zodiac-10px">QUIZ 1</div>
          <div className="mathematics-dr-yashna-sharma-CX7m3G montserrat-medium-gray-7px">
            Mathematics (Dr. Yashna Sharma)
      </div>
          <div className="submitted-CX7m3G montserrat-semi-bold-niagara-8px">Submitted</div>
        </div> */}
        <div className="rectangle-24-C61RwL-stu-s1">
          <Link to="/student-dashboard">
            <div data-tip="Dashboard">
              <MdDashboard size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-date-calendar">
            <div data-tip="Date Calendar">
              <FaCalendarAlt size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-assignment-list">
            <div data-tip="Assignments">
              <MdAssignment size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-quiz-list">
            <div data-tip="Quizzes">
              <RiQuestionnaireFill size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-curriculum-list">
            <div data-tip="Curriculums">
              <FaFileAlt size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-doubt-list">
            <div data-tip="Doubts">
              <FaRegQuestionCircle size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-performance" data-tip="Performance">
            <div>
              <FaChartPie size={24} color="#dc3545" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-notes-list">
            <div data-tip="Lecture Notes">
              <FaStickyNote size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/" onClick={() => handleLogout()}>
            <FaPowerOff
              size={24}
              data-tip="Logout"
              color="rgba(179,179,179,1.0)"
            />
            <ReactTooltip />
          </Link>
        </div>
      </form>

      <form
        className="student-performance-mobile screen"
        name="form2"
        action="form2"
        method="post"
      >
        <div className="component-1-C61RwL">
          <img
            alt="image"
            src={pic_2}
            style={{ height: "90px", marginTop: "-8%" }}
          />
        </div>
        {/* This is the Hamburger icon  */}
        <SideDrawerStudent
          open={displaySideDrawer}
          closeSideDrawer={sideDrawerToggler}
        />
        <div className="component-6-C61RwL" onClick={sideDrawerToggler}>
          <div className="rectangle-48-5mfU7d"></div>
          <div className="rectangle-49-5mfU7d"></div>
          <div className="rectangle-50-5mfU7d"></div>
        </div>
        <div className="my-performance-C61RwL">
          <span className="span1-3EE9bF">
            <span style={{ fontWeight: "400" }}>My</span> Performance
          </span>
        </div>

        <div
          style={{
            position: "absolute",
            top: "185px",
            width: "100%",
            textAlign: "right",
            padding: "0px 10px",
          }}
        >
          <button className="showModalTogler" onClick={ModalTogglerHandler}>
            Show BarGraph
          </button>
        </div>

        <div className="group-69-C61RwL">
          <div className="group-57-8FQU39"></div>

          <div className="classes-8FQU39 montserrat-medium-blue-zodiac-18px">
            Classes
          </div>
          {isLoaded ? (
            <PieChart
              className="donut-chart-1-8FQU39"
              lineWidth={30}
              rounded
              label={({ dataEntry }) => dataEntry.value}
              labelStyle={(index) => ({
                fill: classesData[index].color,
                fontSize: "5px",
                fontFamily: "sans-serif",
              })}
              labelPosition={60}
              data={classesData}
            />
          ) : (
            "Loading..."
          )}
          <img className="ellipse-5-8FQU39" src={ellipse_5_4} />
          <img className="ellipse-6-8FQU39" src={ellipse_6_4} />
          <img className="ellipse-7-8FQU39" src={ellipse_7_1} />
          <div className="completed-8FQU39 montserrat-semi-bold-blue-zodiac-10px">
            Completed
          </div>
          <div className="booked-8FQU39 montserrat-semi-bold-blue-zodiac-10px">
            Booked
          </div>
          <div className="cancelled-8FQU39 montserrat-semi-bold-blue-zodiac-10px">
            Cancelled
          </div>
          <div className="attendance-75-8FQU39">
            Attendance:{" "}
            {isLoaded
              ? perfData.classes
                ? perfData.classes.attendance
                : "--"
              : "--"}
          </div>
        </div>

        <div className="group-76-C61RwL">
          <div className="group-57-Z1JSTw"></div>
          <div className="quizzes-Z1JSTw montserrat-medium-blue-zodiac-18px">
            Quizzes
          </div>
        </div>
        <div className="group-71-C61RwL">
          <div className="group-57-R3ERnh"></div>

          <div className="assignments-R3ERnh montserrat-medium-blue-zodiac-18px">
            Assignments
          </div>
          {isLoaded ? (
            <PieChart
              className="donut-chart-1-R3ERnh"
              lineWidth={30}
              rounded
              label={({ dataEntry }) => dataEntry.value}
              labelStyle={(index) => ({
                fill: assignData[index].color,
                fontSize: "5px",
                fontFamily: "sans-serif",
              })}
              labelPosition={60}
              data={assignData}
            />
          ) : (
            "Loading..."
          )}
          <img className="ellipse-5-R3ERnh" src={ellipse_5_5_2x} />
          <img className="ellipse-6-R3ERnh" src={ellipse_6_5_2x} />
          <div className="assigned-R3ERnh montserrat-semi-bold-blue-zodiac-10px">
            Assigned
          </div>
          <div className="submitted-R3ERnh montserrat-semi-bold-blue-zodiac-10px">
            Submitted
          </div>
          {isLoaded ? (
            <PieChart
              className="donut-chart-1-Z1JSTw"
              lineWidth={30}
              rounded
              label={({ dataEntry }) => dataEntry.value}
              labelStyle={(index) => ({
                fill: quizData[index].color,
                fontSize: "5px",
                fontFamily: "sans-serif",
              })}
              labelPosition={60}
              data={quizData}
            />
          ) : (
            "Loading..."
          )}
          <img className="ellipse-7-R3ERnh" src={ellipse_7_2_2x} />
          <img className="ellipse-8-R3ERnh" src={ellipse_8_2x} />
          <div className="assigned-Z7HFmV montserrat-semi-bold-blue-zodiac-10px">
            Assigned
          </div>
          <div className="submitted-Z7HFmV montserrat-semi-bold-blue-zodiac-10px">
            Submitted
          </div>
        </div>
        {/* <div className="group-81-C61RwL">
          <div className="rectangle-40-7olElM"></div>
          <div className="component-29-7olElM">
            <div className="assignment-24px-3-xwG3Ia">
              <img className="vector-Mbe0xv" src={vector_1_2x} />
              <img className="vector-utxsmi" src={vector_43_2x} />
            </div>
          </div>
          <div className="assignment-1-7olElM montserrat-semi-bold-blue-zodiac-8px">ASSIGNMENT 1</div>
          <div className="mathematics-dr-yashna-sharma-7olElM montserrat-medium-gray-5px">
            Mathematics (Dr. Yashna Sharma)
      </div>
          <div className="submitted-7olElM montserrat-semi-bold-niagara-5px">Submitted</div>

        </div>
        <div className="group-82-C61RwL">
          <div className="rectangle-40-ojBfAf"></div>
          <div className="component-29-ojBfAf">
            <div className="assignment-24px-3-f6UJq1">
              <img className="vector-p03cwU" src={vector_1_2x} />
              <img className="vector-JTwnfR" src={vector_43_2x} />
            </div>
          </div>
          <div className="assignment-1-ojBfAf montserrat-semi-bold-blue-zodiac-8px">ASSIGNMENT 1</div>
          <div className="mathematics-dr-yashna-sharma-ojBfAf montserrat-medium-gray-5px">
            Mathematics (Dr. Yashna Sharma)
      </div>
          <div className="submitted-ojBfAf montserrat-semi-bold-niagara-5px">Submitted</div>
        </div> */}
        {/* <div className="group-83-C61RwL">
          <div className="rectangle-40-jQ4qbJ"></div>
          <div className="component-29-jQ4qbJ">
            <div className="assignment-24px-3-VlxxiV">
              <img className="vector-qNxI6b" src={vector_1_2x} />
              <img className="vector-x03bhR" src={vector_43_2x} />
            </div>
          </div>
          <div className="assignment-1-jQ4qbJ montserrat-semi-bold-blue-zodiac-8px">ASSIGNMENT 1</div>
          <div className="mathematics-dr-yashna-sharma-jQ4qbJ montserrat-medium-gray-5px">
            Mathematics (Dr. Yashna Sharma)
      </div>
          <div className="submitted-jQ4qbJ montserrat-semi-bold-niagara-5px">Submitted</div>
        </div> */}
        {/* <div className="group-84-C61RwL">
          <div className="rectangle-40-ahGy4x"></div>
          <div className="component-29-ahGy4x">
            <div className="assignment-24px-3-hqjSEz">
              <img className="vector-6dDhYK" src={vector_1_2x} />
              <img className="vector-A3ehtq" src={vector_43_2x} />
            </div>
          </div>
          <div className="assignment-1-ahGy4x montserrat-semi-bold-blue-zodiac-8px">ASSIGNMENT 1</div>
          <div className="mathematics-dr-yashna-sharma-ahGy4x montserrat-medium-gray-5px">
            Mathematics (Dr. Yashna Sharma)
      </div>
          <div className="submitted-ahGy4x montserrat-semi-bold-niagara-5px">Submitted</div>
        </div> */}
        <div className="group-70-C61RwL">
          <div className="group-57-kber4s"></div>
          <div className="doubts-kber4s montserrat-medium-blue-zodiac-18px">
            Doubts
          </div>
          {isLoaded ? (
            <PieChart
              className="donut-chart-1-kber4s"
              lineWidth={30}
              rounded
              label={({ dataEntry }) => dataEntry.value}
              labelStyle={(index) => ({
                fill: doubtsData[index].color,
                fontSize: "5px",
                fontFamily: "sans-serif",
              })}
              labelPosition={60}
              data={doubtsData}
            />
          ) : (
            "Loading..."
          )}
          <img className="ellipse-5-kber4s" src={ellipse_5_4} />
          <img className="ellipse-6-kber4s" src={ellipse_6_4} />
          <div className="asked-kber4s montserrat-semi-bold-blue-zodiac-10px">
            Asked
          </div>
          <div className="responded-kber4s montserrat-semi-bold-blue-zodiac-10px">
            Responded
          </div>
        </div>
        <div className="group-72-C61RwL">
          <div className="rectangle-40-gqbUX1"></div>
          <div className="component-29-gqbUX1">
            <div className="assignment-24px-3-lxV7ix">
              <img className="vector-YDNU6K" src={vector_1_2x} />
              <img className="vector-hGGfYL" src={vector_51_2x} />
            </div>
          </div>
          <div className="assignment-1-gqbUX1 montserrat-semi-bold-blue-zodiac-10px">
            ASSIGNMENT 1
          </div>
          <div className="mathematics-dr-yashna-sharma-gqbUX1 montserrat-medium-gray-7px">
            Mathematics (Dr. Yashna Sharma)
          </div>
          <div className="submitted-gqbUX1 montserrat-semi-bold-niagara-8px">
            Submitted
          </div>
        </div>
        {/* <div className="group-77-C61RwL">
          <div className="rectangle-40-2osFvC"></div>
          <div className="component-29-2osFvC">
            <img className="ellipse-4-kNZYuA" src={ellipse_4_13} />
            <div className="assignment-24px-3-kNZYuA">
              <img className="vector-hMWl3h" src={vector_1_2x} />
              <img className="vector-o8aFZp" src={vector_53_2x} />
            </div>
          </div>
          <div className="quiz-1-2osFvC montserrat-semi-bold-blue-zodiac-8px">QUIZ 1</div>
          <div className="mathematics-dr-yashna-sharma-2osFvC montserrat-medium-gray-5px">
            Mathematics (Dr. Yashna Sharma)
      </div>
          <div className="submitted-2osFvC montserrat-semi-bold-niagara-5px">Submitted</div>
        </div>
        <div className="group-78-C61RwL">
          <div className="rectangle-40-hlxJRL"></div>
          <div className="component-29-hlxJRL">
            <div className="assignment-24px-3-jHyK8g">
              <img className="vector-Ojow4P" src={vector_1_2x} />
              <img className="vector-9Sr3Gx" src={vector_43_2x} />
            </div>
          </div>
          <div className="quiz-1-hlxJRL montserrat-semi-bold-blue-zodiac-8px">QUIZ 1</div>
          <div className="mathematics-dr-yashna-sharma-hlxJRL montserrat-medium-gray-5px">
            Mathematics (Dr. Yashna Sharma)
      </div>
          <div className="submitted-hlxJRL montserrat-semi-bold-niagara-5px">Submitted</div>
        </div>
        <div className="group-79-C61RwL">
          <div className="rectangle-40-sbe5OJ"></div>
          <div className="component-29-sbe5OJ">
            <div className="assignment-24px-3-iPxDjX">
              <img className="vector-MX8ksh" src={vector_1_2x} />
              <img className="vector-Z3Homl" src={vector_43_2x} />
            </div>
          </div>
          <div className="quiz-1-sbe5OJ montserrat-semi-bold-blue-zodiac-8px">QUIZ 1</div>
          <div className="mathematics-dr-yashna-sharma-sbe5OJ montserrat-medium-gray-5px">
            Mathematics (Dr. Yashna Sharma)
      </div>
          <div className="submitted-sbe5OJ montserrat-semi-bold-niagara-5px">Submitted</div>
        </div>
        <div className="group-80-C61RwL">
          <div className="rectangle-40-CX7m3G"></div>
          <div className="component-29-CX7m3G">
            <div className="assignment-24px-3-Ll6NDG">
              <img className="vector-xjYbuu" src={vector_1_2x} />
              <img className="vector-oLJRUl" src={vector_43_2x} />
            </div>
          </div>
          <div className="quiz-1-CX7m3G montserrat-semi-bold-blue-zodiac-8px">QUIZ 1</div>
          <div className="mathematics-dr-yashna-sharma-CX7m3G montserrat-medium-gray-5px">
            Mathematics (Dr. Yashna Sharma)
      </div>
          <div className="submitted-CX7m3G montserrat-semi-bold-niagara-5px">Submitted</div>
        </div> */}
        {/* <div className="component-30-C61RwL">
          <div className="choose-subject-i63126022371261-02ks1v">Filter by subject</div>
          <div className="expandmore-24px-1-02ks1v">
            <img className="vector-wGeoOk" src={vector_1_2x} />
            <img className="vector-5FRq6D" src={vector_41_2x} />
          </div>
        </div>
        <a href="javascript:SubmitForm('form2')"> <div className="rectangle-41-C61RwL"></div></a>
        <div className="apply-filters-C61RwL">Apply filters</div> */}
      </form>

      <Popup />
      <BackDrop open={openModal} clicked={ModalTogglerHandler} />
      <div className={atClass.join(" ")}>
        <div className="Modal_inner_scroll">
          <div className="Div_to_have_chart">
            <div
              className="montserrat-medium-blue-zodiac-18px"
              style={{ color: "#dc3545" }}
            >
              Classes
            </div>

            <div
              style={{
                width: "auto",
                height: "auto",
                margin: "10px",
              }}
            >
              {isLoaded ? (
                <BarChart width={320} height={240} data={classesData}>
                  <CartesianGrid strokeDasharray="3 3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="value" fill="#6F58FF" />
                </BarChart>
              ) : (
                "Loading..."
              )}
            </div>
          </div>

          <div className="Div_to_have_chart">
            <div
              className="montserrat-medium-blue-zodiac-18px"
              style={{ color: "#dc3545" }}
            >
              Doubts
            </div>

            <div
              style={{
                width: "auto",
                height: "auto",
                margin: "10px",
              }}
            >
              {isLoaded ? (
                <BarChart width={320} height={240} data={doubtsData}>
                  <CartesianGrid strokeDasharray="2 2" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="value" fill="#6F58FF" />
                </BarChart>
              ) : (
                "Loading..."
              )}
            </div>
          </div>

          <div className="Div_to_have_chart">
            <div
              className="montserrat-medium-blue-zodiac-18px"
              style={{ color: "#dc3545" }}
            >
              Assignment
            </div>
            <div
              style={{
                width: "auto",
                height: "auto",
                margin: "10px",
              }}
            >
              {isLoaded ? (
                <BarChart width={320} height={240} data={assignData}>
                  <CartesianGrid strokeDasharray="2 2" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="value" fill="#6F58FF" />
                </BarChart>
              ) : (
                "Loading..."
              )}
            </div>
          </div>

          <div className="Div_to_have_chart">
            <div
              className="montserrat-medium-blue-zodiac-18px"
              style={{ color: "#dc3545" }}
            >
              Quiz
            </div>
            <div
              style={{
                width: "auto",
                height: "auto",
                margin: "10px",
              }}
            >
              {isLoaded ? (
                <BarChart width={320} height={240} data={quizData}>
                  <CartesianGrid strokeDasharray="2 2" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="value" fill="#6F58FF" />
                </BarChart>
              ) : (
                "Loading..."
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Studentperformance;
