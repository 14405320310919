import React, { useState, useEffect, useLayoutEffect } from "react";
import "./classroom.css";
import SchoolIcon from "@material-ui/icons/School";
import { NavLink, useParams } from "react-router-dom";
import pic_2 from "../../student/dashboard/img/pic_2.png";

import ReactTooltip from "react-tooltip";
import { MdNotificationsNone } from "react-icons/md";
import ellipse_2_2x from "./img/ellipse-2@2x.svg";
import vector_1_2x from "./img/vector-1@2x.svg";
import Popup from "../popup/popup";
import { useSelector, useDispatch } from "react-redux";
import SideDrawer from "../../../UI/sideDrawer/sideDrawer";

import { removeToken } from "../../../utils";
import { deleteTeacher } from "../../../redux/actions/teacher";

import { teacherClassroomStudent } from "../../../crud/teacher.crud";
import CalenderStatus from "../dashboard/CalenderComponent/calanderComponent";
// import moment from "moment";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";

import DescriptionIcon from "@material-ui/icons/Description";
import HelpIcon from "@material-ui/icons/Help";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import NotesIcon from "@material-ui/icons/Notes";

function Teacherpreviousclasses(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { classroomID } = useParams();
  const [classStudent, setClassStudent] = useState([]);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteTeacher(reduxUser.teacher_id));
    removeToken();
  };

  useEffect(() => {
    console.log(classroomID);
    teacherClassroomStudent(classroomID)
      .then((res) => {
        setClassStudent(res);
        console.log(classStudent);
      })
      .catch((err) => console.log(err));
  }, []);

  // const [classes, setClasses] = useState([]);
  // const [upClass, setUpClass] = useState({});

  // useEffect(() => {
  //   teacherPreviousClasses("TS_bibasob")
  //     .then((res) => {
  //       if (res.hasOwnProperty("message")) {
  //         setClasses(false);
  //       } else setClasses(res);
  //     })
  //     .catch((err) => console.log(err));

  //   upcomingClass("TS_bibasob")
  //     .then((res) => {
  //       if (res.hasOwnProperty("message")) {
  //         setUpClass(false);
  //       } else setUpClass(res);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  const user = useSelector((state) => state.teacherDetails.teacher);

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="teacherpreviousclasses"
      />
      <div className="container-center-horizontal">
        <div className="teacher-previousclasses-1 screen">
          <div className="sider-teacher-general-div">
            <div className="sider-teacher-in-general-80">
              <div style={{ marginTop: "120px" }} />
              <NavLink to="/teacher-dashboard" exact>
                <DashboardIcon
                  data-tip="Dashboard"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-date-calendar" exact>
                <EventIcon data-tip="Scheduler" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-previous-classes">
                <ScheduleIcon
                  data-tip="Previous Class"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-students">
                <SchoolIcon
                  data-tip="Enrolled Student"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-curriculum-list">
                <DescriptionIcon
                  data-tip="Curriculum"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-doubt-list">
                <HelpIcon data-tip="Doubts" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-analytics">
                <AssessmentIcon
                  data-tip="Analytics"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-assignments">
                <AssignmentIcon
                  data-tip="Assignments"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-quizzes">
                <LibraryBooksIcon
                  data-tip="Quizzes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-lecture-notes">
                <NotesIcon
                  data-tip="Lecture Notes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              {/* <NavLink to="/teacher-payments"><AccountBalanceIcon data-tip="Payment" className="icon-in-hc-color" /><ReactTooltip /></NavLink> */}
              <div style={{ marginTop: "10px" }} />
              <NavLink to="/" onClick={() => handleLogout()}>
                {" "}
                <PowerSettingsNewIcon
                  data-tip="Logout"
                  className="icon-in-hc-color"
                />{" "}
              </NavLink>
              <div style={{ marginTop: "1px" }} />
            </div>
          </div>
          <div className="flex-col-C61RwL">
            <div className="flex-row-0xy0vn">
              <div className="component-1-GyJUNH">
                <img
                  alt="image"
                  src={pic_2}
                  style={{ height: "115px", marginTop: "-8%" }}
                />
              </div>

              {/* <div className="group-125-GyJUNH">
                <div className="flex-col-OfAOxu">
                  <div className="flex-row-xqwxU1">
                    <div className="flex-col-cSfBx4">
                      <div className="flex-row-Wa7qGO">
                        <div className="total-classes-hxQHcv montserrat-semi-bold-blue-zodiac-18px">
                          Total Classes -
                        </div>
                        <div className="x43-hxQHcv montserrat-medium-blue-zodiac-18px">
                          43
                        </div>
                      </div>
                      <div className="average-rating-Wa7qGO montserrat-semi-bold-blue-zodiac-18px">
                        Average Rating -
                      </div>
                    </div>
                    <div className="x43-5-cSfBx4">
                      <span className="span0-e0hoH9">4.3</span>
                      <span className="span1-e0hoH9"> / 5</span>
                    </div>
                  </div>
                  <div className="flex-row-qItZ2O">
                    <div className="attendance-302Mxf montserrat-semi-bold-blue-zodiac-18px">
                      Attendance -
                    </div>
                    <div className="x97-302Mxf">97%</div>
                  </div>
                  <div className="number-of-classes-per-day-xqwxU1 montserrat-semi-bold-blue-zodiac-18px">
                    Number of Classes Per Day -
                  </div>
                </div>
                <div className="x7-OfAOxu montserrat-medium-blue-zodiac-18px">
                  7
                </div>
              </div> */}

              <div className="group-115-GyJUNH">
                <div className="group-22-3boKt2">
                  <div className="flex-col-R6Ddt1">
                    <div className="biba-sobti-2jrdHj">
                      {user && user.first_name + " " + user && user.last_name}
                    </div>
                    <div className="bibasobtigmailcom-2jrdHj">
                      {user && user.emailID}
                    </div>
                  </div>
                  <img className="vector-R6Ddt1" src={vector_1_2x} />
                </div>
              </div>
            </div>
            <div className="flex-row-oV5UrE">
              <div className="flex-col-QkTteQ">
                {/* <div className="flex-row-Fjgz1C">


                  
                  
                  
                  <div className="insertinvitation-24px-1-A6SpMB">
                    <img className="vector-bLtNxC" src={vector_2xp} />
                  </div>
                </div> */}
                <div className="overlap-group-Fjgz1C">
                  <div className="rectangle-31-Sgr7kw-02-22">
                    <div className="div-rectangle-31-Sgr7kw">
                      Classroom Students
                    </div>
                    {/* <div className="rectangle-31-Sgr7kw-in-content">
                      <div className="rectangle-tingle-12">
                        <SchoolIcon className="cast-for-edu-121-129" />
                        <p className="rectangle-tingle-12-name">
                          Rajendra Swami
                        </p>
                      </div>
                    </div> */}
                    <div className="rectangle-31-Sgr7kw-in-content">
                      {classStudent && classStudent.length > 0 ? (
                        classStudent.map((item, index) => {
                          return (
                            <div className="rectangle-tingle-12s8-5">
                              <SchoolIcon className="cast-for-edu-121-129" />
                              <p className="rectangle-tingle-12-name">{item}</p>
                            </div>
                          );
                        })
                      ) : (
                        <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                          No students found in this classroom currently.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-114-QkTteQ">
                <div className="group-113-eLYKxH">
                  <CalenderStatus />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-center-horizontal">
        <div className="teacherpreviousclasses screen">
          <div className="flex-row-C61RwL">
            <div className="component-1-dXBp89">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "70px", marginTop: "-8%" }}
              />
            </div>

            {/* this is the Navbar */}

            <SideDrawer
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />
            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>

          {/* <div className="group-125-C61RwL">
            <div className="group-124-kWlObw">
              <div className="group-41-e1mbBS">
                <div className="group-40-7vmrSC">
                  <div className="group-39-agxh6K">
                    <div className="group-38-fXlfDP"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="total-classes-kWlObw montserrat-semi-bold-blue-zodiac-20px">
              Total Classes -
            </div>
            <div className="x43-kWlObw">43</div>
            <div className="average-rating-kWlObw montserrat-semi-bold-blue-zodiac-20px">
              Average Rating -
            </div>
            <div className="x43-5-kWlObw">
              <span className="span0-KlKwew">4.3</span>
              <span className="span1-KlKwew"> / 5</span>
            </div>
            <div className="attendance-kWlObw montserrat-semi-bold-blue-zodiac-20px">
              Attendance -
            </div>
            <div className="x97-kWlObw">97%</div>
            <div className="number-of-classes-per-day-kWlObw montserrat-semi-bold-blue-zodiac-20px">
              Number of Classes Per Day -
            </div>
            <div className="x7-kWlObw">7</div>
          </div>
           */}

          {/* <div className="group-83-C61RwL">
            <div className="group-15-jQ4qbJ">
              <div className="rectangle-30-V5d4cE"></div>
              <div className="mathematcs-section-g-V5d4cE">
                <span className="span0-Xx53BE">
                  {upClass && upClass.subject_id} -{" "}
                  <span style={{ color: "#F98131" }}>
                    {upClass && upClass.class_type}
                  </span>
                </span>
                
              </div>
              <div className="upcoming-class-V5d4cE montserrat-medium-blue-zodiac-18px">
                Upcoming Class
              </div>
            </div>
            <div className="friday-jQ4qbJ">
              {upClass && moment(upClass.datetime).format("dddd").toUpperCase()}
            </div>
            <div className="x15-january-jQ4qbJ">
              {upClass && moment(upClass.datetime).format("LL").toUpperCase()}
            </div>
            <div className="x0800-am-0900-am-jQ4qbJ">
              {upClass && moment(upClass.datetime).format("HH:mm")}
            </div>
            <div className="rectangle-51-jQ4qbJ border-1px-blue-zodiac"></div>
            <div className="join-class-jQ4qbJ" onClick={() => startClass()}>
              Join Class
            </div>
          </div> */}

          {/* <div className="overlap-group-C61RwL">
            <div className="rectangle-31-4eduM0">
              {classes.length > 0 ? (
                classes.map((item, index) => {
                  return (
                    <div key={item.id} className="rectangle-36-4eduM0">
                      <div className="payga4wk-z-bshl-Sgr7kw montserrat-semi-bold-blue-zodiac-10px">
                        {index + 1}
                      </div>
                      <div className="x499900-Sgr7kw montserrat-semi-bold-blue-zodiac-10px">
                        {item.class_type}
                      </div>
                      <div className="x07-dec-2020-Sgr7kw montserrat-semi-bold-blue-zodiac-10px">
                        {moment(item.datetime).format("DD-MM-YYYY")}
                      </div>
                      <div className="x014009-pm-Sgr7kw montserrat-semi-bold-blue-zodiac-10px">
                        {moment(item.datetime).format("HH:mm:ss")}
                      </div>
                      <div className="andb586-Sgr7kw montserrat-semi-bold-blue-zodiac-10px">
                        {item.status}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                  No previous classes found.
                </div>
              )}
            </div>
            <div className="previousclass-id-4eduM0 montserrat-semi-bold-gray-10px">
              S.No.
            </div>
            <div className="amount-4eduM0 montserrat-semi-bold-gray-10px">
              Class Type
            </div>
            <div className="transferred-to-4eduM0 montserrat-semi-bold-gray-10px">
              Status
            </div>
            <div className="date-4eduM0 montserrat-semi-bold-gray-10px">Date</div>
            <div className="time-4eduM0 montserrat-semi-bold-gray-10px">Time</div>
            <div className="recent-previousclasses-4eduM0 montserrat-medium-blue-zodiac-18px">
              Previous Classes
            </div>
          </div>
           */}

          <div className="rectangle-12-cE321">
            <div className="div-rectangle-31-Sgr7kw">Classroom Students</div>
            {/* <div className="rectangle-31-Sgr7kw-in-content">
              <div className="rectangle-tingle-12">
                <SchoolIcon className="cast-for-edu-121-129" />
                <p className="rectangle-tingle-12-name">Rajendra Swami</p>
              </div>
            </div> */}
            <div className="rectangle-31-Sgr7kw-in-content">
              {classStudent && classStudent.length > 0 ? (
                classStudent.map((item, index) => {
                  return (
                    <div className="rectangle-tingle-12s8-5">
                      <SchoolIcon className="cast-for-edu-121-129" />
                      <p className="rectangle-tingle-12-name">{item}</p>
                    </div>
                  );
                })
              ) : (
                <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                  No students found in this classroom currently.
                </div>
              )}
            </div>
          </div>

          <div className="group-135-C61RwL-02-20">
            <CalenderStatus />
          </div>
        </div>
      </div>
      <Popup />
    </>
  );
}

export default Teacherpreviousclasses;
