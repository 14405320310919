import React from "react";
import "./dropdown_grade.css";
import { Dropdown } from "semantic-ui-react";

const selectStyles = {
  width: "100%",
  borderRadius: "5px",
  border: "2px solid rgba(217, 83, 79, 0.4)",
  fontFamily: "'Montserrat', Helvetica",
};

const Dropdown_grade = (props) => {
  console.log(props);
  const gradeOptions = [
    // {
    //   key: "Nur",
    //   text: "Nursery",
    //   value: "Nursery",
    // },
    // {
    //   key: "KG",
    //   text: "Kinder Garden",
    //   value: "KG",
    // },
    // {
    //   key: "Interest",
    //   text: "Interest",
    //   value: "Interest",
    // },
    {
      key: "1",
      text: "1",
      value: "1",
    },
    {
      key: "2",
      text: "2",
      value: "2",
    },
    {
      key: "3",
      text: "3",
      value: "3",
    },
    {
      key: "4",
      text: "4",
      value: "4",
    },
    {
      key: "5",
      text: "5",
      value: "5",
    },
    {
      key: "6",
      text: "6",
      value: "6",
    },
    {
      key: "7",
      text: "7",
      value: "7",
    },
    {
      key: "8",
      text: "8",
      value: "8",
    },
    {
      key: "9",
      text: "9",
      value: "9",
    },
    {
      key: "10",
      text: "10",
      value: "10",
    },
    {
      key: "11",
      text: "11",
      value: "11",
    },
    {
      key: "12",
      text: "12",
      value: "12",
    },
  ];

  return (
    <div>
      <div className="Dropdown-lec-notes-3">
        <Dropdown
          className="grade_dropdown"
          style={selectStyles}
          placeholder="Choose Grade"
          fluid
          search
          selection
          options={gradeOptions}
          onChange={(e, { value }) => props.handleGradeSelect(value)}
        />
      </div>
    </div>
  );
};

export default Dropdown_grade;
