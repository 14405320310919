import React, { Fragment } from 'react';
import './sideDrawer.css';

import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { deleteRm } from '../../redux/actions/rm';

import DashboardIcon from '@material-ui/icons/Dashboard';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SchoolIcon from '@material-ui/icons/School';
import HelpIcon from '@material-ui/icons/Help';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { removeToken } from "../../utils";

import Backdrop from '../Backdrop/Backdrop';

const SideDrawerRm = (props) => {

    let attclasses = ["sideDrawer", "Close_SideD"];

    if (props.open) {
        attclasses = ["sideDrawer", "Open_SideD"];
    }

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.rmDetails.rm);
  const handleLogout = () => {
    dispatch(deleteRm(reduxUser.rm_id));
    removeToken();
  };    

    return (
      <Fragment>
        <Backdrop open={props.open} clicked={props.closeSideDrawer} />
        <div className={attclasses.join(" ")}>
          <div
            style={{
              width: "70%",
              height: "70px",
              border: "1px solid #F4F4F4",
              marginLeft: "30px",
            }}
          ></div>

          <NavLink to="/rm-dashboard" exact>
            <div className="sider-ele-mob">
              <DashboardIcon />
              <span className="sider-ele-mob-tag">Dashboard</span>
            </div>
          </NavLink>
          <NavLink to="/rm-issue-list" exact>
            <div className="sider-ele-mob">
              <ScheduleIcon />
              <span className="sider-ele-mob-tag">Previous Issues</span>
            </div>
          </NavLink>
          <NavLink to="/rm-list" exact>
            <div className="sider-ele-mob">
              <SchoolIcon />
              <span className="sider-ele-mob-tag">Students/Teachers</span>
            </div>
          </NavLink>
          <NavLink to="/rm-ticket-list" exact>
            <div className="sider-ele-mob">
              <HelpIcon />
              <span className="sider-ele-mob-tag">Tickets</span>
            </div>
          </NavLink>
          <NavLink to="/rm-statistics" exact>
            <div className="sider-ele-mob">
              <AssessmentIcon />
              <span className="sider-ele-mob-tag">Analytics</span>
            </div>
          </NavLink>
          <NavLink to="/" exact onClick={() => handleLogout()}>
            <div className="sider-ele-mob">
              <PowerSettingsNewIcon />
              <span className="sider-ele-mob-tag">Logout</span>
            </div>
          </NavLink>
        </div>
      </Fragment>
    );
}

export default SideDrawerRm;