import React, { useState, useEffect, useLayoutEffect } from "react";
import { Select, Button } from "semantic-ui-react";
import "./style.css";
import { Link, useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import {
  FaRegQuestionCircle,
  FaCalendarAlt,
  FaFileAlt,
  FaChartPie,
  FaStickyNote,
  FaPowerOff,
} from "react-icons/fa";
import { MdAssignment, MdDashboard } from "react-icons/md";
// import { BiSupport } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Popup from "../../teacher/popup/popup";
// images
// import line_5_2x from "./img/line-5@2x.png";
import vector_141_2x from "./img/vector-141@2x.png";
// import orange_ellipse from "./img/ellipse-2-1@2x.png";
// import orange_ellipse_3 from "./img/ellipse-3-1@2x.png";
// import ellipse_2_2x from "./img/ellipse-2@2x.png";
// import ellipse_3_2x from "./img/ellipse-3@2x.png";
import rectangle_7_1x from "./img/rectangle-7@1x.png";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteStudent } from "../../../redux/actions/student";
import pic_2 from "../dashboard/img/pic_2.png";
import {
  getStudentTeachers,
  getStudentTeachersDateCalendar,
  dashboardDoubts,
  pendingAssignments,
} from "../../../crud/student.crud";
import SideDrawerStudent from "../../../UI/sideDrawer/sideDrawerStudent";

function Studentdatecalendar(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const user = useSelector((state) => state.studentDetails.student);
  const history = useHistory();
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteStudent(reduxUser.enrolled_student_id));
    removeToken();
  };

  const [doubts, setDoubts] = useState([]);
  const [penAssigns, setPenAssigns] = useState([]);
  const [teacherOptions, setTeacherOptions] = useState([]);
  const [selDate, setSelDate] = useState("");
  const [selTeacher, setSelTeacher] = useState("");

  useEffect(() => {
    dashboardDoubts(user.enrolled_student_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setDoubts(false);
        } else setDoubts(res);
      })
      .catch((err) => console.log(err));

    pendingAssignments(user.enrolled_student_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setPenAssigns(false);
        } else setPenAssigns(res);
      })
      .catch((err) => console.log(err));

    getStudentTeachersDateCalendar(user.enrolled_student_id)
      .then((res) => {
        setTeacherOptions(res);
      })
      .catch((err) => console.log(err));
  }, []);

  const onClickDay = (value, event) => {
    setSelDate(moment(value).format("YYYY-MM-DD"));
  };

  const handleSubmit = () => {
    history.push(`/student-time-calendar/${selDate}/${selTeacher}`);
  };

  return (
    <>
      <div className="student-calendar-dates-mobile screen">
        <div className="component-1-C61RwL">
          {/* <img alt="image" className="line-5-9p2GBv" src={line_5_2x} />
          <img alt="image" className="line-6-9p2GBv" src={line_6_2x} /> */}
          <img
            alt="image"
            src={pic_2}
            style={{ height: "90px", marginLeft: "14%" }}
          />
        </div>

        {/* This is the hamburger icon */}

        <SideDrawerStudent
          open={displaySideDrawer}
          closeSideDrawer={sideDrawerToggler}
        />

        <div className="component-6-C61RwL" onClick={sideDrawerToggler}>
          <div className="rectangle-48-5mfU7d"></div>
          <div className="rectangle-49-5mfU7d"></div>
          <div className="rectangle-50-5mfU7d"></div>
        </div>
        <div className="group-85-C61RwL">
          <div className="rectangle-44-rFX9Nh">
            {doubts.length > 0 ? (
              doubts.slice(0, 4).map((doubt) => {
                return (
                  <div className="doubt-row">
                    <FaRegQuestionCircle size={30} />
                    <Link>
                      <div className="montserrat-semi-bold-blue-zodiac-18px">
                        {doubt.doubt_text.length > 31
                          ? doubt.doubt_text.substring(0, 27) + "..."
                          : doubt.doubt_text}
                      </div>
                    </Link>
                    {/* <div className="view-forum-C61RwL montserrat-semi-bold-gray-16px">View</div> */}
                  </div>
                );
              })
            ) : (
              <div></div>
            )}
          </div>
          <div className="doubt-forum-rFX9Nh">Doubt Forum</div>
          <Link to="/student-doubt-list">
            <div className="view-all-rFX9Nh montserrat-semi-bold-gray-12px">
              View all
            </div>
          </Link>

          {/* <div className="rectangle-44-rFX9Nh"></div>
          <div className="doubt-forum-rFX9Nh">Doubt Forum</div>
          <div className="view-all-rFX9Nh montserrat-semi-bold-gray-12px">View all</div>
          <div className="what-is-the-si-unit-of-current-rFX9Nh montserrat-medium-blue-zodiac-16px">
            What is the SI unit of Current?
          </div>
          <div className="how-to-solve-this-rFX9Nh montserrat-medium-blue-zodiac-16px">How to solve this?</div>
          <div className="what-is-the-sol-f-this-question-rFX9Nh montserrat-medium-blue-zodiac-16px">
            What is the solution of this question?
          </div>
          <div className="view-forum-rFX9Nh">View Forum</div>
          <div className="view-forum-CNpLuU">View Forum</div>
          <div className="view-forum-CN8bZ4">View Forum</div>
          <div className="join-7-rFX9Nh">
            <div className="group-wQSXdG">
              <FaRegQuestionCircle size={30} />
            </div>
          </div>
          <div className="join-8-rFX9Nh">
            <div className="group-TtUkiV">
              <FaRegQuestionCircle size={30} />
            </div>
          </div>
          <div className="join-9-rFX9Nh">
            <div className="group-3eHi1a">
              <FaRegQuestionCircle size={30} />
            </div>
          </div> */}
        </div>
        <div className="group-86-C61RwL">
          <div className="pending-assignments-cPi90N">Pending Assignments</div>
          <div className="view-all-cPi90N montserrat-semi-bold-gray-12px">
            View all
          </div>

          {penAssigns.length > 0 ? (
            <div className="rectangle-44-cPi90N">
              <MdAssignment size={30} color="rgba(19,37,71,1.0)" />
              <div className="assignment-rowCol">
                <div className="montserrat-semi-bold-blue-zodiac-18px">
                  {penAssigns[0].assignment_name}
                </div>
                <div className="montserrat-semi-bold-gray-12px">
                  Marks: {penAssigns[0].total_marks}
                </div>
              </div>
            </div>
          ) : (
            <div className="rectangle-44-cPi90N">
              <div className="montserrat-semi-bold-blue-zodiac-18px">
                You have no pending assignment.
              </div>
            </div>
          )}

          {penAssigns.length > 1 ? (
            <div className="rectangle-52-cPi90N">
              <MdAssignment size={30} color="rgba(19,37,71,1.0)" />
              <div className="assignment-rowCol">
                <div className="montserrat-semi-bold-blue-zodiac-18px">
                  {penAssigns[1].assignment_name}
                </div>
                <div className="montserrat-semi-bold-gray-12px">
                  Marks: {penAssigns[1].total_marks}
                </div>
              </div>
            </div>
          ) : (
            <div />
          )}
        </div>
        <div className="component-4-C61RwL">
          <div className="sections-teachi-g-i613101253102-p4wM3n">
            Total
            <br />
            Classes
          </div>
          <div className="x4-i613101253103-p4wM3n">1</div>
        </div>
        <div className="component-5-C61RwL">
          <div className="sections-teachi-g-i613101353102-9wWKr1">
            Completed <br />
            Classes
          </div>
          <div className="x4-i613101353103-9wWKr1">0</div>
        </div>
        <div className="group-87-C61RwL">
          <div className="component-7-D1n0rW">
            <div className="x4-i613101553103-j2Gv3j"></div>
          </div>
          <div className="teacher-name-D1n0rW montserrat-medium-blue-zodiac-20px">
            Teacher Name
          </div>
          <div className="course-D1n0rW montserrat-medium-blue-zodiac-20px">
            Course
          </div>
          <div className="subject-D1n0rW montserrat-medium-blue-zodiac-20px">
            Subject
          </div>
          <div className="grade-D1n0rW montserrat-medium-blue-zodiac-20px">
            Grade
          </div>
        </div>
        <div className="choose-date-for-booking-C61RwL montserrat-medium-blue-zodiac-20px">
          Choose date for booking
        </div>
        <div className="feb-2021-C61RwL montserrat-semi-bold-blue-zodiac-18px">
          <span className="span0-KoN7KL">Feb </span>
          <span className="span1-KoN7KL">2021</span>
        </div>
        {/* <div className="next-month-C61RwL">Next month</div> */}
        <div className="x12-C61RwL montserrat-medium-blue-zodiac-16px">12</div>
        <div className="group-88-C61RwL">
          <Calendar onClickDay={onClickDay} />
          <Select
            style={{ marginTop: "8px" }}
            placeholder="Select teacher"
            options={teacherOptions.map((teacher) => {
              return {
                key: teacher.teacher_id,
                value: teacher.teacher_id,
                text: teacher.first_name + " " + teacher.last_name,
              };
            })}
            onChange={(e, { value }) => setSelTeacher(value)}
          />
          <Button
            color="green"
            onClick={() => handleSubmit()}
            style={{ marginTop: "8px", marginLeft: "8px" }}
          >
            Submit
          </Button>
        </div>
      </div>
      <div className="student-date-calendar screen">
        <div className="student-re-flex">
          <div className="component-1-C61RwLz-3">
            <img
              alt="image"
              src={pic_2}
              style={{ height: "115px", marginTop: "-8%" }}
            />
          </div>

          <div className="group-22-GyJUNH-student-dash">
            <div className="flex-col-vgli2x-student-dash">
              <div className="biba-sobti-h8V8ZR-student-dash">
                {user.first_name + " " + user.last_name}
              </div>
              <div className="bibasobtigmailcom-h8V8ZR-student-dash">
                {user && user.email}
              </div>
            </div>
            <img className="vector-vgli2x-student-dash" src={vector_141_2x} />
          </div>
        </div>

        <img alt="image" className="rectangle-7-C61RwL" src={rectangle_7_1x} />

        <div className="view-all-C61RwL montserrat-semi-bold-gray-12px">
          View all
        </div>
        <div className="x14-C61RwL">14</div>
        <div className="pending-assignments-C61RwL montserrat-semi-bold-blue-zodiac-18px">
          Pending Assignments
        </div>

        {penAssigns.length > 0 ? (
          <div className="rectangle-32-C61RwL">
            <MdAssignment size={30} color="rgba(19,37,71,1.0)" />
            <div className="assignment-rowCol">
              <div className="montserrat-semi-bold-blue-zodiac-18px">
                {penAssigns[0].assignment_name}
              </div>
              <div className="montserrat-semi-bold-gray-12px">
                Marks: {penAssigns[0].total_marks}
              </div>
            </div>
          </div>
        ) : (
          <div className="rectangle-32-C61RwL">
            <div className="montserrat-semi-bold-blue-zodiac-18px">
              You have no pending assignment.
            </div>
          </div>
        )}

        {penAssigns.length > 1 ? (
          <div className="rectangle-33-C61RwL">
            <MdAssignment size={30} color="rgba(19,37,71,1.0)" />
            <div className="assignment-rowCol">
              <div className="montserrat-semi-bold-blue-zodiac-18px">
                {penAssigns[1].assignment_name}
              </div>
              <div className="montserrat-semi-bold-gray-12px">
                Marks: {penAssigns[1].total_marks}
              </div>
            </div>
          </div>
        ) : (
          <div />
        )}

        <div className="rectangle-42-C61RwL-003">
          <div className="you-have-no-upcoming-tests-C61RwL">
            You have no upcoming tests.
          </div>
        </div>

        <div className="rectangle-43-C61RwL">
          <div className="doubt-forum-C61RwL montserrat-semi-bold-blue-zodiac-18px">
            Doubt Forum
          </div>
          <Link to="/student-doubt-list">
            <div className="view-all-VMr6Om montserrat-semi-bold-gray-12px">
              View all
            </div>
          </Link>

          {doubts.length > 0 ? (
            doubts.slice(0, 4).map((doubt) => {
              return (
                <div className="doubt-row">
                  <FaRegQuestionCircle size={30} />
                  <Link>
                    <div
                      className="montserrat-semi-bold-blue-zodiac-18px"
                      style={{ color: "rgba(126,126,126,1.0)" }}
                    >
                      {doubt.doubt_text.length > 31
                        ? doubt.doubt_text.substring(0, 27) + "..."
                        : doubt.doubt_text}
                    </div>
                  </Link>
                  {/* <div className="view-forum-C61RwL montserrat-semi-bold-gray-16px">View</div> */}
                </div>
              );
            })
          ) : (
            <div></div>
          )}
        </div>

        {/* <div className="rectangle-43-C61RwL"></div>
        <div className="doubt-forum-C61RwL montserrat-semi-bold-blue-zodiac-18px">Doubt Forum</div>
        <div className="view-all-VMr6Om montserrat-semi-bold-gray-12px">View all</div>
        <div className="what-is-the-si-unit-of-current-C61RwL montserrat-semi-bold-blue-zodiac-18px">
          What is the SI unit of Current?
        </div>
        <div className="how-to-solve-this-C61RwL montserrat-semi-bold-blue-zodiac-18px">How to solve this?</div>
        <div className="what-is-the-sol-f-this-question-C61RwL montserrat-semi-bold-blue-zodiac-18px">
          What is the solution of this question?
        </div>
        <div className="view-forum-C61RwL montserrat-semi-bold-gray-16px">View Forum</div>
        <div className="view-forum-VMr6Om montserrat-semi-bold-gray-16px">View Forum</div>
        <div className="view-forum-mzXdH9 montserrat-semi-bold-gray-16px">View Forum</div>
        <div className="join-4-C61RwL">
          <div className="group-Ie5dUU">
            <FaRegQuestionCircle size={30} />
          </div>
        </div>
        <div className="join-5-C61RwL">
          <div className="group-Zmqz7u">
            <FaRegQuestionCircle size={30} />
          </div>
        </div>
        <div className="join-6-C61RwL">
          <div className="group-KsMB6y">
            <FaRegQuestionCircle size={30} />
          </div>
        </div>
        <div className="please-check-my-approach-C61RwL montserrat-semi-bold-blue-zodiac-18px">Please check my approach.</div>
        <div className="view-forum-QxM5SU montserrat-semi-bold-gray-16px">View Forum</div>
        <div className="join-7-C61RwL">
          <div className="group-mnqxdV">
            <FaRegQuestionCircle size={30} />
          </div>
        </div> */}
        <div className="component-4-C61RwL-rrre">
          <div className="sections-teaching-i61370653102-p4wM3n">
            Total
            <br />
            Classes
          </div>
          <div className="x4-i61370653103-p4wM3n">1</div>
        </div>
        {/* <div className="component-6-C61RwL"><div className="x4-i61370753103-5mfU7d"></div></div> */}
        <div className="component-5-C61RwL-002">
          <div className="sections-teaching-i61370853102-9wWKr1">
            Completed <br />
            Classes
          </div>
          <div className="x4-i61370853103-9wWKr1">0</div>
        </div>
        <div className="group-23-C61RwL-0ds">
          <Calendar onClickDay={onClickDay} />
          <Select
            placeholder="Select teacher"
            options={teacherOptions.map((teacher) => {
              return {
                key: teacher.teacher_id,
                value: teacher.teacher_id,
                text: teacher.first_name + " " + teacher.last_name,
              };
            })}
            onChange={(e, { value }) => setSelTeacher(value)}
            style={{ marginTop: "15px" }}
          />
          <Button
            color="green"
            onClick={() => handleSubmit()}
            style={{ marginTop: "15px", marginLeft: "12px" }}
          >
            Submit
          </Button>
        </div>
        {/* <div className="next-month-C61RwL">Next month</div> */}

        <div className="choose-date-for-booking-C61RwL">
          Choose Date For Booking
        </div>
        <div className="rectangle-24-C61RwL-stu-s1">
          <Link to="/student-dashboard">
            <div data-tip="Dashboard">
              <MdDashboard size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-date-calendar">
            <div data-tip="Date Calendar">
              <FaCalendarAlt size={24} color="#dc3545" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-assignment-list">
            <div data-tip="Assignments">
              <MdAssignment size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-quiz-list">
            <div data-tip="Quizzes">
              <RiQuestionnaireFill size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-curriculum-list">
            <div data-tip="Curriculums">
              <FaFileAlt size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-doubt-list">
            <div data-tip="Doubts">
              <FaRegQuestionCircle size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-performance" data-tip="Performance">
            <div>
              <FaChartPie size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-notes-list">
            <div data-tip="Lecture Notes">
              <FaStickyNote size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/" onClick={() => handleLogout()}>
            <FaPowerOff
              size={24}
              data-tip="Logout"
              color="rgba(179,179,179,1.0)"
            />
            <ReactTooltip />
          </Link>
        </div>
      </div>
      <Popup />
    </>
  );
}

export default Studentdatecalendar;
