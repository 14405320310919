import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import rootReducer from './reducers';

const initState = {}
const middleware = [thunk]

export const store = createStore(
    rootReducer,
    initState,
    applyMiddleware(...middleware)
);

export const persistor = persistStore(store);

// export default () => {
//     return { store, persistor };
// };