import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Modal from 'react-modal';
import Select from 'react-dropdown-select';
import validator from 'validator';
import generator from 'generate-password';
import { Button } from 'react-bootstrap';
import { Form, Message } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { getStudent } from "../../../redux/actions/student";
import { login, sendOtp } from "../../../crud/auth.crud";
import { register } from "../../../crud/auth.crud";
import { setToken } from "../../../utils";

import "./App.css";
import CustomNav from "../../../components/header/Navbar";
import { Dropdown } from 'semantic-ui-react';

import { ReactTransliterate } from "react-transliterate";
import "react-transliterate/dist/index.css";
import LoginModal from '../../../components/Login_modal/loginModal';
import DropdownOption from '../../higher_committee/dropdown/dropdown_grade';
import DropdownSubjectOption from '../../higher_committee/dropdown/dropdown_subject';

import OtpInput from 'react-otp-input';
import Backdrop from '../../../UI/Backdrop/Backdrop';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

Modal.setAppElement('#root');


const selectStyles = {
  width: "100%",
  borderRadius: "5px",
  border: "2px solid rgba(217, 83, 79, 0.4)",
  fontSize: "15px",
  fontWeight: 400,
  fontFamily: "'Montserrat', Helvetica",
}

function TeacherRegisterForm() {
  const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [login_email, setLoginEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  const [grade_id, setGradeName] = useState("");
  const [subject_id, setSubjectName] = useState("");

  const handleGradeSelect = (value) => {
    setGradeName(value);
  };

  const handleSubjectSelect = (value) => {
    setSubjectName(value);
  };

  // const [submitOtpStatus, setSubmitOtpStatus] = useState(true);
  // const [isErrorShownOTP, setIsErrorShownOTP] = useState(false);
  // const [otpValue, setOtpValue] = useState('');
  // const [userOtpInput, setUserOtpInput] = useState('');
  // const [showOtpModal, setShowOtpModal] = useState(false);
  // const [otpVerified, setOtpVerified] = useState(false);

  // let attclasses = ["div-class-otp-modal-outline", "Close"];

  // if (showOtpModal) {
  //   attclasses = ["div-class-otp-modal-outline", "Open"];
  // }

  // const handleResendClick = () => {
  //   const newOtp = generator.generate({
  //     length: 4,
  //     numbers: true,
  //     lowercase: false,
  //     uppercase: false
  //   })
  //   sendOtp({
  //     newOtp,
  //     "number": father_number
  //   })
  //     .then(res => {
  //       if (res.return) {
  //         setShowOtpModal(true);
  //         setOtpValue(newOtp);
  //         toast.info(`OTP sent successfully to ${father_number}`, {
  //           position: "top-center",
  //           autoClose: 4000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: false,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //       } else {
  //         toast.error(`Error in sending OTP. Please try again later.`, {
  //           position: "top-center",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: false,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //       }
  //     })
  //     .catch(err => {
  //       console.log(err)
  //       toast.error(`Error in sending OTP. Please try again later.`, {
  //         position: "top-center",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     })
  // }

  // const handleSendClick = () => {
  //   const newOtp = generator.generate({
  //     length: 4,
  //     numbers: true,
  //     lowercase: false,
  //     uppercase: false
  //   })
  //   sendOtp({
  //     newOtp,
  //     "number": father_number
  //   })
  //     .then(res => {
  //       if (res.return) {
  //         setShowOtpModal(true);
  //         setOtpValue(newOtp);
  //         toast.info(`OTP sent successfully to ${father_number}`, {
  //           position: "top-center",
  //           autoClose: 4000,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: false,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //       } else {
  //         toast.error(`Error in sending OTP. Please try again later.`, {
  //           position: "top-center",
  //           autoClose: 5000,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: false,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //       }
  //     })
  //     .catch(err => {
  //       console.log(err)
  //       toast.error(`Error in sending OTP. Please try again later.`, {
  //         position: "top-center",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     })
  // }
  // const handleSubmitClick = () => {
  //   if (userOtpInput == otpValue) {
  //     setOtpVerified(true)
  //     setShowOtpModal(false);
  //     toast.success(`OTP verification successful.`, {
  //       position: "top-center",
  //       autoClose: 2500,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     setIsErrorShownOTP(false);
  //   } else {
  //     setIsErrorShownOTP(true);
  //     setUserOtpInput("");
  //   }
  // }
  // const handleOtpSubmission = (value) => {
  //   setUserOtpInput(value);
  // }
  // useEffect(() => {
  //   if (otpValue.length > 3) {
  //     setSubmitOtpStatus(false);
  //   }
  //   else {
  //     setSubmitOtpStatus(true);
  //   }
  // }, [otpValue])


  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  const closeModal = () => setIsModalOpen(previousState => !previousState);

  // const handleLogin = () => {
  //   setLoading(true);
  //   setIsErrorShown(false);

  //   if (!login_email || !password) {
  //     setLoading(false);
  //     setIsErrorShown(true);
  //   } else {
  //     login(login_email, password)
  //       .then(({ data: { access, refresh, email, user_type } }) => {
  //         setLoading(false);
  //         dispatch(getStudent({ access, email, user_type }), history);
  //         setToken(access, refresh);
  //         // history.replace("/student-dashboard");
  //       })
  //       .catch(() => {
  //         setLoading(false);
  //         setIsErrorShown(true);
  //       })
  //   }
  // }

  //form state
  const [first_name, setFirstName] = useState("")
  const [last_name, setLastName] = useState("")
  const [mobile_number, setMobileNumber] = useState("")
  const [father_name, setFatherName] = useState("")
  const [father_number, setFatherNumber] = useState("")
  
  const [country, setCountry] = useState("")
  const [state, setStudentState] = useState("")
  const [postal_code, setPostalCode] = useState("")
  const [current_grade, setCurrentGrade] = useState("")
  const [preferred_subject, setPreferredSubject] = useState("")
  const [user_type, setUserType] = useState("")
  const [email, setEmail] = useState("");

  const userTypeOptions = [
    'Teacher',
    'Relation Manager',
    'Accounts',
    'Sales',
    'Administration',
    'Higher Committee'
  ]

  const handleSubmit = () => {
    // console.log({first_name, last_name, father_name, mobile_number, father_number, country, state, postal_code, grade_id, subject_id})
    if (!validator.isEmail(email)) {
      alert("Please enter a valid email address.")
    } else if (password == "" || user_type == "") {
      alert("Please fill all fields before submitting.")
    } else {
      register(
        email,
        password,
        user_type,
      )
        .then(() => {
          alert(
            "Employee Account Successfully Created."
          );
          history.replace("/");
        })
        .catch(() => {
          alert(
            "There was an error in creating Employee Account"
          );
        });
    }
  }

  return (
    <>
      <CustomNav Open={closeModal} />
      <div className="card-011-1">
        <div className="row">
          <div className="col-sm-4">
            <input
              className="bot-mar"
              style={{ height: "45px" }}
              type="text"
              placeholder="Email ID"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="col-sm-4">
            <input
              className="bot-mar"
              style={{ height: "45px" }}
              type="text"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="col-sm-4">
            <select className="bot-mar" id="cars" style={{ height: "45px" }} onChange={(e) => setUserType(e.target.value)}>
              <option>SELECT USER TYPE</option>
              <option value="2">Teacher</option>
              <option value="3">Relation Manager</option>
              <option value="4">Sales</option>
              <option value="5">Accounts</option>
              <option value="6">Administration</option>
              <option value="7">Higher Committee</option>
            </select>
          </div>
        </div>

        <button
          type="button"
          onClick={() => handleSubmit()}
          className="btn btn-danger submit"
          style={{
            fontFamily: "'Montserrat', Helvetica",
            fontWeight: "600",
            display: "block",
          }}
        >
          Submit
        </button>
      </div>

      <div style={{ height: "10px" }}></div>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />

      {/* <Backdrop open={showOtpModal} />
      <div className={attclasses.join(" ")}>
        <div className="div-class-otp-modal">
          <div className="display-otp-inp-cont">
            <div className="enter-otp-indic23">Enter OTP </div>
            {isErrorShownOTP ? (
              <div className="wrong-otp-error">Wrong OTP</div>
            ) : null}
            <OtpInput
              value={userOtpInput}
              onChange={handleOtpSubmission}
              numInputs={4}
              isInputNum
              separator={<span>-</span>}
              inputStyle={{ width: "2.2em", padding: "0px", margin: "0px" }}
            />
          </div>
          <div className="margin-Sjdf21">
            <button className="Display-but-reSend" onClick={handleResendClick}>
              Resend OTP
            </button>
            <button
              className="Display-but-SendOtp"
              disabled={submitOtpStatus}
              onClick={handleSubmitClick}
            >
              Submit OTP
            </button>
          </div>
        </div>
      </div> */}
      <ToastContainer
        style={{
          fontFamily: "'Montserrat', Helvetica",
          fontWeight: 500,
          padding: "10px",
        }}
      />
    </>
  );
}

export default TeacherRegisterForm;
