import { GET_TEACHER, DELETE_TEACHER } from '../actions/teacher/types'

const initialState = {}

export default function getTeacherDetailsReducer(state = initialState, action) {
    switch(action.type) {
        case GET_TEACHER:
            return {
                ...state,
                teacher: action.payload
            };
        case DELETE_TEACHER:
            return {}
        default:
            return state
    }
}