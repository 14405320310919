import React, { useLayoutEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import navLogo from "../../student/dashboard/img/pic_4.png";
import { Navbar } from 'react-bootstrap';
import tncFile from "./TRG_Terms_and_Conditions.pdf";

const TnC = props => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [file, setFile] = useState(tncFile);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    return (
        <>
            <Navbar className="navbar-scroll py-4" style={{ "fontFamily": "'Montserrat', sans-serif" }} collapseOnSelect expand="lg" variant="light">
                <Navbar.Brand style={{ "fontWeight": "600" }}>
                    <img src={navLogo} width="300px" alt="TheRightGuru Logo" />
                </Navbar.Brand> 
            </Navbar>

            <div>
                <h2 style={{ fontWeight: 600 }}>Terms, Conditions and Disclaimer</h2>
                <Document
                    file="https://therightguru-website.s3.ap-south-1.amazonaws.com/TnC.pdf"
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page pageNumber={pageNumber} />
                </Document>
                <div>
                    <p>Page {pageNumber} of {numPages}</p>
                    <button
                        type="button"
                        disabled={pageNumber <= 1}
                        onClick={previousPage}
                    >
                        Previous
                    </button>
                    <button
                        type="button"
                        disabled={pageNumber >= numPages}
                        onClick={nextPage}
                    >
                        Next
                    </button>
                </div>
            </div>
        </>
    )
}

export default TnC;