import React, { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import LoginModal from "../../../components/Login_modal/loginModal";
import Footer from "../../../components/Footer";
import Popup2 from "../../non_auth/new_popup/popup2";
import CustomNav from "../../../components/header/Navbar";
import "./common.css";

Modal.setAppElement("#root");

const ndaData = [
  {
    id: 1,
    data1: "Official Website",
    data2: "www.upsc.gov.in",
  },
  {
    id: 2,
    data1: "Name of the Exam	",
    data2: "National Defence Academy & Naval Academy (NDA)",
  },
  {
    id: 3,
    data1: "Conducting Body	",
    data2: "Union Public Service Commission (UPSC)",
  },
  {
    id: 4,
    data1: "Exam Level",
    data2: "National",
  },
  {
    id: 5,
    data1: "Exam Frequency",
    data2: "Twice a year",
  },
  {
    id: 6,
    data1: "Exam Levels	",
    data2: "10+2/HSC",
  },
  {
    id: 7,
    data1: "Mode of Examination	",
    data2: "NDA",
  },
  {
    id: 8,
    data1: "Exam category	",
    data2: "National",
  },
  {
    id: 9,
    data1: "Exam mode	",
    data2: "Offline",
  },
  {
    id: 10,
    data1: "Stages	",
    data2: "Written exam and SSB Interview",
  },
  {
    id: 11,
    data1: "Exam Cities	",
    data2: "41",
  },
  {
    id: 12,
    data1: "Scope of NDA	",
    data2: "Admission to:- 1.	Army, 2.	Air Force, 3.	Navy",
  },
  {
    id: 13,
    data1: "Vacancies	",
    data2: "400",
  },
  {
    id: 14,
    data1: "Job Location	",
    data2: "All Over India	",
  },
];
const cdsData = [
  {
    id: 1,
    data1: "Official Website",
    data2: "www.upsc.gov.in",
  },
  {
    id: 2,
    data1: "Name of the Exam	",
    data2: "Combined Defence Services Examination",
  },
  {
    id: 3,
    data1: "Conducting Body	",
    data2: "Union Public Service Commission (UPSC)",
  },
  {
    id: 4,
    data1: "Exam Level",
    data2: "National",
  },
  {
    id: 5,
    data1: "Exam Frequency",
    data2: "Twice a year",
  },
  {
    id: 6,
    data1: "CDS 2 Vacancy	",
    data2: "457",
  },
  {
    id: 7,
    data1: "Mode of Application	",
    data2: "Online",
  },
  {
    id: 8,
    data1: "Registration Date	",
    data2: "20th December 2023 to 9th January 2024",
  },
  {
    id: 9,
    data1: "CDS 1 2024 Exam Date	",
    data2: "21st April 2024",
  },
  {
    id: 10,
    data1: "Selection Process ",
    data2: "Written Test-Interview – Medical Examination	",
  },
  {
    id: 11,
    data1: "Number of Sections	",
    data2:
      "Indian Military Academy (IMA), Indian Naval Academy (INA) and Air Force Academy (AFA) – ThreeOfficers’ Training Academy (OTA) – Two ",
  },
  {
    id: 12,
    data1: "Language of Written Exam	",
    data2: "Bilingual",
  },
  {
    id: 13,
    data1: "Mode of Examination		",
    data2: "Offline",
  },
  {
    id: 14,
    data1: "Type of Question	",
    data2: "Multiple – Choice Questions",
  },
  {
    id: 15,
    data1: "Eligibility	",
    data2: "Unmarried Males & Females",
  },
  {
    id: 16,
    data1: "CDS Exam Duration	",
    data2: "2 Hours (each paper)",
  },
  {
    id: 17,
    data1: "Total marks of the test 	",
    data2: "IMA, INA and AFA – 300OTA - 200",
  },
  {
    id: 18,
    data1: "Making Scheme	",
    data2: "1/3 for each incorrect answer",
  },
  {
    id: 19,
    data1: "Job Location	",
    data2: "All Over India",
  },
];

function NdaCds() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section style={{ width: "100%", marginBottom: "3%" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              width: "80%",
              marginTop: "3%",
              height: "auto",
            }}
          >
            <h1 style={{ fontSize: 24, color: "#dc3545", fontWeight: 600 }}>
              NDA, CDS Exam Pattern
            </h1>
            <h1
              style={{
                fontSize: 18,
                fontWeight: 600,
                marginTop: "2%",
              }}
            >
              NDA Exam Details
            </h1>

            <p
              style={{
                fontSize: 16,
                marginTop: "1%",
                fontWeight: 500,
                lineHeight: "1.6",
              }}
            >
              The application form correction window for UPSC NDA 2024 is
              scheduled to be open from January 10 to 16, 2024. The official
              notification for UPSC NDA 2024 encompasses crucial details about
              the examination, such as the application process, exam date,
              syllabus, exam pattern, vacancies, salary, and other pertinent
              information.
            </p>

            <div>
              <div
                style={{
                  marginBottom: "2%",
                  marginTop: "2%",
                  overflowX: "auto",
                }}
              >
                <table style={{ width: "100%", margin: "0 auto" }}>
                  <tr>
                    <th style={{ textAlign: "center" }} className="heade">
                      Particulars
                    </th>
                    <th style={{ textAlign: "center" }} className="heade">
                      Details
                    </th>
                  </tr>
                  {ndaData.map((data) => {
                    return (
                      <tr>
                        <td style={{ textAlign: "center" }}>{data.data1}</td>
                        <td style={{ textAlign: "center" }}>{data.data2}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>

            <h1
              style={{
                fontSize: 18,
                fontWeight: 600,
                marginTop: "2%",
              }}
            >
              CDS Exam Details
            </h1>

            <p
              style={{
                fontSize: 16,
                marginTop: "1%",
                fontWeight: 500,
                lineHeight: "1.6",
              }}
            >
              The Union Public Service Commission (UPSC) administers the
              "Combined Defence Services" (CDS) Examination biannually, aiming
              to enlist candidates for the Indian Military Academy, Officers
              Training Academy, Indian Naval Academy, and Indian Air Force
              Academy. Anticipated to be released on December 20, 2023, the CDS
              1 Notification is followed by the examination scheduled for April
              21, 2024. Successful candidates undergo an interview by the
              Services Selection Board (SSB) for admission into the respective
              Academies. To excel in the examination, comprehensive knowledge of
              the pattern, syllabus, and insights from previous year exam
              analyses is imperative.
            </p>

            <div>
              <div
                style={{
                  marginBottom: "2%",
                  marginTop: "3%",
                  overflowX: "auto",
                }}
              >
                <table style={{ width: "100%", margin: "0 auto" }}>
                  <tr>
                    <th style={{ textAlign: "center" }} className="heade">
                      Particulars
                    </th>
                    <th style={{ textAlign: "center" }} className="heade">
                      Details
                    </th>
                  </tr>
                  {cdsData.map((data) => {
                    return (
                      <tr>
                        <td style={{ textAlign: "center" }}>{data.data1}</td>
                        <td style={{ textAlign: "center" }}>{data.data2}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>



            <h2 style={{ fontSize: 18, fontWeight: 600, marginTop: "2%" }}>
              Achieve Your Goals with THE RIGHT GURU Tuition Centre
            </h2>
            <p style={{ fontSize: 16, marginTop: "1%", lineHeight: "1.6" }}>
              Join us on a journey of growth, learning, and achievement. Whether
              you're aiming for top grades, entrance exams, or a bright future,
              we are here to provide the guidance and support you need to
              succeed.
            </p>
            <h2 style={{ fontSize: 16, marginTop: "1%" }}>
              For any query, Email us at:{" "}
              <span>
                <a href="mailto:contact@therightguru.com">
                  contact@therightguru.com
                </a>
              </span>
            </h2>
            <p style={{ fontSize: 16, marginTop: "1%", lineHeight: "1.6" }}>
              For a more personalized and detailed understanding, we encourage
              you to visit our centers and consult our dedicated counselors.
            </p>
          </div>
        </div>
      </section>

      <section>
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default NdaCds;
