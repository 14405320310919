import React, { useContext, useState } from "react";
import "./popup.css";
import pic_2 from "../../student/dashboard/img/pic_2.png";

import { WebSocketContext } from "../../../websocket2";

import { createIssue } from "../../../crud/student.crud";
import { useSelector } from "react-redux";

function Popup(props) {
  const reduxState = useSelector((state) => state);
  let user = {};
  let user_type = "";
  if (reduxState.studentDetails.student) {
    user = reduxState.studentDetails.student;
    user_type = "student";
  } else if (reduxState.teacherDetails.teacher) {
    user = reduxState.teacherDetails.teacher;
    user_type = "teacher";
  } else if (reduxState.salesDetails.sales) {
    user = reduxState.salesDetails.sales;
    user_type = "sales";
  } else if (reduxState.hcDetails.hc) {
    user = reduxState.hcDetails.hc;
    user_type = "hc";
  } else {
    user = reduxState.rmDetails.rm;
    user_type = "rm";
  }

  const [issueText, setIssueText] = useState("");
  const [chatText, setChatText] = useState("");

  function showpopup() {
    document.getElementById("popupbox").classList.toggle("disp");
    document.getElementById("popup2").classList.toggle("change1");
    document.getElementById("popupbox2").classList.toggle("change2");

    document.getElementById("help-icon").classList.toggle("fa-headset");
    document.getElementById("help-icon").classList.toggle("fa-times");
  }

  function showpopup2() {
    document.getElementById("popupbox2").classList.toggle("disp2");

    document
      .getElementById("support-icon")
      .classList.toggle("fa-question-circle");
    document.getElementById("support-icon").classList.toggle("fa-times");
  }

  const ws = useContext(WebSocketContext);

  ws.socket.onmessage = (msg) => {
    const payload = JSON.parse(msg.data);
    if (payload.type === "chat_message") {
      // update chatbox with payload.message
      let div1 = document.createElement("div");
      div1.classList.add("message_title");
      let h4 = document.createElement("h4");
      div1.appendChild(h4);
      let text_title = document.createTextNode(payload.sent_by);
      h4.appendChild(text_title);

      let div2 = document.createElement("div");
      div2.classList.add("message_body");
      let p = document.createElement("p");
      div2.appendChild(p);
      let text_body = document.createTextNode(payload.message);
      p.appendChild(text_body);

      let list = document.createElement("li");
      list.appendChild(div1);
      list.appendChild(div2);

      document.getElementById("messages").appendChild(list);
    }
  };

  const sendChatMessage = () => {
    console.log(props.issueUser || (user_type==="student" ? user.enrolled_student_id : user.teacher_id))
    ws.sendMessage({
      type: "CHAT_MESSAGE",
      sent_by:
        user_type === "student"
          ? user.enrolled_student_id
          : user_type === "teacher"
          ? user.teacher_id
          : user.rm_id,
      message: chatText,
      chatGroup:
        props.issueUser ||
        (user_type === "student" ? user.enrolled_student_id : user.teacher_id),
    });
    setChatText("");
  };

  const handleSubmit = () => {
    console.log(user);
    // hit PUT API to create issue
    createIssue({
      issue_text: issueText,
      date: new Date().toISOString(),
      object_id:
        user_type === "student" ? user.enrolled_student_id : user.teacher_id,
      status: "In Progress",
      category: "Category",
    })
      .then((res) => {
        //check if connection is already open
        if (ws.socket.readyState === WebSocket.OPEN) {
          // meant for RM so that he knows issue has been created
          ws.sendMessage({
            type: "ISSUE_CREATED",
            created_by:
              user_type === "student"
                ? user.enrolled_student_id
                : user.teacher_id,
          });

          // meant to make the student join rm-student specific group for further communication
          ws.sendMessage({
            type: "JOIN_CHANNEL",
            user:
              user_type === "student"
                ? user.enrolled_student_id
                : user.teacher_id,
            message: "New student/teacher joined rm_student specific group.",
            group:
              user_type === "student"
                ? user.enrolled_student_id
                : user.teacher_id,
          });

          setIssueText("");
        } else {
          // if connection is not open, wait for it to open and then send message
          ws.socket.onopen = () => {
            ws.sendMessage({
              type: "ISSUE_CREATED",
              created_by:
                user_type === "student"
                  ? user.enrolled_student_id
                  : user.teacher_id,
            });

            ws.sendMessage({
              type: "JOIN_CHANNEL",
              user:
                user_type === "student"
                  ? user.enrolled_student_id
                  : user.teacher_id,
              message: "New student/teacher joined rm_student specific group.",
              group:
                user_type === "student"
                  ? user.enrolled_student_id
                  : user.teacher_id,
            });

            setIssueText("");
          };
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="side-popup-1">
      <button id="popup" onClick={showpopup}>
        <i id="help-icon" className="fas fa-headset"></i>
      </button>
      <div id="popupbox">
        <div className="heading">
          <p>Help</p>
        </div>
        <div className="list1">
          <ul
            className="contact_list"
            style={{ listStyleType: "none", height: "100%" }}
          >
            <li>
              <p style={{ fontSize: "small" }}>
                <span style={{ fontWeight: "550", fontSize: "small" }}>Contact 1</span> :
                9570-424242
              </p>
            </li>
            <li>
              <p style={{ fontSize: "small" }}>
                <span style={{ fontWeight: "550", fontSize: "small" }}>Contact 2</span> :
                74-1919-7878
              </p>
            </li>
            <li>
              <p style={{ fontSize: "small" }}>
                <span style={{ fontWeight: "550", fontSize: "small" }}>E-mail</span> :
                contact@therightguru.com
              </p>
            </li>
          </ul>
        </div>
      </div>

      <button id="popup2" onClick={showpopup2}>
        <i id="support-icon" className="fas fa-question-circle"></i>
      </button>
      <div id="popupbox2">
        <div className="heading">
          <p>Support</p>
        </div>
        <div className="support-text">
          <textarea
            name="text"
            rows="7"
            value={issueText}
            onChange={(e) => setIssueText(e.target.value)}
            placeholder="Raise your issue here.."
          ></textarea>
          <button
            style={{ marginBottom: "25px" }}
            className="support-submit"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <ol id="messages" className="chat_messages"></ol>
        </div>
        <div className="start_chat">
          <div className="chat_form">
            <input
              name="message"
              type="text"
              value={chatText}
              placeholder="Start Chat"
              onChange={(e) => setChatText(e.target.value)}
              autoFocus
              autoComplete="off"
            />
            <button onClick={sendChatMessage}>
              <i
                style={{ color: "#ec3545", transform: "rotate(40deg)" }}
                className="far fa-paper-plane"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Popup;
