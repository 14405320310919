import React, { useLayoutEffect, useState, useRef } from "react";
import Footer from "../../../components/Footer";
import Modal from "react-modal";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import LoginModal from "../../../components/Login_modal/loginModal";
import Popup2 from "../new_popup/popup2";
import CustomNav from "../../../components/header/Navbar";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import './footer.css'
import axios from "axios";

const position = [
  {
    value: "Teacher",
    label: "Teacher",
  },
  {
    value: "Digital marketing",
    label: "Digital marketing",
  },
  {
    value: "Teaching Management",
    label: "Teaching Management",
  },
  {
    value: "Content Design",
    label: "Content Design",
  },
  {
    value: "Designing",
    label: "Designing",
  },
  {
    value: "Customer Service",
    label: "Customer Service",
  },
  {
    value: "Logistics",
    label: "Logistics",
  },
  {
    value: "Technology",
    label: "Technology",
  },
  {
    value: "Finance and Accounting",
    label: "Finance and Accounting",
  },
  {
    value: "Sales",
    label: "Sales",
  },
  {
    value: "Tele-call Marketing",
    label: "Tele-call Marketing",
  },
];
Modal.setAppElement("#root");

export default function Career() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [emaill, setEmaill] = useState("");
  const [mobile, setMobile] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [positionSelected, setPositionSelected] = useState("");
  const [additional, setAdditional] = useState("");
  const [file, setFile] = useState(null);
  const formRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    // Check if a file is selected
    if (selectedFile) {
      // Check if the selected file is a PDF
      if (selectedFile.type === 'application/pdf') {
        // Check the file size (e.g., limit to 5 MB)
        const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
        if (selectedFile.size <= maxSizeInBytes) {
          // File is within size limit, handle it
          setFile(selectedFile);
        } else {
          // File exceeds size limit, handle accordingly
          alert('File size exceeds the limit (5 MB)');
        }
      } else {
        // File is not a PDF, handle accordingly
        alert('Please select a PDF file');
      }
    }
  }

  const submitForm = async(e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("fname", fName);
      formData.append("lname", lName);
      formData.append("email", emaill);
      formData.append("phone", mobile);
      formData.append("address", address);
      formData.append("city", city);
      formData.append("country", country);
      formData.append("position", positionSelected);
      formData.append("additional", additional);
      formData.append("file", file);
      await axios.post(`https://api.therightguru.com/api/career-form/`, formData);

      // Action to take when the form is submitted successfully
      alert("Form submitted successfully!");
      setFName(""); setLName("");
      setEmaill(""); setMobile("");
      setAddress(""); setCity(""); setCountry("");
      setPositionSelected(""); setAdditional("");
      setFile(null);
      // Reset the form using the ref
      formRef.current.reset();
    } catch (err) {
      alert("Something went wrong. Please carefully fill out the form again.")
      console.log(err);
    }
  };

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div style={{ width: "100%" }}>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5%",
          marginBottom: "5%",
        }}
      >
        <div
          className="wwraper career-form-wrapper"
        >
          <div
            style={{
              textAlign: "center",
              color: "#dc3545",
              fontSize: 24,
              fontWeight: 600,
            }}
          >
            CAREER AT TRG
          </div>
          <div
            style={{
              marginTop: "3%",
              fontSize: 20,
              fontWeight: 600,
              paddingBottom:'2%'
            }}
            className="career-input-wrapper"
          >
            Want to have a good career? Apply Now
          </div>
        <form ref={formRef} onSubmit={submitForm}>
          <div
            className="career-input-wrapper"
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ fontWeight: 500 }}>First Name (Required): </p>
              <TextField
                style={{ width: "100%", marginTop: 10, marginBottom: 20 }}
                id="outlined-basic"
                placeholder="First Name "
                variant="outlined"
                onChange={(e) => setFName(e.target.value)}
                required
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ fontWeight: 500 }}>Last Name:</p>
              <TextField
                style={{ width: "100%", marginTop: 10, marginBottom: 20 }}
                id="outlined-basic"
                placeholder="Last Name "
                variant="outlined"
                onChange={(e) => setLName(e.target.value)}
              />
            </div>
          </div>

          <div
            className="career-input-wrapper"
          >
            <p style={{ fontWeight: 500 }}>Email (Required):</p>
            <TextField
              style={{ width: "100%", marginTop: 10, marginBottom: 20 }}
              id="outlined-basic"
              placeholder="Email "
              variant="outlined"
              onChange={(e) => setEmaill(e.target.value)}
              required
            />
          </div>
          <div className="career-input-wrapper">
            <p style={{ fontWeight: 500 }}>Phone (Required):</p>
            <span style={{ fontWeight: 400, fontSize: "12px", color:"grey", fontStyle:"italic" }}>Without country code</span>
            <TextField
              style={{ width: "100%", marginTop: 10, marginBottom: 20 }}
              id="outlined-basic"
              placeholder="Phone "
              variant="outlined"
              type="number"
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              onChange={(e) => setMobile(e.target.value)}
              required
            />
          </div>
          <div className="career-input-wrapper">
            <p style={{ fontWeight: 500 }}>Address (Required):</p>
            <TextField
              style={{ width: "100%", marginTop: 10, marginBottom: 20 }}
              id="outlined-basic"
              placeholder="Address "
              variant="outlined"
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>
          <div className="career-input-wrapper">
            <p style={{ fontWeight: 500 }}>City (Required):</p>
            <TextField
              style={{ width: "100%", marginTop: 10, marginBottom: 20 }}
              id="outlined-basic"
              placeholder="City "
              variant="outlined"
              onChange={(e) => setCity(e.target.value)}
              required
            />
          </div>
          <div className="career-input-wrapper">
            <p style={{ fontWeight: 500 }}>Country (Required):</p>
            <TextField
              style={{ width: "100%", marginTop: 10, marginBottom: 20 }}
              id="outlined-basic"
              placeholder="Country "
              variant="outlined"
              onChange={(e) => setCountry(e.target.value)}
              required
            />
          </div>

          <div className="career-input-wrapper">
            <p style={{ fontWeight: 500 }}>Please choose desired position :</p>
            <TextField
              style={{ marginTop: 10, width: "100%" }}
              id="standard-select-position"
              select
              label="Choose desired position"
              variant="outlined"
              value={ positionSelected }
              onChange={(e) => setPositionSelected(e.target.value)}
              required
            >
              {position.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className="career-input-wrapper">
            <p style={{ fontWeight: 500 }}>Additional information:</p>
            <TextField
              style={{ width: "100%", marginTop: 10, marginBottom: 20 }}
              id="outlined-basic"
              placeholder="Additional Info "
              variant="outlined"
              onChange={(e) => setAdditional(e.target.value)}
            />
          </div>

          <div className="career-input-wrapper">
            <p style={{ fontWeight: 500 }}>Upload Resume (Required):</p>
            <span style={{ fontWeight: 400, fontSize: "12px", color:"grey", fontStyle:"italic" }}>PDF only, and its size must be less than 5 MB.</span>
            <TextField
              style={{ width: "100%", marginTop: 10, marginBottom: 20 }}
              id="outlined-basic"
              variant="outlined"
              type="file"
              inputProps={{ accept: 'application/pdf' }}
              onChange={handleFileChange}
              required
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "2%",
            }}
          >
            <button type='submit'
              style={{
              backgroundColor: "#dc3545",
              padding: 10,
              borderRadius: 8,
              border: "none",
              outline: "none",
              width: 200,
              cursor: "pointer",
              color: "#fff",
              fontSize: 20,
              fontWeight: 600,
              textAlign: "center",
            }}
            >Submit</button>              
          </div>
        </form>
        </div>
      </section>

      <section id="footer">
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}
