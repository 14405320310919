import React, { useState, useEffect, useLayoutEffect } from "react";
import "./style.css";
import { NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import DashboardIcon from "@material-ui/icons/Dashboard";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SchoolIcon from "@material-ui/icons/School";
import DescriptionIcon from "@material-ui/icons/Description";
import HelpIcon from "@material-ui/icons/Help";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import NotesIcon from "@material-ui/icons/Notes";
// import { CgProfile } from "react-icons/cg";
// import { AiTwotoneBank } from "react-icons/ai";
// import { SiGoogleclassroom } from "react-icons/si";
// import line_5_2x from "./img/line-5@2x.svg";
// import line_6_2x from "./img/line-6@2x.svg";
// import donut_chart_1_2x from "./img/donut-chart-1@2x.png";
import ellipse_5_2x from "./img/ellipse-5@2x.svg";
import ellipse_6_2x from "./img/ellipse-6@2x.svg";
import ellipse_7_2x from "./img/ellipse-7@2x.svg";
// import line_5_1_2x from "./img/line-5-1@2x.svg";
// import line_6_1_2x from "./img/line-6-1@2x.svg";
// import donut_chart_1_1_2x from "./img/donut-chart-1-1@2x.png";
import ellipse_5_1_2x from "./img/ellipse-5-1@2x.svg";
import ellipse_6_1_2x from "./img/ellipse-6-1@2x.svg";
import ellipse_7_1_2x from "./img/ellipse-7-1@2x.svg";
// import rectangle_7_1x from "./img/rectangle-7@1x.png";
import vector_2x from "./img/vector@2x.svg";
// import ellipse_2_2x from "./img/ellipse-2@2x.svg";
import { PieChart } from "react-minimal-pie-chart";
import { teacherAnalytics } from "../../../crud/teacher.crud";
import Popup from "../popup/popup";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteTeacher } from "../../../redux/actions/teacher";
import pic_2 from "../../student/dashboard/img/pic_2.png";
import SideDrawer from "../../../UI/sideDrawer/sideDrawer";

function Teacherperformance(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [analData, setAnalData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const user = useSelector((state) => state.teacherDetails.teacher);

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  useEffect(() => {
    teacherAnalytics(user.teacher_id)
      .then((res) => {
        setAnalData(res);
        setIsLoaded(true);
      })
      .catch((err) => console.log(err));
  }, []);

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteTeacher(reduxUser.teacher_id));
    removeToken();
  };

  let classesData = [];

  isLoaded
    ? analData.scheduled
      ? (classesData = [
          { title: "Completed", value: analData.completed, color: "#6F58FF" },
          { title: "Scheduled", value: analData.scheduled, color: "#46C9B8" },
          { title: "Cancelled", value: analData.cancelled, color: "#F76775" },
        ])
      : (classesData = [])
    : (classesData = []);

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="teacher-performance"
      />
      <div className="container-center-horizontal">
        <div className="teacher-performance-mobile screen">
          <div className="flex-row-C61RwL">
            <div className="flex-col-dXBp89">
              <div className="component-1-0PZcXW">
                <img
                  alt="image"
                  src={pic_2}
                  style={{ height: "90px", marginTop: "-8%" }}
                />
              </div>
              <div className="my-analytics-0PZcXW">
                <span className="span0-vfsNCJ">My</span>
                <span className="span1-vfsNCJ"> Analytics</span>
              </div>
            </div>
            {/* This is the Notification div */}
            <SideDrawer
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />
            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>
          <div className="group-69-C61RwL">
            <div className="group-57-8FQU39"></div>
            <div className="classes-8FQU39 montserrat-medium-blue-zodiac-18px">
              Classes
            </div>
            {/* <img className="donut-chart-1-8FQU39" src={donut_chart_1_2x} /> */}
            {isLoaded ? (
              <PieChart
                className="donut-chart-1-8FQU39"
                lineWidth={30}
                rounded
                label={({ dataEntry }) => dataEntry.value}
                labelStyle={(index) => ({
                  fill: classesData[index].color,
                  fontSize: "5px",
                  fontFamily: "sans-serif",
                })}
                labelPosition={60}
                data={classesData}
              />
            ) : (
              "Loading..."
            )}
            <img className="ellipse-5-8FQU39" src={ellipse_5_2x} />
            <img className="ellipse-6-8FQU39" src={ellipse_6_2x} />
            <img className="ellipse-7-8FQU39" src={ellipse_7_2x} />
            <div className="completed-8FQU39 montserrat-semi-bold-blue-zodiac-10px">
              Completed
            </div>
            <div className="scheduled-8FQU39 montserrat-semi-bold-blue-zodiac-10px">
              Scheduled
            </div>
            <div className="cancelled-8FQU39 montserrat-semi-bold-blue-zodiac-10px">
              Cancelled
            </div>
          </div>
          <div className="flex-row-VMr6Om">
            <div className="overlap-group-BdpSxK">
              <div className="group-143-Jge9fh">
                <div className="group-142-fMSFOV">
                  <div className="group-70-5VqovO">
                    <div className="group-57-P36yGh"></div>
                    <div className="students-P36yGh montserrat-medium-blue-zodiac-14px">
                      Students
                    </div>
                  </div>
                </div>
              </div>
              <div className="x10-Jge9fh montserrat-semi-bold-black-72px">
                {analData
                  ? analData.students
                    ? analData.students.length
                    : ""
                  : ""}
              </div>
            </div>
            <div className="overlap-group1-BdpSxK">
              <div className="group-218-q9xEPe">
                <div className="group-145-F1UMB2">
                  <div className="group-142-jIRyYZ">
                    <div className="group-70-wDb64m">
                      <div className="group-57-xx0RFl"></div>
                      <div className="classrooms-xx0RFl montserrat-medium-blue-zodiac-14px">
                        Classrooms
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="x10-q9xEPe montserrat-semi-bold-black-72px">
                {analData
                  ? analData.classrooms
                    ? analData.classrooms.length
                    : ""
                  : ""}
              </div>
            </div>
          </div>
          <div className="flex-row-mzXdH9">
            <div className="overlap-group2-NwNIwt">
              <div className="group-144-84keex">
                <div className="group-142-Zd8H5Z">
                  <div className="group-70-RVY61y">
                    <div className="group-57-kLGnId"></div>
                    <div className="classes-kLGnId montserrat-medium-blue-zodiac-14px">
                      Grades
                    </div>
                  </div>
                </div>
              </div>
              <div className="x3-84keex montserrat-semi-bold-black-72px">
                {analData
                  ? analData.grades
                    ? analData.grades.length
                    : ""
                  : ""}
              </div>
            </div>
            <div className="group-145-NwNIwt">
              <div className="group-142-iVz8z0">
                <div className="group-70-yKZgYD">
                  <div className="group-57-xVQImY"></div>
                  <div className="subjects-xVQImY montserrat-medium-blue-zodiac-14px">
                    Subjects
                  </div>
                </div>
                <div className="x2-yKZgYD montserrat-semi-bold-black-72px">
                  {analData
                    ? analData.subjects
                      ? analData.subjects.length
                      : ""
                    : ""}
                </div>
              </div>
            </div>
          </div>
          <div className="overlap-group3-C61RwL">
            <div className="group-71-eSNWXa">
              <div className="group-57-ymUorQ"></div>
              <div className="assignments-quizzes-ymUorQ montserrat-medium-blue-zodiac-18px">
                Assignments &amp; Quizzes
              </div>
              <div className="total-assignments-given-ymUorQ montserrat-medium-blue-zodiac-18px">
                Total Assignments <br />
                Given
              </div>
              <div className="total-quizzes-given-ymUorQ montserrat-medium-blue-zodiac-18px">
                Total Quizzes <br />
                Given
              </div>
            </div>
            <div className="x4-eSNWXa">
              {analData && analData.total_assignments_given}
            </div>
            <div className="x10-eSNWXa">
              {analData && analData.total_quiz_given}
            </div>
          </div>
        </div>
      </div>
      <div className="container-center-horizontal">
        <div className="teacher-performance screen">
          <div className="sider-teacher-general-div">
            <div className="sider-teacher-in-general-80">
              <div style={{ marginTop: "120px" }} />
              <NavLink to="/teacher-dashboard" exact>
                <DashboardIcon
                  data-tip="Dashboard"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-date-calendar" exact>
                <EventIcon data-tip="Scheduler" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-previous-classes">
                <ScheduleIcon
                  data-tip="Previous Class"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-students">
                <SchoolIcon
                  data-tip="Enrolled Student"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-curriculum-list">
                <DescriptionIcon
                  data-tip="Curriculum"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-doubt-list">
                <HelpIcon data-tip="Doubts" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-analytics">
                <AssessmentIcon
                  data-tip="Analytics"
                  className="icon-in-hc-color-2"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-assignments">
                <AssignmentIcon
                  data-tip="Assignments"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-quizzes">
                <LibraryBooksIcon
                  data-tip="Quizzes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-lecture-notes">
                <NotesIcon
                  data-tip="Lecture Notes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              {/* <NavLink to="/teacher-payments"><AccountBalanceIcon data-tip="Payment" className="icon-in-hc-color" /><ReactTooltip /></NavLink> */}
              <div style={{ marginTop: "10px" }} />
              <NavLink to="/" onClick={() => handleLogout()}>
                {" "}
                <PowerSettingsNewIcon
                  data-tip="Logout"
                  className="icon-in-hc-color"
                />{" "}
              </NavLink>
              <div style={{ marginTop: "1px" }} />
            </div>
          </div>
          <div className="flex-col-C61RwL">
            <div className="component-1-0xy0vn">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "115px", marginTop: "-8%" }}
              />
            </div>
            <div className="my-analytics-0xy0vn">
              <span className="span0-ppPUeW">My</span>
              <span className="span1-ppPUeW"> Analytics</span>
            </div>
            <div className="group-69-0xy0vn">
              <div className="group-57-LDZ1CX"></div>
              <div className="classes-LDZ1CX montserrat-medium-blue-zodiac-18px">
                Classes
              </div>
              {isLoaded ? (
                <PieChart
                  className="donut-chart-1-LDZ1CX"
                  lineWidth={30}
                  rounded
                  label={({ dataEntry }) => dataEntry.value}
                  labelStyle={(index) => ({
                    fill: classesData[index].color,
                    fontSize: "5px",
                    fontFamily: "sans-serif",
                  })}
                  labelPosition={60}
                  data={classesData}
                />
              ) : (
                "Loading..."
              )}
              <img className="ellipse-5-LDZ1CX" src={ellipse_5_1_2x} />
              <img className="ellipse-6-LDZ1CX" src={ellipse_6_1_2x} />
              <img className="ellipse-7-LDZ1CX" src={ellipse_7_1_2x} />
              <div className="completed-LDZ1CX montserrat-semi-bold-blue-zodiac-10px">
                Completed
              </div>
              <div className="scheduled-LDZ1CX montserrat-semi-bold-blue-zodiac-10px">
                Scheduled
              </div>
              <div className="cancelled-LDZ1CX montserrat-semi-bold-blue-zodiac-10px">
                Cancelled
              </div>
            </div>
            <div className="overlap-group-0xy0vn">
              <div className="group-71-y1HRxC">
                <div className="group-57-rylCBo"></div>
                <div className="assignments-quizzes-rylCBo montserrat-medium-blue-zodiac-18px">
                  Assignments &amp; Quizzes
                </div>
                <div className="total-assignments-given-rylCBo montserrat-medium-blue-zodiac-18px">
                  Total Assignments <br />
                  Given
                </div>
                <div className="total-quizzes-given-rylCBo montserrat-medium-blue-zodiac-18px">
                  Total Quizzes <br />
                  Given
                </div>
              </div>
              <div className="x4-y1HRxC">
                {analData && analData.total_assignments_given}
              </div>
              <div className="x10-y1HRxC">
                {analData && analData.total_quiz_given}
              </div>
            </div>
          </div>
          <div className="flex-col-VMr6Om">
            <div className="group-143-lcFreP">
              <div className="group-142-4L6wx1">
                <div className="group-70-qpCQPZ">
                  <div className="group-57-1h9m5p"></div>
                  <div className="students-1h9m5p montserrat-medium-blue-zodiac-18px">
                    Students
                  </div>
                </div>
                <div className="x10-qpCQPZ montserrat-semi-bold-black-96px">
                  {analData
                    ? analData.students
                      ? analData.students.length
                      : ""
                    : ""}
                </div>
              </div>
            </div>
            <div className="overlap-group1-lcFreP">
              <div className="group-73-cKEQA6">
                <div className="group-57-BoR6E9"></div>
                <div className="classes-BoR6E9 montserrat-medium-blue-zodiac-18px">
                  Grades
                </div>
              </div>
              <div className="x3-cKEQA6 montserrat-semi-bold-black-96px">
                {analData
                  ? analData.grades
                    ? analData.grades.length
                    : ""
                  : ""}
              </div>
            </div>
          </div>
          <div className="flex-col-mzXdH9">
            <div className="group-22-IGdxlK">
              <div className="flex-col-oVwSO3">
                <div className="biba-sobti-Ocgsf1">
                  {user.first_name + " " + user.last_name}
                </div>
                <div className="bibasobtigmailcom-Ocgsf1">{user.emailID}</div>
              </div>
              <img className="vector-oVwSO3" src={vector_2x} />
            </div>
            <div className="group-144-IGdxlK">
              <div className="group-142-szfML3">
                <div className="group-70-JtY33o">
                  <div className="group-57-vNyHFn"></div>
                  <div className="classrooms-vNyHFn montserrat-medium-blue-zodiac-18px">
                    Classrooms
                  </div>
                </div>
                <div className="x5-JtY33o montserrat-semi-bold-black-96px">
                  {analData
                    ? analData.classrooms
                      ? analData.classrooms.length
                      : ""
                    : ""}
                </div>
              </div>
            </div>
            <div className="group-141-IGdxlK">
              <div className="group-72-iYZvhX">
                <div className="group-57-UvBJyI"></div>
                <div className="subjects-UvBJyI montserrat-medium-blue-zodiac-18px">
                  Subjects
                </div>
              </div>
              <div className="x2-iYZvhX montserrat-semi-bold-black-96px">
                {analData
                  ? analData.subjects
                    ? analData.subjects.length
                    : ""
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup />
    </>
  );
}

function Component1(props) {
  const { line5, line6 } = props;

  return (
    <div className="component-1 border-1px-blue-zodiac">
      <div className="overlap-group12">
        <img className="line-5" src={line5} />
        <img className="line-6" src={line6} />
      </div>
    </div>
  );
}

function Group70(props) {
  const { children } = props;

  return (
    <div className="group-">
      <div className="overlap-group-4">
        <div className="students montserrat-medium-blue-zodiac-18px">
          {children}
        </div>
      </div>
    </div>
  );
}

function Group142(props) {
  const { number, group70Props, className } = props;

  return (
    <div className={`group-142 ${className || ""}`}>
      <div className="overlap-group-3">
        <Group70>{group70Props.children}</Group70>
        <div className="number-2 montserrat-semi-bold-black-96px">{number}</div>
      </div>
    </div>
  );
}
const component1Data = {
  line5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/line-5-1@2x.png",
  line6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/line-6@2x.png",
};

const group70Data = {
  children: "Students",
};

const group702Data = {
  children: "Classes",
};

const group703Data = {
  children: "Classrooms",
};

const group142Data = {
  number: "5",
  group70Props: group703Data,
};

const group704Data = {
  children: "Subjects",
};

const group1422Data = {
  number: "2",
  group70Props: group704Data,
};

const TeacherperformanceData = {
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-35@2x.png",
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-453@2x.png",
  vector3:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector4:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-153@2x.png",
  vector5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058d518334660f0a4896cec/img/vector-193@2x.png",
  vector7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector8:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-226@2x.png",
  vector9:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector10:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-228@2x.png",
  vector11:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector12:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598e6a8cdfdd614c2d8d97/img/vector-17@2x.png",
  vector13:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector14:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-230@2x.png",
  vector15:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector16:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-241@2x.png",
  vector17:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-242@2x.png",
  vector18:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector19:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-166@2x.png",
  vector20:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-162@2x.png",
  vector21:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-163@2x.png",
  vector22:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-162@2x.png",
  vector23:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-164@2x.png",
  vector24:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-33@2x.png",
  vector25:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-238@2x.png",
  vector26:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-239@2x.png",
  spanText: "My",
  spanText2: " Analytics",
  classes: "Classes",
  donutChart1:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/602fffcd0392314c332de261/img/donut-chart-1@2x.jpg",
  ellipse5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60587e030ef1c80c1aa270c3/img/ellipse-5@2x.png",
  ellipse6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60587e030ef1c80c1aa270c3/img/ellipse-6@2x.png",
  ellipse7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60587e030ef1c80c1aa270c3/img/ellipse-7@2x.png",
  completed: "Completed",
  scheduled: "Scheduled",
  cancelled: "Cancelled",
  rectangle7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605780123e397393f3ab37fb/img/rectangle-7@1x.png",
  text1: "Assignments & Quizzes",
  text2: (
    <>
      Total Assignments <br />
      Given
    </>
  ),
  totalQuizzesGiven: (
    <>
      Total Quizzes <br />
      Given
    </>
  ),
  number: "4",
  number2: "10",
  vector27:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector28:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-38@2x.png",
  ellipse2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/ellipse-2@2x.png",
  number3: "10",
  number4: "10",
  number5: "3",
  bibaSobti: "Biba Sobti",
  bibasobtiGmailCom: "bibasobti@gmail.com",
  vector29:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-36@2x.png",
  component1Props: component1Data,
  group70Props: group70Data,
  group702Props: group702Data,
  group142Props: group142Data,
  group1422Props: group1422Data,
};

export default Teacherperformance;
