import React from 'react';
import "./popup2.css";

const Popup2 = () => {
    function showpopup() {
        document.getElementById("popupbox").classList.toggle("disp");
        document.getElementById("popup2").classList.toggle("change1");
        document.getElementById("popupbox2").classList.toggle("change2");

        document.getElementById("help-icon").classList.toggle("fa-headset");
        document.getElementById("help-icon").classList.toggle("fa-times");
    }

    function showpopup2() {
        document.getElementById("popupbox2").classList.toggle("disp2");

        document
            .getElementById("support-icon")
            .classList.toggle("fa-question-circle");
        document.getElementById("support-icon").classList.toggle("fa-times");
    }
    
    return (
        <div className="sidepopup-new">
            <button id="popup" onClick={showpopup}>
                <i id="help-icon" className="fas fa-headset"></i>
            </button>
            <div id="popupbox">
                <div className="heading">
                    <p>Help</p>
                </div>
                <div className="list1">
                    <ul
                        className="contact_list"
                        style={{ listStyleType: "none", height: "100%" }}
                    >
                        <li>
                            <p style={{ fontSize: "small" }}>
                                <span style={{ fontWeight: "550", fontSize: "small" }}>Contact 1</span> :
                                9570-424242
                            </p>
                        </li>
                        <li>
                            <p style={{ fontSize: "small" }}>
                                <span style={{ fontWeight: "550", fontSize: "small" }}>Contact 2</span> :
                                74-1919-7878
                            </p>
                        </li>
                        <li>
                            <p style={{ fontSize: "small" }}>
                                <span style={{ fontWeight: "550", fontSize: "small" }}>E-mail</span> :
                                contact@therightguru.com
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <button id="popup2" onClick={showpopup2}>
                <i id="support-icon" className="fas fa-question-circle"></i>
            </button>
            <div id="popupbox2">
                <div className="heading">
                    <p>Support</p>
                </div>
                <div className="list1">
                    <ul
                        className="contact_list"
                        style={{ listStyleType: "none", height: "100%" }}
                    >
                        <li>
                            <p style={{ textAlign: "left"}}>
                                <span style={{ fontWeight: "550" }}>Address</span> :
                                <br />42- SF <br />
                                Omaxe Galleria, <br />
                                Sector 14, Bahadurgrah - 124507 <br />
                                Delhi-NCR
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Popup2;
