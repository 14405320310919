import React, { useLayoutEffect, useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { getStudent } from "../../../redux/actions/student";
import { login } from "../../../crud/auth.crud";
import { setToken } from "../../../utils";
import LoginModal from "../../../components/Login_modal/loginModal";
import CustomNav from "../../../components/header/Navbar";

import Footer from "../../../components/Footer";
import pic_5 from "../../student/dashboard/img/pic_5.png";
import Popup2 from "../../non_auth/new_popup/popup2";
import Accordion from "react-bootstrap/Accordion";
Modal.setAppElement("#root");

export default function Faqs() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [reasonValue, setReasonValue] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);

  const handleLogin = () => {
    setLoading(true);
    setIsErrorShown(false);

    if (!email || !password) {
      setLoading(false);
      setIsErrorShown(true);
    } else {
      login(email, password)
        .then(({ data: { access, refresh, email, user_type } }) => {
          setLoading(false);
          dispatch(getStudent({ access, email, user_type }, history));
          setToken(access, refresh);
          // history.replace("/student-dashboard");
        })
        .catch(() => {
          setLoading(false);
          setIsErrorShown(true);
        });
    }
  };
  return (
    <div style={{ width: "100%" }}>
      {/* <section
        style={{
          width: "100%",
          backgroundColor: "#dc3545",
          display: "flex",
          justifyContent: "center",
          paddingBottom: "1%",
        }}
      >
        <Link to="/">
          <img style={{ height: "150px" }} src={pic_5} alt="" />
        </Link>
      </section> */}
      <CustomNav Open={closeModal} />

      <section style={{ width: "80%", margin: "0 auto" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            textAlign: "justify",
          }}
        >
          <h1
            style={{
              fontSize: "30px",
              fontWeight: "800",

              marginBottom: "2%",
              margin: "5% auto ",
            }}
          >
            Frequently Asked Questions (FAQs)
          </h1>
          <div
            style={{
              fontWeight: "700",
              fontSize: "24px",
              marginBottom: "2%",
              color: "#dc3545",
            }}
          >
            FAQs
          </div>
          <div
            style={{ fontSize: "22", fontWeight: "500", marginBottom: "5%" }}
          >
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingOne"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "rgb(220, 53, 69)", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    What is The Right Guru Institute?
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    The Right Guru Institute is an educational hub that offers
                    online, offline, and home tuition services. We cater to
                    students from classes 6th to 12th and provide coaching for
                    competitive exams like SSC, BANK, and College Entrance exams
                    (CUET).
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingTwo"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    When was The Right Guru Institute established?
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    The Right Guru Institute was established in the year 2021.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingThree"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    What subjects and levels do you cover?
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    We cover subjects for classes 6th to 12th across various
                    educational boards. Additionally, we offer coaching for
                    competitive exams such as SSC, BANK, and College Entrance
                    exams (CUET).
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingfour"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapsefour"
                    aria-expanded="false"
                    aria-controls="flush-collapsefour"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    How can I attend classes at The Right Guru Institute?
                  </button>
                </h2>
                <div
                  id="flush-collapsefour"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingfour"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    You can attend our classes through three modes: online,
                    offline (at our physical location), and home tuition. Choose
                    the mode that suits your learning style and convenience.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingfive"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapsefive"
                    aria-expanded="false"
                    aria-controls="flush-collapsefive"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    Who are the educators at The Right Guru Institute?
                  </button>
                </h2>
                <div
                  id="flush-collapsefive"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingfive"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    Our educators are experienced professionals from esteemed
                    institutions. They bring their expertise to provide quality
                    guidance and mentorship to students.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingSix"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSix"
                    aria-expanded="false"
                    aria-controls="flush-collapseSix"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    How do you ensure personalized learning?
                  </button>
                </h2>
                <div
                  id="flush-collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingSix"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    We focus on each student's unique needs and aspirations,
                    providing personalized attention and support to help them
                    excel in their studies and exams.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingSeven"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSeven"
                    aria-expanded="false"
                    aria-controls="flush-collapseSeven"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    What is the advantage of online learning?
                  </button>
                </h2>
                <div
                  id="flush-collapseSeven"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingSeven"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    Online learning offers flexibility and convenience, allowing
                    you to study from any location. Our platform employs modern
                    tools and technologies to enhance your learning experience.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingEight"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseEight"
                    aria-expanded="false"
                    aria-controls="flush-collapseEight"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    Can I get doubt-solving assistance?
                  </button>
                </h2>
                <div
                  id="flush-collapseEight"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingEight"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    Yes, we offer instant doubt-solving assistance through our
                    platform. You can seek clarification whenever you encounter
                    difficulties in your studies.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingNine"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseNine"
                    aria-expanded="false"
                    aria-controls="flush-collapseNine"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    How can I track my progress?
                  </button>
                </h2>
                <div
                  id="flush-collapseNine"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingNine"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    We provide a performance tracking tool that allows you to
                    monitor your progress. This tool helps you understand your
                    strengths and areas for improvement.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingTen"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTen"
                    aria-expanded="false"
                    aria-controls="flush-collapseTen"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    Is The Right Guru Institute inclusive?
                  </button>
                </h2>
                <div
                  id="flush-collapseTen"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingTen"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    Absolutely. We are committed to providing quality education
                    to all students, bridging gender and social gaps. Our
                    platform is designed to cater to diverse learning needs.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingEleven"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseEleven"
                    aria-expanded="false"
                    aria-controls="flush-collapseEleven"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    How can I enroll in courses at The Right Guru Institute?
                  </button>
                </h2>
                <div
                  id="flush-collapseEleven"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingEleven"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    To enroll, you can visit our website, explore the courses,
                    and follow the enrollment process. You can also contact our
                    support team for guidance.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingtwelve"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapsetwelve"
                    aria-expanded="false"
                    aria-controls="flush-collapsetwelve"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    What sets The Right Guru Institute apart from other
                    educational platforms?
                  </button>
                </h2>
                <div
                  id="flush-collapsetwelve"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingtwelve"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    Our personalized learning approach, experienced faculty,
                    multiple learning modes, and commitment to holistic
                    education make us stand out as a reliable and effective
                    educational partner.
                  </div>
                </div>
              </div>
            </div>
            {/* <span style={{ fontWeight: "600" }}>
              Q1: What is The Right Guru Institute?
            </span>
            <br />
            Ans: The Right Guru Institute is an educational hub that offers
            online, offline, and home tuition services. We cater to students
            from classes 6th to 12th and provide coaching for competitive exams
            like SSC, BANK, and College Entrance exams (CUET).
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q2: When was The Right Guru Institute established?
            </span>
            <br />
            Ans: The Right Guru Institute was established in the year 2021.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q3: What subjects and levels do you cover?
            </span>
            <br />
            Ans: We cover subjects for classes 6th to 12th across various
            educational boards. Additionally, we offer coaching for competitive
            exams such as SSC, BANK, and College Entrance exams (CUET).
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q4: How can I attend classes at The Right Guru Institute?
            </span>
            <br />
            Ans: You can attend our classes through three modes: online, offline
            (at our physical location), and home tuition. Choose the mode that
            suits your learning style and convenience.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q5: Who are the educators at The Right Guru Institute?
            </span>
            <br />
            Ans: Our educators are experienced professionals from esteemed
            institutions. They bring their expertise to provide quality guidance
            and mentorship to students.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q6: How do you ensure personalized learning?
            </span>
            <br />
            Ans: We focus on each student's unique needs and aspirations,
            providing personalized attention and support to help them excel in
            their studies and exams.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q7: What is the advantage of online learning?
            </span>
            <br />
            Ans: Online learning offers flexibility and convenience, allowing
            you to study from any location. Our platform employs modern tools
            and technologies to enhance your learning experience.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q8: Can I get doubt-solving assistance?
            </span>
            <br />
            Ans: Yes, we offer instant doubt-solving assistance through our
            platform. You can seek clarification whenever you encounter
            difficulties in your studies.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q9: How can I track my progress?
            </span>
            <br />
            Ans: We provide a performance tracking tool that allows you to
            monitor your progress. This tool helps you understand your strengths
            and areas for improvement.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q10: Is The Right Guru Institute inclusive?
            </span>
            <br />
            Ans: Absolutely. We are committed to providing quality education to
            all students, bridging gender and social gaps. Our platform is
            designed to cater to diverse learning needs.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q11: How can I enroll in courses at The Right Guru Institute?
            </span>
            <br />
            Ans: To enroll, you can visit our website, explore the courses, and
            follow the enrollment process. You can also contact our support team
            for guidance.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q12: What sets The Right Guru Institute apart from other
              educational platforms?
            </span>
            <br />
            Ans: Our personalized learning approach, experienced faculty,
            multiple learning modes, and commitment to holistic education make
            us stand out as a reliable and effective educational partner.
            <br />
            <br /> */}
          </div>

          <div
            style={{
              fontWeight: "700",
              fontSize: "24px",
              marginBottom: "2%",
              color: "#dc3545",
            }}
          >
            Admission Related FAQs
          </div>
          <div
            style={{ fontSize: "22", fontWeight: "500", marginBottom: "5%" }}
          >
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingthirteen"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "rgb(220, 53, 69)", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapsethirteen"
                    aria-expanded="false"
                    aria-controls="flush-collapsethirteen"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    How can I book a trial class at The Right Guru Institute?
                  </button>
                </h2>
                <div
                  id="flush-collapsethirteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingthirteen"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    To book a trial class, click on the "Book My Class" button,
                    fill in the required details, and submit the form. You'll
                    receive a confirmation email with the scheduled time, date,
                    and a link to join the trial class.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingA"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseA"
                    aria-expanded="false"
                    aria-controls="flush-collapseA"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    What happens after I book a trial class?
                  </button>
                </h2>
                <div
                  id="flush-collapseA"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingA"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    Once you book a trial class, you will receive a confirmation
                    email with details for the scheduled session. Our sales team
                    will also reach out to you to answer any questions and
                    provide additional information.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingB"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseB"
                    aria-expanded="false"
                    aria-controls="flush-collapseB"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    How do I join the trial class online?
                  </button>
                </h2>
                <div
                  id="flush-collapseB"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingB"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    In the confirmation email, you'll find a link to join the
                    trial class online. Simply click on the link at the
                    scheduled time to access the session.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingC"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseC"
                    aria-expanded="false"
                    aria-controls="flush-collapseC"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    Can I reschedule or cancel the trial class booking?
                  </button>
                </h2>
                <div
                  id="flush-collapseC"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingC"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    If you need to reschedule or cancel the trial class, please
                    reach out to our support team through the contact details
                    provided in the confirmation email.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingD"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseD"
                    aria-expanded="false"
                    aria-controls="flush-collapseD"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    How can I enroll in regular classes after the trial?
                  </button>
                </h2>
                <div
                  id="flush-collapseD"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingD"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    After the trial class, our sales team will guide you through
                    the enrollment process. They will provide information about
                    available courses, schedules, and payment options.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingE"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseE"
                    aria-expanded="false"
                    aria-controls="flush-collapseE"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    Can I visit an offline center for admission and inquiries?
                  </button>
                </h2>
                <div
                  id="flush-collapseE"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingE"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    Yes, you can! For offline and home tuition inquiries, feel
                    free to visit our nearest offline center. Our staff will be
                    happy to assist you with the admission process and answer
                    any questions you have.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingF"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseF"
                    aria-expanded="false"
                    aria-controls="flush-collapseF"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    How do I connect with The Right Guru Institute's sales team?
                  </button>
                </h2>
                <div
                  id="flush-collapseF"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingF"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    Our sales team will contact you after you book a trial class
                    or express interest in our courses. They can provide
                    detailed information about courses, fees, and enrollment
                    procedures.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingG"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseG"
                    aria-expanded="false"
                    aria-controls="flush-collapseG"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    Can I call The Right Guru Institute for admission inquiries?
                  </button>
                </h2>
                <div
                  id="flush-collapseG"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingG"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    Absolutely. If you have questions about admissions, courses,
                    or any other inquiries, you can call our support hotline for
                    prompt assistance.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingH"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseH"
                    aria-expanded="false"
                    aria-controls="flush-collapseH"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    What documents do I need for admission?
                  </button>
                </h2>
                <div
                  id="flush-collapseH"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingH"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    The required documents for admission will vary based on the
                    course and level. Our sales team will inform you about the
                    specific documents needed during the admission process.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingI"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseI"
                    aria-expanded="false"
                    aria-controls="flush-collapseI"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    Is there a deadline for admission enrollment?
                  </button>
                </h2>
                <div
                  id="flush-collapseI"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingI"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    Admissions at The Right Guru Institute are ongoing, but
                    certain courses may have limited seats. It's recommended to
                    enroll at the earliest to secure your spot.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingJ"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseJ"
                    aria-expanded="false"
                    aria-controls="flush-collapseJ"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    Can I switch between online, offline, and home tuition modes
                    after admission?
                  </button>
                </h2>
                <div
                  id="flush-collapseJ"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingJ"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    Yes, you can discuss your preferences with our sales team
                    and explore the available learning modes. Flexibility is a
                    key feature of our educational offerings.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingK"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseK"
                    aria-expanded="false"
                    aria-controls="flush-collapseK"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    How soon can I start regular classes after the trial
                    session?
                  </button>
                </h2>
                <div
                  id="flush-collapseK"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingK"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    Depending on course availability, you can often start
                    regular classes immediately after the trial session. Our
                    sales team will provide you with the relevant details.
                  </div>
                </div>
              </div>
            </div>
            {/* <span style={{ fontWeight: "600" }}>
              Q1: How can I book a trial class at The Right Guru Institute?
            </span>
            <br />
            Ans: To book a trial class, click on the "Book My Class" button,
            fill in the required details, and submit the form. You'll receive a
            confirmation email with the scheduled time, date, and a link to join
            the trial class.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q2: What happens after I book a trial class?
            </span>
            <br />
            Ans: Once you book a trial class, you will receive a confirmation
            email with details for the scheduled session. Our sales team will
            also reach out to you to answer any questions and provide additional
            information.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q3: How do I join the trial class online?
            </span>
            <br />
            Ans: In the confirmation email, you'll find a link to join the trial
            class online. Simply click on the link at the scheduled time to
            access the session.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q4: Can I reschedule or cancel the trial class booking?
            </span>
            <br />
            Ans: If you need to reschedule or cancel the trial class, please
            reach out to our support team through the contact details provided
            in the confirmation email.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q5: How can I enroll in regular classes after the trial?
            </span>
            <br />
            Ans: After the trial class, our sales team will guide you through
            the enrollment process. They will provide information about
            available courses, schedules, and payment options.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q6: Can I visit an offline center for admission and inquiries?
            </span>
            <br />
            Ans: Yes, you can! For offline and home tuition inquiries, feel free
            to visit our nearest offline center. Our staff will be happy to
            assist you with the admission process and answer any questions you
            have.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q7: How do I connect with The Right Guru Institute's sales team?
            </span>
            <br />
            Ans: Our sales team will contact you after you book a trial class or
            express interest in our courses. They can provide detailed
            information about courses, fees, and enrollment procedures.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q8: Can I call The Right Guru Institute for admission inquiries?
            </span>
            <br />
            Ans: Absolutely. If you have questions about admissions, courses, or
            any other inquiries, you can call our support hotline for prompt
            assistance.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q9: What documents do I need for admission?
            </span>
            <br />
            Ans: The required documents for admission will vary based on the
            course and level. Our sales team will inform you about the specific
            documents needed during the admission process.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q10: Is there a deadline for admission enrollment?
            </span>
            <br />
            Ans: Admissions at The Right Guru Institute are ongoing, but certain
            courses may have limited seats. It's recommended to enroll at the
            earliest to secure your spot.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q11: Can I switch between online, offline, and home tuition modes
              after admission?
            </span>
            <br />
            Ans: Yes, you can discuss your preferences with our sales team and
            explore the available learning modes. Flexibility is a key feature
            of our educational offerings.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q12: How soon can I start regular classes after the trial session?
            </span>
            <br />
            Ans: Depending on course availability, you can often start regular
            classes immediately after the trial session. Our sales team will
            provide you with the relevant details.
            <br />
            <br /> */}
          </div>

          <div
            style={{
              fontWeight: "700",
              fontSize: "24px",
              marginBottom: "2%",
              color: "#dc3545",
            }}
          >
            Payment Related FAQs
          </div>
          <div
            style={{ fontSize: "22", fontWeight: "500", marginBottom: "5%" }}
          >
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingL"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "rgb(220, 53, 69)", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseL"
                    aria-expanded="false"
                    aria-controls="flush-collapseL"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    How do I make payments for online classes at The Right Guru
                    Institute?
                  </button>
                </h2>
                <div
                  id="flush-collapseL"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingL"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    For online classes, we will send you a link via email to
                    complete the payment process securely online. Follow the
                    link and fill in the required details to finalize your
                    enrollment.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingM"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseM"
                    aria-expanded="false"
                    aria-controls="flush-collapseM"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    What payment methods are accepted for online class payments?
                  </button>
                </h2>
                <div
                  id="flush-collapseM"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingM"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    We accept various online payment methods such as
                    credit/debit cards, net banking, mobile wallets, and UPI.
                    Choose the option that suits you best.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingN"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseN"
                    aria-expanded="false"
                    aria-controls="flush-collapseN"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    Can I pay for offline or home tuition classes online as
                    well?
                  </button>
                </h2>
                <div
                  id="flush-collapseN"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingN"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    Yes, you have the option to pay for offline and home tuition
                    classes online using the same secure payment link sent to
                    you via email.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingO"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseO"
                    aria-expanded="false"
                    aria-controls="flush-collapseO"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    What if I prefer to pay in cash for offline or home tuition
                    classes?
                  </button>
                </h2>
                <div
                  id="flush-collapseO"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingO"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    If you prefer to pay in cash for offline or home tuition
                    classes, you can visit our offline centers and complete the
                    payment at the counter. Our staff will assist you with the
                    process.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingP"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseP"
                    aria-expanded="false"
                    aria-controls="flush-collapseP"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    Is it mandatory to pay the full course fee upfront?
                  </button>
                </h2>
                <div
                  id="flush-collapseP"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingP"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    Payment policies may vary based on the course. Our sales
                    team will provide you with detailed information about
                    payment options, including any installment plans available.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingQ"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseQ"
                    aria-expanded="false"
                    aria-controls="flush-collapseQ"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    Will I receive a payment confirmation after making the
                    payment?
                  </button>
                </h2>
                <div
                  id="flush-collapseQ"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingQ"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    Yes, you will receive a payment confirmation email with
                    details of the transaction once your payment is successfully
                    processed.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingR"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseR"
                    aria-expanded="false"
                    aria-controls="flush-collapseR"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    Can I get a receipt for the payment made?
                  </button>
                </h2>
                <div
                  id="flush-collapseR"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingR"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    Absolutely. We will provide you with a receipt for your
                    payment, which you can keep for your records.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingS"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseS"
                    aria-expanded="false"
                    aria-controls="flush-collapseS"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    What if I face issues while making an online payment?
                  </button>
                </h2>
                <div
                  id="flush-collapseS"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingS"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    If you encounter any issues during the online payment
                    process, please contact our support team immediately. They
                    will assist you in resolving the matter.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingT"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseT"
                    aria-expanded="false"
                    aria-controls="flush-collapseT"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    Is my online payment information secure?
                  </button>
                </h2>
                <div
                  id="flush-collapseT"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingT"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    Yes, we prioritize the security of your payment information.
                    Our payment processing is carried out through secure and
                    trusted platforms, ensuring the safety of your data.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingU"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseU"
                    aria-expanded="false"
                    aria-controls="flush-collapseU"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    Can I switch payment modes after starting the payment
                    process?
                  </button>
                </h2>
                <div
                  id="flush-collapseU"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingU"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    Generally, the payment mode you select during the payment
                    process remains fixed. If you encounter any challenges,
                    contact our support team for guidance.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingV"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545", fontWeight: "600" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseV"
                    aria-expanded="false"
                    aria-controls="flush-collapseV"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    Is there a deadline for completing the payment after
                    receiving the link?
                  </button>
                </h2>
                <div
                  id="flush-collapseV"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingV"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    Payment deadlines may vary based on the course and
                    enrollment date. Our emails will provide you with the
                    necessary information regarding payment timelines.
                  </div>
                </div>
              </div>
            </div>
            {/* <span style={{ fontWeight: "600" }}>
              Q1: How do I make payments for online classes at The Right Guru
              Institute?
            </span>
            <br />
            Ans: For online classes, we will send you a link via email to
            complete the payment process securely online. Follow the link and
            fill in the required details to finalize your enrollment.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q2: What payment methods are accepted for online class payments?
            </span>
            <br />
            Ans: We accept various online payment methods such as credit/debit
            cards, net banking, mobile wallets, and UPI. Choose the option that
            suits you best.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q3: Can I pay for offline or home tuition classes online as well?
            </span>
            <br />
            Ans: Yes, you have the option to pay for offline and home tuition
            classes online using the same secure payment link sent to you via
            email.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q4: What if I prefer to pay in cash for offline or home tuition
              classes?
            </span>
            <br />
            Ans: If you prefer to pay in cash for offline or home tuition
            classes, you can visit our offline centers and complete the payment
            at the counter. Our staff will assist you with the process.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q5: Is it mandatory to pay the full course fee upfront?
            </span>
            <br />
            Ans: Payment policies may vary based on the course. Our sales team
            will provide you with detailed information about payment options,
            including any installment plans available.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q6: Will I receive a payment confirmation after making the
              payment?
            </span>
            <br />
            Ans: Yes, you will receive a payment confirmation email with details
            of the transaction once your payment is successfully processed.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q7: Can I get a receipt for the payment made?
            </span>
            <br />
            Ans: Absolutely. We will provide you with a receipt for your
            payment, which you can keep for your records.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q8: What if I face issues while making an online payment?
            </span>
            <br />
            Ans: If you encounter any issues during the online payment process,
            please contact our support team immediately. They will assist you in
            resolving the matter.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q9: Is my online payment information secure?
            </span>
            <br />
            Ans: Yes, we prioritize the security of your payment information.
            Our payment processing is carried out through secure and trusted
            platforms, ensuring the safety of your data.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q10: Can I switch payment modes after starting the payment
              process?
            </span>
            <br />
            Ans: Generally, the payment mode you select during the payment
            process remains fixed. If you encounter any challenges, contact our
            support team for guidance.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              Q11: Is there a deadline for completing the payment after
              receiving the link?
            </span>
            <br />
            Ans: Payment deadlines may vary based on the course and enrollment
            date. Our emails will provide you with the necessary information
            regarding payment timelines.
            <br />
            <br /> */}
          </div>
        </div>
      </section>

      <section id="footer">
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}
