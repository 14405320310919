import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function AddressInfo() {
  return (
    <Container fluid style={{ width: "90%" }}>
      <h1
        style={{
          fontFamily: "'Montserrat', Arial, Helvetica, sans-serif",
          fontWeight: "700",
          fontSize: "30px",
          textAlign: "center",
          margin: "2% 0 3%",
        }}
      >
        Contact Us
      </h1>
      <Row style={{ paddingTop: "2%" }}>
        <Col md={4} style={{ marginBottom: "20px" }}>
          <div
            className="card"
            style={{ border: "8px solid #dc3545", height: "420px" }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.340469125124!2d76.89113907475912!3d28.679460275639133!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0c7495555555%3A0xad362a9e0dd97cc6!2sThe%20Right%20Guru!5e0!3m2!1sen!2sin!4v1690872261720!5m2!1sen!2sin"
              width="100%"
              height="250"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            <div className="card-body">
              <h5 className="card-title" style={{ fontWeight: 600 }}>
                The Right Guru Head office
              </h5>
              <p className="card-text">
                42-SF Omaxe Galleria, Sector 14, Bahadurgarh, Haryana 124507
              </p>
              <a
                className="btn btn-outline-danger mt-3"
                style={{ fontWeight: 600, marginRight: "20px", height: "auto" }}
                href="tel:+919570424242"
              >
                Call us
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/maps/place/The+Right+Guru/@28.678624,76.893336,14z/data=!4m6!3m5!1s0x390d0c7495555555:0xad362a9e0dd97cc6!8m2!3d28.6794603!4d76.893714!16s%2Fg%2F11rxj4t6g1?hl=en&entry=ttu"
                className="btn btn-outline-danger mt-3"
                style={{ fontWeight: 600 }}
              >
                Map
              </a>
            </div>
          </div>
        </Col>
        <Col md={4} style={{ marginBottom: "20px" }}>
          <div
            className="card"
            style={{ border: "8px solid #dc3545", height: "420px" }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d757.1288328336916!2d77.04720844790369!3d28.594355131414105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1ada8a57cd8f%3A0x62fe73c81b79dbf9!2sPlot%20No.%20-%2012%2C%20HL%20Plaza%2C%20Pocket%202%2C%20Sector%2012%20Dwarka%2C%20MLU%2C%20New%20Delhi%2C%20Delhi%2C%20110075!5e0!3m2!1sen!2sin!4v1690873802811!5m2!1sen!2sin"
              width="100%"
              height="250"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            <div className="card-body">
              <h5 className="card-title" style={{ fontWeight: 600 }}>
                The Right Guru Delhi office
              </h5>
              <p className="card-text">
                311 Vardhman Sunder Plaza, Plot No. 12 MLU Sec - 12 Dwarka, New
                Delhi 110078
              </p>
              <a
                className="btn btn-outline-danger mt-3"
                style={{ fontWeight: 600, marginRight: "20px" }}
                href="tel:+917419197878"
              >
                Call us
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://goo.gl/maps/asAKMWNgNvQe8EgR8"
                className="btn btn-outline-danger mt-3"
                style={{ fontWeight: 600 }}
              >
                Map
              </a>
            </div>
          </div>
        </Col>
        <Col md={4} style={{ marginBottom: "20px" }}>
          <div
            className="card"
            style={{ border: "8px solid #dc3545", height: "420px" }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.3770174707506!2d77.0334451!3d28.618460499999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d05e57e0da7eb%3A0x2c50c3761d6d305f!2sThe%20Right%20Guru%20%7C%20Best%20SSC%20%7C%20SSC%20CGL%20%7C%20SSC%20CHSL%7C%20SSC%20MTS%20%7C%20NDA%20%7C%20CDS%20%7C%20Bank%20%7C%20ENGLISH%20SPOKEN%20CLASSES%20%7C%20Dwarka%20Mor%20110059!5e0!3m2!1sen!2sin!4v1707434697173!5m2!1sen!2sin"
              width="100%"
              height="250"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            <div className="card-body">
              <h5 className="card-title" style={{ fontWeight: 600 }}>
                The Right Guru Dwarka Branch
              </h5>
              <p className="card-text">
                Block C, Sewak Park, Dwarka, Delhi, New Delhi, Delhi, 110059
              </p>

              <a
                className="btn btn-outline-danger mt-3"
                style={{ fontWeight: 600, marginRight: "20px" }}
                href="tel:+919647787878"
              >
                Call us
              </a>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://maps.app.goo.gl/4YDYsvrrWxU4uy426"
                className="btn btn-outline-danger mt-3"
                style={{ fontWeight: 600 }}
              >
                Map
              </a>
            </div>
          </div>
        </Col>
      </Row>
      <Row style={{ paddingTop: "2%" }}>
        <Col md={4} style={{ marginBottom: "20px" }}>
          <div
            className="card"
            style={{ border: "8px solid #dc3545", height: "420px" }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.679728541967!2d77.07022507475608!3d28.609383375677428!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x51ecb333ee176cb%3A0xc46b26d1659e5817!2sThe%20Right%20Guru%20Tuition%20Centre!5e0!3m2!1sen!2sin!4v1690873851120!5m2!1sen!2sin"
              width="100%"
              height="250"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            <div className="card-body">
              <h5 className="card-title" style={{ fontWeight: 600 }}>
                The Right Guru Jankipuri Branch
              </h5>
              <p className="card-text">
                Budh Bazar Rd, Mahavir Enclave Part 3, Mahavir Enclave, New
                Jankipuri, New Delhi Delhi, 110059
              </p>

              <a
                className="btn btn-outline-danger mt-3"
                style={{ fontWeight: 600, marginRight: "20px" }}
                href="tel:+919647787878"
              >
                Call us
              </a>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://goo.gl/maps/asAKMWNgNvQe8EgR8"
                className="btn btn-outline-danger mt-3"
                style={{ fontWeight: 600 }}
              >
                Map
              </a>
            </div>
          </div>
        </Col>
        {/* Add more Col elements for other cards */}
        <Col md={4} style={{ marginBottom: "20px" }}>
          <div
            className="card"
            style={{ border: "8px solid #dc3545", height: "420px" }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.340469125124!2d76.89113907475912!3d28.679460275639133!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0c7495555555%3A0xad362a9e0dd97cc6!2sThe%20Right%20Guru!5e0!3m2!1sen!2sin!4v1690872261720!5m2!1sen!2sin"
              width="100%"
              height="250"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            <div className="card-body">
              <h5 className="card-title" style={{ fontWeight: 600 }}>
                The Right Guru Bahadurgarh Branch
              </h5>
              <p className="card-text">
                Omaxe Galleria, Sector 15, Bahadurgarh, Haryana 124507
              </p>

              <a
                className="btn btn-outline-danger mt-3"
                style={{ fontWeight: 600, marginRight: "20px" }}
                href="tel:+917419197878"
              >
                Call us
              </a>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/maps/place/The+Right+Guru/@28.678624,76.893336,14z/data=!4m6!3m5!1s0x390d0c7495555555:0xad362a9e0dd97cc6!8m2!3d28.6794603!4d76.893714!16s%2Fg%2F11rxj4t6g1?hl=en&entry=ttu"
                className="btn btn-outline-danger mt-3"
                style={{ fontWeight: 600 }}
              >
                Map
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AddressInfo;
