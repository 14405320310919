import React, { useState, useLayoutEffect } from 'react'
import { Link, useHistory } from "react-router-dom";
import Modal from 'react-modal';
import {Button } from 'react-bootstrap';
import { Card, Icon, Image } from 'semantic-ui-react'
import { Form, Message } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { getStudent } from "../../../redux/actions/student";
import { login } from "../../../crud/auth.crud";
import { setToken } from "../../../utils";
import LoginModal from "../../../components/Login_modal/loginModal";

import "./style.css";
import CustomNav from "../../../components/header/Navbar";
import Footer from '../../../components/Footer';
import Popup2 from "../../non_auth/new_popup/popup2";
Modal.setAppElement('#root');

function Facultydetail() {
    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, [])

    const [reasonValue, setReasonValue] = useState(0)

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isErrorShown, setIsErrorShown] = useState(false);
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const history = useHistory();
    const dispatch = useDispatch();

    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, [])

const closeModal = () => setIsModalOpen((previousState) => !previousState);

    // const handleLogin = () => {
    //     setLoading(true);
    //     setIsErrorShown(false);

    //     if(!email || !password) {
    //         setLoading(false);
    //         setIsErrorShown(true);
    //     } else {
    //         login(email, password)
    //             .then(({ data: { access, refresh, email, user_type } }) => {
    //                 setLoading(false);
    //                 dispatch(getStudent({ access, email, user_type }, history));
    //                 setToken(access, refresh);
    //                 // history.replace("/student-dashboard");
    //             })
    //             .catch(() => {
    //                 setLoading(false);
    //                 setIsErrorShown(true);
    //             })
    //     }
    // }


    return (
      <div className="course-details-1">
        <CustomNav Open={closeModal} />

        <div style={{ margin: "50px" }}>
          <h1>ONLINE VS OFFLINE</h1>
        </div>

        <div className="row" style={{ padding: "50px;" }}>
          <div className="col-md-2"></div>
          <div className="content-class col-md-8" style={{ padding: "130px;" }}>
            <p className="text-weight">
              The battle of today's generation with an incredibly common
              question! Which one is best online vs offline education?
            </p>{" "}
            <p>
              {" "}
              Every person is different, and every teaching method does not fit
              for everyone. Therefore, you must take care of ‘what would work
              for you and only you’. The right guru has come with a single
              platform for online and offline classes with a very simple,
              structured and relevant courses for our ambitious and
              non-stoppable learners. <br />
              <br /> But there are few differences you must know about online
              and offline classes. Online education provides flexible learning
              system to the learners via internet on their own computer at home,
              or wherever they want. In offline classes education what is known
              as traditional teaching method, requires physically travel to a
              learning Centre and be present at fixed hours. Online learning
              allows you to learn wherever you want, whenever you want.
              <br />
              <br /> Here few more benefits of online education are: <br />
              <br />
              <ul className="text-weight">
                <li>1. Location doesn’t matter</li>
                <li>2. Flexible hours</li>
                <li>3. Time saving</li>
                <li>4. Cheaper</li>
                <li>5. Convenient</li>
              </ul>
              <br />
              And the right guru provides bundles of benefits as a complement to
              our achievers like: <br />
              <br />
              <ul className="text-weight" style={{ color: "red" }}>
                <li>1. Individual monitoring</li>
                <li>2. Structural and disciplined setting</li>
                <li>3. Face to face interaction</li>
                <li>4. Interpersonal skill development</li>
                <li>5. Competitive atmosphere</li>
              </ul>
              <br /> But somehow, if learners need offline teacher for better
              interaction with teacher and because of any other complications.
              We are present here to uncomplicate your situation and provide
              guidance for your betterment.
              <br />
              <br />
              <br />
              <b className="text-weight" style={{ color: "red" }}>
                ‘It always seems impossible until its done’- Nelson Mandela
              </b>
              <br />
              <br />
              Want to have word with us book a trial class and come in{" "}
              <Link to="/contact-us"> contact us.</Link>
            </p>
          </div>
          <div className="col-md-2"></div>
        </div>

        {/* <section className="regular card">
                <h1>Regular Course</h1>
                <div style={{marginTop: "20px"}}>
                    <p>Starting From :</p>
                    <p>Description :</p>
                    <p>Classes :</p>
                    <p>Subjects :</p>
                    <p>Price :</p>
                </div>
            </section> */}

        {/* <section className="crash card">
                <h1></h1>
                <div style={{marginTop: "20px"}}>
                    <p>Starting From :</p>
                    <p>Description :</p>
                    <p>Classes :</p>
                    <p>Subjects :</p>
                    <p>Price :</p>
                </div>
            </section> */}

        {/* <section className="test card">
                <h1>Test Series</h1>
                <div style={{marginTop: "20px"}}>
                    <p>Starting From :</p>
                    <p>Description :</p>
                    <p>Classes :</p>
                    <p>Subjects :</p>
                    <p>Price :</p>
                </div>
            </section> */}

        <section id="footer">
          <Footer />
        </section>
        <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
        <Popup2 />
        {/* <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          className="login-modal"
        >
          <div className="login-modal-title montserrat-semi-bold-blue-zodiac-18px">
            Student Login
          </div>
          <Form error style={{ width: "70%", margin: "auto" }}>
            {isErrorShown ? (
              <Message
                error
                header="Wrong Credentials"
                content="You entered a wrong email or password."
              />
            ) : (
              <div />
            )}
            <Form.Input
              name="email"
              label="Email"
              placeholder="kunal@therightguru.com"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Input
              label="Password"
              type="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              variant="danger"
              onClick={() => handleLogin()}
              disabled={loading}
            >
              Login
            </Button>
          </Form>
        </Modal> */}
      </div>
    );
}

export default Facultydetail;