// import { ConsoleSqlOutlined } from '@ant-design/icons';
// import { uploadFile } from 'react-s3';

export const S3doubtsconfig = {
         bucketName: "therightguru-website",
         dirName: "doubts",
         region: "ap-south-1",
         accessKeyId: process.env.REACT_APP_AWS_KEY,
         secretAccessKey: process.env.REACT_APP_AWS_SEC_KEY,
       };
