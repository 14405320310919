import React, { useState, useEffect, useLayoutEffect } from "react";
import "./hc_classroom.css";
import SideDrawerHc from "../../../UI/sideDrawer/sideDrawerHc";
import { NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import vector_1_2x from "./img/vector-1@2x.svg";
import line_5_2x from "./img/line-5@2x.svg";
import line_6_2x from "./img/line-6@2x.svg";
import { Button, Form, Col, Table } from "react-bootstrap";
import ClassSharpIcon from "@material-ui/icons/ClassSharp";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { setOptions } from "@mobiscroll/react";
import { Message } from "semantic-ui-react";
//import DatePicker from "react-date-picker";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SchoolIcon from "@material-ui/icons/School";
import pic_2 from "../../student/dashboard/img/pic_2.png";
import {
  HCClassroomList,
  HCCreateClassroom,
} from "../../../crud/highercommittee.crud";

import { DatePicker, TimePicker } from "antd";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";

import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteHc } from "../../../redux/actions/hc";
import DropdownOption from "../dropdown/dropdown";

setOptions({
  theme: "ios",
  themeVariant: "light",
});

const Hc_classroom = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [isErrorShown1, setIsErrorShown1] = useState(false);
  const [classroom, setClassroom] = useState({});
  const [course_id, setCourseName] = useState("");
  const [subject_id, setSubjectName] = useState("");
  const [grade_id, setGradeName] = useState("");
  const [create_course_id, setCourseNameCreate] = useState("");
  const [create_subject_id, setSubjectNameCreate] = useState("");
  const [create_grade_id, setGradeNameCreate] = useState("");
  const [classroom_name, setClassroomName] = useState("");
  const [duration, setDuration] = useState("");
  const [start_date, setStartDate] = useState("");
  const [isAllocatedClassroom, setIsAllocatedClassroom] = useState(false);
  const [chooseDateTime, setChooseDateTime] = useState("");
  const [dateTimeList, setDateTimeList] = useState([]);
  const [teacherId, setTeacherId] = useState("");
  const [trial_date, onChange] = useState(new Date());
  const [dateTimeListGMT, setDateTimeListGMT] = useState([]);
  const [completeDateTimeList, setCompleteDateTimeList] = useState([]);

  const onClickDayTime = (value, event) => {
    setChooseDateTime(moment(value).format("YYYY-MM-DDTHH:mm:ssZ"));
  };

  const onClickStartDate = (value, event) => {
    setStartDate(moment(value).format("YYYY-MM-DD"));
  };

  const onClickDelete = (id) => {
    const newArray = dateTimeList.filter((dateTime) => id !== dateTime);
    setDateTimeList([...newArray]);
    const newArray2 = completeDateTimeList.filter((item) => id !== item[0]);
    setCompleteDateTimeList([...newArray2]);
  };

  const handleSlotSelection = (e) => {
    e.preventDefault();
    if (dateTimeList.includes(chooseDateTime) === true) {
      setIsErrorShown(true);
    } else {
      var local_datetime = chooseDateTime;
      setIsErrorShown(false);
      setIsErrorShown1(false);
      setDateTimeList((oldArray) => [...oldArray, chooseDateTime]);
      var gmt = moment.utc(chooseDateTime).format();
      setCompleteDateTimeList((oldArray) => [...oldArray,[local_datetime, gmt]]);      
    }
    // console.log(dateTimeList);
    // console.log(dateTimeListGMT);
    // console.log(completeDateTimeList);
  };

  const user = useSelector((state) => state.hcDetails.hc);

  const dispatch = useDispatch();

  const handleCourseSelect = (value) => {
    setCourseName(value);
  };

  const handleGradeSelect = (value) => {
    setGradeName(value);
  };

  const handleSubjectSelect = (value) => {
    setSubjectName(value);
  };

  const handleCourseSelectCreate = (value) => {
    setCourseNameCreate(value);
  };

  const handleGradeSelectCreate = (value) => {
    setGradeNameCreate(value);
  };

  const handleSubjectSelectCreate = (value) => {
    setSubjectNameCreate(value);
  };

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  const show = (e) => {
    e.preventDefault();
    document.getElementById("table").style.display = "block";
    HCClassroomList(course_id, grade_id, subject_id)
      .then((res) => {
        setClassroom(res);
      })
      .catch((err) => console.log(err));
  };

  const handleLogout = () => {
    dispatch(deleteHc(user.hc_id));
    removeToken();
  };

  const handleClassroom = (e) => {
    console.log(completeDateTimeList);
    if (dateTimeList.length === 0) {
      setIsErrorShown1(true);
    } else {
      e.preventDefault();
      setIsErrorShown(false);
      setIsErrorShown1(false);
      // for (var i = 0; i < dateTimeList.length; i++) {
      //   // var gmt = moment.utc(dateTimeList[i]).format("YYYY-MM-DD HH:mm:ss");
      //   var gmt = moment.utc(dateTimeList[i]).format();
      //   setDateTimeListGMT((oldArray) => [...oldArray, gmt]);  
      //   setCompleteDateTimeList((oldArray) => [...oldArray, [dateTimeList[i], gmt]]);
      // }
      // console.log(dateTimeList);
      // console.log(dateTimeListGMT);
      // console.log(completeDateTimeList);
      HCCreateClassroom({
        classroomID:
          "CRM" +
          create_grade_id +
          "_" +
          create_subject_id +
          "_" +
          start_date + "_" + classroom_name,
        classroom_strength: "0",
        subjectID: create_subject_id,
        courseID: create_course_id,
        gradeID: create_grade_id,
        classroom_name,
        duration_days: duration,
        teacher_id: teacherId,
        dateTimeList,
        start_date,
        completeDateTimeList
      })
        .then((res) => {
          console.log(res)
          if (res.message == "Classroom created") {
            alert("Classroom Created Successfully!");
            setIsAllocatedClassroom(!isAllocatedClassroom);            
          } else {
            alert("Error! Please check all details are properly filled.")
          }

        })
        .catch((err) => console.log(err));
    }
  };

  const show_mobile = (e) => {
    e.preventDefault();
    document.getElementById("table_mobile").style.display = "block";
    HCClassroomList(course_id, grade_id, subject_id)
      .then((res) => {
        setClassroom(res);
      })
      .catch((err) => console.log(err));
  };

  const checkclick = (day) => {
    var res = document.getElementById("input_time-" + day);
    res.toggleAttribute("disabled");
  };

  const checkclick_mobile = (day) => {
    var res = document.getElementById("input-" + day);
    res.toggleAttribute("disabled");
  };

  return (
    <div>
      <div className="relational-manager-ticket-mobile screen">
        <div className="flex-row-C61RwL">
          <div className="component-1-dXBp89">
            <img src={pic_2} style={{ height: "100px", margin: "-8%" }} />
          </div>
          <SideDrawerHc
            open={displaySideDrawer}
            closeSideDrawer={sideDrawerToggler}
          />
          <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
            <div className="rectangle-48-X4qzjs"></div>
            <div className="rectangle-49-X4qzjs"></div>
            <div className="rectangle-50-X4qzjs"></div>
          </div>
        </div>
        <div className="search_class_mobile" style={{marginTop: "80px"}}>
          <div className="search_text_mobile">Search Classroom</div>
          <div className="classroom_content1_mobile">
            <DropdownOption
              handleCourseSelect={handleCourseSelect}
              handleGradeSelect={handleGradeSelect}
              handleSubjectSelect={handleSubjectSelect}
            />

            <div style={{ margin: "auto" }}>
              <Button
                className="text_mobile"
                onClick={show_mobile}
                variant="danger"
              >
                Search
              </Button>
            </div>
          </div>

          <div id="table_mobile" className="classroom_content2_mobile">
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Classroom Code</th>
                  <th>GradeID</th>
                  <th>Strength</th>
                  <th>Start Date</th>
                  {/* <th>Days</th> */}
                </tr>
              </thead>

              {classroom.length > 0 ? (
                classroom.map((item) => {
                  return (
                    <tbody>
                      <tr>
                        <th>{item && item.classroomID}</th>
                        <th>{item && item.gradeID}</th>
                        <th>{item && item.classroom_strength}</th>
                        <th>{item && item.start_date}</th>
                        {/* <th>{item && item.duration_days}</th> */}
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <tbody>
                  <tr>
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>

          <div
            style={{
              marginTop: "80px",
              borderBottom: "3px solid #ec3545",
              width: "100%",
            }}
          ></div>

          <div className="create_class_mobile">
            <div className="search_text_mobile">Create Classroom</div>
            <div className="classroom_content3_mobile">
              <DropdownOption
                handleCourseSelect={handleCourseSelectCreate}
                handleGradeSelect={handleGradeSelectCreate}
                handleSubjectSelect={handleSubjectSelectCreate}
              />
            </div>

            <div className="classroom_content4_mobile">
              <Form.Group>
                <Form.Row>
                  <Form.Label column lg={3}>
                    Classroom Name
                  </Form.Label>
                  <Col>
                    <Form.Control
                      variant="danger"
                      type="text"
                      onChange={(e) => setClassroomName(e.target.value)}
                    />
                  </Col>
                </Form.Row>
                <br />
                <Form.Row>
                  <Form.Label column lg={3}>
                    Duration in Days
                  </Form.Label>
                  <Col>
                    <Form.Control
                      variant="danger"
                      type="text"
                      onChange={(e) => setDuration(e.target.value)}
                    />
                  </Col>
                </Form.Row>
                <br />

                <Form.Row>
                  <Form.Label column lg={3}>
                    Start Date
                  </Form.Label>
                  <Col className="style-a-xdjkhs-1">
                    <DatePicker
                      className="datePicker-inshc"
                      showNow
                      format="DD-MM-YYYY"
                      onChange={onClickStartDate}
                    />
                  </Col>
                </Form.Row>
                <br />
                <Form.Row>
                  <Form.Label column lg={3}>
                    Teacher ID
                  </Form.Label>
                  <Col>
                    <Form.Control
                      variant="danger"
                      type="text"
                      onChange={(e) => setTeacherId(e.target.value)}
                    />
                  </Col>
                </Form.Row>
                <br />
                <Form.Row>
                  <Form.Label column lg={3}>
                    Date & Time
                  </Form.Label>
                  <Col>
                    {isErrorShown ? (
                      <Message
                        error
                        header="Already Selected |"
                        content=" Choose Different Slot"
                        style={{
                          fontSize: "10px",
                          width: "80%",
                          textAlign: "center",
                        }}
                      />
                    ) : (
                      <p style={{ margin: "0px", padding: "0px" }} />
                    )}

                    <div className="style-a-xdjkhs-1">
                      <DatePicker
                        className="datePicker-inshc"
                        showTime={
                          <TimePicker
                            defaultValue={moment("00:00:00", "HH:mm:ss")}
                          />
                        }
                        showNow
                        format="DD-MM-YYYY HH:mm:ss"
                        onChange={onClickDayTime}
                      />
                      <button
                        onClick={(e) => handleSlotSelection(e)}
                        className="div-btn-anajss01"
                      >
                        Add
                      </button>
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Form.Label column lg={3}>
                    List :
                  </Form.Label>
                  <Col>
                    {isErrorShown1 ? (
                      <Message
                        error
                        header="List Is Empty |"
                        content="Select A Slot"
                        style={{
                          fontSize: "10px",
                          width: "80%",
                          textAlign: "center",
                        }}
                      />
                    ) : (
                      <p style={{ margin: "0px", padding: "0px" }} />
                    )}
                    {dateTimeList.map((dateTime) => {
                      return (
                        <div key={dateTime} className="list-divhc-dajak12">
                          {dateTime}
                          <div>
                            <DeleteIcon
                              onClick={() => onClickDelete(dateTime)}
                              style={{
                                fontSize: "16px",
                                color: "gray",
                                margin: "0px 5px",
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Col>
                </Form.Row>
              </Form.Group>
            </div>
            <div className="create_button_mobile">
              <Button
                className="text_mobile"
                variant="danger"
                onClick={(e) => handleClassroom(e)}
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="relational-manager-ticket-list screen"
        style={{ width: "100%" }}
      >
        <div className="sider-hc-teacher-list">
          <div className="sider-hc-teacher-list-in-wdt-80">
            <NavLink to="/hc-dashboard">
              <DashboardIcon
                data-tip="Dashboard"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/hc-teachers-list">
              {" "}
              <SchoolIcon data-tip="Teacher" className="icon-in-hc-color" />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/hc-suggest-rm">
              <SupervisorAccountIcon
                data-tip="Relational Manager"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/hc-ticket-list">
              <AssignmentIcon
                data-tip="Ticket List"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/hc-payments">
              <AccountBalanceIcon
                data-tip="Payments"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/hc-classroom">
              <ClassSharpIcon
                data-tip="Classroom"
                className="icon-in-hc-color"
                style={{ color: "#dc3545" }}
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/" onClick={() => handleLogout()}>
              <PowerSettingsNewIcon
                data-tip="Logout"
                className="icon-in-hc-color"
              />
            </NavLink>
          </div>
        </div>
        <div className="body_hc_class">
          <div className="heading_hc_class">
            {/* <div className="logo-component"></div> */}
            <img
              alt="image"
              src={pic_2}
              style={{ height: "90px", marginLeft: "-8%" }}
            />

            <div
              style={{
                height: "105px",
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ marginRight: "20px" }}></div>
              <div style={{ marginRight: "20px" }}>
                <div className="biba-sobti-vgli2x">{user && user.first_name} {user && user.last_name}</div>
                {/* <div className="bibasobtigmailcom-vgli2x">
                  bibasobti@gmail.com
                </div> */}
              </div>
              <img src={vector_1_2x} />
            </div>
          </div>

          <div className="search_class">
            <div className="search_text">Search Classroom</div>
            <div className="classroom_content1">
              <div style={{ width: "80%" }}>
                <DropdownOption
                  handleCourseSelect={handleCourseSelect}
                  handleGradeSelect={handleGradeSelect}
                  handleSubjectSelect={handleSubjectSelect}
                />
              </div>

              <Button
                style={{ width: "12%" }}
                onClick={(e) => show(e)}
                variant="danger"
              >
                Search
              </Button>
            </div>
            <div id="table" className="classroom_content2">
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Classroom Code</th>
                    <th>Grade</th>
                    <th>Strength</th>
                    <th>Start Date</th>
                  </tr>
                </thead>
                {classroom.length > 0 ? (
                  classroom.map((item) => {
                    return (
                      <tbody>
                        <tr>
                          <th>{item && item.classroomID}</th>
                          <th>{item && item.gradeID}</th>
                          <th>{item && item.classroom_strength}</th>
                          <th>{item && item.start_date}</th>
                        </tr>
                      </tbody>
                    );
                  })
                ) : (
                  <tbody>
                    <tr>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                    </tr>
                  </tbody>
                )}
              </Table>
            </div>
          </div>
          <div
            style={{
              marginTop: "80px",
              borderBottom: "3px solid #ec3545",
              width: "100%",
            }}
          ></div>
          <div className="create_class">
            <div className="search_text">Create Classroom</div>
            <div className="classroom_content3">
              <DropdownOption
                handleCourseSelect={handleCourseSelectCreate}
                handleGradeSelect={handleGradeSelectCreate}
                handleSubjectSelect={handleSubjectSelectCreate}
              />
            </div>
            <div className="classroom_content4">
              <Form.Group>
                <Form.Row>
                  <Form.Label column lg={3}>
                    Classroom Name
                  </Form.Label>
                  <Col>
                    <Form.Control
                      variant="danger"
                      type="text"
                      onChange={(e) => setClassroomName(e.target.value)}
                    />
                  </Col>
                </Form.Row>
                <br />
                <Form.Row>
                  <Form.Label column lg={3}>
                    Duration in Days
                  </Form.Label>
                  <Col>
                    <Form.Control
                      variant="danger"
                      type="text"
                      onChange={(e) => setDuration(e.target.value)}
                    />
                  </Col>
                </Form.Row>
                <br />

                <Form.Row>
                  <Form.Label column lg={3}>
                    Start Date
                  </Form.Label>
                  <Col className="style-a-xdjkhs-1">
                    <DatePicker
                      className="datePicker-inshc"
                      showNow
                      format="DD-MM-YYYY"
                      onChange={onClickStartDate}
                    />
                  </Col>
                </Form.Row>
                <br />

                <Form.Row>
                  <Form.Label column lg={3}>
                    Teacher ID
                  </Form.Label>
                  <Col>
                    <Form.Control
                      variant="danger"
                      type="text"
                      onChange={(e) => setTeacherId(e.target.value)}
                    />
                  </Col>
                </Form.Row>
                <br />
                <Form.Row>
                  <Form.Label column lg={3}>
                    Date & Time
                  </Form.Label>
                  <Col style={{ paddingRight: "200px" }}>
                    {isErrorShown ? (
                      <Message
                        error
                        header="Already Selected |"
                        content=" Choose Different Slot"
                        style={{
                          fontSize: "10px",
                          width: "80%",
                          textAlign: "center",
                        }}
                      />
                    ) : (
                      <p style={{ margin: "0px", padding: "0px" }} />
                    )}
                    <div className="style-a-xdjkhs-1">
                      <DatePicker
                        className="datePicker-inshc"
                        showTime={
                          <TimePicker
                            defaultValue={moment("00:00:00", "HH:mm:ss")}
                          />
                        }
                        showNow
                        format="DD-MM-YYYY HH:mm:ss"
                        onChange={onClickDayTime}
                      />
                      <button
                        onClick={(e) => handleSlotSelection(e)}
                        className="div-btn-anajss01"
                      >
                        Add
                      </button>
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Form.Label column lg={3}>
                    List :
                  </Form.Label>
                  <Col>
                    {isErrorShown1 ? (
                      <Message
                        error
                        header="List Is Empty |"
                        content="Select A Slot"
                        style={{
                          fontSize: "10px",
                          width: "80%",
                          textAlign: "center",
                        }}
                      />
                    ) : (
                      <p style={{ margin: "0px", padding: "0px" }} />
                    )}
                    {dateTimeList.map((dateTime) => {
                      return (
                        <div key={dateTime} className="list-divhc-dajak12">
                          {dateTime}
                          <DeleteIcon
                            onClick={() => onClickDelete(dateTime)}
                            style={{
                              fontSize: "16px",
                              color: "gray",
                              margin: "0px 5px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      );
                    })}
                  </Col>
                </Form.Row>
              </Form.Group>
            </div>

            <div className="create_button">
              <Button variant="danger" onClick={(e) => handleClassroom(e)}>
                Create
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hc_classroom;
