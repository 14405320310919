import React, { useState, useEffect, Fragment, useLayoutEffect } from "react";
import "./style.css";
import { useSelector } from "react-redux";
import Sider from "../../../UI/Sider/sider";
import Navbar from "../../../UI/Navbar/Navbar";
import StatusComponent from "./StatusComponent/Status";
import CalenderUserComponent from "./CalenderComponent/CalenderUserComponent";
import { upcomingClass } from "../../../crud/teacher.crud";
import Popup from "../popup/popup";
import pic_2 from "../../student/dashboard/img/pic_2.png";

function Teacherdashboard(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const user = useSelector((state) => state.teacherDetails.teacher);
  const [upClass, setUpClass] = useState({});

  useEffect(() => {
    upcomingClass(user.teacher_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setUpClass(false);
        } else setUpClass(res);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Fragment>
      <div className="header-display">
        <Navbar />
      </div>

      <div className="content">
        <div className="Sider-display">
          <Sider />
        </div>

        <StatusComponent upClass={upClass} />

        <CalenderUserComponent />
        <Popup />
        <div className="end-margin-15px" />
      </div>
    </Fragment>
  );
}

export default Teacherdashboard;
