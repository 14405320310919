import React, { useState, useEffect, useLayoutEffect } from "react";
import "./detail_style.css";
import { Link, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import pic_2 from "../dashboard/img/pic_2.png";
import {
  FaRegQuestionCircle,
  FaCalendarAlt,
  FaFileAlt,
  FaChartPie,
  FaStickyNote,
  FaPowerOff,
} from "react-icons/fa";
import { MdAssignment, MdDashboard, MdNotificationsNone } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import Popup from "../../teacher/popup/popup";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteStudent } from "../../../redux/actions/student";
// images
import line5_2x from "./img/line-5@2x.png";
import line5_1_2x from "./img/line-5-1@2x.png";
import line6_2x from "./img/line-6@2x.png";
import line6_1_2x from "./img/line-6-1@2x.png";
import component_32 from "./img/component-32@1x.png";
import component_5 from "./img/component-5@1x.png";
import vector_2x from "./img/vector@2x.png";
import vector_1_2x from "./img/vector-1@2x.png";
import vector_5_2x from "./img/vector-5@2x.png";
import rectangle_7 from "./img/rectangle-7@1x.png";
import ellipse_2_2x from "./img/ellipse-2@2x.png";
import {
  doubtDetail,
  createDoubtResponse,
  doubtLike,
  resolveDoubt,
} from "../../../crud/student.crud";

import SideDrawerStudent from "../../../UI/sideDrawer/sideDrawerStudent";

function Studentdoubtsdetail(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const user = useSelector((state) => state.studentDetails.student);
  const [doubt, setDoubt] = useState(null);
  const [doubtResponse, setDoubtResponse] = useState("");
  const [commented, setCommented] = useState(false);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [displayLikeButton, setDisplayLikeButton] = useState(false);

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  useEffect(() => {
    doubtDetail(id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setDoubt(false);
        } else {
          setDoubt(res);
          console.log(doubt);
        }
      })
      .catch((err) => console.log(err));
  }, [commented, isLiked]);
  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteStudent(reduxUser.enrolled_student_id));
    removeToken();
  };

  const handleLikeClick = (doubt_response_id) => {
    doubtLike({
      doubt_response_id,
      object_id: user.enrolled_student_id,
      isLike: "true",
    })
      .then((res) => {
        alert("Action completed.");
        setIsLiked(!isLiked);
        setDisplayLikeButton((previousState) => !previousState);
      })
      .catch((err) => console.log(err));
  };

  const handleResponseSubmit = () => {
    createDoubtResponse({
      userId: user.enrolled_student_id,
      doubtId: doubt.doubt.id,
      text: doubtResponse,
    })
      .then((res) => {
        setCommented(!commented);
        setDoubtResponse("");
        document.getElementById("commentInput").value = "";
        document.getElementById("commentInputMobile").value = "";
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <form
        className="student-doubts-detail-mobile screen"
        name="form1"
        action="form1"
        method="post"
      >
        <div className="component-1-C61RwL">
          <img
            alt="image"
            src={pic_2}
            style={{ height: "90px", marginTop: "-8%" }}
          />
        </div>

        {/* This is tha hamburder icon  */}
        <SideDrawerStudent
          open={displaySideDrawer}
          closeSideDrawer={sideDrawerToggler}
        />

        <div className="component-6-C61RwL" onClick={sideDrawerToggler}>
          <div className="rectangle-48-5mfU7d"></div>
          <div className="rectangle-49-5mfU7d"></div>
          <div className="rectangle-50-5mfU7d"></div>
        </div>
        <a href="javascript:history.back()">
          <div className="solve-doubts-C61RwL">
            <span className="span0-z3pWM6" style={{ color: "#dc3545" }}>
              Solve{" "}
            </span>
            <span className="span1-z3pWM6" style={{ color: "#dc3545" }}>
              Doubts
            </span>
          </div>
        </a>
        <img className="component-32-C61RwL" src={component_32} />
        <div className="component-33-C61RwL"></div>
        <div className="active-C61RwL">{doubt && doubt.doubt.doubt_status}</div>
        <div className="vatsal-agarwal-C61RwL" style={{ color: "#dc3545" }}>
          {doubt && doubt.enrolled_student}
        </div>
        <div
          className="doubt-card-in-comment"
          style={{ overflowY: "scroll", overflowX: "hidden", height: "300px" }}
        >
          {doubt && doubt.doubt_response && doubt.doubt_response.length > 0 ? (
            doubt.doubt_response.map((res) => {
              return (
                <div
                  className="doubt-card-in-comment-in"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "360px",
                    height: "auto",
                  }}
                >
                  <div
                    className="kunal-kashyap-C61RwL montserrat-semi-bold-blue-zodiac-14px"
                    style={{ color: "#000" }}
                  >
                    {res.each_doubt_response.object_id}
                  </div>
                  <div>
                    <div
                      className="it-is-from-unit-1-AwXtS8-in montserrat-medium-blue-zodiac-10px"
                      style={{
                        marginRight: 0,
                        maxWidth: "360px",
                        paddingRight: "5px",
                        wordBreak: "break-all",
                      }}
                    >
                      {res.each_doubt_response.doubt_response_text}
                    </div>
                  </div>
                  {res.likes_data.filter(
                    (each_like) =>
                      each_like.object_id === user.enrolled_student_id &&
                      each_like.isLike
                  ).length > 0 ? (
                    <div className="like_button Color_dc3545">
                      <ThumbUpIcon
                        onClick={() =>
                          handleLikeClick(res.each_doubt_response.id)
                        }
                      />{" "}
                      <p style={{ marginLeft: "5px" }}>
                        {res.each_doubt_response.like}
                      </p>
                    </div>
                  ) : (
                    <div className="like_button Color_grey">
                      <ThumbUpIcon
                        onClick={() =>
                          handleLikeClick(res.each_doubt_response.id)
                        }
                      />{" "}
                      <p style={{ marginLeft: "5px" }}>
                        {res.each_doubt_response.like}
                      </p>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div>No Comments</div>
          )}
        </div>
        <input
          id="commentInputMobile"
          className="group-78-C61RwL"
          name="6392265"
          placeholder="Solve this doubt"
          onChange={(e) => setDoubtResponse(e.target.value)}
          type="text"
        />
        <div
          className="biba-sobti-C61RwL montserrat-semi-bold-blue-zodiac-14px"
          style={{ color: "#000" }}
        >
          {user && user.first_name} {user && user.last_name}
        </div>
        <div className="what-is-euclid-lemma-C61RwL">
          {doubt && doubt.doubt.doubt_text}
        </div>
        <Button
          variant="outline-danger"
          className="rectangle-41-PXwdVf p-0"
          style={{
            fontFamily: "'Montserrat', Helvetica",
            fontSize: 12,
            fontWeight: 600,
          }}
          onClick={() => window.open(doubt && doubt.doubt.doubt_url, "_blank")}
          disabled={doubt && doubt.doubt.doubt_url === ""}
        >
          View
        </Button>
        {
          user.enrolled_student_id === (doubt && doubt.doubt.studentID) ? 
            <Button
              variant="outline-success"
              className="rectangle-41-PXwdVf-2"
              style={{
                fontFamily: "'Montserrat', Helvetica",
                fontSize: 12,
                fontWeight: 600,
                marginLeft: 200,
              }}
              onClick={() =>
                resolveDoubt({ ...doubt.doubt, doubt_status: "Solved" })
              }
            >
              Mark as solved
            </Button>
            : <div></div>
        }
        <Button
          disabled={doubtResponse === ""}
          onClick={() => handleResponseSubmit()}
          className="group-55-C61RwL"
          style={{ backgroundColor: "#dc3545" }}
        >
          <div className="post-4W6TZx">Post</div>
        </Button>
        <a href="javascript:history.back()">
          <div className="arrowback-24px-1-C61RwL">
            <img className="vector-ec7mDZ" src={vector_2x} />
            <img className="vector-wEJYwt" src={vector_1_2x} />
          </div>
        </a>
        <div className="grade-subject-C61RwL">
          {doubt && doubt.doubt.grade_id} - {doubt && doubt.doubt.subject_id}
        </div>
        <div className="date-C61RwL">
          {doubt && moment(doubt.doubt.date).format("MMMM Do, YYYY")}
        </div>
      </form>
      <form
        className="student-doubts-detail screen"
        name="form2"
        action="form2"
        method="post"
      >
        <div className="student-doubts-detail-col">
          <div className="student-re-flex">
            <div className="component-1-C61RwLz-3">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "115px", marginTop: "-8%" }}
              />
            </div>
            <div className="group-22-GyJUNH-student-dash">
              <div className="flex-col-vgli2x-student-dash">
                <div className="biba-sobti-h8V8ZR-student-dash">
                  {user.first_name + " " + user.last_name}
                </div>
                <div className="bibasobtigmailcom-h8V8ZR-student-dash">
                  {user && user.email}
                </div>
              </div>
              <img className="vector-vgli2x-student-dash" src={vector_5_2x} />
            </div>
          </div>

          <img className="rectangle-7-C61RwL" src={rectangle_7} />

          <div className="solve-doubts-C61RwL">
            <span className="span0-z3pWM6" style={{ color: "#dc3545" }}>
              Solve
            </span>
            <span className="span1-z3pWM6" style={{ color: "#dc3545" }}>
              {" "}
              Doubts
            </span>
          </div>
          <div className="doubt-detail-row-1">
            <div className="grade-subject-C61RwL">
              {doubt && doubt.doubt.grade_id} -{" "}
              {doubt && doubt.doubt.subject_id}
            </div>
            <div className="date-C61RwL">
              {doubt && moment(doubt.doubt.date).format("MMMM Do, YYYY")}
            </div>
          </div>

          <img className="component-5-C61RwL" src={component_5} />
          <div className="component-4-C61RwL"></div>
          <div className="active-C61RwL">
            {doubt && doubt.doubt.doubt_status}
          </div>

          <div className="vatsal-agarwal-C61RwL" style={{ color: "#dc3545" }}>
            {doubt && doubt.enrolled_student}
          </div>
          <div className="what-is-euclid-lemma-C61RwL montserrat-medium-black-18px">
            {doubt && doubt.doubt.doubt_text}
          </div>

          <Button
            variant="outline-danger"
            className="rectangle-41-PXwdVf"
            style={{
              fontFamily: "'Montserrat', Helvetica",
              fontSize: 12,
              fontWeight: 600,
            }}
            onClick={() =>
              window.open(doubt && doubt.doubt.doubt_url, "_blank")
            }
            disabled={doubt && doubt.doubt.doubt_url === ""}
          >
            View
          </Button>
          {
            user.enrolled_student_id === (doubt && doubt.doubt.studentID) ?
              <Button
                variant="outline-success"
                className="rectangle-41-PXwdVf-2"
                style={{
                  fontFamily: "'Montserrat', Helvetica",
                  fontSize: 12,
                  fontWeight: 600,
                }}
                onClick={() =>
                  resolveDoubt({ ...doubt.doubt, doubt_status: "Solved" })
                }
              >
                Mark as solved
              </Button>
              : <div></div>
          }
          <input
            id="commentInput"
            className="group-50-C61RwL"
            name="6392265"
            placeholder="Solve this doubt"
            onChange={(e) => setDoubtResponse(e.target.value)}
            type="text"
          />
          <div
            className="biba-sobti-C61RwL montserrat-semi-bold-blue-zodiac-20px"
            style={{ color: "#000" }}
          >
            {user && user.first_name} {user && user.last_name}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              top: "488px",
              left: "12%",
              position: "absolute",
              height: "300px",
              overflowY: "scroll",
              overflowX: "hidden",
              width: "74%",
            }}
          >
            {doubt &&
            doubt.doubt_response &&
            doubt.doubt_response.length > 0 ? (
              doubt.doubt_response.map((res) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "auto",
                      marginBottom: "24px",
                      marginTop: "24px",
                      width: "1100px",
                    }}
                  >
                    <div
                      className="kunal-kashyap-C61RwL montserrat-semi-bold-blue-zodiac-20px"
                      style={{ color: "#000" }}
                    >
                      {res.each_doubt_response.object_id}
                    </div>
                    <div>
                      <div
                        className="it-is-from-unit-1-zA4C22 montserrat-medium-black-18px"
                        style={{
                          marginRight: 0,
                          maxWidth: "1100px",
                          paddingRight: "10px",
                          wordBreak: "break-all",
                        }}
                      >
                        {res.each_doubt_response.doubt_response_text}
                      </div>
                    </div>
                    {res.likes_data.filter(
                      (each_like) =>
                        each_like.object_id === user.enrolled_student_id &&
                        each_like.isLike
                    ).length > 0 ? (
                      <div className="like_button Color_dc3545">
                        <ThumbUpIcon
                          onClick={() =>
                            handleLikeClick(res.each_doubt_response.id)
                          }
                        />{" "}
                        <p style={{ marginLeft: "5px" }}>
                          {res.each_doubt_response.like}
                        </p>
                      </div>
                    ) : (
                      <div className="like_button Color_grey">
                        <ThumbUpIcon
                          onClick={() =>
                            handleLikeClick(res.each_doubt_response.id)
                          }
                        />{" "}
                        <p style={{ marginLeft: "5px" }}>
                          {res.each_doubt_response.like}
                        </p>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div></div>
            )}
          </div>

          <Button
            disabled={doubtResponse === ""}
            onClick={() => handleResponseSubmit()}
            className="group-55-C61RwL"
            style={{ backgroundColor: "#dc3545" }}
          >
            <div className="post-4W6TZx">Post</div>
          </Button>
        </div>
        <div className="rectangle-24-C61RwL-stu-s1">
          <Link to="/student-dashboard">
            <div data-tip="Dashboard">
              <MdDashboard size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-date-calendar">
            <div data-tip="Date Calendar">
              <FaCalendarAlt size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-assignment-list">
            <div data-tip="Assignments">
              <MdAssignment size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-quiz-list">
            <div data-tip="Quizzes">
              <RiQuestionnaireFill size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-curriculum-list">
            <div data-tip="Curriculums">
              <FaFileAlt size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-doubt-list">
            <div data-tip="Doubts">
              <FaRegQuestionCircle size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-performance" data-tip="Performance">
            <div>
              <FaChartPie size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-notes-list">
            <div data-tip="Lecture Notes">
              <FaStickyNote size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/" onClick={() => handleLogout()}>
            <FaPowerOff
              size={24}
              data-tip="Logout"
              color="rgba(179,179,179,1.0)"
            />
            <ReactTooltip />
          </Link>
        </div>
      </form>
      <Popup />
    </>
  );
}

export default Studentdoubtsdetail;
