import React from 'react'
import ReactDOM from 'react-dom'
import "./App.css";
import "antd/dist/antd.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import PrivateRoute from "./components/privateRoute";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";

import WebSocketProvider from "./websocket2";

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// pick a date util library
import MomentUtils from '@date-io/moment';

// PAGES
// Student
import StudentDashboard from "./pages/student/dashboard/index";
import StudentDateCalendar from "./pages/student/date_calendar/index";
import StudentTimeCalendar from "./pages/student/time_calendar/index";
import StudentPerformance from "./pages/student/performance/index";
import StudentEnrolledClasses from "./pages/student/enrolled_classes/index";
import StudentCurriculumList from "./pages/student/curriculum/index";
import StudentAssignmentList from "./pages/student/assignment/list";
import StudentAssignmentDetail from "./pages/student/assignment/detail";
import StudentQuizList from "./pages/student/quiz/list";
import StudentQuizDetail from "./pages/student/quiz/detail";
import StudentDoubtList from "./pages/student/doubt/list";
import StudentDoubtDetail from "./pages/student/doubt/detail";
import StudentNotesList from "./pages/student/notes/list";
import StudentNotesDetail from "./pages/student/notes/detail";
import StudentLiveClassRating from "./pages/student/rating/live_class_rating";
import StudentTrialClassRating from "./pages/student/rating/trial_class_rating";
// Teacher
import TeacherDashboard from "./pages/teacher/dashboard/index";
import TeacherPreviousClasses from "./pages/teacher/previous_classes/index";
import TeacherDateCalendar from "./pages/teacher/date_calendar/index";
import TeacherTimeCalendar from "./pages/teacher/time_calendar/index";
import TeacherPayments from "./pages/teacher/payments/index";
import TeacherStudents from "./pages/teacher/students_list/index";
import TeacherAnalytics from "./pages/teacher/analytics/index";
import TeacherAssignmentStudentsClasrooms from "./pages/teacher/assignment/teacher_classroom_list";
import TeacherStudentAssignmentList from "./pages/teacher/assignment/teacher_assignment_list";
import TeacherClassroomAssignmentList from "./pages/teacher/assignment/teacher_classroom_assignment_list";
import TeacherClassroomQuizList from "./pages/teacher/quiz/teacher_classroom_quiz_list";
import TeacherStudentAssignmentDetail from "./pages/teacher/assignment/teacher_assignment_detail";
import TeacherQuizStudentsClasrooms from "./pages/teacher/quiz/teacher_classroom_list";
import TeacherAssignmentGroupClassroom from "./pages/teacher/assignment/group_classroom";
import TeacherQuizGroupClassroom from "./pages/teacher/quiz/group_classroom";
import TeacherStudentQuizList from "./pages/teacher/quiz/teacher_quiz_list";
import TeacherStudentQuizDetail from "./pages/teacher/quiz/teacher_quiz_detail";
import TeacherCurriculumList from "./pages/teacher/curriculum/index";
import TeacherLectureNotes from "./pages/teacher/lecture_notes/index";
import TeacherDoubtDetail from "./pages/teacher/doubt/detail";
import TeacherDoubtList from "./pages/teacher/doubt/list";
import TeacherClassroom from "./pages/teacher/classroom/classroom";
import TeacherLiveClassRating from "./pages/teacher/rating/live_class_rating";
import StudentTeacherAssignment from "./pages/teacher/student_Performance/Performance";

// Relational Manager
import RMDashboard from "./pages/relational_manager/dashboard/index";
import RMDateCalendar from "./pages/relational_manager/date_calendar/index";
import RMTimeCalendar from "./pages/relational_manager/time_calendar/index";
import RMStatistics from "./pages/relational_manager/statistics/index";
import RMPayments from "./pages/relational_manager/payments/index";
import RMList from "./pages/relational_manager/list/index";
import RMIssueDetail from "./pages/relational_manager/issue-detail/index";
import RMTicketList from "./pages/relational_manager/ticket_list/index";
import RMIssueList from "./pages/relational_manager/issue_list/index";

//Sales
import SalesDashboard from "./pages/sales/dashboard/index";
import SalesStudentDetail from "./pages/sales/student_details/form1";
import SalesPayments from "./pages/sales/payments";
import SalesMyStudents from "./pages/sales/my_students";
import SalesStatistics from "./pages/sales/statistics/index";
import SalesTrialStudentForm from "./pages/sales/sales_trial_student_form/App";

// Non Auth
import Landing from "./pages/no_auth/landing";
import EmployeeLogin from "./pages/employee_login";
import TrialStudentForm from "./pages/non_auth/trial_student/App";
import TeacherRegisterForm from "./pages/non_auth/teacher_register/App";
import CourseDetails from "./pages/non_auth/course_details/coursedetail";
import FacultyDetails from "./pages/non_auth/faculty_details/facultydetail";
import EnrolledStudentForm from "./pages/non_auth/class_booking/class_booking";
import StudyMaterial from "./pages/non_auth/study_material/index";
import HealthRecreation from "./pages/non_auth/health_recreation/index";
import ContactUs from "./pages/non_auth/contact_us/index";
import StudyMaterialRedirect from "./pages/non_auth/study_material/redirect";
import EnrolledStudentFormOffline from "./pages/non_auth/cash_payment/cashpayment";
import ForgetPassword from "./pages/forget_password/index";
import Privacy from "./pages/non_auth/privacy";
import TnC from "./pages/non_auth/tnc";
import AddMaterial from "./pages/add_material";
import NewCreds from "./pages/new_creds";
import Faqs from './pages/non_auth/footer_page/Faqs';
import AboutUs from './pages/non_auth/footer_page/AboutUs';
import OurToppers from './pages/no_auth/landing/OurToppers';
import TrgToppers from './pages/no_auth/landing/TrgToppers';

//higher committee
import HcDetailView from "./pages/higher_committee/hc_detail_view/hc_detail_view";
import HcTicketList from "./pages/higher_committee/ticket_list";
import HcPayments from "./pages/higher_committee/payments";
import HcStudentDetail from "./pages/higher_committee/student_details/form1";
import HcTicketDetail from "./pages/higher_committee/ticket_detail";
import HcNewStudentList from "./pages/higher_committee/new_student_list";
import TeachersList from "./pages/higher_committee/teacher_list/teachers_list";
import SuggestRm from "./pages/higher_committee/suggest_rm/suggest_rm";
import HcClassroom from "./pages/higher_committee/hc_classroom/hc_classroom";


// Accounts
import AccountStudents from "./pages/accounts/student";
import AccountsEmployee from "./pages/accounts/employee";
import AccountsDashboard from "./pages/accounts/dashboard";
import AddFolder from "./pages/add_folder";



// courses
import Courses from './pages/non_auth/courses/Courses';
import Cbse_Icse from './pages/non_auth/courses/Cbse_Icse';
import Cuet from './pages/non_auth/courses/Cuet';
import Ssc from './pages/non_auth/courses/Ssc';
import Clat from './pages/non_auth/courses/Clat';
import Dsssb from './pages/non_auth/courses/Dsssb';
import Other_Cs from './pages/non_auth/courses/Other_Cs';
import Intpro from './pages/non_auth/footer_page/Intpro';
import TrgFranchise from './pages/non_auth/footer_page/TrgFranchise';
import EngSpeak from './pages/non_auth/courses/EngSpeak';
import Career from './pages/non_auth/footer_page/Career';
import LoginModal from './components/Login_modal/loginModal';
import NdaCds from './pages/non_auth/courses/NdaCds';
import ActurialScience from './pages/non_auth/courses/ActurialScience';
import GreGmatSat from './pages/non_auth/courses/GreGmatSat';
import PoliceServices from './pages/non_auth/courses/PoliceServices';





function App() {
  return (
    <Provider store={store}>
      <WebSocketProvider>
        <Router>
          <PersistGate persistor={persistor}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <div className="App">
                <Switch>
                  <Route exact path="/">
                    <Landing />
                  </Route>
                  <Route exact path="/privacy">
                    <Privacy />
                  </Route>
                  <Route exact path="/tnc">
                    <TnC />
                  </Route>
                  <Route path="/trial-student-form">
                    <TrialStudentForm />
                  </Route>
                  <Route path="/login">
                    <LoginModal/>
                  </Route>
                  <Route path="/new-credentials">
                    <TeacherRegisterForm />
                  </Route>
                  <Route path="/enrolled-student-form/:trialStudentId/:amount">
                    <EnrolledStudentForm />
                  </Route>
                  <Route path="/enrolled-student-form-offline">
                    <EnrolledStudentFormOffline />
                  </Route>
                  <Route path="/aboutus">
                    <AboutUs />
                  </Route>
                  <Route path="/career" component={Career} />
                  <Route path="/internship-programme">
                    <Intpro />
                  </Route>
                  <Route path="/trg-franchise">
                    <TrgFranchise />
                  </Route>
                  <Route path="/faqs" component={Faqs} />
                  <Route path="/course-details">
                    <CourseDetails />
                  </Route>
                  <Route path="/our-toppers" component={OurToppers} />
                  <Route path="/courses" component={Courses} />
                  <Route path="/cbse-icse" component={Cbse_Icse} />
                  <Route path="/cuet" component={Cuet} />
                  <Route path="/ssc" component={Ssc} />
                  <Route path="/clat" component={Clat} />
                  <Route path="/dsssb" component={Dsssb} />
                  <Route path="/ndacds" component={NdaCds} />
                  <Route path="/acturial-science" component={ActurialScience} />
                  <Route path="/gre-gmat-sat" component={GreGmatSat} />
                  <Route path="/police-services" component={PoliceServices} />
                  
                  <Route path="/english-speaking" component={EngSpeak} />
                  <Route path="/other-cs" component={Other_Cs} />
                  

                  <Route path="/trg-toppers" component={TrgToppers} />

                  <Route path="/faculty-details">
                    <FacultyDetails />
                  </Route>
                  {/* <Route exact path="/study-material">
                    <StudyMaterial />
                  </Route> */}
                  <Route path="/health-recreation">
                    <HealthRecreation />
                  </Route>
                  <Route path="/contact-us">
                    <ContactUs />
                  </Route>
                  {/* <Route
                    path="/study-material/:material_type"
                    component={() => <StudyMaterialRedirect type="Books" />}
                  /> */}
                  {/* <Route path="/study-material/:material_type">
                    <StudyMaterialRedirect />
                  </Route> */}
                  <Route path="/forgot-password">
                    <ForgetPassword />
                  </Route>
                  <Route path="/add-material">
                    <AddMaterial />
                  </Route>
                  <Route path="/add-folder">
                    <AddFolder />
                  </Route>
                  {/* <Route path="/new-credentials">
                    <NewCreds />
                  </Route> */}
                  <Route
                    path="/teacher-login"
                    component={() => <EmployeeLogin type="teacher" />}
                  />
                  <Route
                    path="/rm-login"
                    component={() => <EmployeeLogin type="rm" />}
                  />
                  <Route
                    path="/sales-login"
                    component={() => <EmployeeLogin type="sales" />}
                  />
                  <Route
                    path="/admin-login"
                    component={() => <EmployeeLogin type="admin" />}
                  />
                  <Route
                    path="/hc-login"
                    component={() => <EmployeeLogin type="hc" />}
                  />
                  <Route
                    path="/accounts-login"
                    component={() => <EmployeeLogin type="accounts" />}
                  />

                  <PrivateRoute path="/student-dashboard" type="student">
                    <StudentDashboard />
                  </PrivateRoute>
                  <PrivateRoute path="/student-date-calendar" type="student">
                    <StudentDateCalendar />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/student-time-calendar/:date/:teacher"
                    type="student"
                  >
                    <StudentTimeCalendar />
                  </PrivateRoute>
                  <PrivateRoute path="/student-performance" type="student">
                    <StudentPerformance />
                  </PrivateRoute>
                  <PrivateRoute path="/student-enrolled-classes" type="student">
                    <StudentEnrolledClasses />
                  </PrivateRoute>
                  <PrivateRoute path="/student-curriculum-list" type="student">
                    <StudentCurriculumList />
                  </PrivateRoute>
                  <PrivateRoute path="/student-assignment-list" type="student">
                    <StudentAssignmentList />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/student-assignment-detail/:id"
                    type="student"
                  >
                    <StudentAssignmentDetail />
                  </PrivateRoute>
                  <PrivateRoute path="/student-quiz-list" type="student">
                    <StudentQuizList />
                  </PrivateRoute>
                  <PrivateRoute path="/student-quiz-detail/:id" type="student">
                    <StudentQuizDetail />
                  </PrivateRoute>
                  <PrivateRoute path="/student-doubt-list" type="student">
                    <StudentDoubtList />
                  </PrivateRoute>
                  <PrivateRoute path="/student-doubt-detail/:id" type="student">
                    <StudentDoubtDetail />
                  </PrivateRoute>
                  <PrivateRoute path="/student-notes-list" type="student">
                    <StudentNotesList />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/student-notes-detail/:teacherId"
                    type="student"
                  >
                    <StudentNotesDetail />
                  </PrivateRoute>
                  <Route path="/student-live-class-rating/:classId">
                    <StudentLiveClassRating />
                  </Route>
                  <Route path="/student-trial-class-rating/:studentId/:classId">
                    <StudentTrialClassRating />
                  </Route>

                  <PrivateRoute path="/teacher-dashboard" type="teacher">
                    <TeacherDashboard />
                  </PrivateRoute>
                  <PrivateRoute path="/teacher-previous-classes" type="teacher">
                    <TeacherPreviousClasses />
                  </PrivateRoute>
                  <PrivateRoute path="/teacher-date-calendar" type="teacher">
                    <TeacherDateCalendar />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/teacher-time-calendar/:date"
                    type="teacher"
                  >
                    <TeacherTimeCalendar />
                  </PrivateRoute>
                  <PrivateRoute path="/teacher-payments" type="teacher">
                    <TeacherPayments />
                  </PrivateRoute>
                  <PrivateRoute path="/teacher-students" type="teacher">
                    <TeacherStudents />
                  </PrivateRoute>
                  <PrivateRoute path="/teacher-analytics" type="teacher">
                    <TeacherAnalytics />
                  </PrivateRoute>
                  <PrivateRoute path="/teacher-assignments" type="teacher">
                    <TeacherAssignmentStudentsClasrooms />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/teacher-assignments-student-list/:studentId"
                    type="teacher"
                  >
                    <TeacherStudentAssignmentList />
                  </PrivateRoute>
                  <PrivateRoute path="/teacher-assignments-classroom-list/:classroomID">
                    <TeacherClassroomAssignmentList />
                  </PrivateRoute>
                  <PrivateRoute path="/teacher-quizzes-classroom-list/:classroomID">
                    <TeacherClassroomQuizList />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/teacher-assignments-student-detail/:id"
                    type="teacher"
                  >
                    <TeacherStudentAssignmentDetail />
                  </PrivateRoute>
                  <PrivateRoute path="/teacher-group-classroom/:classroomID/:group_id">
                    <TeacherAssignmentGroupClassroom />
                  </PrivateRoute>
                  <PrivateRoute path="/teacher-quiz-group-classroom/:classroomID/:group_id">
                    <TeacherQuizGroupClassroom />
                  </PrivateRoute>
                  <PrivateRoute path="/teacher-quizzes" type="teacher">
                    <TeacherQuizStudentsClasrooms />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/teacher-quiz-student-list/:studentId"
                    type="teacher"
                  >
                    <TeacherStudentQuizList />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/teacher-quiz-student-detail/:id"
                    type="teacher"
                  >
                    <TeacherStudentQuizDetail />
                  </PrivateRoute>
                  <PrivateRoute path="/teacher-curriculum-list" type="teacher">
                    <TeacherCurriculumList />
                  </PrivateRoute>
                  <PrivateRoute path="/teacher-doubt-list" type="teacher">
                    <TeacherDoubtList />
                  </PrivateRoute>
                  <PrivateRoute path="/teacher-doubt-detail/:id" type="teacher">
                    <TeacherDoubtDetail />
                  </PrivateRoute>
                  <PrivateRoute path="/teacher-lecture-notes" type="teacher">
                    <TeacherLectureNotes />
                  </PrivateRoute>
                  <PrivateRoute path="/teacher-classroom-student/:classroomID">
                    <TeacherClassroom />
                  </PrivateRoute>
                  <Route path="/teacher-live-class-rating/:teacherId/:class_details">
                    <TeacherLiveClassRating />
                  </Route>
                  <PrivateRoute path="/student-performance-teacher/:id">
                    <StudentTeacherAssignment />
                  </PrivateRoute>

                  <PrivateRoute path="/rm-dashboard" type="rm">
                    <RMDashboard />
                  </PrivateRoute>
                  <PrivateRoute path="/rm-date-calendar/:userId" type="rm">
                    <RMDateCalendar />
                  </PrivateRoute>
                  <PrivateRoute
                    path="/rm-time-calendar/:date/:userId"
                    type="rm"
                  >
                    <RMTimeCalendar />
                  </PrivateRoute>
                  <PrivateRoute path="/rm-statistics" type="rm">
                    <RMStatistics />
                  </PrivateRoute>
                  <PrivateRoute path="/rm-payments" type="rm">
                    <RMPayments />
                  </PrivateRoute>
                  <PrivateRoute path="/rm-list" type="rm">
                    <RMList />
                  </PrivateRoute>
                  <PrivateRoute path="/rm-issue-detail/:id" type="rm">
                    <RMIssueDetail />
                  </PrivateRoute>
                  <PrivateRoute path="/rm-ticket-list" type="rm">
                    <RMTicketList />
                  </PrivateRoute>
                  <PrivateRoute path="/rm-issue-list" type="rm">
                    <RMIssueList />
                  </PrivateRoute>

                  {/* Sales Routes */}
                  <Route path="/sales-dashboard">
                    <SalesDashboard />
                  </Route>
                  <Route path="/sales-student-detail/:studentID">
                    <SalesStudentDetail />
                  </Route>
                  <Route path="/sales-payments">
                    <SalesPayments />
                  </Route>
                  <Route path="/sales-my-students">
                    <SalesMyStudents />
                  </Route>
                  <Route path="/sales-statistics">
                    <SalesStatistics />
                  </Route>
                  <Route path="/sales-trial-student-form/:salesId">
                    <SalesTrialStudentForm />
                  </Route>

                  {/* HC Routes */}
                  <Route path="/hc-teacher-detail-view/:teacherID/:gradeValue/:subjectValue">
                    <HcDetailView />
                  </Route>
                  <Route path="/hc-ticket-list">
                    <HcTicketList />
                  </Route>
                  {/* check */}
                  <Route path="/hc-payments">
                    <HcPayments />
                  </Route>
                  <Route path="/hc-student-detail/:studentID/:salesID">
                    <HcStudentDetail />
                  </Route>
                  <Route path="/hc-ticket-detail/:ticketID">
                    <HcTicketDetail />
                  </Route>
                  <Route path="/hc-dashboard">
                    <HcNewStudentList />
                  </Route>
                  {/* Check */}
                  <Route path="/hc-teachers-list">
                    <TeachersList />
                  </Route>
                  {/* check */}
                  <Route path="/hc-suggest-rm">
                    <SuggestRm />
                  </Route>
                  <Route path="/hc-classroom">
                    <HcClassroom />
                  </Route>

                  {/* Accounts Routes */}
                  <PrivateRoute path="/accounts-student">
                    <AccountStudents />
                  </PrivateRoute>
                  <PrivateRoute path="/accounts-employees">
                    <AccountsEmployee />
                  </PrivateRoute>

                  <PrivateRoute path="/accounts-dashboard">
                    <AccountsDashboard />
                  </PrivateRoute>
                </Switch>
              </div>
            </MuiPickersUtilsProvider>
          </PersistGate>
        </Router>
      </WebSocketProvider>
    </Provider>
  );
}

export default App;
