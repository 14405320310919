import React, { useLayoutEffect, useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import LoginModal from "../../../components/Login_modal/loginModal";
import CustomNav from "../../../components/header/Navbar";
import Background from "./bgcanvas.png";
import Footer from "../../../components/Footer";
import pic_5 from "../../student/dashboard/img/pic_5.png";
import Popup2 from "../../non_auth/new_popup/popup2";
import "./common.css";
Modal.setAppElement("#root");

function Clat() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section style={{ width: "100%" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "80%", marginTop: "5%" }}>
            <div
              style={{
                color: "#dc3545",
                fontSize: 24,
                fontWeight: 700,
                lineHeight: "1.6",
                textAlign: "left",
              }}
            >
              CLAT (Common Law Admission Test)
            </div>

            <div style={{ marginBottom: "2%" }}>
              <p
                style={{
                  fontSize: 16,
                  marginTop: "1%",
                  fontWeight: 500,
                  lineHeight: "1.6",
                }}
              >
                The Consortium of National Law Universities has scheduled CLAT
                2024 for December 3, 2023. This forthcoming exam will have a
                revised format, with a duration of 2 hours in offline mode and a
                reduction in the total number of questions from 150 to 120.
                <br />
                <br />
                In terms of eligibility, General category students must have a
                minimum of 50% in graduation (while reserved categories require
                45%) to appear for the CLAT PG exam in 2024. Additionally, there
                have been modifications in the reservation criteria,
                particularly concerning the seat matrix for Gandhinagar National
                Law University (GNLU), Silvassa.
                <br />
                <br /> Aspiring candidates who have registered for CLAT 2024 can
                now access sample papers provided by the Consortium of NLUs on
                their official website. These sample papers can be downloaded
                using the user ID and password created during registration.
                <br />
                <br />
                Furthermore, the CLAT 2024 Syllabus PDF is readily available for
                download. The syllabus for CLAT UG encompasses five sections:
                English Language, Current Affairs including General Knowledge,
                Legal Reasoning, Logical Reasoning, and Quantitative Techniques.
                For those preparing for CLAT 2024, having access to this
                syllabus is essential for effective preparation.
                <br />
                <br />
                It's worth noting that CLAT is widely regarded as one of India's
                most competitive law entrance exams. Each year, an impressive
                pool of approximately 45,000-50,000 candidates vies for roughly
                3,000 available seats, leading to a qualifying percentage as low
                as 6%. Therefore, meticulous planning and access to appropriate
                resources for CLAT 2024 preparation are imperative.
              </p>
            </div>

            <div
              style={{
                marginTop: "2%",
                borderStyle: "solid",
                borderWidth: "5px",
                borderColor: "#dc3545",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "1%",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 5 }}>
                    Date of Commencement
                  </div>
                  <div style={{ fontWeight: 600 }}>1 January 2024</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>Mode</div>
                  <div style={{ fontWeight: 600 }}>Offline/Online</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>
                    Duration
                  </div>
                  <div style={{ fontWeight: 600 }}>1 Year</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>Fee</div>
                  <div style={{ fontWeight: 600 }}>Rs. 80,000/- (1 Year)</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>
                    Admission
                  </div>
                  <div style={{ fontWeight: 600 }}>Open</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>
                    Class Timing
                  </div>
                  <div style={{ fontWeight: 600 }}>8:00 AM to 8: PM PM</div>
                </div>
              </div>
              <div
                style={{ display: "flex", marginLeft: 20, marginRight: 20 }}
                className="addrs"
              >
                <div
                  style={{
                    borderStyle: "solid",
                    borderWidth: 3,
                    borderColor: "#000",
                    padding: 20,
                    width: "60%",
                    marginTop: "2%",
                    marginBottom: "2%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "-42px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#1d74ca",
                        width: 200,
                        padding: 8,
                        borderStyle: "solid",
                        borderWidth: 3,
                        borderColor: "#000",
                      }}
                    >
                      <h2
                        style={{
                          color: "#fff",
                          fontWeight: 600,
                          fontSize: 20,

                          textAlign: "center",
                        }}
                      >
                        Location
                      </h2>
                    </div>
                  </div>
                  <br />
                  <div style={{ fontWeight: 600 }}>
                      311 Vardhman Sunder Plaza, Plot No. 12 MLU Sec - 12
                      Dwarka, New Delhi 110078
                    </div>
                    <br />
                    <div style={{ fontWeight: 600 }}>
                      42 Second Floor, Omaxe Galleria, Sector 14, Jhajjar Road,
                      Bahadurgarh, Haryana - 124-507
                    </div>
                </div>

                <div
                  style={{
                    borderStyle: "solid",
                    borderWidth: 3,
                    borderColor: "#000",
                    padding: 20,

                    marginTop: "2%",
                    marginBottom: "2%",
                    marginLeft: "5%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "-42px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#1d74ca",
                        width: 150,
                        padding: 8,
                        borderStyle: "solid",
                        borderWidth: 3,
                        borderColor: "#000",
                      }}
                    >
                      <h2
                        style={{
                          color: "#fff",
                          fontWeight: 600,
                          fontSize: 20,

                          textAlign: "center",
                        }}
                      >
                        Contact Us
                      </h2>
                    </div>
                  </div>
                  <br />
                  <div style={{ fontWeight: 600 }}>9570-424242</div>
                  <br />
                  <div style={{ fontWeight: 600 }}>9647-787878</div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "2%",
                  }}
                >
                  <Link to="/trial-student-form">
                    <div className="book-btn">Book Free Trial</div>
                  </Link>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "2%",
                    marginLeft: 10,
                  }}
                >
                  <a href="tel:+919570424242">
                    <div className="cons-btn">Call for Free Consultation</div>
                  </a>
                </div>
              </div>
            </div>
            <div style={{ marginBottom: "2%" }}>
              <h2
                style={{
                  color: "#56A5F5",
                  fontWeight: 600,
                  fontSize: 20,
                  marginTop: "3%",
                }}
              >
                About The Right Guru Classroom Programme
              </h2>
              <p
                style={{
                  fontSize: 16,
                  marginTop: "1%",
                  fontWeight: 500,
                  lineHeight: "1.6",
                }}
              >
                Since 2021, The Right Guru (TRG) has been a prominent player in
                the field of quality education, particularly for competitive
                exams like the SSC CGL, CHSL, MTS, CUET, DSSSB, CLAT, and also
                catering to CBSE/ICSE and other board examinations. With a
                dedicated team and a commitment to excellence, TRG understands
                the unique requirements of aspirants striving to excel in these
                competitive exams and contribute to their career growth.
              </p>
            </div>
            <div style={{ marginBottom: "2%" }}>
              <h2
                style={{
                  color: "#56A5F5",
                  fontWeight: 600,
                  fontSize: 20,
                  marginTop: "3%",
                }}
              >
                Why Choose TRG?
              </h2>
              <p
                style={{
                  fontSize: 16,
                  marginTop: "1%",
                  fontWeight: 500,
                  lineHeight: "1.6",
                }}
              >
                At TRG, we are committed to nurturing academic growth, fostering
                a deep understanding of subjects, and providing personalized
                attention to every student. Our diverse teaching methods and
                experienced educators create an enriching learning environment.
              </p>
            </div>
            <div style={{ marginBottom: "2%" }}>
              <h2
                style={{
                  color: "#56A5F5",
                  fontWeight: 600,
                  fontSize: 20,
                  marginTop: "3%",
                }}
              >
                TRG CLAT Programme Highlights
              </h2>
              <div
                style={{
                  borderStyle: "solid",
                  borderWidth: 5,
                  borderRadius: 8,
                  borderColor: "#dc3545",
                  padding: 10,
                  lineHeight: 1.6,
                  marginTop: "2%",
                }}
              >
                <h3 style={{ color: "#56a5f5", fontWeight: 500 }}>
                  Course Format
                </h3>
                <p style={{}}>
                  &#9635;
                  <span>&nbsp;</span>
                  The course is available online, allowing students to attend
                  classes remotely.
                </p>
                <h3 style={{ color: "#56a5f5", fontWeight: 500 }}>
                  Instructors
                </h3>
                <p style={{}}>
                  &#9635;
                  <span>&nbsp;</span>
                  Our experienced instructors, with backgrounds as former
                  faculty members from renowned institutions, are committed to
                  providing you with top-tier learning experiences that yield
                  excellent results.
                </p>
                <h3 style={{ color: "#56a5f5", fontWeight: 500 }}>
                  Course Material
                </h3>
                <p style={{}}>
                  &#9635;
                  <span>&nbsp;</span>
                  Comprehensive study material provided for English, Legal
                  aptitude, General knowledge, Logical reasoning, and
                  Mathematics.
                </p>
                <p style={{}}>
                  &#9635;
                  <span>&nbsp;</span>
                  The study material consists of well-researched books.
                </p>

                <h3 style={{ color: "#56a5f5", fontWeight: 500 }}>
                  Classroom Lectures
                </h3>
                <p style={{}}>
                  &#9635;
                  <span>&nbsp;</span>
                  Students will benefit from over 350 hours of physical
                  classroom lectures.
                </p>
                <h3 style={{ color: "#56a5f5", fontWeight: 500 }}>
                  Mock Tests
                </h3>
                <p style={{}}>
                  &#9635;
                  <span>&nbsp;</span>
                  The coaching program includes 80 Full Length Mock Tests.
                </p>
                <p style={{}}>
                  &#9635;
                  <span>&nbsp;</span>
                  Mock tests are designed for various law entrance exams: 42
                  CLAT, 22 AILET, 6 SLAT, 5 LSAT, 2 JMI, and 3 MH CET.
                </p>
                <h3 style={{ color: "#56a5f5", fontWeight: 500 }}>
                  Question Bank
                </h3>
                <p style={{}}>
                  &#9635;
                  <span>&nbsp;</span>
                  Students have access to a Question Bank with over 2000 +
                  practice questions.
                </p>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginBottom: "5%",
              }}
            >
              <a
                target="_blank"
                href="https://therightguru-website.s3.ap-south-1.amazonaws.com/CLAT+(1).pdf"
              >
                <div className="book-btn">Know more...</div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default Clat;
