import React, { useEffect, useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import pic_2 from "../../student/dashboard/img/pic_2.png";

import { PieChart } from "react-minimal-pie-chart";
// import "./style.css";
import Popup from "../popup/popup";
import { studentPerformance } from "../../../crud/student.crud";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// images
import vector_1_2x from "./img/vector-1@2x.png";
import vector_51_2x from "./img/vector-51@2x.png";
import ellipse_5_4 from "./img/ellipse-5-4@2x.png";
import ellipse_6_4 from "./img/ellipse-6-4@2x.png";
import ellipse_8_2x from "./img/ellipse-8@2x.png";
import ellipse_7_2_2x from "./img/ellipse-7-2@2x.png";
import ellipse_7_2x from "./img/ellipse-7@2x.png";
import ellipse_6_5_2x from "./img/ellipse-6-5@2x.png";
import ellipse_5_5_2x from "./img/ellipse-5-5@2x.png";
import ellipse_7_1 from "./img/ellipse-7-1@2x.png";
import ellipse_5_2x from "./img/ellipse-5@2x.png";
import ellipse_6_2x from "./img/ellipse-6@2x.png";
// import ellipse_2_2x from "./img/ellipse-2@2x.png";
// import vector_13_2x from "./img/vector-13@2x.png";
import rectangle_7_1x from "./img/rectangle-7@1x.png";
import vector_11_2x from "./img/vector-11@2x.png";
import SideDrawerStudent from "../../../UI/sideDrawer/sideDrawerStudent";

import BackDrop from "../../../UI/Backdrop/Backdrop";

import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar } from "recharts";

function StudentPerformance(props) {
  const { id } = useParams();
  const user = useSelector((state) => state.teacherDetails.teacher);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [perfData, setPerfData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const ModalTogglerHandler = (e) => {
    e.preventDefault();
    setOpenModal((per) => !per);
  };

  let atClass = ["Modal_in_student_per", "Close"];

  if (openModal) {
    atClass = ["Modal_in_student_per", "Open"];
  }

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  useEffect(() => {
    studentPerformance(id)
      .then((res) => {
        setPerfData(res);
        setIsLoaded(true);
      })
      .catch((err) => console.log(err));
  }, []);

  let classesData = [];
  let doubtsData = [];
  let assignData = [];
  let quizData = [];

  isLoaded
    ? perfData.classes
      ? (classesData = [
          {
            title: "Completed",
            name: "Completed",
            value: perfData.classes.completed_classes,
            color: "#6F58FF",
          },
          {
            title: "Booked",
            name: "Booked",
            value: perfData.classes.booked_classes,
            color: "#46C9B8",
          },
          {
            title: "Cancelled",
            name: "Cancelled",
            value: perfData.classes.cancelled_classes,
            color: "#F76775",
          },
        ])
      : (classesData = [])
    : (classesData = []);

  isLoaded
    ? perfData.classes
      ? (doubtsData = [
          {
            title: "Asked",
            name: "Asked",
            value: perfData.doubts.asked,
            color: "#6F58FF",
          },
          {
            title: "Responded",
            name: "Responded",
            value: perfData.doubts.answered,
            color: "#46C9B8",
          },
        ])
      : (doubtsData = [])
    : (doubtsData = []);

  isLoaded
    ? perfData.classes
      ? (assignData = [
          {
            title: "Assigned",
            name: "Assigned",
            value: perfData.assignments.assigned,
            color: "#6F58FF",
          },
          {
            title: "Submitted",
            name: "Submitted",
            value: perfData.assignments.submitted,
            color: "#46C9B8",
          },
        ])
      : (assignData = [])
    : (assignData = []);

  isLoaded
    ? perfData.classes
      ? (quizData = [
          {
            title: "Assigned",
            name: "Assigned",
            value: perfData.quizzes.assigned,
            color: "#6F58FF",
          },
          {
            title: "Submitted",
            name: "Submitted",
            value: perfData.quizzes.submitted,
            color: "#46C9B8",
          },
        ])
      : (quizData = [])
    : (quizData = []);

  return (
    <>
      <form
        className="student-performance screen"
        name="form1"
        action="form1"
        method="post"
      >
        <div className="group-22-C61RwL">
          <img className="vector-VdQhAS" src={vector_11_2x} alt="line" />
          <div className="biba-sobti-VdQhAS">
            {user.first_name + " " + user.last_name}
          </div>
          <div className="bibasobtigmailcom-VdQhAS">{user.emailID}</div>
        </div>
        <img className="rectangle-7-C61RwL" src={rectangle_7_1x} alt="line" />
        {/* <div className="notifications-24px-2-1-C61RwL"></div> */}
        <div className="my-performance-C61RwL">
          <div
            style={{
              width: "390px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <a
              href="javascript:history.back()"
              style={{
                display: "flex",
                alignItems: "center",
                color: "grey",
              }}
            >
              <ArrowBackIcon />
            </a>

            <span className="span1-3EE9bF">
              <span style={{ fontWeight: "400" }}>{id}</span> Performance
            </span>
          </div>

          <button className="showModalTogler" onClick={ModalTogglerHandler}>
            Show BarGraph
          </button>
        </div>

        <div className="group-69-C61RwL">
          <div className="group-57-8FQU39"></div>
          <div className="classes-8FQU39 montserrat-medium-blue-zodiac-18px">
            Classes
          </div>
          {isLoaded ? (
            <PieChart
              className="donut-chart-1-8FQU39"
              lineWidth={30}
              rounded
              label={({ dataEntry }) => dataEntry.value}
              labelStyle={(index) => ({
                fill: classesData[index].color,
                fontSize: "5px",
                fontFamily: "sans-serif",
              })}
              labelPosition={60}
              data={classesData}
            />
          ) : (
            "Loading..."
          )}
          <img className="ellipse-5-8FQU39" src={ellipse_5_2x} alt="line" />
          <img className="ellipse-6-8FQU39" src={ellipse_6_2x} alt="line" />
          <img className="ellipse-7-8FQU39" src={ellipse_7_2x} alt="line" />
          <div className="completed-8FQU39 montserrat-semi-bold-blue-zodiac-10px">
            Completed
          </div>
          <div className="booked-8FQU39 montserrat-semi-bold-blue-zodiac-10px">
            Booked
          </div>
          <div className="cancelled-8FQU39 montserrat-semi-bold-blue-zodiac-10px">
            Cancelled
          </div>
          <div className="attendance-75-8FQU39">
            Attendance:{" "}
            {isLoaded
              ? perfData.classes
                ? perfData.classes.attendance
                : "--"
              : "--"}
          </div>
        </div>

        <div className="group-71-C61RwL">
          <div className="group-57-R3ERnh"></div>
          <div className="assignments-R3ERnh montserrat-medium-blue-zodiac-18px">
            Assignments
          </div>
          {isLoaded ? (
            <PieChart
              className="donut-chart-1-R3ERnh"
              lineWidth={30}
              rounded
              label={({ dataEntry }) => dataEntry.value}
              labelStyle={(index) => ({
                fill: assignData[index].color,
                fontSize: "5px",
                fontFamily: "sans-serif",
              })}
              labelPosition={60}
              data={assignData}
            />
          ) : (
            "Loading..."
          )}
          <img className="ellipse-5-R3ERnh" src={ellipse_5_2x} alt="line" />
          <img className="ellipse-6-R3ERnh" src={ellipse_6_2x} alt="line" />
          <div className="assigned-R3ERnh montserrat-semi-bold-blue-zodiac-10px">
            Assigned
          </div>
          <div className="submitted-R3ERnh montserrat-semi-bold-blue-zodiac-10px">
            Submitted
          </div>
        </div>
        <div className="group-76-C61RwL">
          <div className="group-57-Z1JSTw"></div>
          <div className="quizzes-Z1JSTw montserrat-medium-blue-zodiac-18px">
            Quizzes
          </div>
          {isLoaded ? (
            <PieChart
              className="donut-chart-1-Z1JSTw"
              lineWidth={30}
              rounded
              label={({ dataEntry }) => dataEntry.value}
              labelStyle={(index) => ({
                fill: quizData[index].color,
                fontSize: "5px",
                fontFamily: "sans-serif",
              })}
              labelPosition={60}
              data={quizData}
            />
          ) : (
            "Loading..."
          )}
          <img className="ellipse-5-Z1JSTw" src={ellipse_5_2x} alt="line" />
          <img className="ellipse-6-Z1JSTw" src={ellipse_6_2x} alt="line" />
          <div className="assigned-Z1JSTw montserrat-semi-bold-blue-zodiac-10px">
            Assigned
          </div>
          <div className="submitted-Z1JSTw montserrat-semi-bold-blue-zodiac-10px">
            Submitted
          </div>
        </div>
        <div className="group-70-C61RwL">
          <div className="group-57-kber4s"></div>
          <div className="doubts-kber4s montserrat-medium-blue-zodiac-18px">
            Doubts
          </div>
          {isLoaded ? (
            <PieChart
              className="donut-chart-1-kber4s"
              lineWidth={30}
              rounded
              label={({ dataEntry }) => dataEntry.value}
              labelStyle={(index) => ({
                fill: doubtsData[index].color,
                fontSize: "5px",
                fontFamily: "sans-serif",
              })}
              labelPosition={60}
              data={doubtsData}
            />
          ) : (
            "Loading..."
          )}
          <img className="ellipse-5-kber4s" src={ellipse_5_2x} alt="line" />
          <img className="ellipse-6-kber4s" src={ellipse_6_2x} alt="line" />
          <div className="asked-kber4s montserrat-semi-bold-blue-zodiac-10px">
            Asked
          </div>
          <div className="responded-kber4s montserrat-semi-bold-blue-zodiac-10px">
            Responded
          </div>
        </div>
      </form>

      <form
        className="student-performance-mobile screen"
        name="form2"
        action="form2"
        method="post"
      >
        <div className="component-1-C61RwL">
          <img
            alt="image"
            src={pic_2}
            style={{ height: "90px", marginTop: "-8%" }}
          />
        </div>
        {/* This is the Hamburger icon  */}
        <SideDrawerStudent
          open={displaySideDrawer}
          closeSideDrawer={sideDrawerToggler}
        />
        <div className="component-6-C61RwL" onClick={sideDrawerToggler}>
          <div className="rectangle-48-5mfU7d"></div>
          <div className="rectangle-49-5mfU7d"></div>
          <div className="rectangle-50-5mfU7d"></div>
        </div>

        <div
          className="my-performance-C61RwL"
          style={{
            width: "260px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <a
            href="javascript:history.back()"
            style={{
              display: "flex",
              alignItems: "center",
              color: "grey",
            }}
          >
            <ArrowBackIcon />
          </a>
          <span className="span1-3EE9bF" style={{ fontSize: "25px" }}>
            <span style={{ fontWeight: "400" }}>{id}</span> Performance
          </span>
        </div>

        <div
          style={{
            position: "absolute",
            top: "185px",
            width: "100%",
            textAlign: "right",
            padding: "0px 10px",
          }}
        >
          <button className="showModalTogler" onClick={ModalTogglerHandler}>
            Show BarGraph
          </button>
        </div>

        <div className="group-69-C61RwL">
          <div className="group-57-8FQU39"></div>
          <div className="classes-8FQU39 montserrat-medium-blue-zodiac-18px">
            Classes
          </div>
          {isLoaded ? (
            <PieChart
              className="donut-chart-1-8FQU39"
              lineWidth={30}
              rounded
              label={({ dataEntry }) => dataEntry.value}
              labelStyle={(index) => ({
                fill: classesData[index].color,
                fontSize: "5px",
                fontFamily: "sans-serif",
              })}
              labelPosition={60}
              data={classesData}
            />
          ) : (
            "Loading..."
          )}
          <img className="ellipse-5-8FQU39" src={ellipse_5_4} alt="line" />
          <img className="ellipse-6-8FQU39" src={ellipse_6_4} alt="line" />
          <img className="ellipse-7-8FQU39" src={ellipse_7_1} alt="line" />
          <div className="completed-8FQU39 montserrat-semi-bold-blue-zodiac-10px">
            Completed
          </div>
          <div className="booked-8FQU39 montserrat-semi-bold-blue-zodiac-10px">
            Booked
          </div>
          <div className="cancelled-8FQU39 montserrat-semi-bold-blue-zodiac-10px">
            Cancelled
          </div>
          <div className="attendance-75-8FQU39">
            Attendance:{" "}
            {isLoaded
              ? perfData.classes
                ? perfData.classes.attendance
                : "--"
              : "--"}
          </div>
        </div>

        <div className="group-76-C61RwL">
          <div className="group-57-Z1JSTw"></div>
          <div className="quizzes-Z1JSTw montserrat-medium-blue-zodiac-18px">
            Quizzes
          </div>
        </div>
        <div className="group-71-C61RwL">
          <div className="group-57-R3ERnh"></div>
          <div className="assignments-R3ERnh montserrat-medium-blue-zodiac-18px">
            Assignments
          </div>
          {isLoaded ? (
            <PieChart
              className="donut-chart-1-R3ERnh"
              lineWidth={30}
              rounded
              label={({ dataEntry }) => dataEntry.value}
              labelStyle={(index) => ({
                fill: assignData[index].color,
                fontSize: "5px",
                fontFamily: "sans-serif",
              })}
              labelPosition={60}
              data={assignData}
            />
          ) : (
            "Loading..."
          )}
          <img className="ellipse-5-R3ERnh" src={ellipse_5_5_2x} alt="line" />
          <img className="ellipse-6-R3ERnh" src={ellipse_6_5_2x} alt="line" />
          <div className="assigned-R3ERnh montserrat-semi-bold-blue-zodiac-10px">
            Assigned
          </div>
          <div className="submitted-R3ERnh montserrat-semi-bold-blue-zodiac-10px">
            Submitted
          </div>
          {isLoaded ? (
            <PieChart
              className="donut-chart-1-Z1JSTw"
              lineWidth={30}
              rounded
              label={({ dataEntry }) => dataEntry.value}
              labelStyle={(index) => ({
                fill: quizData[index].color,
                fontSize: "5px",
                fontFamily: "sans-serif",
              })}
              labelPosition={60}
              data={quizData}
            />
          ) : (
            "Loading..."
          )}
          <img className="ellipse-7-R3ERnh" src={ellipse_7_2_2x} alt="line" />
          <img className="ellipse-8-R3ERnh" src={ellipse_8_2x} alt="line" />
          <div className="assigned-Z7HFmV montserrat-semi-bold-blue-zodiac-10px">
            Assigned
          </div>
          <div className="submitted-Z7HFmV montserrat-semi-bold-blue-zodiac-10px">
            Submitted
          </div>
        </div>

        <div className="group-70-C61RwL">
          <div className="group-57-kber4s"></div>
          <div className="doubts-kber4s montserrat-medium-blue-zodiac-18px">
            Doubts
          </div>
          {isLoaded ? (
            <PieChart
              className="donut-chart-1-kber4s"
              lineWidth={30}
              rounded
              label={({ dataEntry }) => dataEntry.value}
              labelStyle={(index) => ({
                fill: doubtsData[index].color,
                fontSize: "5px",
                fontFamily: "sans-serif",
              })}
              labelPosition={60}
              data={doubtsData}
            />
          ) : (
            "Loading..."
          )}
          <img className="ellipse-5-kber4s" src={ellipse_5_4} alt="line" />
          <img className="ellipse-6-kber4s" src={ellipse_6_4} alt="line" />
          <div className="asked-kber4s montserrat-semi-bold-blue-zodiac-10px">
            Asked
          </div>
          <div className="responded-kber4s montserrat-semi-bold-blue-zodiac-10px">
            Responded
          </div>
        </div>
        <div className="group-72-C61RwL">
          <div className="rectangle-40-gqbUX1"></div>
          <div className="component-29-gqbUX1">
            <div className="assignment-24px-3-lxV7ix">
              <img className="vector-YDNU6K" src={vector_1_2x} alt="line" />
              <img className="vector-hGGfYL" src={vector_51_2x} alt="line" />
            </div>
          </div>
          <div className="assignment-1-gqbUX1 montserrat-semi-bold-blue-zodiac-10px">
            ASSIGNMENT 1
          </div>
          <div className="mathematics-dr-yashna-sharma-gqbUX1 montserrat-medium-gray-7px">
            Mathematics (Dr. Yashna Sharma)
          </div>
          <div className="submitted-gqbUX1 montserrat-semi-bold-niagara-8px">
            Submitted
          </div>
        </div>
      </form>

      <Popup />
      <BackDrop open={openModal} clicked={ModalTogglerHandler} />
      <div className={atClass.join(" ")}>
        <div className="Modal_inner_scroll">
          <div className="Div_to_have_chart">
            <div
              className="montserrat-medium-blue-zodiac-18px"
              style={{ color: "#dc3545" }}
            >
              Classes
            </div>

            <div
              style={{
                width: "auto",
                height: "auto",
                margin: "10px",
              }}
            >
              {isLoaded ? (
                <BarChart width={320} height={240} data={classesData}>
                  <CartesianGrid strokeDasharray="3 3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="value" fill="#6F58FF" />
                </BarChart>
              ) : (
                "Loading..."
              )}
            </div>
          </div>

          <div className="Div_to_have_chart">
            <div
              className="montserrat-medium-blue-zodiac-18px"
              style={{ color: "#dc3545" }}
            >
              Doubts
            </div>

            <div
              style={{
                width: "auto",
                height: "auto",
                margin: "10px",
              }}
            >
              {isLoaded ? (
                <BarChart width={320} height={240} data={doubtsData}>
                  <CartesianGrid strokeDasharray="2 2" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="value" fill="#6F58FF" />
                </BarChart>
              ) : (
                "Loading..."
              )}
            </div>
          </div>

          <div className="Div_to_have_chart">
            <div
              className="montserrat-medium-blue-zodiac-18px"
              style={{ color: "#dc3545" }}
            >
              Assignment
            </div>
            <div
              style={{
                width: "auto",
                height: "auto",
                margin: "10px",
              }}
            >
              {isLoaded ? (
                <BarChart width={320} height={240} data={assignData}>
                  <CartesianGrid strokeDasharray="2 2" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="value" fill="#6F58FF" />
                </BarChart>
              ) : (
                "Loading..."
              )}
            </div>
          </div>

          <div className="Div_to_have_chart">
            <div
              className="montserrat-medium-blue-zodiac-18px"
              style={{ color: "#dc3545" }}
            >
              Quiz
            </div>
            <div
              style={{
                width: "auto",
                height: "auto",
                margin: "10px",
              }}
            >
              {isLoaded ? (
                <BarChart width={320} height={240} data={quizData}>
                  <CartesianGrid strokeDasharray="2 2" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="value" fill="#6F58FF" />
                </BarChart>
              ) : (
                "Loading..."
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentPerformance;
