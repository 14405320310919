import React, { useState, useEffect, useLayoutEffect } from "react";
import "./teachers_list.css";
import { NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteHc } from "../../../redux/actions/hc";
import { Link } from "react-router-dom";
import ClassSharpIcon from "@material-ui/icons/ClassSharp";

import Popup from "../../teacher/popup/popup";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SchoolIcon from "@material-ui/icons/School";
import SideDrawerHc from "../../../UI/sideDrawer/sideDrawerHc";
import { HCteacherList } from "../../../crud/highercommittee.crud";
import pic_2 from "../../student/dashboard/img/pic_2.png";
import DropdownOption from "../../higher_committee/dropdown/dropdown2";

import {
  CaretDownOutlined,
  MenuOutlined,
  BellOutlined,
  HomeOutlined,
  UserOutlined,
} from "@ant-design/icons";

const TeachersList = (props) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const [teacherList, setTeacherList] = useState({});
  const user = useSelector((state) => state.hcDetails.hc);

  const [subject_id, setSubjectName] = useState("");
  const [grade_id, setGradeName] = useState("");

  const handleGradeSelect = (value) => {
    setGradeName(value);
  };

  const handleSubjectSelect = (value) => {
    setSubjectName(value);
  };

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(deleteHc(user.hc_id));
    removeToken();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    HCteacherList(subject_id, grade_id)
      .then((res) => {
        setTeacherList(res);
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  return (
    <div className="Hc_detail_layout">
      {/* its css in UI Navbar */}
      <div className="header-wdt-100">
        <header className="Header">
          <HomeOutlined className="logo" />
          <span className="heder-men-not">
            <SideDrawerHc
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />

            <MenuOutlined className="menu" onClick={sideDrawerToggler} />
          </span>
        </header>
      </div>
      {/* This is the sider of the */}
      <div className="sider-hc-teacher-list-2">
        <div className="sider-hc-teacher-list-2-in-wdt-90">
          <div style={{ marginTop: "120px" }} />
          <NavLink to="/hc-dashboard">
            <DashboardIcon data-tip="Dashboard" className="icon-in-hc-color" />
            <ReactTooltip />
          </NavLink>
          <NavLink to="/hc-teachers-list">
            {" "}
            <SchoolIcon
              data-tip="Teacher"
              className="icon-in-hc-color"
              style={{ color: "#dc3545" }}
            />
            <ReactTooltip />
          </NavLink>
          <NavLink to="/hc-suggest-rm">
            <SupervisorAccountIcon
              data-tip="Relational Manager"
              className="icon-in-hc-color"
            />
            <ReactTooltip />
          </NavLink>
          <NavLink to="/hc-ticket-list">
            <AssignmentIcon
              data-tip="Ticket List"
              className="icon-in-hc-color"
            />
            <ReactTooltip />
          </NavLink>
          <NavLink to="/hc-payments">
            <AccountBalanceIcon
              data-tip="Payments"
              className="icon-in-hc-color"
            />
            <ReactTooltip />
          </NavLink>
          <NavLink to="/hc-classroom">
            <ClassSharpIcon data-tip="Classroom" className="icon-in-hc-color" />
            <ReactTooltip />
          </NavLink>
          <NavLink to="/" onClick={() => handleLogout()}>
            <PowerSettingsNewIcon
              data-tip="Logout"
              className="icon-in-hc-color"
            />
          </NavLink>
          <div style={{ marginTop: "1px" }} />
        </div>
      </div>

      <div className="content-in-hc-layout-tech-list">
        <div className="content-in-hc-layout-in-wdt-90">
          {/* Its Css in sales SalesStatistics */}
          <img
            alt="image"
            src={pic_2}
            style={{ height: "100px", marginLeft: "-70%" }}
          />
          {/* <div className="logo-totalStudent-in" /> */}

          <div className="hc-teacher-list-out-view-1">
            <form className="hc-form-in-wdt-align-teachers-list">
              <div style={{ width: "100%", marginTop: "20px" }}>
                <DropdownOption
                  handleGradeSelect={handleGradeSelect}
                  handleSubjectSelect={handleSubjectSelect}
                />
              </div>

              {/* <input type="text" name="Grade" placeholder="Grade" />
                            <input type="text" name="subject" placeholder="Subject" /> */}
              <button
                className="butt-in-hc-form-4px-teachers-list"
                onClick={(e) => handleSubmit(e)}
              >
                Submit
              </button>
            </form>
          </div>
        </div>

        <div className="content-in-hc-layout-in-wdt-90-2">
          {/* Its css in teacher dashboard */}
          <div className="calender-hc-in-user-dis">
            <div></div>
            <div className="User-info">
              <div className="user-info-in">
                <div
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#dc3545",
                  }}
                >
                  {user && user.first_name} {user && user.last_name}
                </div>
                <div>{user && user.email}</div>
              </div>
              <div>
                <UserOutlined className="User-Icon" />
              </div>
            </div>
          </div>

          <div className="hc-teacher-list-out-view-2-in">
            <div className="hc-calender-status">
              {/* <span className="hc-calender-items-data">Teacher ID</span> */}
              <span className="hc-calender-items-data">Teacher Name</span>
              <span className="hc-calender-items-data">Number of Student</span>
              <span className="hc-calender-items-data">
                Number of Classroom
              </span>
              <span className="hc-calender-items-data">Badge</span>
            </div>

            <div className="rectangle-31-Sgr7kw">
              {teacherList.length > 0 ? (
                teacherList.map((item, index) => {
                  return (
                    <Link
                      to={`/hc-teacher-detail-view/${
                        item && item.teacher && item.teacher.teacher_id
                      }/${grade_id}/${subject_id}`}
                    >
                      <div className="hc-status-2">
                        <div className="hc-status-2-in">
                          {/* <span className="hc-calender-items-data">
                            {item && item.teacher && item.teacher.teacher_id}
                          </span> */}
                          <span className="hc-calender-items-data margin-right">
                            {item && item.teacher && item.teacher.first_name}{" "}
                            {item && item.teacher && item.teacher.last_name}
                          </span>
                          <span className="hc-calender-items-data margin-right">
                            {item &&
                              item.teacher &&
                              item.teacher.students &&
                              item.teacher.students.length}
                          </span>
                          <span className="hc-calender-items-data ">
                            {item &&
                              item.teacher &&
                              item.teacher.classrooms &&
                              item.teacher.classrooms.length}
                          </span>
                          <span className="hc-calender-items-data ">
                            {item && item.teacher && item.teacher.badge}
                          </span>
                        </div>
                      </div>
                    </Link>
                  );
                })
              ) : (
                <div
                  style={{
                    fontFamily: "'Montserrat', Helvetica",
                    textAlign: "center",
                    marginTop: "12px",
                  }}
                >
                  No such teacher exists.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <Popup /> */}
    </div>
  );
};

export default TeachersList;
