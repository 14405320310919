import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap"; // Assuming you are using Bootstrap for layout

import "./footer.css";
import facebookIcon from "./icons/facebook.png";
import instagramIcon from "./icons/instagram.png";
import youtubeIcon from "./icons/youtube.png";
import twitterIcon from "./icons/twitter.png";
import linkedinIcon from "./icons/linkedin.png";
import squareLogo from "../pages/student/dashboard/img/pic_3.png";
import appStore from "./icons/appStore.png";
import googlePlay from "./icons/googlePlay.png";
import locationIcon from "./icons/locationIcon.png";

function Footer({ refs }) {
  const dt = new Date();
  return (
    <Container
      fluid
      style={{
        backgroundColor: "#dc3545",
        fontFamily: "'Montserrat', sans-serif",
        color: "#f4f4f4",
      }}
    >
      <section
        style={{
          width: "100%",
        }}
      >
        <div
          className="d-flex flex-wrap justify-content-around"
          style={{
            marginTop: "20px",
            paddingTop: "5%",
            marginBottom: "20px",
          }}
        >
          <div>
            <img src={squareLogo} width="200" alt="TheRightGuru Logo White" />
          </div>
          <div className="my-3" sm={4}>
            <ul>
              <li>
                <Link to="/aboutus" style={{ color: "white" }}>
                  About us
                </Link>
              </li>
              <li className="footer_link">
                <Link to="/internship-programme" style={{ color: "white" }}>
                  Internship Programme
                </Link>
              </li>
              <li className="footer_link">
                <Link to="/trg-franchise" style={{ color: "white" }}>
                  TRG Franchise
                </Link>
              </li>
              <li className="footer_link">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://therightguru-website.s3.ap-south-1.amazonaws.com/TnC.pdf"
                  style={{ color: "white" }}
                >
                  Terms & Conditions
                </a>
              </li>
              <li className="footer_link">
                <Link to="/privacy" style={{ color: "white" }}>
                  Privacy Policy
                </Link>
              </li>
              <li className="footer_link">
                {/* <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSeVVpq96KWOQBdLYbkz5pT83ztSu_en6Z5cTqJanG-BZ1VX1w/viewform"
                  style={{ color: "white" }}
                >
                  Careers
                </a> */}
                <Link to="/career" style={{ color: "white" }}>
                  Career
                </Link>
              </li>
              <li className="footer_link">
                <Link to="/faqs" style={{ color: "white" }}>
                  FAQs
                </Link>
              </li>
              <li>
                <Link to="/contact-us" style={{ color: "white" }}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          <div className="my-3" sm={2}>
            <h3 style={{ fontWeight: "600", color: "#fff" }}>Courses</h3>
            <ul>
              <li className="footer_link">
                <Link to="/cbse-icse" style={{ color: "white" }}>
                  CBSE/ICSE/OTHERS BOARDS
                </Link>
              </li>
              <li className="footer_link">
                <Link to="/ssc" style={{ color: "white" }}>
                  SSC
                </Link>
              </li>
              <li className="footer_link">
                <Link to="/cuet" style={{ color: "white" }}>
                  CUET
                </Link>
              </li>
              
              <li className="footer_link">
                <Link to="/clat" style={{ color: "white" }}>
                  CLAT
                </Link>
              </li>
              <li className="footer_link">
                <Link to="/dsssb" style={{ color: "white" }}>
                  DSSSB
                </Link>
              </li>
              <li className="footer_link">
                <Link to="/ndacds" style={{ color: "white" }}>
                  NDA & CDS
                </Link>
              </li>
              <li className="footer_link">
                <Link to="/acturial-science" style={{ color: "white" }}>
                  ACTURIAL SCIENCE
                </Link>
              </li>
              <li className="footer_link">
                <Link to="/gre-gmat-sat" style={{ color: "white" }}>
                  GRE, GMAT & SAT
                </Link>
              </li>
              <li className="footer_link">
                <Link to="/police-services" style={{ color: "white" }}>
                  POLICE SERVICES
                </Link>
              </li>
              <li className="footer_link">
                <Link to="/english-speaking" style={{ color: "white" }}>
                  ENGLISH SPEAKING COURSE
                </Link>
              </li>
              <li className="footer_link">
                <Link to="/other-cs" style={{ color: "white" }}>
                  OTHER COMPETITIVE SERVICES
                </Link>
              </li>
            </ul>
          </div>

          <div className="my-3" sm={2}>
            <h3 style={{ fontWeight: "600", color: "#fff" }}>Follow Us</h3>
            <div style={{ marginTop: "24px" }}>
              <a
                href="https://www.facebook.com/The-Right-Guru-101006872301721"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="footer_icons"
                  src={facebookIcon}
                  width="20"
                  alt="Facebook Icon"
                />
              </a>
              <a
                href="https://www.instagram.com/therightguru/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="footer_icons"
                  src={instagramIcon}
                  width="20"
                  alt="Instagram Icon"
                />
              </a>
              <a
                href="https://twitter.com/The_Right_Guru"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="footer_icons"
                  src={twitterIcon}
                  width="20"
                  alt="Twitter Icon"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/the-right-guru/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="footer_icons"
                  src={linkedinIcon}
                  width="20"
                  alt="Linkedin Icon"
                />
              </a>
              <div style={{ marginTop: "20px" }}>
                <ul>
                  <li>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.trg.TheRightGuru"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={googlePlay}
                        width="130"
                        alt="Google Play Store Link"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://apps.apple.com/in/app/the-right-guru/id1585378387"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={appStore}
                        width="130"
                        height="40"
                        style={{ marginTop: "10px" }}
                        alt="App Store Link"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="my-3" sm={12} md={6} lg={3}>
            <h3 style={{ fontWeight: 600, color: "#fff" }}>TRG Branch</h3>
            <ul
              className=""
              style={{
                marginTop: "24px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <li>
                <div
                  className="my-2"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <a
                    style={{
                      color: "#ffff",
                      display: "flex",
                      alignItems: "center",
                    }}
                    target="_blank"
                    href="https://maps.app.goo.gl/z5pXqEGdEdULvFuB8"
                  >
                    <img
                      className="location_icon"
                      src={locationIcon}
                      width="20"
                    />
                    <span style={{ marginLeft: "5px" }}>
                      Bahadurgarh Sector 14
                    </span>
                  </a>
                </div>
              </li>
              <li>
                <div
                  className="my-2"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <a
                    style={{
                      color: "#ffff",
                      display: "flex",
                      alignItems: "center",
                    }}
                    target="_blank"
                    href="https://maps.app.goo.gl/z5pXqEGdEdULvFuB8"
                  >
                    <img
                      className="location_icon"
                      src={locationIcon}
                      width="20"
                    />
                    <span style={{ marginLeft: "5px" }}>
                      Bahadurgarh Sector 15
                    </span>
                  </a>
                </div>
              </li>
              <li>
                <div
                  className="my-2"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <a
                    style={{
                      color: "#ffff",
                      display: "flex",
                      alignItems: "center",
                    }}
                    target="_blank"
                    href="https://maps.app.goo.gl/GqPcGVKqfBKSXg5PA"
                  >
                    <img
                      className="location_icon"
                      src={locationIcon}
                      width="20"
                    />
                    
                    <span style={{ marginLeft: "5px" }}>Dwarka sec-12</span>
                  </a>
                </div>
              </li>
              <li>
                <div
                  className="my-2"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <a
                    style={{
                      color: "#ffff",
                      display: "flex",
                      alignItems: "center",
                    }}
                    target="_blank"
                    href="https://maps.app.goo.gl/4YDYsvrrWxU4uy426"
                  >
                    <img
                      className="location_icon"
                      src={locationIcon}
                      width="20"
                    />
                    <span style={{ marginLeft: "5px" }}>Dwarka Mor</span>
                  </a>
                </div>
              </li>
              <li>
                <div
                  className="my-2"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <a
                    style={{
                      color: "#ffff",
                      display: "flex",
                      alignItems: "center",
                    }}
                    target="_blank"
                    href="https://maps.app.goo.gl/GqPcGVKqfBKSXg5PA"
                  >
                    <img
                      className="location_icon"
                      src={locationIcon}
                      width="20"
                    />
                    <span style={{ marginLeft: "5px" }}>Jankipuri</span>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          className="d-flex justify-content-evenly pl-5 pr-5 pt-4 pb-4"
          style={{ fontWeight: "500" }}
        >
          <div>
            <span>&copy;</span> {dt.getFullYear()} Right Guru.com Private
            Limited, All Rights Reserved.
          </div>
          <div>
            Made with <span style={{ color: "#f4f4f4" }}>&#9829;</span> in
            India.
          </div>
        </div>
      </section>
    </Container>
  );
}

export default Footer;
