import { GET_STUDENT, DELETE_STUDENT } from '../actions/student/types'

const initialState = {}

export default function studentDetailsReducer(state = initialState, action) {
    switch(action.type) {
        case GET_STUDENT:
            return {
                ...state,
                student: action.payload
            };
        case DELETE_STUDENT:
            return {}
        default:
            return state
    }
}