import React, { useLayoutEffect, useState } from "react";
import Footer from "../../../components/Footer";
import Modal from "react-modal";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import LoginModal from "../../../components/Login_modal/loginModal";
import Popup2 from "../new_popup/popup2";
import CustomNav from "../../../components/header/Navbar";

Modal.setAppElement("#root");

export default function AboutUs() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div style={{ width: "100%" }}>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section style={{ width: "85%", margin: "0 auto" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            textAlign: "justify",
          }}
        >
          <h1
            style={{
              fontSize: "30px",
              fontWeight: "800",

              marginBottom: "2%",
              margin: "5% auto ",
            }}
          >
            About Us
          </h1>
          <div
            style={{ fontWeight: "600", fontSize: "24px", marginBottom: "2%" }}
          >
            ESTD 2021
          </div>
          <div
            style={{ fontSize: "20", marginBottom: "5%", fontWeight: "500" }}
          >
            At The Right Guru Institute, we proudly uphold a legacy of
            excellence in education. Our institution is a renowned name that
            stands as the premier destination for holistic learning and academic
            preparation. Established in 2021, we have rapidly grown to be a
            cornerstone of quality education, serving over 2000 students across
            diverse educational pathways.
          </div>
          <div
            style={{ fontWeight: "600", fontSize: "24px", marginBottom: "2%" }}
          >
            Our Vision:
          </div>
          <div
            style={{ fontSize: "24", marginBottom: "5%", fontWeight: "500" }}
          >
            Our vision is to be the guiding light for students on their journey
            towards success. Through our innovative teaching methods and
            comprehensive approach, we strive to empower students to achieve
            their academic goals and excel in various competitive examinations.
          </div>
          <div
            style={{ fontWeight: "600", fontSize: "24px", marginBottom: "2%" }}
          >
            Comprehensive Educational Offerings:
          </div>
          <div
            style={{ fontSize: "20", marginBottom: "5%", fontWeight: "500" }}
          >
            <span style={{ fontWeight: "600" }}>
              1. Tailored Learning Modes:
            </span>
            With an unwavering commitment to student-centric education, we
            provide a trifecta of learning modes - online, offline, and home
            tuition. We recognize that every student's learning style is unique,
            and we offer flexibility that caters to individual preferences.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>
              2. Holistic Learning Spectrum:
            </span>{" "}
            From classes 6th to 12th, we offer comprehensive educational
            solutions that span across various academic levels. Whether it's
            mastering core subjects or preparing for competitive exams like SSC,
            BANK, and College Entrance exams (CUET), our courses are
            meticulously designed to provide a solid foundation.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>3. Experienced Faculty:</span>
            Our team of dedicated educators comprises seasoned professionals
            from prestigious institutions. Their expertise ensures that students
            receive quality guidance and mentorship, promoting a deeper
            understanding of subjects and exam strategies.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>4. Modern Learning Tools:</span>
            We are at the forefront of modern education. Our institution
            seamlessly integrates the latest educational technologies and
            methodologies to enhance the learning experience and engagement of
            our students.
            <br />
            <br />
            <span style={{ fontWeight: "600" }}>5. Personalized</span>
            Attention: At The Right Guru, every student is an individual with
            unique aspirations. We focus on providing personalized attention and
            support to ensure each student's growth and success.
          </div>
          <div
            style={{ fontWeight: "600", fontSize: "24px", marginBottom: "2%" }}
          >
            Our Impact:
          </div>
          <div
            style={{ fontSize: "22", fontWeight: "500", marginBottom: "5%" }}
          >
            In just a short span of time, our institution has made a significant
            impact on the educational landscape. We have emerged as a go-to
            platform for holistic education that goes beyond textbooks,
            nurturing well-rounded individuals ready to conquer academic
            challenges and life's opportunities.
          </div>
        </div>
      </section>

      <section id="footer">
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}
