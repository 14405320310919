import React, { useState, useEffect, useLayoutEffect } from "react";
import "./style.css";
import moment from "moment";
import { Link } from "react-router-dom";
import logo from "./img/logo123.png";
import { Form, Col, Table } from "react-bootstrap";
import { DatePicker, Radio, Space } from 'antd';
import Dropdown_employee from "../dropdown/dropdown_employee";
import {
  MdDashboard, 
  MdSchool,
} from "react-icons/md";
import vector_2_2x from "./img/vector-2@2x.png";
import line_5_2x from "./img/line-5@2x.svg";
import vector_5_2x from "./img/vector-5@2x.png";
import line_6_2x from "./img/line-6@2x.svg";
import a58341_1_2x from "./img/5834-1-1@2x.png";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SideDrawerAccounts from "../../../UI/sideDrawer/sideDrawerAccounts";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import pic_2 from "../../student/dashboard/img/pic_2.png";
import pic_3 from "../../student/dashboard/img/pic_3.png";

import {
  accountStudentTotal,
  accountEmployeeTotal
} from "../../../crud/accounts.crud";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteAccounts } from "../../../redux/actions/accounts";

function AccountsDashboard(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [employeeType, setEmployeeName] = useState("");
  const [create_employee_id, setEmployeeNameCreate] = useState("");
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const user = useSelector((state) => state.accountsDetails.accounts);
  const [handleFilter, setHandleFilter] = useState("");
  const [incomingStartDate, setIncomingStartDate] = useState("");
  const [incomingEndDate, setIncomingEndDate] = useState("");
  const [outgoingStartDate, setOutgoingStartDate] = useState("");
  const [outgoingEndDate, setOutgoingEndDate] = useState("");
  const { RangePicker } = DatePicker;

  const handleEmployeeSelect = (value) => {
    setEmployeeName(value);
  };
  const handleEmployeeSelectCreate = (value) => {
    setEmployeeNameCreate(value);
  };
  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  const onClickIncomingDate = (value, event) => {
    setIncomingStartDate(moment(value[0]).format("YYYY-MM-DD"));
    setIncomingEndDate(moment(value[1]).format("YYYY-MM-DD"));
  };

  const onClickOutgoingDate = (value, event) => {
    setOutgoingStartDate(moment(value[0]).format("YYYY-MM-DD"));
    setOutgoingEndDate(moment(value[1]).format("YYYY-MM-DD"));
  };

  const [studentTransactions, setStudentTransactions] = useState([]);
  const [studentTransactionsTotal, setStudentTransactionsTotal] = useState('NA');
  const [employeeTransactions, setEmployeeTransactions] = useState([]);
  const [employeeTransactionsTotal, setEmployeeTransactionsTotal] = useState('NA');

  const getTotalIncome = () => {
    // call total student accounts api
    accountStudentTotal(incomingStartDate, incomingEndDate)
      .then(res => {
        setStudentTransactions(res.total_incoming);
        setStudentTransactionsTotal(res.total_sum);
      })
    .catch(err => console.log(err))
  }

  const getTotalExpenditure = () => {
    // call total Employees accounts api

    accountEmployeeTotal(outgoingStartDate, outgoingEndDate, employeeType)
      .then(res => {
        setEmployeeTransactions(res.total_outgoing);
        setEmployeeTransactionsTotal(res.total_sum);
        console.log(res);
      })
    .catch(err => console.log(err))
  }
  

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(deleteAccounts(user.accounts_employee_id));
    removeToken();
  };

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="accounts-dashboard"
      />
      <div className="container-center-horizontal">
        <div className="accounts-dashboard screen mobile-help">
          <div className="overlap-group1-C61RwL-accounts-dashboard">
            <div className="rectangle-1-RH0WJ5"></div>
            <Link to="/" onClick={() => handleLogout()}>
              <div
                className="logout-RH0WJ5"
                style={{ color: "rgba(179,179,179,1.0)" }}
              >
                Logout
              </div>
            </Link>
            <div className="component-1-RH0WJ5">
              <img
                alt="image"
                src={pic_3}
                style={{ height: "115px", marginTop: "0%", marginLeft: "14%" }}
              />
              {/* <img className="logo1122" src={logo} alt="logo" /> */}
            </div>
            <Link to="/accounts-dashboard">
              <div className="dashboard-RH0WJ5">Dashboard</div>
            </Link>
            <Link to="/accounts-employees">
              <div className="employee-RH0WJ5">Employee</div>
            </Link>

            <div className="school-24px-1-RH0WJ5">
              <Link to="/accounts-employees">
                <SupervisorAccountIcon
                  style={{ color: "rgba(179,179,179,1.0)" }}
                />
              </Link>
            </div>
            <div className="vector-RH0WJ5">
              <Link to="/accounts-dashboard">
                <MdDashboard size={24} color="#dc3545" />
              </Link>
            </div>
            <Link to="/accounts-student">
              <div className="previous-issues-RH0WJ5">My Students</div>
            </Link>

            <div className="historytoggleoff-24px-1-RH0WJ5">
              <div className="group-YrhztS">
                <Link to="/accounts-student">
                  <MdSchool size={24} color="rgba(179,179,179,1.0)" />
                </Link>
              </div>
            </div>

            <div
              className="logout-24px-3-RH0WJ5"
              style={{ color: "rgba(179,179,179,1.0)" }}
            >
              <PowerSettingsNewIcon size={24} color="rgba(179,179,179,1.0)" />
            </div>
          </div>
          <div className="flex-col-C61RwL1">
            <div className="flex-row-0xy0vn">
              <div className="notifications-24px-2-1-GyJUNH"></div>
              <div className="group-22-GyJUNH">
                <div className="biba-sobti-vgli2x">
                  {user && user.first_name} {user && user.last_name}
                </div>
                <div className="bibasobtigmailcom-vgli2x">
                  {user && user.emailID}
                </div>
              </div>
              <img className="vector-GyJUNH" src={vector_5_2x} alt="line" />
            </div>

            <div style={{ top: "-5rem", margin: "50px" }}>
              <div
                className="overlap-group-0xy0vn-accounts-dashboard"
                style={{ padding: "5px 3px" }}
              >
                <div className="heading-1">Total Income</div>

                <div className="buttons-box-1_2g">
                  <div className="range-selector-part">
                    <div className="select-dates">Select Range</div>

                    <Col className="style-a-xdjkhs-1-accounts-dashboard">
                      <RangePicker
                        className="datePicker-inshc"
                        showNow
                        format="DD-MM-YYYY"
                        onChange={onClickIncomingDate}
                      />
                    </Col>
                    <div className="filter-class">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={getTotalIncome}
                      >
                        Submit
                      </button>
                    </div>
                  </div>

                  <div
                    id="table_income"
                    className="class-table-income"
                    style={{ overflow: "auto", width: "100%", height: "200px" }}
                  >
                    <Table bordered hover>
                      <thead>
                        <tr>
                          <th>Transaction ID</th>
                          <th>Trial Student ID</th>
                          <th>Payment for</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Amount</th>
                          <th>Payment-Mode</th>
                        </tr>
                      </thead>

                      {studentTransactions.map((transaction) => (
                        <tbody key={transaction.student_transaction_id}>
                          <tr>
                            <th>{transaction.student_transaction_id}</th>
                            <th>{transaction.trial_student_id}</th>
                            <th>{transaction.payment_for}</th>
                            <th>
                              {moment(transaction.created_on).format(
                                "DD/MM/YYYY"
                              )}
                            </th>
                            <th>
                              {moment(transaction.created_on).format("LTS")}
                            </th>
                            <th>{transaction.amount}</th>
                            <th>{transaction.method}</th>
                          </tr>
                        </tbody>
                      ))}
                    </Table>
                  </div>
                  <div className="total-income-box">
                    Total income = {studentTransactionsTotal}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: "40px",
                borderBottom: "3px solid #ec3545",
                width: "100%",
                marginLeft: "57px",
              }}
            ></div>
            <div style={{ top: "-8rem", margin: "50px" }}>
              <div
                className="overlap-group-0xy0vn-accounts-dashboard-1"
                style={{ padding: "3px" }}
              >
                <div className="heading-2">Total Expenditure</div>

                <div className="buttons-box-2_2g">
                  <div className="range-selector-part-1">
                    <div className="select-type">
                      <Dropdown_employee
                        Style={{ top: "-5px", left: "-8px" }}
                        handleEmployeeSelect={handleEmployeeSelect}
                      />
                    </div>

                    <div className="select-dates-1">Select Range</div>

                    <Col className="style-a-xdjkhs-2-accounts-dashboard">
                      <RangePicker
                        className="datePicker-inshc"
                        showNow
                        format="DD-MM-YYYY"
                        onChange={onClickOutgoingDate}
                      />
                    </Col>
                    <div
                      className="filter-class-1"
                      style={{ marginTop: "-13px" }}
                    >
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={getTotalExpenditure}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  <div
                    id="table_expenditure"
                    className="class-table-expenditure"
                    style={{ overflow: "auto", width: "100%", height: "200px" }}
                  >
                    <Table bordered hover>
                      <thead>
                        <tr>
                          <th>Payment ID</th>
                          <th>Employee Type</th>
                          <th>From account_number</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Amount</th>
                          <th>Payment-Mode</th>
                        </tr>
                      </thead>

                      {employeeTransactions &&
                        employeeTransactions.map((transaction) => (
                          <tbody key={transaction.payemnt_ID}>
                            <tr>
                              <th>{transaction.payment_ID}</th>
                              <th>{transaction.employeeType}</th>
                              <th>{transaction.from_account_number}</th>
                              <th>
                                {moment(transaction.date_time).format("L")}
                              </th>
                              <th>
                                {moment(transaction.date_time).format("LTS")}
                              </th>
                              <th>{transaction.amount}</th>
                              <th>{transaction.payment_mode}</th>
                            </tr>
                          </tbody>
                        ))}
                    </Table>
                  </div>
                  <div className="total-income-box-1">
                    Total expenditure = {employeeTransactionsTotal}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-center-horizontal">
        <div className="sales-dashboard-mobile screen">
          <div className="flex-row-C61RwL">
            <div className="component-1-dXBp89">
              {/* <img
                alt="image"
                src={pic_2}
                style={{ height: "90px", marginTop: "-8%" }}
              /> */}
              {/* <img className="line-5-Q41bBg" src={line_5_2x} alt="line" />
              <img className="line-6-Q41bBg" src={line_6_2x} alt="line" /> */}
            </div>

            <SideDrawerAccounts
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />
            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>

          {/* Vatsal added this code */}

          <div className="card">
            <div className="overlap-group-0xy0vn-accounts-dashboard">
              <div
                className="heading-1"
                style={{ margin: "auto", width: "auto", textAlign: "center" }}
              >
                Total Income
              </div>

              <div className="buttons-box-1_2g">
                <div className="select-dates">Select Range</div>
                <div className="mobile-calendar">
                  <Col>
                    <RangePicker
                      className="datePicker-inshc"
                      showNow
                      format="DD-MM-YYYY"
                      onChange={onClickIncomingDate}
                    />
                  </Col>
                </div>

                <div className="filter-class">
                  <button
                    type="button"
                    className="btn btn-danger mobile-button"
                    onClick={getTotalIncome}
                  >
                    Submit
                  </button>
                </div>

                <div
                  id="table_income_mobile"
                  className="class-table-income"
                  style={{
                    width: "300px",
                    overflow: "scroll",
                    fontSize: "12px",
                  }}
                >
                  <Table bordered hover>
                    <thead>
                      <tr>
                        <th>Transaction ID</th>
                        <th>Trial Student ID</th>
                        <th>Payment for</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Amount</th>
                        <th>Payment-Mode</th>
                      </tr>
                    </thead>

                    {studentTransactions.map((transaction) => (
                      <tbody key={transaction.student_transaction_id}>
                        <tr>
                          <th>{transaction.student_transaction_id}</th>
                          <th>{transaction.trial_student_id}</th>
                          <th>{transaction.payment_for}</th>
                          <th>
                            {moment(transaction.created_on).format(
                              "DD/MM/YYYY"
                            )}
                          </th>
                          <th>
                            {moment(transaction.created_on).format("LTS")}
                          </th>
                          <th>{transaction.amount}</th>
                          <th>{transaction.method}</th>
                        </tr>
                      </tbody>
                    ))}
                  </Table>
                </div>
                <div
                  className="total-income-box"
                  style={{
                    margin: "auto",
                    width: "auto",
                    textAlign: "center",
                    fontSize: "21px",
                    marginTop: "18px",
                  }}
                >
                  Total income = {studentTransactionsTotal}
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="overlap-group-0xy0vn-accounts-dashboard">
              <div
                className="heading-2"
                style={{ margin: "auto", width: "auto", textAlign: "center" }}
              >
                Total Expenditure
              </div>

              <div className="buttons-box-2_2g">
                <div className="select-type-mobile">
                  <Dropdown_employee
                    handleEmployeeSelect={handleEmployeeSelect}
                  />
                </div>
                <div className="select-dates">Select Range</div>
                <div className="mobile-calendar">
                  <Col>
                    <RangePicker
                      className="datePicker-inshc"
                      showNow
                      format="DD-MM-YYYY"
                      onChange={onClickOutgoingDate}
                    />
                  </Col>
                </div>
                <div className="filter-class-1-mobile">
                  <button
                    type="button"
                    className="btn btn-danger mobile-button"
                    onClick={getTotalExpenditure}
                  >
                    Submit
                  </button>
                </div>
                <div
                  id="table_expenditure"
                  className="class-table-expenditure"
                  style={{
                    width: "300px",
                    overflow: "scroll",
                    fontSize: "12px",
                  }}
                >
                  <Table bordered hover>
                    <thead>
                      <tr>
                        <th>Payment ID</th>
                        <th>Employee Type</th>
                        <th>From account_number</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Amount</th>
                        <th>Payment-Mode</th>
                      </tr>
                    </thead>

                    {employeeTransactions &&
                      employeeTransactions.map((transaction) => (
                        <tbody key={transaction.payment_ID}>
                          <tr>
                            <th>{transaction.payment_ID}</th>
                            <th>{transaction.employeeType}</th>
                            <th>{transaction.from_account_number}</th>
                            <th>{moment(transaction.date_time).format("L")}</th>
                            <th>
                              {moment(transaction.date_time).format("LTS")}
                            </th>
                            <th>{transaction.amount}</th>
                            <th>{transaction.payment_mode}</th>
                          </tr>
                        </tbody>
                      ))}
                  </Table>
                </div>
                <div
                  className="total-income-box-1"
                  style={{
                    margin: "auto",
                    width: "auto",
                    textAlign: "center",
                    fontSize: "21px",
                    marginTop: "18px",
                  }}
                >
                  Total expenditure = {employeeTransactionsTotal}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountsDashboard;
