import React, { Fragment } from "react";
import "./sideDrawer.css";

import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteAccounts } from "../../redux/actions/accounts";

import DashboardIcon from "@material-ui/icons/Dashboard";
import SchoolIcon from "@material-ui/icons/School";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import Backdrop from "../Backdrop/Backdrop";
import { removeToken } from "../../utils";

const SideDrawerAccounts = (props) => {
  let attclasses = ["sideDrawer", "Close_SideD"];

  if (props.open) {
    attclasses = ["sideDrawer", "Open_SideD"];
    }
    
const dispatch = useDispatch();
const user = useSelector((state) => state.accountsDetails.accounts);

  const handleLogout = () => {
    dispatch(deleteAccounts(user.accounts_employee_id));
    removeToken();
    };
    
  return (
    <Fragment>
      <Backdrop open={props.open} clicked={props.closeSideDrawer} />
      <div className={attclasses.join(" ")}>
        <div
          style={{
            width: "70%",
            height: "70px",
            border: "1px solid #F4F4F4",
            marginLeft: "30px",
          }}
        ></div>

        <NavLink to="/accounts-dashboard" exact>
          <div className="sider-ele-mob">
            <DashboardIcon />
            <span className="sider-ele-mob-tag">Dashboard</span>
          </div>
        </NavLink>

        <NavLink to="/accounts-student" exact>
          <div className="sider-ele-mob">
            <SchoolIcon />
            <span className="sider-ele-mob-tag">Students</span>
          </div>
        </NavLink>

        <NavLink to="/accounts-employees" exact>
          <div className="sider-ele-mob">
            <SupervisorAccountIcon />
            <span className="sider-ele-mob-tag">Employees</span>
          </div>
        </NavLink>
        <NavLink to="/" exact onClick={() => handleLogout()}>
          <div className="sider-ele-mob">
            <PowerSettingsNewIcon />
            <span className="sider-ele-mob-tag">Logout</span>
          </div>
        </NavLink>
      </div>
    </Fragment>
  );
};

export default SideDrawerAccounts;
