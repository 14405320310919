import React, { useState, useEffect, useLayoutEffect } from "react";
import "./style.css";
import { Link, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import moment from 'moment';
import Modal from 'react-modal';
import { Button } from "semantic-ui-react";
import { FaRegQuestionCircle, FaCalendarAlt, FaFileAlt, FaChartPie, FaStickyNote, FaPowerOff } from "react-icons/fa";
import { MdAssignment, MdDashboard, MdNotificationsNone, MdSchool } from "react-icons/md";
import { BiSupport, BiHistory } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { AiTwotoneBank } from "react-icons/ai";
import line_5_2x from "./img/line-5@2x.svg";
import line_6_2x from "./img/line-6@2x.svg";
import vector_2x from "./img/vector@2x.png";
import vector_1_2x from "./img/vector-1@2x.svg";
import line_5_1_2x from "./img/line-5-1@2x.svg";
import line_6_1_2x from "./img/line-6-1@2x.svg";
import vector_2_2x from "./img/vector-2@2x.svg";
import ellipse_2_1_2x from "./img/ellipse-2-1@2x.svg";
import rectangle_7_1x from "./img/rectangle-7@1x.png";
import vector_7_2x from "./img/vector-7@2x.svg";
import vector_8_2x from "./img/vector-8@2x.svg";
import SideDrawerRm from '../../../UI/sideDrawer/sideDrawerRm';
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from '../../../utils';
import { deleteRm } from '../../../redux/actions/rm';
import Popup from '../../teacher/popup/popup';
import { deleteSlot, cancelClass, cancelClassWithSlot, getSingleClass, cancelTrialClass, getSingleTrialClass } from '../../../crud/rm.crud';
import { getSlots } from '../../../crud/teacher.crud';
import RmIssueList from '../table_dash/rm_table';
import { studentClassList } from '../../../crud/student.crud';
import pic_2 from "../../student/dashboard/img/pic_2.png";
function Relationmanagertimecalendar(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  const { userId, date } = useParams();
  const [studentClasses, setStudentClasses] = useState([]);
  const [teacherSlots, setTeacherSlots] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const user = useSelector((state) => state.rmDetails.rm);

  useEffect(() => {
    if (userId.includes("ES")) {
      studentClassList(userId, date)
        .then(res => {
          if (res.hasOwnProperty("message")) {
            setStudentClasses(false);
          } else setStudentClasses(res);
        })
        .catch(err => console.log(err))
    } else if (userId.includes("TC")) {
      getSlots(userId, date)
        .then(res => {
          if (res.hasOwnProperty("message")) {
            setTeacherSlots(false);
          } else setTeacherSlots(res);
        })
        .catch(err => console.log(err))
    }
  }, [isUpdated])

  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [isClassModalOpen, setIsClassModalOpen] = useState(false);
  const [isSlotModalOpen, setIsSlotModalOpen] = useState(false);
  const [canClass, setCanClass] = useState({});
  const [delSlot, setDelSlot] = useState({});
  const [delTime, setDelTime] = useState("");
  const [delDate, setDelDate] = useState("");

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.rmDetails.rm);
  const handleLogout = () => {
    dispatch(deleteRm(reduxUser.rm_id))
    removeToken();
  }

  const closeClassModal = () => setIsClassModalOpen(false);
  const closeSlotModal = () => setIsSlotModalOpen(false);

  const sideDrawerToggler = () => {
    setDisplaySideDrawer(previousState => !previousState);
  };

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="relation-manager-time-calendar"
      />
      <div className="container-center-horizontal">
        <div className="relational-manager-calendar-time-mobile screen">
          <div className="flex-row-C61RwL">
            <div className="component-1-dXBp89">
              <img src={pic_2} style={{ height: "100px", margin: "-8%" }} />
            </div>
            <SideDrawerRm
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />

            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>
          <div className="group-87-C61RwL">
            <div className="component-7-D1n0rW">
              <div className="x4-i636398853103-j2Gv3j montserrat-semi-bold-blue-zodiac-64px"></div>
            </div>
            <div className="teacher-name-D1n0rW montserrat-medium-blue-zodiac-20px">
              Teacher Name
            </div>
            <div className="course-D1n0rW montserrat-medium-blue-zodiac-20px">
              Course
            </div>
            <div className="subject-D1n0rW montserrat-medium-blue-zodiac-20px">
              Subject
            </div>
            <div className="grade-D1n0rW montserrat-medium-blue-zodiac-20px">
              Grade
            </div>
          </div>
          <div className="flex-row-VMr6Om">
            <div className="component-4-BdpSxK">
              <div className="sections-teachi-g-i636398553102-fVQN4V">
                Total
                <br />
                Classes
              </div>
              <div className="x4-i636398553103-fVQN4V">23</div>
            </div>
            <div className="component-5-BdpSxK">
              <div className="sections-teachi-g-i636398653102-PBxgdW">
                Completed <br />
                Classes
              </div>
              <div className="x4-i636398653103-PBxgdW">21</div>
            </div>
          </div>
          <div className="choose-time-for-booking-C61RwL montserrat-medium-blue-zodiac-20px">
            Choose time for booking
          </div>
          <div className="flex-row-mzXdH9">
            <a href="javascript:history.back()">
              <div className="arrowback-24px-1-NwNIwt">
                <img className="vector-8g5xxj" src={vector_2x} />
                <img className="vector-P0KAxp" src={vector_1_2x} />
              </div>
            </a>
            <div className="go-back-NwNIwt montserrat-semi-bold-blue-zodiac-18px">
              Go Back
            </div>
            <div className="feb-18-2021-NwNIwt-10-10">
              {moment(date).format("MMM DD, YYYY")}
            </div>
          </div>
          <div className="group-112-C61RwL-11-1">
            <div className="rectangle-31-Ajm6uA-10-101">
              <div className="rectangle-31-Ajm6uA-10-101-head">
                <span>Time</span>
                <span>Class/Slot</span>
                <span>Action</span>
              </div>
              {userId.includes("ES") ? (
                studentClasses.length > 0 ? (
                  studentClasses.map((item) => {
                    var local = moment
                      .utc(item.datetime)
                      .local()
                      .format("YYYY-MM-DD HH:mm:ss");
                    var local_date = moment(local).format("YYYY-MM-DD");                  
                    if (local_date == date) {
                    return (
                      <div className="rectangle-40-PXwdVf">
                        <div className="thue-ee roboto-medium-blue-zodiac-12px">
                          {moment(item.datetime).format("LT")}
                        </div>
                        <div className="thue-ee-1 roboto-medium-blue-zodiac-12px">
                          Class
                        </div>
                        <button
                          onClick={() => {
                            setIsClassModalOpen(true);
                            setCanClass(item);
                          }}
                          style={{ border: "none", background: "none" }}
                        >
                          <div className="delete-PXwdVf">Cancel</div>
                        </button>
                      </div>
                    );                      
                    }

                  })
                ) : (
                  <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                    No classes found.
                  </div>
                )
              ) : teacherSlots.length > 0 ? (
                  teacherSlots.map((item) => {
                    var datetime = moment
                      .utc(item.date + " " + item.time)
                      .format();
                    var local = moment
                      .utc(datetime)
                      .local()
                      .format("YYYY-MM-DD HH:mm:ss");
                    var local_date = moment(local).format("YYYY-MM-DD");
                    var local_time = moment(local).format("HH:mm:ss");   
                    if (local_date == date) {
                  return (
                    <div className="rectangle-40-PXwdVf">
                      <div className="thue-ee roboto-medium-blue-zodiac-12px">
                        {moment(local).format("HH:mm:ss")}
                      </div>
                      <div className="thue-ee-1 roboto-medium-blue-zodiac-12px">
                        Slot
                      </div>
                      <button
                        onClick={() => {
                          setIsSlotModalOpen(true);
                          setDelSlot(item);
                          setDelTime(local_time);
                          setDelDate(local_date);                          
                        }}
                        style={{ border: "none", background: "none" }}
                      >
                        <div className="delete-PXwdVf">Remove</div>
                      </button>
                    </div>
                  );                      
                    }
                })
              ) : (
                <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                  No slots found.
                </div>
              )}
            </div>

            {/* <div className="rectangle-40-Ajm6uA"></div>
            <div className="rectangle-41-Ajm6uA"></div>
            <div className="rectangle-47-Ajm6uA"></div>
            <div className="x12pm-Ajm6uA roboto-medium-blue-zodiac-14px">12pm</div>
            <div className="x1pm-Ajm6uA roboto-medium-blue-zodiac-14px">1pm</div>
            <div className="organic-4-Ajm6uA roboto-medium-blue-zodiac-14px">Organic-4</div>
            <div className="completed-Ajm6uA roboto-medium-blue-zodiac-14px">Completed</div>
            <div className="group-90-Ajm6uA">
              <div className="rectangle-49-OUIM6H"></div>
              <div className="x2pm-OUIM6H roboto-medium-blue-zodiac-14px">2pm</div>
              <div className="add-OUIM6H roboto-medium-niagara-14px">Add</div>
            </div>
            <div className="group-92-Ajm6uA">
              <div className="rectangle-44-JoE0OI"></div>
              <div className="x4pm-JoE0OI roboto-medium-blue-zodiac-14px">4pm</div>
              <div className="add-JoE0OI roboto-medium-niagara-14px">Add</div>
            </div>
            <div className="group-93-Ajm6uA">
              <div className="rectangle-50-mdOnc7"></div>
              <div className="x5pm-mdOnc7 roboto-medium-blue-zodiac-14px">5pm</div>
              <div className="add-mdOnc7 roboto-medium-niagara-14px">Add</div>
            </div>
            <div className="group-91-Ajm6uA">
              <div className="rectangle-48-bC1JcG"></div>
              <div className="x3pm-bC1JcG roboto-medium-blue-zodiac-14px">3pm</div>
              <div className="organic-5-bC1JcG roboto-medium-blue-zodiac-14px">Organic-5</div>
              <div className="upcoming-bC1JcG roboto-medium-blue-zodiac-14px">Upcoming</div>
              <div className="delete-bC1JcG">Delete</div>
            </div>
            <div className="status-Ajm6uA roboto-bold-gray-16px">Status</div>
            <div className="time-Ajm6uA roboto-bold-gray-16px">Time</div>
            <div className="class-name-Ajm6uA roboto-bold-gray-16px">Class Name</div>
            <div className="add-delete-Ajm6uA roboto-bold-gray-16px">Add/Delete</div> */}
          </div>
          <div className="group-210-C61RwL">
            <RmIssueList userState={user} />
          </div>
        </div>
      </div>
      <div className="container-center-horizontal">
        <div className="relation-manager-time-calendar screen">
          <div className="group-209-C61RwL-10-1">
            <Link to="/rm-dashboard">
              <MdDashboard
                size={24}
                data-tip="Dashboard"
                color="rgba(179,179,179,1.0)"
              />
              <ReactTooltip />
            </Link>
            <div className="historytoggleoff-24px-1-D0BVcB">
              <div className="group-rAjf8W">
                <Link to="/rm-issue-list">
                  {" "}
                  <BiHistory
                    data-tip="Previous Issues"
                    size={24}
                    color="rgba(179,179,179,1.0)"
                  />
                  <ReactTooltip />
                </Link>
              </div>
            </div>
            <div className="school-24px-1-D0BVcB">
              <Link to="/rm-list">
                <MdSchool
                  data-tip="My Students"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
            <div className="help-24px-2-D0BVcB">
              <Link to="/rm-ticket-list">
                <FaRegQuestionCircle
                  data-tip="Tickets"
                  size={24}
                  color="white"
                />
                <ReactTooltip />
              </Link>
            </div>
            <div className="analytics-24px-4-D0BVcB">
              <Link to="/rm-statistics">
                <FaChartPie
                  data-tip="Analytics"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
            {/* <div className="accountbalance-24px-1-D0BVcB">
              <Link to="/rm-payments"><AiTwotoneBank data-tip="Payments" size={24} color="rgba(179,179,179,1.0)" /><ReactTooltip /></Link>
            </div> */}
            <div className="vector-Ayxm2x">
              <Link to="/" onClick={() => handleLogout()}>
                <FaPowerOff
                  data-tip="Logout"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
          </div>
          <div className="flex-col-C61RwL">
            <div className="flex-row-0xy0vn">
              <div className="component-1-GyJUNH">
                <img alt="image" src={pic_2} style={{ height: "100px" }} />
              </div>
              {/* <div className="notifications-24px-2-1-GyJUNH">
            <MdNotificationsNone size={28} />
            </div> */}
              <div className="notifications-24px-2-1-GyJUNH">
                <div className="vector-40xw7g">
                  <MdNotificationsNone size={28} />
                </div>
                <img className="ellipse-2-RYyMFx" src={ellipse_2_1_2x} />
              </div>
              <div className="group-22-GyJUNH">
                <div className="flex-col-vgli2x">
                  <div className="biba-sobti-h8V8ZR">
                    {reduxUser.first_name + " " + reduxUser.last_name}
                  </div>
                  <div className="bibasobtigmailcom-h8V8ZR">
                    {reduxUser.emailID}
                  </div>
                </div>
                <img className="vector-vgli2x" src={vector_2_2x} />
              </div>
            </div>
            <div className="flex-row-oV5UrE">
              <div className="flex-col-QkTteQ">
                <div className="flex-row-Fjgz1C">
                  <div className="overlap-group1-A6SpMB">
                    <div className="component-6-fhQzNT">
                      <div className="x4-i636387353103-hPEL6O montserrat-semi-bold-blue-zodiac-64px"></div>
                    </div>
                    <div className="teacher-name-fhQzNT montserrat-medium-blue-zodiac-24px">
                      {userId}
                    </div>
                  </div>
                </div>
                <div className="choose-time-for-booking-Fjgz1C">
                  Choose Time For Booking
                </div>
                <div className="flex-row-LOFkVa">
                  <a href="javascript:history.back()">
                    <div className="arrowback-24px-1-OlYFha">
                      <img className="vector-xKmPAn" src={vector_2x} />
                      <img className="vector-ZAQVda" src={vector_1_2x} />
                    </div>
                  </a>
                  <div className="go-back-OlYFha montserrat-semi-bold-gray-24px">
                    Go back
                  </div>
                  <div className="feb-18-2021-OlYFha">
                    {moment(date).format("MMM DD, YYYY")}
                  </div>
                </div>
                <div className="overlap-group-Fjgz1C">
                  <img className="rectangle-7-PXwdVf" src={rectangle_7_1x} />
                  <div className="rectangle-31-PXwdVf-10-1-1">
                    {userId.includes("ES") ? (
                      studentClasses.length > 0 ? (
                        studentClasses.map((item) => {
                    var local = moment
                      .utc(item.datetime)
                      .local()
                      .format("YYYY-MM-DD HH:mm:ss");
                          var local_date = moment(local).format("YYYY-MM-DD");  
                          if (local_date == date) {
                                                return (
                                                  <div className="rectangle-40-PXwdVf">
                                                    <div className="x12pm-PXwdVf-inai-aia roboto-medium-blue-zodiac-14px">
                                                      {moment(
                                                        item.datetime
                                                      ).format("LT")}
                                                    </div>
                                                    <div className="completed-PXwdVf roboto-medium-blue-zodiac-14px">
                                                      Class
                                                    </div>
                                                    <button
                                                      onClick={() => {
                                                        setIsClassModalOpen(
                                                          true
                                                        );
                                                        setCanClass(item);
                                                      }}
                                                      style={{
                                                        border: "none",
                                                        background: "none",
                                                      }}
                                                    >
                                                      <div className="delete-PXwdVf">
                                                        Cancel
                                                      </div>
                                                    </button>
                                                  </div>
                                                );
                    }      

                        })
                      ) : (
                        <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                          No classes found.
                        </div>
                      )
                    ) : teacherSlots.length > 0 ? (
                        teacherSlots.map((item) => {
                    var datetime = moment
                      .utc(item.date + " " + item.time)
                      .format();
                    var local = moment
                      .utc(datetime)
                      .local()
                      .format("YYYY-MM-DD HH:mm:ss");
                    var local_date = moment(local).format("YYYY-MM-DD");
                          var local_time = moment(local).format("HH:mm:ss");   

                          if (local_date == date) {
                                              return (
                                                <div className="rectangle-40-PXwdVf">
                                                  <div className="x12pm-PXwdVf roboto-medium-blue-zodiac-14px">
                                                    {moment(local).format(
                                                      "HH:mm:ss"
                                                    )}
                                                  </div>
                                                  <div className="completed-PXwdVf roboto-medium-blue-zodiac-14px">
                                                    Slot
                                                  </div>
                                                  <button
                                                    onClick={() => {
                                                      setIsSlotModalOpen(true);
                                                      setDelSlot(item);
                                                      setDelTime(local_time);
                                                      setDelDate(local_date);
                                                    }}
                                                    style={{
                                                      border: "none",
                                                      background: "none",
                                                    }}
                                                  >
                                                    <div className="delete-PXwdVf">
                                                      Remove
                                                    </div>
                                                  </button>
                                                </div>
                                              );
                    }      
                      })
                    ) : (
                      <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                        No slots found.
                      </div>
                    )}
                  </div>
                  <div className="status-PXwdVf roboto-medium-gray-20px">
                    Class/Slot
                  </div>
                  <div className="time-PXwdVf roboto-medium-gray-20px">
                    Time
                  </div>
                  <div className="add-delete-PXwdVf roboto-medium-gray-20px">
                    Action
                  </div>
                </div>
              </div>
              <div className="flex-col-jXTb6S">
                <div className="group-202-OSnTIv">
                  <RmIssueList userState={user} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isClassModalOpen}
        onRequestClose={closeClassModal}
        className="slot-modal"
      >
        <div className="slot-modal-title montserrat-semi-bold-blue-zodiac-18px">
          Confirm Class Cancelling
        </div>
        <div className="slot-modal-body">
          <div className="slot-details">
            Date: {moment(canClass.datetime).format("DD-MM-YYYY")} | Time:{" "}
            {moment(canClass.datetime).format("LT")} | For: {userId}
          </div>
          <Button
            color="red"
            onClick={() => {
              cancelClass({ ...canClass, status: "Cancelled" })
              // cancelClassWithSlot({ ...canClass, status: "Cancelled" })
                .then((res) => {
                  closeClassModal();
                  alert("Class cancelled successfully");
                  setIsUpdated(!isUpdated);
                })
                .catch((err) => console.log(err));
            }}
          >
            Confirm Cancellation
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={isSlotModalOpen}
        onRequestClose={closeSlotModal}
        className="slot-modal"
      >
        <div className="slot-modal-title montserrat-semi-bold-blue-zodiac-18px">
          Confirm Slot Removal
        </div>
        <div className="slot-modal-body">
          <div className="slot-details">
            Date: {delDate} | Time: {delTime} |
            For: {userId}
          </div>
          <Button
            color="red"
            onClick={() => {
              deleteSlot(delSlot.id)
                .then((res) => {
                  if (delSlot.status == "Booked") {
                    getSingleClass(date, delSlot.time, userId)
                      .then((res) =>
                        cancelClass({ ...res, status: "Cancelled" })
                      )
                      .catch((err) => console.log(err));

                    getSingleTrialClass(date, delSlot.time, userId)
                      .then((res) =>
                        cancelTrialClass({ ...res, status: "Cancelled" })
                      )
                      .catch((err) => console.log(err));
                  }
                  closeSlotModal();
                  alert("Slot removed successfully");
                  setIsUpdated(!isUpdated);
                })
                .catch((err) => console.log(err));
            }}
          >
            Confirm Removal
          </Button>
        </div>
      </Modal>
      <Popup />
    </>
  );
}

export default Relationmanagertimecalendar;