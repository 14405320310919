import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import trgApp from "./trg.png";
import appStore from "../../../components/icons/appStore.png";
import googlePlay from "../../../components/icons/googlePlay.png";

export default function TrgApp() {
  return (
    <Container fluid>
      <h1
        style={{
          fontFamily: "'Montserrat', Arial, Helvetica, sans-serif",
          fontWeight: "700",
          fontSize: "x-large",
          textAlign: "center",
          marginTop: "5%",
          marginBottom: "2",
          color: "#ffff",
          marginBottom: "3%",
        }}
      >
        DOWLOAD TRG LEARNING APP
      </h1>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "2% 0",
        }}
      >
        <div style={{ width: "100%", maxWidth: "1000px" }}>
          <img style={{ width: "100%", height: "auto" }} src={trgApp} alt="" />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row", // Stack links vertically on small screens
          justifyContent:"center",
          alignItems: "center",
          textAlign: "center",
          gap:50
        }}
      >
        <a
          href="https://play.google.com/store/apps/details?id=com.trg.TheRightGuru"
          target="_blank"
          rel="noreferrer"
          
        >
          <img src={googlePlay} width="150" alt="Google Play Store Link" />
        </a>
        <a
          href="https://apps.apple.com/in/app/the-right-guru/id1585378387"
          target="_blank"
          rel="noreferrer"
        >
          <img src={appStore} width="150" height="45" alt="App Store Link" />
        </a>
      </div>
    </Container>
  );
}
