import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import './rm_table.css'
import moment from "moment";
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { rmIssueList } from "../../../crud/rm.crud";
// import { useSelector, useDispatch } from "react-redux";
import { deleteRm } from '../../../redux/actions/rm';
import { WebSocketContext } from '../../../websocket2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

function CalenderCompStatus(props) {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    
    const user = props.userState;
    const reduxIssues = useSelector(state => state.rmDetails.issues);

    const [issues, setIssues] = useState([])
    const [newIssuesLength, setNewIssuesLength] = useState(0);

    useEffect(() => {
        if (reduxIssues && reduxIssues.length > newIssuesLength) {
            toast.info(`You have a new issue by ${reduxIssues[reduxIssues.length - 1].created_by}. Please open the issue to connect.`, {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setNewIssuesLength(prevState => prevState + 1);
        }
    }, [reduxIssues])


    const ws = useContext(WebSocketContext);

    const joinRoom = userId => {
        if (ws.socket.readyState === WebSocket.OPEN) {
            ws.sendMessage({
                'type': 'JOIN_CHANNEL',
                'group': userId,
                'user': user.rm_id,
                'message': 'RM joined rm_student specific group.'
            });
        } else {
            ws.socket.onopen = () => {
                ws.sendMessage({
                    'type': 'JOIN_CHANNEL',
                    'group': userId,
                    'user': user.rm_id,
                    'message': 'RM joined rm_student specific group.'
                });
            }
        }
    }

    useEffect(() => {
        rmIssueList(user && user.rm_id)
            .then(res => {
                if (res.hasOwnProperty("message")) {
                    setIssues(false)
                } else setIssues(res);
            })
            .catch(err => console.log(err))
    }, [])

    // const dispatch = useDispatch();
    // const reduxUser = useSelector((state) => state.rmDetails.rm);
    // const handleLogout = () => {
    //     dispatch(deleteRm(reduxUser.rm_id))
    //     removeToken();
    // }
    return (
        <div className="rm-status-table-c">

            {/* <div className="calender-user-dis">
                <div>
                    <BellOutlined className='notification-2' />
                </div>
                <div className="User-info">
                    <div className="user-info-in">
                        <div style={
                            {
                                fontSize: '24px',
                                fontWeight: 'bold',
                                color: '#dc3545'
                            }
                        }>{user && user.first_name} {user && user.last_name}</div>
                        <div>{user && user.emailID}</div>
                    </div>
                    <div >
                        <UserOutlined className="User-Icon" />
                    </div>
                </div>
            </div>
 */}

            <div className="content-rm-list-issue">
                <span className="content-rm-op-issue">
                    Open Issue List
                </span>

                {/* <span className="content-right-date">
                    {moment().format('MMM DD, YYYY')}
                    
                </span> */}
            </div>

            <div className="status4-in-rm-table">
                <div className="calender-rm-status">
                    <span className="calender-rm-item-sno">S. No.</span>
                    <span className="calender-rm-item-data">Student</span>
                    <span className="calender-rm-item-data margin-in-left">Issue Status</span>
                    <span className=" calender-rm-item-data">Date</span>
                    <span className="calender-rm-item-data">Category</span>

                </div>
                <div className="dic-ins-s-e-00039">
                    {
                        issues.length > 0 ?
                            issues.filter(iss => iss.status != "Resolved" && iss.status != "Resolved by RM").length > 0 ?
                            issues.filter(iss => iss.status != "Resolved" && iss.status != "Resolved by RM").map((issue, index) => {
                                return (
                                    <Link to={"/rm-issue-detail/" + issue.id} onClick={() => joinRoom(issue.object_id)} style={{ color: "#000" }}>
                                        <div key={issue.id} className="rm-in-status-x">
                                            {/* <div className="rm-in-status-x-in"> */}
                                            <span className="calender-rm-item-sno">{index + 1}</span>
                                            <span className="calender-rm-item-data">{issue.object_id}</span>
                                            <span className="calender-rm-item-data">{issue.status}</span>
                                            <span className="calender-rm-item-data">{moment(issue.date).format("DD-MM-YYYY")}</span>
                                            <span className="calender-rm-item-data">{issue.category}</span>
                                        </div>
                                    </Link>
                                )
                            })
                                : <div style={{ fontFamily: "'Montserrat', Helvetica" }}>No issues raised for you.</div>
                            : <div style={{ fontFamily: "'Montserrat', Helvetica" }}>No open issues for you.</div>
                    }

                </div>
                {/* <div className="classTime-select-comp">
                    <TimePicker
                        // use12Hours format="h:mm a"
                        onChange={setSelTime}
                        disableClock
                    />
                    <Button variant="danger" onClick={handleSubmit} disabled={selTime == ""} style={{ fontFamily: "'Montserrat', Helvetica", fontWeight: '600', fontSize: '12px' }}>
                        Open New Slot
                    </Button>
                </div> */}
            </div>
        </div>
    );
};

export default CalenderCompStatus;