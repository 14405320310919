import React, { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import LoginModal from "../../../components/Login_modal/loginModal";
import Footer from "../../../components/Footer";
import Popup2 from "../../non_auth/new_popup/popup2";
import CustomNav from "../../../components/header/Navbar";
import "./common.css";

Modal.setAppElement("#root");

const acturialScienceData = [
  {
    id: 1,
    data1: "Branch Name 	",
    data2: "Actuarial Science",
  },
  {
    id: 2,
    data1: "Degree 	",
    data2: "BA, BBA, B.Sc, MA, M.Com",
  },
  {
    id: 3,
    data1: "Average Salary 	",
    data2: "Rs. 14 LPA ( for Actuary)",
  },
  {
    id: 4,
    data1: "Duration	",
    data2: "UG: 3 years, PG: 2 years",
  },
  {
    id: 5,
    data1: "Eligibility Criteria 	",
    data2: "UG: 10+2, PG:  Bachelor’s Degree",
  },
  {
    id: 6,
    data1: "Top Entrance Exams ",
    data2: "CUET UG, ACET, and CUET PG",
  },
  {
    id: 7,
    data1: "Career Options	",
    data2:
      "Actuary, Accountants and Auditors, Budget Analysts, Financial Analyst, and Cost Estimates, and Underwriter.",
  },
  {
    id: 8,
    data1: "Recruiting Companies 	",
    data2:
      "ICICI Prudential, ING, E&Y, PwC, Allstate, Wills Towers Watson, The Hartford	",
  },
  {
    id: 9,
    data1: "Admission Process	",
    data2: "Entrance Examination/ Direct Admission",
  },
];

function ActurialScience() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section style={{ width: "100%", marginBottom: "3%" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              width: "80%",
              marginTop: "3%",
              height: "auto",
            }}
          >
            <h1 style={{ fontSize: 24, color: "#dc3545", fontWeight: 600 }}>
              About Actuarial Science
            </h1>
            <p
              style={{
                fontSize: 16,
                marginTop: "1%",
                fontWeight: 500,
                lineHeight: "1.6",
              }}
            >
              Actuarial Science is a specialized field within statistics and
              mathematics focused on the evaluation of financial risks prevalent
              in the insurance and finance sectors. This discipline employs
              diverse statistical and mathematical methods to quantify and
              analyze financial risks effectively. By utilizing probability
              analysis and statistics, actuarial science aims to address and
              solve uncertainties related to future financial events.
            </p>
            <br />
            <p
              style={{
                fontSize: 16,
                marginTop: "1%",
                fontWeight: 500,
                lineHeight: "1.6",
              }}
            >
              Actuarial Science is a specialized field within statistics and
              mathematics focused on the evaluation of financial risks prevalent
              in the insurance and finance sectors. This discipline employs
              diverse statistical and mathematical methods to quantify and
              analyze financial risks effectively. By utilizing probability
              analysis and statistics, actuarial science aims to address and
              solve uncertainties related to future financial events.
            </p>
            <br />
            <p
              style={{
                fontSize: 16,
                marginTop: "1%",
                fontWeight: 500,
                lineHeight: "1.6",
              }}
            >
              Actuarial Science is a specialized field within statistics and
              mathematics focused on the evaluation of financial risks prevalent
              in the insurance and finance sectors. This discipline employs
              diverse statistical and mathematical methods to quantify and
              analyze financial risks effectively. By utilizing probability
              analysis and statistics, actuarial science aims to address and
              solve uncertainties related to future financial events.
            </p>

            <div style={{ marginTop: "3%" }}>
              <h1 style={{ fontSize: 18, fontWeight: 600 }}>
                Highlights – Actuarial Science
              </h1>
              <div
                style={{
                  marginBottom: "2%",
                  marginTop: "2%",
                  overflowX: "auto",
                }}
              >
                <table style={{ width: "100%", margin: "0 auto" }}>
                  <tr>
                    <th style={{ textAlign: "center" }} className="heade">
                      Particulars
                    </th>
                    <th style={{ textAlign: "center" }} className="heade">
                      Details
                    </th>
                  </tr>
                  {acturialScienceData.map((data) => {
                    return (
                      <tr>
                        <td style={{ textAlign: "center" }}>{data.data1}</td>
                        <td style={{ textAlign: "center" }}>{data.data2}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>

            <h2 style={{ fontSize: 18, fontWeight: 600, marginTop: "2%" }}>
              Eligibility Criteria for Actuarial Science Programs (UG & PG)
            </h2>
            <p style={{ fontSize: 16, marginTop: "1%", lineHeight: "1.6" }}>
              Actuarial Science programs are available at both the undergraduate
              and postgraduate levels, catering to candidates with a strong
              interest in finance and mathematics. The eligibility criteria vary
              based on the educational level and the specific requirements of
              the institution. Below outlines the eligibility criteria for
              Actuarial Science courses.
            </p>
            <h2 style={{ fontSize: 16, fontWeight: 600, marginTop: "2%" }}>
              Eligibility Criteria for UG Courses
            </h2>

            <p style={{ fontSize: 16, marginTop: "1%", lineHeight: "1.6" }}>
              For undergraduate programs, candidates are generally required to
              successfully complete their 10+2 education with a specified valid
              score as mandated by the desired institute. It is recommended that
              candidates thoroughly review the eligibility criteria before
              applying for admission. The eligibility criteria for undergraduate
              Actuarial Science courses include:
            </p>

            <div style={{ lineHeight: "1.6", marginTop: "1%" }}>
              <h2 style={{ fontSize: 16, marginTop: "1%", fontWeight: 600 }}>
                <span>1. </span>Educational Qualifications:
              </h2>
              <p style={{ fontSize: 16, marginLeft: "1%" }}>
                <span style={{ fontWeight: 600 }}>- </span>Candidates must have
                completed their 10+2 examinations in either the Commerce or
                Mathematics stream.
              </p>
              <p style={{ fontSize: 16, marginLeft: "1%" }}>
                <span style={{ fontWeight: 600 }}>- </span>Proficiency in
                English, with it being one of the subjects studied as a first
                language.
              </p>
            </div>
            <div style={{ lineHeight: "1.6", marginTop: "1%" }}>
              <h2 style={{ fontSize: 16, marginTop: "1%", fontWeight: 600 }}>
                <span>2. </span>Age Restrictions:
              </h2>
              <p style={{ fontSize: 16, marginLeft: "1%" }}>
                <span style={{ fontWeight: 600 }}>- </span>There are typically
                no age restrictions for candidates appearing for the Actuarial
                Science exam.
              </p>
            </div>

            <div style={{ lineHeight: "1.6", marginTop: "1%" }}>
              <h2 style={{ fontSize: 16, marginTop: "1%", fontWeight: 600 }}>
                <span>3. </span>Aggregate Percentage:
              </h2>
              <p style={{ fontSize: 16, marginLeft: "1%" }}>
                <span style={{ fontWeight: 600 }}>- </span>Candidates are
                required to have a minimum aggregate score of 60% in their 10+2
                examinations.
              </p>
            </div>

            <div style={{ lineHeight: "1.6", marginTop: "1%" }}>
              <h2 style={{ fontSize: 16, marginTop: "1%", fontWeight: 600 }}>
                <span>4. </span>Mathematics Score:
              </h2>
              <p style={{ fontSize: 16, marginLeft: "1%" }}>
                <span style={{ fontWeight: 600 }}>- </span>Specifically,
                candidates should have obtained a minimum of 70% in Mathematics.
              </p>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: "2%",
                justifyContent: "end",
              }}
            >
              <a
                target="_blank"
                href="https://therightguru-website.s3.ap-south-1.amazonaws.com/Actuarial+Science+Exam/Actuarial+Science+Exam.pdf"
              >
                <div className="book-btn">Know more...</div>
              </a>
            </div>

            <h2 style={{ fontSize: 18, fontWeight: 600, marginTop: "2%" }}>
              Achieve Your Goals with THE RIGHT GURU Tuition Centre
            </h2>
            <p style={{ fontSize: 16, marginTop: "1%", lineHeight: "1.6" }}>
              Join us on a journey of growth, learning, and achievement. Whether
              you're aiming for top grades, entrance exams, or a bright future,
              we are here to provide the guidance and support you need to
              succeed.
            </p>
            <h2 style={{ fontSize: 16, marginTop: "1%" }}>
              For any query, Email us at:{" "}
              <span>
                <a href="mailto:contact@therightguru.com">
                  contact@therightguru.com
                </a>
              </span>
            </h2>
            <p style={{ fontSize: 16, marginTop: "1%", lineHeight: "1.6" }}>
              For a more personalized and detailed understanding, we encourage
              you to visit our centers and consult our dedicated counselors.
            </p>
          </div>
        </div>
      </section>

      <section>
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default ActurialScience;
