import axios from "axios";
import { toAPIURL } from "../../../utils";
import { GET_ACCOUNTS, DELETE_ACCOUNTS } from "./types";

// GET ACCOUNTS
export const getAccounts = (user_data, history) => dispatch => {
    axios
        .get(toAPIURL("api/accounts-details/" + user_data.email))
        .then(res => {
            dispatch({
                type: GET_ACCOUNTS,
                payload: res.data
            });
            history.replace("/accounts-dashboard")
        })
        .catch(err => console.log(err));
}

// DELETE ACCOUNTS
export const deleteAccounts = accountsId => ({
    type: DELETE_ACCOUNTS,
    payload: accountsId
})