import React, { useState, useEffect, useLayoutEffect } from "react";
import "./teacher_classroom_assignments_style.css";
// import { Dropdown } from "semantic-ui-react";
import { Link, NavLink, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Button } from "react-bootstrap";

import { MdAssignment, MdSchool } from "react-icons/md";
// import { BiHistory } from "react-icons/bi";
// import { RiQuestionnaireFill } from "react-icons/ri";
import vector_2x from "./img/vector@2x.png";
import vector_6_2x from "./img/vector-6@2x.svg";
import rectangle_7_1x from "./img/rectangle-7@1x.png";
import vector_2xs from "./img/vector@2x.svg";
import Popup from "../popup/popup";
import SideDrawer from "../../../UI/sideDrawer/sideDrawer";
import { S3taconfig } from "../../../s3/s3taconfig.js";
import S3FileUpload from "react-s3";
// import { uploadFile } from "react-s3";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteTeacher } from "../../../redux/actions/teacher";
import {
  teacherClassroomStudent,
  classroomUploadAssignment,
} from "../../../crud/teacher.crud";
import pic_2 from "../../student/dashboard/img/pic_2.png";

import { studentAssignments } from "../../../crud/student.crud";
import CalenderStatus from "../dashboard/CalenderComponent/calanderComponent";
import DropdownOption from "../../higher_committee/dropdown/dropdown2";

import DashboardIcon from "@material-ui/icons/Dashboard";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SchoolIcon from "@material-ui/icons/School";
import DescriptionIcon from "@material-ui/icons/Description";
import HelpIcon from "@material-ui/icons/Help";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import NotesIcon from "@material-ui/icons/Notes";

function Teacherassignmentlist(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { classroomID } = useParams();
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const user = useSelector((state) => state.teacherDetails.teacher);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [assignment_url, setAssignmentUrl] = useState("");
  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  const [subject_id, setSubjectName] = useState("");
  const [grade_id, setGradeName] = useState("");

  const handleGradeSelect = (value) => {
    setGradeName(value);
  };

  const handleSubjectSelect = (value) => {
    setSubjectName(value);
  };

  useEffect(() => {
    console.log(classroomID);
    teacherClassroomStudent(classroomID).then((res) => {
      console.log(res[0]);
      studentAssignments(res[0])
        .then((res) => {
          if (res.hasOwnProperty("message")) {
            setAssignments(false);
          } else setAssignments(res);
        })
        .catch((err) => console.log(err));
    });
  }, []);

  // useEffect(() => {
  //   teacherAssignmentStudent(id)
  //     .then(res => {
  //       setAssignment(res);
  //     })
  //     .catch(err => console.log(err))
  // }, [isSubmitted])

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteTeacher(reduxUser.teacher_id));
    removeToken();
  };

  function upload(e) {
    console.log(e.target.files[0]);
    S3FileUpload.uploadFile(e.target.files[0], S3taconfig)
      .then((data) => {
        setAssignmentUrl(data.location);
      })
      .catch((err) => {
        alert(err);
      });
  }

  const [assignmentName, setAssignmentName] = useState("");
  const [assignmentMarks, setAssignmentMarks] = useState("");
  const [assignmentSubject, setAssignmentSubject] = useState("");
  const [assignmentGrade, setAssignmentGrade] = useState("");

  const handleSubmit = () => {
    classroomUploadAssignment({
      assignment_meta_id:
        user.teacher_id + `1_${assignmentGrade}_${assignmentSubject}`,
      teacher_id: user.teacher_id,
      course_id: "ONRCCBSE",
      grade_id,
      subject_id,
      assignment_id: `${user.teacher_id}_${assignmentGrade}_${assignmentSubject}_${assignmentName}`,
      assignment_url: assignment_url,
      total_marks: assignmentMarks,
      assignment_tag: assignmentName,
      assignment_name: assignmentName,
      classroomID,
      marks: "NA",
      status: "Assigned",
      solution_url: "NA",
    })
      .then((res) => {
        alert("Assignment Updated Successfully!");
        setIsSubmitted(!isSubmitted);
      })
      .catch((err) => console.log(err));
  };

  const subjectOptions = [
    {
      key: "_1",
      text: "English",
      value: "English",
    },
    {
      key: "_2",
      text: "Hindi",
      value: "Hindi",
    },
    {
      key: "_3",
      text: "Science",
      value: "Science",
    },
    {
      key: "MTH",
      text: "Mathematics",
      value: "MTH",
    },
    {
      key: "_5",
      text: "Social Studies",
      value: "SST",
    },
  ];

  const gradeOptions = [
    {
      key: "_9",
      text: "9",
      value: "9",
    },
    {
      key: "_10",
      text: "10",
      value: "10",
    },
  ];

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="teacher-assignment-list-classroom"
      />
      <div className="container-center-horizontal">
        <form
          className="teacher-assignment-list-classroom screen"
          name="form2"
          action="form2"
          method="post"
        >
          <div className="sider-teacher-general-div">
            <div className="sider-teacher-in-general-80">
              <div style={{ marginTop: "120px" }} />
              <NavLink to="/teacher-dashboard" exact>
                <DashboardIcon
                  data-tip="Dashboard"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-date-calendar" exact>
                <EventIcon data-tip="Scheduler" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-previous-classes">
                <ScheduleIcon
                  data-tip="Previous Class"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-students">
                <SchoolIcon
                  data-tip="Enrolled Student"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-curriculum-list">
                <DescriptionIcon
                  data-tip="Curriculum"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-doubt-list">
                <HelpIcon data-tip="Doubts" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-analytics">
                <AssessmentIcon
                  data-tip="Analytics"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-assignments">
                <AssignmentIcon
                  data-tip="Assignments"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-quizzes">
                <LibraryBooksIcon
                  data-tip="Quizzes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-lecture-notes">
                <NotesIcon
                  data-tip="Lecture Notes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              {/* <NavLink to="/teacher-payments"><AccountBalanceIcon data-tip="Payment" className="icon-in-hc-color" /><ReactTooltip /></NavLink> */}
              <div style={{ marginTop: "10px" }} />
              <NavLink to="/" onClick={() => handleLogout()}>
                {" "}
                <PowerSettingsNewIcon
                  data-tip="Logout"
                  className="icon-in-hc-color"
                />{" "}
              </NavLink>
              <div style={{ marginTop: "1px" }} />
            </div>
          </div>
          <div className="flex-col-C61RwL">
            <div className="component-1-0xy0vn">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "115px", marginTop: "-8%" }}
              />
            </div>
            <div className="flex-row-0xy0vn">
              <a href="javascript:history.back()">
                <div className="arrowback-24px-1-fKmFVR">
                  <img className="vector-LysGKa" src={vector_2x} />
                  <img className="vector-ePmONu" src={vector_6_2x} />
                </div>
              </a>
              <div className="back-to-all-stu-and-classrooms-GyJUNH">
                <span className="span0-id3RTJ">Back to all</span>
                <span className="span1-id3RTJ"> Students and Classrooms </span>
              </div>
            </div>
            <div className="overlap-group-0xy0vn">
              <div className="group-46-y1HRxC">
                <div className="rectangle-40-DxZ3Ki"></div>
                <div className="vatsal-agarwal-DxZ3Ki montserrat-semi-bold-blue-zodiac-18px">
                  {classroomID}
                </div>
              </div>
              <div className="group-167-y1HRxC">
                <div className="component-29-I0Q5xv"></div>
                <div className="vector-I0Q5xv">
                  <MdSchool size={24} color="white" />
                </div>
              </div>
            </div>
            <div className="flex-row-oV5UrE">
              {assignments.length > 0 &&
              assignments.filter(
                (assgn) => assgn.meta.teacher_id === user.teacher_id
              ).length > 0 ? (
                assignments
                  .filter((assgn) => assgn.meta.teacher_id === user.teacher_id)
                  .map((assgn) => {
                    return (
                      <div className="group-57-QkTteQ">
                        <div className="rectangle-40-jEma4X">
                          <div className="vector-L9lx3B">
                            {" "}
                            <MdAssignment size={24} color="#dc3545" />{" "}
                          </div>
                          <div className="assignment-1-submitted-jEma4X montserrat-semi-bold-white-12px">
                            <span className="span0-90XWKj">
                              {assgn.assignment_data.assignment_name}
                              <br />
                            </span>
                            <span className="span1-90XWKj">
                              {assgn.student_response.status}
                            </span>
                          </div>
                          <Link
                            to={
                              "/teacher-group-classroom/" +
                              classroomID +
                              "/" +
                              assgn.assignment_data.id
                            }
                          >
                            <Button
                              variant="outline-danger"
                              className="rectangle-41-nxpXxz"
                              style={{
                                fontFamily: "'Montserrat', Helvetica",
                                fontSize: 7,
                                fontWeight: 600,
                              }}
                            >
                              Open
                            </Button>
                          </Link>
                        </div>
                      </div>
                    );
                  })
              ) : (
                <div className="group-57-QkTteQ">
                  No assignments given to this student.
                </div>
              )}
            </div>
            <div className="flex-row-4iD7zR">
              <div className="give-a-new-assignment-ossvxU">
                <span className="span0-PJpsBj">Give a</span>
                <span className="span1-PJpsBj"> new assignment</span>
              </div>
              <img className="rectangle-7-ossvxU" src={rectangle_7_1x} />
            </div>
            {/* <div className="flex-row-t6Autm">
              <div className="choose-from-exi-ing-assignments-gD87cZ">
                Choose from existing assignments
              </div>
              <Dropdown
                placeholder="Choose Assignment"
                search
                selection
                options={subjectOptions}
              />
            </div>
            <div className="or-0xy0vn montserrat-semi-bold-blue-zodiac-18px">
              OR
            </div> */}
            <div className="flex-row-uPORJx">
              <div className="Assignment-name-in-desk-3">
                <div className="upload-a-new-assignment-RgyNpo">
                  Upload a new assignment
                </div>
                <div className="overlap-group1-RgyNpo">
                  {/* <div className="rectangle-42-GC1et8"></div> */}
                  <div className="rectangle-42-GC1et8">
                    <label for="Id-in-input-c">+ Upload Assignment</label>
                    <input
                      id="Id-in-input-c"
                      style={{ display: "none" }}
                      type="file"
                      onChange={(e) => upload(e)}
                    />
                  </div>
                </div>
              </div>

              <div style={{ width: "92%", margin: "10px 30px" }}>
                <DropdownOption
                  handleGradeSelect={handleGradeSelect}
                  handleSubjectSelect={handleSubjectSelect}
                />
              </div>

              <div className="Assignment-name-in-desk">
                <div className="Assignment-name-head-div">Assignment Name</div>
                <input
                  type="text"
                  placeholder="Assignment Name"
                  onChange={(e) => setAssignmentName(e.target.value)}
                />
              </div>
              <div className="Assignment-name-in-desk">
                <div className="Assignment-name-head-div">Total Marks</div>
                <input
                  type="text"
                  placeholder="Total Marks"
                  onChange={(e) => setAssignmentMarks(e.target.value)}
                />
              </div>

              <div className="overlap-group2-0xy0vn">
                <Button
                  onClick={() => handleSubmit()}
                  variant="danger"
                  className="rectangle-43-52Wjwg"
                  style={{
                    fontFamily: "'Montserrat', Helvetica",
                    fontSize: 12,
                    fontWeight: 600,
                  }}
                >
                  Submit
                </Button>
                {/* <div className="rectangle-43-52Wjwg"></div> */}
                {/* <div className="submit-52Wjwg">Submit</div> */}
              </div>
            </div>
          </div>
          <div className="flex-col-VMr6Om">
            <div className="group-115-lcFreP">
              <div className="group-22-cLbj0f">
                <div className="flex-col-0sTO9E">
                  <div className="biba-sobti-JJC5aN">
                    {user.first_name + " " + user.last_name}
                  </div>
                  <div className="bibasobtigmailcom-JJC5aN">{user.emailID}</div>
                </div>
                <img className="vector-0sTO9E" src={vector_2xs} />
              </div>
            </div>
            <div className="group-114-lcFreP">
              <div className="group-113-8pxyib">
                <CalenderStatus />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="container-center-horizontal">
        <form
          className="teacher-assignment-list-mobile screen"
          name="form4"
          action="form4"
          method="post"
        >
          <div className="flex-row-C61RwL">
            <div className="component-1-dXBp8999">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "90px", marginTop: "-8%" }}
              />
            </div>

            {/* This is the Navbar */}
            <SideDrawer
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />

            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>
          <div className="group-145-C61RwL">
            <a href="javascript:history.back()">
              <div className="arrowback-24px-1-bxkR86">
                <img className="vector-uzm8xK" src={vector_2x} />
                <img className="vector-Ni3obm" src={vector_6_2x} />{" "}
              </div>
            </a>
            <div className="back-to-all-students-bxkR86">
              <span className="span0-f1YMcf">Back to All </span>
              <span className="span1-f1YMcf">Students</span>
            </div>
          </div>
          <div className="group-46-C61RwL">
            <div className="rectangle-40-AvtzuR"></div>
            <div className="vatsal-agarwal-AvtzuR">{classroomID}</div>
          </div>
          <div className="group-148-C61RwL">
            <div className="group-58-z6Eanu">
              {assignments.length > 0 &&
              assignments.filter(
                (assgn) => assgn.meta.teacher_id === user.teacher_id
              ).length > 0 ? (
                assignments
                  .filter((assgn) => assgn.meta.teacher_id === user.teacher_id)
                  .map((assgn) => {
                    return (
                      <div className="group-147-EaZ6o4">
                        <div className="rectangle-40-ggUFIx">
                          <div className="vector-0sFba3">
                            {" "}
                            <MdAssignment size={24} color="#dc3545" />{" "}
                          </div>
                          <div className="assignment-1-submitted-ggUFIx montserrat-semi-bold-white-12px">
                            <span className="span0-8H9rje">
                              {assgn.assignment_data.assignment_name}
                              <br />
                            </span>
                            <span className="span1-8H9rje">
                              {assgn.student_response.status}
                            </span>
                          </div>
                          <Link
                            to={
                              "/teacher-group-classroom/" +
                              classroomID +
                              "/" +
                              assgn.student_response.id
                            }
                          >
                            <Button
                              variant="outline-danger"
                              className="rectangle-41-ggUFIx"
                              style={{
                                fontFamily: "'Montserrat', Helvetica",
                                fontSize: 7,
                                fontWeight: 600,
                              }}
                            >
                              Open
                            </Button>
                          </Link>
                        </div>
                      </div>
                    );
                  })
              ) : (
                <div className="group-147-EaZ6o4">
                  No assignments given to this student.
                </div>
              )}
            </div>
          </div>
          <div className="group-150-C61RwL">
            <div className="give-a-new-assignment-XvbDcl">
              <span className="span0-gfYSUz">Give a new </span>
              <span className="span1-gfYSUz">Assignment</span>
            </div>
          </div>
          {/* <div className="choose-from-exi-ing-assignments-C61RwL">
            Choose from existing assignments
          </div> */}
          {/* <div className="component-30-C61RwL">
            <div className="choose-subject-i65717352371261-02ks1v">Select assignment</div>
            <div className="expandmore-24px-1-02ks1v">
              <img className="vector-wGeoOk" src={vector_2x} />
              <img className="vector-5FRq6D" src={vector_10_2x} />
            </div>
          </div> */}
          {/* <Dropdown
            placeholder="Choose Assignment"
            search
            selection
            options={subjectOptions}
          />
          <div className="or-C61RwL">OR</div> */}
          <div className="upload-a-new-assignment-C61RwL">
            Upload a new assignment
          </div>
          <div className="group-151-C61RwL">
            {/* <div className="rectangle-42-nqcpBp"></div>className="rectangle-42-nqcpBp" */}
            <label for="Input-in-10c">+ Upload Assignment</label>
            <input
              id="Input-in-10c"
              style={{ display: "none" }}
              type="file"
              onChange={(e) => upload(e)}
            />
            {/* <div className="choose-file-nqcpBp">Choose File</div> */}
          </div>

          <div style={{ width: "92%", marginTop: "10px" }}>
            <DropdownOption
              handleGradeSelect={handleGradeSelect}
              handleSubjectSelect={handleSubjectSelect}
            />
          </div>

          <div className="Assignment-name-in-desk">
            <div className="Assignment-name-head-div">Assignment Name</div>
            <input
              type="text"
              placeholder="Assignment Name"
              className="merge-in-input"
              onChange={(e) => setAssignmentName(e.target.value)}
            />
          </div>
          <div className="Assignment-name-in-desk">
            <div className="Assignment-name-head-div">Total Marks</div>
            <input
              type="text"
              placeholder="Total Marks"
              className="merge-in-input"
              onChange={(e) => setAssignmentMarks(e.target.value)}
            />
          </div>

          <div className="overlap-group-C61RwL">
            <Button
              onClick={() => handleSubmit()}
              variant="danger"
              className="rectangle-43-4eduM0"
              style={{
                fontFamily: "'Montserrat', Helvetica",
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              Submit
            </Button>
            {/* <a href="javascript:SubmitForm('form4')"> <div className="rectangle-43-4eduM0"></div></a>
            <div className="submit-4eduM0">Submit</div> */}
          </div>
          <div className="group-154-C61RwL">
            <CalenderStatus />
          </div>
        </form>
      </div>
      <Popup />
    </>
  );
}

function Component1(props) {
  const { line5, line6 } = props;

  return (
    <div className="component-1 border-1px-blue-zodiac">
      <div className="overlap-group18">
        <img className="line-5" src={line5} />
        <img className="line-6" src={line6} />
      </div>
    </div>
  );
}

function Component29(props) {
  const { overlapGroup3, vector, vector2 } = props;

  return (
    <div className="component-29">
      <div
        className="overlap-group-3"
        style={{ backgroundImage: `url(${overlapGroup3})` }}
      >
        <div className="assignment-24px-3">
          <div className="overlap-group-4">
            <img className="vector-6" src={vector} />
            <img className="vector-7" src={vector2} />
          </div>
        </div>
      </div>
    </div>
  );
}

function Component30(props) {
  const { chooseSubjectI532, vector, vector2 } = props;

  return (
    <div className="component-30 border-1px-black">
      <div className="choose-subject-i558 montserrat-medium-gray-10px">
        {chooseSubjectI532}
      </div>
      <div className="expandmore-24px-1">
        <div className="overlap-group3-3">
          <img className="vector-26" src={vector} />
          <img className="vector-27" src={vector2} />
        </div>
      </div>
    </div>
  );
}

function Group92(props) {
  const { x4Pm, opened, className } = props;

  return (
    <div className={`group-92 ${className || ""}`}>
      <div className="overlap-group-5">
        <div className="x4pm roboto-medium-blue-zodiac-14px">{x4Pm}</div>
        <div className="opened-1 roboto-medium-niagara-14px">{opened}</div>
      </div>
    </div>
  );
}
const component1Data = {
  line5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/line-5-1@2x.png",
  line6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/line-6@2x.png",
};

const component29Data = {
  overlapGroup3:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598fcf4e78cf803e37e567/img/ellipse-4-7@2x.png",
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598fcf4e78cf803e37e567/img/vector-43@2x.png",
};

const component292Data = {
  overlapGroup3:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598fcf4e78cf803e37e567/img/ellipse-4-8@2x.png",
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598fcf4e78cf803e37e567/img/vector-45@2x.png",
};

const component30Data = {
  chooseSubjectI532: "Select assignment",
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60587e030ef1c80c1aa270c3/img/vector-41@2x.png",
};

const group92Data = {
  x4Pm: "4pm",
  opened: "Opened",
};

const group922Data = {
  x4Pm: "6pm",
  opened: "Opened",
};

const group923Data = {
  x4Pm: "7pm",
  opened: "Opened",
};

const group924Data = {
  x4Pm: "8pm",
  opened: "Opened",
};

const group925Data = {
  x4Pm: "9pm",
  opened: "Opened",
};

const TeacherassignmentlistData = {
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-35@2x.png",
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-453@2x.png",
  vector3:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector4:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-153@2x.png",
  vector5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058d518334660f0a4896cec/img/vector-193@2x.png",
  vector7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector8:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-226@2x.png",
  vector9:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector10:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-228@2x.png",
  vector11:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector12:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-237@2x.png",
  vector13:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector14:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058910c5433a9ddabee45c1/img/vector-207@2x.png",
  vector15:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector16:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-241@2x.png",
  vector17:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-242@2x.png",
  vector18:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector19:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-166@2x.png",
  vector20:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-162@2x.png",
  vector21:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-163@2x.png",
  vector22:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-162@2x.png",
  vector23:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-164@2x.png",
  vector24:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-33@2x.png",
  vector25:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-238@2x.png",
  vector26:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-239@2x.png",
  vector27:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector28:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-224@2x.png",
  spanText: "Back to all",
  spanText2: " Students and Classrooms ",
  vatsalAgarwal: "VATSAL AGARWAL",
  group167:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058910c5433a9ddabee45c1/img/ellipse-4@2x.png",
  vector29:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598e31b90976028c50cd54/img/vector-3@2x.png",
  spanText3: (
    <>
      ASSIGNMENT 1<br />
    </>
  ),
  spanText4: "Submitted",
  open: "Open",
  spanText5: (
    <>
      ASSIGNMENT 2<br />
    </>
  ),
  spanText6: "Assigned",
  open2: "Open",
  spanText7: "Give a",
  spanText8: " new assignment",
  rectangle7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/rectangle-7-5@1x.png",
  text5: "Choose from existing assignments",
  or: "OR",
  text6: "Upload a new assignment",
  chooseFile: "Choose File",
  submit: "Submit",
  vector30:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector31:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-38@2x.png",
  ellipse2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/ellipse-2@2x.png",
  bibaSobti: "Biba Sobti",
  bibasobtiGmailCom: "bibasobti@gmail.com",
  vector32:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-36@2x.png",
  openCalendar: "Open Calendar",
  date: "Feb 18, 2021",
  time: "Time",
  className: "Class Name",
  status: "Status",
  addDelete: "Add/Delete",
  price: "12pm",
  organic4: "Organic-4",
  completed: "Completed",
  booked: "Booked",
  x1Pm: "1pm",
  x2Pm: "2pm",
  opened: "Opened",
  add: "Add",
  x5Pm: "5pm",
  add2: "Add",
  x3Pm: "3pm",
  organic5: "Organic-5",
  upcoming: "Upcoming",
  booked2: "Booked",
  price2: "10pm",
  opened2: "Opened",
  component1Props: component1Data,
  component29Props: component29Data,
  component292Props: component292Data,
  component30Props: component30Data,
  group92Props: group92Data,
  group922Props: group922Data,
  group923Props: group923Data,
  group924Props: group924Data,
  group925Props: group925Data,
};

export default Teacherassignmentlist;
