import axios from "axios";
import { toAPIURL } from "../utils";
export const LOGIN_URL = toAPIURL("api/token/?format=json");
export const REGISTER_URL = toAPIURL("api/user-register");
export const UPDATE_PASSWORD_URL = toAPIURL("api/update-password");

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(email, password, user_type) {
  return axios.post(REGISTER_URL, { email, password, user_type });
}

export function updatePassword(email, password) {
  return axios.put(UPDATE_PASSWORD_URL, { email, password });
}

export async function sendOtp(otpBody) {
  try {
    const res = await axios.get(
      toAPIURL(`api/send-otp/${otpBody.number}/${otpBody.country}`)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function verifyOtp(otpBody) {
  try {
    const res = await axios.get(
      toAPIURL(`api/verify-otp/${otpBody.number}/${otpBody.otp}/${otpBody.country}`)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}