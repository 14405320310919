import React, { Fragment } from 'react';
import './sideDrawer.css';

import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import DashboardIcon from '@material-ui/icons/Dashboard';
import SchoolIcon from '@material-ui/icons/School';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { removeToken } from "../../utils";
import { deleteSales } from "../../redux/actions/sales";
import Backdrop from '../Backdrop/Backdrop';

const SideDrawerPayments = (props) => {

    let attclasses = ["sideDrawer", "Close_SideD"];

    if (props.open) {
        attclasses = ["sideDrawer", "Open_SideD"];
    }

    const user = useSelector((state) => state.salesDetails.sales);

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(deleteSales(user.sales_id));
    removeToken();
  };    

    return (
      <Fragment>
        <Backdrop open={props.open} clicked={props.closeSideDrawer} />
        <div className={attclasses.join(" ")}>
          <div
            style={{
              width: "70%",
              height: "70px",
              border: "1px solid #F4F4F4",
              marginLeft: "30px",
            }}
          ></div>

          <NavLink to="/sales-dashboard" exact>
            <div className="sider-ele-mob">
              <DashboardIcon />
              <span className="sider-ele-mob-tag">Dashboard</span>
            </div>
          </NavLink>

          <NavLink to="/sales-my-students" exact>
            <div className="sider-ele-mob">
              <SchoolIcon />
              <span className="sider-ele-mob-tag">My Students</span>
            </div>
          </NavLink>

          <NavLink to="/sales-statistics" exact>
            <div className="sider-ele-mob">
              <AssessmentIcon />
              <span className="sider-ele-mob-tag">Analytics</span>
            </div>
          </NavLink>

          <NavLink to="/sales-trial-student-form/:salesId" exact>
            <div className="sider-ele-mob">
              <AccountBalanceIcon />
              <span className="sider-ele-mob-tag">Trial Student Form</span>
            </div>
          </NavLink>
          <NavLink to="/" exact onClick={() => handleLogout()}>
            <div className="sider-ele-mob">
              <PowerSettingsNewIcon />
              <span className="sider-ele-mob-tag">Logout</span>
            </div>
          </NavLink>
        </div>
      </Fragment>
    );
}

export default SideDrawerPayments;