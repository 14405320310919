import React, { useEffect, useState, useLayoutEffect } from "react";
// import { Button } as BootButton from "react-bootstrap";
import "./list_style.css";
import pic_2 from "../../student/dashboard/img/pic_2.png";

import { Link, NavLink } from "react-router-dom";
// import { Dropdown } from "semantic-ui-react";
import ReactTooltip from "react-tooltip";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SchoolIcon from "@material-ui/icons/School";
import DescriptionIcon from "@material-ui/icons/Description";
import HelpIcon from "@material-ui/icons/Help";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import NotesIcon from "@material-ui/icons/Notes";

import { doubtList } from "../../../crud/teacher.crud";
import DropdownOption from "../../higher_committee/dropdown/dropdown_status";

// images

import vector_2x from "./img/vector@2x.png";
import vector_5x from "./img/vector-5@2x.png";
import vector_1_2x from "./img/vector-1@2x.png";
import vector_3_2x from "./img/vector-3@2x.png";
import vector_5_2x from "./img/vector-5@2x.png";
import vector_7_2x from "./img/vector-7@2x.png";

import ellipse_4_1_2x from "./img/ellipse-4-1@2x.png";

import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteTeacher } from "../../../redux/actions/teacher";

import SideDrawer from "../../../UI/sideDrawer/sideDrawer";
import Popup from "../popup/popup";

function Teacherdoubts(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const user = useSelector((state) => state.teacherDetails.teacher);
  const [doubts, setDoubts] = useState([]);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);

  const [subject_id, setSubjectName] = useState("");
  const [grade_id, setGradeName] = useState("");
  const [status, setStatus] = useState("");

  const [mapDoubt, setMapDoubt] = useState([]);
  const [allDoubt, setAllDoubt] = useState(true);

  const handleGradeSelect = (value) => {
    setGradeName(value);
  };

  const handleSubjectSelect = (value) => {
    setSubjectName(value);
  };

  const handleStatusSelect = (value) => {
    setStatus(value);
  };

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteTeacher(reduxUser.teacher_id));
    removeToken();
  };

  useEffect(() => {
    doubtList(user.teacher_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setDoubts(false);
        } else setDoubts(res);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleClick = () => {
    setAllDoubt(true);
    setMapDoubt([]);
  };
  const handleClick1 = () => {
    setAllDoubt(false);
  };

  let attClassInAll = ["Div-all-sdj32", "Class_giving_border"];
  let attClassInAll2 = ["Div-all-sdj32"];

  if (!allDoubt) {
    attClassInAll2 = ["Div-all-sdj32", "Class_giving_border"];
    attClassInAll = ["Div-all-sdj32"];
  }

  const triggerFilter = () => {
    setAllDoubt(false);
    setMapDoubt(
      doubts.filter((res) => {
        if (status === "" && subject_id === "" && grade_id === "") {
          return res;
        } else if (
          res.doubt_status.toLowerCase().includes(status.toLowerCase()) &&
          res.grade_id.toLowerCase().includes(grade_id.toLowerCase()) &&
          res.subject_id.toLowerCase().includes(subject_id.toLowerCase())
        ) {
          return res;
        }
      })
    );
  };

  return (
    <>
      <form
        className="student-doubts-list-mobile screen"
        name="form4"
        action="form4"
        method="post"
      >
        <div className="component-1-C61RwL">
          <img
            alt="image"
            src={pic_2}
            style={{ height: "90px", marginTop: "-8%" }}
          />
        </div>

        {/* This is the hamburger icon  */}
        <SideDrawer
          open={displaySideDrawer}
          closeSideDrawer={sideDrawerToggler}
        />

        <div className="component-6-C61RwL" onClick={sideDrawerToggler}>
          <div className="rectangle-48-5mfU7d"></div>
          <div className="rectangle-49-5mfU7d"></div>
          <div className="rectangle-50-5mfU7d"></div>
        </div>
        <div className="solve-doubts-C61RwL">
          <span className="span0-z3pWM6">Solve </span>
          <span className="span1-z3pWM6">Doubts</span>
        </div>
        <div className="group-72-C61RwL">
          <div className="rectangle-40-gqbUX1"></div>
          <div className="component-29-gqbUX1">
            <div className="assignment-24px-3-lxV7ix">
              <img className="vector-YDNU6K" src={vector_2x} />
              <img className="vector-hGGfYL" src={vector_1_2x} />
            </div>
          </div>
          <div className="assignment-1-gqbUX1 montserrat-semi-bold-blue-zodiac-10px">
            ASSIGNMENT 1
          </div>
          <div className="mathematics-dr-yashna-sharma-gqbUX1 montserrat-medium-gray-7px">
            Mathematics (Dr. Yashna Sharma)
          </div>
          <div className="submitted-gqbUX1 montserrat-semi-bold-niagara-8px">
            Submitted
          </div>
        </div>
        <div className="group-73-C61RwL">
          <div className="rectangle-40-ql7mN0"></div>
          <div className="component-29-ql7mN0">
            <img className="ellipse-4-MxHjgv" src={ellipse_4_1_2x} />
            <div className="assignment-24px-3-MxHjgv">
              <img className="vector-x8YWNM" src={vector_2x} />
              <img className="vector-UwmKsc" src={vector_3_2x} />
            </div>
          </div>
          <div className="assignment-1-ql7mN0 montserrat-semi-bold-blue-zodiac-10px">
            ASSIGNMENT 1
          </div>
          <div className="mathematics-dr-yashna-sharma-ql7mN0 montserrat-medium-gray-7px">
            Mathematics (Dr. Yashna Sharma)
          </div>
          <div className="submitted-ql7mN0 montserrat-semi-bold-niagara-8px">
            Submitted
          </div>
        </div>
        <div className="group-74-C61RwL">
          <div className="rectangle-40-aCi14x"></div>
          <div className="component-29-aCi14x">
            <div className="assignment-24px-3-DVuPOa">
              <img className="vector-Z87Q8p" src={vector_2x} />
              <img className="vector-5tYqrB" src={vector_5_2x} />
            </div>
          </div>
          <div className="assignment-1-aCi14x montserrat-semi-bold-blue-zodiac-10px">
            ASSIGNMENT 1
          </div>
          <div className="mathematics-dr-yashna-sharma-aCi14x montserrat-medium-gray-7px">
            Mathematics (Dr. Yashna Sharma)
          </div>
          <div className="submitted-aCi14x montserrat-semi-bold-niagara-8px">
            Submitted
          </div>
        </div>
        <div className="group-75-C61RwL">
          <div className="rectangle-40-sDLNVS"></div>
          <div className="component-29-sDLNVS">
            <div className="assignment-24px-3-z4xZZj">
              <img className="vector-hyPzor" src={vector_2x} />
              <img className="vector-McuNxD" src={vector_7_2x} />
            </div>
          </div>
          <div className="assignment-1-sDLNVS montserrat-semi-bold-blue-zodiac-10px">
            ASSIGNMENT 1
          </div>
          <div className="mathematics-dr-yashna-sharma-sDLNVS montserrat-medium-gray-7px">
            Mathematics (Dr. Yashna Sharma)
          </div>
          <div className="submitted-sDLNVS montserrat-semi-bold-niagara-8px">
            Submitted
          </div>
        </div>
        <div className="group-99-C61RwL">
          <DropdownOption
            handleGradeSelect={handleGradeSelect}
            handleSubjectSelect={handleSubjectSelect}
            handleStatusSelect={handleStatusSelect}
          />
        </div>
        {/* <div className="group-100-C61RwL">
          <input
            className="component-30-lvfzGr border-1px-black-2"
            placeholder="Status"
            onChange={(e) => setStatus(e.target.value)}
          />
         
        </div> */}
        <div
          className="group-100-VMr6Om-x"
          style={{ backgroundColor: "#dc3545" }}
          onClick={triggerFilter}
        >
          <div className="group-94-xEvN2q">Filter</div>
        </div>

        <div className="bhsks-snfasa-sd2">
          <div className={attClassInAll.join(" ")} onClick={handleClick}>
            All
          </div>
          <div className={attClassInAll2.join(" ")} onClick={handleClick1}>
            {" "}
            Searched
          </div>
        </div>

        <div className="doubt-card-in-mob-202">
          {allDoubt === true ? (
            doubts && doubts.length > 0 ? (
              doubts.map((doubt) => {
                return <DoubtCard key={doubt.id} doubt={doubt} user={user} />;
              })
            ) : (
              <div className="vatsal-agarwal-B6ZBuj">
                No doubts present at the moment.
              </div>
            )
          ) : mapDoubt && mapDoubt.length > 0 ? (
            mapDoubt.map((doubt) => {
              return <DoubtCard key={doubt.id} doubt={doubt} user={user} />;
            })
          ) : (
            <div className="vatsal-agarwal-B6ZBuj">
              No doubts present at the moment.
            </div>
          )}
        </div>
      </form>
      <form className="student-doubts-t screen">
        <div className="flex-col-1234">
          <div className="flex-row-0xy0vn">
            <div className="component-1-GyJUNH">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "115px", marginTop: "-8%" }}
              />
            </div>

            <div className="group-22-GyJUNH">
              <div className="flex-col-vgli2x">
                <div className="biba-sobti-h8V8ZR">
                  {user.first_name + " " + user.last_name}
                </div>
                <div className="bibasobtigmailcom-h8V8ZR">{user.emailID}</div>
              </div>
              <img className="vector-vgli2x" src={vector_5x} />
            </div>
          </div>

          <div className="solve-doubts-C61RwL">
            <span className="span0-z3pWM6">Solve</span>
            <span className="span1-z3pWM6"> Doubts</span>
          </div>

          <div className="row-1-2">
            <div className="teacher-doubt-drop1">
              <DropdownOption
                handleGradeSelect={handleGradeSelect}
                handleSubjectSelect={handleSubjectSelect}
                handleStatusSelect={handleStatusSelect}
              />
            </div>

            {/* <div className="status-C61RwL montserrat-medium-black-18px">Status</div> */}
            {/* <input
              className="rectangle-70-C61RwL"
              placeholder="Status"
              onChange={(e) => setStatus(e.target.value)}
            /> */}
            <div
              className="group-53-C61RwL-1001"
              style={{
                backgroundColor: "#dc3545",
                color: "white",
                textAlign: "center",
                fontWeight: "600",
              }}
              onClick={triggerFilter}
            >
              Filter
            </div>
          </div>

          <div className="bhsks-snfasa-sd2">
            <div className={attClassInAll.join(" ")} onClick={handleClick}>
              All
            </div>
            <div className={attClassInAll2.join(" ")} onClick={handleClick1}>
              {" "}
              Searched
            </div>
          </div>

          <div className="group-220-C61RwL-112w">
            {allDoubt === true ? (
              doubts && doubts.length > 0 ? (
                doubts.map((doubt) => {
                  return <DoubtCard key={doubt.id} doubt={doubt} user={user} />;
                })
              ) : (
                <div className="vatsal-agarwal-B6ZBuj">
                  No doubts present at the moment.
                </div>
              )
            ) : mapDoubt && mapDoubt.length > 0 ? (
              mapDoubt.map((doubt) => {
                return <DoubtCard key={doubt.id} doubt={doubt} user={user} />;
              })
            ) : (
              <div className="vatsal-agarwal-B6ZBuj">
                No doubts present at the moment.
              </div>
            )}
          </div>
        </div>
        <div className="sider-teacher-general-div">
          <div className="sider-teacher-in-general-80">
            <div style={{ marginTop: "120px" }} />
            <NavLink to="/teacher-dashboard" exact>
              <DashboardIcon
                data-tip="Dashboard"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/teacher-date-calendar" exact>
              <EventIcon data-tip="Scheduler" className="icon-in-hc-color" />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/teacher-previous-classes">
              <ScheduleIcon
                data-tip="Previous Class"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/teacher-students">
              <SchoolIcon
                data-tip="Enrolled Student"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/teacher-curriculum-list">
              <DescriptionIcon
                data-tip="Curriculum"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/teacher-doubt-list">
              <HelpIcon data-tip="Doubts" className="icon-in-hc-color-2" />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/teacher-analytics">
              <AssessmentIcon
                data-tip="Analytics"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/teacher-assignments">
              <AssignmentIcon
                data-tip="Assignments"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/teacher-quizzes">
              <LibraryBooksIcon
                data-tip="Quizzes"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/teacher-lecture-notes">
              <NotesIcon
                data-tip="Lecture Notes"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            {/* <NavLink to="/teacher-payments"><AccountBalanceIcon data-tip="Payment" className="icon-in-hc-color" /><ReactTooltip /></NavLink> */}
            <div style={{ marginTop: "10px" }} />
            <NavLink to="/" onClick={() => handleLogout()}>
              {" "}
              <PowerSettingsNewIcon
                data-tip="Logout"
                className="icon-in-hc-color"
              />{" "}
            </NavLink>
            <div style={{ marginTop: "1px" }} />
          </div>
        </div>
        {/* </div> */}
      </form>
      <Popup />
    </>
  );
}

function DoubtCard({ doubt, user }) {
  return (
    <div className="doubt-card">
      <div className="component-4-B6ZBuj">
        <div className="doubt-name-status">
          <div className="vatsal-agarwal-B6ZBuj-doubt-s">{doubt.studentID}</div>
          <div className="active-B6ZBuj">{doubt.doubt_status}</div>
        </div>
        <div className="doubt-text-expand">
          <div className="what-is-euclid-lemma-B6ZBuj montserrat-medium-black-18px">
            {doubt.doubt_text}
          </div>
          <Link to={`/teacher-doubt-detail/${doubt.id}`}>
            <div className="expand-B6ZBuj">Expand</div>
          </Link>
        </div>
      </div>
      {/* <div className="biba-sobti-B6ZBuj">{user && `${user.first_name} ${user.last_name}`}</div>
      <Input placeholder='Solve this doubt' />
      <Button primary className="doubt-post-button">Post</Button> */}
    </div>
  );
}

export default Teacherdoubts;
