import React, { useEffect } from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../pages/student/dashboard/img/pic_5.png";
import "./navbar.css";
import CallIcon from "../icons/call.png";
import HamburgerIcon from "../icons/hamburger.png";

// import navLogo from "../../logo_new.png";

const CustomNav = (props) => {
  return (
    <>
      <div className="mainn-cont">
        <div
          style={{ display: "grid", justifyItems: "end", marginRight: "7%" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              paddingTop: 10,
              marginBottom: -25,
              zIndex: 100,
              marginRight: 20,
            }}
          >
            <img lassName="call_icon" src={CallIcon} width="20" />
            <p
              style={{
                fontSize: 16,
                marginLeft: 5,
                color: "#fff",
                fontWeight: 600,
              }}
            >
              +91-9570-424242
            </p>
          </div>
        </div>
        <Navbar
          style={{ backgroundColor: "#dc3545" }}
          expand="lg"
          className="main-nav"
        >
          <Navbar.Brand as={Link} to="/">
            <img width={"250"} src={logo} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle
            className="icon-nav"
            aria-controls="basic-navbar-nav"
            style={{
              borderColor: "#fff",
              color: "#fff",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              className="icon-nav"
              width={24}
              src={HamburgerIcon}
              alt="icon"
            />
          </Navbar.Toggle>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {/* ms-auto class pushes items to the right */}
              <Nav.Link className="navv-item" as={Link} to="/">
                <p className="main-text">Home</p>
              </Nav.Link>
              <a href="https://materials.therightguru.com" target="_blank" className="navv-item nav-link">
                <p className="main-text study-mat">Study Material</p>
              </a>

              <NavDropdown
                title={<p className="main-text">Our Services</p>}
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/cbse-icse">
                  <p className="main-text2">CBSE/ICSE/OTHER BOARDS</p>
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/ssc">
                  <p className="main-text2">SSC</p>
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/cuet">
                  <p className="main-text2">CUET</p>
                </NavDropdown.Item>

                <NavDropdown.Item as={Link} to="/dsssb">
                  <p className="main-text2">DSSSB</p>
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/clat">
                  <p className="main-text2">CLAT</p>
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/ndacds">
                  <p className="main-text2">NDA & CDS</p>
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/acturial-science">
                  <p className="main-text2">ACTURIAL SCIENCE</p>
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/gre-gmat-sat">
                  <p className="main-text2">GRE, GMAT & SAT</p>
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/police-services">
                  <p className="main-text2">POLICE SERVICES</p>
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/english-speaking">
                  <p className="main-text2">ENGLISH SPEAKING</p>
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/other-cs">
                  <p className="main-text2">OTHER COMPETITIVE SERVICES</p>
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} to="/contact-us">
                <p className="main-text">Contact us</p>
              </Nav.Link>
              <Nav.Link as={Link} to="/faqs">
                <p className="main-text">FAQs</p>
              </Nav.Link>
            </Nav>
            <Nav>
              <Link onClick={props.Open}>
                <div className="login-btn">Login/Register</div>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      {/* <Container
        fluid
        style={{
          width: "100%",
          backgroundColor: "#dedad1",
          paddingTop: "1%",
        }}
      >
        <nav
          className="navbar "
          style={{ display: "flex", margin: "0 auto", width: "85%" }}
        >
          <div>
            <a className="navbar-brand" href="/">
              <img src={pic_4} style={{ width: "200px" }} />
            </a>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "50px" }}>
            <div>
              <Link onClick={props.Open}>
                <p style={{ color: "#dc3545", fontWeight: "600", fontFamily: "'Montserrat', sans-serif" }}>Login</p>
              </Link>
            </div>
            <div>
              <Link onClick={props.Open}>
                <p style={{ color: "#dc3545", fontWeight: "600", fontFamily: "'Montserrat', sans-serif" }}>Signup</p>
              </Link>
            </div>
          </div>
        </nav>
        <div
          style={{
            width: "80%",
            display: "flex",
            margin: "0 auto",
            justifyContent: "center",
            paddingBottom: "1%",
          }}
        >
          <ul
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "50px",
            }}
          >
            <li>
              <Link to="/" style={{ color: "#dc3545", fontWeight: "600", fontFamily: "'Montserrat', sans-serif" }}>Home</Link>
            </li>
            <li>
              <Link to='/courses' style={{ color: "#dc3545", fontWeight: "600", fontFamily: "'Montserrat', sans-serif" }}>Courses</Link>
            </li>
            <li>
              <Link to="/study-material" style={{ color: "#dc3545", fontWeight: "600", fontFamily: "'Montserrat', sans-serif" }}>Study Material</Link>
            </li>
            <li>
              <Link style={{ color: "#dc3545", fontWeight: "600", fontFamily: "'Montserrat', sans-serif" }}>Toppers</Link>
            </li>

            <li>
              <Link to="/contact-us" style={{ color: "#dc3545", fontWeight: "600", fontFamily: "'Montserrat', sans-serif" }} >Contact us</Link>
            </li>
            <li>
              <Link to="/faqs" style={{ color: "#dc3545", fontWeight: "600", fontFamily: "'Montserrat', sans-serif" }}>FAQs</Link>
            </li>
          </ul>
        </div>
      </Container> */}
    </>
  );
};
export default CustomNav;
