import React, { Component } from "react";
import "./Navbar.css";

import SideDrawer from "../sideDrawer/sideDrawer";

import { MenuOutlined } from "@ant-design/icons";

import pic_2 from "../../pages/student/dashboard/img/pic_2.png";

class Navbar extends Component {
  state = {
    displaySideDrawer: false,
  };

  sideDrawerToggler = () => {
    this.setState((previousState) => {
      return {
        displaySideDrawer: !previousState.displaySideDrawer,
      };
    });
  };

  render() {
    return (
      <div>
        <SideDrawer
          open={this.state.displaySideDrawer}
          closeSideDrawer={this.sideDrawerToggler}
        />

        <header className="Header">
          <div className="logo">
            <img
              src={pic_2}
              alt="Image"
              style={{ height: "70px", marginLeft: "-5%", marginTop: "20px" }}
            />
          </div>
          <span className="heder-men-not">
            {/* <BellOutlined className='notification' /> */}
            <MenuOutlined onClick={this.sideDrawerToggler} className="menu" />
          </span>
        </header>
      </div>
    );
  }
}

export default Navbar;
