import React from "react";
import "./dropdown_employee.css";
import { Dropdown } from "semantic-ui-react";

const selectStyles = {
  width: "100%",
  borderRadius: "5px",
  border: "2px solid rgba(217, 83, 79, 0.4)",
  fontFamily: "'Montserrat', Helvetica",
};

const Dropdown_employee = (props) => {
  console.log(props);
  const employeeOptions = [
    {
      key: "All",
      text: "All",
      value: "All",
      
    },
    {
      key: "Accounts",
      text: "Accounts",
      value: "Accounts",
      
    },
    {
      key: "Sales",
      text: "Sales",
      value: "Sales",
    },
    {
      key: "Teacher",
      text: "Teacher",
      value: "Teacher",
    },
    {
      key: "Higher Committee",
      text: "Higher Committee",
      value: "Higher Committee",
    },
    {
      key: "Relation Manager",
      text: "Relation Manager",
      value: "Relation Manager",
    },
  ];

  return (
    <div>
      <div className="Dropdown-lec-notes-3">
        <Dropdown
          className="employee_dropdown"
          style={selectStyles}
          placeholder="Employee Type"
          fluid
          search
          selection
          options={employeeOptions}
          onChange={(e, { value }) => props.handleEmployeeSelect(value)}
        />
      </div>
    </div>
  );
};

export default Dropdown_employee;
