import React from "react";
import "./totalStudRenConv.css";
import { PieChart } from "react-minimal-pie-chart";
import { Badge } from "antd";
import Popup from "../../../teacher/popup/popup";

import { BellOutlined, UserOutlined } from "@ant-design/icons";

const totalStudentRenConv = (props) => {
  let classesData = [];

  props.isLoaded
    ? props.analData
      ? props.analData.total_renewal_students_contacted
        ? (classesData = [
            {
              title: "Total Renewal Students in Contact Active",
              value: props.analData.total_renewal_students_in_contact_active,
              color: "#3be2e2",
            },
            {
              title: "Total Renewal Students Converted",
              value: props.analData.total_renewal_students_converted,
              color: "#3be28e",
            },
            {
              title: "Total Renewal Students Lost Expected",
              value: props.analData.total_renewal_students_lost_expected,
              color: "#3b46e2",
            },
            {
              title: "Total Renewal Students Lost Price",
              value: props.analData.total_renewal_students_lost_price,
              color: "#cadb2d",
            },
            {
              title: "Total Renewal Students Lost Intent",
              value: props.analData.total_renewal_students_lost_intent,
              color: "#E38627",
            },
          ])
        : (classesData = [])
      : (classesData = [])
    : (classesData = []);

  let displayData = [];

  props.isLoaded
    ? props.analData
      ? (displayData = [
          {
            title: "Total Renewal Students in Contact Active",
            value: props.analData.total_renewal_students_in_contact_active,
            color: "#3be2e2",
          },
          {
            title: "Total Renewal Students Converted",
            value: props.analData.total_renewal_students_converted,
            color: "#3be28e",
          },
          {
            title: "Total Renewal Students Contacted",
            value: props.analData.total_renewal_students_contacted,
            color: "#8438ca",
          },
          {
            title: "Total Renewal Students Lost Expected",
            value: props.analData.total_renewal_students_lost_expected,
            color: "#3b46e2",
          },
          {
            title: "Total Renewal Students Lost Price",
            value: props.analData.total_renewal_students_lost_price,
            color: "#cadb2d",
          },
          {
            title: "Total Renewal Students Lost Intent",
            value: props.analData.total_renewal_students_lost_intent,
            color: "#E38627",
          },
        ])
      : (displayData = [])
    : (displayData = []);
  //console.log(classesData);
  return (
    <div className="totalStudentConvRen-layout-wdt-100">
      <div className="calender-totalStudentConRen-user-dis">
        <div>
        </div>
        <div className="User-info">
          <div className="user-info-in">
            <div
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#dc3545",
              }}
            >
              {props.user && props.user.first_name}{" "}
              {props.user && props.user.last_name}
            </div>
            <div>{props.user && props.user.emailID}</div>
          </div>
          <div>
            <UserOutlined className="User-Icon" />
          </div>
        </div>
      </div>

      <div className="totalStudentConvRen-view-1">
        <div className="head-teachStudConvRen-view-1-in">
          <span>Renewal Student Contacted</span>
          <span>Renewal Student Converted</span>
        </div>
        <div className="totalStudentConvRen-view-1-in">
          <PieChart
            className="piRen-in-total-stu-conv"
            lineWidth={30}
            label={({ dataEntry }) => dataEntry.value}
            labelStyle={(index) => ({
              fill: classesData[index].color,
              fontSize: "5px",
              fontFamily: "sans-serif",
            })}
            labelPosition={60}
            data={classesData}
          />

          <div className="BadgeRen-in-10px">
            {classesData.map((Datamap) => (
              <div className="BadgeRen-in-10px-container">
                <Badge style={{ width: "10px" }} color={Datamap.color} />
                {/* <span style={{ width: '4px' }}>.</span> */}
                <p className="pRen-text-badge">{Datamap.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="totalStudentConvRen-view-2">
        <div className="head-teachStudConvRen-view-1-in">
          <span>Renewal Student Contacted</span>
          <span>Renewal Student Converted</span>
        </div>

        <div className="TableRen-Badge-in-10px">
          {displayData.map((Datamap) => (
            <div className="TableRen-Badge-in-10px-container">
              <p className="TableRen-Badge-in-10px-p-container">
                {Datamap.title}
              </p>
              <p className="TableRen-Badge-in-10px-p-container">
                {Datamap.value}
              </p>
            </div>
          ))}
        </div>
      </div>
      {/* <Popup /> */}
    </div>
  );
};

export default totalStudentRenConv;
