import axios from "axios";
import { toAPIURL } from "../utils";

export async function teacherDetail(teacherId) {
  try {
    const res = await axios.get(
      toAPIURL("api/teacher-details-id/" + teacherId)
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function editTeacherDetail(teacher) {
  try {
    const res = await axios.put(
      toAPIURL("api/teacher-details-id/" + teacher.teacher_id),
      teacher
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function upcomingClass(teacherId) {
  try {
    const res = await axios.get(
      toAPIURL("api/teacher-upcoming-class/" + teacherId)
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function teacherAnalytics(teacherId) {
  try {
    const res = await axios.get(toAPIURL("api/teacher-analytics/" + teacherId));
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function teacherPreviousClasses(teacherId) {
  try {
    const res = await axios.get(toAPIURL("api/previous-classes/" + teacherId));
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function teacherClassrooms(teacherId) {
  try {
    const res = await axios.get(
      toAPIURL("api/teacher-classroom-list/" + teacherId)
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function doubtList(teacherId) {
  try {
    const res = await axios.get(
      toAPIURL("api/doubt-list-teacher/" + teacherId)
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function teacherStudents(teacherId) {
  try {
    const res = await axios.get(toAPIURL("api/assignment-list/" + teacherId));
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function teacherSubGrades(teacherId) {
  try {
    const res = await axios.get(toAPIURL("api/sub-grade/" + teacherId));
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function getSlots(teacher_id, date) {
  try {
    const res = await axios.get(
      toAPIURL(`api/only-teacher-slot/${teacher_id}/${date}`)
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function openSlot(slot, teacher_id, date) {
  try {
    const res = await axios.put(
      toAPIURL(`api/teacher-slot/0/${teacher_id}/${date}`),
      slot
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function uploadAssignment(assignment_details) {
  try {
    const res = await axios.put(
      toAPIURL("api/assignment-detail/0"),
      assignment_details
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function classroomUploadAssignment(assignment_details) {
  try {
    const res = await axios.put(
      toAPIURL("api/class-assignment-detail/0"),
      assignment_details
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function uploadQuiz(quiz_details) {
  try {
    const res = await axios.put(toAPIURL("api/quiz-detail/0"), quiz_details);
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function classroomUploadQuiz(quiz_details) {
  try {
    const res = await axios.put(
      toAPIURL("api/class-quiz-detail/0"),
      quiz_details
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function studentEachAssignmentDetails(student_response_id) {
  try {
    const res = await axios.get(
      toAPIURL("api/student-assignment-detail/" + student_response_id)
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function getNotes(teacher_id) {
  try {
    const res = await axios.get(toAPIURL("api/lecture-note/" + teacher_id));
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function uploadNotes(notes_details) {
  try {
    const res = await axios.put(toAPIURL("api/lecture-note/0"), notes_details);
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function teacherClassroomStudent(classroomID) {
  try {
    const res = await axios.get(
      toAPIURL("api/classroom-students/" + classroomID)
    );
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function getSingleClass(classId) {
  try {
    const res = await axios.get(
      toAPIURL("api/single-class/" + classId)
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}

export async function getSingleTrialClass(classId) {
  try {
    const res = await axios.get(
      toAPIURL("api/single-trial-class/" + classId)
    );
    return res.data;
  } catch (err) {
    console.error(err);
  }
}
