import React, { useLayoutEffect, useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import LoginModal from "../../../components/Login_modal/loginModal";
import CustomNav from "../../../components/header/Navbar";
import Background from "./bgcanvas.png";
import Footer from "../../../components/Footer";
import pic_5 from "../../student/dashboard/img/pic_5.png";
import Popup2 from "../../non_auth/new_popup/popup2";
import "./common.css";

Modal.setAppElement("#root");

function Cbse_Icse() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);

  const scrollToCbseFeeSection = () => {
    const cbseFeeSection = document.getElementById("cbse-fee-section");
    if (cbseFeeSection) {
      cbseFeeSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section style={{ width: "100%" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "80%", marginTop: "5%" }}>
            <div
              style={{
                color: "#dc3545",
                fontSize: 20,
                fontWeight: 600,
                lineHeight: "1.6",
                textAlign: "left",
              }}
            >
              TRG's 1-Year CBSE/ICSE and Other Boards Comprehensive Tuition
              Classes, opt for Online, Offline, or Home Learning! <br />
              by The Right Guru
            </div>

            <div
              style={{
                marginTop: "2%",
                borderStyle: "solid",
                borderWidth: "5px",
                borderColor: "#dc3545",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "1%",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 5 }}>
                    Date of Commencement
                  </div>
                  <div style={{ fontWeight: 600 }}>3 rd April 2023</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>Mode</div>
                  <div style={{ fontWeight: 600 }}>Offline/Online</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>
                    Duration
                  </div>
                  <div style={{ fontWeight: 600 }}>12 Months</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>Fee</div>
                  <div style={{ fontWeight: 600 }}>
                    <a
                      href="#cbse-fee-section"
                      onClick={scrollToCbseFeeSection}
                    >
                      Click here!
                    </a>
                  </div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>
                    Admission
                  </div>
                  <div style={{ fontWeight: 600 }}>Open</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>
                    Class Timing
                  </div>
                  <div style={{ fontWeight: 600 }}>8:00 AM to 8: PM PM</div>
                </div>
                <div
                  className="addrs"
                  style={{ display: "flex", marginLeft: 20, marginRight: 20 }}
                >
                  <div
                    style={{
                      borderStyle: "solid",
                      borderWidth: 3,
                      borderColor: "#000",
                      padding: 20,
                      width: "60%",
                      marginTop: "2%",
                      marginBottom: "2%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "-42px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#1d74ca",
                          width: 200,
                          padding: 8,
                          borderStyle: "solid",
                          borderWidth: 3,
                          borderColor: "#000",
                        }}
                      >
                        <h2
                          style={{
                            color: "#fff",
                            fontWeight: 600,
                            fontSize: 20,

                            textAlign: "center",
                          }}
                        >
                          Location
                        </h2>
                      </div>
                    </div>
                    <br />

                    <div style={{ fontWeight: 600 }}>
                      311 Vardhman Sunder Plaza, Plot No. 12 MLU Sec - 12
                      Dwarka, New Delhi 110078
                    </div>
                    <br />
                    <div style={{ fontWeight: 600 }}>
                      42 Second Floor, Omaxe Galleria, Sector 14, Jhajjar Road,
                      Bahadurgarh, Haryana - 124-507
                    </div>
                    
                  </div>

                  <div
                    style={{
                      borderStyle: "solid",
                      borderWidth: 3,
                      borderColor: "#000",
                      padding: 20,
                      Width: "30%",
                      marginTop: "2%",
                      marginBottom: "2%",
                      marginLeft: "5%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "-42px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#1d74ca",
                          width: 150,
                          padding: 8,
                          borderStyle: "solid",
                          borderWidth: 3,
                          borderColor: "#000",
                        }}
                      >
                        <h2
                          style={{
                            color: "#fff",
                            fontWeight: 600,
                            fontSize: 20,

                            textAlign: "center",
                          }}
                        >
                          Contact Us
                        </h2>
                      </div>
                    </div>
                    <br />
                    <div style={{ fontWeight: 600 }}>9570-424242</div>
                    <br />
                    <div style={{ fontWeight: 600 }}>9647-787878</div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "2%",
                  }}
                >
                  <Link to="/trial-student-form">
                    <div className="book-btn">Book Free Trial</div>
                  </Link>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "2%",
                  }}
                >
                  <a href="tel:+919570424242">
                    <div className="cons-btn">Call for Free Consultation</div>
                  </a>
                </div>
              </div>
            </div>
            <div style={{ marginBottom: "2%" }}>
              <h2
                style={{
                  color: "#56A5F5",
                  fontWeight: 600,
                  fontSize: 20,
                  marginTop: "3%",
                }}
              >
                About The Right Guru Classroom Programme
              </h2>
              <p
                style={{
                  fontSize: 16,
                  marginTop: "1%",
                  fontWeight: 500,
                  lineHeight: "1.6",
                }}
              >
                Since 2021, The Right Guru (TRG) has been a prominent player in
                the field of quality education, particularly for competitive
                exams like the SSC CGL, CHSL, MTS, CUET, DSSSB, CLAT, and also
                catering to CBSE/ICSE and other board examinations. With a
                dedicated team and a commitment to excellence, TRG understands
                the unique requirements of aspirants striving to excel in these
                competitive exams and contribute to their career growth.
              </p>
            </div>
            <div style={{ marginBottom: "2%" }}>
              <div
                style={{
                  marginBottom: "2%",
                  marginTop: "2%",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <table style={{ width: "100%" }}>
                  <tr>
                    <td className="heade">Why Choose TRG?</td>
                    <td>
                      At TRG, we are committed to nurturing academic growth,
                      fostering a deep understanding of subjects, and providing
                      personalized attention to every student. Our diverse
                      teaching methods and experienced educators create an
                      enriching learning environment.
                    </td>
                  </tr>
                  <tr>
                    <td className="heade">
                      Our Approach to Board Exam Preparation{" "}
                    </td>
                    <td>
                      At THE RIGHT GURU Tuition Centre, we believe in the power
                      of comprehensive learning. Our expert educators are
                      committed to providing you with top-notch study resources,
                      personalized guidance, and a transformative learning
                      experience.
                    </td>
                  </tr>
                  <tr>
                    <td className="heade">Guidance for Class 10 and 12</td>
                    <td>
                      Class 10 and 12 are crucial stages of your academic
                      journey. We are here to help you navigate them with
                      confidence. Our experienced faculty will empower you with
                      the knowledge, skills, and exam strategies needed to excel
                      in board exams.
                    </td>
                  </tr>
                  <tr>
                    <td className="heade">Mastering the Board Exam Pattern</td>
                    <td>
                      Understanding the Board exam pattern is key to your
                      success. Our teaching methodology focuses on both
                      scholastic and co-scholastic areas. We believe in
                      nurturing not only academic excellence but also physical
                      and mental well-being.
                    </td>
                  </tr>
                  <tr>
                    <td className="heade">Personalized Learning</td>
                    <td>
                      Education is not one-size-fits-all. We recognize your
                      unique learning needs and adapt our teaching methods to
                      cater to them. Our goal is to help you grasp concepts,
                      develop critical thinking skills, and ace your exams.
                    </td>
                  </tr>
                  <tr>
                    <td className="heade">Beyond the Classroom</td>
                    <td>
                      Education extends beyond textbooks. We encourage
                      participation in extracurricular activities, sports, arts,
                      and more. These experiences not only enhance your skills
                      but also shape you into a well-rounded individual.
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div
              style={{
                marginBottom: "2%",
                marginBottom: "2%",
                borderStyle: "solid",
                borderWidth: 5,
                borderColor: "#dc3545",
                padding: 10,
                borderRadius: 8,
              }}
            >
              <h2
                style={{
                  color: "#56A5F5",
                  fontWeight: 600,
                  fontSize: 20,
                  marginTop: "3%",
                }}
              >
                TRG Online/ Offline / Home Tuition Program
              </h2>
              <p
                style={{
                  fontSize: 16,
                  marginTop: "1%",
                  fontWeight: 500,
                  lineHeight: "1.6",
                }}
              >
                Step onto the path of academic achievement by enrolling in our
                program. Choose the mode that suits you best—Offline Classroom
                Tuition or Exclusive Online Content.
              </p>

              <div style={{ marginBottom: "2%" }}>
                <h2
                  style={{
                    color: "#56A5F5",
                    fontWeight: 600,
                    fontSize: 20,
                    marginTop: "3%",
                  }}
                >
                  Unlock Your Academic Potential with TRG's Comprehensive All
                  Subjects Programme!
                </h2>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "2%",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#dc3545",
                      width: 200,
                      padding: 8,
                    }}
                  >
                    <h2
                      style={{
                        color: "#fff",
                        fontWeight: 600,
                        fontSize: 20,

                        textAlign: "center",
                      }}
                    >
                      Subjects Covered
                    </h2>
                  </div>
                </div>
                <div
                  style={{
                    marginBottom: "2%",
                    marginTop: "2%",
                    display: "flex",
                    marginLeft: 10,
                    marginRight: 10,
                    flexWrap: "wrap",
                    overflowX: "auto",
                  }}
                >
                  <table
                    style={{
                      width: "100%",
                    }}
                  >
                    <tr>
                      <td>Mathematics</td>
                      <td>Chemistry</td>
                      <td>Accountancy</td>
                      <td>Physics</td>
                      <td>History</td>
                    </tr>
                    <tr>
                      <td>Geography</td>
                      <td>Political Science</td>
                      <td>Economics</td>
                      <td>Biology</td>
                      <td>Business Studies</td>
                    </tr>
                    <tr>
                      <td>English</td>
                      <td>Psychology</td>
                      <td>Sociology</td>
                      <td>Skill Subjects</td>
                      <td>Many More</td>
                    </tr>
                  </table>
                </div>

                <div
                  style={{
                    marginBottom: "2%",
                    marginTop: "2%",
                    overflowX: "auto",
                  }}
                >
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td className="heade">Excellence Through Expertise </td>
                      <td>
                        Experience the advantage of being guided by seasoned
                        educators dedicated to delivering top-notch education.
                        Our experienced teachers are committed to providing you
                        with quality learning that drives results.
                      </td>
                    </tr>
                    <tr>
                      <td className="heade">
                        Introducing the TRG Level 2 Teaching Advantage
                      </td>
                      <td>
                        Empower your child with the adept guidance of our
                        accomplished educators. Our seasoned teachers excel not
                        only in unravelling intricate concepts but also in
                        providing real-time doubt resolution, cultivating
                        personalized 1-on-1 interactions with students
                      </td>
                    </tr>
                    <tr>
                      <td className="heade">
                        Stay Ahead with Comprehensive Resources
                      </td>
                      <td>
                        Our program includes a wide range of resources to ensure
                        your success. Revision Notes & Weekly Tests: Stay
                        organized and assess your progress regularly with our
                        comprehensive study materials and weekly tests.
                      </td>
                    </tr>
                    <tr>
                      <td className="heade">In-Depth Concept Assimilation</td>
                      <td>
                        Delve profoundly into challenging subjects through
                        captivating visual simulations and dynamic storytelling
                        techniques. Your child will confidently master even the
                        most intricate topics.
                      </td>
                    </tr>
                    <tr>
                      <td className="heade">Exclusive Online Content</td>
                      <td>
                        Access a treasure trove of valuable resources through
                        our student portal, designed to enhance your learning
                        experience.
                        <br /> Prepare effectively with concise video lessons
                        for efficient last-minute studying.
                      </td>
                    </tr>
                    <tr>
                      <td className="heade">Real-Time Doubt Mitigation</td>
                      <td>
                        Our committed class educators are resolute in promptly
                        tending to doubts during lessons, guaranteeing an
                        uninterrupted learning experience.
                      </td>
                    </tr>
                    <tr>
                      <td className="heade">Access to TRG Premium App</td>
                      <td>
                        Elevate learning with unrestricted access to enriching
                        videos on the TRG Learning App. These resources augment
                        pre and post-class learning, transmuting education into
                        a holistic odyssey.
                      </td>
                    </tr>
                    <tr>
                      <td className="heade">
                        Regular Assessments and Assignments
                      </td>
                      <td>
                        Foster topic comprehension and retention with systematic
                        homework and assessments administered during classes.
                        This approach bolsters understanding and augments
                        knowledge retention.
                      </td>
                    </tr>
                    <tr>
                      <td className="heade">TRG Mentor Support</td>
                      <td>
                        Clear your doubts and receive guidance from mentors
                        committed to your academic success.
                      </td>
                    </tr>
                    <tr>
                      <td className="heade">Student Counselling Available</td>
                      <td>
                        We provide personalized counseling to help you overcome
                        any academic challenges you might encounter.
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="heade">
                        <span style={{ color: "#000" }}>
                          Choose the{" "}
                          <span style={{ fontWeight: 700 }}>
                            {" "}
                            Annual Course,
                          </span>{" "}
                          join our year-long course. EMI option is also
                          available!
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div style={{ marginBottom: "2%" }}>
              <h2
                style={{
                  color: "#56A5F5",
                  fontWeight: 600,
                  fontSize: 20,
                  marginTop: "3%",
                }}
              >
                Fees Structure
              </h2>
              <p
                style={{
                  fontSize: 16,
                  marginTop: "1%",
                  fontWeight: 500,
                  lineHeight: "1.6",
                }}
              >
                <div id="cbse-fee-section">
                  <table style={{ width: "100%" }}>
                    <tr>
                      <th
                        style={{
                          textAlign: "center",
                          color: "#56a5f5",
                          fontWeight: "700",
                        }}
                      >
                        Class
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          color: "#56a5f5",
                          fontWeight: "700",
                        }}
                      >
                        Subjects
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          color: "#56a5f5",
                          fontWeight: "700",
                        }}
                      >
                        Fee(Annual Pakage)
                      </th>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          color: "#56a5f5",
                          fontWeight: "600",
                        }}
                      >
                        6th Class
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <span>Math & Science</span>
                          <span>Math, Science & English</span>
                          <span>All Subjects</span>
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <span>2100/-</span>
                          <span>2800/-</span>
                          <span>3200/-</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          color: "#56a5f5",
                          fontWeight: "600",
                        }}
                      >
                        7th Class
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "left",
                          }}
                        >
                          <span>Math & Science</span>
                          <span>Math, Science & English</span>
                          <span>All Subjects</span>
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <span>2100/-</span>
                          <span>2800/-</span>
                          <span>3200/-</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          color: "#56a5f5",
                          fontWeight: "600",
                        }}
                      >
                        8th Class
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "left",
                          }}
                        >
                          <span>Math & Science</span>
                          <span>Math, Science & English</span>
                          <span>All Subjects</span>
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <span>2100/-</span>
                          <span>2800/-</span>
                          <span>3200/-</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          color: "#56a5f5",
                          fontWeight: "600",
                        }}
                      >
                        9th Class
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "left",
                          }}
                        >
                          <span>Math & Science</span>
                          <span>Math, Science & English</span>
                          <span>All Subjects</span>
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <span>3100/-</span>
                          <span>3800/-</span>
                          <span>4200/-</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          color: "#56a5f5",
                          fontWeight: "600",
                        }}
                      >
                        10th Class
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "left",
                          }}
                        >
                          <span>Math & Science</span>
                          <span>Math, Science & English</span>
                          <span>All Subjects</span>
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <span>3100/-</span>
                          <span>3800/-</span>
                          <span>4200/-</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          color: "#56a5f5",
                          fontWeight: "600",
                        }}
                      >
                        11th Class
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "left",
                          }}
                        >
                          <span>Science </span>
                          <span>Commerce</span>
                          <span>Arts & Humanities</span>
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <span>2500/- Per Subject</span>
                          <span>2100/- Per Subject</span>
                          <span>1800/- Per Subject</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          color: "#56a5f5",
                          fontWeight: "600",
                        }}
                      >
                        12th Class
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "left",
                          }}
                        >
                          <span>Science </span>
                          <span>Commerce</span>
                          <span>Arts & Humanities</span>
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <span>2500/- Per Subject</span>
                          <span>2100/- Per Subject</span>
                          <span>1800/- Per Subject</span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}>
                        <span>*</span> The fee structure is location-dependent.
                        For fee-related inquiries, please contact our dedicated
                        counselors.
                        <br />
                        For any query, Email us at:{" "}
                        <a
                          href="mailto:contact@therightguru.com"
                          style={{ color: "#dc3545" }}
                        >
                          contact@therightguru.com
                        </a>
                      </td>
                    </tr>
                  </table>
                </div>
              </p>
            </div>
            <div style={{ marginBottom: "2%" }}>
              <h2
                style={{
                  color: "#56A5F5",
                  fontWeight: 600,
                  fontSize: 20,
                  marginTop: "3%",
                }}
              >
                Achieve Your Goals with THE RIGHT GURU Tuition Centre
              </h2>
              <p
                style={{
                  fontSize: 16,
                  marginTop: "1%",
                  fontWeight: 500,
                  lineHeight: "1.6",
                }}
              >
                Join us on a journey of growth, learning, and achievement.
                Whether you're aiming for top grades, entrance exams, or a
                bright future, we are here to provide the guidance and support
                you need to succeed.
                <br />
                <br />
                For any query, Email us at:{" "}
                <a
                  href="mailto:contact@therightguru.com"
                  style={{ color: "#dc3545" }}
                >
                  contact@therightguru.com
                </a>
                <br />
                <br />
                For a more personalized and detailed understanding, we encourage
                you to visit our centers and consult our dedicated counselors.
              </p>
            </div>

            {/* <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginBottom: "5%",
              }}
            >
              <Link to="/">
                <div className="book-btn">Know more...</div>
              </Link>
            </div> */}
          </div>
        </div>
      </section>

      <section>
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default Cbse_Icse;
