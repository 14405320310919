const ENVIRONMENT_STAGE = "production";
//const ENVIRONMENT_STAGE = "development";
export const SERVER =
ENVIRONMENT_STAGE === "development"
    ? "http://localhost:8000/"
    : "https://therightguru.com/";
// export const SERVER = "https://test.therightguru.com/";

export const toAPIURL = (pathname) =>
  `${SERVER}${
    pathname.startsWith("/") ? pathname.substring(1, pathname.length) : pathname
  }`;

export const setToken = (access, refresh, email) => {
  localStorage.setItem("access", access);
  localStorage.setItem("refresh", refresh);
  localStorage.setItem("unique", email);
};

export const removeToken = () => {
  localStorage.clear();
};
