import React, { useState, useLayoutEffect } from 'react';
import { Link, useHistory, Redirect } from "react-router-dom";
import Modal from 'react-modal';
import { Button, Container } from 'react-bootstrap';
import { Form, Message } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { getStudent } from "../../../redux/actions/student";
import { login } from "../../../crud/auth.crud";
import { setToken } from "../../../utils";
import { Divider } from 'antd';
import './style.css';
import LoginModal from "../../../components/Login_modal/loginModal";
import CustomNav from "../../../components/header/Navbar";
import Footer from '../../../components/Footer';
import BackDrop from "../../../UI/Backdrop/Backdrop";
import Popup2 from "../../non_auth/new_popup/popup2";
Modal.setAppElement('#root');


const Study_material = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen(previousState => !previousState);


  const handleLogin = () => {
    setLoading(true);
    setIsErrorShown(false);

    if (!email || !password) {
      setLoading(false);
      setIsErrorShown(true);
    } else {
      login(email, password)
        .then(({ data: { access, refresh, email, user_type } }) => {
          setLoading(false);
          dispatch(getStudent({ access, email, user_type }, history));
          setToken(access, refresh);
          // history.replace("/student-dashboard");
        })
        .catch(() => {
          setLoading(false);
          setIsErrorShown(true);
        })
    }
  }

  return (
    <Container fluid>
      <div style={{ width: "100%" }}>
        <CustomNav Open={closeModal} />

        <div className="heading_box">
          <p>STUDY MATERIAL</p>
        </div>

        <div className="boxes_container content-class-study">
          <p style={{ margin: "20px" }}>
            Learning is a deep process, which have a major impact on the
            learners’ future as well as their present consciousness. In this
            stage of learning, students develop their interest to the academic
            fields, and for this they need to choose right materials for study
            to score good. The right guru provides all subjects notes from 6
            to 12 classes, prepared by subjects' experts according to the
            latest syllabus. These notes are packed up with basic concepts
            with realistic examples in an interactive manner, so that our
            learners understand topic easily and relate it to their real life.
            <br />
            <br />
            Want to have word with us book a trial class and come in{" "}
            <Link to="/contact-us"> contact us.</Link>
          </p>
        </div>

        <Container fluid style={{ width: "80%" }}>

          <div className="row">
            <Link
              to="/study-material/LectureNotes"
              style={{
                marginRight: "30px",
                backgroundImage:
                  "linear-gradient(rgb(15, 23, 97), rgb(40, 35, 119))",
              }}
              className="color_box col-md"
            >
              <p>Lecture Notes</p>
              <i className="fas fa-chevron-right"></i>
            </Link>
            <Link
              to="/study-material/Syllabus"
              style={{
                marginRight: "30px",
                backgroundImage:
                  "linear-gradient(rgb(44, 45, 65), rgb(32, 26, 51))",
              }}
              className="color_box col-md"
            >
              <p>Syllabus</p>
              <i className="fas fa-chevron-right"></i>
            </Link>
            <Link
              to="/study-material/Books"
              style={{
                backgroundImage:
                  "linear-gradient(rgb(55, 129, 88), rgb(23, 70, 50))",
              }}
              className="color_box col-md"
            >
              <p>Books</p>
              <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
          <div className="row">
            <Link
              to="/study-material/SamplePapers"
              style={{
                marginRight: "30px",
                backgroundImage:
                  "linear-gradient(rgb(134, 33, 148), rgb(173, 32, 98))",
              }}
              className="color_box col-md"
            >
              <p>Sample Papers</p>
              <i className="fas fa-chevron-right"></i>
            </Link>
            <Link
              to="/study-material/PreviousYearPapers"
              style={{
                marginRight: "30px",
                backgroundImage:
                  "linear-gradient(rgb(35, 114, 151), rgb(31, 100, 94))",
              }}
              className="color_box col-md"
            >
              <p>
                Previous Year <br /> Papers
              </p>
              <i className="fas fa-chevron-right"></i>
            </Link>
            <Link
              to="/study-material/SupportMaterial"
              style={{
                backgroundImage:
                  "linear-gradient(rgb(197, 114, 59), rgb(155, 145, 60))",
              }}
              className="color_box col-md"
            >
              <p>Support Material</p>
              <i className="fas fa-chevron-right"></i>
            </Link>
          </div>
        </Container>
        {/* <div className="content-class-future" style={{ color: "red" }}>
            <p>
              {" "}
              <strong> STUDY MATERIAL WILL BE UPLOADED SOON...</strong>
            </p>
          </div> */}

        <Footer />
      </div>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
      {/* <div className="login_modal_layout_100">
          <BackDrop open={isModalOpen} clicked={closeModal} />
          <div
            // isOpen={isModalOpen}
            // onRequestClose={closeModal}
            className={attclasses.join(" ")}
          >
            <div className="login-modal-title-2-10x">Student LogIn</div>
            <Form className="form-login-modal-2" error>
              {isErrorShown ? (
                <Message
                  error
                  header="Wrong Credentials"
                  content="You entered a wrong email or password."
                  style={{
                    fontSize: "10px",
                    width: "80%",
                    textAlign: "center",
                  }}
                />
              ) : (
                <p style={{ margin: "0px", padding: "0px" }} />
              )}

              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                name="email"
                placeholder="Email Address"
                className="form-input-login-modal-2"
                onChange={(e) => setEmail(e.target.value)}
              />

              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                name="password"
                className="form-input-login-modal-2"
                onChange={(e) => setPassword(e.target.value)}
              />

              <Button
                className="button-login-modal"
                variant="danger"
                onClick={() => handleLogin()}
                disabled={loading}
              >
                Log in
              </Button>

              <p className="message-2-land-log">Forgot Password?</p>

              <Divider style={{ margin: "5px 5px" }} />

              <p className="message-2-land-log">
                Not Registered?{" "}
                <span style={{ color: "#e3241d" }}>
                  {" "}
                  Book A Free Trial Class
                </span>
              </p>
            </Form>
          </div>
        </div> */}
    </Container>
  );
}

export default Study_material;
