import axios from "axios";
import { toAPIURL } from "../utils";

export async function trialStudentDetails(studentId) {
  try {
    const res = await axios.get(toAPIURL("api/trial-student/" + studentId));
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function upcomingClass(studentId) {
  try {
    const res = await axios.get(
      toAPIURL("api/student-upcoming-class/" + studentId)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function dashboardDoubts(studentId) {
  try {
    const res = await axios.get(
      toAPIURL("api/dashboard-doubt-list/" + studentId)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function pendingAssignments(studentId) {
  try {
    const res = await axios.get(
      toAPIURL("api/student-pending-assignments/" + studentId)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function studentPerformance(studentId) {
  try {
    const res = await axios.get(
      toAPIURL("api/student-performance/" + studentId)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function studentAssignments(studentId) {
  try {
    const res = await axios.get(
      toAPIURL("api/student-assignment-list/" + studentId)
    );
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function studentQuizzes(studentId) {
  console.log(studentId);
  try {
    const res = await axios.get(toAPIURL("api/quiz-detail/" + studentId));
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function doubtList(studentId) {
  try {
    const res = await axios.get(toAPIURL("api/doubt-list/" + studentId));
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function doubtDetail(doubtId) {
  try {
    const res = await axios.get(toAPIURL("api/doubt-detail/" + doubtId));
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function resolveDoubt(doubt) {
  try {
    const res = await axios.put(toAPIURL("api/create-doubt/" + doubt.id), doubt);
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function studentAssignmentDetails(student_response_id) {
  try {
    const res = await axios.get(
      toAPIURL("api/student-assignment-detail/" + student_response_id)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function classStudentAssignmentDetails(student_id, group_id) {
  try {
    const res = await axios.get(
      toAPIURL(
        `api/class-student-assignment-response/${student_id}/${group_id}`
      )
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function classStudentQuizDetails(student_id, group_id) {
  try {
    const res = await axios.get(
      toAPIURL(`api/class-student-quiz-response/${student_id}/${group_id}`)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function studentQuizDetails(student_response_id) {
  try {
    const res = await axios.get(
      toAPIURL("api/student-quiz-detail/" + student_response_id)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function getSlots(student_id, teacher_id, date) {
  try {
    const res = await axios.get(
      toAPIURL(`api/teacher-slot/${student_id}/${teacher_id}/${date}`)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function getStudentTeachers(student_id) {
  try {
    const res = await axios.get(
      toAPIURL("api/student-teachers-list/" + student_id)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function getStudentTeachersDateCalendar(student_id) {
  try {
    const res = await axios.get(
      toAPIURL("api/student-teachers-list-date-calendar/" + student_id)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function studentLectureNotes(student_id, teacher_id) {
  try {
    const res = await axios.get(
      toAPIURL(
        "api/lecture-notes-detail-student/" + student_id + "/" + teacher_id
      )
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function studentCoursesList(student_id) {
  try {
    const res = await axios.get(
      toAPIURL("api/lecture-notes-list/" + student_id)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function bookTrialClass(studentDetails) {
  try {
    const res = await axios.put(
      toAPIURL("api/trial-class-form-student/0"),
      studentDetails
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function createEnrolledStudent(studentDetails) {
  try {
    const res = await axios.put(
      toAPIURL("api/new-enrolled-student/0"),
      studentDetails
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function enrolledStudentPayment(details) {
  try {
    const res = await axios.post(toAPIURL("api/create-payment-ES"), details);
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function registerStudent(details) {
  try {
    const res = await axios.post(toAPIURL("api/user-register"), details);
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function addStudentTransaction(details) {
  try {
    const res = await axios.put(toAPIURL("api/store-transaction/0"), details);
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function studentBookClass(classDetails) {
  try {
    const res = await axios.put(toAPIURL("api/class-student/0"), classDetails);
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function updateSlotStatus(slot) {
  try {
    const res = await axios.put(
      toAPIURL(`api/teacher-slot/${slot.id}/${slot.teacher_id}/${slot.date}`),
      slot
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function submitAssignment(assgnDetails) {
  try {
    const res = await axios.put(
      toAPIURL("api/upload-assignment/" + assgnDetails.id),
      assgnDetails
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function submitQuiz(quizDetails) {
  try {
    const res = await axios.put(
      toAPIURL("api/upload-quiz/" + quizDetails.id),
      quizDetails
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function createDoubtResponse(responseDetails) {
  try {
    const res = await axios.put(
      toAPIURL("api/doubt-response/0"),
      responseDetails
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function liveClassRating(ratingDetail) {
  try {
    const res = await axios.put(
      toAPIURL("api/teacher-rating/" + ratingDetail.teacher_id),
      ratingDetail
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function trialClassRating(ratingDetail) {
  console.log(ratingDetail);
  try {
    const res = await axios.put(
      toAPIURL("api/submit-form-response/0"),
      ratingDetail
    );
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function doubtLike(likeDetail) {
  console.log(likeDetail);
  try {
    const res = await axios.put(
      toAPIURL("api/doubt-response-like/" + likeDetail.doubt_response_id),
      likeDetail
    );
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function curriculumList(studentId) {
  try {
    const res = await axios.get(
      toAPIURL("api/curriculum-notes-list/" + studentId)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function curriculumDetail(
  courseId,
  gradeId,
  subjectId,
  statusCurriculum
) {
  try {
    const res = await axios.get(
      toAPIURL(
        `api/curriculum-detail-view/${courseId}/${gradeId}/${subjectId}/${statusCurriculum}`
      )
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function enrolledStudentDetail(studentId) {
  try {
    const res = await axios.get(
      toAPIURL("api/enrolled-student-id/" + studentId)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function editEnrolledStudentDetail(student) {
  try {
    const res = await axios.put(
      toAPIURL("api/enrolled-student-id/" + student.enrolled_student_id),
      student
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function creadeDoubt(doubtDetail) {
  try {
    console.log(doubtDetail);
    const res = await axios.put(toAPIURL("api/create-doubt/0"), doubtDetail);
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function createIssue(issueDetail) {
  try {
    const res = await axios.put(toAPIURL("api/create-issue/0"), issueDetail);
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function studentClassList(studentId, date) {
  try {
    const res = await axios.get(
      toAPIURL(`api/student-class-list/${studentId}/${date}`)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function materialList(type, gradeId, subjectId, folder) {
  try {
    console.log(gradeId);
    console.log(subjectId);
    console.log(type);
    console.log(folder);
    const res = await axios.get(
      toAPIURL(`api/get-material/${gradeId}/${subjectId}/${type}/${folder}`)
    );
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function folderList(type, gradeId, subjectId) {
  try {
    const res = await axios.get(
      toAPIURL(`api/get-material-folder/${gradeId}/${subjectId}/${type}`)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}