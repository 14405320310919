import React, { useState, useEffect, useLayoutEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { FaRegQuestionCircle, FaCalendarAlt, FaFileAlt, FaChartPie, FaStickyNote, FaPowerOff } from "react-icons/fa";
import { MdAssignment, MdDashboard, MdNotificationsNone, MdSchool } from "react-icons/md";
import { BiSupport, BiHistory } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { AiTwotoneBank } from "react-icons/ai";
import line_5_2x from "./img/line-5@2x.svg";
import line_6_2x from "./img/line-6@2x.svg";
import line_5_1_2x from "./img/line-5-1@2x.svg";
import line_6_1_2x from "./img/line-6-1@2x.svg";
import vector_1_2x from "./img/vector-1@2x.svg";
import rectangle_7_1x from "./img/rectangle-7@1x.png";
import vector_5_2x from "./img/vector-5@2x.svg";
import vector_6_2x from "./img/vector-6@2x.svg";
import ellipse_2_1_2x from "./img/ellipse-2-1@2x.svg";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from '../../../utils';
import { deleteRm } from '../../../redux/actions/rm';
import SideDrawerRm from '../../../UI/sideDrawer/sideDrawerRm';
import { rmManageList } from "../../../crud/rm.crud";
import Popup from '../../teacher/popup/popup';
import RmIssueList from '../table_dash/rm_table';
import pic_2 from "../../student/dashboard/img/pic_2.png";

function Relationalmanagerlist(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const user = useSelector((state) => state.rmDetails.rm);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);

  const [students, setStudents] = useState([])

  const sideDrawerToggler = () => {
    setDisplaySideDrawer(previousState => !previousState);
  };

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.rmDetails.rm);
  const handleLogout = () => {
    dispatch(deleteRm(reduxUser.rm_id))
    removeToken();
  }

  useEffect(() => {
    rmManageList(user.rm_id)
      .then(res => {
        if (res.hasOwnProperty("message")) {
          setStudents(false)
        } else setStudents(res);
      })
      .catch(err => console.log(err))
  }, [])

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="relational-manager-list"
      />
      <div className="container-center-horizontal">
        <div className="relational-manager-list-mobile screen">
          <div className="flex-row-C61RwL">
            <div className="component-1-dXBp89">
              <img src={pic_2} style={{ height: "100px", margin: "-8%" }} />
            </div>
            <SideDrawerRm
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />

            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>
          <div className="group-127-C61RwL">
            <div className="list-of-students-2zqKZg">List of Students</div>
            <div className="overlap-group1-2zqKZg">
              <div className="dashboard-24px-1-759J8x">
                <img className="vector-L9XHu9" src="img/vector@2x.png" />
              </div>
              <div className="group-45-759J8x">
                <div className="rectangle-20-aIq8ji-09-9">
                  <div className="roboto-bold-white-14px-9-2">S. No.</div>
                  <div className="roboto-bold-white-14px-9-2">
                    Roll&nbsp;&nbsp;Number
                  </div>
                  <div className="roboto-bold-white-14px-9-2">Name</div>
                  <div className="roboto-bold-white-14px-9-2">Grade</div>
                  <div className="roboto-bold-white-14px-9-2">
                    Contact Number
                  </div>
                </div>
              </div>
            </div>
            <div className="overlap-group-2zqKZg">
              <div className="rectangle-30-eVSctS-10-1">
                <div className="akljs-awjon-da1">
                  {students.length > 0 ? (
                    students.map((student, index) => {
                      return (
                        <div className="group-56-eVSctS-0001">
                          <div>{index + 1}</div>
                          <div>{student.enrolled_student_id}</div>
                          <div>
                            {student.first_name + " " + student.last_name}
                          </div>
                          <div>{student.current_grade}</div>
                          <div>{student.mobile_number}</div>
                        </div>
                      );
                    })
                  ) : (
                    <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                      No student/teacher assigned to you.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="group-210-C61RwL">
            <RmIssueList userState={user} />
          </div>
        </div>
      </div>
      <div className="container-center-horizontal">
        <div className="relational-manager-list screen">
          <div className="group-209-C61RwL-10-1">
            <Link to="/rm-dashboard">
              <MdDashboard
                size={24}
                data-tip="Dashboard"
                color="rgba(179,179,179,1.0)"
              />
              <ReactTooltip />
            </Link>
            <div className="historytoggleoff-24px-1-D0BVcB">
              <div className="group-rAjf8W">
                <Link to="/rm-issue-list">
                  {" "}
                  <BiHistory
                    data-tip="Previous Issues"
                    size={24}
                    color="rgba(179,179,179,1.0)"
                  />
                  <ReactTooltip />
                </Link>
              </div>
            </div>
            <div className="school-24px-1-D0BVcB">
              <Link to="/rm-list">
                <MdSchool data-tip="My Students" size={24} color="#dc3545" />
                <ReactTooltip />
              </Link>
            </div>
            <div className="help-24px-2-D0BVcB">
              <Link to="/rm-ticket-list">
                <FaRegQuestionCircle
                  data-tip="Tickets"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
            <div className="analytics-24px-4-D0BVcB">
              <Link to="/rm-statistics">
                <FaChartPie
                  data-tip="Analytics"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
            {/* <div className="accountbalance-24px-1-D0BVcB">
              <Link to="/rm-payments"><AiTwotoneBank data-tip="Payments" size={24} color="rgba(179,179,179,1.0)" /><ReactTooltip /></Link>
            </div> */}
            <div className="vector-Ayxm2x">
              <Link to="/" onClick={() => handleLogout()}>
                <FaPowerOff
                  data-tip="Logout"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
          </div>
          <div className="flex-col-C61RwL-10-11">
            <div className="flex-row-0xy0vn">
              <div className="component-1-GyJUNH">
                <img alt="image" src={pic_2} style={{ height: "100px" }} />
              </div>
              <div className="notifications-24px-2-1-GyJUNH">
                <div className="vector-40xw7g">
                  <MdNotificationsNone size={28} />
                </div>
                <img className="ellipse-2-RYyMFx" src={ellipse_2_1_2x} />
              </div>
              <div className="group-22-GyJUNH">
                <div className="flex-col-vgli2x">
                  <div className="biba-sobti-h8V8ZR">
                    {user && user.first_name} {user && user.last_name}
                  </div>
                  <div className="bibasobtigmailcom-h8V8ZR">
                    {user && user.emailID}
                  </div>
                </div>
                <img className="vector-vgli2x" src={vector_1_2x} />
              </div>
            </div>
            <div className="flex-row-oV5UrE">
              <div className="overlap-group-QkTteQ">
                <img className="rectangle-7-dlP8Ul" src={rectangle_7_1x} />
                <div className="group-203-dlP8Ul">
                  <div className="list-of-students-5GLwEc">
                    List of Students
                  </div>
                  <div className="group-45-5GLwEc">
                    <div className="rectangle-20-Cd7ClN">
                      <div className="roboto-medium-white-20px">S. No.</div>
                      <div className="roboto-medium-white-20px">
                        Roll Number
                      </div>
                      <div className="roboto-medium-white-20px">Name</div>
                      <div className="roboto-medium-white-20px">Grade</div>
                      <div className="roboto-medium-white-20px">
                        Contact Number
                      </div>
                    </div>
                  </div>
                  <div className="overlap-group1-5GLwEc">
                    <div className="dashboard-24px-1-SrHNwx">
                      <img className="vector-OAiMwE" src="img/vector@2x.png" />
                    </div>
                    <div className="rectangle-30-SrHNwx-10-1">
                      <div className="akljs-awjon-da1">
                        {students.length > 0 ? (
                          students.map((student, index) => {
                            return (
                              <div className="group-56-SrHNwx-10-11">
                                <div>{index + 1}</div>
                                <div>{student.enrolled_student_id}</div>
                                <div>
                                  {student.first_name + " " + student.last_name}
                                </div>
                                <div>{student.current_grade}</div>
                                <div>{student.mobile_number}</div>
                              </div>
                            );
                          })
                        ) : (
                          <div
                            style={{ fontFamily: "'Montserrat', Helvetica" }}
                          >
                            No student/teacher assigned to you.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-col-QkTteQ">
                <div className="group-202-Fjgz1C">
                  <RmIssueList userState={user} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup />
    </>
  );
}


function Component1(props) {
  const { line5, line6 } = props;

  return (
    <div className="component-1 border-1px-blue-zodiac">
      <div className="overlap-group6">
        <img className="line-5" src={line5} />
        <img className="line-6" src={line6} />
      </div>
    </div>
  );
}
const component1Data = {
  line5: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60599271a8f3ed5507ddebbe/img/line-5-4@2x.png",
  line6: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/line-6@2x.png",
};

const RelationalmanagerlistData = {
  vector: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-35@2x.png",
  vector2: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1c6a4e78cf803e37e7fd/img/vector-29@2x.png",
  vector3: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1d433ce41a6c7995fc03/img/vector-9@2x.png",
  vector4: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector5: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058d518334660f0a4896cec/img/vector-193@2x.png",
  vector6: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector7: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-228@2x.png",
  vector8: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector9: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-237@2x.png",
  vector10: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector11: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-166@2x.png",
  vector12: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-162@2x.png",
  vector13: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-163@2x.png",
  vector14: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-162@2x.png",
  vector15: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-164@2x.png",
  vector16: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-33@2x.png",
  vector17: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector18: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-38@2x.png",
  ellipse2: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/ellipse-2@2x.png",
  title: "Biba Sobti",
  bibasobtiGmailCom: "bibasobti@gmail.com",
  vector19: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-36@2x.png",
  rectangle7: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60588e6f3f36904103fcf791/img/rectangle-7-2@1x.png",
  vector20: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  className: "Class Name",
  studentName: "Student Name",
  place: "Date",
  time: "Time",
  listOfStudents: "List of Students",
  sNo: "S. No.",
  student: "Student",
  rollNumber: "Roll Number",
  grade: "Grade",
  contactNumber: "Contact Number",
  openIssueList: "Open Issue List",
  number: "1",
  number2: "2",
  vatsalAgarwal: "Vatsal Agarwal",
  inProgess: "In Progess",
  shreyansJain: "Shreyans Jain",
  pending: "Pending",
  kunalKashyap: "Kunal Kashyap",
  ticket: "Ticket",
  price: "SOS",
  price2: "SOS",
  status: "Status",
  sNo2: "S.No.",
  studentName2: "Student Name",
  category: "Category",
  number3: "3",
  number4: "4",
  number5: "5",
  number6: "6",
  number7: "7",
  number8: "8",
  number9: "9",
  place2: "Standard",
  vector21: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1c6a4e78cf803e37e7fd/img/vector-26@2x.png",
  messages: "Messages",
  vector22: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1c6a4e78cf803e37e7fd/img/vector-25@2x.png",
  component1Props: component1Data,
};

export default Relationalmanagerlist;