import React, { useLayoutEffect, useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import LoginModal from "../../../components/Login_modal/loginModal";
import CustomNav from "../../../components/header/Navbar";
import Background from './bgcanvas.png'
import Footer from "../../../components/Footer";
import pic_5 from "../../student/dashboard/img/pic_5.png";
import Popup2 from "../../non_auth/new_popup/popup2";
import "./courses.css"
Modal.setAppElement("#root");


function Courses() {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    // const [reasonValue, setReasonValue] = useState(0)

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isErrorShown, setIsErrorShown] = useState(false);
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const history = useHistory();
    const dispatch = useDispatch();
    let attclasses = ["login-modal-2", "Close-Landing"];

    if (isModalOpen) {
        attclasses = ["login-modal-2", "Open-Landing"];
    }

    const closeModal = () => setIsModalOpen(previousState => !previousState);

    return (
        <div>
            <section>
                <CustomNav Open={closeModal} />
            </section>
            <section className="cont">
                <div style={{ width: "80%" }}>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        <div className="course-card" style={{
                            backgroundImage:
                                "linear-gradient(rgb(55, 129, 88), rgb(23, 70, 50))",
                        }} onClick={() => { }}>
                            <p>CBSE / ICSE / OTHERS</p>
                        </div>
                        <div className="course-card" style={{
                            backgroundImage:
                                "linear-gradient(rgb(55, 129, 88), rgb(23, 70, 50))",
                        }}>
                            <p>CUET</p>
                        </div>
                        <div className="course-card" style={{
                            backgroundImage:
                                "linear-gradient(rgb(55, 129, 88), rgb(23, 70, 50))",
                        }}>
                            <p>SSC</p>
                        </div>
                        <div className="course-card" style={{
                            backgroundImage:
                                "linear-gradient(rgb(55, 129, 88), rgb(23, 70, 50))",
                        }}>
                            <p>CLAT</p>
                        </div>
                        <div className="course-card" style={{
                            backgroundImage:
                                "linear-gradient(rgb(55, 129, 88), rgb(23, 70, 50))",
                        }}>
                            <p>DSSSB</p>
                        </div>
                        <div className="course-card" style={{
                            backgroundImage:
                                "linear-gradient(rgb(55, 129, 88), rgb(23, 70, 50))",
                        }}>
                            <p>POLICE SERVICES</p>
                        </div>
                    </div>

                </div>
            </section>
            <section>
                <Footer />
            </section>
            <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
            <Popup2 />
        </div>
    )
}

export default Courses