import React, { useState, useEffect, useLayoutEffect } from "react";
import "./teacher_assgn_detail_style.css";

import DashboardIcon from "@material-ui/icons/Dashboard";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SchoolIcon from "@material-ui/icons/School";
import DescriptionIcon from "@material-ui/icons/Description";
import HelpIcon from "@material-ui/icons/Help";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import NotesIcon from "@material-ui/icons/Notes";
import { NavLink, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Button } from "react-bootstrap";

import pic_2 from "../../student/dashboard/img/pic_2.png";

import { MdAssignment, MdSchool } from "react-icons/md";
// import { BiSupport, BiHistory } from "react-icons/bi";

import vector_2xp from "./img/vector@2x.png";
import vector_2x from "./img/vector@2x.png";
import vector_2xs from "./img/vector@2x.svg";
import vector_6_2x from "./img/vector-6@2x.svg";
import line_8_1x from "./img/line-8@1x.svg";
import line_8_1_2x from "./img/line-8-1@2x.svg";
import Popup from "../popup/popup";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteTeacher } from "../../../redux/actions/teacher";
import SideDrawer from "../../../UI/sideDrawer/sideDrawer";
import {
  studentAssignmentDetails,
  submitAssignment,
} from "../../../crud/student.crud";

import CalenderStatus from "../dashboard/CalenderComponent/calanderComponent";

function Teacherassignmentdetail(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id } = useParams();
  const user = useSelector((state) => state.teacherDetails.teacher);
  const [assignment, setAssignment] = useState(null);
  const [assignmentMarks, setAssignmentMarks] = useState(0);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [graded, setGraded] = useState(false);

  useEffect(() => {
    studentAssignmentDetails(id)
      .then((res) => {
        setAssignment(res);
      })
      .catch((err) => console.log(err));
  }, [graded]);

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteTeacher(reduxUser.teacher_id));
    removeToken();
  };
  const gradeAssignment = () => {
    if (parseInt(assignmentMarks) > assignment.assignment_group.total_marks) {
      alert("Cannot give more than total marks.");
    } else {
      submitAssignment({
        ...assignment.student_response,
        marks: assignmentMarks,
        status: "Graded",
      })
        .then((res) => {
          alert("Assignment graded successfully.");
          setGraded(!graded);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="teacher-assignment-detail"
      />
      <div className="container-center-horizontal">
        <form
          className="teacher-assignment-detail screen"
          name="form3"
          action="form3"
          method="post"
        >
          <div className="sider-teacher-general-div">
            <div className="sider-teacher-in-general-80">
              <div style={{ marginTop: "120px" }} />
              <NavLink to="/teacher-dashboard" exact>
                <DashboardIcon
                  data-tip="Dashboard"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-date-calendar" exact>
                <EventIcon data-tip="Scheduler" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-previous-classes">
                <ScheduleIcon
                  data-tip="Previous Class"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-students">
                <SchoolIcon
                  data-tip="Enrolled Student"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-curriculum-list">
                <DescriptionIcon
                  data-tip="Curriculum"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-doubt-list">
                <HelpIcon data-tip="Doubts" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-analytics">
                <AssessmentIcon
                  data-tip="Analytics"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-assignments">
                <AssignmentIcon
                  data-tip="Assignments"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-quizzes">
                <LibraryBooksIcon
                  data-tip="Quizzes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-lecture-notes">
                <NotesIcon
                  data-tip="Lecture Notes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              {/* <NavLink to="/teacher-payments"><AccountBalanceIcon data-tip="Payment" className="icon-in-hc-color" /><ReactTooltip /></NavLink> */}
              <div style={{ marginTop: "10px" }} />
              <NavLink to="/" onClick={() => handleLogout()}>
                {" "}
                <PowerSettingsNewIcon
                  data-tip="Logout"
                  className="icon-in-hc-color"
                />{" "}
              </NavLink>
              <div style={{ marginTop: "1px" }} />
            </div>
          </div>
          <div className="flex-col-C61RwL">
            <div className="flex-row-0xy0vn">
              <div className="component-1-GyJUNH">
                <img
                  alt="image"
                  src={pic_2}
                  style={{ height: "115px", marginTop: "-8%" }}
                />
              </div>
              {/* <div className="notifications-24px-2-1-GyJUNH">
              <img className="vector-RYyMFx" src={vector_2x} />
              <img className="vector-40xw7g" src={vector_2_2x} />
              <img className="ellipse-2-RYyMFx" src={ellipse_2_2x} />
            </div> */}
              <div className="group-22-GyJUNH">
                <div className="flex-col-vgli2x">
                  <div className="biba-sobti-h8V8ZR">
                    {user && user.first_name} {user && user.last_name}
                  </div>
                  <div className="bibasobtigmailcom-h8V8ZR">{user.emailID}</div>
                </div>
                <img className="vector-vgli2x" src={vector_2xs} />
              </div>
            </div>
            <div className="flex-row-oV5UrE">
              <div className="flex-col-QkTteQ">
                <div className="overlap-group1-Fjgz1C">
                  <div className="group-47-1UTYTe">
                    <div className="rectangle-40-pjV5k8"></div>
                    <div className="vatsal-agarwal-pjV5k8 montserrat-semi-bold-blue-zodiac-18px">
                      {assignment &&
                        assignment.student_response.enrolled_student_id}
                    </div>
                  </div>
                  <div className="group-167-1UTYTe">
                    <a href="javascript:history.back()">
                      <div className="arrowback-24px-1-Fjgz1C_1">
                        <img
                          className="vector-xxy9YX"
                          src={vector_2x}
                          alt="line"
                        />
                        <img
                          className="vector-H1IJdQ"
                          src={vector_6_2x}
                          alt="line"
                        />
                      </div>
                    </a>
                    <div className="component-29-DBfxBL"></div>
                    <div className="vector-DBfxBL">
                      <MdSchool size={24} color="white" />
                    </div>
                  </div>
                </div>
                <div className="overlap-group-Fjgz1C">
                  {/* <img
                    className="rectangle-7-PXwdVf"
                    src={rectangle_7_1x}
                    alt="line"
                  /> */}

                  <div className="group-46-PXwdVf">
                    <div className="rectangle-40-eRDTVr"></div>
                    <a
                      href={
                        assignment && assignment.student_response.solution_url
                      }
                      target="_blank"
                    >
                      <Button
                        disabled={
                          assignment &&
                          assignment.student_response.status == "Assigned"
                        }
                        variant="danger"
                        className="rectangle-44-eRDTVr"
                        style={{
                          fontFamily: "'Montserrat', Helvetica",
                          fontSize: 10,
                          fontWeight: 600,
                        }}
                      >
                        View Submission
                      </Button>
                    </a>
                    <Button
                      disabled={assignmentMarks == ""}
                      variant="danger"
                      className="rectangle-43-eRDTVr"
                      style={{
                        fontFamily: "'Montserrat', Helvetica",
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                      onClick={gradeAssignment}
                    >
                      Submit
                    </Button>
                    <div className="component-29-eRDTVr">
                      <div className="assignment-24px-3-wx3HpW">
                        <div className="vector-Z4cObr">
                          {" "}
                          <MdAssignment size={24} color="white" />{" "}
                        </div>
                        {/* <img className="vector-KeQs0d" src="img/vector@2x.png" />
                      <img className="vector-Z4cObr" src="img/vector-4@2x.svg" /> */}
                      </div>
                    </div>
                    <div className="assignment-1-eRDTVr montserrat-semi-bold-blue-zodiac-18px">
                      {assignment &&
                        assignment.assignment_group.assignment_name}
                    </div>
                    <div className="grade-assignment-eRDTVr">
                      Grade assignment
                    </div>
                    <div className="x10-marks-eRDTVr">
                      {assignment && assignment.assignment_group.total_marks}{" "}
                      Marks
                    </div>
                    <div className="mathematics-dr-yashna-sharma-eRDTVr">
                      {assignment && assignment.meta.subject_id} (
                      {assignment && assignment.meta.teacher_id})
                    </div>
                    <div className="submitted-eRDTVr">
                      {assignment && assignment.student_response.status}
                    </div>
                    <a
                      href={
                        assignment && assignment.assignment_group.assignment_url
                      }
                      target="_blank"
                    >
                      <Button
                        variant="outline-danger"
                        className="view-assignment-eRDTVr"
                        style={{
                          fontFamily: "'Montserrat', Helvetica",
                          fontSize: 10,
                          fontWeight: 600,
                        }}
                      >
                        View Assignment
                      </Button>
                    </a>
                    {/* <a href="#s3link"><div className="view-assignment-eRDTVr">View Assignment</div></a> */}
                  </div>
                  <img className="line-8-PXwdVf" src={line_8_1x} />
                  <input
                    className="component-18-PXwdVf border-1px-blue-zodiac"
                    name="6582169"
                    placeholder="Enter Marks"
                    type="number"
                    onChange={(e) => setAssignmentMarks(e.target.value)}
                  />
                </div>
              </div>
              <div className="group-114-QkTteQ">
                <div className="group-113-eLYKxH">
                  <CalenderStatus />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="container-center-horizontal">
        <form
          className="teacher-assignment-detail-mobile screen"
          name="form6"
          action="form6"
          method="post"
        >
          <div className="flex-row-C61RwL">
            <div className="component-1-dXBp89  ">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "90px", marginTop: "-8%" }}
              />
            </div>

            {/* This is the Nav bar  */}

            <SideDrawer
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />
            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>
          <div className="overlap-group2-C61RwL">
            <a href="javascript:history.back()">
              <div className="arrowback-24px-1-Fjgz1C">
                <img className="vector-xxy9YX" src={vector_2xp} />
                <img className="vector-H1IJdQ" src={vector_6_2x} />
              </div>
            </a>
            <div className="group-46-4a9k2Y">
              <div className="rectangle-40-6x6k20"></div>
              <div className="vatsal-agarwal-6x6k20">
                {assignment && assignment.student_response.enrolled_student_id}
              </div>
            </div>
            <div className="group-184-4a9k2Y">
              <div className="component-29-3nzxAx"></div>
              <div className="vector-3nzxAx">
                <MdSchool size={14} color="white" />
              </div>
            </div>
          </div>
          <div className="overlap-group1-C61RwL">
            <div className="group-156-RH0WJ5">
              <div className="group-155-bB7yjR">
                <div className="rectangle-40-VBdSDa"></div>
                <div className="assignment-1-VBdSDa">
                  {assignment && assignment.assignment_group.assignment_name}
                </div>
                <div className="grade-assignment-VBdSDa">Grade assignment</div>
                <div className="x10-marks-VBdSDa">
                  {assignment && assignment.assignment_group.total_marks} Marks
                </div>
                <div className="mathematics-dr-yashna-sharma-VBdSDa">
                  {assignment && assignment.meta.subject_id} (
                  {assignment && assignment.meta.teacher_id})
                </div>
                <div className="submitted-VBdSDa">
                  {assignment && assignment.student_response.status}
                </div>
                <a
                  href={
                    assignment && assignment.assignment_group.assignment_url
                  }
                  target="_blank"
                >
                  <Button
                    variant="outline-danger"
                    className="view-assignment-VBdSDa"
                    style={{
                      fontFamily: "'Montserrat', Helvetica",
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                  >
                    View Assignment
                  </Button>
                </a>
                {/* <a href="javascript:SubmitForm('form6')"><div className="view-assignment-VBdSDa">View Assignment</div></a> */}
                <a
                  href={assignment && assignment.student_response.solution_url}
                  target="_blank"
                >
                  <Button
                    disabled={
                      assignment &&
                      assignment.student_response.status == "Assigned"
                    }
                    variant="danger"
                    className="rectangle-44-VBdSDa"
                    style={{
                      fontFamily: "'Montserrat', Helvetica",
                      fontSize: 10,
                      fontWeight: 600,
                    }}
                  >
                    View Submission
                  </Button>
                </a>
                {/* <div className="view-submission-VBdSDa">View Submission</div> */}
                <Button
                  disabled={assignmentMarks == ""}
                  variant="danger"
                  className="rectangle-43-VBdSDa"
                  style={{
                    fontFamily: "'Montserrat', Helvetica",
                    fontSize: 12,
                    fontWeight: 600,
                  }}
                  onClick={gradeAssignment}
                >
                  Submit
                </Button>
              </div>
              <img className="line-8-bB7yjR" src={line_8_1_2x} />
            </div>
            <div className="component-30-RH0WJ5">
              <div className="assignment-24px-3-o9VQTT">
                <div className="vector-Ec0lHu">
                  {" "}
                  <MdAssignment size={18} color="white" />{" "}
                </div>
                {/* <img className="vector-YBcXf4" src="img/vector@2x.png" />
              <img className="vector-Ec0lHu" src="img/vector-35@2x.svg" /> */}
              </div>
            </div>
          </div>
          <div className="flex-row-VMr6Om-0091">
            <CalenderStatus />
          </div>
        </form>
      </div>
      <Popup />
    </>
  );
}

function Component1(props) {
  const { line5, line6 } = props;

  return (
    <div className="component-1 border-1px-blue-zodiac">
      <div className="overlap-group14">
        <img className="line-5" src={line5} />
        <img className="line-6" src={line6} />
      </div>
    </div>
  );
}

function Component29(props) {
  const { component29, vector, vector2 } = props;

  return (
    <div
      className="component-29"
      style={{ backgroundImage: `url(${component29})` }}
    >
      <div className="assignment-24px-3">
        <div className="overlap-group5-1">
          <img className="vector-27" src={vector} />
          <img className="vector-28" src={vector2} />
        </div>
      </div>
    </div>
  );
}

function Component18(props) {
  const {
    inputName,
    inputType,
    inputPlaceholder,
    inputRequired,
    childsNameI55827,
  } = props;

  return (
    <input
      className="component-18 border-1px-blue-zodiac"
      name={inputName}
      placeholder={inputPlaceholder}
      type={inputType}
      required={inputRequired}
    />
  );
}

function Group92(props) {
  const { x4Pm, opened, className } = props;

  return (
    <div className={`group-92 ${className || ""}`}>
      <div className="overlap-group-3">
        <div className="x4pm roboto-medium-blue-zodiac-14px">{x4Pm}</div>
        <div className="opened-1 roboto-medium-niagara-14px">{opened}</div>
      </div>
    </div>
  );
}
const component1Data = {
  line5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/line-5-1@2x.png",
  line6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/line-6@2x.png",
};

const component29Data = {
  component29:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058910c5433a9ddabee45c1/img/ellipse-4-4@2x.png",
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598fcf4e78cf803e37e567/img/vector-110@2x.png",
};

const component18Data = {
  inputName: "5582647",
  inputType: "text",
  inputPlaceholder: "EnterMarks-TeacherAssignemntDetail",
  inputRequired: "false",
  childsNameI55827: "Enter marks",
};

const group92Data = {
  x4Pm: "4pm",
  opened: "Opened",
};

const group922Data = {
  x4Pm: "6pm",
  opened: "Opened",
};

const group923Data = {
  x4Pm: "7pm",
  opened: "Opened",
};

const group924Data = {
  x4Pm: "8pm",
  opened: "Opened",
};

const group925Data = {
  x4Pm: "9pm",
  opened: "Opened",
};

const TeacherassignmentdetailData = {
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-35@2x.png",
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-453@2x.png",
  vector3:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector4:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-153@2x.png",
  vector5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058d518334660f0a4896cec/img/vector-193@2x.png",
  vector7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector8:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-226@2x.png",
  vector9:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector10:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-228@2x.png",
  vector11:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector12:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-237@2x.png",
  vector13:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector14:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058910c5433a9ddabee45c1/img/vector-207@2x.png",
  vector15:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector16:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-241@2x.png",
  vector17:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-242@2x.png",
  vector18:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector19:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-166@2x.png",
  vector20:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-162@2x.png",
  vector21:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-163@2x.png",
  vector22:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-162@2x.png",
  vector23:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-164@2x.png",
  vector24:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-33@2x.png",
  vector25:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-238@2x.png",
  vector26:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-239@2x.png",
  vector27:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector28:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-38@2x.png",
  ellipse2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/ellipse-2@2x.png",
  title: "Biba Sobti",
  bibasobtiGmailCom: "bibasobti@gmail.com",
  vector29:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-36@2x.png",
  vector30:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector31:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-224@2x.png",
  vatsalAgarwal: "VATSAL AGARWAL",
  group167:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058910c5433a9ddabee45c1/img/ellipse-4@2x.png",
  vector32:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598e31b90976028c50cd54/img/vector-3@2x.png",
  rectangle7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/rectangle-7-5@1x.png",
  assignment1: "ASSIGNMENT 1",
  text1: "Mathematics (Dr. Yashna Sharma)",
  address: "10 Marks",
  viewAssignment: "View Assignment",
  viewSubmission: "View Submission",
  gradeAssignment: "Grade assignment",
  submit: "Submit",
  submitted: "Submitted",
  line8:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058910c5433a9ddabee45c1/img/line-8@1x.png",
  openCalendar: "Open Calendar",
  date: "Feb 18, 2021",
  time: "Time",
  className: "Class Name",
  status: "Status",
  addDelete: "Add/Delete",
  price: "12pm",
  organic4: "Organic-4",
  completed: "Completed",
  booked: "Booked",
  x1Pm: "1pm",
  x2Pm: "2pm",
  opened: "Opened",
  add: "Add",
  x5Pm: "5pm",
  add2: "Add",
  x3Pm: "3pm",
  organic5: "Organic-5",
  upcoming: "Upcoming",
  booked2: "Booked",
  price2: "10pm",
  opened2: "Opened",
  component1Props: component1Data,
  component29Props: component29Data,
  component18Props: component18Data,
  group92Props: group92Data,
  group922Props: group922Data,
  group923Props: group923Data,
  group924Props: group924Data,
  group925Props: group925Data,
};

export default Teacherassignmentdetail;
