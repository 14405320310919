import { DELETE_ACCOUNTS, GET_ACCOUNTS } from '../actions/accounts/types'

const initialState = {}

export default function getAccountsDetailsReducer(state = initialState, action) {
    switch(action.type) {
        case GET_ACCOUNTS:
            return {
                ...state,
                accounts: action.payload
            }
        case DELETE_ACCOUNTS:
            return {}
        default:
            return state
    }
}