import React, { useState, useEffect, useLayoutEffect } from "react";
import "../../higher_committee/hc_classroom/hc_classroom.css";
import SideDrawerAccounts from "../../../UI/sideDrawer/sideDrawerAccounts";
import { NavLink, Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import "./style.css";
import line_5_2x from "./img/line-5@2x.svg";
import line_6_2x from "./img/line-6@2x.svg";
import vector_5_2x from "./img/vector-5@2x.png";
import { Button, Form, Col, Table } from "react-bootstrap";
import ClassSharpIcon from "@material-ui/icons/ClassSharp";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { setOptions } from "@mobiscroll/react";
import { Message } from "semantic-ui-react";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SchoolIcon from "@material-ui/icons/School";
import Dropdown_employee from "../dropdown/dropdown_employee";
import pic_2 from "../../student/dashboard/img/pic_2.png";
import {
    accountStudentTotal,
    accountEmployeeTotal,
    getEmployeePayment,
    recordNewPayment
} from "../../../crud/accounts.crud";
import { DatePicker, TimePicker } from "antd";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteAccounts } from "../../../redux/actions/accounts";
import { AccountEmployee } from "../../../crud/accounts.crud";

setOptions({
    theme: "ios",
    themeVariant: "light",
});

const AccountsEmployee = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [employeeId, setEmployeeId] = useState("");
    const [paymentMode, setPaymentMode] = useState("");
    const [paymentId, setPaymentId] = useState("");
    const [amount, setAmount] = useState(0);
    const [accountNumb, setAccountNumb] = useState("");
    const [employeeType, setEmployeeType] = useState("");
    const handleEmployeeTypeSelect = (value) => {
      setEmployeeType(value);
    };
    const recordPayment = () => {
        recordNewPayment({
            employee_id: employeeId,
            payment_mode: paymentMode,
            payment_ID: paymentId,
            amount,
            from_account_number: accountNumb,
          date_time: (new Date()).toISOString(),
            employeeType
        })
            .then(res => {
                alert("New Payment Succesfully recorded.");
                setEmployeeId("")
                setPaymentMode("")
                setPaymentId("")
                setAmount(0)
                setAccountNumb("")
            })
            .catch(err => console.log(err))
    }

  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
    const [isErrorShown, setIsErrorShown] = useState(false);
    const [isErrorShown1, setIsErrorShown1] = useState(false);
    const [duration, setDuration] = useState("");
    const [start_date, setStartDate] = useState("");
    const [isAllocatedClassroom, setIsAllocatedClassroom] = useState(false);
    const [chooseDateTime, setChooseDateTime] = useState("");
    const [dateTimeList, setDateTimeList] = useState([]);
    const [teacherId, setTeacherId] = useState("");
    const [trial_date, onChange] = useState(new Date());
    const [handleFilter, setHandleFilter] = useState("");
    const [myStudents, setMyStudents] = useState([]);
    const [payments, setPayments] = useState({});
    const [outgoingStartDate, setOutgoingStartDate] = useState("");
    const [outgoingEndDate, setOutgoingEndDate] = useState("");
    const { RangePicker } = DatePicker;
    const [employeeName, setEmployeeName] = useState("");
    const [employeeTransactions, setEmployeeTransactions] = useState([]);
    const [employeeTransactionsTotal, setEmployeeTransactionsTotal] = useState('NA');
    const [salaryDetails, setSalaryDetails] = useState({});

    const getEmployeeSalary = () => {
        getEmployeePayment(employeeName, outgoingStartDate, outgoingEndDate)
            .then(res => {
                setSalaryDetails(res)
            })
            .catch(err => {
                console.log(err)
                setEmployeeName("")
                setOutgoingStartDate("")
                setOutgoingEndDate("")
            })
    }
    
    const handleEmployeeSelect = (value) => {
        setEmployeeName(value);
    };
    const onClickDayTime = (value, event) => {
        setChooseDateTime(moment(value).format("YYYY-MM-DDTHH:00:00Z"));
    };
    const onClickOutgoingDate = (value, event) => {
        setOutgoingStartDate(moment(value[0]).format("YYYY-MM-DD"));
        setOutgoingEndDate(moment(value[1]).format("YYYY-MM-DD"));
    };
    const onClickStartDate = (value, event) => {
        setStartDate(moment(value).format("YYYY-MM-DD"));
    };

    const onClickDelete = (id) => {
        const newArray = dateTimeList.filter((dateTime) => id !== dateTime);
        setDateTimeList([...newArray]);
    };

    const getTotalExpenditure = () => {
        // call total Employees accounts api
        accountEmployeeTotal(outgoingStartDate, outgoingEndDate, employeeName)
            .then(res => {
                setEmployeeTransactions(res.total_incoming);
                setEmployeeTransactionsTotal(res.total_sum);
            })
            .catch(err => console.log(err))
    }

    const handleSlotSelection = (e) => {
        e.preventDefault();
        if (dateTimeList.includes(chooseDateTime) === true) {
            setIsErrorShown(true);
        } else {
            setIsErrorShown(false);
            setIsErrorShown1(false);
            setDateTimeList((oldArray) => [...oldArray, chooseDateTime]);
        }
        console.log(dateTimeList);
    };

    const user = useSelector((state) => state.accountsDetails.accounts);

    const dispatch = useDispatch();

    
  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

    const handleLogout = () => {
        dispatch(deleteAccounts(user.accounts_employee_id));
        removeToken();
    };

  useEffect(() => {
    AccountEmployee()
      .then((res) => {
        setPayments(res);
        console.log(res.length);
      })
      .catch((err) => console.log(err));
  }, []);

    return (
      <div>
        <div className="relational-manager-ticket-mobile screen">
          <div className="flex-row-C61RwL">
            <div className="component-1-dXBp89">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "90px", marginTop: "-8%" }}
              />
            </div>
            <SideDrawerAccounts
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />
            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>

          <div
            className="body_accounts-employee"
            style={{ width: "100%", height: "2000px" }}
          >
            <div className="create_class">
              <div className="search_text">Record New Payment</div>
              <div className="classroom_content4">
                <Form.Group>
                  <Form.Row>
                    <Form.Label column lg={3}>
                      Employee ID
                    </Form.Label>
                    <Col>
                      <Form.Control
                        variant="danger"
                        type="text"
                        value={employeeId}
                        onChange={(e) => setEmployeeId(e.target.value)}
                      />
                    </Col>
                  </Form.Row>
                  <br />

                  <Form.Row>
                    <Form.Label column lg={3}>
                      Payment Mode
                    </Form.Label>
                    <Col>
                      <Form.Control
                        variant="danger"
                        type="text"
                        value={paymentMode}
                        onChange={(e) => setPaymentMode(e.target.value)}
                      />
                    </Col>
                  </Form.Row>
                  <br />
                  <Form.Row>
                    <Form.Label column lg={3}>
                      Payment ID
                    </Form.Label>
                    <Col>
                      <Form.Control
                        variant="danger"
                        type="text"
                        value={paymentId}
                        onChange={(e) => setPaymentId(e.target.value)}
                      />
                    </Col>
                  </Form.Row>
                  <br />
                  <Form.Row>
                    <Form.Label column lg={3}>
                      Amount
                    </Form.Label>
                    <Col>
                      <Form.Control
                        variant="danger"
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </Col>
                  </Form.Row>
                  <br />

                  <Form.Row>
                    <Form.Label column lg={3}>
                      From Account Number
                    </Form.Label>
                    <Col>
                      <Form.Control
                        variant="danger"
                        type="text"
                        value={accountNumb}
                        onChange={(e) => setAccountNumb(e.target.value)}
                      />
                    </Col>
                  </Form.Row>
                  <br />
                  <Form.Row>
                    <Form.Label column lg={3} style={{ margin: "10px 0px" }}>
                      Employee Type
                    </Form.Label>
                    <Col>
                      {" "}
                      <Dropdown_employee
                        style={{ marginLeft: "1100px" }}
                        handleEmployeeSelect={handleEmployeeTypeSelect}
                      />
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <div className="style-a-xdjkhs-1"></div>
                    {/* </Col> */}
                  </Form.Row>
                </Form.Group>
              </div>

              <div className="create_button">
                <Button variant="danger" onClick={recordPayment}>
                  Submit
                </Button>
              </div>
            </div>
            <div
              style={{
                marginTop: "80px",
                borderBottom: "3px solid #ec3545",
                width: "100%",
              }}
            ></div>
            <div list-box>
              <div className="list-of-students-5GLwEc-accounts-employee-mob">
                <span>List of Employee Transactions</span>
                <input
                  type="text"
                  placeholder="Filter List"
                  style={{
                    width: "210px",
                    marginRight: "5px",
                    color: "black",
                  }}
                  onChange={(e) => setHandleFilter(e.target.value)}
                />
              </div>

              <div className="table-scr">
                <div className="group-45-5GLwEc-ly-employee">
                  <div className="rectangle-20-Cd7ClN-in-accounts-employee">
                    <div className="roboto-medium-white-20px">ID</div>
                    <div className="roboto-medium-white-20px">Emp Acc ID</div>
                    <div className="roboto-medium-white-20px">
                      From account_number
                    </div>
                    <div className="roboto-medium-white-20px">Date</div>
                    <div className="roboto-medium-white-20px">Time</div>
                    <div className="roboto-medium-white-20px">Amount</div>
                    <div className="roboto-medium-white-20px">Payment-Mode</div>
                  </div>
                </div>
                <div className="rectangle-30-y1HRxC-10-c-accounts-employee">
                  <div className="nfkjbdajhbjd-fajdn3-employee">
                    {payments.length > 0 ? (
                      payments
                        .filter((res) => {
                          const test_temp = res.employee_account_id;
                          if (handleFilter === "") {
                            return res;
                          } else if (
                            test_temp
                              .toLowerCase()
                              .includes(handleFilter.toLowerCase())
                          ) {
                            return res;
                          }
                        })
                        .map((item) => {
                          return (
                            <div
                              key={item && item.id}
                              className="group-56-y1HRxC-9-ew-accounts-employee"
                            >
                              <div className="payga4wk-z-bshl-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px">
                                {item && item.id}
                              </div>
                              <div className="x499900-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px">
                                {item && item.employee_account_id.length <= 17
                                  ? item.employee_account_id
                                  : item.employee_account_id.slice(0, 17) +
                                    "..."}
                              </div>
                              <div className="x07-dec-2020-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px">
                                {item && item.from_account_number}
                              </div>
                              <div className="x014009-pm-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px">
                                {item && moment(item.date_time).format("LL")}
                              </div>
                              <div className="andb586-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px">
                                {item && moment(item.date_time).format("LT")}
                              </div>
                              <div className="andb586-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px">
                                {item && item.amount}
                              </div>
                              <div className="andb586-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px">
                                {item && item.payment_mode}
                              </div>
                            </div>
                          );
                        })
                    ) : (
                      <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                        No occupied students
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{
                  marginTop: "80px",
                  borderBottom: "3px solid #ec3545",
                  width: "100%",
                }}
              ></div>

              <div
                className="range-selector-part-1"
                style={{ marginTop: "10%" }}
              >
                <div className="input-group mb-3" style={{ width: "100%" }}>
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      Employee ID
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={employeeName}
                    onChange={(e) => setEmployeeName(e.target.value)}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>

                <div className="select-dates-1" style={{ marginTop: "16px" }}>
                  Select Range
                </div>

                <Col
                  className="style-a-xdjkhs-2-accounts-dashboard"
                  style={{ marginTop: "20px" }}
                >
                  <RangePicker
                    className="datePicker-inshc"
                    showNow
                    format="DD-MM-YYYY"
                    onChange={onClickOutgoingDate}
                  />
                </Col>
                <div className="filter-class-1" style={{ marginTop: "3px" }}>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={getEmployeeSalary}
                  >
                    Submit
                  </button>
                </div>
              </div>

              <div
                className="input-group mb-3"
                style={{ width: "75%", marginLeft: "30px" }}
              >
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    Decided Salary
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Decided Salary"
                  value={
                    salaryDetails &&
                    salaryDetails.employee_data &&
                    salaryDetails.employee_data.teaching_type
                      ? salaryDetails.employee_data.teaching_type == 2
                        ? "₹" +
                          salaryDetails.employee_data.per_class.toString() +
                          " per Hour"
                        : salaryDetails.employee_data.teaching_type == 1
                        ? "₹" +
                          salaryDetails.employee_data.per_month.toString() +
                          " per Month"
                        : "₹" +
                          salaryDetails.employee_data.per_package.toString() +
                          " per Package"
                      : "₹" +
                        (salaryDetails.employee_data &&
                          salaryDetails.employee_data.per_month.toString()) +
                        " per Month"
                  }
                  aria-label="Username"
                  disabled
                  aria-describedby="basic-addon1"
                  style={{ width: "30%" }}
                />
              </div>

              <div
                className="input-group mb-3"
                style={{ width: "75%", marginLeft: "30px" }}
              >
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    Final Payment
                  </span>
                </div>
                <input
                  type="text"
                  disabled
                  className="form-control"
                  value={
                    salaryDetails && "₹" + salaryDetails.final_payment + "/-"
                  }
                  placeholder="Final Payment"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="relational-manager-ticket-list screen"
          style={{ width: "100%" }}
        >
          <div className="sider-hc-teacher-list">
            <div className="sider-hc-teacher-list-in-wdt-80">
              <NavLink to="/accounts-dashboard">
                <DashboardIcon
                  data-tip="Dashboard"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/accounts-student">
                {" "}
                <SchoolIcon data-tip="Students" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/accounts-employees">
                <SupervisorAccountIcon
                  data-tip="Employees"
                  className="icon-in-hc-color"
                  style={{ color: "#dc3545" }}
                />
                <ReactTooltip />
              </NavLink>

              <NavLink to="/" onClick={() => handleLogout()}>
                <PowerSettingsNewIcon
                  data-tip="Logout"
                  className="icon-in-hc-color"
                />
              </NavLink>
            </div>
          </div>
          <div
            className="body_accounts-employee"
            style={{ width: "100%", height: "2000px" }}
          >
            <div className="heading_hc_class">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "100px", marginTop: "-8%" }}
              />
              {/* <div className="logo-component"></div> */}

              <div
                style={{
                  height: "105px",
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  className="flex-row-0xy0vn"
                  style={{ marginRight: "-24rem" }}
                >
                  <a href="rm-statistics.html"></a>
                  <div className="notifications-24px-2-1-GyJUNH"></div>
                  <div className="group-22-GyJUNH">
                    <div className="biba-sobti-vgli2x">
                      {user && user.first_name} {user && user.last_name}
                    </div>
                    <div className="bibasobtigmailcom-vgli2x">
                      {user && user.emailID}
                    </div>
                  </div>
                  <img className="vector-GyJUNH" src={vector_5_2x} alt="line" />
                </div>
              </div>
            </div>

            {/* <div
                        style={{
                            marginTop: "80px",
                            borderBottom: "3px solid #ec3545",
                            width: "100%",
                        }}
                    ></div> */}
            <div className="create_class">
              <div className="search_text">Record New Payment</div>
              {/* <div className="classroom_content3" style={{ width: "30%" , marginLeft:"9%"}}>
                            <DropdownOption
                                handleCourseSelect={handleCourseSelectCreate}
                                handleGradeSelect={handleGradeSelectCreate}
                                handleSubjectSelect={handleSubjectSelectCreate}
                            />
                            <Dropdown_paymentMode />
                        </div> */}
              <div className="classroom_content4">
                <Form.Group>
                  <Form.Row>
                    <Form.Label column lg={3}>
                      Employee ID
                    </Form.Label>
                    <Col>
                      <Form.Control
                        variant="danger"
                        type="text"
                        value={employeeId}
                        onChange={(e) => setEmployeeId(e.target.value)}
                      />
                    </Col>
                  </Form.Row>
                  <br />

                  <Form.Row>
                    <Form.Label column lg={3}>
                      Payment Mode
                    </Form.Label>
                    <Col>
                      <Form.Control
                        variant="danger"
                        type="text"
                        value={paymentMode}
                        onChange={(e) => setPaymentMode(e.target.value)}
                      />
                    </Col>
                  </Form.Row>
                  <br />
                  <Form.Row>
                    <Form.Label column lg={3}>
                      Payment ID
                    </Form.Label>
                    <Col>
                      <Form.Control
                        variant="danger"
                        type="text"
                        value={paymentId}
                        onChange={(e) => setPaymentId(e.target.value)}
                      />
                    </Col>
                  </Form.Row>
                  <br />
                  {/* <Form.Row>
                                    <Form.Label column lg={3}>
                                        Start Date
                                    </Form.Label>
                                    <Col className="style-a-xdjkhs-1">
                                        <DatePicker
                                            className="datePicker-inshc"
                                            showNow
                                            format="DD-MM-YYYY"
                                            onChange={onClickStartDate}
                                        />
                                    </Col>
                                </Form.Row> */}
                  <Form.Row>
                    <Form.Label column lg={3}>
                      Amount
                    </Form.Label>
                    <Col>
                      <Form.Control
                        variant="danger"
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </Col>
                  </Form.Row>
                  <br />

                  <Form.Row>
                    <Form.Label column lg={3}>
                      From Account Number
                    </Form.Label>
                    <Col>
                      <Form.Control
                        variant="danger"
                        type="text"
                        value={accountNumb}
                        onChange={(e) => setAccountNumb(e.target.value)}
                      />
                    </Col>
                  </Form.Row>
                  <br />
                  <br />
                  <Form.Row>
                    <Form.Label column lg={3}>
                      Employee Type
                    </Form.Label>
                    <Col style={{ marginTop: "8px" }}>
                      {" "}
                      <Dropdown_employee
                        Style={{ left: "30px" }}
                        handleEmployeeSelect={handleEmployeeTypeSelect}
                      />
                    </Col>
                  </Form.Row>
                  <br />
                  <Form.Row>
                    {/* <Form.Label column lg={3}>
                                        Date & Time
                                    </Form.Label>
                                    <Col style={{ paddingRight: "200px" }}>
                                        {isErrorShown ? (
                                            <Message
                                                error
                                                header="Already Selected |"
                                                content=" Choose Different Slot"
                                                style={{
                                                    fontSize: "10px",
                                                    width: "80%",
                                                    textAlign: "center",
                                                }}
                                            />
                                        ) : (
                                            <p style={{ margin: "0px", padding: "0px" }} />
                                        )} */}
                    <div className="style-a-xdjkhs-1">
                      {/* <DatePicker
                                                className="datePicker-inshc"
                                                showTime={
                                                    <TimePicker defaultValue={moment("00", "HH")} />
                                                }
                                                showNow
                                                format="DD-MM-YYYY HH"
                                                onChange={onClickDayTime}
                                            /> */}
                      {/* <button
                                                onClick={(e) => handleSlotSelection(e)}
                                                className="div-btn-anajss01"
                                            >
                                                Add
                                            </button> */}
                    </div>
                    {/* </Col> */}
                  </Form.Row>
                  {/* <Form.Row>
                                    <Form.Label column lg={3}>
                                        List :
                                    </Form.Label>
                                    <Col>
                                        {isErrorShown1 ? (
                                            <Message
                                                error
                                                header="List Is Empty |"
                                                content="Select A Slot"
                                                style={{
                                                    fontSize: "10px",
                                                    width: "80%",
                                                    textAlign: "center",
                                                }}
                                            />
                                        ) : (
                                            <p style={{ margin: "0px", padding: "0px" }} />
                                        )}
                                        {dateTimeList.map((dateTime) => {
                                            return (
                                                <div key={dateTime} className="list-divhc-dajak12">
                                                    {dateTime}
                                                    <DeleteIcon
                                                        onClick={() => onClickDelete(dateTime)}
                                                        style={{
                                                            fontSize: "16px",
                                                            color: "gray",
                                                            margin: "0px 5px",
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </Col>
                                </Form.Row> */}
                </Form.Group>
              </div>

              <div className="create_button">
                <Button variant="danger" onClick={recordPayment}>
                  Submit
                </Button>
              </div>
            </div>
            <div
              style={{
                marginTop: "80px",
                borderBottom: "3px solid #ec3545",
                width: "104.5%",
                marginLeft: "-57px",
              }}
            ></div>
            <div list-box style={{ marginLeft: "48px" }}>
              <div className="list-of-students-5GLwEc-accounts-employee">
                <span>List of Employee Transactions</span>
                <input
                  type="text"
                  placeholder="Filter List"
                  style={{
                    width: "210px",
                    marginRight: "5px",
                    color: "black",
                  }}
                  onChange={(e) => setHandleFilter(e.target.value)}
                />
              </div>
              <div className="group-45-5GLwEc-ly-employee">
                <div className="rectangle-20-Cd7ClN-in-accounts-employee">
                  <div className="roboto-medium-white-20px">ID</div>
                  <div className="roboto-medium-white-20px">Emp Acc ID</div>
                  <div className="roboto-medium-white-20px">
                    From account_number
                  </div>
                  <div className="roboto-medium-white-20px">Date</div>
                  <div className="roboto-medium-white-20px">Time</div>
                  <div className="roboto-medium-white-20px">Amount</div>
                  <div className="roboto-medium-white-20px">Payment-Mode</div>
                </div>
              </div>
              <div className="rectangle-30-y1HRxC-10-c-accounts-employee">
                <div className="nfkjbdajhbjd-fajdn3-employee">
                  {payments.length > 0 ? (
                    payments
                      .filter((res) => {
                        const test_temp = res.employee_account_id;
                        if (handleFilter === "") {
                          return res;
                        } else if (
                          test_temp
                            .toLowerCase()
                            .includes(handleFilter.toLowerCase())
                        ) {
                          return res;
                        }
                      })
                      .map((item) => {
                        return (
                          <div
                            key={item && item.id}
                            className="group-56-y1HRxC-9-ew-accounts-employee"
                          >
                            <div className="payga4wk-z-bshl-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px">
                              {item && item.id}
                            </div>
                            <div className="x499900-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px">
                              {item && item.employee_account_id.length <= 17
                                ? item.employee_account_id
                                : item.employee_account_id.slice(0, 17) + "..."}
                            </div>
                            <div className="x07-dec-2020-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px">
                              {item && item.from_account_number}
                            </div>
                            <div className="x014009-pm-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px">
                              {item && moment(item.date_time).format("LL")}
                            </div>
                            <div className="andb586-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px">
                              {item && moment(item.date_time).format("LT")}
                            </div>
                            <div className="andb586-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px">
                              {item && item.amount}
                            </div>
                            <div className="andb586-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px">
                              {item && item.payment_mode}
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                      No occupied students
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  marginTop: "80px",
                  borderBottom: "3px solid #ec3545",
                  width: "104.5%",
                  marginLeft: "-57px",
                }}
              ></div>

              <div
                className="range-selector-part-1"
                style={{ marginTop: "10%" }}
              >
                <div
                  className="input-group mb-3"
                  style={{ width: "30%", marginRight: "5%" }}
                >
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      Employee ID
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={employeeName}
                    onChange={(e) => setEmployeeName(e.target.value)}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>

                <div className="select-dates-1">Select Range</div>

                <Col className="style-a-xdjkhs-2-accounts-dashboard">
                  <RangePicker
                    className="datePicker-inshc"
                    showNow
                    format="DD-MM-YYYY"
                    onChange={onClickOutgoingDate}
                  />
                </Col>
                <div className="filter-class-1" style={{ marginTop: "-13px" }}>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={getEmployeeSalary}
                  >
                    Submit
                  </button>
                </div>
              </div>

              <div className="input-group mb-3" style={{ width: "50%" }}>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    Decided Salary
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Decided Salary"
                  value={
                    salaryDetails &&
                    salaryDetails.employee_data &&
                    salaryDetails.employee_data.teaching_type
                      ? salaryDetails.employee_data.teaching_type == 2
                        ? "₹" +
                          salaryDetails.employee_data.per_class.toString() +
                          " per Hour"
                        : salaryDetails.employee_data.teaching_type == 1
                        ? "₹" +
                          salaryDetails.employee_data.per_month.toString() +
                          " per Month"
                        : "₹" +
                          salaryDetails.employee_data.per_package.toString() +
                          " per Package"
                      : "₹" +
                        (salaryDetails.employee_data &&
                          salaryDetails.employee_data.per_month.toString()) +
                        " per Month"
                  }
                  aria-label="Username"
                  disabled
                  aria-describedby="basic-addon1"
                  style={{ width: "30%" }}
                />
              </div>

              <div className="input-group mb-3" style={{ width: "50%" }}>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    Final Payment
                  </span>
                </div>
                <input
                  type="text"
                  disabled
                  className="form-control"
                  value={
                    salaryDetails && "₹" + salaryDetails.final_payment + "/-"
                  }
                  placeholder="Final Payment"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default AccountsEmployee;
