import React, { useLayoutEffect, useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import LoginModal from "../../../components/Login_modal/loginModal";
import CustomNav from "../../../components/header/Navbar";
import Background from "./bgcanvas.png";
import Footer from "../../../components/Footer";
import pic_5 from "../../student/dashboard/img/pic_5.png";
import Popup2 from "../../non_auth/new_popup/popup2";
import "./common.css";
Modal.setAppElement("#root");

function Cuet() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section style={{ width: "100%" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "80%", marginTop: "5%" }}>
            <div
              style={{
                color: "#dc3545",
                fontSize: 20,
                fontWeight: 700,
                lineHeight: "1.6",
                textAlign: "left",
              }}
            >
              CUET 2024/25 Coaching for 3 Lakh+ Undergraduate Seats across 250+
              Premier Universities!
            </div>
            <div
              style={{
                marginTop: "2%",
                marginBottom: "2%",
                textAlign: "left",
                fontSize: 18,
                fontWeight: 600,
              }}
            >
              Enroll in CUET Coaching Classes for Comprehensive Preparation!
            </div>
            <div
              style={{
                marginTop: "2%",
                borderStyle: "solid",
                borderWidth: "5px",
                borderColor: "#dc3545",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "1%",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 5 }}>
                    Date of Commencement
                  </div>
                  <div style={{ fontWeight: 600 }}>1 January 2024</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>Mode</div>
                  <div style={{ fontWeight: 600 }}>Offline/Online</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>
                    Duration
                  </div>
                  <div style={{ fontWeight: 600 }}>3 Months</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>Fee</div>
                  <div style={{ fontWeight: 600 }}>Rs. 15,000/- (3 Month)</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>
                    Admission
                  </div>
                  <div style={{ fontWeight: 600 }}>Open</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>
                    Class Timing
                  </div>
                  <div style={{ fontWeight: 600 }}>8:00 AM to 8: PM PM</div>
                </div>
              </div>

              <div
                style={{ display: "flex", marginLeft: 20, marginRight: 20 }}
                className="addrs"
              >
                <div
                  style={{
                    borderStyle: "solid",
                    borderWidth: 3,
                    borderColor: "#000",
                    padding: 20,
                    width: "60%",
                    marginTop: "2%",
                    marginBottom: "2%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "-42px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#1d74ca",
                        width: 200,
                        padding: 8,
                        borderStyle: "solid",
                        borderWidth: 3,
                        borderColor: "#000",
                      }}
                    >
                      <h2
                        style={{
                          color: "#fff",
                          fontWeight: 600,
                          fontSize: 20,

                          textAlign: "center",
                        }}
                      >
                        Location
                      </h2>
                    </div>
                  </div>
                  <br />
                  <div style={{ fontWeight: 600 }}>
                    311 Vardhman Sunder Plaza, Plot No. 12 MLU Sec - 12 Dwarka,
                    New Delhi 110078
                  </div>
                  <br />
                  <div style={{ fontWeight: 600 }}>
                    42 Second Floor, Omaxe Galleria, Sector 14, Jhajjar Road,
                    Bahadurgarh, Haryana - 124-507
                  </div>
                </div>

                <div
                  style={{
                    borderStyle: "solid",
                    borderWidth: 3,
                    borderColor: "#000",
                    padding: 20,

                    marginTop: "2%",
                    marginBottom: "2%",
                    marginLeft: "5%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "-42px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#1d74ca",
                        width: 150,
                        padding: 8,
                        borderStyle: "solid",
                        borderWidth: 3,
                        borderColor: "#000",
                      }}
                    >
                      <h2
                        style={{
                          color: "#fff",
                          fontWeight: 600,
                          fontSize: 20,

                          textAlign: "center",
                        }}
                      >
                        Contact Us
                      </h2>
                    </div>
                  </div>
                  <br />
                  <div style={{ fontWeight: 600 }}>9570-424242</div>
                  <br />
                  <div style={{ fontWeight: 600 }}>9647-787878</div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "2%",
                  }}
                >
                  <Link to="/trial-student-form">
                    <div className="book-btn">Book Free Trial</div>
                  </Link>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "2%",
                    marginLeft: 10,
                  }}
                >
                  <a href="tel:+919570424242">
                    <div className="cons-btn">Call for Free Consultation</div>
                  </a>
                </div>
              </div>
            </div>
            <div style={{ marginBottom: "2%" }}>
              <h2
                style={{
                  color: "#56A5F5",
                  fontWeight: 600,
                  fontSize: 20,
                  marginTop: "3%",
                }}
              >
                About The Right Guru Classroom Programme
              </h2>
              <p
                style={{
                  fontSize: 16,
                  marginTop: "1%",
                  fontWeight: 500,
                  lineHeight: "1.6",
                }}
              >
                Since 2021, The Right Guru (TRG) has been a prominent player in
                the field of quality education, particularly for competitive
                exams like the SSC CGL, CHSL, MTS, CUET, DSSSB, CLAT, and also
                catering to CBSE/ICSE and other board examinations. With a
                dedicated team and a commitment to excellence, TRG understands
                the unique requirements of aspirants striving to excel in these
                competitive exams and contribute to their career growth.
              </p>
            </div>
            <div style={{ marginBottom: "2%" }}>
              <h2
                style={{
                  color: "#56A5F5",
                  fontWeight: 600,
                  fontSize: 20,
                  marginTop: "3%",
                }}
              >
                Why Choose TRG?
              </h2>
              <p
                style={{
                  fontSize: 16,
                  marginTop: "1%",
                  fontWeight: 500,
                  lineHeight: "1.6",
                }}
              >
                At TRG, we are committed to nurturing academic growth, fostering
                a deep understanding of subjects, and providing personalized
                attention to every student. Our diverse teaching methods and
                experienced educators create an enriching learning environment.
              </p>
            </div>
            <div style={{ marginBottom: "2%" }}>
              <h2
                style={{
                  color: "#56A5F5",
                  fontWeight: 600,
                  fontSize: 20,
                  marginTop: "3%",
                }}
              >
                TRG CUET Programme Highlights
              </h2>
              <div
                style={{
                  borderStyle: "solid",
                  borderWidth: 5,
                  borderRadius: 8,
                  borderColor: "#dc3545",
                  padding: 10,
                  lineHeight: 1.6,
                  marginTop: "2%",
                }}
              >
                <h3 style={{ color: "#56a5f5", fontWeight: 500 }}>
                  Engage in Regular Classes
                </h3>
                <p style={{}}>
                  &#9635;
                  <span>&nbsp;</span>
                  Participate in scheduled regular classes that provide
                  consistent guidance, covering every aspect of the CUET
                  examination.
                </p>
                <h3 style={{ color: "#56a5f5", fontWeight: 500 }}>
                  Comprehensive Study Material
                </h3>
                <p style={{}}>
                  &#9635;
                  <span>&nbsp;</span>
                  Access meticulously curated study material for each subject,
                  designed to facilitate a thorough understanding of the CUET
                  syllabus.
                </p>
                <h3 style={{ color: "#56a5f5", fontWeight: 500 }}>
                  Offline Classroom Mode
                </h3>
                <p style={{}}>
                  &#9635;
                  <span>&nbsp;</span>
                  Immerse yourself in an offline classroom environment, allowing
                  for direct interaction with instructors and peers to enhance
                  learning.
                </p>

                <h3 style={{ color: "#56a5f5", fontWeight: 500 }}>
                  Weekly Assessment
                </h3>
                <p style={{}}>
                  &#9635;
                  <span>&nbsp;</span>
                  Measure your progress with regular weekly assessments that
                  pinpoint your strengths and areas needing improvement.
                </p>
                <h3 style={{ color: "#56a5f5", fontWeight: 500 }}>
                  Free Offline Test Series
                </h3>
                <p style={{}}>
                  &#9635;
                  <span>&nbsp;</span>
                  Access our exclusive offline test series to simulate real exam
                  conditions and boost your confidence before the actual CUET
                  exam.
                </p>

                <h3 style={{ color: "#56a5f5", fontWeight: 500 }}>
                  Guidance from Experienced Mentors
                </h3>
                <p style={{}}>
                  &#9635;
                  <span>&nbsp;</span>
                  Receive valuable guidance from experienced mentors who will
                  provide insights, tips, and strategies to excel in the CUET
                  exam.
                </p>
                <h3 style={{ color: "#56a5f5", fontWeight: 500 }}>
                  Admission Opens on 1st January 2024
                </h3>
                <p style={{}}>
                  &#9635;
                  <span>&nbsp;</span>
                  Secure your spot early! Admissions commence from 1st January
                  2024, enabling you to start your preparation journey promptly.
                </p>
                <h3 style={{ color: "#56a5f5", fontWeight: 500 }}>
                  Course Duration: 3 Months
                </h3>
                <p style={{}}>
                  &#9635;
                  <span>&nbsp;</span>
                  Join our structured CUET coaching programme that spans over 3
                  months, ensuring an in-depth and well-paced curriculum
                  delivery.
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginBottom: "5%",
              }}
            >
              <a
                target="_blank"
                href="https://therightguru-website.s3.ap-south-1.amazonaws.com/CUET+1.pdf"
              >
                <div className="book-btn">Know more...</div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section>
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default Cuet;
