import React, { useState, useEffect, useLayoutEffect } from "react";
import PowerSettingsToneIcon from "@material-ui/icons/PowerSettingsNew";
import { Link, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import ClassSharpIcon from "@material-ui/icons/ClassSharp";

import {
  MdAssignment,
  MdDashboard,
  MdNotificationsNone,
  MdSchool,
} from "react-icons/md";
import { FaRegQuestionCircle, FaChartPie, FaPowerOff } from "react-icons/fa";
import { AiTwotoneBank } from "react-icons/ai";
import MenuIcon from "@material-ui/icons/Menu";
import SideDrawerHc from "../../../UI/sideDrawer/sideDrawerHc";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import "./hc_detail_view.css";
import Backdrop from "../../../UI/Backdrop/Backdrop";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteHc } from "../../../redux/actions/hc";

import { MenuOutlined, BellOutlined, HomeOutlined } from "@ant-design/icons";

import {
  teacherDetail,
  teacherPersonalDetail,
  teacherSubGrade,
  HCModifyTeacher,
} from "../../../crud/highercommittee.crud";
import HcStatus from "./status_hc_comp/Status";
import HcComponent from "./content_hc_comp/ContentComponent";
import { SettingsApplicationsRounded } from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import Popup from "../../teacher/popup/popup";
function HcDetailView(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  const user = useSelector((state) => state.hcDetails.hc);
  const [teacherData, setTeacherData] = useState([]);
  const [isLoaded, setIsLoaded] = useState([]);
  const [teacherPersonal, setTeacherPersonal] = useState([]);
  const [teach, setTeach] = useState([]);
  const [isteacherChange, setIsTeacherChange] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { teacherID, gradeValue, subjectValue } = useParams();
  const [teacher_id, setTeacherid] = useState("");
  const [student_id, setStudentid] = useState("");

  const ModalToggler = () => {
    setShowModal((previousState) => !previousState);
  };

  let the_at_class = ["div-hc-tech-modal", "Display-out-tech"];
  if (showModal) {
    the_at_class = ["div-hc-tech-modal", "Display-in-tech"];
  }

  //Its component is not present yet
  const handleModify = (e) => {
    e.preventDefault();
    HCModifyTeacher({
      teacher_id,
      new_teacher_id: teacherID,
      student_id,
      subject_id: subjectValue,
      grade_id: gradeValue,
    })
      .then((res) => {
        console.log(res)
        alert("Teacher Modified Successfully!");
        setIsTeacherChange(!isteacherChange);
      })
      .catch((err) => console.log(err));
  };

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(deleteHc(user.hc_id));
    removeToken();
  };

  useEffect(() => {
    teacherDetail(teacherID)
      .then((res) => {
        setTeacherData(res);
        setIsLoaded(true);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    teacherPersonalDetail(teacherID)
      .then((res) => {
        setTeacherPersonal(res);
        setIsLoaded(true);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    teacherSubGrade(teacherID)
      .then((res) => {
        setTeach(res);
        setIsLoaded(true);
      })
      .catch((err) => console.log(err));
  }, []);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  return (
    <div className="hc_detail_layout">
      <Backdrop open={showModal} clicked={ModalToggler} />
      <div className={the_at_class.join(" ")}>
        <CancelIcon className="icon-to-cancel-modal" onClick={ModalToggler} />
        <form className="hc-form-in-wdt-align-tech-23">
          <input
            type="text"
            name="student_ID"
            placeholder="Student ID"
            onChange={(e) => setStudentid(e.target.value)}
          />
          <input
            type="text"
            name="current_rm_ID"
            placeholder="Current Teacher ID"
            onChange={(e) => setTeacherid(e.target.value)}
          />
          <input
            type="text"
            name="new_rm_ID"
            placeholder="New Teacher ID"
            value={teacherID}
          />
          <button
            className="butt-in-hc-form-4px-tech"
            onClick={(e) => handleModify(e)}
          >
            Modify
          </button>
        </form>
      </div>
      <div className="header-wdt-100">
        <header className="Header">
          <HomeOutlined className="logo" />
          <span className="heder-men-not">
            <BellOutlined className="notification" />
            <SideDrawerHc
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />

            <MenuOutlined className="menu" onClick={sideDrawerToggler} />
          </span>
        </header>
      </div>

      <div className="sider-hc-detail">
        <span style={{ color: "white" }}>
          <div className="sidenav">
            <div className="sidelink">
              <Link to="/hc-dashboard">
                <MdDashboard
                  data-tip="Dashboard"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
            <div className="sidelink">
              <Link to="/hc-teachers-list">
                {" "}
                <MdSchool
                  data-tip="Teacher"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
            <div className="sidelink">
              <Link to="/hc-suggest-rm">
                <SupervisorAccountIcon
                  data-tip="Relational Manager"
                  size={24}
                  style={{ color: "rgba(179,179,179,1.0)" }}
                />
                <ReactTooltip />
              </Link>
            </div>
            <div className="sidelink">
              <Link to="/hc-ticket-list">
                <MdAssignment
                  data-tip="Ticket List"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
            <div className="sidelink">
              <Link to="/hc-payments">
                <AiTwotoneBank
                  data-tip="Payments"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
            <div className="sidelink">
              <Link to="/hc-classroom">
                <ClassSharpIcon
                  data-tip="Classroom"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
            <div className="sidelink">
              <Link to="/" onClick={() => handleLogout()}>
                <PowerSettingsToneIcon
                  data-tip="Logout"
                  size={24}
                  style={{ color: "rgba(179,179,179,1.0)", marginTop: "60px" }}
                />
                <ReactTooltip />
              </Link>
            </div>
          </div>
        </span>
      </div>

      <div className="content-in-hc-layout">
        <HcStatus
          teacherData={teacherData}
          teacherPersonal={teacherPersonal}
          teach={teach}
          changeTeacher={ModalToggler}
        />
        <HcComponent teacherData={teacherData} changeTeacher={ModalToggler} />
      </div>
      {/* <Popup /> */}
    </div>
  );
}

export default HcDetailView;
