import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';

function PrivateRoute({ children, type, ...rest }) {

    let user = useSelector(state => state);
    if (type === "student") {
        user = user.studentDetails
    } else if (type === "teacher") {
        user = user.teacherDetails
    }

    return (
        <Route {...rest} render={(props) => {
            return localStorage.getItem("access") === null
                ? <Redirect to='/' />
                : children
        }} />
    )
}

export default PrivateRoute;