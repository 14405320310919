import axios from "axios";
import { toAPIURL } from "../../../utils";
import { GET_RM, DELETE_RM, ADD_ISSUE } from "./types";

// GET RM
export const getRm = (user_data, history) => dispatch => {
    axios
        .get(toAPIURL("api/rm-details/" + user_data.email))
        .then(res => {
            dispatch({
                type: GET_RM,
                payload: res.data
            });
            history.replace("/rm-dashboard")
        })
        .catch(err => console.log(err));
}

// DELETE RM
export const deleteRm = rmId => ({
    type: DELETE_RM,
    payload: rmId
})

// ADD ISSUE
export const addIssue = issueMessage => ({
    type: ADD_ISSUE,
    payload: issueMessage
})