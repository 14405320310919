import React, { useLayoutEffect, useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { TbPointFilled } from "react-icons/tb";

import LoginModal from "../../../components/Login_modal/loginModal";
import CustomNav from "../../../components/header/Navbar";

import Footer from "../../../components/Footer";
import pic_5 from "../../student/dashboard/img/pic_5.png";
import Popup2 from "../../non_auth/new_popup/popup2";

Modal.setAppElement("#root");

const list = [
  {
    id: 1,
    title: "RBI Grade B Examination",
  },
  {
    id: 2,
    title:
      "Botanist, Legal Officer, Joint Assistant Director, Specialist Gr III recruitment",
  },
  {
    id: 3,
    title: "Combined Geo-Scientist & Geologist Examination",
  },
  {
    id: 4,
    title: "IES/ ISS Examination",
  },
  {
    id: 5,
    title: "Indian Space Research Organization Scientist Examination",
  },
  {
    id: 6,
    title: "Stenographer Grade C&D recruitment",
  },
  {
    id: 7,
    title: "Specialist Cadre Officer Examination",
  },
  {
    id: 8,
    title: "Deputy General Manager, Analyst",
  },
  {
    id: 9,
    title: "RRB NTPC Examination",
  },
  {
    id: 10,
    title: "LIC AAO Examination",
  },
  {
    id: 11,
    title:
      "Intelligence Bureau Assistant Central Intelligence Officers Examination",
  },
  {
    id: 12,
    title: "Defense Research & Development Organization SET Examination",
  },
  {
    id: 13,
    title: "UIDAI Examination",
  },
  {
    id: 14,
    title: "Indian Economic Service (IES) Exam",
  },
  {
    id: 15,
    title: "Indian Statistical Service (ISS) Exam",
  },
  {
    id: 16,
    title: "Defense Research and Development Organization (DRDO) Exam",
  },
  {
    id: 17,
    title: "Indian Forest Service (IFS) Exam",
  },
  {
    id: 18,
    title: "Intelligence Bureau (IB) Exam",
  },
  {
    id: 19,
    title: "Reserve Bank of India (RBI) Exams",
  },
  {
    id: 20,
    title: "Staff Selection Commission (SSC) Exams (other than SSC CGL)",
  },
  {
    id: 21,
    title: "State Public Service Commission Exams",
  },
];

function Other_Cs() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "3%",
          }}
        >
          <div
            style={{
              width: "80%",
              marginTop: "3%",
              height: "auto",
            }}
          >
            <h1 style={{ fontSize: 24, color: "#dc3545", fontWeight: 600 }}>
              Other Government Services
            </h1>
            <p
              style={{
                fontSize: 16,
                marginTop: "1%",
                fontWeight: 500,
                lineHeight: "1.6",
              }}
            >
              Numerous government job examinations in India, administered by
              various government bodies, often don't garner as much attention as
              well-known exams like the UPSC Civil Services or SSC Combined
              Graduate Level. Nonetheless, these lesser-known exams provide
              promising career prospects and opportunities.
            </p>
            <h2
              style={{
                fontSize: 18,
                marginTop: "1%",
                fontWeight: 600,
                lineHeight: "1.6",
              }}
            >
              Here are the list of some lesser know government exam yet, offers
              decent career option:
            </h2>
            {
              <div>
                {list.map((item) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "1%",
                        gap: "1%",
                      }}
                    >
                      <div>
                        <TbPointFilled size={20} color="#dc3545" />
                      </div>
                      <div
                        style={{
                          width: "95%",
                          height: "5%",

                          paddingLeft: "2%",
                          fontSize: 16,
                          fontWeight: 500,
                        }}
                      >
                        {item.title}
                      </div>
                    </div>
                  );
                })}
              </div>
            }
          </div>
        </div>
      </section>

      <section>
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default Other_Cs;
