import React, { useState, useEffect, useLayoutEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useSelector, useDispatch } from "react-redux";
import Popup from "../../teacher/popup/popup";

import { MdAssignment, MdDashboard, MdNotificationsNone, MdSchool } from "react-icons/md";
import { BiSupport, BiHistory } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { AiTwotoneBank } from "react-icons/ai";
import line_5_2x from "./img/line-5@2x.svg";
import line_6_2x from "./img/line-6@2x.svg";
import vector_4_2x from "./img/vector-4@2x.svg";
import vector_5_2x from "./img/vector-5@2x.svg";
import line_5_1_2x from "./img/line-5-1@2x.svg";
import line_6_1_2x from "./img/line-6-1@2x.svg";
import ellipse_2_1_2x from "./img/ellipse-2-1@2x.svg";
import { NavLink } from "react-router-dom";
import { FaRegQuestionCircle, FaCalendarAlt, FaFileAlt, FaChartPie, FaStickyNote, FaPowerOff } from "react-icons/fa";
import pic_2 from "../../student/dashboard/img/pic_2.png";
import DashboardIcon from '@material-ui/icons/Dashboard';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SchoolIcon from '@material-ui/icons/School';
import SideDrawerSales from '../../../UI/sideDrawer/sideDrawerSales';
import { removeToken } from '../../../utils';
import { deleteSales } from '../../../redux/actions/sales';


function Salespayments(props) {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
    const user = useSelector((state) => state.salesDetails.sales);

    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(deleteSales(user.sales_id))
        removeToken();
    }

    const sideDrawerToggler = () => {
        setDisplaySideDrawer(previousState => !previousState);
    }

    return (
      <>
        <input
          type="hidden"
          id="anPageName"
          name="page"
          value="sales-payments"
        />
        <div className="container-center-horizontal">
          <div className="sales-payments screen">
            {/* <div className="group-209-C61RwL"> */}
            {/* <Link to="/sales-dashboard"><MdDashboard size={24} data-tip="Dashboard" color="rgba(179,179,179,1.0)" /><ReactTooltip /></Link>

                        <div className="school-24px-1-D0BVcB">
                            <Link to="/sales-my-students"><MdSchool data-tip="My Students" size={24} color="rgba(179,179,179,1.0)" /><ReactTooltip /></Link>
                        </div>

                        <div className="analytics-24px-4-D0BVcB">
                            <Link to="/rm-statistics"><FaChartPie data-tip="Analytics" size={24} color="rgba(179,179,179,1.0)" /><ReactTooltip /></Link>
                        </div>
                        <div className="accountbalance-24px-1-D0BVcB">
                            <Link to="/sales-payments"><AiTwotoneBank data-tip="Payments" size={24} color="#dc3545" /><ReactTooltip /></Link>
                        </div>
                        <div className="accountbalance-24px-1-D0BVcB">
                            <Link to="/rm-statistics"><FaRegQuestionCircle data-tip="Help Center" size={24} color="rgba(179,179,179,1.0)" /><ReactTooltip /></Link>
                        </div>
                        
                        <img className="vector-Ayxm2x" src="img/vector-6@2x.svg" /> */}
            {/* <FaPowerOff size={24} color="rgba(179,179,179,1.0)"/> */}
            {/* </div> */}

            <div className="sider-SalesStatistics-detail">
              <span style={{ color: "white" }}>
                <div className="sider-hc-teacher-list-in-wdt-80">
                  <NavLink to="/sales-dashboard">
                    <DashboardIcon
                      data-tip="Dashboard"
                      className="icon-in-hc-color"
                    />
                    <ReactTooltip />
                  </NavLink>
                  <NavLink to="/sales-my-students">
                    {" "}
                    <SchoolIcon
                      data-tip="My Students"
                      className="icon-in-hc-color"
                    />
                    <ReactTooltip />
                  </NavLink>
                  <NavLink to="/sales-statistics">
                    <AssessmentIcon
                      data-tip="Analytics"
                      className="icon-in-hc-color"
                    />
                    <ReactTooltip />
                  </NavLink>
                  <NavLink to="/sales-trial-student-form/:salesId">
                    <AccountBalanceIcon
                      data-tip="Trial Student Form"
                      className="icon-in-hc-color"
                      style={{ color: "#dc3545" }}
                    />
                    <ReactTooltip />
                  </NavLink>
                  {/* <NavLink to="/rm-statistics"><FaRegQuestionCircle data-tip="Help Center" className="icon-in-hc-color" /><ReactTooltip /></NavLink> */}
                  <NavLink to="/" onClick={() => handleLogout()}>
                    <PowerSettingsNewIcon
                      data-tip="Logout"
                      className="icon-in-hc-color"
                    />
                  </NavLink>
                </div>
              </span>
            </div>

            <div className="flex-col-C61RwL">
              <div className="flex-row-0xy0vn">
                <div className="component-1-GyJUNH">
                  <img src={pic_2} style={{ height: "115px", margin: "-8%" }} />
                </div>
                <div className="group-125-GyJUNH">
                  <div className="flex-col-OfAOxu">
                    <div className="flex-row-xqwxU1">
                      <div className="total-issues-cSfBx4 montserrat-semi-bold-blue-zodiac-18px">
                        Total Students -
                      </div>
                      <div className="x43-cSfBx4 montserrat-medium-blue-zodiac-18px">
                        43
                      </div>
                    </div>
                    <div className="flex-row-qItZ2O">
                      <div className="solved-issues-302Mxf montserrat-semi-bold-blue-zodiac-18px">
                        Solved Students -
                      </div>
                      <div className="x40-302Mxf montserrat-medium-blue-zodiac-18px">
                        40
                      </div>
                    </div>
                    <div className="flex-row-BeEgsN">
                      <div className="percentage-XDafRx montserrat-semi-bold-blue-zodiac-18px">
                        Percentage -
                      </div>
                      <div className="x97-XDafRx">97%</div>
                    </div>
                    <div className="number-of-issues-per-day-xqwxU1 montserrat-semi-bold-blue-zodiac-18px">
                      Number of Students Per Day -
                    </div>
                  </div>
                  <div className="x7-OfAOxu montserrat-medium-blue-zodiac-18px">
                    7
                  </div>
                </div>
                <div className="group-115-GyJUNH">
                  <div className="notifications-24px-2-1-GyJUNH">
                    <div className="vector-40xw7g">
                      <MdNotificationsNone size={28} />
                    </div>
                    <img className="ellipse-2-RYyMFx" src={ellipse_2_1_2x} />
                  </div>
                  <div className="group-22-3boKt2">
                    <div className="flex-col-R6Ddt1">
                      <div className="biba-sobti-2jrdHj">
                        {user && user.first_name + " " + user && user.last_name}
                      </div>
                      <div className="bibasobtigmailcom-2jrdHj">
                        {user && user.emailID}
                      </div>
                    </div>
                    <img className="vector-R6Ddt1" src="img/vector-1@2x.svg" />
                  </div>
                </div>
              </div>
              <div className="flex-row-oV5UrE">
                <div className="overlap-group-QkTteQ">
                  <div className="status-dlP8Ul montserrat-semi-bold-gray-12px">
                    Status
                  </div>
                  <div className="rectangle-47-dlP8Ul"></div>
                  <div className="completed-dlP8Ul">Completed</div>
                  <div className="rectangle-31-dlP8Ul"></div>
                  <div className="payment-id-dlP8Ul montserrat-semi-bold-gray-12px">
                    Payment Id
                  </div>
                  <div className="amount-dlP8Ul montserrat-semi-bold-gray-12px">
                    Amount
                  </div>
                  <div className="date-dlP8Ul montserrat-semi-bold-gray-12px">
                    Date
                  </div>
                  <div className="time-dlP8Ul montserrat-semi-bold-gray-12px">
                    Time
                  </div>
                  <div className="transferred-to-dlP8Ul montserrat-semi-bold-gray-12px">
                    Transferred To
                  </div>
                  <div className="recent-payments-dlP8Ul">Recent payments</div>
                  <div className="rectangle-36-dlP8Ul"></div>
                  <div className="payga4wk-z-bshl-dlP8Ul montserrat-semi-bold-blue-zodiac-10px">
                    pay_GA4wkZBshl
                  </div>
                  <div className="x499900-dlP8Ul montserrat-semi-bold-blue-zodiac-10px">
                    <span className="span0-Kodf6P">4999.</span>
                    <span className="span1-Kodf6P">00</span>
                  </div>
                  <div className="x07-dec-2020-dlP8Ul montserrat-semi-bold-blue-zodiac-10px">
                    07 Dec 2020
                  </div>
                  <div className="x014009-pm-dlP8Ul montserrat-semi-bold-blue-zodiac-10px">
                    01:40:09 pm
                  </div>
                  <div className="andb586-dlP8Ul montserrat-semi-bold-blue-zodiac-10px">
                    ANDB*******586
                  </div>
                  <div className="rectangle-37-dlP8Ul"></div>
                  <div className="rectangle-38-dlP8Ul"></div>
                  <div className="rectangle-39-dlP8Ul"></div>
                  <div className="rectangle-48-dlP8Ul"></div>
                  <div className="rectangle-49-dlP8Ul"></div>
                  <div className="rectangle-50-dlP8Ul"></div>
                </div>
                <div className="insertinvitation-24px-1-QkTteQ">
                  <img className="vector-bpjCos" src="img/vector@2x.png" />
                </div>
                <div className="group-202-QkTteQ">
                  <div className="open-issue-list-4FsOUs montserrat-semi-bold-blue-zodiac-18px">
                    Open Issue List
                  </div>
                  <div className="overlap-group1-4FsOUs">
                    <div className="rectangle-31-H1OX4C"></div>
                    <div className="rectangle-40-H1OX4C"></div>
                    <div className="rectangle-41-H1OX4C"></div>
                    <div className="rectangle-47-H1OX4C"></div>
                    <div className="x1-H1OX4C roboto-medium-blue-zodiac-14px">
                      1
                    </div>
                    <div className="x2-H1OX4C roboto-medium-blue-zodiac-14px">
                      2
                    </div>
                    <div className="vatsal-agarwal-H1OX4C roboto-medium-blue-zodiac-14px">
                      Vatsal Agarwal
                    </div>
                    <div className="in-progess-H1OX4C roboto-medium-blue-zodiac-14px">
                      In Progess
                    </div>
                    <div className="group-92-H1OX4C"></div>
                    <div className="group-95-H1OX4C"></div>
                    <div className="group-96-H1OX4C"></div>
                    <div className="group-94-H1OX4C"></div>
                    <div className="group-99-H1OX4C">
                      <div className="rectangle-48-WteuDV"></div>
                      <div className="shreyans-jain-WteuDV roboto-medium-blue-zodiac-14px">
                        Shreyans Jain
                      </div>
                      <div className="pending-WteuDV roboto-medium-blue-zodiac-14px">
                        Pending
                      </div>
                    </div>
                    <div className="group-91-H1OX4C">
                      <div className="rectangle-48-3A77zI"></div>
                      <div className="kunal-kashyap-3A77zI roboto-medium-blue-zodiac-14px">
                        Kunal Kashyap
                      </div>
                      <div className="ticket-3A77zI roboto-medium-blue-zodiac-14px">
                        Ticket
                      </div>
                    </div>
                    <div className="sos-H1OX4C roboto-medium-crusta-14px">
                      SOS
                    </div>
                    <div className="sos-Oxmxv6 roboto-medium-crusta-14px">
                      SOS
                    </div>
                    <div className="status-H1OX4C roboto-bold-gray-16px">
                      Status
                    </div>
                    <div className="s-no-H1OX4C roboto-bold-gray-16px">
                      S.No.
                    </div>
                    <div className="student-name-H1OX4C roboto-bold-gray-16px">
                      Student Name
                    </div>
                    <div className="category-H1OX4C roboto-bold-gray-16px">
                      Category
                    </div>
                    <div className="x3-H1OX4C roboto-medium-blue-zodiac-14px">
                      3
                    </div>
                    <div className="x4-H1OX4C roboto-medium-blue-zodiac-14px">
                      4
                    </div>
                    <div className="x5-H1OX4C roboto-medium-blue-zodiac-14px">
                      5
                    </div>
                    <div className="x6-H1OX4C roboto-medium-blue-zodiac-14px">
                      6
                    </div>
                    <div className="x7-H1OX4C roboto-medium-blue-zodiac-14px">
                      7
                    </div>
                    <div className="x8-H1OX4C roboto-medium-blue-zodiac-14px">
                      8
                    </div>
                    <div className="x9-H1OX4C roboto-medium-blue-zodiac-14px">
                      9
                    </div>
                    <div className="standard-H1OX4C roboto-medium-niagara-14px">
                      Standard
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-204-0xy0vn">
                <div className="group-125-4xLMVj"></div>
                <img className="vector-4xLMVj" src={vector_4_2x} />
                <div className="messages-4xLMVj">Messages</div>
                <img className="vector-Axu9vm" src={vector_5_2x} />
              </div>
            </div>
          </div>
        </div>
        <div className="container-center-horizontal">
          <div className="sales-mobile-payments screen">
            <div className="flex-row-C61RwL">
              <div className="component-1-dXBp89">
                <img className="line-5-Q41bBg" src={line_5_1_2x} />
                <img className="line-6-Q41bBg" src={line_6_1_2x} />
              </div>
              <SideDrawerSales
                open={displaySideDrawer}
                closeSideDrawer={sideDrawerToggler}
              />
              <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
                <div className="rectangle-48-X4qzjs"></div>
                <div className="rectangle-49-X4qzjs"></div>
                <div className="rectangle-50-X4qzjs"></div>
              </div>
            </div>
            <div className="group-125-C61RwL">
              <div className="group-124-kWlObw">
                <div className="group-41-e1mbBS">
                  <div className="group-40-7vmrSC">
                    <div className="group-39-agxh6K">
                      <div className="group-38-fXlfDP"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="total-issues-kWlObw montserrat-semi-bold-blue-zodiac-20px">
                Total Students -
              </div>
              <div className="x43-kWlObw">43</div>
              <div className="solved-issues-kWlObw montserrat-semi-bold-blue-zodiac-20px">
                Solved Students -
              </div>
              <div className="x40-kWlObw">40</div>
              <div className="percentage-kWlObw montserrat-semi-bold-blue-zodiac-20px">
                Percentage -
              </div>
              <div className="x97-kWlObw">97%</div>
              <div className="number-of-issues-per-day-kWlObw montserrat-semi-bold-blue-zodiac-20px">
                Number of Students Per Day -
              </div>
              <div className="x7-kWlObw">7</div>
            </div>
            <div className="group-211-C61RwL">
              <div className="rectangle-31-agT0Rh"></div>
              <div className="payment-id-agT0Rh montserrat-semi-bold-gray-10px">
                Payment Id
              </div>
              <div className="amount-agT0Rh montserrat-semi-bold-gray-10px">
                Amount
              </div>
              <div className="transferred-to-agT0Rh montserrat-semi-bold-gray-10px">
                Transferred To
              </div>
              <div className="date-agT0Rh montserrat-semi-bold-gray-10px">
                Date
              </div>
              <div className="time-agT0Rh montserrat-semi-bold-gray-10px">
                Time
              </div>
              <div className="recent-payments-agT0Rh montserrat-medium-blue-zodiac-18px">
                Recent payments
              </div>
              <div className="rectangle-36-agT0Rh"></div>
              <div className="rectangle-37-agT0Rh"></div>
              <div className="rectangle-38-agT0Rh"></div>
              <div className="rectangle-39-agT0Rh"></div>
              <div className="rectangle-40-agT0Rh"></div>
              <div className="rectangle-41-agT0Rh"></div>
              <div className="rectangle-42-agT0Rh"></div>
            </div>
            <div className="group-210-C61RwL">
              <div className="open-issue-list-Uoyr1g montserrat-semi-bold-blue-zodiac-18px">
                Open Issue List
              </div>
              <div className="overlap-group-Uoyr1g">
                <div className="rectangle-31-32Z2fi"></div>
                <div className="rectangle-40-32Z2fi"></div>
                <div className="rectangle-41-32Z2fi"></div>
                <div className="rectangle-47-32Z2fi"></div>
                <div className="x12pm-32Z2fi roboto-medium-blue-zodiac-14px">
                  12pm
                </div>
                <div className="x1pm-32Z2fi roboto-medium-blue-zodiac-14px">
                  1pm
                </div>
                <div className="organic-4-32Z2fi roboto-medium-blue-zodiac-14px">
                  Organic-4
                </div>
                <div className="completed-32Z2fi roboto-medium-blue-zodiac-14px">
                  Completed
                </div>
                <div className="group-90-32Z2fi">
                  <div className="rectangle-49-Gfzi7S"></div>
                  <div className="x2pm-Gfzi7S roboto-medium-blue-zodiac-14px">
                    2pm
                  </div>
                  <div className="opened-Gfzi7S roboto-medium-niagara-14px">
                    Opened
                  </div>
                </div>
                <div className="group-95-32Z2fi">
                  <div className="rectangle-49-eI2rAV"></div>
                  <div className="x2pm-eI2rAV roboto-medium-blue-zodiac-14px">
                    2pm
                  </div>
                  <div className="opened-eI2rAV roboto-medium-niagara-14px">
                    Opened
                  </div>
                </div>
                <div className="group-96-32Z2fi">
                  <div className="rectangle-49-t6sl2a"></div>
                  <div className="x2pm-t6sl2a roboto-medium-blue-zodiac-14px">
                    2pm
                  </div>
                  <div className="opened-t6sl2a roboto-medium-niagara-14px">
                    Opened
                  </div>
                </div>
                <div className="group-92-32Z2fi">
                  <div className="rectangle-44-GcMqx5"></div>
                  <div className="x4pm-GcMqx5 roboto-medium-blue-zodiac-14px">
                    4pm
                  </div>
                  <div className="opened-GcMqx5 roboto-medium-niagara-14px">
                    Opened
                  </div>
                </div>
                <div className="group-94-32Z2fi">
                  <div className="rectangle-44-xy5Uks"></div>
                  <div className="x6pm-xy5Uks roboto-medium-blue-zodiac-14px">
                    6pm
                  </div>
                  <div className="opened-xy5Uks roboto-medium-niagara-14px">
                    Opened
                  </div>
                </div>
                <div className="group-91-32Z2fi">
                  <div className="rectangle-48-4Mx5vA"></div>
                  <div className="x3pm-4Mx5vA roboto-medium-blue-zodiac-14px">
                    3pm
                  </div>
                  <div className="organic-5-4Mx5vA roboto-medium-blue-zodiac-14px">
                    Organic-5
                  </div>
                  <div className="upcoming-4Mx5vA roboto-medium-blue-zodiac-14px">
                    Upcoming
                  </div>
                </div>
                <div className="booked-32Z2fi roboto-medium-crusta-14px">
                  Booked
                </div>
                <div className="booked-Cb1J56 roboto-medium-crusta-14px">
                  Booked
                </div>
                <div className="status-32Z2fi roboto-bold-gray-16px">
                  Status
                </div>
                <div className="s-no-32Z2fi roboto-bold-gray-16px">S.No.</div>
                <div className="student-32Z2fi roboto-bold-gray-16px">
                  Student
                </div>
                <div className="category-32Z2fi roboto-bold-gray-16px">
                  Category
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Popup /> */}
      </>
    );
}


function Component1(props) {
    const { line5, line6 } = props;

    return (
        <div className="component-1 border-1px-blue-zodiac">
            <div className="overlap-group5-1">
                <img className="line-5" src={line5} />
                <img className="line-6" src={line6} />
            </div>
        </div>
    );
}


function Dashboard24px1(props) {
    const { src } = props;

    return (
        <div className="insertinvitation-24px-1">
            <img className="vector-16" src={src} />
        </div>
    );
}
const component1Data = {
    line5: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60599271a8f3ed5507ddebbe/img/line-5-4@2x.png",
    line6: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/line-6@2x.png",
};

const dashboard24px1Data = {
    src: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
};

const SalesData = {
    vector: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-35@2x.png",
    vector2: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1c6a4e78cf803e37e7fd/img/vector-29@2x.png",
    vector3: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1d433ce41a6c7995fc03/img/vector-9@2x.png",
    vector4: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
    vector5: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058d518334660f0a4896cec/img/vector-193@2x.png",
    vector6: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
    vector7: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-228@2x.png",
    vector8: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
    vector9: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-237@2x.png",
    vector10: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
    vector11: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1d433ce41a6c7995fc03/img/vector-64@2x.png",
    vector12: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1d433ce41a6c7995fc03/img/vector-60@2x.png",
    vector13: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1d433ce41a6c7995fc03/img/vector-61@2x.png",
    vector14: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1d433ce41a6c7995fc03/img/vector-60@2x.png",
    vector15: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1d433ce41a6c7995fc03/img/vector-62@2x.png",
    vector16: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-33@2x.png",
    totalIssues: "Total Issues -",
    number: "43",
    solvedIssues: "Solved Issues -",
    number2: "40",
    percentage: "Percentage -",
    percent: "97%",
    text1: "Number of Issues Per Day -",
    number3: "7",
    vector17: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
    vector18: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-38@2x.png",
    ellipse2: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/ellipse-2@2x.png",
    title: "Biba Sobti",
    bibasobtiGmailCom: "bibasobti@gmail.com",
    vector19: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-36@2x.png",
    status: "Status",
    completed: "Completed",
    paymentId: "Payment Id",
    amount: "Amount",
    place: "Date",
    time: "Time",
    transferredTo: "Transferred To",
    recentPayments: "Recent payments",
    pay_Ga4Wkzbshl: "pay_GA4wkZBshl",
    spanText: "4999.",
    spanText2: "00",
    date: "07 Dec 2020",
    x014009Pm: "01:40:09 pm",
    andb586: "ANDB*******586",
    openIssueList: "Open Issue List",
    number4: "1",
    number5: "2",
    vatsalAgarwal: "Vatsal Agarwal",
    inProgess: "In Progess",
    shreyansJain: "Shreyans Jain",
    pending: "Pending",
    kunalKashyap: "Kunal Kashyap",
    ticket: "Ticket",
    price: "SOS",
    price2: "SOS",
    status2: "Status",
    sNo: "S.No.",
    studentName: "Student Name",
    category: "Category",
    number6: "3",
    number7: "4",
    number8: "5",
    number9: "6",
    number10: "7",
    number11: "8",
    number12: "9",
    place2: "Standard",
    vector20: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1c6a4e78cf803e37e7fd/img/vector-26@2x.png",
    messages: "Messages",
    vector21: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1c6a4e78cf803e37e7fd/img/vector-25@2x.png",
    component1Props: component1Data,
    dashboard24px1Props: dashboard24px1Data,
};

export default Salespayments;