import React, { useState, useLayoutEffect } from 'react';
import { Link, useHistory, Redirect } from "react-router-dom";
import Modal from 'react-modal';
import { Button } from 'react-bootstrap';
import { Form, Message } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { getStudent } from "../../../redux/actions/student";
import { login } from "../../../crud/auth.crud";
import { setToken } from "../../../utils";
import { Divider } from 'antd';
import './style.css';
import LoginModal from "../../../components/Login_modal/loginModal";
import CustomNav from "../../../components/header/Navbar";
import Footer from '../../../components/Footer';
import BackDrop from "../../../UI/Backdrop/Backdrop";
import Popup2 from "../../non_auth/new_popup/popup2";

Modal.setAppElement('#root');


const Contact_Us = () => {
    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, [])

    // const [reasonValue, setReasonValue] = useState(0)

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isErrorShown, setIsErrorShown] = useState(false);
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const history = useHistory();
    const dispatch = useDispatch();
    let attclasses = ["login-modal-2", "Close-Landing"];

    if (isModalOpen) {
        attclasses = ["login-modal-2", "Open-Landing"];
    }

    const closeModal = () => setIsModalOpen(previousState => !previousState);


    // const handleLogin = () => {
    //     setLoading(true);
    //     setIsErrorShown(false);

    //     if (!email || !password) {
    //         setLoading(false);
    //         setIsErrorShown(true);
    //     } else {
    //         login(email, password)
    //             .then(({ data: { access, refresh, email, user_type } }) => {
    //                 setLoading(false);
    //                 dispatch(getStudent({ access, email, user_type }, history));
    //                 setToken(access, refresh);
    //                 // history.replace("/student-dashboard");
    //             })
    //             .catch(() => {
    //                 setLoading(false);
    //                 setIsErrorShown(true);
    //             })
    //     }
    // }

    return (
      <div>
        <div className="landing-page-layout-100">
          <CustomNav Open={closeModal} />

          <div className="heading_box">
            <p>CONTACT US</p>
          </div>

          <div className="boxes_container content-class-health">
            <p style={{ margin: "20px" }}>
              <ol className="text-weight">
                <li>Contact 1 : 9570-424242</li>
                <li>Contact 2 : 74-1919-7878</li>
                <li>
                  Email : <span> </span>
                  <a
                    href="mailto:contact@therightguru.com"
                    style={{ color: "#dc3545" }}
                  >
                    contact@therightguru.com
                  </a>
                </li>
              </ol>
              <br />
              <br />
              <ol className="text-weight" style={{ color: "red" }}>
                <li>Address : </li>
                <li>42-SF</li>
                <li>Omaxe Galleria, Sector-14</li>
                <li>Bahadurgarh-124507</li>
                <li>Delhi-NCR</li>
              </ol>

              <br />
              <br />
            </p>
          </div>

          <div className="content-class-future" style={{ color: "red" }}></div>

          <Footer />
        </div>

        {/* <div className="login_modal_layout_100">
          <BackDrop open={isModalOpen} clicked={closeModal} />
          <div
            // isOpen={isModalOpen}
            // onRequestClose={closeModal}
            className={attclasses.join(" ")}
          >
            <div className="login-modal-title-2-10x">Student LogIn</div>
            <Form className="form-login-modal-2" error>
              {isErrorShown ? (
                <Message
                  error
                  header="Wrong Credentials"
                  content="You entered a wrong email or password."
                  style={{
                    fontSize: "10px",
                    width: "80%",
                    textAlign: "center",
                  }}
                />
              ) : (
                <p style={{ margin: "0px", padding: "0px" }} />
              )}

              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                name="email"
                placeholder="Email Address"
                className="form-input-login-modal-2"
                onChange={(e) => setEmail(e.target.value)}
              />

              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                name="password"
                className="form-input-login-modal-2"
                onChange={(e) => setPassword(e.target.value)}
              />

              <Button
                className="button-login-modal"
                variant="danger"
                onClick={() => handleLogin()}
                disabled={loading}
              >
                Log in
              </Button>

              <p className="message-2-land-log">Forgot Password?</p>

              <Divider style={{ margin: "5px 5px" }} />

              <p className="message-2-land-log">
                Not Registered?{" "}
                <span style={{ color: "#e3241d" }}>
                  {" "}
                  Book A Free Trial Class
                </span>
              </p>
            </Form>
          </div>
        </div> */}
        <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
        <Popup2 />
      </div>
    );
}

export default Contact_Us;
