import React, { useState, useEffect, useLayoutEffect } from "react";
import "./teacher_classroom_style.css";
import { Link, NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { MdSchool } from "react-icons/md";
import { SiGoogleclassroom } from "react-icons/si";
import vector_2x from "./img/vector@2x.svg";
import ellipse_4_7_2x from "./img/ellipse-4-7@2x.svg";
import vector_2xp from "./img/vector@2x.png";
import vector_40_2x from "./img/vector-40@2x.svg";
import ellipse_4_8_2x from "./img/ellipse-4-8@2x.svg";
import vector_42_2x from "./img/vector-42@2x.svg";
import ellipse_4_9_2x from "./img/ellipse-4-9@2x.svg";
import vector_44_2x from "./img/vector-44@2x.svg";
import ellipse_4_10_2x from "./img/ellipse-4-10@2x.svg";
import vector_46_2x from "./img/vector-46@2x.svg";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteTeacher } from "../../../redux/actions/teacher";

import { teacherClassrooms, teacherStudents } from "../../../crud/teacher.crud";
import Popup from "../popup/popup";
import SideDrawer from "../../../UI/sideDrawer/sideDrawer";
import CalenderStatus from "../dashboard/CalenderComponent/calanderComponent";
import pic_2 from "../../student/dashboard/img/pic_2.png";

import DashboardIcon from "@material-ui/icons/Dashboard";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SchoolIcon from "@material-ui/icons/School";
import DescriptionIcon from "@material-ui/icons/Description";
import HelpIcon from "@material-ui/icons/Help";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import NotesIcon from "@material-ui/icons/Notes";

function Teacherassignmentsstudents(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [students, setStudents] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const user = useSelector((state) => state.teacherDetails.teacher);

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };
  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteTeacher(reduxUser.teacher_id));
    removeToken();
  };

  useEffect(() => {
    teacherStudents(user.teacher_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setStudents(false);
        } else setStudents(res);
      })
      .catch((err) => console.log(err));

    teacherClassrooms(user.teacher_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setClassrooms(false);
        } else setClassrooms(res);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="teacher-assignments-students"
      />
      <div className="container-center-horizontal">
        <div className="teacher-assignments-students screen">
          <div className="sider-teacher-general-div">
            <div className="sider-teacher-in-general-80">
              <div style={{ marginTop: "120px" }} />
              <NavLink to="/teacher-dashboard" exact>
                <DashboardIcon
                  data-tip="Dashboard"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-date-calendar" exact>
                <EventIcon data-tip="Scheduler" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-previous-classes">
                <ScheduleIcon
                  data-tip="Previous Class"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-students">
                <SchoolIcon
                  data-tip="Enrolled Student"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-curriculum-list">
                <DescriptionIcon
                  data-tip="Curriculum"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-doubt-list">
                <HelpIcon data-tip="Doubts" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-analytics">
                <AssessmentIcon
                  data-tip="Analytics"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-assignments">
                <AssignmentIcon
                  data-tip="Assignments"
                  className="icon-in-hc-color-2"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-quizzes">
                <LibraryBooksIcon
                  data-tip="Quizzes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-lecture-notes">
                <NotesIcon
                  data-tip="Lecture Notes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              {/* <NavLink to="/teacher-payments"><AccountBalanceIcon data-tip="Payment" className="icon-in-hc-color" /><ReactTooltip /></NavLink> */}
              <div style={{ marginTop: "10px" }} />
              <NavLink to="/" onClick={() => handleLogout()}>
                {" "}
                <PowerSettingsNewIcon
                  data-tip="Logout"
                  className="icon-in-hc-color"
                />{" "}
              </NavLink>
              <div style={{ marginTop: "1px" }} />
            </div>
          </div>
          <div className="flex-col-C61RwL">
            <img
              alt="image"
              src={pic_2}
              style={{ height: "115px", marginTop: "-5%", marginLeft: "-78%" }}
            />

            <div className="my-assignments-0xy0vn">
              <span className="span0-CgbxYT">My</span>
              <span className="span1-CgbxYT"> Assignments</span>
            </div>
            <div className="choose-a-studen-ssign-a-new-one-0xy0vn">
              Choose a student to view their assignments or assign a new one
            </div>
            <div className="group-174-0xy0vn">
              <div className="students-list">
                {students.length > 0 ? (
                  students.map((student) => {
                    return (
                      <div className="overlap-group1-Y9c9n6">
                        <Link
                          to={
                            "/teacher-assignments-student-list/" +
                            student.enrolled_student_id
                          }
                        >
                          <div className="rectangle-40-K97w2w">
                            <div className="group-167-K97w2w">
                              <div className="component-29-6bMYwa"></div>
                              <div className="vector-6bMYwa">
                                <MdSchool size={24} color="white" />
                              </div>
                            </div>
                            <div className="studTile-col">
                              <div className="vatsal-agarwal-K97w2w montserrat-semi-bold-blue-zodiac-14px">
                                {student.first_name + " " + student.last_name}
                              </div>
                              {/* <div className="mathematics-class-9-K97w2w montserrat-medium-gray-10px">
                                Mathematics - Class 9
                              </div> */}
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="montserrat-semi-bold-blue-zodiac-14px"
                    style={{ color: "black" }}
                  >
                    You have no enrolled students.
                  </div>
                )}
              </div>
            </div>
            <div className="choose-a-classr-ssign-a-new-one-0xy0vn">
              Choose a classroom to view their assignments or assign a new one
            </div>
            <div className="overlap-group-0xy0vn">
              <div className="classrooms-list">
                {classrooms.length > 0 ? (
                  classrooms.map((classroom) => {
                    return (
                      <Link
                        to={
                          "/teacher-assignments-classroom-list/" +
                          classroom.classroomID
                        }
                      >
                        <div className="group-116-huXul0">
                          <div className="rectangle-40-xlTRPb">
                            <div className="group-179-y1HRxC">
                              <div className="group-169-6z1aPT">
                                <div className="component-29-MWA2s1">
                                  <div className="vector-SzWn3A">
                                    <SiGoogleclassroom
                                      size={21}
                                      color="white"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="classTile-col">
                              <div
                                className="mathematics-class-9-xlTRPb montserrat-semi-bold-blue-zodiac-14px"
                                style={{ width: "100%" }}
                              >
                                {classroom.classroom_name +
                                  " - CLASS " +
                                  classroom.gradeID}
                              </div>
                              <div className="class-strength-20-xlTRPb montserrat-medium-gray-10px">
                                Class Strength - {classroom.classroom_strength}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })
                ) : (
                  <div
                    className="montserrat-semi-bold-blue-zodiac-14px"
                    style={{ color: "black" }}
                  >
                    You have no classrooms.
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex-col-VMr6Om">
            <div className="group-115-lcFreP">
              <div className="group-22-cLbj0f">
                <div className="flex-col-0sTO9E">
                  <div className="biba-sobti-JJC5aN">
                    {user.first_name + " " + user.last_name}
                  </div>
                  <div className="bibasobtigmailcom-JJC5aN">{user.emailID}</div>
                </div>
                <img className="vector-0sTO9E" src={vector_2x} />
              </div>
            </div>
            <div className="group-114-lcFreP">
              <CalenderStatus />
            </div>
          </div>
        </div>
      </div>
      <div className="container-center-horizontal">
        <div className="teacher-assignment-student-list-mobile screen">
          <div className="flex-col-C61RwL">
            <div className="group-72-0xy0vn">
              <div className="rectangle-40-MiJunx"></div>
              <div className="component-29-MiJunx">
                <img className="ellipse-4-UEWQ0j" src={ellipse_4_7_2x} />
                <div className="assignment-24px-3-UEWQ0j">
                  <img className="vector-RChgoP" src={vector_2xp} />
                  <img className="vector-CcJI6B" src={vector_40_2x} />
                </div>
              </div>
              <div className="assignment-1-MiJunx montserrat-semi-bold-blue-zodiac-10px">
                ASSIGNMENT 1
              </div>
              <div className="mathematics-dr-yashna-sharma-MiJunx montserrat-medium-gray-7px">
                Mathematics (Dr. Yashna Sharma)
              </div>
              <div className="submitted-MiJunx">Submitted</div>
            </div>
            <div className="group-73-0xy0vn">
              <div className="rectangle-40-7iGIN1"></div>
              <div className="component-29-7iGIN1">
                <img className="ellipse-4-1VRSUu" src={ellipse_4_8_2x} />
                <div className="assignment-24px-3-1VRSUu">
                  <img className="vector-Vn3aY5" src={vector_2xp} />
                  <img className="vector-lP2eQk" src={vector_42_2x} />
                </div>
              </div>
              <div className="assignment-1-7iGIN1 montserrat-semi-bold-blue-zodiac-10px">
                ASSIGNMENT 1
              </div>
              <div className="mathematics-dr-yashna-sharma-7iGIN1 montserrat-medium-gray-7px">
                Mathematics (Dr. Yashna Sharma)
              </div>
              <div className="submitted-7iGIN1">Submitted</div>
            </div>
            <div className="group-74-0xy0vn">
              <div className="rectangle-40-zHT0nt"></div>
              <div className="component-29-zHT0nt">
                <img className="ellipse-4-uiXjoi" src={ellipse_4_9_2x} />
                <div className="assignment-24px-3-uiXjoi">
                  <img className="vector-7YVAkz" src={vector_2xp} />
                  <img className="vector-zLTigN" src={vector_44_2x} />
                </div>
              </div>
              <div className="assignment-1-zHT0nt montserrat-semi-bold-blue-zodiac-10px">
                ASSIGNMENT 1
              </div>
              <div className="mathematics-dr-yashna-sharma-zHT0nt montserrat-medium-gray-7px">
                Mathematics (Dr. Yashna Sharma)
              </div>
              <div className="submitted-zHT0nt">Submitted</div>
            </div>
            <div className="group-75-0xy0vn">
              <div className="rectangle-40-FVieMB"></div>
              <div className="component-29-FVieMB">
                <img className="ellipse-4-poPk0w" src={ellipse_4_10_2x} />
                <div className="assignment-24px-3-poPk0w">
                  <img className="vector-f2PIuq" src={vector_2xp} />
                  <img className="vector-hEE9Rr" src={vector_46_2x} />
                </div>
              </div>
              <div className="assignment-1-FVieMB montserrat-semi-bold-blue-zodiac-10px">
                ASSIGNMENT 1
              </div>
              <div className="mathematics-dr-yashna-sharma-FVieMB montserrat-medium-gray-7px">
                Mathematics (Dr. Yashna Sharma)
              </div>
              <div className="submitted-FVieMB">Submitted</div>
            </div>
          </div>
          <div className="flex-col-VMr6Om">
            <div className="flex-row-lcFreP">
              <div className="flex-col-NvvWoh">
                <div className="component-1-PNnH4c">
                  <img
                    alt="image"
                    src={pic_2}
                    style={{ height: "90px", marginTop: "-8%" }}
                  />
                </div>
                <div className="my-assignments-PNnH4c">
                  <span className="span0-W95EnD">My </span>
                  <span className="span1-W95EnD">Assignments</span>
                </div>
              </div>

              {/* This is the Navbar Component */}
              <SideDrawer
                open={displaySideDrawer}
                closeSideDrawer={sideDrawerToggler}
              />
              <div className="component-6-NvvWoh" onClick={sideDrawerToggler}>
                <div className="rectangle-48-MRvDS0"></div>
                <div className="rectangle-49-MRvDS0"></div>
                <div className="rectangle-50-MRvDS0"></div>
              </div>
            </div>
            <div className="choose-a-studen-ssign-a-new-one-lcFreP">
              Choose a student to view their assignments or assign a new one
            </div>
            <div className="group-186-lcFreP">
              <div className="group-138-0Wopvi">
                {students.length > 0 ? (
                  students.map((student) => {
                    return (
                      <Link
                        to={
                          "/teacher-assignments-student-list/" +
                          student.enrolled_student_id
                        }
                      >
                        <div className="rectangle-40-pckt8m-10-1101">
                          <div className="component-29-4Baocj-10-11">
                            <MdSchool size={24} color="white" />
                          </div>

                          <div className="classTile-col">
                            <div className="mathematics-class-9-pckt8m-ii-i montserrat-semi-bold-blue-zodiac-14px">
                              {student.first_name}
                            </div>
                            <div className="class-strength-20-pckt8m montserrat-medium-gray-10px">
                              {student.last_name}
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })
                ) : (
                  <div
                    className="montserrat-semi-bold-blue-zodiac-14px"
                    style={{ color: "black" }}
                  >
                    You have no enrolled students.
                  </div>
                )}
              </div>
            </div>
            <div className="choose-a-classr-ssign-a-new-one-lcFreP">
              Choose a classroom to view their assignments or assign a new one
            </div>
            <div className="group-187-lcFreP">
              <div className="group-139-BhPClO">
                {classrooms.length > 0 ? (
                  classrooms.map((classroom) => {
                    return (
                      <Link
                        to={
                          "/teacher-assignments-classroom-list/" +
                          classroom.classroomID
                        }
                      >
                        <div className="rectangle-40-pckt8m-10-1101">
                          <div className="component-29-4Baocj-10-11">
                            <SiGoogleclassroom size={22} color="white" />
                          </div>

                          <div className="classTile-col">
                            <div
                              className="mathematics-class-9-pckt8m-ii-i montserrat-semi-bold-blue-zodiac-14px"
                              style={{ width: "100%" }}
                            >
                              {classroom.classroom_name +
                                " - CLASS " +
                                classroom.gradeID}
                            </div>
                            <div className="class-strength-20-pckt8m montserrat-medium-gray-10px">
                              Class Strength - {classroom.classroom_strength}
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })
                ) : (
                  <div
                    className="montserrat-semi-bold-blue-zodiac-14px"
                    style={{ color: "black" }}
                  >
                    You have no classrooms.
                  </div>
                )}
              </div>
            </div>
            <div className="flex-row-SXcK7r">
              <CalenderStatus />
            </div>
          </div>
        </div>
      </div>
      <Popup />
    </>
  );
}

export default Teacherassignmentsstudents;
