import { DELETE_HC, GET_HC } from '../actions/hc/types'

const initialState = {}

export default function getHcDetailsReducer(state = initialState, action) {
    switch(action.type) {
        case GET_HC:
            return {
                ...state,
                hc: action.payload
            }
        case DELETE_HC:
            return {}
        default:
            return state
    }
}