import React, { useState, useEffect, useLayoutEffect } from "react";
import "./group_classroom.css";
import SchoolIcon from "@material-ui/icons/School";
import { Link, NavLink, useParams, useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import pic_2 from "../../student/dashboard/img/pic_2.png";

import ReactTooltip from "react-tooltip";
import {
  FaRegQuestionCircle,
  FaCalendarAlt,
  FaFileAlt,
  FaChartPie,
  FaStickyNote,
  FaPowerOff,
} from "react-icons/fa";
import {
  MdAssignment,
  MdDashboard,
  MdNotificationsNone,
  MdSchool,
} from "react-icons/md";
import { BiSupport, BiHistory } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { AiTwotoneBank } from "react-icons/ai";
import { SiGoogleclassroom } from "react-icons/si";
import line_5_2x from "./img/line-5@2x.svg";
import line_6_2x from "./img/line-6@2x.svg";
import ellipse_2_2x from "./img/ellipse-2@2x.svg";
import vector_1_2x from "./img/vector-1@2x.svg";
import rectangle_40_2x from "./img/rectangle-40@2x.svg";
import vector_2xp from "./img/vector@2x.png";
import vector_6_2x from "./img/vector-6@2x.svg";
import line_5_1_2x from "./img/line-5-1@2x.svg";
import line_6_1_2x from "./img/line-6-1@2x.svg";
import Popup from "../popup/popup";
import { useSelector, useDispatch } from "react-redux";

import { removeToken } from "../../../utils";
import { deleteTeacher } from "../../../redux/actions/teacher";
import SideDrawer from "../../../UI/sideDrawer/sideDrawer";

import { classStudentQuizDetails } from "../../../crud/student.crud";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
// import SchoolIcon from "@material-ui/icons/School";
import DescriptionIcon from "@material-ui/icons/Description";
import HelpIcon from "@material-ui/icons/Help";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import NotesIcon from "@material-ui/icons/Notes";

import {
  teacherPreviousClasses,
  upcomingClass,
  teacherClassroomStudent,
} from "../../../crud/teacher.crud";
import CalenderStatus from "../dashboard/CalenderComponent/calanderComponent";

function Teacherpreviousclasses(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteTeacher(reduxUser.teacher_id));
    removeToken();
  };

  const { classroomID, group_id } = useParams();
  const [classStudent, setClassStudent] = useState([]);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [studentResponse, setStudentResponse] = useState({});
  const history = useHistory();

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  const handleSubmit = (item) => {
    console.log(item);
    classStudentQuizDetails(item, group_id)
      .then((res) => {
        if (!res.id) {
          alert("This quiz is not assigned for all students of the classroom.")
        } else {
          setStudentResponse(res);
          history.push("/teacher-quiz-student-detail/" + res.id);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    console.log(group_id);
    teacherClassroomStudent(classroomID)
      .then((res) => {
        setClassStudent(res);
      })
      .catch((err) => console.log(err));
  }, []);

  // const [classes, setClasses] = useState([]);
  // const [upClass, setUpClass] = useState({});

  // useEffect(() => {
  //   teacherPreviousClasses("TS_bibasob")
  //     .then((res) => {
  //       if (res.hasOwnProperty("message")) {
  //         setClasses(false);
  //       } else setClasses(res);
  //     })
  //     .catch((err) => console.log(err));

  //   upcomingClass("TS_bibasob")
  //     .then((res) => {
  //       if (res.hasOwnProperty("message")) {
  //         setUpClass(false);
  //       } else setUpClass(res);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  const user = useSelector((state) => state.teacherDetails.teacher);

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="teacherpreviousclasses"
      />
      <div className="container-center-horizontal">
        <div className="teacher-group-classroom-quiz screen">
          <div className="sider-teacher-general-div">
            <div className="sider-teacher-in-general-80">
              <div style={{ marginTop: "120px" }} />
              <NavLink to="/teacher-dashboard" exact>
                <DashboardIcon
                  data-tip="Dashboard"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-date-calendar" exact>
                <EventIcon data-tip="Scheduler" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-previous-classes">
                <ScheduleIcon
                  data-tip="Previous Class"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-students">
                <SchoolIcon
                  data-tip="Enrolled Student"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-curriculum-list">
                <DescriptionIcon
                  data-tip="Curriculum"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-doubt-list">
                <HelpIcon data-tip="Doubts" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-analytics">
                <AssessmentIcon
                  data-tip="Analytics"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-assignments">
                <AssignmentIcon
                  data-tip="Assignments"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-quizzes">
                <LibraryBooksIcon
                  data-tip="Quizzes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-lecture-notes">
                <NotesIcon
                  data-tip="Lecture Notes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              {/* <NavLink to="/teacher-payments"><AccountBalanceIcon data-tip="Payment" className="icon-in-hc-color" /><ReactTooltip /></NavLink> */}
              <div style={{ marginTop: "10px" }} />
              <NavLink to="/" onClick={() => handleLogout()}>
                {" "}
                <PowerSettingsNewIcon
                  data-tip="Logout"
                  className="icon-in-hc-color"
                />{" "}
              </NavLink>
              <div style={{ marginTop: "1px" }} />
            </div>
          </div>
          <div className="flex-col-C61RwL">
            <div className="flex-row-0xy0vn">
              <div className="component-1-GyJUNH">
                <img
                  alt="image"
                  src={pic_2}
                  style={{ height: "115px", marginTop: "-8%" }}
                />
              </div>

              <div className="group-115-GyJUNH">
                <div className="group-22-3boKt2">
                  <div className="flex-col-R6Ddt1">
                    <div className="biba-sobti-2jrdHj">
                      {user.first_name + " " + user.last_name}
                    </div>
                    <div className="bibasobtigmailcom-2jrdHj">
                      {user && user.emailID}
                    </div>
                  </div>
                  <img className="vector-R6Ddt1" src={vector_1_2x} />
                </div>
              </div>
            </div>
            <div className="flex-row-oV5UrE">
              <div className="flex-col-QkTteQ">
                <a href="javascript:history.back()">
                  <div className="arrowback-24px-1-Fjgz1C">
                    <img className="vector-xxy9YX" src={vector_2xp} />
                    <img className="vector-H1IJdQ" src={vector_6_2x} />
                  </div>
                </a>
                <div className="overlap-group-Fjgz1C">
                  <div className="rectangle-31-Sgr7kw-02-22">
                    <div className="div-rectangle-31-Sgr7kw">
                      Classroom Students
                    </div>

                    <div className="rectangle-31-Sgr7kw-in-content">
                      {classStudent && classStudent.length > 0 ? (
                        classStudent.map((item, index) => {
                          return (
                            <div className="rectangle-tingle-12">
                              <div style={{ display: "flex" }}>
                                <SchoolIcon className="cast-for-edu-121-129" />
                                <p className="rectangle-tingle-12-name">
                                  {item}
                                </p>
                              </div>
                              <div
                                className="rectangle-41-nxpXxz-pp1-010"
                                onClick={() => handleSubmit(item)}
                              >
                                Check Submission
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                          No students found in this classroom currently.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-114-QkTteQ">
                <div className="group-113-eLYKxH">
                  <CalenderStatus />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-center-horizontal">
        <div className="teacherpreviousclasses screen">
          <div className="flex-row-C61RwL">
            <div className="component-1-dXBp89">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "70px", marginTop: "-8%" }}
              />
            </div>

            {/* this is the Navbar */}

            <SideDrawer
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />
            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>

          <div className="rectangle-12-cE321">
            <a href="javascript:history.back()">
              <div className="arrowback-24px-1-Fjgz1C">
                <img className="vector-xxy9YX" src={vector_2xp} />
                <img className="vector-H1IJdQ" src={vector_6_2x} />
              </div>
            </a>
            <br />
            <div className="div-rectangle-31-Sgr7kw">Classroom Students</div>
            {/* <div className="rectangle-31-Sgr7kw-in-content">
              <div className="rectangle-tingle-12">
                <SchoolIcon className="cast-for-edu-121-129" />
                <p className="rectangle-tingle-12-name">Rajendra Swami</p>
              </div>
            </div> */}
            <div className="rectangle-31-Sgr7kw-in-content">
              {classStudent && classStudent.length > 0 ? (
                classStudent.map((item, index) => {
                  return (
                    <div className="rectangle-tingle-12">
                      <div style={{ display: "flex" }}>
                        <SchoolIcon className="cast-for-edu-121-129" />
                        <p className="rectangle-tingle-12-name-in-mmos">
                          {item}
                        </p>
                      </div>
                      <div
                        className="rectangle-41-nxpXxz-pp1-010-1"
                        onClick={() => handleSubmit(item)}
                      >
                        Check Submission
                      </div>
                    </div>
                  );
                })
              ) : (
                <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                  No students found in this classroom currently.
                </div>
              )}
            </div>
          </div>

          <div className="group-135-C61RwL-02-20">
            <CalenderStatus />
          </div>
        </div>
      </div>
      <Popup />
    </>
  );
}

export default Teacherpreviousclasses;
