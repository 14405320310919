import React, { useState, useEffect, useLayoutEffect } from "react";
import "./teacher_quiz_list_style.css";
import { Link, NavLink, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import pic_2 from "../../student/dashboard/img/pic_2.png";

import DashboardIcon from "@material-ui/icons/Dashboard";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SchoolIcon from "@material-ui/icons/School";
import DescriptionIcon from "@material-ui/icons/Description";
import HelpIcon from "@material-ui/icons/Help";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import NotesIcon from "@material-ui/icons/Notes";

import { MdSchool } from "react-icons/md";
import { RiQuestionnaireFill } from "react-icons/ri";
import vector_2x from "./img/vector@2x.png";
import vector_6_2x from "./img/vector-6@2x.svg";
import vector_2xs from "./img/vector@2x.svg";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteTeacher } from "../../../redux/actions/teacher";
import SideDrawer from "../../../UI/sideDrawer/sideDrawer";

import Popup from "../popup/popup";
import { S3tqconfig } from "../../../s3/s3tqconfig.js";
import S3FileUpload from "react-s3";
import { uploadQuiz } from "../../../crud/teacher.crud";

import { studentQuizzes } from "../../../crud/student.crud";
import CalenderStatus from "../dashboard/CalenderComponent/calanderComponent";
import DropdownOption from "../../higher_committee/dropdown/dropdown2";

function Teacherquizlist(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { studentId } = useParams();
  const user = useSelector((state) => state.teacherDetails.teacher);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [quizzes, setQuizzes] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [quiz_url, setQuizUrl] = useState("");

  const [subject_id, setSubjectName] = useState("");
  const [grade_id, setGradeName] = useState("");

  const handleGradeSelect = (value) => {
    setGradeName(value);
  };

  const handleSubjectSelect = (value) => {
    setSubjectName(value);
  };

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteTeacher(reduxUser.teacher_id));
    removeToken();
  };

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  useEffect(() => {
    studentQuizzes(studentId)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setQuizzes(false);
        } else setQuizzes(res);
      })
      .catch((err) => console.log(err));
  }, []);

  function upload(e) {
    console.log(e.target.files[0]);
    S3FileUpload.uploadFile(e.target.files[0], S3tqconfig)
      .then((data) => {
        setQuizUrl(data.location);
      })
      .catch((err) => {
        alert(err);
      });
  }

  const [quizName, setQuizName] = useState("");
  const [quizMarks, setQuizMarks] = useState("");
  const [quizSubject, setQuizSubject] = useState("");
  const [quizGrade, setQuizGrade] = useState("");

  const handleSubmit = () => {
    uploadQuiz({
      quiz_meta_id: user.teacher_id + `1_${quizGrade}_${quizSubject}`,
      teacher_id: user.teacher_id,
      course_id: "ONRCCBSE",
      grade_id,
      subject_id,
      quiz_id: `${user.teacher_id}_${quizGrade}_${quizSubject}_${quizName}`,
      quiz_url: quiz_url,
      total_marks: quizMarks,
      quiz_tag: quizName,
      quiz_name: quizName,
      student_id: studentId,
      marks: "NA",
      status: "Assigned",
      solution_url: "NA",
    })
      .then((res) => {
        alert("Quiz Updated Successfully!");
        setIsSubmitted(!isSubmitted);
      })
      .catch((err) => console.log(err));
  };

  const subjectOptions = [
    {
      key: "_1",
      text: "English",
      value: "English",
    },
    {
      key: "_2",
      text: "Hindi",
      value: "Hindi",
    },
    {
      key: "_3",
      text: "Science",
      value: "Science",
    },
    {
      key: "MTH",
      text: "Mathematics",
      value: "MTH",
    },
    {
      key: "_5",
      text: "Social Studies",
      value: "SST",
    },
  ];

  const gradeOptions = [
    {
      key: "_9",
      text: "9",
      value: "9",
    },
    {
      key: "_10",
      text: "10",
      value: "10",
    },
  ];

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="teacher-quiz-list"
      />
      <div className="container-center-horizontal">
        <form
          className="teacher-quiz-list screen"
          name="form2"
          action="form2"
          method="post"
        >
          <div className="sider-teacher-general-div">
            <div className="sider-teacher-in-general-80">
              <div style={{ marginTop: "120px" }} />
              <NavLink to="/teacher-dashboard" exact>
                <DashboardIcon
                  data-tip="Dashboard"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-date-calendar" exact>
                <EventIcon data-tip="Scheduler" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-previous-classes">
                <ScheduleIcon
                  data-tip="Previous Class"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-students">
                <SchoolIcon
                  data-tip="Enrolled Student"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-curriculum-list">
                <DescriptionIcon
                  data-tip="Curriculum"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-doubt-list">
                <HelpIcon data-tip="Doubts" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-analytics">
                <AssessmentIcon
                  data-tip="Analytics"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-assignments">
                <AssignmentIcon
                  data-tip="Assignments"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-quizzes">
                <LibraryBooksIcon
                  data-tip="Quizzes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-lecture-notes">
                <NotesIcon
                  data-tip="Lecture Notes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              {/* <NavLink to="/teacher-payments"><AccountBalanceIcon data-tip="Payment" className="icon-in-hc-color" /><ReactTooltip /></NavLink> */}
              <div style={{ marginTop: "10px" }} />
              <NavLink to="/" onClick={() => handleLogout()}>
                {" "}
                <PowerSettingsNewIcon
                  data-tip="Logout"
                  className="icon-in-hc-color"
                />{" "}
              </NavLink>
              <div style={{ marginTop: "1px" }} />
            </div>
          </div>
          <div className="flex-col-C61RwL">
            <div className="flex-col-0xy0vn">
              <div className="component-1-xpmbvu">
                <img
                  alt="image"
                  src={pic_2}
                  style={{ height: "115px", marginTop: "-8%" }}
                />
              </div>
              <div className="flex-row-xpmbvu">
                <a href="javascript:history.back()">
                  <div
                    className="arrowback-24px-1-fKmFVR"
                    style={{ marginBottom: "7px" }}
                  >
                    <img className="vector-LysGKa" src={vector_2x} />
                    <img className="vector-ePmONu" src={vector_6_2x} />
                  </div>
                </a>
                <div className="back-to-all-students-fKmFVR">
                  <span className="span0-5NNoRV">Back to all</span>
                  <span className="span1-5NNoRV"> Students</span>
                </div>
              </div>
              <div className="group-46-xpmbvu">
                <div className="rectangle-40-sWDKkr"></div>
                <div className="component-29-sWDKkr">
                  <div className="assignment-24px-3-5xSoMg">
                    <div className="vector-Q7CGrB">
                      <MdSchool size={20} color="white" />
                    </div>
                    {/* <img className="vector-T4S6jg" src="img/vector@2x.png" />
                  <img className="vector-Q7CGrB" src="img/vector-4@2x.svg" /> */}
                  </div>
                </div>
                <div className="vatsal-agarwal-sWDKkr montserrat-semi-bold-blue-zodiac-18px">
                  {studentId}
                </div>
              </div>
              <div className="flex-row-9Sxrs4">
                {quizzes.length > 0 &&
                quizzes.filter(
                  (quiz) => quiz.meta.teacher_id === user.teacher_id
                ).length > 0 ? (
                  quizzes
                    .filter((quiz) => quiz.meta.teacher_id === user.teacher_id)
                    .map((quiz) => {
                      return (
                        <div className="group-57-w9UJWR">
                          <div className="rectangle-40-8BB6VN">
                            <div className="vector-cmbNsS">
                              {" "}
                              <RiQuestionnaireFill
                                size={24}
                                color="#dc3545"
                              />{" "}
                            </div>
                            <div className="quiz-1-ungraded-8BB6VN montserrat-semi-bold-white-12px">
                              <span className="span0-K8Ge20">
                                {quiz.quiz_data.quiz_name}
                                <br />
                              </span>
                              <span className="span1-K8Ge20">
                                {quiz.student_response.status}
                              </span>
                            </div>
                            <Link
                              to={
                                "/teacher-quiz-student-detail/" +
                                quiz.student_response.id
                              }
                            >
                              <Button
                                variant="outline-danger"
                                className="rectangle-41-PHCGTk"
                                style={{
                                  fontFamily: "'Montserrat', Helvetica",
                                  fontSize: 7,
                                  fontWeight: 600,
                                }}
                              >
                                Open
                              </Button>
                            </Link>
                          </div>
                        </div>
                      );
                    })
                ) : (
                  <div className="group-57-w9UJWR">
                    No quiz given to this student.
                  </div>
                )}
              </div>
            </div>

            <div className="flex-container-col-C61RwL-8-ex">
              <div className="give-a-new-quiz-3xHcwy">Give A New Quiz</div>

              {/* <div className="flex-container-col-C61RwL-8-ex-1-xe">
                <div className="choose-from-existing-quizzes-3xHcwy">Choose from existing quiz</div>
                <div>
                  <Dropdown
                    placeholder='Choose Quiz'
                    search
                    selection
                    //create new list and change the list name
                    options={subjectOptions}
                  />
                </div>
              </div>

              <div className="or-3xHcwy-8 montserrat-semi-bold-blue-zodiac-18px">OR</div> */}

              <div className="flex-container-col-C61RwL-8-ex-1-x">
                <div className="choose-from-existing-quizzes-3xHcwy-1">
                  Upload a new quiz
                </div>
                <div className="overlap-group2-nFOi0F">
                  <label for="input-file-tupe">+ Upload Quiz</label>
                  <input
                    id="input-file-tupe"
                    style={{ display: "none" }}
                    type="file"
                    onChange={(e) => upload(e)}
                  />
                </div>
              </div>

              <div style={{ width: "100%", margin: "10px 30px" }}>
                <DropdownOption
                  handleGradeSelect={handleGradeSelect}
                  handleSubjectSelect={handleSubjectSelect}
                />
              </div>

              <div className="flex-container-col-C61RwL-8-ex-1-x">
                <div className="choose-from-existing-quizzes-3xHcwy-1">
                  Quiz Name
                </div>
                <input
                  type="text"
                  placeholder="Assignment Name"
                  onChange={(e) => setQuizName(e.target.value)}
                  className="Assignment-Name-input-1"
                />
              </div>

              <div className="flex-container-col-C61RwL-8-ex-1-x">
                <div className="choose-from-existing-quizzes-3xHcwy-1">
                  Total Marks
                </div>
                <input
                  type="text"
                  placeholder="Total Marks"
                  onChange={(e) => setQuizMarks(e.target.value)}
                  className="Assignment-Name-input-1"
                />
              </div>

              <div className="overlap-group3-0xy0vn">
                <Button
                  onClick={() => handleSubmit()}
                  variant="danger"
                  className="rectangle-43-GW1jy1"
                  style={{
                    fontFamily: "'Montserrat', Helvetica",
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
          <div className="flex-col-VMr6Om">
            <div className="group-115-lcFreP">
              <div className="group-22-cLbj0f">
                <div className="flex-col-0sTO9E">
                  <div className="biba-sobti-JJC5aN">
                    {user.first_name + " " + user.last_name}
                  </div>
                  <div className="bibasobtigmailcom-JJC5aN">{user.emailID}</div>
                </div>
                <img className="vector-0sTO9E" src={vector_2xs} />
              </div>
            </div>
            <div className="group-114-lcFreP">
              <div className="group-113-8pxyib">
                <CalenderStatus />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="container-center-horizontal">
        <form
          className="teacher-quiz-list-mobile screen"
          name="form4"
          action="form4"
          method="post"
        >
          <div className="flex-row-C61RwL">
            <div className="component-1-dXBp89">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "90px", marginTop: "-8%" }}
              />
            </div>

            {/* This is the Navbar */}
            <SideDrawer
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />
            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>
          <div className="group-145-C61RwL">
            {/* <a href="javascript:history.back()">
              <div className="arrowback-24px-1-bxkR86">
                <img className="vector-9DUZ6p" src={vector_2x} />
                <img className="vector-tDx0rx" src={line_6_2x} />
              </div>
            </a> */}
            <a href="javascript:history.back()">
              <div className="arrowback-24px-1-Fjgz1C">
                <img className="vector-xxy9YX" src={vector_2x} />
                <img className="vector-H1IJdQ" src={vector_6_2x} />
              </div>
            </a>
            <div className="back-to-all-students-bxkR86">
              <span className="span0-f1YMcf">Back to All </span>
              <span className="span1-f1YMcf">Students</span>
            </div>
          </div>
          <div className="overlap-group2-C61RwL">
            <div className="group-46-4a9k2Y">
              <div className="rectangle-40-6x6k20"></div>
              <div className="vatsal-agarwal-6x6k20">{studentId}</div>
            </div>
            <div className="group-184-4a9k2Y">
              <div className="component-29-3nzxAx"></div>
              <div className="vector-3nzxAx">
                <MdSchool size={20} color="white" />
              </div>
              {/* <img className="vector-3nzxAx" src="img/vector-43@2x.svg" /> */}
            </div>
          </div>
          <div className="group-148-4eduM0">
            <div className="group-58-dBCxR8">
              {quizzes.length > 0 &&
              quizzes.filter((quiz) => quiz.meta.teacher_id === user.teacher_id)
                .length > 0 ? (
                quizzes
                  .filter((quiz) => quiz.meta.teacher_id === user.teacher_id)
                  .map((quiz) => {
                    return (
                      <div className="group-147-ockMBu">
                        <div className="rectangle-40-HBYxxn">
                          <div className="vector-teIxNm">
                            {" "}
                            <RiQuestionnaireFill
                              size={24}
                              color="#dc3545"
                            />{" "}
                          </div>
                          <div className="quiz-1-submitted-HBYxxn montserrat-semi-bold-white-12px">
                            <span className="span0-Mq46bO">
                              {quiz.quiz_data.quiz_name}
                              <br />
                            </span>
                            <span className="span1-Mq46bO">
                              {quiz.student_response.status}
                            </span>
                          </div>
                          <Link
                            to={
                              "/teacher-quiz-student-detail/" +
                              quiz.student_response.id
                            }
                          >
                            <Button
                              variant="outline-danger"
                              className="rectangle-41-HBYxxn"
                              style={{
                                fontFamily: "'Montserrat', Helvetica",
                                fontSize: 7,
                                fontWeight: 600,
                              }}
                            >
                              Open
                            </Button>
                          </Link>
                        </div>
                      </div>
                    );
                  })
              ) : (
                <div className="group-57-w9UJWR">
                  No quiz given to this student.
                </div>
              )}
            </div>
          </div>
          <div className="group-150-C61RwL">
            <div className="give-a-new-quiz-XvbDcl">
              <span className="span0-HScZy5">Give a new </span>
              <span className="span1-HScZy5">Quiz</span>
            </div>
          </div>
          {/* <div className="choose-from-existing-quizes-C61RwL">Choose from existing quizes</div>
          <Dropdown
            placeholder='Choose Quiz'
            search
            selection
            options={subjectOptions}
          />
          <div className="or-C61RwL-10">OR</div> */}
          <div className="upload-a-new-quiz-C61RwL">Upload a new quiz</div>
          <div className="group-151-C61RwL">
            {/* <div className="rectangle-42-nqcpBp"></div> */}
            <label for="you-input-file">+ Upload Quiz</label>
            <input
              style={{ display: "none" }}
              id="you-input-file"
              type="file"
              onChange={(e) => upload(e)}
            />
            {/* <div className="choose-file-nqcpBp">Choose File</div> */}
          </div>

          <div style={{ width: "92%", margin: "15px 0 5px 0" }}>
            <DropdownOption
              handleGradeSelect={handleGradeSelect}
              handleSubjectSelect={handleSubjectSelect}
            />
          </div>

          <div className="Assignment-name-in-desk-8-2">
            <div className="Assignment-name-head-div">Quiz Name</div>
            <input
              type="text"
              placeholder="Assignment Name"
              onChange={(e) => setQuizName(e.target.value)}
            />
          </div>
          <div className="Assignment-name-in-desk-8-2">
            <div className="Assignment-name-head-div">Total Marks</div>
            <input
              type="text"
              placeholder="Total Marks"
              onChange={(e) => setQuizMarks(e.target.value)}
            />
          </div>

          <div className="overlap-group3-C61RwL">
            {/* <div className="rectangle-43-eSNWXa"></div> */}
            <Button
              onClick={() => handleSubmit()}
              variant="danger"
              className="rectangle-43-eSNWXa"
              style={{
                fontFamily: "'Montserrat', Helvetica",
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              Submit
            </Button>
            {/* <div className="submit-eSNWXa">Submit</div> */}
          </div>
          <div className="group-154-C61RwL">
            <CalenderStatus />
          </div>
        </form>
      </div>
      <Popup />
    </>
  );
}

function Component1(props) {
  const { line5, line6 } = props;

  return (
    <div className="component-1 border-1px-blue-zodiac">
      <div className="overlap-group17">
        <img className="line-5" src={line5} />
        <img className="line-6" src={line6} />
      </div>
    </div>
  );
}

function Component29(props) {
  const { component29, vector, vector2 } = props;

  return (
    <div
      className="component-29"
      style={{ backgroundImage: `url(${component29})` }}
    >
      <div className="assignment-24px-3">
        <div className="overlap-group4-3">
          <img className="vector-25" src={vector} />
          <img className="vector-26" src={vector2} />
        </div>
      </div>
    </div>
  );
}

function Group177(props) {
  const { overlapGroup6, vector, vector2 } = props;

  return (
    <div className="group-177">
      <div
        className="overlap-group6-1"
        style={{ backgroundImage: `url(${overlapGroup6})` }}
      >
        <div className="group-1">
          <div className="overlap-group4-2">
            <img className="vector-6" src={vector} />
            <img className="vector-7" src={vector2} />
          </div>
        </div>
      </div>
    </div>
  );
}

function Component30(props) {
  const { chooseSubjectI532, vector, vector2 } = props;

  return (
    <div className="component-30 border-1px-black">
      <div className="choose-subject-i558 montserrat-medium-gray-10px">
        {chooseSubjectI532}
      </div>
      <div className="expandmore-24px-1">
        <div className="overlap-group4-4">
          <img className="vector-27" src={vector} />
          <img className="vector-28" src={vector2} />
        </div>
      </div>
    </div>
  );
}

function Group92(props) {
  const { x4Pm, opened, className } = props;

  return (
    <div className={`group-92 ${className || ""}`}>
      <div className="overlap-group-2">
        <div className="x4pm roboto-medium-blue-zodiac-14px">{x4Pm}</div>
        <div className="opened-1 roboto-medium-niagara-14px">{opened}</div>
      </div>
    </div>
  );
}
const component1Data = {
  line5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/line-5-1@2x.png",
  line6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/line-6@2x.png",
};

const component29Data = {
  component29:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058910c5433a9ddabee45c1/img/ellipse-4@2x.png",
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058910c5433a9ddabee45c1/img/vector-7@2x.png",
};

const group177Data = {
  overlapGroup6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605990618a1478626e861880/img/ellipse-4-17@2x.png",
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605990618a1478626e861880/img/vector-92@2x.png",
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605990618a1478626e861880/img/vector-93@2x.png",
};

const group1772Data = {
  overlapGroup6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605990618a1478626e861880/img/ellipse-4-18@2x.png",
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605990618a1478626e861880/img/vector-94@2x.png",
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605990618a1478626e861880/img/vector-95@2x.png",
};

const component30Data = {
  chooseSubjectI532: "Select quiz",
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60587e030ef1c80c1aa270c3/img/vector-41@2x.png",
};

const group92Data = {
  x4Pm: "4pm",
  opened: "Opened",
};

const group922Data = {
  x4Pm: "6pm",
  opened: "Opened",
};

const group923Data = {
  x4Pm: "7pm",
  opened: "Opened",
};

const group924Data = {
  x4Pm: "8pm",
  opened: "Opened",
};

const group925Data = {
  x4Pm: "9pm",
  opened: "Opened",
};

const TeacherquizlistData = {
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-35@2x.png",
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-453@2x.png",
  vector3:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector4:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-153@2x.png",
  vector5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058d518334660f0a4896cec/img/vector-193@2x.png",
  vector7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector8:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-226@2x.png",
  vector9:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector10:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-228@2x.png",
  vector11:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector12:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-237@2x.png",
  vector13:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector14:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-230@2x.png",
  vector15:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector16:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-241@2x.png",
  vector17:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-242@2x.png",
  vector18:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector19:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-166@2x.png",
  vector20:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-162@2x.png",
  vector21:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-163@2x.png",
  vector22:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-162@2x.png",
  vector23:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-164@2x.png",
  vector24:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-33@2x.png",
  vector25:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058d05e3f36904103fcf918/img/vector-208@2x.png",
  vector26:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058d05e3f36904103fcf918/img/vector-209@2x.png",
  vector27:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector28:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-224@2x.png",
  spanText: "Back to all",
  spanText2: " Students",
  vatsalAgarwal: "VATSAL AGARWAL",
  spanText3: (
    <>
      QUIZ 1<br />
    </>
  ),
  spanText4: "Ungraded",
  open: "Open",
  spanText5: (
    <>
      QUIZ 2<br />
    </>
  ),
  spanText6: "Not Submitted",
  open2: "Open",
  spanText7: "Give a",
  spanText8: " new quiz",
  text3: "Choose from existing quizzes",
  or: "OR",
  uploadANewQuiz: "Upload a new quiz",
  rectangle7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60588e6f3f36904103fcf791/img/rectangle-7-3@1x.png",
  chooseFile: "Choose File",
  submit: "Submit",
  vector29:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector30:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-38@2x.png",
  ellipse2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/ellipse-2@2x.png",
  bibaSobti: "Biba Sobti",
  bibasobtiGmailCom: "bibasobti@gmail.com",
  vector31:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-36@2x.png",
  openCalendar: "Open Calendar",
  date: "Feb 18, 2021",
  time: "Time",
  className: "Class Name",
  status: "Status",
  addDelete: "Add/Delete",
  price: "12pm",
  organic4: "Organic-4",
  completed: "Completed",
  booked: "Booked",
  x1Pm: "1pm",
  x2Pm: "2pm",
  opened: "Opened",
  add: "Add",
  x5Pm: "5pm",
  add2: "Add",
  x3Pm: "3pm",
  organic5: "Organic-5",
  upcoming: "Upcoming",
  booked2: "Booked",
  price2: "10pm",
  opened2: "Opened",
  component1Props: component1Data,
  component29Props: component29Data,
  group177Props: group177Data,
  group1772Props: group1772Data,
  component30Props: component30Data,
  group92Props: group92Data,
  group922Props: group922Data,
  group923Props: group923Data,
  group924Props: group924Data,
  group925Props: group925Data,
};

export default Teacherquizlist;
