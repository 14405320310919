import React, { useState, useEffect, useLayoutEffect } from "react";
import "./list_style.css";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import {
  FaRegQuestionCircle,
  FaCalendarAlt,
  FaFileAlt,
  FaChartPie,
  FaStickyNote,
  FaPowerOff,
} from "react-icons/fa";
import { MdAssignment, MdDashboard, MdNotificationsNone } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import Popup from "../../teacher/popup/popup";
import {
  dashboardDoubts,
  getStudentTeachers,
} from "../../../crud/student.crud";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteStudent } from "../../../redux/actions/student";
import pic_2 from "../dashboard/img/pic_2.png";

// images
import line5_2x from "./img/line-5@2x.png";
import line5_1_2x from "./img/line-5-1@2x.png";
import line6_2x from "./img/line-6@2x.png";
import line6_1_2x from "./img/line-6-1@2x.png";
import vector_3_2x from "./img/vector-3@2x.png";
import rectangle_7 from "./img/rectangle-7@1x.png";
import ellipse_2_2x from "./img/ellipse-2@2x.png";

import SideDrawerStudent from "../../../UI/sideDrawer/sideDrawerStudent";

function Studentlecturenotelist(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const user = useSelector((state) => state.studentDetails.student);
  const [doubts, setDoubts] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(deleteStudent(user.enrolled_student_id));
    removeToken();
  };

  useEffect(() => {
    dashboardDoubts(user.enrolled_student_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setDoubts(false);
        } else setDoubts(res);
      })
      .catch((err) => console.log(err));

    getStudentTeachers(user.enrolled_student_id)
      .then((res) => {
        setTeachers(res);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <div className="student-lecture-note-list screen">
        <div className="lecture-note-list-col">
          <div className="student-re-flex">
            <div className="component-1-C61RwLz-3">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "115px", marginTop: "-8%" }}
              />
            </div>
            <div className="group-22-GyJUNH-student-dash">
              <div className="flex-col-vgli2x-student-dash">
                <div className="biba-sobti-h8V8ZR-student-dash">
                  {user.first_name + " " + user.last_name}
                </div>
                <div className="bibasobtigmailcom-h8V8ZR-student-dash">
                  {user && user.email}
                </div>
              </div>
              <img className="vector-vgli2x-student-dash" src={vector_3_2x} />
            </div>
          </div>

          {/* <div className="notifications-24px-2-1-C61RwL">
          <div className="vector-CLdsug"><MdNotificationsNone size={28} /></div>
          <img className="ellipse-2-OSIBbx" src={ellipse_2_2x} />
        </div> */}
          <div className="my-lecture-notes-C61RwL">
            <span className="span0-sKAmxU">My</span>
            <span className="span1-sKAmxU"> Lecture Notes</span>
          </div>
          <div className="x14-C61RwL">14</div>
          <div className="rectangle-43-C61RwL">
            {doubts.length > 0 ? (
              doubts.slice(0, 4).map((doubt) => {
                return (
                  <div className="doubt-row">
                    <FaRegQuestionCircle size={30} />
                    <Link>
                      <div
                        className="montserrat-semi-bold-blue-zodiac-18px"
                        style={{ color: "rgba(126,126,126,1.0)" }}
                      >
                        {doubt.doubt_text.length > 31
                          ? doubt.doubt_text.substring(0, 27) + "..."
                          : doubt.doubt_text}
                      </div>
                    </Link>
                    {/* <div className="view-forum-C61RwL montserrat-semi-bold-gray-16px">View</div> */}
                  </div>
                );
              })
            ) : (
              <div />
            )}
            <div className="doubt-forum-C61RwL montserrat-semi-bold-blue-zodiac-18px">
              Doubt Forum
            </div>
            <Link to="/student-doubt-list">
              <div className="view-all-C61RwL">View all</div>
            </Link>
          </div>

          <div className="group-57-C61RwL">
            {teachers.length > 0 ? (
              teachers.map((teacher) => {
                return (
                  <div className="rectangle-40-AwXtS8">
                    <FaStickyNote size={30} color="#dc3545" />
                    <div className="mathematics-grade-9-AwXtS8 montserrat-semi-bold-white-18px">
                      <span className="span0-A5sNXg">
                        {teacher.first_name + " " + teacher.last_name}
                        <br />
                      </span>
                      <span className="span1-A5sNXg">Notes</span>
                    </div>
                    <Link to={"/student-notes-detail/" + teacher.teacher_id}>
                      <Button
                        variant="outline-danger"
                        className="rectangle-41-AwXtS8"
                        style={{
                          fontFamily: "'Montserrat', Helvetica",
                          fontSize: 12,
                          fontWeight: 600,
                          width: "120px",
                        }}
                      >
                        View
                      </Button>
                    </Link>
                  </div>
                );
              })
            ) : (
              <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                No teachers to show.
              </div>
            )}
          </div>
        </div>
        <div className="rectangle-24-C61RwL-stu-s1">
          <Link to="/student-dashboard">
            <div data-tip="Dashboard">
              <MdDashboard size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-date-calendar">
            <div data-tip="Date Calendar">
              <FaCalendarAlt size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-assignment-list">
            <div data-tip="Assignments">
              <MdAssignment size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-quiz-list">
            <div data-tip="Quizzes">
              <RiQuestionnaireFill size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-curriculum-list">
            <div data-tip="Curriculums">
              <FaFileAlt size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-doubt-list">
            <div data-tip="Doubts">
              <FaRegQuestionCircle size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-performance" data-tip="Performance">
            <div>
              <FaChartPie size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-notes-list">
            <div data-tip="Lecture Notes">
              <FaStickyNote size={24} color="#dc3545" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/" onClick={() => handleLogout()}>
            <FaPowerOff
              size={24}
              data-tip="Logout"
              color="rgba(179,179,179,1.0)"
            />
            <ReactTooltip />
          </Link>
        </div>
      </div>
      <div className="student-lecture-notes-list-mobile screen">
        <div className="component-1-C61RwL">
          <img
            alt="image"
            src={pic_2}
            style={{ height: "90px", marginTop: "-8%" }}
          />
        </div>

        {/* This is the hamburger icon */}

        <SideDrawerStudent
          open={displaySideDrawer}
          closeSideDrawer={sideDrawerToggler}
        />
        <div className="component-6-C61RwL" onClick={sideDrawerToggler}>
          <div className="rectangle-48-5mfU7d"></div>
          <div className="rectangle-49-5mfU7d"></div>
          <div className="rectangle-50-5mfU7d"></div>
        </div>

        <div className="my-lecture-notes-C61RwL">
          <span className="span0-sKAmxU">My </span>
          <span className="span1-sKAmxU">Lecture Notes</span>
        </div>
        <div className="group-57-C61RwL">
          {teachers.length > 0 ? (
            teachers.map((teacher) => {
              return (
                <div className="rectangle-40-AwXtS8">
                  <FaStickyNote size={30} color="#dc3545" />
                  <div className="mathematics-grade-9-AwXtS8 montserrat-semi-bold-white-18px">
                    <span className="span0-A5sNXg">
                      {teacher.first_name + " " + teacher.last_name}
                      <br />
                    </span>
                    <span className="span1-A5sNXg">Grade 9</span>
                  </div>
                  <Link to={"/student-notes-detail/" + teacher.teacher_id}>
                    <Button
                      variant="outline-danger"
                      className="rectangle-41-AwXtS8"
                      style={{
                        fontFamily: "'Montserrat', Helvetica",
                        fontSize: 12,
                        fontWeight: 600,
                        width: "120px",
                      }}
                    >
                      View
                    </Button>
                  </Link>
                </div>
              );
            })
          ) : (
            <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
              No teachers to show.
            </div>
          )}
        </div>
        <div className="group-85-C61RwL">
          <div className="rectangle-44-rFX9Nh">
            {doubts.length > 0 ? (
              doubts.slice(0, 4).map((doubt) => {
                return (
                  <div className="doubt-row">
                    <FaRegQuestionCircle size={30} />
                    <Link>
                      <div className="montserrat-semi-bold-blue-zodiac-18px">
                        {doubt.doubt_text.length > 31
                          ? doubt.doubt_text.substring(0, 27) + "..."
                          : doubt.doubt_text}
                      </div>
                    </Link>
                    {/* <div className="view-forum-C61RwL montserrat-semi-bold-gray-16px">View</div> */}
                  </div>
                );
              })
            ) : (
              <div />
            )}
          </div>
          <div className="doubt-forum-rFX9Nh">Doubt Forum</div>
          <Link to="/student-doubt-list">
            <div className="view-all-rFX9Nh">View all</div>
          </Link>
        </div>
      </div>
      <Popup />
    </>
  );
}

export default Studentlecturenotelist;
