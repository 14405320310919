import React, { useState, useEffect, useLayoutEffect } from "react";
import "./live.css";
import { Link, useHistory, useParams } from "react-router-dom";
import pic_2 from "../dashboard/img/pic_2.png";

import {
  FaRegQuestionCircle,
  FaCalendarAlt,
  FaFileAlt,
  FaChartPie,
  FaStickyNote,
  FaPowerOff,
} from "react-icons/fa";
import { MdAssignment, MdDashboard, MdNotificationsNone } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import ReactTooltip from "react-tooltip";
import { PieChart } from "react-minimal-pie-chart";
import vector_1_2x from "./img/vector-1@2x.png";
import line6_2x from "./img/line-6@2x.png";
import line5_2x from "./img/line-5@2x.png";
import vector_3_2x from "./img/vector-3@2x.png";
import ReactStars from "react-rating-stars-component";
import { liveClassRating } from "../../../crud/student.crud";
import { getSingleClass } from "../../../crud/teacher.crud";

function StudentLiveClassRating() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const { classId } = useParams();
  const [teacherId, setTeacherId] = useState("")
  useEffect(() => {
    // Get class details and set teacher
    getSingleClass(classId)
      .then(res => setTeacherId(res.teacher_id))
      .catch(err => console.log(err))
  }, [])
  
  const [rating, setResponseText] = useState(10);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const history = useHistory();
  const firstExample = {
    size: 40,
    count: 10,
    color: "black",
    activeColor: "red",
    a11y: true,
    isHalf: true,
    emptyIcon: <i className="far fa-star" />,
    halfIcon: <i className="fa fa-star-half-o" style={{ margin: '0px 2px' }} />,
    filledIcon: <i className="fa fa-star" style={{ margin: '0px 2px' }} />,
    onChange: (newValue) => {
      setResponseText(newValue);
    },
  };

  const handleSubmit = () => {
    liveClassRating({
      teacher_id: teacherId,
      rating,
    })
      .then((res) => {
        alert("Rating added successfully");
        setIsSubmitted(!isSubmitted);
        history.replace("/student-dashboard");
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="IDentii-tidi">
        {/* <div className="component-1-C61RwL-0001">
          <img alt="image" src={pic_2} style={{ height: "115px", marginTop: "-8%" }} />

        </div> */}
        <div className="card-c-class-mobile">
          <div className="card-body-in-c-class">
            <div className="inclqam-ds-0039">
              HOW WAS YOUR EXPERIENCE ?
            </div>
            <div className="rating_stars2">
              {" "}
              <ReactStars
                {...firstExample}
                size={18}
              />
            </div>
            <button
              type="button"
              className="btn btn-danger in-ele-btn-c"
              onClick={() => handleSubmit()}
            >
              Submit
            </button>
          </div>
        </div>

        <div className="card-c-class">
          <div className="card-body-in-c-class">
            <div className="inclqam-ds-0039">
              HOW WAS YOUR EXPERIENCE ?
            </div>
            <div className="rating_stars2">
              {" "}
              <ReactStars
                {...firstExample}
              />
            </div>
            <button
              type="button"
              className="btn btn-danger in-ele-btn-c"
              onClick={() => handleSubmit()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentLiveClassRating;
