import React, { useState, useEffect, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import "./trial.css";
import line6_2x from "./img/line-6@2x.png";
import line5_2x from "./img/line-5@2x.png";
import ReactStars from "react-rating-stars-component";
import { trialClassRating } from "../../../crud/student.crud";
import { getSingleTrialClass } from "../../../crud/teacher.crud";
import pic_2 from "../dashboard/img/pic_2.png";

function StudentTrialClassRating() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const { studentId, classId } = useParams();
  
  const [rating1, setResponseText1] = useState({
    questionId: "5",
    text: 0,
  });
  const [rating2, setResponseText2] = useState({
    questionId: "4",
    text: 10,
  });
  const [rating3, setResponseText3] = useState({
    questionId: "3",
    text: 0,
  });
  const [rating4, setResponseText4] = useState({
    questionId: "2",
    text:0,
  });
  const [suggestion, setSuggestionText] = useState("");
  const [answer5, setAnswer5] = useState({ questionId: "1", text: "No reviews" });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const firstExample = {
    size: 20,
    count: 10,
    color: "gray",
    activeColor: "red",
    a11y: true,
    isHalf: true,
    emptyIcon: <i className="far fa-star" />,
    halfIcon: <i className="fa fa-star-half-o" style={{ margin: '2px' }} />,
    filledIcon: <i className="fa fa-star" style={{ margin: '2px' }} />,
    onChange: (newValue) => {
      setResponseText1({ questionId: "5", text: newValue });
    },
  };

  const secondExample = {
    size: 20,
    count: 10,
    color: "gray",
    activeColor: "red",
    a11y: true,
    isHalf: true,
    emptyIcon: <i className="far fa-star" />,
    halfIcon: <i className="fa fa-star-half-o" style={{ margin: '2px' }} />,
    filledIcon: <i className="fa fa-star" style={{ margin: '2px' }} />,
    onChange: (newValue) => {
      setResponseText2({ questionId: "4", text: newValue });
    },
  };

  const thirdExample = {
    size: 20,
    count: 10,
    color: "gray",
    activeColor: "red",
    a11y: true,
    isHalf: true,
    emptyIcon: <i className="far fa-star" />,
    halfIcon: <i className="fa fa-star-half-o" style={{ margin: '2px' }} />,
    filledIcon: <i className="fa fa-star" style={{ margin: '2px' }} />,
    onChange: (newValue) => {
      setResponseText3({ questionId: "3", text: newValue });
    },
  };

  const fourthExample = {
    size: 20,
    count: 10,
    color: "gray",
    activeColor: "red",
    a11y: true,
    isHalf: true,
    emptyIcon: <i className="far fa-star" />,
    halfIcon: <i className="fa fa-star-half-o" style={{ margin: '2px' }} />,
    filledIcon: <i className="fa fa-star" style={{ margin: '2px' }} />,
    onChange: (newValue) => {
      setResponseText4({ questionId: "2", text: newValue });
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(classId)
    trialClassRating({
      formId: "TCSF",
      userId: studentId,
      receiverId: classId,
      answers: [rating1, rating2, rating3, rating4, answer5],
    })
      .then((res) => {
        alert("Rating added successfully");
        setIsSubmitted(!isSubmitted);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div >
        <div className="component-1-C61RwL_x">
          <img alt="image" src={pic_2} style={{ height: "115px", marginTop: "-8%" }} />

        </div>
        <div
          className="card"
          style={{ width: "72%", height: "auto", top: "200px", padding: '8px', marginBottom: '20px', margin: "auto" }}
        >
          <div className="card-body" style={{ padding: "10px" }}>
            <div className="feedback_title_x"> Feedback</div>

            <div className="rating_stars_x">
              <div id="5" className="ques_1">
                On a scale of 1-10, where 1 being the lowest and to being the
                highest, how would you rate your learning experience?
              </div>
              <div className="star_1">
                {" "}
                <ReactStars {...firstExample} />
              </div>
              <div id="4" className="ques_1">
                On a scale of 1-10, where 1 being the lowest and 10 being the
                highest how would you rate the teaching manner of our teacher?
              </div>
              <div className="star_1">
                {" "}
                <ReactStars
                  {...secondExample}
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                />
              </div>
              <div id="3" className="ques_1">
                On a scale of 1-10, where being the lowest and 10 being the
                highest, how would you rate our teaching platform?
              </div>
              <div className="star_1">
                {" "}
                <ReactStars
                  {...thirdExample}
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                />
              </div>
              <div id="2" className="ques_1">
                On a scale of 1-10, where 1 being the lowest and to being the
                highest, how would you rate our content of teaching?
              </div>
              <div className="star_1">
                {" "}
                <ReactStars
                  {...fourthExample}
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                />
              </div>
              <div className="ques_1">
                Any other comments/ reviews/ suggestions
              </div>
              <textarea
                className="form-control"
                onChange={(e) =>
                  setAnswer5({ questionId: "1", text: e.target.value })
                }
              ></textarea>
            </div>
            <button
              type="button"
              className="btn btn-danger vsjL-sdn10cm"
              onClick={(e) => handleSubmit(e)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentTrialClassRating;
