import React, { useState, useEffect, useLayoutEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { PieChart } from "react-minimal-pie-chart";
import { FaRegQuestionCircle, FaCalendarAlt, FaFileAlt, FaChartPie, FaStickyNote, FaPowerOff } from "react-icons/fa";
import { MdAssignment, MdDashboard, MdNotificationsNone, MdSchool } from "react-icons/md";
import { BiSupport, BiHistory } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { AiTwotoneBank } from "react-icons/ai";
import line_5_2x from "./img/line-5@2x.svg";
import line_6_2x from "./img/line-6@2x.svg";
import ellipse_2_1_2x from "./img/ellipse-2-1@2x.svg";
import vector_2x from "./img/vector@2x.svg";
import donut_chart_1_2x from "./img/donut-chart-1@2x.png";
import ellipse_5_2x from "./img/ellipse-5@2x.svg";
import ellipse_6_2x from "./img/ellipse-6@2x.svg";
import ellipse_7_2x from "./img/ellipse-7@2x.svg";
import ellipse_8_2x from "./img/ellipse-8@2x.svg";
import vector_3_2x from "./img/vector-3@2x.svg";
import vector_4_2x from "./img/vector-4@2x.svg";
import ellipse_4_2x from "./img/ellipse-4@2x.svg";
import vector_6_2x from "./img/vector-6@2x.png";
import vector_22_2x from "./img/vector-22@2x.svg";
import ellipse_4_1_2x from "./img/ellipse-4-1@2x.svg";
import vector_24_2x from "./img/vector-24@2x.svg";
import ellipse_4_2_2x from "./img/ellipse-4-2@2x.svg";
import vector_26_2x from "./img/vector-26@2x.svg";
import ellipse_4_3_2x from "./img/ellipse-4-3@2x.svg";
import vector_28_2x from "./img/vector-28@2x.svg";
import line_5_1_2x from "./img/line-5-1@2x.svg";
import line_6_1_2x from "./img/line-6-1@2x.svg";
import donut_chart_1_1_2x from "./img/donut-chart-1-1@2x.png";
import ellipse_5_1_2x from "./img/ellipse-5-1@2x.svg";
import ellipse_6_1_2x from "./img/ellipse-6-1@2x.svg";
import ellipse_7_1_2x from "./img/ellipse-7-1@2x.svg";
import ellipse_8_1_2x from "./img/ellipse-8-1@2x.svg";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from '../../../utils';
import { deleteRm } from '../../../redux/actions/rm';
import pic_2 from "../../student/dashboard/img/pic_2.png";

import { rmStatistics } from "../../../crud/rm.crud";
import SideDrawerRm from '../../../UI/sideDrawer/sideDrawerRm';
import Popup from '../../teacher/popup/popup';

function Rmstatistics(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  const user = useSelector((state) => state.rmDetails.rm);
  const [stats, setStats] = useState(undefined);
  const [isLoaded, setIsLoaded] = useState(false)
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);



  const sideDrawerToggler = () => {
    setDisplaySideDrawer(previousState => !previousState);
  };

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.rmDetails.rm);
  const handleLogout = () => {
    dispatch(deleteRm(reduxUser.rm_id))
    removeToken();
  }

  useEffect(() => {
    rmStatistics(user.rm_id)
      .then(res => {
        setStats(res);
        setIsLoaded(true);
      })
      .catch(err => console.log(err))
  }, [])

  let rmData = []

  isLoaded ? rmData = [
    { title: 'Resolved', value: stats.total_issues_resolved, color: '#6F58FF' },
    { title: 'Resolved by RM', value: stats.issues_resolved_by_rm, color: '#46C9B8' },
    { title: 'Tickets Raised', value: stats.tickets_raised, color: '#F76775' },
  ] : rmData = [];

  return (
    <>
      <input type="hidden" id="anPageName" name="page" value="rm-statistics" />
      <div className="container-center-horizontal">
        <div className="rm-statistics screen">
          <div className="group-209-C61RwL-10-1">
            <Link to="/rm-dashboard">
              <MdDashboard
                size={24}
                data-tip="Dashboard"
                color="rgba(179,179,179,1.0)"
              />
              <ReactTooltip />
            </Link>
            <div className="historytoggleoff-24px-1-D0BVcB">
              <div className="group-rAjf8W">
                <Link to="/rm-issue-list">
                  {" "}
                  <BiHistory
                    data-tip="Previous Issues"
                    size={24}
                    color="rgba(179,179,179,1.0)"
                  />
                  <ReactTooltip />
                </Link>
              </div>
            </div>
            <div className="school-24px-1-D0BVcB">
              <Link to="/rm-list">
                <MdSchool
                  data-tip="My Students"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
            <div className="help-24px-2-D0BVcB">
              <Link to="/rm-ticket-list">
                <FaRegQuestionCircle
                  data-tip="Tickets"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
            <div className="analytics-24px-4-D0BVcB">
              <Link to="/rm-statistics">
                <FaChartPie data-tip="Analytics" size={24} color="#dc3545" />
                <ReactTooltip />
              </Link>
            </div>
            {/* <div className="accountbalance-24px-1-D0BVcB">
              <Link to="/rm-payments"><AiTwotoneBank data-tip="Payments" size={24} color="rgba(179,179,179,1.0)" /><ReactTooltip /></Link>
            </div> */}
            <div className="vector-Ayxm2x">
              <Link to="/" onClick={() => handleLogout()}>
                <FaPowerOff
                  data-tip="Logout"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
          </div>
          <div className="flex-col-C61RwL-10-1">
            <div className="flex-row-0xy0vn">
              <div className="flex-col-GyJUNH">
                <div className="flex-row-3xHcwy-iciw">
                  <div className="component-1-xKTZor">
                    <img src={pic_2} style={{ height: "100px" }} />
                  </div>
                  <div className="my-statistics-xKTZor">
                    <span className="span0-liz3Y2">My</span>
                    <span className="span1-liz3Y2"> Statistics</span>
                  </div>
                  <div className="notifications-24px-2-1-GyJUNH">
                    <div className="vector-40xw7g">
                      <MdNotificationsNone size={28} />
                    </div>
                    <img className="ellipse-2-RYyMFx" src={ellipse_2_1_2x} />
                  </div>
                </div>
                <div className="flex-row-d1Gp1h">
                  <div className="group-143-t3Ing0">
                    <div className="group-142-WaYlHd">
                      <div className="group-70-GW5w5b">
                        <div className="group-57-6hhWQr"></div>
                        <div className="total-issues-6hhWQr montserrat-medium-blue-zodiac-18px">
                          Total Issues
                        </div>
                      </div>
                      <div className="x10-GW5w5b montserrat-semi-bold-black-96px">
                        {stats && stats.total_issues}
                      </div>
                    </div>
                  </div>
                  <div className="group-161-t3Ing0">
                    <div className="group-142-MAFAU8">
                      <div className="group-70-1sM1lf">
                        <div className="group-57-zE5xZQ"></div>
                        <div className="resolved-zE5xZQ montserrat-medium-blue-zodiac-18px">
                          Resolved
                        </div>
                      </div>
                      <div className="x7-1sM1lf montserrat-semi-bold-black-96px">
                        {stats && stats.total_issues_resolved}
                      </div>
                    </div>
                  </div>
                  <div className="group-162-t3Ing0">
                    <div className="group-142-qkrrvg">
                      <div className="group-70-apKywr">
                        <div className="group-57-cVNVVu"></div>
                        <div className="resolved-by-me-cVNVVu montserrat-medium-blue-zodiac-18px">
                          Resolved by me
                        </div>
                      </div>
                      <div className="x3-apKywr montserrat-semi-bold-black-96px">
                        {stats && stats.issues_resolved_by_rm}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-col-gkUG7I">
                <div className="group-22-nFOi0F">
                  <div className="flex-col-j5WY2e">
                    <div className="biba-sobti-8HzYQT">
                      {user && user.first_name} {user && user.last_name}
                    </div>
                    <div className="bibasobtigmailcom-8HzYQT">
                      {user && user.emailID}
                    </div>
                  </div>
                  <img className="vector-j5WY2e" src={vector_2x} />
                </div>
                <div className="group-163-nFOi0F">
                  <div className="group-142-6apKlK">
                    <div className="group-70-kHK9TT">
                      <div className="group-57-EoPRmN"></div>
                      <div className="tickets-raised-EoPRmN montserrat-medium-blue-zodiac-18px">
                        Tickets raised
                      </div>
                    </div>
                    <div className="x3-kHK9TT montserrat-semi-bold-black-96px">
                      {stats && stats.tickets_raised}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-col-0xy0vn">
              <div className="overlap-group-xpmbvu">
                <img
                  className="rectangle-7-O40pOL"
                  src="img/rectangle-7@1x.png"
                />
                <div className="group-69-O40pOL">
                  <div className="group-57-oTpdOr"></div>
                  <div className="issue-statistics-oTpdOr montserrat-medium-blue-zodiac-18px">
                    Issue Statistics
                  </div>
                  {isLoaded ? (
                    <PieChart
                      className="donut-chart-1-oTpdOr"
                      lineWidth={30}
                      rounded
                      label={({ dataEntry }) => dataEntry.value}
                      labelStyle={(index) => ({
                        fill: rmData[index].color,
                        fontSize: "5px",
                        fontFamily: "sans-serif",
                      })}
                      labelPosition={60}
                      data={rmData}
                    />
                  ) : (
                    "Loading..."
                  )}
                  <img className="ellipse-5-oTpdOr" src={ellipse_5_2x} />
                  <img className="ellipse-6-oTpdOr" src={ellipse_6_2x} />
                  <img className="ellipse-7-oTpdOr" src={ellipse_7_2x} />
                  {/* <img className="ellipse-8-oTpdOr" src={ellipse_8_2x} /> */}
                  <div className="resolved-oTpdOr montserrat-semi-bold-blue-zodiac-10px">
                    Resolved
                  </div>
                  <div className="resolved-by-rm-oTpdOr montserrat-semi-bold-blue-zodiac-10px">
                    Resolved by RM
                  </div>
                  <div className="tickets-raised-oTpdOr montserrat-semi-bold-blue-zodiac-10px">
                    Tickets Raised
                  </div>
                  {/* <div className="open-oTpdOr montserrat-semi-bold-blue-zodiac-10px">Open</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-center-horizontal">
        <div className="rm-statistics-mobile screen">
          <div className="flex-row-C61RwL">
            <div className="flex-col-dXBp89">
              <div className="group-72-0PZcXW">
                <div className="rectangle-40-EXDUvW"></div>
                <div className="component-29-EXDUvW">
                  <img className="ellipse-4-tOztDT" src={ellipse_4_2x} />
                  <div className="assignment-24px-3-tOztDT">
                    <img className="vector-sxwcxa" src={vector_6_2x} />
                    <img className="vector-XgFQAe" src={vector_22_2x} />
                  </div>
                </div>
                <div className="assignment-1-EXDUvW montserrat-semi-bold-blue-zodiac-10px">
                  ASSIGNMENT 1
                </div>
                <div className="mathematics-dr-yashna-sharma-EXDUvW montserrat-medium-gray-7px">
                  Mathematics (Dr. Yashna Sharma)
                </div>
                <div className="submitted-EXDUvW montserrat-semi-bold-niagara-8px">
                  Submitted
                </div>
              </div>
              <div className="group-73-0PZcXW">
                <div className="rectangle-40-LFvdd7"></div>
                <div className="component-29-LFvdd7">
                  <img className="ellipse-4-1HHR9j" src={ellipse_4_1_2x} />
                  <div className="assignment-24px-3-1HHR9j">
                    <img className="vector-eH2jVX" src={vector_6_2x} />
                    <img className="vector-JGyev7" src={vector_24_2x} />
                  </div>
                </div>
                <div className="assignment-1-LFvdd7 montserrat-semi-bold-blue-zodiac-10px">
                  ASSIGNMENT 1
                </div>
                <div className="mathematics-dr-yashna-sharma-LFvdd7 montserrat-medium-gray-7px">
                  Mathematics (Dr. Yashna Sharma)
                </div>
                <div className="submitted-LFvdd7 montserrat-semi-bold-niagara-8px">
                  Submitted
                </div>
              </div>
              <div className="group-74-0PZcXW">
                <div className="rectangle-40-URlHrD"></div>
                <div className="component-29-URlHrD">
                  <img className="ellipse-4-xgoJzt" src={ellipse_4_2_2x} />
                  <div className="assignment-24px-3-xgoJzt">
                    <img className="vector-xkta9U" src={vector_6_2x} />
                    <img className="vector-WcNXVM" src={vector_26_2x} />
                  </div>
                </div>
                <div className="assignment-1-URlHrD montserrat-semi-bold-blue-zodiac-10px">
                  ASSIGNMENT 1
                </div>
                <div className="mathematics-dr-yashna-sharma-URlHrD montserrat-medium-gray-7px">
                  Mathematics (Dr. Yashna Sharma)
                </div>
                <div className="submitted-URlHrD montserrat-semi-bold-niagara-8px">
                  Submitted
                </div>
              </div>
              <div className="group-75-0PZcXW">
                <div className="rectangle-40-VqvJAl"></div>
                <div className="component-29-VqvJAl">
                  <img className="ellipse-4-e62HuU" src={ellipse_4_3_2x} />
                  <div className="assignment-24px-3-e62HuU">
                    <img className="vector-wEaHDs" src={vector_6_2x} />
                    <img className="vector-VZdL8H" src={vector_28_2x} />
                  </div>
                </div>
                <div className="assignment-1-VqvJAl montserrat-semi-bold-blue-zodiac-10px">
                  ASSIGNMENT 1
                </div>
                <div className="mathematics-dr-yashna-sharma-VqvJAl montserrat-medium-gray-7px">
                  Mathematics (Dr. Yashna Sharma)
                </div>
                <div className="submitted-VqvJAl montserrat-semi-bold-niagara-8px">
                  Submitted
                </div>
              </div>
            </div>
            <div className="flex-col-Xi618D">
              <div className="component-1-y0eQTV">
                <img src={pic_2} style={{ height: "100px", margin: "-8%" }} />
              </div>
              <div className="my-statistics-y0eQTV">
                <span className="span0-p1GhMy">My</span>
                <span className="span1-p1GhMy"> Statistics</span>
              </div>
              <div className="group-143-y0eQTV">
                <div className="group-142-zGQBKN">
                  <div className="group-70-4yUOVC">
                    <div className="group-57-ytlf7G"></div>
                    <div className="total-issues-ytlf7G montserrat-medium-blue-zodiac-18px">
                      Total Issues
                    </div>
                  </div>
                  <div className="x10-4yUOVC montserrat-semi-bold-black-96px">
                    {stats && stats.total_issues}
                  </div>
                </div>
              </div>
              <div className="group-161-y0eQTV">
                <div className="group-142-7IAPPv">
                  <div className="group-70-F7BMxN">
                    <div className="group-57-xvYkoy"></div>
                    <div className="resolved-xvYkoy montserrat-medium-blue-zodiac-18px">
                      Resolved
                    </div>
                  </div>
                  <div className="x7-F7BMxN montserrat-semi-bold-black-96px">
                    {stats && stats.total_issues_resolved}
                  </div>
                </div>
              </div>
              <div className="group-162-y0eQTV">
                <div className="group-142-b16P8T">
                  <div className="group-70-kq6nGX">
                    <div className="group-57-HxxsyS"></div>
                    <div className="resolved-by-me-HxxsyS montserrat-medium-blue-zodiac-18px">
                      Resolved by me
                    </div>
                  </div>
                  <div className="x3-kq6nGX montserrat-semi-bold-black-96px">
                    {stats && stats.issues_resolved_by_rm}
                  </div>
                </div>
              </div>
              <div className="group-163-y0eQTV">
                <div className="group-142-ieM5yY">
                  <div className="group-70-ByagiY">
                    <div className="group-57-ObkCNx"></div>
                    <div className="tickets-raised-ObkCNx montserrat-medium-blue-zodiac-18px">
                      Tickets raised
                    </div>
                  </div>
                  <div className="x3-ByagiY montserrat-semi-bold-black-96px">
                    {stats && stats.tickets_raised}
                  </div>
                </div>
              </div>
            </div>
            <SideDrawerRm
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />
            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>
          <div className="group-69-C61RwL">
            <div className="group-57-8FQU39"></div>
            <div className="issue-statistics-8FQU39 montserrat-medium-blue-zodiac-18px">
              Issue Statistics
            </div>
            {isLoaded ? (
              <PieChart
                className="donut-chart-1-8FQU39"
                lineWidth={30}
                rounded
                label={({ dataEntry }) => dataEntry.value}
                labelStyle={(index) => ({
                  fill: rmData[index].color,
                  fontSize: "5px",
                  fontFamily: "sans-serif",
                })}
                labelPosition={60}
                data={rmData}
              />
            ) : (
              "Loading..."
            )}
            <img className="ellipse-5-8FQU39" src={ellipse_5_1_2x} />
            <img className="ellipse-6-8FQU39" src={ellipse_6_1_2x} />
            <img className="ellipse-7-8FQU39" src={ellipse_7_1_2x} />
            {/* <img className="ellipse-8-8FQU39" src={ellipse_8_1_2x} /> */}
            <div className="resolved-8FQU39 montserrat-semi-bold-blue-zodiac-10px">
              Resolved
            </div>
            <div className="resolved-by-rm-8FQU39 montserrat-semi-bold-blue-zodiac-10px">
              Resolved by RM
            </div>
            <div className="tickets-raised-8FQU39 montserrat-semi-bold-blue-zodiac-10px">
              Tickets Raised
            </div>
            {/* <div className="open-8FQU39 montserrat-semi-bold-blue-zodiac-10px">Open</div> */}
          </div>
        </div>
      </div>
      <Popup />
    </>
  );
}


function Component1(props) {
  const { line5, line6 } = props;

  return (
    <div className="component-1 border-1px-blue-zodiac">
      <div className="overlap-group8">
        <img className="line-5" src={line5} />
        <img className="line-6" src={line6} />
      </div>
    </div>
  );
}


function Group142(props) {
  const { totalIssues, number, className } = props;

  return (
    <div className={`group-142 ${className || ""}`}>
      <div className="overlap-group-2">
        <div className="group-70">
          <div className="overlap-group1-1">
            <div className="total-issues montserrat-medium-blue-zodiac-18px">{totalIssues}</div>
          </div>
        </div>
        <h1 className="number montserrat-semi-bold-black-96px">{number}</h1>
      </div>
    </div>
  );
}
const component1Data = {
  line5: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605990a43ce41a6c7995f91f/img/line-5-4@2x.png",
  line6: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/line-6@2x.png",
};

const group142Data = {
  totalIssues: "Total Issues",
  number: "10",
};

const group1422Data = {
  totalIssues: "Resolved",
  number: "7",
};

const group1423Data = {
  totalIssues: "Resolved by me",
  number: "3",
};

const group1424Data = {
  totalIssues: "Tickets raised",
  number: "3",
};

const RmstatisticsData = {
  vector: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-35@2x.png",
  vector2: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1c6a4e78cf803e37e7fd/img/vector-29@2x.png",
  vector3: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1d433ce41a6c7995fc03/img/vector-9@2x.png",
  vector4: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector5: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058d518334660f0a4896cec/img/vector-193@2x.png",
  vector6: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector7: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-228@2x.png",
  vector8: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector9: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1d433ce41a6c7995fc03/img/vector-36@2x.png",
  vector10: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector11: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-166@2x.png",
  vector12: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-162@2x.png",
  vector13: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-163@2x.png",
  vector14: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-162@2x.png",
  vector15: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-164@2x.png",
  vector16: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-33@2x.png",
  spanText: "My",
  spanText2: " Statistics",
  vector17: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector18: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-38@2x.png",
  ellipse2: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/ellipse-2@2x.png",
  bibaSobti: "Biba Sobti",
  bibasobtiGmailCom: "bibasobti@gmail.com",
  vector19: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-36@2x.png",
  rectangle7: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/rectangle-7-5@1x.png",
  issueStatistics: "Issue Statistics",
  donutChart1: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1d433ce41a6c7995fc03/img/donut-chart-1@2x.png",
  ellipse5: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1d433ce41a6c7995fc03/img/ellipse-5@2x.png",
  ellipse6: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1d433ce41a6c7995fc03/img/ellipse-6@2x.png",
  ellipse7: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1d433ce41a6c7995fc03/img/ellipse-7@2x.png",
  ellipse8: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1d433ce41a6c7995fc03/img/ellipse-8@2x.png",
  resolved: "Resolved",
  resolvedByRm: "Resolved by RM",
  ticketsRaised: "Tickets Raised",
  open: "Open",
  vector20: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1c6a4e78cf803e37e7fd/img/vector-26@2x.png",
  messages: "Messages",
  vector21: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1c6a4e78cf803e37e7fd/img/vector-25@2x.png",
  component1Props: component1Data,
  group142Props: group142Data,
  group1422Props: group1422Data,
  group1423Props: group1423Data,
  group1424Props: group1424Data,
};

export default Rmstatistics;