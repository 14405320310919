import axios from "axios";
import { toAPIURL } from "../../../utils";
import { GET_HC, DELETE_HC } from "./types";

// GET TEACHER
export const getHc = (user_data, history) => dispatch => {
    axios
        .get(toAPIURL("api/hc-details/" + user_data.email))
        .then(res => {
            dispatch({
                type: GET_HC,
                payload: res.data
            });
            history.replace("/hc-dashboard")
        })
        .catch(err => console.log(err));
}

// DELETE HC
export const deleteHc = hcId => ({
    type: DELETE_HC,
    payload: hcId
})