import React from "react";
import "./dstrip.css";

function DownloadStrip() {
  return (
    <div className="conatiner">
      <div className="wraper">
        <section className="enable-animation">
          <div className="marquee">
            <ul className="marquee__content">
              <li>Join! Classroom Tuition Classes </li>
              <li> | </li>
              <li>Enroll now in English Spoken Classes </li>
              <li> | </li>
              <li>Excel in Economics </li>
              <li> | </li>
              <li>Boost your Board Preparation </li>
              <li> | </li>
              <li>Assess yourself with Interactive Classes </li>
              <li> | </li>
            </ul>
            <ul className="marquee__content">
              <li>Join! Classroom Tuition Classes </li>
              <li> | </li>
              <li>Enroll now in English Spoken Classes </li>
              <li> | </li>
              <li>Excel in Economics </li>
              <li> | </li>
              <li>Boost your Board Preparation </li>
              <li> | </li>
              <li>Assess yourself with Interactive Classes </li>
              <li> | </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
}

export default DownloadStrip;
