import React, { useLayoutEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import generator from 'generate-password';
import Modal from 'react-modal';
import Select from 'react-dropdown-select';
import navLogo from "../../student/dashboard/img/pic_4.png";
import { Navbar } from 'react-bootstrap';
import { Dropdown } from "semantic-ui-react";
import "./cashpayment.css";
import { Divider } from 'antd';
import { trialStudentDetails, createEnrolledStudent, registerStudent, addStudentTransaction } from "../../../crud/student.crud";
import DropdownOption from "../../higher_committee/dropdown/dropdown_grade";
Modal.setAppElement('#root');

// const countryOptions = [
//     {
//         value: "India",
//         label: "India",
//     },
//     {
//         value: "United Kingdom",
//         label: "United Kingdom",
//     }
// ]

const countryOptions = [
  {
    value: "India",
    text: "India",
    key: "India",
  },
  {
    value: "United Kingdom",
    text: "United Kingdom",
    key: "UnitedKingdom",
  },
  {
    value: "USA",
    text: "USA",
    key: "USA",
  },
  {
    value: "Canada",
    text: "Canada",
    key: "Canada",
  },
  {
    value: "New Zealand",
    text: "New Zealand",
    key: "NewZealand",
  },
  {
    value: "Australia",
    text: "Australia",
    key: "Australia",
  },
];

const usaStateOptions = [
  {
    value: "Alabama",
    text: "Alabama",
    key: "ALB",
  },
  {
    value: "Alaska",
    text: "Alaska",
    key: "ALS",
  },
  {
    value: "Arizona",
    text: "Arizona",
    key: "AR",
  },
  {
    value: "Arkansas",
    text: "Arkansas",
    key: "ARK",
  },
  {
    value: "California",
    text: "California",
    key: "CA",
  },
  {
    value: "Colorado",
    text: "Colorado",
    key: "CO",
  },
  {
    value: "Connecticut",
    text: "Connecticut",
    key: "CT",
  },
  {
    value: "Delaware",
    text: "Delware",
    key: "DE",
  },
  {
    value: "Florida",
    text: "Florida",
    key: "FL",
  },
  {
    value: "Georgia",
    text: "Georgia",
    key: "GA",
  },
  {
    value: "Hawaii",
    text: "Hawaii",
    key: "HA",
  },
  {
    value: "Idaho",
    text: "Idaho",
    key: "ID",
  },
  {
    value: "Illinois",
    text: "Illinois",
    key: "IL",
  },
  {
    value: "Indiana",
    text: "Indiana",
    key: "IN",
  },
  {
    value: "Iowa",
    text: "Iowa",
    key: "IO",
  },
  {
    value: "Kansas",
    text: "Kansas",
    key: "KA",
  },
  {
    value: "Kentucky",
    text: "Kentucky",
    key: "KT",
  },
  {
    value: "Louisiana",
    text: "Louisiana",
    key: "LO",
  },
  {
    value: "Maine",
    text: "Maine",
    key: "MN",
  },
  {
    value: "Maryland",
    text: "Maryland",
    key: "MR",
  },
  {
    value: "Massachusetts",
    text: "Massachusetts",
    key: "MA",
  },
  {
    value: "Michigan",
    text: "Michigan",
    key: "MI",
  },
  {
    value: "Minnesota",
    text: "Minnesota",
    key: "MNS",
  },
  {
    value: "Mississippi",
    text: "Mississippi",
    key: "MSP",
  },
  {
    value: "Missouri",
    text: "Missouri",
    key: "MS",
  },
  {
    value: "Montana",
    text: "Montana",
    key: "MO",
  },
  {
    value: "Nebraska",
    text: "Nebraska",
    key: "NB",
  },
  {
    value: "Nevada",
    text: "Nevada",
    key: "NE",
  },
  {
    value: "New Hampshire",
    text: "New Hampshire",
    key: "NH",
  },
  {
    value: "New Jersey",
    text: "New Jersey",
    key: "NJ",
  },
  {
    value: "New Mexico",
    text: "New Mexico",
    key: "NM",
  },
  {
    value: "New York",
    text: "New York",
    key: "NY",
  },
  {
    value: "North Carolina",
    text: "North Carolina",
    key: "NC",
  },
  {
    value: "North Dakota",
    text: "North Dakota",
    key: "ND",
  },
  {
    value: "Ohio",
    text: "Ohio",
    key: "OH",
  },
  {
    value: "Oklahoma",
    text: "Oklahoma",
    key: "OK",
  },
  {
    value: "Oregon",
    text: "Oregon",
    key: "OR",
  },
  {
    value: "Pennsylvania",
    text: "Pennsylvania",
    key: "PA",
  },
  {
    value: "Rhode Island",
    text: "Rhode Island",
    key: "RI",
  },
  {
    value: "South Carolina",
    text: "South Carolina",
    key: "SC",
  },
  {
    value: "South Dakota",
    text: "South Dakota",
    key: "SD",
  },
  {
    value: "Tennessee",
    text: "Tennessee",
    key: "TN",
  },
  {
    value: "Texas",
    text: "Texas",
    key: "TA",
  },
  {
    value: "Utah",
    text: "Utah",
    key: "UT",
  },
  {
    value: "Vermont",
    text: "Vermont",
    key: "VE",
  },
  {
    value: "Virginia",
    text: "Virginia",
    key: "VA",
  },
  {
    value: "Washington",
    text: "Washington",
    key: "WA",
  },
  {
    value: "West Virginia",
    text: "West Virginia",
    key: "WV",
  },
  {
    value: "Wisconsin",
    text: "Wisconsin",
    key: "WI",
  },
  {
    value: "Wyoming",
    text: "Wyoming",
    key: "WY",
  },
];

const ausStateOptions = [
  {
    value: "New South Wales",
    text: "New South Wales",
    key: "NSW",
  },
  {
    value: "Queensland",
    text: "Queensland",
    key: "QN",
  },
  {
    value: "South Australia",
    text: "South Australia",
    key: "SA",
  },
  {
    value: "Tasmania",
    text: "Tasmania",
    key: "TA",
  },
  {
    value: "Victoria",
    text: "Victoria",
    key: "VI",
  },
  {
    value: "Western Australia",
    text: "Western Australia",
    key: "WA",
  },
  {
    value: "Australian Capital Territory",
    text: "Australian Capital Territory",
    key: "ACT",
  },
  {
    value: "Northern Territory",
    text: "Northern Territory",
    key: "NT",
  },
];

const stateOptions = [
  {
    value: "Andaman and Nicobar Islands",
    text: "Andaman and Nicobar Islands",
    key: "AN",
  },
  {
    value: "Andhra Pradesh",
    text: "Andhra Pradesh",
    key: "AP",
  },
  {
    value: "Arunachal Pradesh",
    text: "Arunachal Pradesh",
    key: "AR",
  },
  {
    value: "Assam",
    text: "Assam",
    key: "AS",
  },
  {
    value: "Bihar",
    text: "Bihar",
    key: "BR",
  },
  {
    value: "Chandigarh",
    text: "Chandigarh",
    key: "CG",
  },
  {
    value: "Chhattisgarh",
    text: "Chhattisgarh",
    key: "CH",
  },
  {
    value: "Dadra and Nagar Haveli",
    text: "Dadra and Nagar Haveli",
    key: "DH",
  },
  {
    value: "Daman and Diu",
    text: "Daman and Diu",
    key: "DD",
  },
  {
    value: "Delhi",
    text: "Delhi",
    key: "DL",
  },
  {
    value: "Goa",
    text: "Goa",
    key: "GA",
  },
  {
    value: "Gujarat",
    text: "Gujarat",
    key: "GJ",
  },
  {
    value: "Haryana",
    text: "Haryana",
    key: "HR",
  },
  {
    value: "Himachal Pradesh",
    text: "Himachal Pradesh",
    key: "HP",
  },
  {
    value: "Jammu and Kashmir",
    text: "Jammu and Kashmir",
    key: "JK",
  },
  {
    value: "Jharkhand",
    text: "Jharkhand",
    key: "JH",
  },
  {
    value: "Karnataka",
    text: "Karnataka",
    key: "KA",
  },
  {
    value: "Kerala",
    text: "Kerala",
    key: "KL",
  },
  {
    value: "Lakshadweep",
    text: "Lakshadweep",
    key: "LD",
  },
  {
    value: "Madhya Pradesh",
    text: "Madhya Pradesh",
    key: "MP",
  },
  {
    value: "Maharashtra",
    text: "Maharashtra",
    key: "MH",
  },
  {
    value: "Manipur",
    text: "Manipur",
    key: "MN",
  },
  {
    value: "Meghalaya",
    text: "Meghalaya",
    key: "ML",
  },
  {
    value: "Mizoram",
    text: "Mizoram",
    key: "MZ",
  },
  {
    value: "Nagaland",
    text: "Nagaland",
    key: "NL",
  },
  {
    value: "Odisha",
    text: "Odisha",
    key: "OR",
  },
  {
    value: "Puducherry",
    text: "Puducherry",
    key: "PY",
  },
  {
    value: "Punjab",
    text: "Punjab",
    key: "PB",
  },
  {
    value: "Rajasthan",
    text: "Rajasthan",
    key: "RZ",
  },
  {
    value: "Sikkim",
    text: "Sikkim",
    key: "SK",
  },
  {
    value: "Tamil Nadu",
    text: "Tamil Nadu",
    key: "TN",
  },
  {
    value: "Telangana",
    text: "Telangana",
    key: "TS",
  },
  {
    value: "Tripura",
    text: "Tripura",
    key: "TR",
  },
  {
    value: "Uttar Pradesh",
    text: "Uttar Pradesh",
    key: "UP",
  },
  {
    value: "Uttarakhand",
    text: "Uttarakhand",
    key: "UK",
  },
  {
    value: "West Bengal",
    text: "West Bengal",
    key: "WB",
  },
];  

const gradeOptions = [
    {
        value: "-1",
        label: "Nursery",
    },
    {
        value: "0",
        label: "KG",
    },
    {
        value: "1",
        label: "1",
    },
    {
        value: "2",
        label: "2",
    },
    {
        value: "3",
        label: "3",
    },
    {
        value: "4",
        label: "4",
    },
    {
        value: "5",
        label: "5",
    },
    {
        value: "6",
        label: "6",
    },
    {
        value: "7",
        label: "7",
    },
    {
        value: "8",
        label: "8",
    },
    {
        value: "9",
        label: "9",
    },
    {
        value: "10",
        label: "10",
    },
    {
        value: "11",
        label: "11",
    },
    {
        value: "12",
        label: "12",
    },
]

const selectStyles = {
    width: "100%",
    paddingLeft: "10px",
    borderRadius: "5px",
    border: "2px solid rgba(217, 83, 79, 0.4)",
    fontSize: "15px",
    fontWeight: 400,
    fontFamily: "'Montserrat', Helvetica",
}

const Cashpayment = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const history = useHistory();

  //form state
  const [first_name, setFirstName] = useState("")
  const [last_name, setLastName] = useState("")
  const [mobile_number, setMobileNumber] = useState("")
  const [father_name, setFatherName] = useState("")
  const [father_number, setFatherNumber] = useState("")
  const [email, setEmail] = useState("")
  const [country, setCountry] = useState("")
  const [state, setStudentState] = useState("")
  const [postal_code, setPostalCode] = useState("")
  const [city, setCity] = useState("")
  const [address, setAddress] = useState("")
  const [landmark, setLandmark] = useState("")
  const [school, setSchool] = useState("")
  const [lastMarks, setLastMarks] = useState(0)
  const [current_grade, setCurrentGrade] = useState("")
const [cashAmount, setCashAmount] = useState(0)
    const handleGradeSelect = (value) => {
      setCurrentGrade(value);
    };
    
    const handleSubmit = async () => {
        console.log(current_grade);
        console.log(country);
        console.log(state);
    if(mobile_number == "" || country == "" || state == "" || postal_code == "" || current_grade == "" ||
    city == "" || address == "" || landmark == "" || school == "" || lastMarks == "") {
      alert("Please fill all fields before submitting.");
    } else {
        // hit API to save payment details to database
        addStudentTransaction({
            student_transaction_id: "offline_" + email,
            trial_student_id: `TS_${email}`,
            student_name: first_name + " " + last_name,
            payment_for: "Student Enrollement",
            email: email,
            created_on: (new Date()).toISOString(),
            amount: cashAmount,
            method: "Offline"
        })
          .then(() => console.log("Payment Successfull"))
          .catch(err => console.log(err))
        
         //hit APIs to create Enrolled Student in DB and register user
        createEnrolledStudent({
            first_name,
            last_name,
            mobile_number,
            father_name,
            father_number,
            email,
            country,
            state,
            postal_code,
            current_grade,
            city,
            address,
            landmark,
            current_school: school,
            last_marks: parseFloat(lastMarks),
            enrolled_student_id: "ES_" + email,
            trial_student_id: `TS_${email}`
        })
        .then(() => {
            const password = generator.generate({
              length: 10,
              numbers: true,
            });
            registerStudent({
                email,
                password,
                user_type: 1
            })
            .then(res => {
                alert(`Welcome to The Right Guru!\n 
                    You can now login to your dashboard using the following credentials:-\n
                    email:${email}\n
                    password:${password}\n
                    Please record this password and keep it safe.`)
                history.replace("/");
            })
            .catch(err => {
                console.log(err)
            })
        })
        .catch(err => {
            console.log(err)
        })
    }  
  }

    return (
      <div>
        <Navbar
          className="navbar-scroll py-4"
          style={{ fontFamily: "'Montserrat', sans-serif" }}
          collapseOnSelect
          expand="lg"
          bg="light"
          variant="light"
        >
          <Navbar.Brand style={{ fontWeight: "600" }}>
            <img src={navLogo} width="172px" />
          </Navbar.Brand>
        </Navbar>

        <div className="card">
          <div className="row">
            <div className="col-sm-4">
              <input
                type="text"
                placeholder="Student's First Name"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="col-sm-4">
              <input
                type="text"
                placeholder="Student's Last Name"
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="col-sm-4">
              <input
                type="text"
                placeholder="Father's Name"
                onChange={(e) => setFatherName(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 smtext">
              <input
                type="text"
                placeholder="Primary Email Address"
                className=" input2"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <input
                type="text"
                placeholder="Current School"
                className=" input2"
                onChange={(e) => setSchool(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4">
              <input
                type="text"
                placeholder="Mobile number"
                className=" input2"
                onChange={(e) => setMobileNumber(e.target.value)}
              />
              <p style={{ fontSize: "10px", paddingTop: "1px" }}>
                *Please enter 10 digit mobile number without any country code or
                '0'
              </p>
            </div>
            <div className="col-sm-4">
              <input
                type="text"
                placeholder="Parent's Mobile Number"
                className=" input2"
                onChange={(e) => setFatherNumber(e.target.value)}
              />
              <p style={{ fontSize: "10px", paddingTop: "1px" }}>
                *Please enter 10 digit mobile number without any country code or
                '0'
              </p>
            </div>
            <div className="col-sm-4">
              <DropdownOption handleGradeSelect={handleGradeSelect} />
              {/* <Select
                options={gradeOptions}
                className="mbottom"
                placeholder="Student's Current Grade"
                searchable
                onChange={(value) => setCurrentGrade(value[0].value)}
                color="#ec3545"
                style={selectStyles}
              /> */}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4">
              {/* <Select
                            className="mbottom"
                            options={countryOptions}
                            placeholder="Select Country"
                            searchable
                            color="#ec3545"
                            onChange={value => setCountry(value[0].value)}
                            style={selectStyles}
                        /> */}
              <Dropdown
                placeholder="Select Country"
                search
                selection
                onChange={(e, c) => setCountry(c.value)}
                options={countryOptions}
                style={selectStyles}
              />
            </div>
            <div className="col-sm-4">
              {country != "India" ? (
                country == "USA" ? (
                  <Dropdown
                    placeholder="State"
                    search
                    selection
                    onChange={(e, properties) =>
                      setStudentState(properties.value)
                    }
                    style={selectStyles}
                    className="dropdown1"
                    options={usaStateOptions}
                  />
                ) : country == "Australia" ? (
                  <Dropdown
                    placeholder="State"
                    search
                    selection
                    onChange={(e, properties) =>
                      setStudentState(properties.value)
                    }
                    style={selectStyles}
                    className="dropdown1"
                    options={ausStateOptions}
                  />
                ) : (
                  <input
                    type="text"
                    placeholder="State"
                    onChange={(e) => setStudentState(e.target.value)}
                  />
                )
              ) : (
                <Dropdown
                  placeholder="State"
                  search
                  selection
                  onChange={(e, properties) =>
                    setStudentState(properties.value)
                  }
                  style={selectStyles}
                  className="dropdown1"
                  options={stateOptions}
                />
              )}
              {/* <input
                className="input2"
                type="text"
                placeholder="State"
                onChange={(e) => setStudentState(e.target.value)}
              /> */}
            </div>
            <div className="col-sm-4">
              <input
                className="input2"
                type="text"
                placeholder="Postal Code"
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 smtext">
              <input
                type="text"
                placeholder="Address"
                className=" input2"
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <input
                type="text"
                placeholder="City"
                className=" input2"
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 smtext">
              <input
                type="text"
                placeholder="Landmark"
                className=" input2"
                onChange={(e) => setLandmark(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <input
                type="number"
                placeholder="Percentage scored in previous class"
                className=" input2"
                onChange={(e) => setLastMarks(e.target.value)}
              />
            </div>
          </div>
          {/* <div className="row">
                <div className="col-md-6" >
                  <input type="text" placeholder="Preferred Date"/>
                </div>
                <div className="col-md-6">
                  <input type="text" placeholder="Preferred Time Slot"/>
                </div>
              </div> */}
          <div style={{ width: "100%" }}>
            <Divider />
            <div className="amount-display">
              <span>Amount To Be Paid</span>
              <span className="amountt-div">
                <input
                  className="amountt"
                  type="number"
                  onChange={(e) => setCashAmount(e.target.value)}
                  placeholder="Enter Amount"
                />
              </span>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-danger submit"
            onClick={() => handleSubmit()}
            style={{
              fontFamily: "'Montserrat', Helvetica",
              fontWeight: "600",
              minHeight: "40px",
            }}
          >
            Submit
          </button>
        </div>

        <div style={{ height: "10px" }}></div>
      </div>
    );
}

export default Cashpayment;
