import React, { useState, useEffect, useLayoutEffect } from "react";
import "./teacher_classroom_style.css";
import pic_2 from "../../student/dashboard/img/pic_2.png";

import { Link, NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { MdSchool } from "react-icons/md";
import { SiGoogleclassroom } from "react-icons/si";
import vector_2x from "./img/vector@2x.svg";
import ellipse_4_7_2x from "./img/ellipse-4-7@2x.svg";
import vector_2xp from "./img/vector@2x.png";
import vector_40_2x from "./img/vector-40@2x.svg";
import ellipse_4_8_2x from "./img/ellipse-4-8@2x.svg";
import vector_42_2x from "./img/vector-42@2x.svg";
import ellipse_4_9_2x from "./img/ellipse-4-9@2x.svg";
import vector_44_2x from "./img/vector-44@2x.svg";
import ellipse_4_10_2x from "./img/ellipse-4-10@2x.svg";
import vector_46_2x from "./img/vector-46@2x.svg";
import Popup from "../popup/popup";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteTeacher } from "../../../redux/actions/teacher";
import SideDrawer from "../../../UI/sideDrawer/sideDrawer";

import { teacherClassrooms, teacherStudents } from "../../../crud/teacher.crud";
import CalenderStatus from "../dashboard/CalenderComponent/calanderComponent";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SchoolIcon from "@material-ui/icons/School";
import DescriptionIcon from "@material-ui/icons/Description";
import HelpIcon from "@material-ui/icons/Help";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import NotesIcon from "@material-ui/icons/Notes";

function Teacherqiuzstudents(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [students, setStudents] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const user = useSelector((state) => state.teacherDetails.teacher);

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteTeacher(reduxUser.teacher_id));
    removeToken();
  };

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  useEffect(() => {
    teacherStudents(user.teacher_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setStudents(false);
        } else setStudents(res);
      })
      .catch((err) => console.log(err));

    teacherClassrooms(user.teacher_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setClassrooms(false);
        } else setClassrooms(res);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="teacher-qiuz-students"
      />
      <div className="container-center-horizontal">
        <div className="teacher-qiuz-students screen">
          <div className="sider-teacher-general-div">
            <div className="sider-teacher-in-general-80">
              <div style={{ marginTop: "120px" }} />
              <NavLink to="/teacher-dashboard" exact>
                <DashboardIcon
                  data-tip="Dashboard"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-date-calendar" exact>
                <EventIcon data-tip="Scheduler" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-previous-classes">
                <ScheduleIcon
                  data-tip="Previous Class"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-students">
                <SchoolIcon
                  data-tip="Enrolled Student"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-curriculum-list">
                <DescriptionIcon
                  data-tip="Curriculum"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-doubt-list">
                <HelpIcon data-tip="Doubts" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-analytics">
                <AssessmentIcon
                  data-tip="Analytics"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-assignments">
                <AssignmentIcon
                  data-tip="Assignments"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-quizzes">
                <LibraryBooksIcon
                  data-tip="Quizzes"
                  className="icon-in-hc-color-2"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-lecture-notes">
                <NotesIcon
                  data-tip="Lecture Notes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              {/* <NavLink to="/teacher-payments"><AccountBalanceIcon data-tip="Payment" className="icon-in-hc-color" /><ReactTooltip /></NavLink> */}
              <div style={{ marginTop: "10px" }} />
              <NavLink to="/" onClick={() => handleLogout()}>
                {" "}
                <PowerSettingsNewIcon
                  data-tip="Logout"
                  className="icon-in-hc-color"
                />{" "}
              </NavLink>
              <div style={{ marginTop: "1px" }} />
            </div>
          </div>
          <div className="flex-col-C61RwL">
            <img
              alt="image"
              src={pic_2}
              style={{ height: "115px", marginTop: "-6%", marginLeft: "-78%" }}
            />

            <div className="my-quizzes-0xy0vn">
              <span className="span0-qoX1og">My</span>
              <span className="span1-qoX1og"> Quizzes</span>
            </div>
            <div className="choose-a-studen-ssign-a-new-one-0xy0vn">
              Choose a student to view their quizzes or assign a new one
            </div>
            <div className="group-174-0xy0vn">
              <div className="students-list">
                {students.length > 0 ? (
                  students.map((student) => {
                    return (
                      <div className="overlap-group1-Y9c9n6-001">
                        <Link
                          to={
                            "/teacher-quiz-student-list/" +
                            student.enrolled_student_id
                          }
                        >
                          <div className="rectangle-40-K97w2w">
                            <div className="group-167-K97w2w">
                              <div className="component-29-6bMYwa"></div>
                              <div className="vector-6bMYwa">
                                <MdSchool size={24} color="white" />
                              </div>
                            </div>
                            <div className="studTile-col">
                              <div className="vatsal-agarwal-K97w2w montserrat-semi-bold-blue-zodiac-14px">
                                {student.first_name + " " + student.last_name}
                              </div>
                              <div className="mathematics-class-9-K97w2w montserrat-medium-gray-10px">
                                Mathematics - Class 9
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className=".montserrat-semi-bold-blue-zodiac-14px"
                    style={{ color: "black" }}
                  >
                    You have no enrolled students.
                  </div>
                )}
              </div>
            </div>
            <div className="choose-a-classr-ssign-a-new-one-0xy0vn">
              Choose a classroom to view their quizzes or assign a new one
            </div>
            <div className="overlap-group-0xy0vn">
              <div className="classrooms-list">
                {classrooms.length > 0 ? (
                  classrooms.map((classroom) => {
                    return (
                      <Link
                        to={
                          "/teacher-quizzes-classroom-list/" +
                          classroom.classroomID
                        }
                      >
                        <div className="group-116-huXul0">
                          <div className="rectangle-40-xlTRPb">
                            <div className="group-179-y1HRxC">
                              <div className="group-169-6z1aPT">
                                <div className="component-29-MWA2s1">
                                  <div className="vector-SzWn3A">
                                    <SiGoogleclassroom
                                      size={21}
                                      color="white"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="classTile-col">
                              <div
                                className="mathematics-class-9-xlTRPb montserrat-semi-bold-blue-zodiac-14px"
                                style={{ width: "100%" }}
                              >
                                {classroom.classroom_name +
                                  " - CLASS " +
                                  classroom.gradeID}
                              </div>
                              <div className="class-strength-20-xlTRPb montserrat-medium-gray-10px">
                                Class Strength - {classroom.classroom_strength}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })
                ) : (
                  <div
                    className="montserrat-semi-bold-blue-zodiac-14px"
                    style={{ color: "black" }}
                  >
                    You have no classrooms.
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex-col-VMr6Om">
            <div className="group-115-lcFreP">
              <div className="group-22-cLbj0f">
                <div className="flex-col-0sTO9E">
                  <div className="biba-sobti-JJC5aN">
                    {user.first_name + " " + user.last_name}
                  </div>
                  <div className="bibasobtigmailcom-JJC5aN">{user.emailID}</div>
                </div>
                <img className="vector-0sTO9E" src={vector_2x} />
              </div>
            </div>
            <div className="group-114-lcFreP">
              <CalenderStatus />
            </div>
          </div>
        </div>
      </div>
      <div className="container-center-horizontal">
        <div className="teacher-quiz-student-list-mobile screen">
          <div className="flex-col-C61RwL">
            <div className="group-72-0xy0vn">
              <div className="rectangle-40-MiJunx"></div>
              <div className="component-29-MiJunx">
                <img className="ellipse-4-UEWQ0j" src={ellipse_4_7_2x} />
                <div className="assignment-24px-3-UEWQ0j">
                  <img className="vector-RChgoP" src={vector_2xp} />
                  <img className="vector-CcJI6B" src={vector_40_2x} />
                </div>
              </div>
              <div className="assignment-1-MiJunx montserrat-semi-bold-blue-zodiac-10px">
                ASSIGNMENT 1
              </div>
              <div className="mathematics-dr-yashna-sharma-MiJunx montserrat-medium-gray-7px">
                Mathematics (Dr. Yashna Sharma)
              </div>
              <div className="submitted-MiJunx">Submitted</div>
            </div>
            <div className="group-73-0xy0vn">
              <div className="rectangle-40-7iGIN1"></div>
              <div className="component-29-7iGIN1">
                <img className="ellipse-4-1VRSUu" src={ellipse_4_8_2x} />
                <div className="assignment-24px-3-1VRSUu">
                  <img className="vector-Vn3aY5" src={vector_2xp} />
                  <img className="vector-lP2eQk" src={vector_42_2x} />
                </div>
              </div>
              <div className="assignment-1-7iGIN1 montserrat-semi-bold-blue-zodiac-10px">
                ASSIGNMENT 1
              </div>
              <div className="mathematics-dr-yashna-sharma-7iGIN1 montserrat-medium-gray-7px">
                Mathematics (Dr. Yashna Sharma)
              </div>
              <div className="submitted-7iGIN1">Submitted</div>
            </div>
            <div className="group-74-0xy0vn">
              <div className="rectangle-40-zHT0nt"></div>
              <div className="component-29-zHT0nt">
                <img className="ellipse-4-uiXjoi" src={ellipse_4_9_2x} />
                <div className="assignment-24px-3-uiXjoi">
                  <img className="vector-7YVAkz" src={vector_2xp} />
                  <img className="vector-zLTigN" src={vector_44_2x} />
                </div>
              </div>
              <div className="assignment-1-zHT0nt montserrat-semi-bold-blue-zodiac-10px">
                ASSIGNMENT 1
              </div>
              <div className="mathematics-dr-yashna-sharma-zHT0nt montserrat-medium-gray-7px">
                Mathematics (Dr. Yashna Sharma)
              </div>
              <div className="submitted-zHT0nt">Submitted</div>
            </div>
            <div className="group-75-0xy0vn">
              <div className="rectangle-40-FVieMB"></div>
              <div className="component-29-FVieMB">
                <img className="ellipse-4-poPk0w" src={ellipse_4_10_2x} />
                <div className="assignment-24px-3-poPk0w">
                  <img className="vector-f2PIuq" src={vector_2xp} />
                  <img className="vector-hEE9Rr" src={vector_46_2x} />
                </div>
              </div>
              <div className="assignment-1-FVieMB montserrat-semi-bold-blue-zodiac-10px">
                ASSIGNMENT 1
              </div>
              <div className="mathematics-dr-yashna-sharma-FVieMB montserrat-medium-gray-7px">
                Mathematics (Dr. Yashna Sharma)
              </div>
              <div className="submitted-FVieMB">Submitted</div>
            </div>
          </div>
          <div className="flex-col-VMr6Om">
            <div className="flex-row-lcFreP">
              <div className="flex-col-NvvWoh">
                <div className="component-1-PNnH4c">
                  <img
                    alt="image"
                    src={pic_2}
                    style={{ height: "90px", marginTop: "-8%" }}
                  />
                </div>
                <div className="my-quiz-PNnH4c">
                  <span className="span0-V08QdX">My </span>
                  <span className="span1-V08QdX">Quiz</span>
                </div>
              </div>

              {/* This is the Navbar */}
              <SideDrawer
                open={displaySideDrawer}
                closeSideDrawer={sideDrawerToggler}
              />
              <div className="component-6-NvvWoh" onClick={sideDrawerToggler}>
                <div className="rectangle-48-MRvDS0"></div>
                <div className="rectangle-49-MRvDS0"></div>
                <div className="rectangle-50-MRvDS0"></div>
              </div>
            </div>
            <div className="choose-a-studen-ssign-a-new-one-lcFreP">
              Choose a student to view their quizes or assign a new one
            </div>
            <div className="group-186-lcFreP">
              <div className="group-138-0Wopvi">
                {students.length > 0 ? (
                  students.map((student) => {
                    return (
                      <Link
                        to={
                          "/teacher-quiz-student-list/" +
                          student.enrolled_student_id
                        }
                      >
                        <div className="rectangle-40-pckt8m-10-1101">
                          <div className="component-29-4Baocj-10-11">
                            <MdSchool size={24} color="white" />
                          </div>

                          <div className="classTile-col">
                            <div className="mathematics-class-9-pckt8m-ii-i montserrat-semi-bold-blue-zodiac-14px">
                              {student.first_name}
                            </div>
                            <div className="class-strength-20-pckt8m montserrat-medium-gray-10px">
                              {student.last_name}
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })
                ) : (
                  <div
                    className="montserrat-semi-bold-blue-zodiac-14px"
                    style={{ color: "black" }}
                  >
                    You have no enrolled students.
                  </div>
                )}
              </div>
              {/* <div className="group-167-0Wopvi">
                <div className="component-29-ukHLxf"></div>
                <div className="vector-ukHLxf">
                  <MdSchool size={22} color="white" />
                </div>
              </div> */}
            </div>
            <div className="choose-a-classr-ssign-a-new-one-lcFreP">
              Choose a classroom to view their quizes or assign a new one
            </div>
            <div className="group-187-lcFreP">
              <div className="group-139-BhPClO">
                {classrooms.length > 0 ? (
                  classrooms.map((classroom) => {
                    return (
                      <Link
                        to={
                          "/teacher-quizzes-classroom-list/" +
                          classroom.classroomID
                        }
                      >
                        <div className="rectangle-40-pckt8m-10-1101">
                          <div className="component-29-4Baocj-10-11">
                            <SiGoogleclassroom size={22} color="white" />
                          </div>

                          <div className="classTile-col">
                            <div className="mathematics-class-9-pckt8m-ii-i montserrat-semi-bold-blue-zodiac-14px">
                              {classroom.classroom_name +
                                " - CLASS " +
                                classroom.gradeID}
                            </div>
                            <div className="class-strength-20-pckt8m montserrat-medium-gray-10px">
                              Class Strength - {classroom.classroom_strength}
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })
                ) : (
                  <div
                    className="montserrat-semi-bold-blue-zodiac-14px"
                    style={{ color: "black" }}
                  >
                    You have no classrooms.
                  </div>
                )}
                {/* <div className="group-137-2wAK2u">
                  <div className="rectangle-40-9VsX3a"></div>
                  <div className="physics-class-9-9VsX3a montserrat-semi-bold-blue-zodiac-12px">
                    PHYSICS - CLASS 9
                  </div>
                  <div className="class-strength-8-9VsX3a montserrat-medium-gray-9px">
                    Class Strength - 8
                  </div>
                </div> */}
              </div>
            </div>
            <div className="flex-row-SXcK7r">
              <CalenderStatus />
            </div>
          </div>
        </div>
      </div>
      <Popup />
    </>
  );
}

function Group166(props) {
  const { group166, vector, className } = props;

  return (
    <div
      className={`group-166 ${className || ""}`}
      style={{ backgroundImage: `url(${vector})` }}
    >
      <img className="vector-6" src={group166} />
    </div>
  );
}

function Group47(props) {
  const { kunalKashyap, text3, className } = props;

  return (
    <div className={`group-47 ${className || ""}`}>
      <div className="overlap-group-3">
        <div className="kunal-kashyap montserrat-semi-bold-blue-zodiac-14px">
          {kunalKashyap}
        </div>
        <div className="text-3 montserrat-medium-gray-10px">{text3}</div>
      </div>
    </div>
  );
}

function Group179(props) {
  const { group179, overlapGroup1, vector, className } = props;

  return (
    <div
      className={`group-179 ${className || ""}`}
      style={{ backgroundImage: `url(${group179})` }}
    >
      <div className="castforeducation-24px-1">
        <div
          className="overlap-group1-2"
          style={{ backgroundImage: `url(${overlapGroup1})` }}
        >
          <img className="vector-7" src={vector} />
        </div>
      </div>
    </div>
  );
}

function Group92(props) {
  const { x4Pm, opened, className } = props;

  return (
    <div className={`group-92 ${className || ""}`}>
      <div className="overlap-group-4">
        <div className="x4pm roboto-medium-blue-zodiac-14px">{x4Pm}</div>
        <div className="opened-1 roboto-medium-niagara-14px">{opened}</div>
      </div>
    </div>
  );
}
const group166Data = {
  group166:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058910c5433a9ddabee45c1/img/ellipse-4@2x.png",
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598e31b90976028c50cd54/img/vector-3@2x.png",
};

const group1662Data = {
  group166:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058910c5433a9ddabee45c1/img/ellipse-4@2x.png",
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598e31b90976028c50cd54/img/vector-3@2x.png",
};

const group47Data = {
  kunalKashyap: "KUNAL KASHYAP",
  text3: "Mathematics - Class 9",
};

const group1663Data = {
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058910c5433a9ddabee45c1/img/ellipse-4@2x.png",
  group166:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598e31b90976028c50cd54/img/vector-3@2x.png",
};

const group472Data = {
  kunalKashyap: "SHREYANS JAIN",
  text3: "Mathematics - Class 10",
};

const group1664Data = {
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058910c5433a9ddabee45c1/img/ellipse-4@2x.png",
  group166:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598e31b90976028c50cd54/img/vector-3@2x.png",
};

const group473Data = {
  kunalKashyap: "MATHEMATICS - CLASS 9",
  text3: "Class Strength - 20",
};

const group474Data = {
  kunalKashyap: "MATHEMATICS - CLASS 10",
  text3: "Class Strength - 14",
};

const group475Data = {
  kunalKashyap: "PHYSICS - CLASS 9",
  text3: "Class Strength - 5",
};

const group179Data = {
  group179:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058910c5433a9ddabee45c1/img/ellipse-4@2x.png",
  overlapGroup1:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598fcf4e78cf803e37e567/img/vector-33@2x.png",
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
};

const group1792Data = {
  group179:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058910c5433a9ddabee45c1/img/ellipse-4@2x.png",
  overlapGroup1:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598fcf4e78cf803e37e567/img/vector-33@2x.png",
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
};

const group1793Data = {
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058910c5433a9ddabee45c1/img/ellipse-4@2x.png",
  overlapGroup1:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598fcf4e78cf803e37e567/img/vector-33@2x.png",
  group179:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
};

const group92Data = {
  x4Pm: "4pm",
  opened: "Opened",
};

const group922Data = {
  x4Pm: "6pm",
  opened: "Opened",
};

const group923Data = {
  x4Pm: "7pm",
  opened: "Opened",
};

const group924Data = {
  x4Pm: "8pm",
  opened: "Opened",
};

const group925Data = {
  x4Pm: "9pm",
  opened: "Opened",
};

const TeacherqiuzstudentsData = {
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-35@2x.png",
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-453@2x.png",
  vector3:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector4:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-153@2x.png",
  vector5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058d518334660f0a4896cec/img/vector-193@2x.png",
  vector7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector8:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-226@2x.png",
  vector9:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector10:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-228@2x.png",
  vector11:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector12:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-237@2x.png",
  vector13:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector14:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-230@2x.png",
  vector15:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector16:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-241@2x.png",
  vector17:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-242@2x.png",
  vector18:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector19:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-166@2x.png",
  vector20:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-162@2x.png",
  vector21:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-163@2x.png",
  vector22:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-162@2x.png",
  vector23:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-164@2x.png",
  vector24:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-33@2x.png",
  vector25:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058d05e3f36904103fcf918/img/vector-208@2x.png",
  vector26:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058d05e3f36904103fcf918/img/vector-209@2x.png",
  union:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598fcf4e78cf803e37e567/img/union@2x.png",
  spanText: "My",
  spanText2: " Quizzes",
  text1: "Choose a student to view their quizzes or assign a new one",
  vatsalAgarwal: "VATSAL AGARWAL",
  text2: "Mathematics - Class 9",
  text5: "Choose a classroom to view their quizzes or assign a new one",
  rectangle7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/rectangle-7-5@1x.png",
  vector27:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector28:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-38@2x.png",
  ellipse2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/ellipse-2@2x.png",
  bibaSobti: "Biba Sobti",
  bibasobtiGmailCom: "bibasobti@gmail.com",
  vector29:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-36@2x.png",
  openCalendar: "Open Calendar",
  date: "Feb 18, 2021",
  time: "Time",
  className: "Class Name",
  status: "Status",
  addDelete: "Add/Delete",
  price: "12pm",
  organic4: "Organic-4",
  completed: "Completed",
  booked: "Booked",
  x1Pm: "1pm",
  x2Pm: "2pm",
  opened: "Opened",
  add: "Add",
  x5Pm: "5pm",
  add2: "Add",
  x3Pm: "3pm",
  organic5: "Organic-5",
  upcoming: "Upcoming",
  booked2: "Booked",
  price2: "10pm",
  opened2: "Opened",
  group166Props: group166Data,
  group1662Props: group1662Data,
  group47Props: group47Data,
  group1663Props: group1663Data,
  group472Props: group472Data,
  group1664Props: group1664Data,
  group473Props: group473Data,
  group474Props: group474Data,
  group475Props: group475Data,
  group179Props: group179Data,
  group1792Props: group1792Data,
  group1793Props: group1793Data,
  group92Props: group92Data,
  group922Props: group922Data,
  group923Props: group923Data,
  group924Props: group924Data,
  group925Props: group925Data,
};

export default Teacherqiuzstudents;
