import React, { useState, useEffect, useLayoutEffect } from "react";
import { Select, Button, Dropdown, Input } from 'semantic-ui-react';
import "./style.css";
import { Link, useHistory, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import line_5_2x from "./img/line-5@2x.svg";
import ellipse_2_1_2x from "./img/ellipse-2-1@2x.svg";
import line_6_2x from "./img/line-6@2x.svg";
import moment from "moment";
import { FaRegQuestionCircle, FaChartPie, FaStickyNote, FaPowerOff } from "react-icons/fa";
import { MdDashboard, MdNotificationsNone, MdSchool } from "react-icons/md";
import { BiHistory } from "react-icons/bi";
import { AiTwotoneBank } from "react-icons/ai";
import ellipse_2_2x from "./img/ellipse-2@2x.svg";
import ellipse_3_2x from "./img/ellipse-3@2x.svg";
import line_5_1_2x from "./img/line-5-1@2x.svg";
import line_6_1_2x from "./img/line-6-1@2x.svg";
import vector_2x from "./img/vector@2x.svg";
import ellipse_2_2_2x from "./img/ellipse-2-2@2x.svg";
import ellipse_3_1_2x from "./img/ellipse-3-1@2x.svg";
import vector_3_2x from "./img/vector-3@2x.svg";
import vector_4_2x from "./img/vector-4@2x.svg";
import rectangle_7_1x from "./img/rectangle-7@1x.png";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
//color="rgba(179,179,179,1.0)"
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from '../../../utils';
import { deleteRm } from '../../../redux/actions/rm';
import Popup from '../../teacher/popup/popup';
import SideDrawerRm from '../../../UI/sideDrawer/sideDrawerRm';
import RmIssueList from '../table_dash/rm_table';
import pic_2 from "../../student/dashboard/img/pic_2.png";


function Relationalmanagerdatecalendar(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  const { userId } = useParams();
  const user = useSelector((state) => state.rmDetails.rm);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [selDate, setSelDate] = useState("");
  // const [userId, setUserId] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  const reduxUser = useSelector((state) => state.rmDetails.rm);
  const handleLogout = () => {
    dispatch(deleteRm(reduxUser.rm_id))
    removeToken();
  }

  const sideDrawerToggler = () => {
    setDisplaySideDrawer(previousState => !previousState);
  };

  const onClickDay = (value, event) => {
    setSelDate(moment(value).format("YYYY-MM-DD"));
  }

  const handleSubmit = () => {
    history.push(`/rm-time-calendar/${selDate}/${userId}`);
  }

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="relational-manager-date-calendar"
      />
      <div className="container-center-horizontal">
        <div className="relational-manager-date-calendar-mobile screen">
          <div className="flex-row-C61RwL">
            <div className="component-1-dXBp89">
              <img src={pic_2} style={{ height: "100px", margin: "-8%" }} />
            </div>

            <SideDrawerRm
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />
            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>
          {/* <div className="group-87-C61RwL">
            <div className="component-7-D1n0rW">
              <div className="x4-i636292053103-j2Gv3j"></div>
            </div>
            <div className="teacher-name-D1n0rW montserrat-medium-blue-zodiac-20px">
              Teacher Name
            </div>
            <div className="course-D1n0rW montserrat-medium-blue-zodiac-20px">
              Course
            </div>
            <div className="subject-D1n0rW montserrat-medium-blue-zodiac-20px">
              Subject
            </div>
            <div className="grade-D1n0rW montserrat-medium-blue-zodiac-20px">
              Grade
            </div>
          </div> */}
          {/* <div className="flex-row-VMr6Om">
            <div className="component-4-BdpSxK">
              <div className="sections-teachi-g-i636291753102-fVQN4V">
                Total
                <br />
                Classes
              </div>
              <div className="x4-i636291753103-fVQN4V">23</div>
            </div>
            <div className="component-5-BdpSxK">
              <div className="sections-teachi-g-i636291853102-PBxgdW">
                Completed <br />
                Classes
              </div>
              <div className="x4-i636291853103-PBxgdW">21</div>
            </div>
          </div> */}
          <div className="choose-date-for-booking-C61RwL montserrat-medium-blue-zodiac-20px">
            Choose date for booking
          </div>
          <div className="flex-row-mzXdH9">
            <div className="feb-2021-NwNIwt">
              <span className="span0-z5xbcu">Feb </span>
              <span className="span1-z5xbcu">2021</span>
            </div>
            <div className="next-month-NwNIwt">Next month</div>
          </div>
          <div className="overlap-group-C61RwL">
            <div className="x12-4eduM0 montserrat-medium-blue-zodiac-16px">
              12
            </div>
            <div className="group-88-4eduM0">
              <Calendar onClickDay={onClickDay} />
              <Input
                disabled
                value={userId}
                placeholder="Enter Student/Teacher ID"
                style={{ width: "74%", top: "5px" }}
              />
              <Button
                color="green"
                onClick={() => handleSubmit()}
                style={{
                  width: "25%",
                  alignSelf: "center",
                  padding: "0.785714em 0.5em 1.5714em",
                  height: "2em",
                  margin: "14px 7.25em -1px -4px",
                }}
              >
                Submit
              </Button>
            </div>
            <div className="group-210-4eduM0">
              <RmIssueList userState={user} />
            </div>
          </div>
        </div>
      </div>
      <div className="container-center-horizontal">
        <div className="relational-manager-date-calendar screen">
          <div className="group-209-C61RwL-10-1">
            <Link to="/rm-dashboard">
              <MdDashboard
                size={24}
                data-tip="Dashboard"
                color="rgba(179,179,179,1.0)"
              />
              <ReactTooltip />
            </Link>
            <div className="historytoggleoff-24px-1-D0BVcB">
              <Link to="/rm-previous-classes">
                {" "}
                <BiHistory
                  data-tip="Previous Classes"
                  size={24}
                  color="white"
                />
                <ReactTooltip />
              </Link>
            </div>
            <div className="school-24px-1-D0BVcB">
              <Link to="/rm-list">
                <MdSchool
                  data-tip="My Students"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
            <div className="help-24px-2-D0BVcB">
              <Link to="/rm-issue-list">
                <FaRegQuestionCircle
                  data-tip="Doubts"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
            <div className="analytics-24px-4-D0BVcB">
              <Link to="/rm-statistics">
                <FaChartPie
                  data-tip="Analytics"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
            {/* <div className="accountbalance-24px-1-D0BVcB">
              <Link to="/rm-payments"><AiTwotoneBank data-tip="Payments" size={24} color="rgba(179,179,179,1.0)" /><ReactTooltip /></Link>
            </div> */}
            {/* <FaPowerOff size={24} color="rgba(179,179,179,1.0)"/> */}
            <div className="vector-Ayxm2x-01-1">
              <Link to="/" onClick={() => handleLogout()}>
                <FaPowerOff
                  data-tip="Logout"
                  size={24}
                  color="rgba(179,179,179,1.0)"
                />
                <ReactTooltip />
              </Link>
            </div>
          </div>
          <div className="flex-col-C61RwL-10-1">
            <div className="flex-row-0xy0vn">
              <div className="component-1-GyJUNH">
                <img src={pic_2} style={{ height: "100px" }} />
              </div>
              <div className="notifications-24px-2-1-GyJUNH">
                <div className="vector-40xw7g">
                  <MdNotificationsNone size={28} />
                </div>
                <img className="ellipse-2-RYyMFx" src={ellipse_2_1_2x} />
              </div>
              <div className="group-22-GyJUNH">
                <div className="flex-col-vgli2x">
                  <div className="biba-sobti-h8V8ZR">
                    {reduxUser &&
                      reduxUser.first_name + " " + reduxUser.last_name}
                  </div>
                  <div className="bibasobtigmailcom-h8V8ZR">
                    {reduxUser && reduxUser.emailID}
                  </div>
                </div>
                <img className="vector-vgli2x" src={vector_2x} />
              </div>
            </div>
            <div className="flex-row-oV5UrE">
              <div className="flex-col-QkTteQ">
                <div className="flex-row-Fjgz1C">
                  {/* <div className="component-4-A6SpMB">
                    <div className="sections-teachi-g-i636278553102-Xri3Jx">
                      Total
                      <br />
                      Classes
                    </div>
                    <div className="x4-i636278553103-Xri3Jx">23</div>
                  </div> */}
                  {/* <div className="overlap-group1-A6SpMB">
                    <div className="component-6-fhQzNT">
                      <div className="x4-i636278653103-hPEL6O"></div>
                    </div>
                    <div className="teacher-name-fhQzNT montserrat-medium-blue-zodiac-24px">
                      Teacher Name
                    </div>
                    <div className="course-fhQzNT montserrat-medium-blue-zodiac-24px">
                      Course
                    </div>
                    <div className="subject-fhQzNT montserrat-medium-blue-zodiac-24px">
                      Subject
                    </div>
                    <div className="grade-fhQzNT montserrat-medium-blue-zodiac-24px">
                      Grade
                    </div>
                  </div> */}
                  <div className="component-5-A6SpMB">
                    <div className="sections-teachi-g-i636278753102-nTYimd">
                      Completed <br />
                      Classes
                    </div>
                    <div className="x4-i636278753103-nTYimd">21</div>
                  </div>
                </div>
                <div className="choose-date-for-booking-Fjgz1C">
                  Choose Date For Edit
                </div>
                <div className="overlap-group-Fjgz1C">
                  <img className="rectangle-7-PXwdVf" src={rectangle_7_1x} />
                  <div className="group-23-PXwdVf">
                    <Calendar onClickDay={onClickDay} />
                    <Input
                      disabled
                      value={userId}
                      placeholder="Enter Student/Teacher ID"
                    />
                    <Button
                      color="green"
                      onClick={() => handleSubmit()}
                      style={{ width: "25%", alignSelf: "center" }}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
              <div className="group-202-QkTteQ">
                <RmIssueList userState={user} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup />
    </>
  );
}

export default Relationalmanagerdatecalendar;