import React, { useState } from "react";
import "./guideModal.css";
import BackDrop from "../../UI/Backdrop/Backdrop";
// import { Form, Message } from "semantic-ui-react";
import guideImg from "../../guide.png";

const GuideModal = (props) => {
  let attclasses = ["login-modal-2-01_33", "Close-Landing"];

  if (props.isModalOpen) {
    attclasses = ["login-modal-2-01_33", "Open-Landing"];
  }

  return (
    <div className="login_modal_layout_100">
      <BackDrop open={props.isModalOpen} clicked={props.closeModal} />
      <div
        // isOpen={isModalOpen}
        // onRequestClose={closeModal}
        className={attclasses.join(" ")}
      >
        <div className="login_guide-div">
          <img src={guideImg} width="100%" style={{ borderRadius: "10px" }} />
        </div>
      </div>
    </div>
  );
};

export default GuideModal;
