import React, { useState, useEffect, useLayoutEffect } from "react";
import "./style.css";
import logo from "./img/vector-8@2x.png";
import image from "./img/vector-3@2x.svg";
import { ticketDetail } from "../../../crud/highercommittee.crud";
import PowerSettingsToneIcon from "@material-ui/icons/PowerSettingsNew";
import { Link, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { GiRelationshipBounds } from "react-icons/gi";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { removeToken } from "../../../utils";
import ClassSharpIcon from "@material-ui/icons/ClassSharp";
import { deleteHc } from "../../../redux/actions/hc";
import pic_2 from "../../student/dashboard/img/pic_2.png";
import {
  MdAssignment,
  MdDashboard,
  MdNotificationsNone,
  MdSchool,
} from "react-icons/md";
import { FaRegQuestionCircle, FaChartPie, FaPowerOff } from "react-icons/fa";
import { AiTwotoneBank } from "react-icons/ai";
import MenuIcon from "@material-ui/icons/Menu";
import SideDrawerHc from "../../../UI/sideDrawer/sideDrawerHc";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import Popup from "../../teacher/popup/popup";
import { resolveTicket } from "../../../crud/rm.crud";

function Index(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { ticketID } = useParams();
  const [curr_ticket, setTicketDetail] = useState({});
  const user = useSelector((state) => state.hcDetails.hc);
  const [statusChange, setStatusChange] = useState(false);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(deleteHc(user.hc_id));
    removeToken();
  };
  useEffect(() => {
    ticketDetail(ticketID)
      .then((res) => {
        setTicketDetail(res);
        console.log(res);
      })
      .catch((err) => console.log(err));
  }, []);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };
  return (
    <div>
      <div className="navbar2">
        <div className="sidenav">
          <div className="sidelink">
            <Link to="/hc-dashboard">
              <MdDashboard
                data-tip="Dashboard"
                size={24}
                color="rgba(179,179,179,1.0)"
              />
              <ReactTooltip />
            </Link>
          </div>
          <div className="sidelink">
            <Link to="/hc-teachers-list">
              {" "}
              <MdSchool
                data-tip="Teacher"
                size={24}
                color="rgba(179,179,179,1.0)"
              />
              <ReactTooltip />
            </Link>
          </div>
          <div className="sidelink">
            <Link to="/hc-suggest-rm">
              <SupervisorAccountIcon
                data-tip="Relational Manager"
                size={24}
                style={{ color: "rgba(179,179,179,1.0)" }}
              />
              <ReactTooltip />
            </Link>
          </div>
          <div className="sidelink">
            <Link to="/hc-ticket-list">
              <MdAssignment
                data-tip="Ticket List"
                size={24}
                color="rgba(179,179,179,1.0)"
              />
              <ReactTooltip />
            </Link>
          </div>
          <div className="sidelink">
            <Link to="/hc-payments">
              <AiTwotoneBank
                data-tip="Payments"
                size={24}
                color="rgba(179,179,179,1.0)"
              />
              <ReactTooltip />
            </Link>
          </div>
          <div className="sidelink">
            <Link to="/hc-classroom">
              <ClassSharpIcon
                data-tip="Classroom"
                size={24}
                color="rgba(179,179,179,1.0)"
              />
              <ReactTooltip />
            </Link>
          </div>
          <div className="sidelink">
            <Link to="/" onClick={() => handleLogout()}>
              <PowerSettingsToneIcon
                data-tip="Logout"
                size={24}
                style={{ color: "rgba(179,179,179,1.0)" }}
              />
              <ReactTooltip />
            </Link>
          </div>
        </div>
      </div>

      <header>
        <div className="head">
          <img
            alt="image"
            src={pic_2}
            style={{ height: "90px" }}
          />
          <SideDrawerHc
            open={displaySideDrawer}
            closeSideDrawer={sideDrawerToggler}
          />

          <span
            className="fa fa-bars mob_nav"
            onClick={sideDrawerToggler}
          ></span>
          <img
            className="mobile_nav"
            style={{ float: "right", margin: "0 0 0 20px" }}
            src={image}
            alt="profile"
          />
          <div
            className="mobile_nav"
            style={{ float: "right", textAlign: "right" }}
          >
            <div className="biba-sobti-h8V8ZR">
              {user && user.first_name} {user && user.last_name}
            </div>
            <div className="bibasobtigmailcom-h8V8ZR">{user && user.email}</div>
          </div>
          <div
            className="mobile_nav"
            style={{
              float: "right",
              marginRight: "40px",
              marginTop: "15px",
              cursor: "pointer",
            }}
          >
            <MdNotificationsNone size={32} />
          </div>
        </div>
      </header>

      <section className="main">
        <div className="card">
          <form action="">
            <div className="row">
              <div className="col-lg-4">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">TicketID </span>
                  </div>
                  {/* <input type="text" className="form-control" /> */}
                  <span className="form-control">
                    {curr_ticket && curr_ticket.ticket && curr_ticket.ticket.id}{" "}
                  </span>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">RaisedBy </span>
                  </div>
                  <span className="form-control">
                    {curr_ticket &&
                      curr_ticket.ticket &&
                      curr_ticket.ticket.raised_by}{" "}
                  </span>
                  {/* <input type="text" className="form-control" /> */}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Category </span>
                  </div>
                  <span className="form-control">
                    {curr_ticket &&
                      curr_ticket.ticket &&
                      curr_ticket.ticket.category}{" "}
                  </span>
                  {/* <input type="text" className="form-control" /> */}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Date </span>
                  </div>
                  <span className="form-control">
                    {curr_ticket &&
                      curr_ticket.ticket &&
                      curr_ticket.ticket.date_raised}{" "}
                  </span>
                  {/* <input type="text" className="form-control" /> */}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Status </span>
                  </div>
                  <span className="form-control">
                    {curr_ticket &&
                      curr_ticket.ticket &&
                      curr_ticket.ticket.ticket_status}{" "}
                  </span>
                  {/* <input type="text" className="form-control" /> */}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Contact No </span>
                  </div>
                  <span className="form-control">
                    {curr_ticket &&
                      curr_ticket.employee &&
                      curr_ticket.employee.contact_number}{" "}
                  </span>
                  {/* <input type="text" className="form-control" /> */}
                </div>
              </div>
            </div>

            <div style={{ margin: "40px 0" }}>
              <h2 className="mb-3 text">Issue</h2>
              <textarea
                disabled
                className="form-control mb-3"
                rows="10"
                value={
                  curr_ticket &&
                  curr_ticket.issue &&
                  curr_ticket.issue[0].issue_text
                }
              ></textarea>
            </div>

            <div style={{ marginBottom: "40px" }}>
              <h2 className="mb-3 text">Comments</h2>
              <textarea
                disabled
                rows="10"
                className="form-control mb-3"
                value={
                  curr_ticket &&
                  curr_ticket.ticket &&
                  curr_ticket.ticket.comments
                }
              ></textarea>
            </div>

            <div className="submit">
              <Button
                disabled={
                  curr_ticket.ticket &&
                  curr_ticket.ticket.ticket_status === "Resolved"
                }
                className="btn btn-danger"
                variant={
                  curr_ticket.ticket &&
                  curr_ticket.ticket.ticket_status === "Resolved"
                    ? "success"
                    : "outline-success"
                }
                onClick={() => {
                  resolveTicket({
                    ...curr_ticket.ticket,
                    ticket_status: "Resolved",
                  });
                  setStatusChange(!statusChange);
                }}
              >
                {curr_ticket.ticket &&
                curr_ticket.ticket.ticket_status === "Resolved"
                  ? "Resolved"
                  : "Resolve"}
              </Button>
            </div>
          </form>
        </div>
      </section>

      <div style={{ marginTop: "50px" }}>.</div>
      {/* <Popup /> */}
    </div>
  );
}

export default Index;
