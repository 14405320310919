import React, { useState, useEffect, useLayoutEffect} from "react";
import "./form1.css";
import logo from "./img/vector-8@2x.png";
import image from "./img/vector-3@2x.svg";
import { AiTwotoneBank } from "react-icons/ai";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ClassSharpIcon from "@material-ui/icons/ClassSharp";
import ReactTooltip from "react-tooltip";
import ReactStars from "react-rating-stars-component";
import pic_2 from "../../student/dashboard/img/pic_2.png";
import {
  MdAssignment,
  MdDashboard,
  MdNotificationsNone,
  MdSchool,
} from "react-icons/md";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PowerSettingsToneIcon from "@material-ui/icons/PowerSettingsNew";
import { HcStudentDetail } from "../../../crud/highercommittee.crud";
import { salesTrialStudent, salesHCComments } from "../../../crud/sales.crud";
import Popup from "../../teacher/popup/popup";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteHc } from "../../../redux/actions/hc";
import SideDrawerHc from "../../../UI/sideDrawer/sideDrawerHc";

function Form1(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  const { studentID, salesID } = useParams();
  const [details, setDetails] = useState([]);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [response_text, setResponseText] = useState("");
  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  const star = (
    <div className="rating">
      <span className="fa fa-star checked"></span>
      <span className="fa fa-star checked"></span>
      <span className="fa fa-star checked"></span>
      <span className="fa fa-star"></span>
      <span className="fa fa-star"></span>
      <span className="fa fa-star"></span>
      <span className="fa fa-star"></span>
      <span className="fa fa-star"></span>
      <span className="fa fa-star"></span>
      <span className="fa fa-star"></span>
    </div>
  );
  const user = useSelector((state) => state.hcDetails.hc);

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(deleteHc(user.hc_id));
    removeToken();
  };
  useEffect(() => {
    HcStudentDetail(studentID)
      .then((res) => {
        setDetails(res);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    salesTrialStudent(studentID, salesID).then((res) => {
      salesHCComments({
        sales_trial_id: res.id,
        date: new Date().toISOString(),
        response_text,
        object_id: user.higher_committee_id,
        trial_student_id: studentID,
      })
        .then((res) => {
          alert("Comment Posted Successfully!");
          setIsSubmitted(!isSubmitted);
        })
        .catch((err) => console.log(err));
    });
  };

  return (
    <div className="hc-student-details-1">
      <div className="sidenav">
        <div className="insidesidenav">
          <div className="sidelink">
            <Link to="/hc-dashboard">
              <MdDashboard
                data-tip="Dashboard"
                size={24}
                color="rgba(179,179,179,1.0)"
              />
              <ReactTooltip />
            </Link>
          </div>
          <div className="sidelink">
            <Link to="/hc-teachers-list">
              {" "}
              <MdSchool
                data-tip="Teacher"
                size={24}
                color="rgba(179,179,179,1.0)"
              />
              <ReactTooltip />
            </Link>
          </div>
          <div className="sidelink">
            <Link to="/hc-suggest-rm">
              <SupervisorAccountIcon
                data-tip="Relational Manager"
                size={24}
                style={{ color: "rgba(179,179,179,1.0)" }}
              />
              <ReactTooltip />
            </Link>
          </div>
          <div className="sidelink">
            <Link to="/hc-ticket-list">
              <MdAssignment
                data-tip="Ticket List"
                size={24}
                color="rgba(179,179,179,1.0)"
              />
              <ReactTooltip />
            </Link>
          </div>
          <div className="sidelink">
            <Link to="/hc-payments">
              <AiTwotoneBank
                data-tip="Payments"
                size={24}
                color="rgba(179,179,179,1.0)"
              />
              <ReactTooltip />
            </Link>
          </div>
          <div className="sidelink">
            <Link to="/hc-classroom">
              <ClassSharpIcon
                data-tip="Classroom"
                size={24}
                color="rgba(179,179,179,1.0)"
              />
              <ReactTooltip />
            </Link>
          </div>
          <div className="sidelink">
            <Link to="/" onClick={() => handleLogout()}>
              <PowerSettingsToneIcon
                data-tip="Logout"
                size={24}
                style={{ color: "rgba(179,179,179,1.0)" }}
              />
              <ReactTooltip />
            </Link>
          </div>
        </div>
        {/* <a className="sidelink" href="#"><MdSchool size={24} color="rgba(179,179,179,1.0)"/></a>
        <a className="sidelink" href="#"><FaChartPie size={24} color="rgba(179,179,179,1.0)"/></a>
        <a className="sidelink" href="#"><AiTwotoneBank size={24} color="rgba(179,179,179,1.0)"/></a>
        <a className="sidelink" href="#"><FaRegQuestionCircle size={24} color="rgba(179,179,179,1.0)"/></a>
        <a className="sidelink" href="#" style={{marginTop: "200px"}}><FaPowerOff size={24} color="rgba(179,179,179,1.0)"/></a> */}
      </div>

      <header>
        <div className="head">
          <img src={pic_2} style={{ height: "115px" }} />
          {/* <img src={logo} alt="logo" /> */}
          <SideDrawerHc
            open={displaySideDrawer}
            closeSideDrawer={sideDrawerToggler}
          />

          <span
            className="fa fa-bars mob_nav"
            onClick={sideDrawerToggler}
          ></span>
          <img
            className="mobile_nav"
            style={{ float: "right", margin: "0 0 0 20px" }}
            src={image}
            alt="profile"
          />
          <div
            className="mobile_nav"
            style={{ float: "right", textAlign: "right" }}
          >
            <div className="biba-sobti-h8V8ZR">
              {user && user.first_name} {user && user.last_name}
            </div>
            <div className="bibasobtigmailcom-h8V8ZR">{user && user.email}</div>
          </div>
          <div
            className="mobile_nav"
            style={{
              float: "right",
              marginRight: "40px",
              marginTop: "15px",
              cursor: "pointer",
            }}
          >
            <MdNotificationsNone size={32} />
          </div>
        </div>
      </header>

      <Card
        style={{
          borderRadius: "10px",
          boxShadow: "0 0 5px rgba(0,0,0,0.2)",
          margin: "50px 50px 100px 130px",
          paddingBottom: "100px",
        }}
        className="card-ex"
      >
        <Card.Body style={{ width: "100%" }}>
          <div className="card_heading" style={{ marginBottom: "35px" }}>
            <p>Student Form Data of Trial Class</p>
          </div>
          <Form style={{ paddingLeft: "10px" }}>
            <Row>
              <Col>
                <Form.Group>
                  <div className="label">Student Name</div>
                  <Form.Control
                    type="name"
                    placeholder="Student Name"
                    value={
                      details && details.student && details.student.first_name
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <div className="label">Father's Name</div>
                  <Form.Control
                    type="name"
                    placeholder="Father's Name"
                    value={
                      details && details.student && details.student.father_name
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <div className="label">E-mail Address</div>
                  <Form.Control
                    type="name"
                    placeholder="Primary Email Address"
                    value={details && details.student && details.student.email}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <div className="label">Parent's Contact No.</div>
                  <Form.Control
                    type="name"
                    placeholder="Parent's Mobile Number"
                    value={
                      details &&
                      details.student &&
                      details.student.father_number
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <div className="label">Other Contact No.</div>
                  <Form.Control
                    type="name"
                    placeholder="Other Mobile Number"
                    value={
                      details &&
                      details.student &&
                      details.student.mobile_number
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <div className="label">Child's Current Grade</div>
                  <Form.Control
                    type="name"
                    placeholder="Child's Current Grade"
                    value={
                      details &&
                      details.student &&
                      details.student.current_grade
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <div className="label">Country</div>
                  <Form.Control
                    type="name"
                    placeholder="Country"
                    value={
                      details && details.student && details.student.country
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <div className="label">State</div>
                  <Form.Control
                    type="name"
                    placeholder="State"
                    value={details && details.student && details.student.state}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <div className="label">Postal Code</div>
                  <Form.Control
                    type="name"
                    placeholder="Postal Code"
                    value={
                      details && details.student && details.student.postal_code
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <div className="label">Preferred Subject</div>
                  <Form.Control
                    type="name"
                    placeholder="Preferred Subject"
                    value={
                      details &&
                      details.student &&
                      details.student.preferred_subject
                    }
                  />
                </Form.Group>
              </Col>
              {/* <Col>
                <Form.Group>
                  <div className="label">Preferred Timeslot</div>
                  <Form.Control type="name" placeholder="Preferred Time Slot" value={details && details.student && details.student.postal_code}/>
                </Form.Group>
              </Col> */}
            </Row>
          </Form>

          <div className="card_heading">
            <p>Student's Feedback</p>
          </div>
          <div className="heading_line">
            <p>
              On a scale of 1-10, where 1 being the lowest and 10 being the
              highest, how would you rate your learning experience?
            </p>
          </div>
          {details && details.responses && (
            <div classname="rating" style={{ marginLeft: "25px" }}>
              {" "}
              <ReactStars
                value={parseFloat(details.responses[0].answer)}
                edit={false}
                count={10}
                size={30}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                style={"margin-left: 40px"}
                activeColor="#dc3545"
              />
            </div>
          )}

          <div className="heading_line">
            <p>
              On a scale of 1-10, where 1 being the lowest and 10 being the
              highest, how would you rate the teaching manner of our teacher?
            </p>
          </div>
          {details && details.responses && (
            <div classname="rating" style={{ marginLeft: "25px" }}>
              {" "}
              <ReactStars
                value={parseFloat(details.responses[1].answer)}
                edit={false}
                count={10}
                size={30}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                style={"margin-left: 40px"}
                activeColor="#dc3545"
              />
            </div>
          )}

          <div className="heading_line">
            <p>
              On a scale of 1-10, where 1 being the lowest and 10 being the
              highest, how would you rate our teaching platform?
            </p>
          </div>
          {details && details.responses && (
            <div classname="rating" style={{ marginLeft: "25px" }}>
              {" "}
              <ReactStars
                value={parseFloat(details.responses[2].answer)}
                edit={false}
                count={10}
                size={30}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                style={"margin-left: 40px"}
                activeColor="#dc3545"
              />
            </div>
          )}

          <div className="heading_line">
            <p>
              On a scale of 1-10, where 1 being the lowest and 10 being the
              highest, how would you rate our content of teaching?
            </p>
          </div>
          {details && details.responses && (
            <div classname="rating" style={{ marginLeft: "25px" }}>
              {" "}
              <ReactStars
                value={parseFloat(details.responses[3].answer)}
                edit={false}
                count={10}
                size={30}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                style={"margin-left: 40px"}
                activeColor="#dc3545"
              />
            </div>
          )}

          <div className="heading_line">
            <p>Any other comments/reviews/suggestions?</p>
          </div>
          <input
            type="text"
            className="comment"
            value={details && details.responses && details.responses[4].answer}
          />

          <div className="card_heading" style={{ marginTop: "60px" }}>
            <p>Teacher's Feedback</p>
          </div>
          <div className="heading_line">
            <p>
              Please comment about students interest, aims or any other relevant
              information you obtained during live C lass that may help sales
              team.
            </p>
          </div>
          <textarea
            rows="15"
            type="text"
            className="comment"
            value={details && details.teacher_response}
          ></textarea>

          {details ? (
            details.saleshc_discussion ? (
              details.saleshc_discussion.length > 0 ? (
                details.saleshc_discussion.map((discuss) => {
                  return (
                    <div>
                      <div className="heading_line">
                        <p>
                          {discuss.object_id.includes("SA") == true
                            ? "Sales"
                            : discuss.object_id.includes("RM") == true
                            ? "Relational Manager"
                            : discuss.object_id.includes("AC") == true
                            ? "Account"
                            : "Higher Committee"}
                        </p>
                      </div>
                      <textarea
                        rows="2"
                        type="text"
                        className="comment_2"
                        placeholder="He wants one-one class of mathematics."
                        value={discuss.response_text}
                      ></textarea>
                    </div>
                  );
                })
              ) : (
                <div />
              )
            ) : (
              <div />
            )
          ) : (
            <div />
          )}

          <div className="card_heading" style={{ marginTop: "60px" }}>
            <p>Sales Higher Committee Discussion</p>
          </div>

          <div className="bottom_comment">
            <textarea
              rows="6"
              placeholder="Type your comments here"
              onChange={(e) => setResponseText(e.target.value)}
            ></textarea>
            <Button variant="danger" onClick={(e) => handleSubmit(e)}>
              Post
            </Button>
          </div>
        </Card.Body>
      </Card>

      <div style={{ marginTop: "100px" }}>.</div>
      {/* <Popup /> */}
    </div>
  );
}

export default Form1;
