import React, { useState, useLayoutEffect } from "react";
import "./style.css";
import { FaRegQuestionCircle, FaCalendarAlt, FaFileAlt, FaChartPie, FaStickyNote, FaPowerOff } from "react-icons/fa";
import { MdAssignment, MdDashboard, MdNotificationsNone } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Popup from '../../teacher/popup/popup';
// images
import line5_2x from "./img/line-5@2x.png"
import line5_1_2x from "./img/line-5-1@2x.png"
import line6_2x from "./img/line-6@2x.png"
import line6_1_2x from "./img/line-6-1@2x.png"
import rectangle_40_2x from "./img/rectangle-40@2x.png"
import orange_ellipse from "./img/ellipse-2@2x.png"
import vector_141_2x from "./img/vector-141@2x.png"
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from '../../../utils';
import { deleteStudent } from '../../../redux/actions/student';
import pic_2 from "../dashboard/img/pic_2.png";
import SideDrawerStudent from '../../../UI/sideDrawer/sideDrawerStudent';

function Studentcalendarenrolledcourses(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  const user = useSelector((state) => state.studentDetails.student);

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteStudent(reduxUser.enrolled_student_id))
    removeToken();
  }

  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);

  const sideDrawerToggler = () => {
    setDisplaySideDrawer(previousState => !previousState);
  };

  return (
    <>
      <input type="hidden" id="anPageName" name="page" value="student-calendar-enrolledcourses" />
      <div className="student-calendar-teacher-list-mobile screen">
        <div className="component-1-C61RwL border-1px-blue-zodiac">
          {/* <img alt="image" className="line-5-9p2GBv" src={line5_2x} />
          <img alt="image" className="line-6-9p2GBv" src={line6_2x} /> */}
          <img alt="image" src={pic_2} style={{ height: "115px", marginTop: "-8%" }} />

        </div>

        {/* This is the hamburger icon  */}
        <SideDrawerStudent open={displaySideDrawer} closeSideDrawer={sideDrawerToggler} />

        <div className="component-6-C61RwL" onClick={sideDrawerToggler}>
          <div className="rectangle-48-5mfU7d"></div>
          <div className="rectangle-49-5mfU7d"></div>
          <div className="rectangle-50-5mfU7d"></div>
        </div>
        <div className="group-83-C61RwL">
          <div className="group-15-jQ4qbJ">
            <div className="rectangle-30-V5d4cE"></div>
            <div className="mathematcs-section-g-V5d4cE">
              <span className="span0-Xx53BE">Mathematcs - </span><span className="span1-Xx53BE">Section G</span>
            </div>
            <div className="upcoming-class-V5d4cE montserrat-medium-blue-zodiac-18px">Upcoming Class</div>
          </div>
          <div className="friday-jQ4qbJ">FRIDAY</div>
          <div className="x15-january-jQ4qbJ">15 JANUARY</div>
          <div className="x0800-am-0900-am-jQ4qbJ">08:00 AM - 09:00 AM</div>
          <div className="rectangle-51-jQ4qbJ border-1px-blue-zodiac"></div>
          <div className="join-class-jQ4qbJ">Join Class</div>
        </div>
        <div className="group-84-C61RwL">
          <div className="rectangle-42-ahGy4x"></div>
          <div className="your-have-no-upcoming-tests-ahGy4x">Your have no upcoming tests.</div>
          <div className="see-details-ahGy4x montserrat-semi-bold-gray-12px">See details &gt;</div>
        </div>
        <div className="rectangle-44-C61RwL"></div>
        <div className="group-85-C61RwL">


          <div className="join-7-rFX9Nh">
            <div className="group-wQSXdG">
              <FaRegQuestionCircle size={30} />
            </div>
          </div>
          <div className="join-8-rFX9Nh">
            <div className="group-TtUkiV">
              <FaRegQuestionCircle size={30} />
            </div>
          </div>
          <div className="join-9-rFX9Nh">
            <div className="group-3eHi1a">
              <FaRegQuestionCircle size={30} />
            </div>
          </div>
        </div>
        <div className="group-86-C61RwL">
          <div className="pending-assignments-cPi90N montserrat-medium-blue-zodiac-18px">Pending Assignments</div>
          <div className="view-all-cPi90N montserrat-semi-bold-gray-12px">View all</div>
          <div className="rectangle-44-cPi90N"></div>
          <div className="rectangle-52-cPi90N"></div>
        </div>
        <div className="group-45-C61RwL">
          <div className="rectangle-20-IvCEtI"></div>
          <div className="subject-IvCEtI roboto-medium-white-14px">Subject</div>
          <div className="s-no-IvCEtI roboto-medium-white-14px">S. No.</div>
          <div className="teacher-name-IvCEtI roboto-medium-white-14px">Teacher Name</div>
          <div className="grade-IvCEtI roboto-medium-white-14px">Grade</div>
          <div className="course-IvCEtI roboto-medium-white-14px">Course</div>
        </div>
      </div>
      <div className="student-calendar-enrolledcourses screen">
        <img alt="image" className="rectangle-7-C61RwL" src="img/rectangle-7@1x.png" />
        <div className="group-158-C61RwL">
          <div className="group-22-GyJUNH-student-dash">
            <div className="flex-col-vgli2x-student-dash">
              <div className="biba-sobti-h8V8ZR-student-dash">{user.first_name + " " + user.last_name}</div>
              <div className="bibasobtigmailcom-h8V8ZR-student-dash">{user && user.email}</div>
            </div>
            <img className="vector-vgli2x-student-dash" src={vector_141_2x} />
          </div>
         
        </div>
        <div className="view-all-C61RwL montserrat-semi-bold-gray-12px">View all</div>
        <div className="group-13-C61RwL">
          <div className="group-43-WQXeF1">
            <div className="group-42-SMU6q4">
              <div className="group-41-G4Fvxm">
                <div className="group-40-Hw0YGE">
                  <div className="group-39-tu69oo">
                    <div className="group-38-r5Ayig">
                      <div className="group-44-yiEHl9"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="upcoming-class-WQXeF1">Upcoming Class</div>
          <div className="mathematics-section-g-WQXeF1">
            <span className="span0-SSwiU9">Mathematics -</span><span className="span1-SSwiU9">&nbsp;</span>
            <span className="span2-SSwiU9">Section G</span>
          </div>

        </div>

        <img alt="image" className="rectangle-40-C61RwL" src={rectangle_40_2x} />


        <div className="join-4-C61RwL">
          <div className="group-Ie5dUU">
            <FaRegQuestionCircle size={30} />
          </div>
        </div>
        <div className="join-5-C61RwL">
          <div className="group-Zmqz7u">
            <FaRegQuestionCircle size={30} />
          </div>
        </div>
        <div className="join-6-C61RwL">
          <div className="group-KsMB6y">
            <FaRegQuestionCircle size={30} />
          </div>
        </div>
        <div className="please-check-my-approach-C61RwL montserrat-semi-bold-blue-zodiac-18px">Please check my approach.</div>
        <div className="view-forum-QxM5SU montserrat-semi-bold-gray-16px">View Forum</div>
        <div className="join-7-C61RwL">
          <div className="group-mnqxdV">
            <FaRegQuestionCircle size={30} />
          </div>
        </div>
        <div className="component-1-C61RwL border-1px-blue-zodiac">
          <img alt="image" src={pic_2} style={{ height: "115px", marginTop: "-8%" }} />

        </div>
        <div className="group-117-C61RwL">
          <div className="rectangle-30-Qgw4ic"></div>
          <div className="dashboard-24px-1-Qgw4ic"><img alt="image" className="vector-R9PQJK" src="img/vector-328@2x.png" /></div>
          <div className="list-of-enrolled-courses-Qgw4ic">List of Enrolled Courses</div>
          <div className="group-45-Qgw4ic">
            <div className="rectangle-20-liwwFq"></div>
            <div className="subject-liwwFq roboto-medium-white-20px">Subject</div>
            <div className="s-no-liwwFq roboto-medium-white-20px">S. No.</div>
            <div className="teacher-name-liwwFq roboto-medium-white-20px">Teacher Name</div>
            <div className="grade-liwwFq roboto-medium-white-20px">Grade</div>
            <div className="course-liwwFq roboto-medium-white-20px">Course</div>
          </div>
        </div>
        <div className="rectangle-24-C61RwL"></div>
        <div className="vector-C61RwL">
          <Link to="/" onClick={() => handleLogout()}><FaPowerOff size={24} data-tip="Logout" color="rgba(179,179,179,1.0)" /><ReactTooltip /></Link>
        </div>
        <Link to="/student-curriculum-list">
          <div className="description-24px-1-C61RwL" data-tip="Curriculums">
            <FaFileAlt size={24} color="rgba(179,179,179,1.0)" />
          </div><ReactTooltip />
        </Link>
        <Link to="/student-date-calendar">
          <div className="vector-VMr6Om" data-tip="Date Calendar">
            <FaCalendarAlt size={24} color="rgba(179,179,179,1.0)" />
          </div><ReactTooltip />
        </Link>
        <Link to="/student-dashboard">
          <div className="vector-mzXdH9" data-tip="Dashboard">
            <MdDashboard size={24} color="rgba(179,179,179,1.0)" />
          </div> <ReactTooltip />
        </Link>
        <Link to="/student-doubt-list">
          <div className="help-24px-1-C61RwL" data-tip="Doubts">
            <FaRegQuestionCircle size={24} color="rgba(179,179,179,1.0)" />
          </div><ReactTooltip />
        </Link>
        <Link to="/student-assignment-list">
          <div className="assignment-24px-1-C61RwL" data-tip="Assignments">
            <MdAssignment size={24} color="rgba(179,179,179,1.0)" />
          </div> <ReactTooltip />
        </Link>
        <Link to="/">
          <div className="supportagent-24px-1-C61RwL">
            <BiSupport size={24} color="rgba(179,179,179,1.0)" data-tip="Support Desk" />
          </div><ReactTooltip />
        </Link>
        <Link to="/student-performance" data-tip="Performance">
          <div className="analytics-24px-3-C61RwL">
            <FaChartPie size={24} color="rgba(179,179,179,1.0)" />
          </div> <ReactTooltip />
        </Link>
        <div className="group-C61RwL">
          <Link to="/student-quiz-list">
            <div className="group-NOXmfT" data-tip="Quizzes">
              <RiQuestionnaireFill size={24} color="rgba(179,179,179,1.0)" />
            </div> <ReactTooltip />
          </Link>
        </div>
        <div className="group-160-C61RwL">
          <Link to="/student-notes-list">
            <div className="stickynote2-24px-1-Cp4Be6" data-tip="Lecture Notes">
              <FaStickyNote size={24} color="rgba(179,179,179,1.0)" />
            </div><ReactTooltip />
          </Link>
        </div>
      </div>
      <Popup />
    </>
  );
}

export default Studentcalendarenrolledcourses;