import React, { useState, useEffect, useLayoutEffect } from "react";
import "./detail_style.css";
import { Link, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import {
  FaRegQuestionCircle,
  FaCalendarAlt,
  FaFileAlt,
  FaChartPie,
  FaStickyNote,
  FaPowerOff,
} from "react-icons/fa";
import { MdAssignment, MdDashboard, MdNotificationsNone } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import Popup from "../../teacher/popup/popup";
import {
  dashboardDoubts,
  studentLectureNotes,
} from "../../../crud/student.crud";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteStudent } from "../../../redux/actions/student";
import pic_2 from "../dashboard/img/pic_2.png";
import SideDrawerStudent from "../../../UI/sideDrawer/sideDrawerStudent";

// images
import line5_2x from "./img/line-5@2x.png";
import line5_1_2x from "./img/line-5-1@2x.png";
import line6_2x from "./img/line-6@2x.png";
import line6_1_2x from "./img/line-6-1@2x.png";
import vector_3_2x from "./img/vector-3@2x.png";
import vector_138_2x from "./img/vector-138@2x.png";
import vector_147_2x from "./img/vector-147@2x.png";
import vector_151_2x from "./img/vector-151@2x.png";
import rectangle_7 from "./img/rectangle-7@1x.png";
import ellipse_2_2x from "./img/ellipse-2@2x.png";

function Studentlecturenotes(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const user = useSelector((state) => state.studentDetails.student);
  const { teacherId } = useParams();
  const [doubts, setDoubts] = useState([]);
  const [notes, setNotes] = useState([]);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(deleteStudent(user.enrolled_student_id));
    removeToken();
  };

  useEffect(() => {
    dashboardDoubts(user.enrolled_student_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setDoubts(false);
        } else setDoubts(res);
      })
      .catch((err) => console.log(err));
    studentLectureNotes(user.enrolled_student_id, teacherId)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setNotes(false);
        } else setNotes(res[0]);
      })
      .catch((err) => console.log(err));
  }, []);

  const showNotes = (note) => {
    window.open(note.notes_url, "_blank");
    console.log("Note opened.");
  };

  return (
    <>
      <form
        className="student-lecture-notes-mobile screen"
        name="form1"
        action="form1"
        method="post"
      >
        <div className="component-1-C61RwL">
          <img
            alt="image"
            src={pic_2}
            style={{ height: "90px", marginTop: "-8%" }}
          />
        </div>

        {/* This is the hamburger icon  */}
        <SideDrawerStudent
          open={displaySideDrawer}
          closeSideDrawer={sideDrawerToggler}
        />
        <div className="component-6-C61RwL" onClick={sideDrawerToggler}>
          <div className="rectangle-48-5mfU7d"></div>
          <div className="rectangle-49-5mfU7d"></div>
          <div className="rectangle-50-5mfU7d"></div>
        </div>

        <div className="group-85-C61RwL">
          <div className="rectangle-44-rFX9Nh">
            {doubts.length > 0 ? (
              doubts.slice(0, 4).map((doubt) => {
                return (
                  <div className="doubt-row">
                    <FaRegQuestionCircle size={30} />
                    <Link>
                      <div className="montserrat-semi-bold-blue-zodiac-18px">
                        {doubt.doubt_text.length > 31
                          ? doubt.doubt_text.substring(0, 27) + "..."
                          : doubt.doubt_text}
                      </div>
                    </Link>
                    {/* <div className="view-forum-C61RwL montserrat-semi-bold-gray-16px">View</div> */}
                  </div>
                );
              })
            ) : (
              <div />
            )}
          </div>
          <div className="doubt-forum-rFX9Nh">Doubt Forum</div>
          <Link to="/student-doubt-list">
            <div className="view-all-rFX9Nh">View all</div>
          </Link>
        </div>
        <div className="my-lecture-notes-C61RwL">
          <span className="span0-sKAmxU">My</span>
          <span className="span1-sKAmxU"> Lecture Notes</span>
        </div>
        <div className="group-103-C61RwL">
          <div className="group-96-B08mCn-000201">
            {notes && notes.length > 0 ? (
              notes.map((note) => {
                return (
                  <div className="rectangle-40-LoIUnw">
                    <FaStickyNote size={26} color="#dc3545" />
                    <div className="noteTile-col">
                      <div className="montserrat-semi-bold-blue-zodiac-14px">
                        {note.notes_id}
                      </div>
                      <div className="montserrat-medium-gray-10px">
                        {teacherId}
                      </div>
                    </div>
                    <a
                      onClick={() => showNotes(note)}
                      style={{ marginLeft: "auto" }}
                    >
                      <div className="open-QxM5SU montserrat-semi-bold-crusta-12px">
                        Open
                      </div>
                    </a>
                  </div>
                );
              })
            ) : (
              <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                No notes uploaded by this teacher.
              </div>
            )}
          </div>
        </div>
        {/* <div className="group-60-C61RwL">
        <div className="component-29-Up6zcy"></div>
        <div className="description-24px1-1-Up6zcy">
          <FaStickyNote size={24} color="white" />
        </div>
      </div>
      <div className="group-104-C61RwL">
        <div className="component-29-yXv3Oh"></div>
        <div className="description-24px1-1-yXv3Oh">
          <FaStickyNote size={24} color="white" />
        </div>
      </div>
      <div className="group-105-C61RwL">
        <div className="component-29-a77sPZ"></div>
        <div className="description-24px1-1-a77sPZ">
          <FaStickyNote size={24} color="white" />
        </div>
      </div> */}
        <a href="javascript:history.back()">
          <div className="arrowback-24px-1-C61RwL">
            <img className="vector-ec7mDZ" src={vector_138_2x} />
            <img className="vector-wEJYwt" src={vector_147_2x} />
          </div>
        </a>
      </form>
      <form
        className="student-lecture-notes screen"
        name="form2"
        action="form2"
        method="post"
      >
        <div className="lecture-notes-detail-col">
          <div className="student-re-flex">
            <div className="component-1-C61RwLz-3">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "115px", marginTop: "-8%" }}
              />
            </div>

            <div className="group-22-GyJUNH-student-dash">
              <div className="flex-col-vgli2x-student-dash">
                <div className="biba-sobti-h8V8ZR-student-dash">
                  {user.first_name + " " + user.last_name}
                </div>
                <div className="bibasobtigmailcom-h8V8ZR-student-dash">
                  {user && user.email}
                </div>
              </div>
              <img className="vector-vgli2x-student-dash" src={vector_151_2x} />
            </div>
          </div>

          <div className="my-lecture-notes-C61RwL">
            <span className="span0-sKAmxU">My</span>
            <span className="span1-sKAmxU"> Lecture Notes</span>
          </div>
          <div className="group-46-C61RwL">
            {notes && notes.length > 0 ? (
              notes.map((note) => {
                return (
                  <div className="rectangle-40-AvtzuR">
                    <FaStickyNote size={26} color="#dc3545" />
                    <div className="noteTile-col">
                      <div className="montserrat-semi-bold-blue-zodiac-14px">
                        {note.notes_id}
                      </div>
                      <div className="montserrat-medium-gray-10px">
                        {teacherId}
                      </div>
                    </div>
                    <a
                      onClick={() => showNotes(note)}
                      style={{ marginLeft: "auto" }}
                    >
                      <div className="open-QxM5SU montserrat-semi-bold-crusta-12px">
                        Open
                      </div>
                    </a>
                  </div>
                );
              })
            ) : (
              <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                No notes uploaded by this teacher.
              </div>
            )}
          </div>
          {/* <div className="x14-C61RwL">14</div> */}

          <div className="rectangle-43-C61RwL">
            {doubts.length > 0 ? (
              doubts.slice(0, 4).map((doubt) => {
                return (
                  <div className="doubt-row">
                    <FaRegQuestionCircle size={30} />
                    <Link>
                      <div className="montserrat-semi-bold-blue-zodiac-18px">
                        {doubt.doubt_text.length > 31
                          ? doubt.doubt_text.substring(0, 27) + "..."
                          : doubt.doubt_text}
                      </div>
                    </Link>
                    {/* <div className="view-forum-C61RwL montserrat-semi-bold-gray-16px">View</div> */}
                  </div>
                );
              })
            ) : (
              <div />
            )}
            <div className="doubt-forum-C61RwL montserrat-semi-bold-blue-zodiac-18px">
              Doubt Forum
            </div>
            <Link to="/student-doubt-list">
              <div className="view-all-C61RwL">View all</div>
            </Link>
          </div>
        </div>

        <div className="rectangle-24-C61RwL-stu-s1">
          <Link to="/student-dashboard">
            <div data-tip="Dashboard">
              <MdDashboard size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-date-calendar">
            <div data-tip="Date Calendar">
              <FaCalendarAlt size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-assignment-list">
            <div data-tip="Assignments">
              <MdAssignment size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-quiz-list">
            <div data-tip="Quizzes">
              <RiQuestionnaireFill size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-curriculum-list">
            <div data-tip="Curriculums">
              <FaFileAlt size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-doubt-list">
            <div data-tip="Doubts">
              <FaRegQuestionCircle size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-performance" data-tip="Performance">
            <div>
              <FaChartPie size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-notes-list">
            <div data-tip="Lecture Notes">
              <FaStickyNote size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/" onClick={() => handleLogout()}>
            <FaPowerOff
              size={24}
              data-tip="Logout"
              color="rgba(179,179,179,1.0)"
            />
            <ReactTooltip />
          </Link>
        </div>
      </form>
      <Popup />
    </>
  );
}

export default Studentlecturenotes;
