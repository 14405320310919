import React from "react";
import { useSelector } from "react-redux";
import "./ContentComponent.css";

import { BellOutlined, UserOutlined } from "@ant-design/icons";
import Popup from '../../../teacher/popup/popup';

function HcComponent(props) {
  const user = useSelector((state) => state.hcDetails.hc);

  return (
    <div className="component-hc-layout-wdt-100">
      <div className="calender-user-dis-hc-view">
        <div>
          <BellOutlined className="notification-2" />
        </div>
        <div className="User-info">
          <div className="user-info-in">
            <div
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#dc3545",
              }}
            >
              Babita Sobit
            </div>
            <div>biba@gmail.com</div>
          </div>
          <div>
            <UserOutlined className="User-Icon" />
          </div>
        </div>
      </div>
      <div className="hc-content-view-2">
        <div className="content-component-teacher-op-hc">Classes</div>
        <div className="content-table-hc-list">
          <span>Scheduled</span>
          <span>Cancelled</span>
          <span>Completed</span>
        </div>
        <div className="content-Comp-2-hc">
          <div className="content-Comp-2-hc-in">
            <span className="table-item-hc-content">
              {props.teacherData.scheduled}
            </span>
            <span className="table-item-hc-content">
              {props.teacherData.cancelled}
            </span>
            <span className="table-item-hc-content">
              {props.teacherData.completed}
            </span>
          </div>
        </div>
      </div>

      <div className="hc-content-view-2">
        <div className="content-component-teacher-op-hc">Quizzes</div>
        <div className="content-table-hc-list-q-a">
          <span>Assigned</span>
          <span>Graded</span>
        </div>
        <div className="content-Comp-2-hc-q-a">
          <div className="content-Comp-2-hc-in">
            <span>{props.teacherData.total_quiz_given}</span>
            <span>{props.teacherData.graded_quiz}</span>
          </div>
        </div>
      </div>

      <div className="hc-content-view-2 insane-tech">
        <div className="content-component-teacher-op-hc">Assignment</div>
        <div className="content-table-hc-list-q-a">
          <span>Assigned</span>
          <span>Graded</span>
        </div>
        <div className="content-Comp-2-hc-q-a">
          <div className="content-Comp-2-hc-in">
            <span>{props.teacherData.total_assignments_given}</span>
            <span>{props.teacherData.graded_assignment}</span>
          </div>
        </div>
      </div>

      <button className="button-comp-mar-col" onClick={props.changeTeacher}>
        Change Teacher
      </button>
      {/* <Popup /> */}
    </div>
  );
}

export default HcComponent;
