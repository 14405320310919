import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import studentDetailsReducer from "./studentReducers";
import teacherDetailsReducer from "./teacherReducers";
import rmDetailsReducer from "./rmReducers";
import salesDetailsReducer from "./salesReducers";
import hcDetailsReducer from "./hcReducers";
import accountsDetailsReducer from "./accountsReducers";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "studentDetails",
    "teacherDetails",
    "rmDetails",
    "salesDetails",
    "hcDetails",
    "accountsDetails",
  ],
};

const rootReducer = combineReducers({
  studentDetails: studentDetailsReducer,
  teacherDetails: teacherDetailsReducer,
  rmDetails: rmDetailsReducer,
  salesDetails: salesDetailsReducer,
  hcDetails: hcDetailsReducer,
  accountsDetails: accountsDetailsReducer,
});

export default persistReducer(persistConfig, rootReducer);
