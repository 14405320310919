import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from '../../../utils';
import { deleteHc } from '../../../redux/actions/hc'; import "./style.css";
import { NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import line_5_2x from "./img/line-5@2x.svg";
import ClassSharpIcon from '@material-ui/icons/ClassSharp';
import line_6_2x from "./img/line-6@2x.svg";
import {
  FaRegQuestionCircle,
  FaCalendarAlt,
  FaFileAlt,
  FaChartPie,
  FaStickyNote,
  FaPowerOff,
} from "react-icons/fa";
import {
  MdAssignment,
  MdDashboard,
  MdNotificationsNone,
  MdSchool,
} from "react-icons/md";
import { BiSupport, BiHistory } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { AiTwotoneBank } from "react-icons/ai";
import { GiRelationshipBounds } from "react-icons/gi";
import vector_6_2x from "./img/vector-6@2x.svg";
import line_5_1_2x from "./img/line-5-1@2x.svg";
import line_6_1_2x from "./img/line-6-1@2x.svg";
import vector_1_2x from "./img/vector-1@2x.svg";
import vector_2x from "./img/vector@2x.png";
import vector_22_2x from "./img/vector-22@2x.svg";
import vector_23_2x from "./img/vector-23@2x.svg";
import ellipse_2_1_2x from "./img/ellipse-2-1@2x.svg";
import { allTicketList } from "../../../crud/highercommittee.crud";
import { Link } from "react-router-dom";

import Popup from "../../teacher/popup/popup";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SchoolIcon from "@material-ui/icons/School";
import moment from "moment";
import SideDrawerHc from "../../../UI/sideDrawer/sideDrawerHc";
import pic_2 from "../../student/dashboard/img/pic_2.png";
function TicketList(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const [tickets, setTickets] = useState([]);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [handleFilter, setHandleFilter] = useState('');

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  const user = useSelector((state) => state.hcDetails.hc);

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(deleteHc(user.hc_id))
    removeToken();
  }
  useEffect(() => {
    allTicketList("In Progress")
      .then((res) => {
        setTickets(res);
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  // useEffect(() => {
  //   allTicketList("Higher Committee")
  //     .then(res => {
  //       setTickets(res);
  //     })
  //     .catch(err => console.log(err))
  // }, [])

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="relational-manager-ticket-list"
      />
      <div className="container-center-horizontal">
        <div className="higher-committee-ticket-mobile screen">
          <div className="flex-row-C61RwL">
            <div className="component-1-dXBp89">
              <img src={pic_2} style={{ height: "100px", margin: "-8%" }} />
            </div>
            <SideDrawerHc
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />
            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>
          <div className="group-127-C61RwL">
            <div className="my-tickets-2zqKZg">
              <span className="span0-6gnXOk">My</span>
              <span className="span1-6gnXOk">&nbsp;</span>
              <span className="span2-6gnXOk">Tickets</span>
            </div>
            <input
              type="text"
              placeholder="Filter List"
              style={{ width: "200px", marginLeft: "10px", marginTop: "5px" }}
              onChange={(e) => setHandleFilter(e.target.value)}
            />
            <div className="overlap-group1-2zqKZg">
              {/* <div className="dashboard-24px-1-759J8x">
                <img className="vector-L9XHu9" src="img/vector@2x.png" />
              </div>
              <div className="group-45-759J8x"> */}
              <div className="rectangle-20-aIq8ji-mob-hc-t">
                <div className="roboto-bold-white-10px">Raised By</div>
                <div className="roboto-bold-white-10px">Ticket Status</div>
                <div className="x014009-pm-Sgr7kw-chan-in roboto-bold-white-10px">
                  Date
                </div>
                <div className="roboto-bold-white-10px">Category</div>
              </div>
            </div>
            <div className="overlap-group-2zqKZg">
              {/* <div className="class-name-eVSctS roboto-medium-white-20px">
                Class Name
              </div>
              <div className="s-no-eVSctS roboto-medium-white-20px">S. No.</div>
              <div className="student-name-eVSctS roboto-medium-white-20px">
                Student Name
              </div>
              <div className="date-eVSctS roboto-medium-white-20px">Date</div>
              <div className="time-eVSctS roboto-medium-white-20px">Time</div> */}
              <div className="rectangle-30-eVSctS-chn">
                <div className="Rahkfaj-sdank23k">
                  {tickets.length > 0 ? (
                    tickets
                      .filter((res) => {
                        if (handleFilter === "") {
                          return res;
                        } else if (
                          res.id
                            .toLowerCase()
                            .includes(handleFilter.toLowerCase())
                        ) {
                          return res;
                        }
                      })
                      .map((item, index) => {
                        return (
                          <Link to={`/hc-ticket-detail/${item && item.id}`}>
                            <div className="group-56-eVSctS-01010">
                              <div className="x499900-Sgr7kw-chan-in montserrat-semi-bold-blue-zodiac-8px">
                                {item && item.raised_by}
                              </div>
                              <div className="x07-dec-2020-Sgr7kw-chan-in montserrat-semi-bold-blue-zodiac-8px">
                                {item && item.ticket_status}
                              </div>
                              <div className="x014009-pm-Sgr7kw-chan-in montserrat-semi-bold-blue-zodiac-8px">
                                {item && item.date_raised}
                              </div>
                              <div className="andb586-Sgr7kw-chan-in montserrat-semi-bold-blue-zodiac-8px">
                                {item && item.category}
                              </div>
                            </div>
                          </Link>
                        );
                      })
                  ) : (
                    <div
                      className="group-56-eVSctS-01010"
                      style={{ fontFamily: "'Montserrat', Helvetica" }}
                    >
                      No active tickets found.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-center-horizontal">
        <div className="higher-committee-ticket-list screen">
          <div className="sider-hc-ticket-list">
            <div className="sider-hc-teacher-list-in-wdt-80">
              <NavLink to="/hc-dashboard">
                <DashboardIcon
                  data-tip="Dashboard"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/hc-teachers-list">
                {" "}
                <SchoolIcon data-tip="Teacher" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/hc-suggest-rm">
                <SupervisorAccountIcon
                  data-tip="Relational Manager"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/hc-ticket-list">
                <AssignmentIcon
                  data-tip="Ticket List"
                  className="icon-in-hc-color"
                  style={{ color: "#dc3545" }}
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/hc-payments">
                <AccountBalanceIcon
                  data-tip="Payments"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/hc-classroom">
                <ClassSharpIcon
                  data-tip="Classroom"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/" onClick={() => handleLogout()}>
                <PowerSettingsNewIcon
                  data-tip="Logout"
                  className="icon-in-hc-color"
                />
              </NavLink>
            </div>
          </div>
          <div className="flex-col-C61RwL">
            <div className="flex-row-0xy0vn">
              <div className="component-1-GyJUNH">
                <img alt="image" src={pic_2} style={{ height: "100px" }} />
                {/* <img className="line-5-qWvJ7Z" src={line_5_1_2x} />
                <img className="line-6-qWvJ7Z" src={line_6_1_2x} /> */}
              </div>
              <div className="username-container">
                <div className="group-22-GyJUNH">
                  <div className="biba-sobti-vgli2x">
                    {user && user.first_name} {user && user.last_name}
                  </div>
                  <div className="bibasobtigmailcom-vgli2x">
                    {user && user.email}
                  </div>
                </div>
                <img className="vector-GyJUNH" src={vector_1_2x} />
              </div>
            </div>
            <div className="flex-row-oV5UrE">
              <div className="flex-col-QkTteQ">
                <div className="my-tickets-Fjgz1C-xzs">
                  <div>
                    <span className="span0-xITcw8">My</span>
                    <span className="span1-xITcw8"> Tickets</span>
                  </div>
                  <input
                    type="text"
                    placeholder="Filter List"
                    style={{ width: "210px", marginRight: "5px" }}
                    onChange={(e) => setHandleFilter(e.target.value)}
                  />
                </div>
                <div className="overlap-group1-Fjgz1C">
                  {/* <div className="dashboard-24px-1-1UTYTe">
                    <img className="vector-NlXsxj" src={vector_2x} />
                  </div>
                  <div className="group-45-1UTYTe"> */}
                  <div className="rectangle-20-fqPQjE0-hc-t">
                    <div className="x499900-Sgr7kw-102-tic roboto-medium-white-20px">
                      Raised By
                    </div>
                    <div className="roboto-medium-white-20px">
                      Ticket Status
                    </div>
                    <div className="x014009-pm-Sgr7kw-tic-1 roboto-medium-white-20px">
                      Date
                    </div>
                    <div className="roboto-medium-white-20px">Category</div>
                  </div>
                </div>
                <div className="overlap-group-Fjgz1C">
                  <img
                    className="rectangle-7-PXwdVf"
                    src="img/rectangle-7@1x.png"
                  />
                  <div className="dashboard-24px-1-PXwdVf">
                    <img className="vector-OUzaf1" src="img/vector@2x.png" />
                  </div>
                  <div className="rectangle-30-PXwdVf-092-1">
                    <div className="Rahkfaj-sdank23k">
                      {tickets.length > 0 ? (
                        tickets
                          .filter((res) => {
                            if (handleFilter === "") {
                              return res;
                            } else if (
                              res.id
                                .toLowerCase()
                                .includes(handleFilter.toLowerCase())
                            ) {
                              return res;
                            }
                          })
                          .map((item, index) => {
                            return (
                              <Link
                                to={`/hc-ticket-detail/${item && item.id}`}
                                style={{ width: "100%" }}
                              >
                                <div className="group-56-PXwdVf-089">
                                  <div className="x499900-Sgr7kw-102-tic montserrat-semi-bold-blue-zodiac-10px">
                                    {item && item.raised_by}
                                  </div>
                                  <div className="x07-dec-2020-Sgr7kw-102-tic montserrat-semi-bold-blue-zodiac-10px">
                                    {item && item.ticket_status}
                                  </div>
                                  <div className="x014009-pm-Sgr7kw-tic montserrat-semi-bold-blue-zodiac-10px">
                                    {item && item.date_raised}
                                  </div>
                                  <div className="andb586-Sgr7kw-102-tic montserrat-semi-bold-blue-zodiac-10px">
                                    {item && item.category}
                                  </div>
                                </div>
                              </Link>
                            );
                          })
                      ) : (
                        <div
                          className="group-56-PXwdVf-089"
                          style={{ fontFamily: "'Montserrat', Helvetica" }}
                        >
                          No active tickets found.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Popup /> */}
    </>
  );
}

export default TicketList;
