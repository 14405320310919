import React from 'react';
import './totalStudentConv.css';
import { PieChart } from "react-minimal-pie-chart";
import { Badge } from 'antd';
import Popup from "../../../teacher/popup/popup";
import pic_2 from "../../../student/dashboard/img/pic_2.png";
const totalStudentConv = (props) => {
  let classesData = []

  props.isLoaded ? props.analData ? props.analData.total_students_contacted ? classesData = [
    { title: 'Total Students in Contact Active', value: props.analData.total_students_in_contact_active, color: '#3be2e2' },
    { title: 'Total Students Converted', value: props.analData.total_students_converted, color: '#3be28e' },
      { title: 'Total Students Lost Expected', value: props.analData.total_students_lost_expected, color: '#3b46e2' },
      { title: 'Total Students Lost Price', value: props.analData.total_students_lost_price, color: '#cadb2d' },
    { title: 'Total Students Lost Intent', value: props.analData.total_students_lost_intent, color: '#E38627' },
  ] : classesData = [] : classesData = [] : classesData = [];    

      let displayData = []

  props.isLoaded ? props.analData ? displayData = [
    { title: 'Total Students in Contact Active', value: props.analData.total_students_in_contact_active, color: '#3be2e2' },
    { title: 'Total Students Converted', value: props.analData.total_students_converted, color: '#3be28e' },
      { title: 'Total Students Contacted', value: props.analData.total_students_contacted, color: '#8438ca' },
      { title: 'Total Students Lost Expected', value: props.analData.total_students_lost_expected, color: '#3b46e2' },
      { title: 'Total Students Lost Price', value: props.analData.total_students_lost_price, color: '#cadb2d' },
    { title: 'Total Students Lost Intent', value: props.analData.total_students_lost_intent, color: '#E38627' },
  ] : displayData = [] : displayData = [];  
        //console.log(classesData);
    
    return (
      <div className="totalStudentConv-layout-wdt-100">
        {/* <div className="logo-totalStudent-in" /> */}
        <img src={pic_2} style={{ height: "115px", marginLeft: "-70%" }} />
        <h1 className="div-main-heading-in">My Performance</h1>
        <div className="totalStudentConv-view-1">
          <div className="head-teachStudConv-view-1-in">
            <span>Student Contacted</span>
            <span>Student Converted</span>
          </div>
          <div className="totalStudentConv-view-1-in">
            <PieChart
              className="pi-in-total-stu-conv"
              lineWidth={30}
              label={({ dataEntry }) => dataEntry.value}
              labelStyle={(index) => ({
                fill: classesData[index].color,
                fontSize: "5px",
                fontFamily: "sans-serif",
              })}
              labelPosition={60}
              data={classesData}
            />

            <div className="Badge-in-10px">
              {classesData.map((Datamap) => (
                <div className="Badge-in-10px-container">
                  <Badge style={{ width: "10px" }} color={Datamap.color} />
                  {/* <span style={{ width: '4px' }}>.</span> */}
                  <p className="p-text-badge">{Datamap.title}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="totalStudentConv-view-2">
          <div className="head-teachStudConv-view-1-in">
            <span>Student Contacted</span>
            <span>Student Converted</span>
          </div>

          <div className="Table-Badge-in-10px">
            {displayData.map((Datamap) => (
              <div className="Table-Badge-in-10px-container">
                <p className="Table-Badge-in-10px-p-container">
                  {Datamap.title}
                </p>
                <p className="Table-Badge-in-10px-p-container">
                  {Datamap.value}
                </p>
              </div>
            ))}
          </div>
        </div>
        {/* <Popup /> */}
      </div>
    );
}

export default totalStudentConv;