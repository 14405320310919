import React, { useState, useEffect, useLayoutEffect } from "react";
import "./index.css";

import ReactTooltip from "react-tooltip";
import { NavLink } from "react-router-dom";
import {
  FaRegQuestionCircle,
  FaCalendarAlt,
  FaFileAlt,
  FaChartPie,
  FaStickyNote,
  FaPowerOff,
} from "react-icons/fa";

import Popup from "../../teacher/popup/popup";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SchoolIcon from "@material-ui/icons/School";
import SideDrawerSales from "../../../UI/sideDrawer/sideDrawerSales";
import { useSelector, useDispatch } from "react-redux";
import { salesAnalytics } from "../../../crud/sales.crud";
import { removeToken } from '../../../utils';
import { deleteSales } from '../../../redux/actions/sales';
import TotalStudentConv from "./TotalStudentComverted/totalStudentConv";
import TotalStudentRenConv from "./TotalStudentRenewal/totalStudRenConv";
import { MenuOutlined, BellOutlined, HomeOutlined } from "@ant-design/icons";

function SalesStatistics(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [analData, setAnalData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const user = useSelector((state) => state.salesDetails.sales);

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(deleteSales(user.sales_id))
    removeToken();
  }

  useEffect(() => {
    salesAnalytics(user.sales_employee_id)
      .then((res) => {
        setAnalData(res);
        setIsLoaded(true);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="salesStatistics_detail_layout">
      {/* Its Css in the sales and UI Sider compenent */}
      <div className="header-wdt-100">
        <header className="Header">
          <HomeOutlined className="logo" />
          <span className="heder-men-not">
            <SideDrawerSales
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />

            <MenuOutlined className="menu" onClick={sideDrawerToggler} />
          </span>
        </header>
      </div>

      <div className="sider-SalesStatistics-detail">
        <span style={{ color: "white" }}>
          <div className="sider-hc-teacher-list-in-wdt-80">
            <NavLink to="/sales-dashboard">
              <DashboardIcon
                data-tip="Dashboard"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/sales-my-students">
              {" "}
              <SchoolIcon data-tip="My Students" className="icon-in-hc-color" />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/sales-statistics">
              <AssessmentIcon
                data-tip="Analytics"
                className="icon-in-hc-color"
                style={{ color: '#dc3545' }}
              />
              <ReactTooltip />
            </NavLink>
            <NavLink to="/sales-trial-student-form/:salesId">
              <AccountBalanceIcon
                data-tip="Trial Student Form"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink>
            {/* <NavLink to="/rm-statistics">
              <FaRegQuestionCircle
                data-tip="Help Center"
                className="icon-in-hc-color"
              />
              <ReactTooltip />
            </NavLink> */}
            <NavLink to="/" onClick={() => handleLogout()}>
              <PowerSettingsNewIcon
                data-tip="Logout"
                className="icon-in-hc-color"
              />
            </NavLink>
          </div>
        </span>
      </div>

      <div className="content-in-SalesStatistics-layout">
        <TotalStudentConv analData={analData} isLoaded={isLoaded} />
        <TotalStudentRenConv
          user={user}
          analData={analData}
          isLoaded={isLoaded}
        />
      </div>
      {/* <Popup /> */}
    </div>
  );
}

export default SalesStatistics;
