import React, { useLayoutEffect, useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import LoginModal from "../../../components/Login_modal/loginModal";
import CustomNav from "../../../components/header/Navbar";
import Background from "./bgcanvas.png";
import Footer from "../../../components/Footer";
import pic_5 from "../../student/dashboard/img/pic_5.png";
import Popup2 from "../../non_auth/new_popup/popup2";
import "./common.css";
Modal.setAppElement("#root");

function EngSpeak() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section style={{ width: "100%" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "80%", marginTop: "5%" }}>
            <div
              style={{
                color: "#dc3545",
                fontSize: 24,
                fontWeight: 700,
                lineHeight: "1.6",
                textAlign: "left",
              }}
            >
              English Speaking Course
            </div>

            <div
              style={{
                marginTop: "2%",
                marginBottom: "2%",
                textAlign: "left",
                fontSize: 18,
                fontWeight: 700,
              }}
            >
              Enroll in English Speaking Course for Comprehensive Preparation!
            </div>

            <div
              style={{
                marginTop: "2%",
                borderStyle: "solid",
                borderWidth: "5px",
                borderColor: "#dc3545",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "1%",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 5 }}>
                    Date of Commencement
                  </div>
                  <div style={{ fontWeight: 600 }}>Open Enrollment</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>Mode</div>
                  <div style={{ fontWeight: 600 }}>Offline/Online</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>
                    Duration
                  </div>
                  <div style={{ fontWeight: 600 }}>According to Plan</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>Fee</div>
                  <div style={{ fontWeight: 600 }}>According to Plan</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>
                    Admission
                  </div>
                  <div style={{ fontWeight: 600 }}>Open</div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ fontWeight: 500, marginBottom: 10 }}>
                    Class Timing
                  </div>
                  <div style={{ fontWeight: 600 }}>Flexible</div>
                </div>
              </div>

              <div
                style={{ display: "flex", marginLeft: 20, marginRight: 20 }}
                className="addrs"
              >
                <div
                  style={{
                    borderStyle: "solid",
                    borderWidth: 3,
                    borderColor: "#000",
                    padding: 20,
                    width: "60%",
                    marginTop: "2%",
                    marginBottom: "2%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "-42px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#1d74ca",
                        width: 200,
                        padding: 8,
                        borderStyle: "solid",
                        borderWidth: 3,
                        borderColor: "#000",
                      }}
                    >
                      <h2
                        style={{
                          color: "#fff",
                          fontWeight: 600,
                          fontSize: 20,

                          textAlign: "center",
                        }}
                      >
                        Location
                      </h2>
                    </div>
                  </div>
                  <br />
                  <div style={{ fontWeight: 600 }}>
                      311 Vardhman Sunder Plaza, Plot No. 12 MLU Sec - 12
                      Dwarka, New Delhi 110078
                    </div>
                    <br />
                    <div style={{ fontWeight: 600 }}>
                      42 Second Floor, Omaxe Galleria, Sector 14, Jhajjar Road,
                      Bahadurgarh, Haryana - 124-507
                    </div>
                </div>

                <div
                  style={{
                    borderStyle: "solid",
                    borderWidth: 3,
                    borderColor: "#000",
                    padding: 20,

                    marginTop: "2%",
                    marginBottom: "2%",
                    marginLeft: "5%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "-42px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#1d74ca",
                        width: 150,
                        padding: 8,
                        borderStyle: "solid",
                        borderWidth: 3,
                        borderColor: "#000",
                      }}
                    >
                      <h2
                        style={{
                          color: "#fff",
                          fontWeight: 600,
                          fontSize: 20,

                          textAlign: "center",
                        }}
                      >
                        Contact Us
                      </h2>
                    </div>
                  </div>
                  <br />
                  <div style={{ fontWeight: 600 }}>9570-424242</div>
                  <br />
                  <div style={{ fontWeight: 600 }}>9647-787878</div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "2%",
                  }}
                >
                  <Link to="/trial-student-form">
                    <div className="book-btn">Book Free Trial</div>
                  </Link>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "2%",
                    marginLeft: 10,
                  }}
                >
                  <a href="tel:+919570424242">
                    <div className="cons-btn">Call for Free Consultation</div>
                  </a>
                </div>
              </div>
            </div>
            <div style={{ marginBottom: "2%" }}>
              <h2
                style={{
                  color: "#56A5F5",
                  fontWeight: 600,
                  fontSize: 20,
                  marginTop: "3%",
                }}
              >
                About The Right Guru Classroom Programme
              </h2>
              <p
                style={{
                  fontSize: 16,
                  marginTop: "1%",
                  fontWeight: 500,
                  lineHeight: "1.6",
                }}
              >
                Since 2021, The Right Guru (TRG) has been a prominent player in
                the field of quality education, particularly for competitive
                exams like the SSC CGL, CHSL, MTS, CUET, DSSSB, CLAT, and also
                catering to CBSE/ICSE and other board examinations. With a
                dedicated team and a commitment to excellence, TRG understands
                the unique requirements of aspirants striving to excel in these
                competitive exams and contribute to their career growth.
              </p>
            </div>
            <div style={{ marginBottom: "2%" }}>
              <h2
                style={{
                  color: "#56A5F5",
                  fontWeight: 600,
                  fontSize: 20,
                  marginTop: "3%",
                }}
              >
                Why Choose TRG?
              </h2>
              <p
                style={{
                  fontSize: 16,
                  marginTop: "1%",
                  fontWeight: 500,
                  lineHeight: "1.6",
                }}
              >
                At TRG, we are committed to nurturing academic growth, fostering
                a deep understanding of subjects, and providing personalized
                attention to every student. Our diverse teaching methods and
                experienced educators create an enriching learning environment.
              </p>
            </div>
            <div style={{ marginBottom: "2%" }}>
              <h2
                style={{
                  color: "#56A5F5",
                  fontWeight: 600,
                  fontSize: 20,
                  marginTop: "3%",
                }}
              >
                English Speaking Course
              </h2>
              <p
                style={{
                  fontSize: 16,
                  marginTop: "1%",
                  fontWeight: 500,
                  lineHeight: "1.6",
                }}
              >
                TRG's English speaking program is designed for everyone, whether
                you're a beginner, have some knowledge, or aim to improve your
                fluency.
                <br />
                Enjoy small classes for personalized attention and useful
                feedback, ensuring a smooth learning experience.
                <br />
                Our course covers the basics of English and includes engaging
                group activities like Group Discussions, Debates, Role Plays,
                and more.
                <br />
                We kickstart your journey with a quick level check through a
                simple question test. This helps us place you in the right
                course level alongside peers of similar skills.
              </p>
            </div>

            <div
              style={{
                marginBottom: "2%",
                marginTop: "2%",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <table style={{ width: "100%" }}>
                <tr>
                  <td colSpan={3} className="heade" style={{ fontWeight: 700 }}>
                    GENERAL ENGLISH PROGRAM
                  </td>
                </tr>
                <tr>
                  <th style={{ fontWeight: 600 }}>Levels</th>
                  <th style={{ fontWeight: 600 }}>Duration</th>
                  <th style={{ fontWeight: 600 }}>Fees</th>
                </tr>
                <tr>
                  <td style={{ fontWeight: 600 }}>Basic</td>
                  <td>3 months/ 60 classes</td>
                  <td>14999 /- (4999/- per month)</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 600 }}>Intermediate</td>
                  <td>3 months/ 60 classes</td>
                  <td>14999 /- (4999/- per month)</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 600 }}>Advanced</td>
                  <td>1.5 months/ 30 classes</td>
                  <td>7499/-</td>
                </tr>

                <tr>
                  <td
                    colSpan={3}
                    className="heade"
                    style={{ fontWeight: 600, color: "#000" }}
                  >
                    We also provide Personalized Classes @ 1200/- per hour
                    <br />
                    Study material also included
                  </td>
                </tr>
              </table>
            </div>

            <p
              style={{
                fontSize: 16,
                marginTop: "1%",
                fontWeight: 500,
                lineHeight: "1.6",
                marginBottom: "5%",
              }}
            >
              For any query, Email us at:{" "}
              <a
                href="mailto:contact@therightguru.com"
                style={{ color: "#dc3545" }}
              >
                contact@therightguru.com
              </a>
              <br />
              <br />
              For a more personalized and detailed understanding, we encourage
              you to visit our centers and consult our dedicated counselors.
            </p>

            {/* <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginBottom: "5%",
              }}
            >
              <Link to="/">
                <div className="book-btn">Know more...</div>
              </Link>
            </div> */}
          </div>
        </div>
      </section>

      <section>
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default EngSpeak;
