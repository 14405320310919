import React, { useState, useEffect, useLayoutEffect } from 'react';
import './Status.css';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import img5834_1_1_2x from '../img/5834-1-1@2x.png';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';
import moment from "moment";
import { teacherClassrooms } from "../../../../crud/teacher.crud";
// import pic_2 from "../../student/dashboard/img/pic_2.png";
import pic_2 from "../../../student/dashboard/img/pic_2.png";

import { Button } from "react-bootstrap";

function Status({ upClass }) {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const [classrooms, setClassrooms] = useState([]);

    useEffect(() => {
        teacherClassrooms(user.teacher_id)
        .then((res) => {
            if (res.hasOwnProperty("message")) {
            setClassrooms(false);
            } else setClassrooms(res);
        })
        .catch((err) => console.log(err));
  }, []);
    
    const user = useSelector((state) => state.teacherDetails.teacher);

    const startClass = () => {
        let class_date = moment(upClass.datetime).minutes(
            moment(upClass.datetime).minutes() - 10
        );
        if (moment().isBefore(class_date)) {
            alert("Can't start class before 10 minutes of start time.");
        } else {
            window.location.replace("https://class.therightguru.com/" + upClass.id + "&" + upClass.teacher_id.replace("@", "_at_").replace(/\./g, " "));
        }
    }
        return(
            <div className="status-display">
                <div className="status1">
                    <div className="content-teacher">
                        <div className="content-teacher-in">
                            Welcome back, <span style={{ fontWeight: 'bold', paddingLeft: '58px' }}>{user.first_name} {user.last_name}!</span>
                        </div>
                        {/* <div className="content-status">
                            <p>
                                Your student completed <span style={{ color: 'white' }}>80%</span> of the tasks.
                            </p>
                            <p style={{ paddingTop: '5px' }}>
                                Progress is <span style={{ color: 'white' }}>very good</span>
                            </p>
                        </div> */}
                    </div>
                    <div className="Logo-in">
                        <img alt="image" className="x5834-1-C61RwL" src={img5834_1_1_2x} />
                    </div>
                </div>

                {
                    upClass ? (
                        <div className="status2">
                            <div className="content-teacher">
                            <div className="content-teacher-in">
                                Upcoming Class
                            </div>
                            <div className="content-status">
                                <p>
                                {upClass.subject_id} - <span style={{ color: '#F98131' }}>{upClass.class_type}</span>
                                </p>

                            </div>
                            <Button className="class-buttons" variant="success" onClick={() => startClass()}>Start Class</Button>
                            </div>
                            <div className="Logo-in">
                            <div className="font-family-mont">
                                <p className="font-family-mont-1_1p">{moment(upClass.datetime).format("dddd").toUpperCase()}</p>
                                <p className="font-family-mont-2_2p">{moment(upClass.datetime).format("LL").toUpperCase()}</p>
                                <span className="font-family-mont-3_3p">{moment(upClass.datetime).format("HH:mm")}</span>
                            </div>
                            </div>
                        </div>
                    ) : (
                        <div className="status2">
                            <div className="content-teacher">
                                <div className="content-teacher-in">
                                        No Upcoming Class
                                </div>
                            </div>
                        </div>
                    )
                }


                <div className="status3">
                    <div style={{width:'100%'}}>
                        <div className="content-teacher-in-my">
                            <div className ="my-class-v">
                                <span className="my-class-v-in">
                                    My Classrooms
                                </span>
                                <Link to="/teacher-students">
                                    <p className="content-right">
                                        View all
                                    </p>
                                </Link>
                            </div>
                        </div>
                        <div className="content-status">
                            {
                                classrooms.length > 4 ? (
                                    classrooms.slice(0, 4).map(c => {
                                        return (
                                            <div className="content-status-in">
                                                <CastForEducationIcon className="cast-for-edu" />
                                                <p>{c.classroom_name} -
                                                    <span style={{ color: 'rgb(220, 53, 69)' }}>{c.subjectID} {c.gradeID}</span>
                                                </p>
                                            </div>   
                                        )
                                    })
                                ) : classrooms.length > 0 ? (
                                        classrooms.map(c => {
                                            return (
                                                <div className="content-status-in">
                                                    <CastForEducationIcon className="cast-for-edu" />
                                                    <p>{c.classroom_name} -
                                                        <span style={{ color: 'rgb(220, 53, 69)' }}>{c.subjectID} {c.gradeID}</span>
                                                    </p>
                                                </div>   
                                            )
                                        })
                                ) : (
                                        <div className="content-status-in">
                                            {/* <CastForEducationIcon className="cast-for-edu" /> */}
                                            <p>No classrooms</p>
                                        </div>        
                                )  
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
};

export default Status;