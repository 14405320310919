import { DELETE_RM, GET_RM, ADD_ISSUE } from '../actions/rm/types'

const initialState = {
    issues: []
}

export default function getRmDetailsReducer(state = initialState, action) {
    switch(action.type) {
        case GET_RM:
            return {
                ...state,
                rm: action.payload
            };
        case DELETE_RM:
            return {
                issues: []
            }
        case ADD_ISSUE:
            return {
                ...state,
                issues: [...state.issues, action.payload]
            }
        default:
            return state
    }
}