import React, { useState, useEffect, useLayoutEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { NavLink } from "react-router-dom";
import line_5_2x from "./img/line-5@2x.svg";
import line_6_2x from "./img/line-6@2x.svg";
import line_5_1_2x from "./img/line-5-1@2x.svg";
import line_6_1_2x from "./img/line-6-1@2x.svg";
import vector_1_2x from "./img/vector-1@2x.svg";
import rectangle_7_1x from "./img/rectangle-7@1x.png";
import vector_5_2x from "./img/vector-5@2x.svg";
import vector_6_2x from "./img/vector-6@2x.svg";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SchoolIcon from "@material-ui/icons/School";
import SideDrawerSales from "../../../UI/sideDrawer/sideDrawerSales";
import { salesMyStudent } from "../../../crud/sales.crud";
import { removeToken } from '../../../utils';
import { deleteSales } from '../../../redux/actions/sales';
import { useSelector, useDispatch } from "react-redux";
import Popup from "../../teacher/popup/popup";
import pic_2 from "../../student/dashboard/img/pic_2.png";

function My_students(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [myStudents, setMyStudents] = useState([]);
  const user = useSelector((state) => state.salesDetails.sales);
  const [handleFilter, setHandleFilter] = useState('');


  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(deleteSales(user.sales_id))
    removeToken();
  }

  useEffect(() => {
    salesMyStudent(user && user.sales_employee_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setMyStudents(false);
        } else setMyStudents(res);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="my_students-list"
      />
      <div className="container-center-horizontal">
        <div className="my_students-list-mobile screen" style={{height: "1000px"}}>
          <div className="flex-row-C61RwL">
            <div className="component-1-dXBp89">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "90px", marginTop: "-8%" }}
              />
              {/* <img className="line-5-Q41bBg" src={line_5_2x} />
              <img className="line-6-Q41bBg" src={line_6_2x} /> */}
            </div>
            <SideDrawerSales
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />

            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>
          <div className="group-127-C61RwL">
            <div className="list-of-students-2zqKZg">List of Students</div>
            <input
              type="text"
              placeholder="Filter List"
              style={{ width: "200px", marginLeft: "10px", marginTop: "5px" }}
              onChange={(e) => setHandleFilter(e.target.value)}
            />
            <div className="overlap-group1-2zqKZg">
              <div className="dashboard-24px-1-759J8x">
                <img className="vector-L9XHu9" src="img/vector@2x.png" />
              </div>
              <div className="group-45-759J8x">
                <div className="rectangle-20-aIq8ji-7-3">
                  <div className="roboto-bold-white-10px-2">S. No.</div>
                  <div className="roboto-bold-white-10px-2">Student Name</div>
                  <div className="roboto-bold-white-10px-2">
                    Trial Class Count
                  </div>
                  <div className="roboto-bold-white-10px-2">StudentStatus</div>
                  <div className="roboto-bold-white-10px-2">Class Status</div>
                </div>
              </div>
            </div>
            <div className="overlap-group-2zqKZg-0-i">
              {/* <div className="class-name-eVSctS roboto-medium-white-20px">Class Name</div>
                            <div className="s-no-eVSctS roboto-medium-white-20px">S. No.</div>s
                            <div className="student-name-eVSctS roboto-medium-white-20px">Student Name</div>
                            <div className="date-eVSctS roboto-medium-white-20px">Date</div>
                            <div className="time-eVSctS roboto-medium-white-20px">Time</div> */}
              <div className="rectangle-30-y1HRxC-mobile-10">
                <div className="nfkjbdajhbjd-fajdn3">
                  {myStudents.length > 0 ? (
                    myStudents
                      .filter((res) => {
                        const test_temp =
                          res.student.first_name + " " + res.student.last_name;
                        if (handleFilter === "") {
                          return res;
                        } else if (
                          test_temp
                            .toLowerCase()
                            .includes(handleFilter.toLowerCase())
                        ) {
                          return res;
                        }
                      })
                      .map((item, index) => {
                        return (
                          <Link
                            to={`/sales-student-detail/${
                              item &&
                              item.student &&
                              item.student.trial_student_id
                            }`}
                          >
                            <div
                              key={item.id}
                              className="group-56-y1HRxC-mobile-10"
                            >
                              <div className="payga4wk-z-bshl-Sgr7kw-mob-10 montserrat-semi-bold-blue-zodiac-10px">
                                {index + 1}
                              </div>
                              <div className="x499900-Sgr7kw-mob-10 montserrat-semi-bold-blue-zodiac-10px">
                                {item &&
                                  item.student &&
                                  item.student.first_name}{" "}
                                {item && item.student && item.student.last_name}
                              </div>
                              <div className="x07-dec-2020-Sgr7kw-mob-10 montserrat-semi-bold-blue-zodiac-10px">
                                {item &&
                                  item.student &&
                                  item.student.trial_class_count}
                              </div>
                              <div className="x014009-pm-Sgr7kw-mob-10 montserrat-semi-bold-blue-zodiac-10px">
                                {item &&
                                  item.student &&
                                  item.student.student_status}
                              </div>
                              <div className="andb586-Sgr7kw-mob-10 montserrat-semi-bold-blue-zodiac-10px">
                                {item &&
                                  item.student &&
                                  item.student.class_status}
                              </div>
                            </div>
                          </Link>
                        );
                      })
                  ) : (
                    <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                      No occupied students
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-center-horizontal">
        <div className="my_students-list screen">
          <div className="sider-SalesStatistics-detail">
            <span style={{ color: "white" }}>
              <div className="sider-hc-teacher-list-in-wdt-80">
                <NavLink to="/sales-dashboard">
                  <DashboardIcon
                    data-tip="Dashboard"
                    className="icon-in-hc-color"
                  />
                  <ReactTooltip />
                </NavLink>
                <NavLink to="/sales-my-students">
                  {" "}
                  <SchoolIcon
                    data-tip="My Students"
                    className="icon-in-hc-color"
                    style={{ color: "#dc3545" }}
                  />
                  <ReactTooltip />
                </NavLink>
                <NavLink to="/sales-statistics">
                  <AssessmentIcon
                    data-tip="Analytics"
                    className="icon-in-hc-color"
                  />
                  <ReactTooltip />
                </NavLink>
                <NavLink to="/sales-trial-student-form/:salesId">
                  <AccountBalanceIcon
                    data-tip="Trial Student Form"
                    className="icon-in-hc-color"
                  />
                  <ReactTooltip />
                </NavLink>
                <NavLink to="/" onClick={() => handleLogout()}>
                  <PowerSettingsNewIcon
                    data-tip="Logout"
                    className="icon-in-hc-color"
                  />
                </NavLink>
              </div>
            </span>
          </div>
          <div className="flex-col-C61RwL">
            <div className="flex-row-0xy0vn">
              <div className="component-1-GyJUNH">
                <img src={pic_2} style={{ height: "115px", margin: "-8%" }} />
                {/* <img className="line-5-qWvJ7Z" src={line_5_1_2x} />
                <img className="line-6-qWvJ7Z" src={line_6_1_2x} /> */}
              </div>
              <div className="group-22-GyJUNH">
                <div className="flex-col-vgli2x">
                  <div className="biba-sobti-h8V8ZR">
                    {user && user.first_name + " " + user && user.last_name}
                  </div>
                  <div className="bibasobtigmailcom-h8V8ZR">
                    {user && user.emailID}
                  </div>
                </div>
                <img className="vector-vgli2x" src={vector_1_2x} />
              </div>
            </div>
            <div className="flex-row-oV5UrE">
              <div className="overlap-group-QkTteQs">
                <img className="rectangle-7-dlP8Ul" src={rectangle_7_1x} />
                <div className="group-203-dlP8Ul">
                  <div className="list-of-students-5GLwEc">
                    <span>List of Students</span>
                    <input
                      type="text"
                      placeholder="Filter List"
                      style={{
                        width: "210px",
                        marginRight: "5px",
                        color: "black",
                      }}
                      onChange={(e) => setHandleFilter(e.target.value)}
                    />
                  </div>
                  <div className="group-45-5GLwEc-ly">
                    <div className="rectangle-20-Cd7ClN-in-my">
                      <div className="roboto-medium-white-20px">S. No.</div>
                      <div className="roboto-medium-white-20px">Student</div>
                      <div className="roboto-medium-white-20px">
                        TrialClassCount
                      </div>
                      <div className="roboto-medium-white-20px">
                        StudentStatus
                      </div>
                      <div className="roboto-medium-white-20px">
                        Class Status
                      </div>
                    </div>
                  </div>
                  <div className="overlap-group1-5GLwEc-in-my">
                    <div className="rectangle-30-y1HRxC-10-c">
                      <div className="nfkjbdajhbjd-fajdn3">
                        {myStudents.length > 0 ? (
                          myStudents
                            .filter((res) => {
                              const test_temp =
                                res.student.first_name +
                                " " +
                                res.student.last_name;
                              if (handleFilter === "") {
                                return res;
                              } else if (
                                test_temp
                                  .toLowerCase()
                                  .includes(handleFilter.toLowerCase())
                              ) {
                                return res;
                              }
                            })
                            .map((item, index) => {
                              return (
                                <Link
                                  to={`/sales-student-detail/${
                                    item &&
                                    item.student &&
                                    item.student.trial_student_id
                                  }`}
                                  style={{ width: "100%" }}
                                >
                                  <div
                                    key={item.id}
                                    className="group-56-y1HRxC-9-ew"
                                  >
                                    <div className="payga4wk-z-bshl-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px">
                                      {index + 1}
                                    </div>
                                    <div className="x499900-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px">
                                      {item &&
                                        item.student &&
                                        item.student.first_name}{" "}
                                      {item &&
                                        item.student &&
                                        item.student.last_name}
                                    </div>
                                    <div className="x07-dec-2020-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px">
                                      {item &&
                                        item.student &&
                                        item.student.trial_class_count}
                                    </div>
                                    <div className="x014009-pm-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px">
                                      {item &&
                                        item.student &&
                                        item.student.student_status}
                                    </div>
                                    <div className="andb586-Sgr7kw-my-stu montserrat-semi-bold-blue-zodiac-10px">
                                      {item &&
                                        item.student &&
                                        item.student.class_status}
                                    </div>
                                  </div>
                                </Link>
                              );
                            })
                        ) : (
                          <div
                            style={{ fontFamily: "'Montserrat', Helvetica" }}
                          >
                            No occupied students
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Popup /> */}
    </>
  );
}

function Component1(props) {
  const { line5, line6 } = props;

  return (
    <div className="component-1 border-1px-blue-zodiac">
      <div className="overlap-group6">
        <img className="line-5" src={line5} />
        <img className="line-6" src={line6} />
      </div>
    </div>
  );
}
const component1Data = {
  line5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60599271a8f3ed5507ddebbe/img/line-5-4@2x.png",
  line6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/line-6@2x.png",
};

const My_studentsData = {
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-35@2x.png",
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1c6a4e78cf803e37e7fd/img/vector-29@2x.png",
  vector3:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1d433ce41a6c7995fc03/img/vector-9@2x.png",
  vector4:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058d518334660f0a4896cec/img/vector-193@2x.png",
  vector6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-228@2x.png",
  vector8:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector9:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-237@2x.png",
  vector10:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector11:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-166@2x.png",
  vector12:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-162@2x.png",
  vector13:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-163@2x.png",
  vector14:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-162@2x.png",
  vector15:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-164@2x.png",
  vector16:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-33@2x.png",
  vector17:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector18:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-38@2x.png",
  ellipse2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/ellipse-2@2x.png",
  title: "Biba Sobti",
  bibasobtiGmailCom: "bibasobti@gmail.com",
  vector19:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-36@2x.png",
  rectangle7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60588e6f3f36904103fcf791/img/rectangle-7-2@1x.png",
  vector20:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  className: "Class Name",
  studentName: "Student Name",
  place: "Date",
  time: "Time",
  listOfStudents: "List of Students",
  sNo: "S. No.",
  student: "Student",
  rollNumber: "Roll Number",
  grade: "Grade",
  contactNumber: "Contact Number",
  openIssueList: "Open Issue List",
  number: "1",
  number2: "2",
  vatsalAgarwal: "Vatsal Agarwal",
  inProgess: "In Progess",
  shreyansJain: "Shreyans Jain",
  pending: "Pending",
  kunalKashyap: "Kunal Kashyap",
  ticket: "Ticket",
  price: "SOS",
  price2: "SOS",
  status: "Status",
  sNo2: "S.No.",
  studentName2: "Student Name",
  category: "Category",
  number3: "3",
  number4: "4",
  number5: "5",
  number6: "6",
  number7: "7",
  number8: "8",
  number9: "9",
  place2: "Standard",
  vector21:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1c6a4e78cf803e37e7fd/img/vector-26@2x.png",
  messages: "Messages",
  vector22:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1c6a4e78cf803e37e7fd/img/vector-25@2x.png",
  component1Props: component1Data,
};

export default My_students;
