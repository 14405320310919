import React from 'react';
import { useSelector } from "react-redux";
import './status.css'
import Popup from "../../../teacher/popup/popup"
import pic_2 from "../../../student/dashboard/img/pic_2.png";
function Status(props){
    const user = useSelector((state) => state.hcDetails.hc);

    return (
      <div className="Status-layout-wdt-100">
        {/* <div className="logo-in-hc-st-teacher-view" /> */}
        <img alt="image" src={pic_2} style={{ height: "90px", marginLeft: "-70%" }} />
        <button className="button-comp-mar-col-2" onClick={props.changeTeacher}>
          Change Teacher
        </button>
        <div className="hc-status-view-1">
          <div className="hc-status-view-in">
            <span className="hc-status-head-in">Name</span>
            <span>
              {props.teacherPersonal.first_name}{" "}
              {props.teacherPersonal.last_name}
            </span>
          </div>
          <div className="hc-status-view-in">
            <span className="hc-status-head-in">ID</span>
            <span>{props.teacherPersonal.teacher_id}</span>
          </div>
          <div className="hc-status-view-in">
            <span className="hc-status-head-in">Rating</span>
            <span>{props.teacherData.rating}</span>
          </div>
          <div className="hc-status-view-in">
            <span className="hc-status-head-in">Badge</span>
            <span>{props.teacherData.badge}</span>
          </div>
          <div className="hc-status-view-in">
            <span className="hc-status-head-in">Number of Students</span>
            <span>
              {props.teacherData.students
                ? props.teacherData.students.length
                : ""}
            </span>
          </div>
          <div className="hc-status-view-in">
            <span className="hc-status-head-in">Number of Classroom</span>
            <span>
              {props.teacherData.classrooms
                ? props.teacherData.classrooms.length
                : ""}
            </span>
          </div>
        </div>
        <div className="hc-status-view-2">
          <div className="content-teacher-op-hc">Expertise</div>
          <div className="content-table-hc">
            <span>Teacher ID</span>
            <span>Subject</span>
            <span>Grade</span>
          </div>

          {props.teach ? (
            props.teach.length > 0 ? (
              props.teach.map((eachTeach) => {
                return (
                  <div className="status-2-hc">
                    <div className="status-2-hc-in">
                      <span className="table-item-hc-status-2">
                        {eachTeach.teacher}
                      </span>
                      <span className="table-item-hc-status">
                        {eachTeach.subject}
                      </span>
                      <span className="table-item-hc-status-2">
                        {eachTeach.grade}
                      </span>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>No length</div>
            )
          ) : (
            <div>No data</div>
          )}
        </div>
        {/* <Popup /> */}
      </div>
    );
}

export default Status;