import React, { useState } from "react";
import { TextField, Button } from "@mui/material";

import "./popup.css";

function Popup(props) {
  return props.trigger ? (
    <div className="main">
      <div className="container">
        <button className="closebtn" onClick={() => props.setTrigger(false)}>
          &#9587;
        </button>
        <div className="heading">ENQUIRY FORM</div>
        <form action="">
          <div>
            <TextField
              fullWidth
              type="text"
              id="outlined-basic"
              label="Your Name"
              variant="outlined"
              size="small"
              margin="normal"
              required
            />
          </div>
          <div>
            <TextField
              fullWidth
              type="number"
              id="outlined-basic"
              label="Phone Number"
              variant="outlined"
              size="small"
              margin="normal"
              inputProps={{ maxLength:"10" }}
              required
            />
          </div>
          <div>
            <TextField
              fullWidth
              type="email"
              id="outlined-basic"
              label="Email"
              variant="outlined"
              size="small"
              margin="normal"
              required
            />
          </div>
          <div>
            <TextField
              id="outlined-multiline-static"
              label="Query"
              margin="normal"
              multiline
              rows={4}
              fullWidth
              required
            />
          </div>
          <div
            style={{
              marginTop: "50px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button style={{ backgroundColor: "#dc3545" }} variant="contained">
              Submit
            </Button>
            {props.children}
          </div>
        </form>
      </div>
    </div>
  ) : (
    ""
  );
}

export default Popup;
