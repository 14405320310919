import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import CountUp from "react-countup";
import { Icon, Image } from "semantic-ui-react";
import "./styles.css";
import CustomNav from "../../../components/header/Navbar";
import Welcome from "../../../components/Welcome";
import Footer from "../../../components/Footer";

import Popup2 from "../../non_auth/new_popup/popup2";

import LoginModal from "../../../components/Login_modal/loginModal";
import GuideModal from "../../../components/Guide_modal/guideModal";
import DownloadStrip from "../../../components/header/DownloadStrip";

import OurToppers from "./OurToppers";
import TrgApp from "./TrgApp";
import AddressInfo from "./AddressInfo";
import Corosal from "./corosal_pic/Corosal";

import Popup from "../../non_auth/popup/Popup";
import Guru from "../../non_auth/faculty_details/Guru";
import Background from "../../../components/icons/bgcanvas.png";
import OurServices_Courses from "./OurServices_Courses";
import StudentReviews from "./StudentReviews";
import axios from "axios";

Modal.setAppElement("#root");

function Landing() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const aboutUsRef = useRef(null);
  const visionRef = useRef(null);

  const executeAboutUsScroll = () => aboutUsRef.current.scrollIntoView();
  const executeVisionScroll = () => visionRef.current.scrollIntoView();

  const [reasonValue, setReasonValue] = useState(0);
  const [showStudentGuide, setShowStudentGuide] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => setIsModalOpen((previousState) => !previousState);

  function closeGuideModal() {
    setShowStudentGuide(false);
  }

  const [timePop, setTimePop] = useState(false);

  const [data, setData] = useState(null);

  // Function to fetch data from the API
  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.therightguru.com/api/announcement-entry/');
      setData(response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
      console.log(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Fetch data when component mounts
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTimePop(true);
    }, 3000);
  }, []);

  return (
    <>
      <div className="landing-page-layout-100">
        <DownloadStrip />
        <div className="pb-5">
          <CustomNav Open={closeModal} />

          {/* <Navbar1 /> */}
          {/* <Nav /> */}

          {/* { { carousel  } } */}

          <section
            style={{
              // backgroundImage: `url(${Background})`,
              // backgroundPosition: "center",
              // backgroundSize: "cover",
              // backgroundRepeat: "repeat",
              paddingTop: "5%",
              paddingBottom: "2%",
            }}
          >
            <Corosal />
          </section>
        </div>

        <hr
          style={{ height: 10, width: "100%", color: "#dc3545" }}
          className="mx-auto"
        />

        {/* <Row style={{ marginTop: "5%" }}> */}
        {/* <Col md={6} className="tagg">
            <section id="tag-and-button" ref={aboutUsRef}>
              <Container fluid>
                <div className="justify-content-center">
                  <div className="tagline text-center">
                    <h1>Live 1:1 & Group Online Classes</h1>
                    <h6>
                      'The Right Guru’ (TRG) provides most structured,
                      integrated, virtual platform. It helps to inspire,
                      encourage, and empower learners.
                      <br />
                      <br />
                      Primary element of TRG is to provide an independent
                      environment to learners by connecting them directly to
                      gurus.
                    </h6>
                  </div>
                  <div className="text-center tag-button">
                    <Link to="/trial-student-form">
                      <Button
                        variant="danger"
                        style={{
                          fontFamily:
                            "'Montserrat', Arial, Helvetica, sans-serif",
                          fontWeight: "600",
                          fontSize: "14px",
                          padding: "14px",
                          width: "inherit",
                        }}
                      >
                        Book FREE Trial Class
                      </Button>
                    </Link>
                  </div>
                  <div className="text-center tag-button">
                    <div>
                      <Link
                        style={{ fontWeight: 600 }}
                        onClick={() => setShowStudentGuide(!showStudentGuide)}
                      >
                        How it Works?
                      </Link>
                    </div>
                  </div>
                </div>
              </Container>
            </section>
          </Col> */}
        {/* <Col md={6} className="mx-auto"> */}
        <Col className="my-5">
          <Welcome />
        </Col>
        {/* </Row> */}

        {/* added branches  */}
        {/* <Row >
          <Col md={8} className="tagg">
            <section id="branches" >
              <div className="tagline">

                <h1>Our Branches : </h1>
                <ul>
                  <li> <h6>42, Second Floor, Omaxe Galleria, Sector-14, Jhajjar Road, Bahadurgarh-124507 (Delhi-NCR)</h6> </li>
                  <li> <h6>212 S/F Vardhman Sunder Plaza, MLU Plot No. 12, Sec - 12, Dwarka New Delhi - 110078</h6> </li>
                </ul>
              </div>
            </section>
          </Col>
        </Row> */}
        <section id="announcemet">
          <div style={{ width: "80%" }} className="divannounce">
            <h2
              className="mx-auto d-flex justify-content-center align-items-center"
              style={{
                fontWeight: 600,
                fontSize: 20,
                color: "#fff",
                backgroundColor: "#dc3545",
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              Annoucements
            </h2>
            <div
              className="annnouce"
              style={{
                margin: 10,
                display: "flex",
                flexDirection: "column",
                lineHeight: 2,
              }}
            > 
              {data && data.map((item, index) => (
                <a href={item.rediectLink} target="_blank" key={index}>
                  <p>
                    <span>&#9635; &nbsp;</span>
                    {item.title}
                  </p>
                </a>
              ))}
            </div>
          </div>
        </section>
        <hr
          style={{ height: 10, width: "100%", color: "#dc3545" }}
          className="mx-auto"
        />

        <section id="services">
          <h1
            style={{
              fontFamily: "'Montserrat', Arial, Helvetica, sans-serif",
              fontWeight: "700",
              fontSize: "x-large",
              textAlign: "center",
              margin: "5% 0",
            }}
          >
            OUR SERVICES & COURSES
          </h1>
          <OurServices_Courses />
          {/* <Container fluid>
            <Row className="justify-content-around">
              <Col md={3}>
                <Link to="/course-details">
                  <div className="card1">
                    <h3>Teaching - Online | Offline</h3>
                    <p className="small">
                      The right guru has come with a single platform for online
                      and offline classes with a very simple, structured and
                      relevant courses for our ambitious and nonstoppable
                      learners.
                    </p>
                    <div className="go-corner" href="#">
                      <div className="go-arrow">→</div>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={3}>
                <Link to="/study-material">
                  <div className="card1">
                    <h3>Study Material</h3>
                    <p className="small">
                      The right guru provides all subjects notes from 6 to 12
                      classes, prepared by subjects' experts according to the
                      latest syllabus.
                    </p>
                    <div className="go-corner" href="#">
                      <div className="go-arrow">→</div>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={3}>
                <Link to="/health-recreation">
                  <div className="card1">
                    <h3>Health, Fitness & Recreation</h3>
                    <p className="small">
                      The right guru not only ensure learners’ academic growth
                      but also their mental growth. We provide different and
                      diverse activities for the physical and mental wellness.
                    </p>
                    <div className="go-corner" href="#">
                      <div className="go-arrow">→</div>
                    </div>
                  </div>
                </Link>
              </Col>
            </Row>
          </Container> */}
        </section>

        <section id="our-toppers">
          <Container fluid>
            <h1
              style={{
                fontFamily: "'Montserrat', Arial, Helvetica, sans-serif",
                fontWeight: "700",
                fontSize: "35px",
                textAlign: "center",
                marginTop: "3%",
                marginBottom: "2%",
                color: "white",
              }}
            >
              TRG TOPPERS
            </h1>
            <h2
              style={{
                fontFamily: "'Montserrat', Arial, Helvetica, sans-serif",
                fontWeight: "600",
                marginBottom: "2%",
                textAlign: "center",
                margin: "0% 1%",
                color: "white",
              }}
            >
              &nbsp;
            </h2>

            <OurToppers />
            {/* <Row
              className="justify-content-around align-items-center"
              style={{ minHeight: "220px" }}
            >
              <Col md={3} className="text-center mt-xs-3">
                <CountUp
                  className="counter"
                  delay={3}
                  start={0}
                  end={1000}
                  duration={3}
                  suffix="+"
                />
                <span className="counter-label"> Students</span>
              </Col>
              <Col md={3} className="text-center mt-xs-3">
                <CountUp
                  className="counter"
                  delay={3}
                  start={0}
                  end={100}
                  duration={3}
                  suffix="+"
                />
                <span className="counter-label"> Teachers</span>
              </Col>
              <Col md={3} className="text-center mt-xs-3">
                <CountUp
                  className="counter"
                  delay={3}
                  start={0}
                  end={15}
                  duration={3}
                  suffix="+"
                />
                <span className="counter-label"> Courses</span>
              </Col>
            </Row>
            <Row
              className="justify-content-around align-items-center"
              style={{ minHeight: "220px" }}
            >
              <Col md={3} className="text-center mt-xs-3">
                <CountUp
                  className="counter"
                  delay={3}
                  start={0}
                  end={447}
                  duration={3}
                  suffix="+"
                />
                <span className="counter-label"> Live Classes</span>
              </Col>
              <Col md={3} className="text-center mt-xs-3">
                <CountUp
                  className="counter"
                  delay={3}
                  start={0}
                  end={113}
                  duration={3}
                  suffix="+"
                />
                <span className="counter-label"> Videos</span>
              </Col>
            </Row> */}
          </Container>
        </section>

        {/* <section id="why-choose-us">
          <h1
            style={{
              fontFamily: "'Montserrat', Arial, Helvetica, sans-serif",
              fontWeight: "700",
              fontSize: "x-large",
              textAlign: "center",
              margin: "5% 0",
            }}
          >
            WHY CHOOSE US?
          </h1>
          <div className="container-fluid container-sm">
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingOne"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "rgb(220, 53, 69)" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    Personalized learning
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    'The right guru' provides a customised learning platform. we
                    provide learners a prescribed pathway through which they can
                    learn, connect, discover their potential and explore their
                    learning experience.
                    <br />
                    <br />
                    It is based on student's interests, needs and helps to
                    analyse their strength and area of improvement. we provide
                    comprehensive coverage with live interaction, practice
                    questions and quizzes to our learners.
                    <br />
                    <br />
                    During the entire learning process, we keep encouraging and
                    motivating our learners to make their journey smooth.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingTwo"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    Instant doubt solving forum
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    We provide a quick doubt solving platform where the learners
                    can ask doubts as many times as they want, anytime from
                    anywhere.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingThree"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    Learner oriented teaching
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    'The right guru' focuses on learners' interest by their
                    active participation in the structured curriculum
                    activities.
                    <br />
                    <br />
                    'The right guru' helps to make an independent environment of
                    interactive and engaging learning for the learners by
                    building a superior learning experience unlike any
                    traditional learning experience.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingfour"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapsefour"
                    aria-expanded="false"
                    aria-controls="flush-collapsefour"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    Performance assessment tool
                  </button>
                </h2>
                <div
                  id="flush-collapsefour"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingfour"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    The right guru' enables the learners to track and evaluate
                    their daily performances. Through the performance assessment
                    tool, both the educator and learners can assist the growth
                    and development of learners at various levels.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingfive"
                  style={{ paddingTop: "1%", paddingBottom: "1%" }}
                >
                  <button
                    className="accordion-button collapsed accordion-personalize-header"
                    style={{ color: "#dc3545" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapsefive"
                    aria-expanded="false"
                    aria-controls="flush-collapsefive"
                  >
                    <i
                      style={{ marginRight: "10px" }}
                      className="fab fa-acquisitions-incorporated"
                    ></i>
                    Healthy learning system
                  </button>
                </h2>
                <div
                  id="flush-collapsefive"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingfive"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    className="accordion-body accordion-personalize-body"
                    style={{ textAlign: "justify", lineHeight: "28px" }}
                  >
                    We understand the needs and expectations of the students,
                    hence 'Right Guru' connects the best teachers with the best
                    technology to create a cohesive and harmonious world-class
                    learning platform for every student.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="how-we-are-different" ref={visionRef}>
          <div
            className="container-fluid container-sm"
            style={{
              paddingLeft: "8%",
              paddingRight: "8%",
              textAlign: "center",
              marginTop: "5%",
              marginBottom: "5%",
            }}
          >
            <div
              className="card-header"
              style={{
                color: "white",
                border: "8px solid #dc3545",
                borderRadius: "20px 20px 0 0",
                fontFamily: "'Montserrat', Arial, Helvetica, sans-serif",
                fontWeight: "700",
                fontSize: "x-large",
                background: "#dc3545",
              }}
            >
              HOW WE ARE DIFFERENT?
            </div>
            <div
              className="card-body"
              style={{
                border: "8px solid #dc3545",
                padding: "5% 8%",
                borderRadius: "0 0 20px 20px",
              }}
            >
              <h5 style={{ textAlign: "justify", fontSize: "20px" }}>
                'The Right Guru' (TRG) works on a principle of universalization
                of education. We thrive to bridge gender and social category
                gaps in education by providing universal access of education to
                all.
                <br />
                <br />
                We provide personalized learning programs based on an individual
                student's capabilities. We connect educators with learners and
                facilitate them in speeding up their new knowledge acquisitions.
                <br />
                <br />
                We focus on "conceptual clarification," "practical learning,"
                and "self-assessment" to counter learners' difficulty, which
                enhance level of every learner.
              </h5>
            </div>
          </div>
          {/* <h1 style={{ fontFamily: "'Montserrat', Arial, Helvetica, sans-serif", fontWeight: "700", fontSize: "x-large", textAlign: "center", margin: "5% 0" }}>
                        HOW WE ARE DIFFERENT?
                    </h1>
                    <div className="container-fluid container-sm" style={{ paddingLeft: "8%", paddingRight: "8%"}}>
                        <h5 style={{ textAlign: "justify", fontSize: "20px" }}>
                            'The right guru' works on the principle of
                            universalization of education. We thrives to bridge
                            the gender and social category gaps in education by
                            providing universal access of education to all.
                            <br />
                            <br />
                            We provide personalized learning programs that are
                            based on student's capabilities. We connect our educators with the learners and facilitate them in
                            speeding up their new knowledge acquisitions.
                            <br />
                            <br />
                            We focus on " conceptual clarification", "practical
                            learning", and "self assessment" to counter learners'
                            levels of difficulty, that enhance the learning level of
                            every type of learners.
                        </h5>
                    </div> */}
        {/* </section>  */}

        <section id="teachers">
          <h1
            style={{
              fontFamily: "'Montserrat', Arial, Helvetica, sans-serif",
              fontWeight: "700",
              fontSize: "x-large",
              textAlign: "center",
              margin: "5% 0",
            }}
          >
            Reviews by Our Students
          </h1>
          {/* <Container fluid>
            <Row className="justify-content-around">
              <Col md={3} className="teacher-card">
                <div style={{ height: "300px" }}>
                  <Card
                    style={{
                      fontFamily: "'Montserrat', Arial, Helvetica, sans-serif",
                      margin: "auto",
                    }}
                  >
                    <Card.Img variant="top" src={samir} />
                    <Card.Body>
                      <Card.Title>MD. Sameer</Card.Title>
                      <Card.Text className="card-text-personal"></Card.Text>
                    </Card.Body>
                    <Card.Footer className="text-muted">
                      Accounts & Economics
                    </Card.Footer>
                  </Card>
                </div>
              </Col>
              <Col md={3} className="teacher-card" style={{ height: "420px" }}>
                <Card
                  style={{
                    fontFamily: "'Montserrat', Arial, Helvetica, sans-serif",
                    margin: "auto",
                  }}
                >
                  <Card.Img variant="top" src={pooja} />
                  <Card.Body>
                    <Card.Title>Pooja</Card.Title>
                  </Card.Body>
                  <Card.Footer className="text-muted">
                    PGT Geography
                  </Card.Footer>
                </Card>
              </Col>
              <Col md={3} className="teacher-card" style={{ height: "420px" }}>
                <Card
                  style={{
                    fontFamily: "'Montserrat', Arial, Helvetica, sans-serif",
                    margin: "auto",
                  }}
                >
                  <Card.Img variant="top" src={europa} />
                  <Card.Body>
                    <Card.Title>Europa</Card.Title>
                  </Card.Body>
                  <Card.Footer className="text-muted">PGT Biology</Card.Footer>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-center" style={{ marginTop: "5%" }}>
              <Button
                variant="danger"
                style={{
                  fontFamily: "'Montserrat', Arial, Helvetica, sans-serif",
                  fontWeight: "600",
                  fontSize: "14px",
                  padding: "14px",
                  width: "144px",
                }}
              >
                <Link to="/faculty-details">
                  <div style={{ color: "white" }}>SEE ALL</div>
                </Link>
              </Button>
            </Row>
          </Container> */}
          <div
            style={{
              width: "85%",

              margin: "0 auto", // Center the container horizontally
              display: "flex",
              justifyContent: "center",
              alignItems: "center", // Center the content vertically
            }}
          >
            <StudentReviews />
          </div>
        </section>

        <section id="trg-mobile-feature">
          <TrgApp />
        </section>

        <section id="address-info">
          <AddressInfo />
        </section>

        {/* <section id="testimonials">
                    <Container fluid>Testimonals</Container>
                </section> */}

        <section id="footer">
          <Footer refs={[executeAboutUsScroll, executeVisionScroll]} />
        </section>
        {/* <Popup trigger={timePop} setTrigger={setTimePop} /> */}
      </div>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
      <GuideModal isModalOpen={showStudentGuide} closeModal={closeGuideModal} />
    </>
  );
}

export default Landing;
