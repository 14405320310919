import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import "./notesStatus.css";
import { S3lnconfig } from "../../../../s3/s3lnconfig.js";
import S3FileUpload from "react-s3";
import { uploadNotes, getNotes } from "../../../../crud/teacher.crud";
// import pic_2 from "../../student/dashboard/img/pic_2.png";
import pic_2 from "../../../student/dashboard/img/pic_2.png";

import NotesIcon from "@material-ui/icons/Notes";
import DropdownOption from "../../../higher_committee/dropdown/dropdown2";

const NotesView = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const [notesUrl, setNotesUrl] = useState("");
  const [grade, setGrade] = useState("");
  const [subject, setSubject] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const [notes, setNotes] = useState([]);

  const [subject_id, setSubjectName] = useState("");
  const [grade_id, setGradeName] = useState("");

  const handleGradeSelect = (value) => {
    setGradeName(value);
  };

  const handleSubjectSelect = (value) => {
    setSubjectName(value);
  };

  useEffect(() => {
    getNotes(reduxUser.teacher_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setNotes(false);
        } else setNotes(res);
      })
      .catch((err) => console.log(err));
  }, [uploaded]);

  function upload(e) {
    S3FileUpload.uploadFile(e.target.files[0], S3lnconfig)
      .then((data) => {
        setNotesUrl(data.location);
      })
      .catch((err) => {
        alert(err);
      });
  }

  const handleSubmit = () => {
    if (notesUrl == "" || subject_id == "" || grade_id == "") {
      alert("Please select file, subject and grade to upload notes.");
    } else {
      uploadNotes({
        teacher_id: reduxUser.teacher_id,
        subject_id: subject_id,
        grade_id: grade_id,
        notes_url: notesUrl,
      })
        .then((res) => {
          alert("Notes uploaded successfully.");
          setNotesUrl("");
          setSubjectName("");
          setGradeName("");
          setUploaded(!uploaded);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className="width-in-notes-class">
      <div className="logo-in-class-10">
        <img
          alt="image"
          src={pic_2}
          style={{ height: "110px", marginTop: "-8%" }}
        />
      </div>

      <div className="input-in-lec-notes">
        <label for="file-input">+ Upload File</label>

        <input
          id="file-input"
          type="file"
          onChange={(e) => upload(e)}
          style={{ display: "none" }}
        />
      </div>

      <div className="status-1">
        <h3 className="head-in-lec-notes">Upload New Notes</h3>

        <div style={{ width: "90%", margin: "0 auto 5px auto" }}>
          <DropdownOption
            handleGradeSelect={handleGradeSelect}
            handleSubjectSelect={handleSubjectSelect}
          />
        </div>

        <button className="button-in-lec-notes" onClick={handleSubmit}>
          Submit
        </button>
      </div>

      <h3 className="head-in-lec-notes" style={{ marginTop: "20px" }}>
        All Notes
      </h3>

      <div className="status-2-all-notes">
        <div className="status-2-all-notes-in">
          {notes.length > 0 ? (
            notes.map((note) => {
              return (
                <div
                  key={note.details.notes_subject_id}
                  className="status-in-lec-notes"
                >
                  <a href={note.details.notes_url} target="_blank">
                    <div className="content-in-lecture-notes">
                      <div className="display-flex">
                        <NotesIcon className="cast-for-edu" />
                        <div>
                          <h4 className="notes-heading-font-color">
                            {note.details.notes_subject_id.slice(0, 20)}
                          </h4>
                          <p>{note.meta.subject_id}</p>
                        </div>
                      </div>
                      {/* <div>
                                                <BorderColorIcon className="Content-icon-in-lec-notes" />
                                                <DeleteIcon className="Content-icon-in-lec-notes" />
                                            </div> */}
                    </div>
                  </a>
                </div>
              );
            })
          ) : (
            <div
              className="status-2-all-notes-in"
              style={{ fontFamily: "'Montserrat', Helvetica" }}
            >
              No notes uploaded by you.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotesView;
