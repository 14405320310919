import React, { useState, useEffect, useLayoutEffect } from "react";
import "./style.css";
import { Link, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {
  FaRegQuestionCircle,
  FaCalendarAlt,
  FaFileAlt,
  FaChartPie,
  FaStickyNote,
  FaPowerOff,
} from "react-icons/fa";
import { MdAssignment, MdDashboard, MdNotificationsNone } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import { Button } from "react-bootstrap";
import {
  dashboardDoubts,
  curriculumList,
  curriculumDetail,
} from "../../../crud/student.crud";
import Popup from "../../teacher/popup/popup";
import pic_2 from "../dashboard/img/pic_2.png";
// images
// images
import line5_2x from "./img/line-5@2x.png";
import line5_1_2x from "./img/line-5-1@2x.png";
import line6_2x from "./img/line-6@2x.png";
import line6_1_2x from "./img/line-6-1@2x.png";
import vector_3_2x from "./img/vector-3@2x.png";
import rectangle_7 from "./img/rectangle-7@1x.png";
import ellipse_2_2x from "./img/ellipse-2@2x.png";

import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteStudent } from "../../../redux/actions/student";

import SideDrawerStudent from "../../../UI/sideDrawer/sideDrawerStudent";

function Studentcurriculumlist(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const user = useSelector((state) => state.studentDetails.student);
  const [doubts, setDoubts] = useState([]);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [courses, setCourses] = useState([]);

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  useEffect(() => {
    dashboardDoubts(user.enrolled_student_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setDoubts(false);
        } else setDoubts(res);
      })
      .catch((err) => console.log(err));
  }, []);

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteStudent(reduxUser.enrolled_student_id));
    removeToken();
  };

  useEffect(() => {
    curriculumList(user.enrolled_student_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setCourses(false);
        } else setCourses(res);
      })
      .catch((err) => console.log(err));
  }, []);

  const showCurriculum = (course) => {
    curriculumDetail(course.course, course.grade, course.subject, "Active")
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          alert("No curriculum found for this subject-grade.");
        } else {
          window.open(res.curriculum_url, "_blank");
          console.log("Curriculum opened.");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <form
        className="student-curriculum-list screen"
        name="form1"
        action="form1"
        method="post"
      >
        <div className="student-curriculum-col">
          <div className="student-re-flex">
            <div className="component-1-C61RwLz-3">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "115px", marginTop: "-8%" }}
              />
            </div>
            <div className="group-22-GyJUNH-student-dash">
              <div className="flex-col-vgli2x-student-dash">
                <div className="biba-sobti-h8V8ZR-student-dash">
                  {user.first_name + " " + user.last_name}
                </div>
                <div className="bibasobtigmailcom-h8V8ZR-student-dash">
                  {user && user.email}
                </div>
              </div>
              <img className="vector-vgli2x-student-dash" src={vector_3_2x} />
            </div>
          </div>

          <div className="my-curriculums-C61RwL">
            <span className="span0-iymWsl">My</span>
            <span className="span1-iymWsl"> Curriculums</span>
          </div>

          <div className="rectangle-43-C61RwL">
            {doubts.length > 0 ? (
              doubts.slice(0, 4).map((doubt) => {
                return (
                  <div className="doubt-row">
                    <FaRegQuestionCircle size={30} />
                    <Link>
                      <div
                        className="montserrat-semi-bold-blue-zodiac-18px"
                        style={{ color: "rgba(126,126,126,1.0)" }}
                      >
                        {doubt.doubt_text.length > 31
                          ? doubt.doubt_text.substring(0, 27) + "..."
                          : doubt.doubt_text}
                      </div>
                    </Link>
                    {/* <div className="view-forum-C61RwL montserrat-semi-bold-gray-16px">View</div> */}
                  </div>
                );
              })
            ) : (
              <div />
            )}
            <div className="doubt-forum-C61RwL montserrat-semi-bold-blue-zodiac-18px">
              Doubt Forum
            </div>
            <Link to="/student-doubt-list">
              <div className="view-all-C61RwL">View all</div>
            </Link>
          </div>

          <div
            style={{
              position: "absolute",
              top: "300px",
              left: "6%",
              width: "400px",
              height: "600px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
            }}
          >
            {courses.length > 0 ? (
              courses.map((course) => {
                return (
                  <div className="rectangle-40-C61RwL">
                    <div className="description-24px-2-Lt4Luf">
                      <FaFileAlt size={28} color="#dc3545" />
                    </div>
                    <div className="mathematics-grade-9-C61RwL montserrat-semi-bold-white-18px">
                      <span className="span0-Wwz8X4">
                        {course.subject}
                        <br />
                      </span>
                      <span className="span1-Wwz8X4">Grade {course.grade}</span>
                    </div>
                    <Button
                      variant="outline-danger"
                      className="rectangle-41-C61RwL"
                      style={{
                        fontFamily: "'Montserrat', Helvetica",
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                      onClick={() => showCurriculum(course)}
                    >
                      View
                    </Button>
                  </div>
                );
              })
            ) : (
              <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                No courses to show.
              </div>
            )}
          </div>
        </div>
        <div className="rectangle-24-C61RwL-stu-s1">
          <Link to="/student-dashboard">
            <div data-tip="Dashboard">
              <MdDashboard size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-date-calendar">
            <div data-tip="Date Calendar">
              <FaCalendarAlt size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-assignment-list">
            <div data-tip="Assignments">
              <MdAssignment size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-quiz-list">
            <div data-tip="Quizzes">
              <RiQuestionnaireFill size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-curriculum-list">
            <div data-tip="Curriculums">
              <FaFileAlt size={24} color="#dc3545" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-doubt-list">
            <div data-tip="Doubts">
              <FaRegQuestionCircle size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-performance" data-tip="Performance">
            <div>
              <FaChartPie size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-notes-list">
            <div data-tip="Lecture Notes">
              <FaStickyNote size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/" onClick={() => handleLogout()}>
            <FaPowerOff
              size={24}
              data-tip="Logout"
              color="rgba(179,179,179,1.0)"
            />
            <ReactTooltip />
          </Link>
        </div>
      </form>
      <form
        className="student-curriculum-mobile screen"
        name="form2"
        action="form2"
        method="post"
      >
        <div className="component-1-C61RwL">
          <img
            alt="image"
            src={pic_2}
            style={{ height: "90px", marginTop: "-8%" }}
          />
        </div>

        {/* This is the hamburger icons */}
        <SideDrawerStudent
          open={displaySideDrawer}
          closeSideDrawer={sideDrawerToggler}
        />
        <div className="component-6-C61RwL" onClick={sideDrawerToggler}>
          <div className="rectangle-48-5mfU7d"></div>
          <div className="rectangle-49-5mfU7d"></div>
          <div className="rectangle-50-5mfU7d"></div>
        </div>
        <div className="my-curriculums-C61RwL">
          <span className="span0-iymWsl">My </span>
          <span className="span1-iymWsl">Curriculums</span>
        </div>
        <div className="group-72-C61RwL">
          <div className="rectangle-40-gqbUX1"></div>
          <div className="component-29-gqbUX1">
            <div className="assignment-24px-3-lxV7ix">
              <FaFileAlt size={26} color="white" />
            </div>
          </div>
          <div className="assignment-1-gqbUX1 montserrat-semi-bold-blue-zodiac-10px">
            ASSIGNMENT 1
          </div>
          <div className="mathematics-dr-yashna-sharma-gqbUX1 montserrat-medium-gray-7px">
            Mathematics (Dr. Yashna Sharma)
          </div>
          <div className="submitted-gqbUX1 montserrat-semi-bold-niagara-8px">
            Submitted
          </div>
        </div>
        <div className="group-73-C61RwL">
          <div className="rectangle-40-ql7mN0"></div>
          <div className="component-29-ql7mN0">
            <div className="assignment-24px-3-MxHjgv">
              <FaFileAlt size={26} color="white" />
            </div>
          </div>
          <div className="assignment-1-ql7mN0 montserrat-semi-bold-blue-zodiac-10px">
            ASSIGNMENT 1
          </div>
          <div className="mathematics-dr-yashna-sharma-ql7mN0 montserrat-medium-gray-7px">
            Mathematics (Dr. Yashna Sharma)
          </div>
          <div className="submitted-ql7mN0 montserrat-semi-bold-niagara-8px">
            Submitted
          </div>
        </div>
        <div className="group-74-C61RwL">
          <div className="rectangle-40-aCi14x"></div>
          <div className="component-29-aCi14x">
            <div className="assignment-24px-3-DVuPOa">
              <FaFileAlt size={26} color="white" />
            </div>
          </div>
          <div className="assignment-1-aCi14x montserrat-semi-bold-blue-zodiac-10px">
            ASSIGNMENT 1
          </div>
          <div className="mathematics-dr-yashna-sharma-aCi14x montserrat-medium-gray-7px">
            Mathematics (Dr. Yashna Sharma)
          </div>
          <div className="submitted-aCi14x montserrat-semi-bold-niagara-8px">
            Submitted
          </div>
        </div>
        <div className="group-75-C61RwL">
          <div className="rectangle-40-sDLNVS"></div>
          <div className="component-29-sDLNVS">
            <div className="assignment-24px-3-z4xZZj">
              <FaFileAlt size={26} color="white" />
            </div>
          </div>
          <div className="assignment-1-sDLNVS montserrat-semi-bold-blue-zodiac-10px">
            ASSIGNMENT 1
          </div>
          <div className="mathematics-dr-yashna-sharma-sDLNVS montserrat-medium-gray-7px">
            Mathematics (Dr. Yashna Sharma)
          </div>
          <div className="submitted-sDLNVS montserrat-semi-bold-niagara-8px">
            Submitted
          </div>
        </div>
        <div className="group-57-C61RwL">
          {courses.length > 0 ? (
            courses.map((course) => {
              return (
                <div className="rectangle-40-AwXtS8">
                  {/* <div className="assignment-24px-3-qliRLH"> */}
                  <FaFileAlt size={30} color="#dc3545" />
                  {/* </div> */}
                  <div className="mathematics-grade-9-AwXtS8 montserrat-semi-bold-white-18px">
                    <span className="span0-A5sNXg">
                      {course.subject}
                      <br />
                    </span>
                    <span className="span1-A5sNXg">Grade {course.grade}</span>
                  </div>
                  <Button
                    variant="outline-danger"
                    className="rectangle-41-AwXtS8"
                    style={{
                      fontFamily: "'Montserrat', Helvetica",
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                    onClick={() => showCurriculum(course)}
                  >
                    View
                  </Button>
                </div>
              );
            })
          ) : (
            <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
              No courses found.
            </div>
          )}
        </div>
        <div className="group-85-C61RwL">
          <div className="rectangle-44-rFX9Nh">
            {doubts.length > 0 ? (
              doubts.slice(0, 4).map((doubt) => {
                return (
                  <div className="doubt-row">
                    <FaRegQuestionCircle size={30} />
                    <Link>
                      <div className="montserrat-semi-bold-blue-zodiac-18px">
                        {doubt.doubt_text.length > 31
                          ? doubt.doubt_text.substring(0, 27) + "..."
                          : doubt.doubt_text}
                      </div>
                    </Link>
                    {/* <div className="view-forum-C61RwL montserrat-semi-bold-gray-16px">View</div> */}
                  </div>
                );
              })
            ) : (
              <div />
            )}
          </div>
          <div className="doubt-forum-rFX9Nh">Doubt Forum</div>
          <Link to="/student-doubt-list">
            <div className="view-all-rFX9Nh">View all</div>
          </Link>
        </div>
      </form>
      <Popup />
    </>
  );
}

export default Studentcurriculumlist;
