import React, { useState, useLayoutEffect } from 'react';
import { Link, useHistory, Redirect, useParams } from "react-router-dom";
import Modal from 'react-modal';
import { Button } from 'react-bootstrap';
import { Form, Message } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { getStudent } from "../../../redux/actions/student";
import { login } from "../../../crud/auth.crud";
import { materialList, folderList } from '../../../crud/student.crud';
import { setToken } from "../../../utils";
import { Divider } from 'antd';
import './redirect.css';

import CustomNav from "../../../components/header/Navbar";
import Footer from '../../../components/Footer';
import BackDrop from "../../../UI/Backdrop/Backdrop";
import Dropdown2 from "../../higher_committee/dropdown/dropdown2";
import Dropdown_books from "../../higher_committee/dropdown/dropdown_books";
import Dropdown_lectureNotes from "../../higher_committee/dropdown/dropdown_lectureNotes";
import Dropdown_previousPpr from "../../higher_committee/dropdown/dropdown_previousPpr";
import Dropdown_samplePpr from "../../higher_committee/dropdown/dropdown_samplePpr";
import Dropdown_supportMaterial from "../../higher_committee/dropdown/dropdown_supportMaterial";
import Dropdown_syllabus from "../../higher_committee/dropdown/dropdown_syllabus";

Modal.setAppElement('#root');


const Study_Redirect = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const { material_type } = useParams();

  const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  const [material_list, setMaterialList] = useState([]);
  const [folder_list, setFolderList] = useState([]);
  const [subject_id, setSubjectName] = useState("");
  const [grade_id, setGradeName] = useState("");

  const handleGradeSelect = (value) => {
    setGradeName(value);
  };

  const handleSubjectSelect = (value) => {
    setSubjectName(value);
  };

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen(previousState => !previousState);


  const handleLogin = () => {
    setLoading(true);
    setIsErrorShown(false);

    if (!email || !password) {
      setLoading(false);
      setIsErrorShown(true);
    } else {
      login(email, password)
        .then(({ data: { access, refresh, email, user_type } }) => {
          setLoading(false);
          dispatch(getStudent({ access, email, user_type }, history));
          setToken(access, refresh);
          // history.replace("/student-dashboard");
        })
        .catch(() => {
          setLoading(false);
          setIsErrorShown(true);
        })
    }
  }

  const bookMaterialList = folder_id => {
    materialList(material_type, grade_id, subject_id, folder_id)
      .then(res => {
        setMaterialList(res);
        setFolderList([]);
      })
      .catch(err => console.log(err))
  }

  const searchMaterial = () => {
    if (material_type == "Books") {
      folderList(material_type, grade_id, subject_id)
        .then(res => {
          setFolderList(res);
          setMaterialList([]);
        })
        .catch(err => console.log(err))
    } else {
      materialList(material_type, grade_id, subject_id, "a")
        .then(res => {
          setMaterialList(res);
        })
        .catch(err => console.log(err))
    }
  }

  return (
    <div>
      <div className="landing-page-layout-100">
        <CustomNav Open={closeModal} />

        <div className="heading_box">
          <p>
            {(() => {
              switch (material_type) {
                case "LectureNotes":
                  return "Lecture Notes - NCERT Notes for All Classes";
                case "Syllabus":
                  return "Syllabus - Revised CBSE Syllabus for 2021-22";
                case "Books":
                  return "Books - Download Free PDFs of NCERT Books (1st to 12th)";
                case "SamplePapers":
                  return "Sample Papers - Class IX to XII Sample Paper 2021-22";
                case "PreviousYearPapers":
                  return "Previous Year Papers - for CBSE Class X & XII";
                case "SupportMaterial":
                  return "Support Material - for CBSE Class IXth to XII";
                default:
                  return "Study Material";
              }
            })()}
          </p>

          {/* <p>STUDY MATERIAL</p> */}
        </div>

        <div className="boxes_container content-class-study">
          <p style={{ margin: "20px" }}>
            Learning is a deep process, which have a major impact on the
            learners’ future as well as their present consciousness. In this
            stage of learning, students develop their interest to the academic
            fields, and for this they need to choose right materials for study
            to score good. The right guru provides all subjects notes from 6
            to 12 classes, prepared by subjects' experts according to the
            latest syllabus. These notes are packed up with basic concepts
            with realistic examples in an interactive manner, so that our
            learners understand topic easily and relate it to their real life.
            <br />
            <br />
            Want to have word with us book a trial class and come in{" "}
            <Link to="/contact-us"> contact us.</Link>
          </p>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="dropdownn row">
            <div className="col-sm-8">
              {(() => {
                switch (material_type) {
                  case "LectureNotes":
                    return (
                      <Dropdown_lectureNotes
                        handleGradeSelect={handleGradeSelect}
                        handleSubjectSelect={handleSubjectSelect}
                      />
                    );
                  case "Syllabus":
                    return (
                      <Dropdown_syllabus
                        handleGradeSelect={handleGradeSelect}
                        handleSubjectSelect={handleSubjectSelect}
                      />
                    );
                  case "Books":
                    return (
                      <Dropdown_books
                        handleGradeSelect={handleGradeSelect}
                        handleSubjectSelect={handleSubjectSelect}
                      />
                    );
                  case "SamplePapers":
                    return (
                      <Dropdown_samplePpr
                        handleGradeSelect={handleGradeSelect}
                        handleSubjectSelect={handleSubjectSelect}
                      />
                    );
                  case "PreviousYearPapers":
                    return (
                      <Dropdown_previousPpr
                        handleGradeSelect={handleGradeSelect}
                        handleSubjectSelect={handleSubjectSelect}
                      />
                    );
                  case "SupportMaterial":
                    return (
                      <Dropdown_supportMaterial
                        handleGradeSelect={handleGradeSelect}
                        handleSubjectSelect={handleSubjectSelect}
                      />
                    );
                  default:
                    return (
                      <Dropdown2
                        handleGradeSelect={handleGradeSelect}
                        handleSubjectSelect={handleSubjectSelect}
                      />
                    );
                }
              })()}
              {/* <Dropdown2
                  handleGradeSelect={handleGradeSelect}
                  handleSubjectSelect={handleSubjectSelect}
                /> */}
            </div>
            <div className="col-sm-4">
              <Button
                className="drop-button"
                variant="danger"
                onClick={searchMaterial}
              >
                Search
              </Button>
            </div>
          </div>

          {/* <div className="row"></div> */}
        </div>

        <div className="folder-list" style={{ margin: "auto", width: "50%" }}>
          {folder_list.length > 0 ? (
            folder_list.map((item) => {
              return (
                <div
                  style={{
                    margin: "auto",
                    backgroundColor: "green",
                    width: "50%",
                  }}
                >
                  <Button
                    className="drop-button"
                    variant="success"
                    onClick={() => bookMaterialList(item.id)}
                  >
                    {item.folder_name}
                  </Button>
                </div>
              );
            })
          ) : (
            <div></div>
          )}
        </div>

        <div className="material-list-css">
          {material_list && material_list.length > 0 ? (
            material_list.map((item) => {
              return <ItemTile material={item} />;
            })
          ) : (
            <div
              style={{
                fontSize: "18px",
                fontWeight: "xl",
                marginBottom: "15px",
              }}
            >
              No files to show.
            </div>
          )}
        </div>

        <Footer />
      </div>

      <div className="login_modal_layout_100">
        <BackDrop open={isModalOpen} clicked={closeModal} />
        <div
          // isOpen={isModalOpen}
          // onRequestClose={closeModal}
          className={attclasses.join(" ")}
        >
          <div className="login-modal-title-2-10x">Student LogIn</div>
          <Form className="form-login-modal-2" error>
            {isErrorShown ? (
              <Message
                error
                header="Wrong Credentials"
                content="You entered a wrong email or password."
                style={{
                  fontSize: "10px",
                  width: "80%",
                  textAlign: "center",
                }}
              />
            ) : (
              <p style={{ margin: "0px", padding: "0px" }} />
            )}

            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              name="email"
              placeholder="Email Address"
              className="form-input-login-modal-2"
              onChange={(e) => setEmail(e.target.value)}
            />

            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              name="password"
              className="form-input-login-modal-2"
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              className="button-login-modal"
              variant="danger"
              onClick={() => handleLogin()}
              disabled={loading}
            >
              Log in
            </Button>

            <p className="message-2-land-log">Forgot Password?</p>

            <Divider style={{ margin: "5px 5px" }} />

            <p className="message-2-land-log">
              Not Registered?{" "}
              <span style={{ color: "#e3241d" }}>
                {" "}
                Book A Free Trial Class
              </span>
            </p>
          </Form>
        </div>
      </div>
    </div>
  );
}

const ItemTile = ({ material }) => {
  return (
    <div style={{ width: "60%", margin: "auto", marginTop: "10px", marginBottom: "20px", height: "60px", boxShadow: "0px 0px 2px 2px rgba(0,0,0,0.3)", borderRadius: "4px", padding: "12px 0px" }}>
      <a href={material.material_url} target="_blank" rel="noreferrer">{material.material_name}</a>
      <div>{material.folder_name}</div>
    </div>
  )
}

export default Study_Redirect;
