import React, { useState } from "react";
import classes from './index.module.css';

import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form, Message } from 'semantic-ui-react';
import pic_2 from "../student/dashboard/img/pic_2.png";

import { login } from "../../crud/auth.crud";
import { setToken } from "../../utils";
import { getTeacher } from "../../redux/actions/teacher";
import { getRm } from "../../redux/actions/rm";
import { getHc} from "../../redux/actions/hc";
import { getSales } from "../../redux/actions/sales/index";
import { getAccounts } from "../../redux/actions/accounts/index";
// import { normalizeUnits } from "moment";


function EmployeeLogin({ type }) {
    const [isErrorShown, setIsErrorShown] = useState(false);
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const history = useHistory();
    const dispatch = useDispatch();

    const handleLogin = () => {
        setLoading(true);
        setIsErrorShown(false);

        if (!email || !password) {
            setLoading(false);
            setIsErrorShown(true);
        } else {
            login(email, password)
                .then(({ data: { access, refresh, email, user_type } }) => {
                    setLoading(false);
                    if (type == "teacher") {
                        dispatch(getTeacher({ access, email, user_type }, history));
                    } else if (type == "rm") {
                        dispatch(getRm({ access, email, user_type }, history));
                    } else if (type == "sales") {
                        dispatch(getSales({ access, email, user_type }, history));
                    } else if (type == "admin") {
                        console.log("Type is Admin");
                    } else if(type == "hc") {
                        dispatch(getHc({ access, email, user_type }, history));
                    } else if (type == "accounts") {
                        dispatch(getAccounts({ access, email, user_type }, history));
                    }

                    setToken(access, refresh);
                    // history.replace("/" + type + "-dashboard");
                })
                .catch(() => {
                    setLoading(false);
                    setIsErrorShown(true);
                })
        }
    }


    return (
        <div className={classes.Login_outer}>
            <div className={classes.Login_Layout}>
                <img className={classes.Logo_Margin} src={pic_2} width="208px" height="185px" alt="TheRightGuru Logo" />
                <p className={classes.Login_Page_Heading}>Login To Your Dashboard</p>
                {
                    isErrorShown ?
                        <Message
                            className={classes.Error_handler}
                            error 
                            header='Wrong Credentials'
                            content='You entered a wrong email or password.'
                            style={{ fontSize: '10px', width: '80%', textAlign: 'center' }}
                        /> :
                        <p style={{ margin: '0px', padding: '0px' }} />
                }
                <Form className={classes.Login_Form_Handler}>
                    <Form.Input
                        fluid icon='user'
                        iconPosition='left'
                        placeholder='Email Address'
                        onChange={e => setEmail(e.target.value)}
                        className={classes.Login_Form_Input}
                    />
                    <Form.Input
                        fluid icon='lock'
                        iconPosition='left'
                        placeholder='Password'
                        type='password'
                        onChange={e => setPassword(e.target.value)}
                        className={classes.Login_Form_Input}
                    />

                    <button
                        onClick={() => handleLogin()}
                        e='password'
                        onChange={e => setPassword(e.target.value)}
                        disabled={loading}
                        className={classes.Login_Butt_Sub}
                    >
                        Log In
                    </button>
                    <Link to="/forgot-password">
                        <p className={classes.Message}>Forgot Password?</p>
                    </Link>
                </Form>
            </div>
        </div>
    )
}

export default EmployeeLogin;