import React, { useState, useEffect, useLayoutEffect } from "react";
import "./style.css";
import { NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { MdNotificationsNone } from "react-icons/md";
import ellipse_2_2x from "./img/ellipse-2@2x.svg";
import vector_1_2x from "./img/vector-1@2x.svg";
import rectangle_40_2x from "./img/rectangle-40@2x.svg";
import vector_2xp from "./img/vector@2x.png";
import Popup from "../popup/popup";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteTeacher } from "../../../redux/actions/teacher";
import pic_2 from "../../student/dashboard/img/pic_2.png";

import { teacherPreviousClasses } from "../../../crud/teacher.crud";
import { upcomingClass } from "../../../crud/teacher.crud";
import SideDrawer from "../../../UI/sideDrawer/sideDrawer";
import CalenderStatus from "../dashboard/CalenderComponent/calanderComponent";

import DashboardIcon from "@material-ui/icons/Dashboard";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SchoolIcon from "@material-ui/icons/School";
import DescriptionIcon from "@material-ui/icons/Description";
import HelpIcon from "@material-ui/icons/Help";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import NotesIcon from "@material-ui/icons/Notes";

function Teacherpayments(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const user = useSelector((state) => state.teacherDetails.teacher);

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteTeacher(reduxUser.teacher_id));
    removeToken();
  };

  const [classes, setClasses] = useState([]);
  const [upClass, setUpClass] = useState({});
  useEffect(() => {
    teacherPreviousClasses(user.teacher_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setClasses(false);
        } else setClasses(res);
      })
      .catch((err) => console.log(err));
    upcomingClass(user.teacher_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setUpClass(false);
        } else setUpClass(res);
      })
      .catch((err) => console.log(err));
  }, []);

  const startClass = () => {
    window.location.replace(
      "https://class.therightguru.com/" + upClass.id + "&" + upClass.teacher_id
    );
  };

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="teacherpayments"
      />
      <div className="container-center-horizontal">
        <div className="teacher-payments-1 screen">
          <div className="sider-teacher-general-div">
            <div className="sider-teacher-in-general-80">
              <div style={{ marginTop: "120px" }} />
              <NavLink to="/teacher-dashboard" exact>
                <DashboardIcon
                  data-tip="Dashboard"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-date-calendar" exact>
                <EventIcon data-tip="Scheduler" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-previous-classes">
                <ScheduleIcon
                  data-tip="Previous Class"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-students">
                <SchoolIcon
                  data-tip="Enrolled Student"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-curriculum-list">
                <DescriptionIcon
                  data-tip="Curriculum"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-doubt-list">
                <HelpIcon data-tip="Doubts" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-analytics">
                <AssessmentIcon
                  data-tip="Analytics"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-assignments">
                <AssignmentIcon
                  data-tip="Assignments"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-quizzes">
                <LibraryBooksIcon
                  data-tip="Quizzes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-lecture-notes">
                <NotesIcon
                  data-tip="Lecture Notes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              {/* <NavLink to="/teacher-payments"><AccountBalanceIcon data-tip="Payment" className="icon-in-hc-color" /><ReactTooltip /></NavLink> */}
              <div style={{ marginTop: "10px" }} />
              <NavLink to="/" onClick={() => handleLogout()}>
                {" "}
                <PowerSettingsNewIcon
                  data-tip="Logout"
                  className="icon-in-hc-color"
                />{" "}
              </NavLink>
              <div style={{ marginTop: "1px" }} />
            </div>
          </div>
          <div className="flex-col-C61RwL">
            <div className="flex-row-0xy0vn">
              <div className="component-1-GyJUNH border-1px-blue-zodiac">
                <img
                  alt="image"
                  src={pic_2}
                  style={{ height: "115px", marginTop: "-8%" }}
                />
              </div>
              <div className="group-125-GyJUNH">
                <div className="flex-col-OfAOxu">
                  <div className="flex-row-xqwxU1">
                    <div className="flex-col-cSfBx4">
                      <div className="flex-row-Wa7qGO">
                        <div className="total-classes-hxQHcv montserrat-semi-bold-blue-zodiac-18px">
                          Total Classes -
                        </div>
                        <div className="x43-hxQHcv montserrat-medium-blue-zodiac-18px">
                          43
                        </div>
                      </div>
                      <div className="average-rating-Wa7qGO montserrat-semi-bold-blue-zodiac-18px">
                        Average Rating -
                      </div>
                    </div>
                    <div className="x43-5-cSfBx4">
                      <span className="span0-e0hoH9">4.3</span>
                      <span className="span1-e0hoH9"> / 5</span>
                    </div>
                  </div>
                  <div className="flex-row-qItZ2O">
                    <div className="attendance-302Mxf montserrat-semi-bold-blue-zodiac-18px">
                      Attendance -
                    </div>
                    <div className="x97-302Mxf">97%</div>
                  </div>
                  <div className="number-of-classes-per-day-xqwxU1 montserrat-semi-bold-blue-zodiac-18px">
                    Number of Classes Per Day -
                  </div>
                </div>
                <div className="x7-OfAOxu montserrat-medium-blue-zodiac-18px">
                  7
                </div>
              </div>
              <div className="group-115-GyJUNH">
                <div className="notifications-24px-2-1-3boKt2">
                  <div className="vector-MIOlFW">
                    <MdNotificationsNone size={28} />
                  </div>
                  {/* <img className="vector-nY7RYD" src="img/vector@2x.png" />
                <img className="vector-MIOlFW" src="img/vector-3@2x.svg" /> */}
                  <img className="ellipse-2-nY7RYD" src={ellipse_2_2x} />
                </div>
                <div className="group-22-3boKt2">
                  <div className="flex-col-R6Ddt1">
                    <div className="biba-sobti-2jrdHj">
                      {user.first_name + " " + user.last_name}
                    </div>
                    <div className="bibasobtigmailcom-2jrdHj">
                      {user.emailID}
                    </div>
                  </div>
                  <img className="vector-R6Ddt1" src={vector_1_2x} />
                </div>
              </div>
            </div>
            <div className="flex-row-oV5UrE">
              <div className="flex-col-QkTteQ">
                <div className="flex-row-Fjgz1C">
                  <div className="group-126-A6SpMB">
                    <div className="group-43-UDxQs2">
                      <div className="group-41-gGlKTJ">
                        <div className="group-40-yr0SVl">
                          <div className="group-39-gLH3zI">
                            <div className="group-38-j7ZAdN"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="upcoming-class-UDxQs2">Upcoming Class</div>
                    <div className="mathematics-section-g-UDxQs2">
                      <span className="span0-68314r">
                        {upClass && upClass.subject_id} -{" "}
                        <span style={{ color: "#F98131" }}>
                          {upClass && upClass.class_type}
                        </span>
                      </span>
                      {/* <span className="span1-68314r">&nbsp;</span
                      ><span className="span2-68314r">Section G</span> */}
                    </div>
                    <div className="friday-UDxQs2">
                      {upClass &&
                        moment(upClass.datetime).format("dddd").toUpperCase()}
                    </div>
                    <div className="x15-january-UDxQs2">
                      {upClass &&
                        moment(upClass.datetime).format("LL").toUpperCase()}
                    </div>
                    <div className="x0800-am-0900-am-UDxQs2">
                      {upClass && moment(upClass.datetime).format("HH:mm")}
                    </div>
                    <img
                      className="rectangle-40-UDxQs2"
                      src={rectangle_40_2x}
                    />
                    <div
                      className="join-class-UDxQs2"
                      onClick={() => startClass()}
                    >
                      Join Class
                    </div>
                  </div>
                  <div className="insertinvitation-24px-1-A6SpMB">
                    <img className="vector-bLtNxC" src={vector_2xp} />
                  </div>
                </div>
                <div className="overlap-group-Fjgz1C">
                  <div className="status-PXwdVf montserrat-semi-bold-gray-12px">
                    Status
                  </div>
                  <div className="rectangle-47-PXwdVf"></div>
                  <div className="completed-PXwdVf">Completed</div>
                  <div className="group-188-PXwdVf">
                    <div className="rectangle-31-Sgr7kw"></div>
                    <div className="payment-id-Sgr7kw montserrat-semi-bold-gray-12px">
                      Payment Id
                    </div>
                    <div className="amount-Sgr7kw montserrat-semi-bold-gray-12px">
                      Amount
                    </div>
                    <div className="date-Sgr7kw montserrat-semi-bold-gray-12px">
                      Date
                    </div>
                    <div className="time-Sgr7kw montserrat-semi-bold-gray-12px">
                      Time
                    </div>
                    <div className="transferred-to-Sgr7kw montserrat-semi-bold-gray-12px">
                      Transferred To
                    </div>
                    <div className="recent-payments-Sgr7kw">
                      Recent payments
                    </div>
                    <div className="rectangle-36-Sgr7kw"></div>
                    <div className="payga4wk-z-bshl-Sgr7kw montserrat-semi-bold-blue-zodiac-10px">
                      pay_GA4wkZBshl
                    </div>
                    <div className="x499900-Sgr7kw montserrat-semi-bold-blue-zodiac-10px">
                      <span className="span0-rBkabs">4999.</span>
                      <span className="span1-rBkabs">00</span>
                    </div>
                    <div className="x07-dec-2020-Sgr7kw montserrat-semi-bold-blue-zodiac-10px">
                      07 Dec 2020
                    </div>
                    <div className="x014009-pm-Sgr7kw montserrat-semi-bold-blue-zodiac-10px">
                      01:40:09 pm
                    </div>
                    <div className="andb586-Sgr7kw montserrat-semi-bold-blue-zodiac-10px">
                      ANDB*******586
                    </div>
                    <div className="rectangle-37-Sgr7kw"></div>
                    <div className="rectangle-38-Sgr7kw"></div>
                    <div className="rectangle-39-Sgr7kw"></div>
                  </div>
                </div>
              </div>
              <div className="group-114-QkTteQ">
                <CalenderStatus />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-center-horizontal">
        <div className="teacherpayments screen">
          <div className="flex-row-C61RwL">
            <div className="component-1-dXBp89 border-1px-blue-zodiac">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "115px", marginTop: "-8%" }}
              />
            </div>

            {/* This is the navbar */}
            <SideDrawer
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />
            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>
          <div className="group-125-C61RwL">
            <div className="group-124-kWlObw">
              <div className="group-41-e1mbBS">
                <div className="group-40-7vmrSC">
                  <div className="group-39-agxh6K">
                    <div className="group-38-fXlfDP"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="total-classes-kWlObw montserrat-semi-bold-blue-zodiac-20px">
              Total Classes -
            </div>
            <div className="x43-kWlObw">43</div>
            <div className="average-rating-kWlObw montserrat-semi-bold-blue-zodiac-20px">
              Average Rating -
            </div>
            <div className="x43-5-kWlObw">
              <span className="span0-KlKwew">4.3</span>
              <span className="span1-KlKwew"> / 5</span>
            </div>
            <div className="attendance-kWlObw montserrat-semi-bold-blue-zodiac-20px">
              Attendance -
            </div>
            <div className="x97-kWlObw">97%</div>
            <div className="number-of-classes-per-day-kWlObw montserrat-semi-bold-blue-zodiac-20px">
              Number of Classes Per Day -
            </div>
            <div className="x7-kWlObw">7</div>
          </div>
          <div className="group-83-C61RwL">
            <div className="group-15-jQ4qbJ">
              <div className="rectangle-30-V5d4cE"></div>
              <div className="mathematcs-section-g-V5d4cE">
                <span className="span0-Xx53BE">
                  {upClass && upClass.subject_id} -{" "}
                  <span style={{ color: "#F98131" }}>
                    {upClass && upClass.class_type}
                  </span>
                </span>
                {/* <span className="span1-Xx53BE">Section G */}
              </div>
              <div className="upcoming-class-V5d4cE montserrat-medium-blue-zodiac-18px">
                Upcoming Class
              </div>
            </div>
            <div className="friday-jQ4qbJ">
              {upClass && moment(upClass.datetime).format("dddd").toUpperCase()}
            </div>
            <div className="x15-january-jQ4qbJ">
              {upClass && moment(upClass.datetime).format("LL").toUpperCase()}
            </div>
            <div className="x0800-am-0900-am-jQ4qbJ">
              {upClass && moment(upClass.datetime).format("HH:mm")}
            </div>
            <div className="rectangle-51-jQ4qbJ border-1px-blue-zodiac"></div>
            <div className="join-class-jQ4qbJ" onClick={() => startClass()}>
              Join Class
            </div>
          </div>
          <div className="overlap-group-C61RwL">
            <div className="rectangle-31-4eduM0"></div>
            <div className="payment-id-4eduM0 montserrat-semi-bold-gray-10px">
              Payment Id
            </div>
            <div className="amount-4eduM0 montserrat-semi-bold-gray-10px">
              Amount
            </div>
            <div className="transferred-to-4eduM0 montserrat-semi-bold-gray-10px">
              Transferred To
            </div>
            <div className="date-4eduM0 montserrat-semi-bold-gray-10px">
              Date
            </div>
            <div className="time-4eduM0 montserrat-semi-bold-gray-10px">
              Time
            </div>
            <div className="recent-payments-4eduM0 montserrat-medium-blue-zodiac-18px">
              Recent payments
            </div>
            <div className="rectangle-36-4eduM0"></div>
            <div className="rectangle-37-4eduM0"></div>
            <div className="rectangle-38-4eduM0"></div>
            <div className="rectangle-39-4eduM0"></div>
            <div className="rectangle-40-4eduM0"></div>
            <div className="rectangle-41-4eduM0"></div>
            <div className="rectangle-42-4eduM0"></div>
          </div>
          <div className="group-135-C61RwL">
            <CalenderStatus />
          </div>
        </div>
      </div>
      <Popup />
    </>
  );
}

function Component1(props) {
  const { line5, line6 } = props;

  return (
    <div className="component-1 border-1px-blue-zodiac">
      <div className="overlap-group1-1">
        <img
          alt="image"
          src={pic_2}
          style={{ height: "115px", marginTop: "-8%" }}
        />
      </div>
    </div>
  );
}

function Dashboard24px1(props) {
  const { src } = props;

  return (
    <div className="insertinvitation-24px-1">
      <img className="vector-24" src={src} />
    </div>
  );
}

function Group92(props) {
  const { x4Pm, opened, className } = props;

  return (
    <div className={`group-92 ${className || ""}`}>
      <div className="overlap-group-3">
        <div className="x4pm roboto-medium-blue-zodiac-14px">{x4Pm}</div>
        <div className="opened-1 roboto-medium-niagara-14px">{opened}</div>
      </div>
    </div>
  );
}
const component1Data = {
  line5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/line-5-1@2x.png",
  line6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/line-6@2x.png",
};

const dashboard24px1Data = {
  src: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
};

const group92Data = {
  x4Pm: "4pm",
  opened: "Opened",
};

const group922Data = {
  x4Pm: "6pm",
  opened: "Opened",
};

const group923Data = {
  x4Pm: "7pm",
  opened: "Opened",
};

const group924Data = {
  x4Pm: "8pm",
  opened: "Opened",
};

const group925Data = {
  x4Pm: "9pm",
  opened: "Opened",
};

const TeacherpaymentsData = {
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-35@2x.png",
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-453@2x.png",
  vector3:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector4:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-153@2x.png",
  vector5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058d518334660f0a4896cec/img/vector-193@2x.png",
  vector7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector8:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-226@2x.png",
  vector9:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector10:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-228@2x.png",
  vector11:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector12:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-237@2x.png",
  vector13:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector14:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-230@2x.png",
  vector15:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector16:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-241@2x.png",
  vector17:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-242@2x.png",
  vector18:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector19:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-196@2x.png",
  vector20:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-192@2x.png",
  vector21:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-193@2x.png",
  vector22:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-192@2x.png",
  vector23:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-194@2x.png",
  vector24:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-33@2x.png",
  vector25:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-238@2x.png",
  vector26:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-239@2x.png",
  totalClasses: "Total Classes -",
  number: "43",
  averageRating: "Average Rating -",
  spanText: "4.3",
  spanText2: " / 5",
  attendance: "Attendance -",
  percent: "97%",
  text2: "Number of Classes Per Day -",
  number2: "7",
  vector27:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector28:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-38@2x.png",
  ellipse2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/ellipse-2@2x.png",
  title: "Biba Sobti",
  bibasobtiGmailCom: "bibasobti@gmail.com",
  vector29:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-36@2x.png",
  upcomingClass: "Upcoming Class",
  spanText3: "Mathematics -",
  spanText4: " ",
  spanText5: "Section G",
  overlapGroup1:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/rectangle-40-1@2x.png",
  joinClass: "Join Class",
  friday: "FRIDAY",
  address: "15 JANUARY",
  x0800Am0900Am: "08:00 AM - 09:00 AM",
  status: "Status",
  completed: "Completed",
  recentPayments: "Recent payments",
  paymentId: "Payment Id",
  amount: "Amount",
  place: "Date",
  time: "Time",
  transferredTo: "Transferred To",
  pay_Ga4Wkzbshl: "pay_GA4wkZBshl",
  spanText6: "4999.",
  spanText7: "00",
  date: "07 Dec 2020",
  x014009Pm: "01:40:09 pm",
  andb586: "ANDB*******586",
  openCalendar: "Open Calendar",
  date2: "Feb 18, 2021",
  time2: "Time",
  className: "Class Name",
  status2: "Status",
  addDelete: "Add/Delete",
  price: "12pm",
  organic4: "Organic-4",
  completed2: "Completed",
  booked: "Booked",
  x1Pm: "1pm",
  x2Pm: "2pm",
  opened: "Opened",
  add: "Add",
  x5Pm: "5pm",
  add2: "Add",
  x3Pm: "3pm",
  organic5: "Organic-5",
  upcoming: "Upcoming",
  booked2: "Booked",
  price2: "10pm",
  opened2: "Opened",
  component1Props: component1Data,
  dashboard24px1Props: dashboard24px1Data,
  group92Props: group92Data,
  group922Props: group922Data,
  group923Props: group923Data,
  group924Props: group924Data,
  group925Props: group925Data,
};

export default Teacherpayments;
