import React, { useState, useEffect, useLayoutEffect } from "react";
import "./style.css";
import { NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import vector_1_2x from "./img/vector-1@2x.svg";
import Popup from "../popup/popup";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteTeacher } from "../../../redux/actions/teacher";
import SideDrawer from "../../../UI/sideDrawer/sideDrawer";

import CalenderStatus from "../dashboard/CalenderComponent/calanderComponent";
import {
  teacherPreviousClasses,
  upcomingClass,
} from "../../../crud/teacher.crud";
import moment from "moment";
import pic_2 from "../../student/dashboard/img/pic_2.png";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SchoolIcon from "@material-ui/icons/School";
import DescriptionIcon from "@material-ui/icons/Description";
import HelpIcon from "@material-ui/icons/Help";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import NotesIcon from "@material-ui/icons/Notes";

function Teacherpreviousclasses(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteTeacher(reduxUser.teacher_id));
    removeToken();
  };

  const [classes, setClasses] = useState([]);
  const [upClass, setUpClass] = useState({});

  useEffect(() => {
    teacherPreviousClasses(reduxUser.teacher_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setClasses(false);
        } else setClasses(res);
      })
      .catch((err) => console.log(err));

    upcomingClass(reduxUser.teacher_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setUpClass(false);
        } else setUpClass(res);
      })
      .catch((err) => console.log(err));
  }, []);

  const user = useSelector((state) => state.teacherDetails.teacher);
  const startClass = () => {
    window.location.replace(
      "https://class.therightguru.com/" + upClass.id + "&" + upClass.teacher_id
    );
  };

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="teacherpreviousclasses"
      />
      <div className="container-center-horizontal">
        <div className="teacher-previousclasses-original screen">
          <div className="sider-teacher-general-div">
            <div className="sider-teacher-in-general-80">
              <div style={{ marginTop: "120px" }} />
              <NavLink to="/teacher-dashboard" exact>
                <DashboardIcon
                  data-tip="Dashboard"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-date-calendar" exact>
                <EventIcon data-tip="Scheduler" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-previous-classes">
                <ScheduleIcon
                  data-tip="Previous Class"
                  className="icon-in-hc-color-2"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-students">
                <SchoolIcon
                  data-tip="Enrolled Student"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-curriculum-list">
                <DescriptionIcon
                  data-tip="Curriculum"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-doubt-list">
                <HelpIcon data-tip="Doubts" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-analytics">
                <AssessmentIcon
                  data-tip="Analytics"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-assignments">
                <AssignmentIcon
                  data-tip="Assignments"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-quizzes">
                <LibraryBooksIcon
                  data-tip="Quizzes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-lecture-notes">
                <NotesIcon
                  data-tip="Lecture Notes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              {/* <NavLink to="/teacher-payments"><AccountBalanceIcon data-tip="Payment" className="icon-in-hc-color" /><ReactTooltip /></NavLink> */}
              <div style={{ marginTop: "10px" }} />
              <NavLink to="/" onClick={() => handleLogout()}>
                {" "}
                <PowerSettingsNewIcon
                  data-tip="Logout"
                  className="icon-in-hc-color"
                />{" "}
              </NavLink>
              <div style={{ marginTop: "1px" }} />
            </div>
          </div>

          <div className="flex-col-C61RwL">
            <div className="flex-row-0xy0vn">
              <div className="component-1-GyJUNH">
                <img
                  alt="image"
                  src={pic_2}
                  style={{ height: "115px", marginTop: "-8%" }}
                />
              </div>
              <div className="group-115-GyJUNH">
                <div className="group-22-3boKt2">
                  <div className="flex-col-R6Ddt1">
                    <div className="biba-sobti-2jrdHj">
                      {user.first_name + " " + user.last_name}
                    </div>
                    <div className="bibasobtigmailcom-2jrdHj">
                      {user.emailID}
                    </div>
                  </div>
                  <img className="vector-R6Ddt1" src={vector_1_2x} />
                </div>
              </div>
            </div>
            <div className="flex-row-oV5UrE">
              <div className="flex-col-QkTteQ">
                <div className="flex-row-Fjgz1C">
                  <div className="group-126-A6SpMB">
                    <div className="group-43-UDxQs2">
                      <div className="group-41-gGlKTJ">
                        <div className="group-40-yr0SVl">
                          <div className="group-39-gLH3zI">
                            <div className="group-38-j7ZAdN"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="upcoming-class-UDxQs2">Upcoming Class</div>
                    <div className="mathematics-section-g-UDxQs2">
                      <span className="span0-68314r">
                        {upClass && upClass.subject_id} -{" "}
                        <span style={{ color: "#F98131" }}>
                          {upClass && upClass.class_type}
                        </span>
                      </span>
                      {/* <span className="span1-68314r">&nbsp;</span> */}
                      {/*<span className="span2-68314r">Section G</span> */}
                    </div>
                    <div className="friday-UDxQs2">
                      {upClass &&
                        moment(upClass.datetime).format("dddd").toUpperCase()}
                    </div>
                    <div className="x15-january-UDxQs2">
                      {upClass &&
                        moment(upClass.datetime).format("LL").toUpperCase()}
                    </div>
                    <div className="x0800-am-0900-am-UDxQs2" style={{marginTop: "16px"}}>
                      {upClass && moment(upClass.datetime).format("HH:mm")}
                    </div>

                    <div
                      className="join-class-UDxQs2"
                      onClick={() => startClass()}
                    >
                      Join Class
                    </div>
                  </div>
                </div>
                <div className="overlap-group-Fjgz1C">
                  <div className="group-188-PXwdVf">
                    <div className="rectangle-31-Sgr7kw">
                      <div className="dbcajh-32-kjhak3">
                        {classes.length > 0 ? (
                          classes.map((item, index) => {
                            return (
                              <div className="rectangle-36-Sgr7kw-1001">
                                <div className="payga4wk-z-bshl-Sgr7kw montserrat-semi-bold-blue-zodiac-10px">
                                  {index + 1}
                                </div>
                                <div className="x499900-Sgr7kw montserrat-semi-bold-blue-zodiac-10px">
                                  {item.class_type}
                                </div>
                                <div className="x07-dec-2020-Sgr7kw montserrat-semi-bold-blue-zodiac-10px">
                                  {moment(item.datetime).format("DD-MM-YYYY")}
                                </div>
                                <div className="x014009-pm-Sgr7kw montserrat-semi-bold-blue-zodiac-10px">
                                  {moment(item.datetime).format("HH:mm:ss")}
                                </div>
                                <div className="andb586-Sgr7kw montserrat-semi-bold-blue-zodiac-10px">
                                  {item.status}
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div
                            style={{ fontFamily: "'Montserrat', Helvetica" }}
                          >
                            No previous classes found.
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="previousclass-id-Sgr7kw montserrat-semi-bold-gray-12px">
                      S.No.
                    </div>
                    <div className="amount-Sgr7kw-01 montserrat-semi-bold-gray-12px">
                      Class Type
                    </div>
                    <div className="date-Sgr7kw montserrat-semi-bold-gray-12px">
                      Date
                    </div>
                    <div className="time-Sgr7kw montserrat-semi-bold-gray-12px">
                      Time
                    </div>
                    <div className="transferred-to-Sgr7kw montserrat-semi-bold-gray-12px">
                      Status
                    </div>
                    <div className="recent-previousclasses-Sgr7kw">
                      Previous Classes
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-114-QkTteQ">
                <CalenderStatus />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-center-horizontal">
        <div className="teacherpreviousclasses screen">
          <div className="flex-row-C61RwL">
            <div className="component-1-dXBp89">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "90px", marginTop: "-8%" }}
              />
            </div>

            {/* this is the Navbar */}

            <SideDrawer
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />
            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>
          <div className="group-125-C61RwL">
            <div className="group-124-kWlObw">
              <div className="group-41-e1mbBS">
                <div className="group-40-7vmrSC">
                  <div className="group-39-agxh6K">
                    <div className="group-38-fXlfDP"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="total-classes-kWlObw montserrat-semi-bold-blue-zodiac-20px">Total Classes -</div>
            <div className="x43-kWlObw">43</div>
            <div className="average-rating-kWlObw montserrat-semi-bold-blue-zodiac-20px">Average Rating -</div>
            <div className="x43-5-kWlObw"><span className="span0-KlKwew">4.3</span><span className="span1-KlKwew"> / 5</span></div>
            <div className="attendance-kWlObw montserrat-semi-bold-blue-zodiac-20px">Attendance -</div>
            <div className="x97-kWlObw">97%</div>
            <div className="number-of-classes-per-day-kWlObw montserrat-semi-bold-blue-zodiac-20px">
              Number of Classes Per Day -
          </div>
            <div className="x7-kWlObw">7</div> */}
          </div>
          <div className="group-83-C61RwL">
            <div className="group-15-jQ4qbJ">
              <div className="rectangle-30-V5d4cE"></div>
              <div className="mathematcs-section-g-V5d4cE">
                <span className="span0-Xx53BE">
                  {upClass && upClass.subject_id} -{" "}
                  <span style={{ color: "#F98131" }}>
                    {upClass && upClass.class_type}
                  </span>
                </span>
                {/* <span className="span1-Xx53BE">Section G</span> */}
              </div>
              <div className="upcoming-class-V5d4cE montserrat-medium-blue-zodiac-18px">
                Upcoming Class
              </div>
            </div>
            <div className="friday-jQ4qbJ">
              {upClass && moment(upClass.datetime).format("dddd").toUpperCase()}
            </div>
            <div className="x15-january-jQ4qbJ">
              {upClass && moment(upClass.datetime).format("LL").toUpperCase()}
            </div>
            <div className="x0800-am-0900-am-jQ4qbJ">
              {upClass && moment(upClass.datetime).format("HH:mm")}
            </div>
            <div className="rectangle-51-jQ4qbJ border-1px-blue-zodiac"></div>
            <div className="join-class-jQ4qbJ" onClick={() => startClass()}>
              Join Class
            </div>
          </div>
          <div className="overlap-group-C61RwL">
            <div className="rectangle-31-4eduM0-919">
              <div className="dbcajh-32-kjhak3">
                {classes.length > 0 ? (
                  classes.map((item, index) => {
                    return (
                      <div key={item.id} className="rectangle-36-4eduM0-001-1">
                        <div className="payga4wk-z-bshl-Sgr7kw montserrat-semi-bold-blue-zodiac-10px">
                          {index + 1}
                        </div>
                        <div className="x499900-Sgr7kw montserrat-semi-bold-blue-zodiac-10px">
                          {item.class_type}
                        </div>
                        <div className="x07-dec-2020-Sgr7kw montserrat-semi-bold-blue-zodiac-10px">
                          {moment(item.datetime).format("DD-MM-YYYY")}
                        </div>
                        <div className="x014009-pm-Sgr7kw montserrat-semi-bold-blue-zodiac-10px">
                          {moment(item.datetime).format("HH:mm:ss")}
                        </div>
                        <div className="andb586-Sgr7kw montserrat-semi-bold-blue-zodiac-10px">
                          {item.status}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div style={{ fontFamily: "'Montserrat', Helvetica" }}>
                    No previous classes found.
                  </div>
                )}
              </div>
            </div>
            <div className="previousclass-id-4eduM0 montserrat-semi-bold-gray-10px">
              S.No.
            </div>
            <div className="amount-4eduM0-01 montserrat-semi-bold-gray-10px">
              Class Type
            </div>
            <div className="transferred-to-4eduM0-01 montserrat-semi-bold-gray-10px">
              Status
            </div>
            <div className="date-4eduM0-01 montserrat-semi-bold-gray-10px">
              Date
            </div>
            <div className="time-4eduM0-01 montserrat-semi-bold-gray-10px">
              Time
            </div>
            <div className="recent-previousclasses-4eduM0 montserrat-medium-blue-zodiac-18px">
              Previous Classes
            </div>
          </div>
          <div className="group-135-C61RwL">
            <CalenderStatus />
          </div>
        </div>
      </div>
      <Popup />
    </>
  );
}

export default Teacherpreviousclasses;
