import React, { useState, useLayoutEffect } from "react";
import "./index.css";
import { NavLink } from "react-router-dom";
import CalenderUserComponent from "../dashboard/CalenderComponent/CalenderUserComponent";
import LectureNotesStatus from "./NotesStatus/notesStatus";
import ReactTooltip from "react-tooltip";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteTeacher } from "../../../redux/actions/teacher";
import pic_2 from "../../student/dashboard/img/pic_2.png";

import { MenuOutlined } from "@ant-design/icons";

import SideDrawer from "../../../UI/sideDrawer/sideDrawer";

import DashboardIcon from "@material-ui/icons/Dashboard";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SchoolIcon from "@material-ui/icons/School";
import DescriptionIcon from "@material-ui/icons/Description";
import HelpIcon from "@material-ui/icons/Help";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import NotesIcon from "@material-ui/icons/Notes";
import Popup from "../popup/popup";

const Lecture_Notes = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteTeacher(reduxUser.teacher_id));
    removeToken();
  };

  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };
  return (
    <div className="Teacher_Lecture_Layout">
      <SideDrawer
        open={displaySideDrawer}
        closeSideDrawer={sideDrawerToggler}
      />

      {/* its css in UI Navbar */}
      <div className="header-wdt-100">
        <header className="Header">
          <div className="logo">
            <img
              alt="image"
              src={pic_2}
              style={{ height: "60px", marginTop: "2%" }}
            />
          </div>
          <span className="heder-men-not">
            {/* <BellOutlined className='notification' /> */}

            <MenuOutlined className="menu" onClick={sideDrawerToggler} />
          </span>
        </header>
      </div>
      {/* This is the sider of the */}
      <div className="sider-teacher-Notes">
        <div className="sider-teacher-Notes-in-wdt-80">
          <div style={{ marginTop: "120px" }} />
          <NavLink to="/teacher-dashboard" exact>
            <DashboardIcon data-tip="Dashboard" className="icon-in-hc-color" />
            <ReactTooltip />
          </NavLink>
          <NavLink to="/teacher-date-calendar" exact>
            <EventIcon data-tip="Scheduler" className="icon-in-hc-color" />
            <ReactTooltip />
          </NavLink>
          <NavLink to="/teacher-previous-classes">
            <ScheduleIcon
              data-tip="Previous Class"
              className="icon-in-hc-color"
            />
            <ReactTooltip />
          </NavLink>
          <NavLink to="/teacher-students">
            <SchoolIcon
              data-tip="Enrolled Student"
              className="icon-in-hc-color"
            />
            <ReactTooltip />
          </NavLink>
          <NavLink to="/teacher-curriculum-list">
            <DescriptionIcon
              data-tip="Curriculum"
              className="icon-in-hc-color"
            />
            <ReactTooltip />
          </NavLink>
          <NavLink to="/teacher-doubt-list">
            <HelpIcon data-tip="Doubts" className="icon-in-hc-color" />
            <ReactTooltip />
          </NavLink>
          <NavLink to="/teacher-analytics">
            <AssessmentIcon data-tip="Analytics" className="icon-in-hc-color" />
            <ReactTooltip />
          </NavLink>
          <NavLink to="/teacher-assignments">
            <AssignmentIcon
              data-tip="Assignments"
              className="icon-in-hc-color"
            />
            <ReactTooltip />
          </NavLink>
          <NavLink to="/teacher-quizzes">
            <LibraryBooksIcon data-tip="Quizzes" className="icon-in-hc-color" />
            <ReactTooltip />
          </NavLink>
          <NavLink to="/teacher-lecture-notes">
            <NotesIcon
              data-tip="Lecture Notes"
              className="icon-in-hc-color-2"
            />
            <ReactTooltip />
          </NavLink>
          {/* <NavLink to="/teacher-payments"><AccountBalanceIcon data-tip="Payment" className="icon-in-hc-color" /><ReactTooltip /></NavLink> */}
          <div style={{ marginTop: "10px" }} />
          <NavLink to="/" onClick={() => handleLogout()}>
            {" "}
            <PowerSettingsNewIcon
              data-tip="Logout"
              className="icon-in-hc-color"
            />{" "}
          </NavLink>
          <div style={{ marginTop: "1px" }} />
        </div>
      </div>
      <LectureNotesStatus />
      <CalenderUserComponent />
      <Popup />
    </div>
  );
};

export default Lecture_Notes;
