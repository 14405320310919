import React, { useState, useEffect, useLayoutEffect } from "react";
import "./style.css";
import { NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import ClassSharpIcon from '@material-ui/icons/ClassSharp';
import line_5_2x from "./img/line-5@2x.svg";
import line_6_2x from "./img/line-6@2x.svg";
import vector_1_2x from "./img/vector-1@2x.svg";
import line_5_1_2x from "./img/line-5-1@2x.svg";
import line_6_1_2x from "./img/line-6-1@2x.svg";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SchoolIcon from "@material-ui/icons/School";
import SideDrawerHc from "../../../UI/sideDrawer/sideDrawerHc";
import { HCGetTransactions } from "../../../crud/highercommittee.crud";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from '../../../utils';
import { deleteHc } from '../../../redux/actions/hc'; import Popup from '../../teacher/popup/popup';
import pic_2 from "../../student/dashboard/img/pic_2.png";

import moment from "moment";
function HcPayments(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [transactions, setTransactions] = useState({});
  const [email, setEmail] = useState("");
  //const [esid, setEnrolledStudentId] = useState("");
  const [showTable, setShowTable] = useState(false);
  const user = useSelector((state) => state.hcDetails.hc);

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(deleteHc(user.hc_id))
    removeToken();
  }
  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    HCGetTransactions(email)
      .then((res) => {
        setTransactions(res);
      })
      .catch((err) => console.log(err));
    setShowTable(true);
  };

  // useEffect(() => {
  //   HCGetTransactions("kanishklal@gmail.com")
  //     .then((res) => {
  //       if (res.hasOwnProperty("message")) {
  //         setTransactions(false);
  //       } else setTransactions(res);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  let att_class = "Display-None";
  if (showTable) {
    att_class = "with-hc-payment-100";
  }

  return (
    <>
      <input type="hidden" id="anPageName" name="page" value="hcpayments" />
      <div className="container-center-horizontal">
        <div className="hcpayments screen">
          <div className="sider-hc-teacher-list">
            <div className="sider-hc-teacher-list-in-wdt-80">
              <NavLink to="/hc-dashboard">
                <DashboardIcon
                  data-tip="Dashboard"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/hc-teachers-list">
                {" "}
                <SchoolIcon data-tip="Teacher" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/hc-suggest-rm">
                <SupervisorAccountIcon
                  data-tip="Relational Manager"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/hc-ticket-list">
                <AssignmentIcon
                  data-tip="Ticket List"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/hc-payments">
                <AccountBalanceIcon
                  data-tip="Payments"
                  className="icon-in-hc-color"
                  style={{ color: "#dc3545" }}
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/hc-classroom">
                <ClassSharpIcon
                  data-tip="Classroom"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/" onClick={() => handleLogout()}>
                <PowerSettingsNewIcon
                  data-tip="Logout"
                  className="icon-in-hc-color"
                />
              </NavLink>
            </div>
          </div>
          <div className="flex-col-C61RwL">
            <div className="flex-row-0xy0vn">
              <div className="component-1-GyJUNH">
                <img alt="image" src={pic_2} style={{ height: "90px" }} />
              </div>
              <div className="group-115-GyJUNH">
                <div className="group-22-3boKt2">
                  <div className="flex-col-R6Ddt1">
                    <div className="biba-sobti-2jrdHj">
                      {user && user.first_name} {user && user.last_name}
                    </div>
                    <div className="bibasobtigmailcom-2jrdHj">
                      {user && user.email}
                    </div>
                  </div>
                  <img className="vector-R6Ddt1" src={vector_1_2x} alt="line" />
                </div>
              </div>
            </div>
            <div className="flex-row-oV5UrE">
              <div className="overlap-group-QkTteQ-in-c-p">
                <div className="overlap-group-QkTteQ-input-in-f">
                  <input
                    type="text"
                    placeholder="Email ID"
                    style={{ width: "280px", margin: "5px" }}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button className="btn-hc-payment" onClick={handleSubmit}>
                    Filter
                  </button>
                </div>
                <div className={att_class}>
                  <div className="rectangle-31-dlP8Ul-hc-pay">
                    <div className="rectangle-31-dlP8Ul-hc-pay-in">
                      <div className="montserrat-semi-bold-gray-12px">
                        Payment Id
                      </div>
                      <div className="montserrat-semi-bold-gray-12px">
                        Student Name
                      </div>
                      <div className=" montserrat-semi-bold-gray-12px">
                        Date
                      </div>
                      <div className="montserrat-semi-bold-gray-12px">Time</div>
                      <div className="montserrat-semi-bold-gray-12px">
                        Amount
                      </div>
                    </div>
                    {/* <div className="recent-payments-dlP8Ul">Recent payments</div> */}
                    {transactions.length > 0 ? (
                      transactions.map((item, index) => {
                        return (
                          <div className="rectangle-36-dlP8Ul-10-2e">
                            <div className="payga4wk-z-bshl-Sgr7kw-98 montserrat-semi-bold-blue-zodiac-12px">
                              {item && item.student_transaction_id}
                            </div>
                            <div className="montserrat-semi-bold-blue-zodiac-12px">
                              {item && item.student_name}
                            </div>
                            <div className="montserrat-semi-bold-blue-zodiac-12px">
                              {item &&
                                moment(item.created_on).format("DD-MM-YYYY")}
                            </div>
                            <div className="montserrat-semi-bold-blue-zodiac-12px">
                              {item &&
                                moment(item.created_on).format("HH:mm:ss")}
                            </div>
                            <div className=" montserrat-semi-bold-blue-zodiac-12px">
                              {item && item.amount}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div
                        className="rectangle-36-dlP8Ul-10-2e"
                        style={{ fontFamily: "'Montserrat', Helvetica" }}
                      >
                        No transactions found for this student.
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="insertinvitation-24px-1-QkTteQ"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-center-horizontal">
        <div className="sales-mobile-payments screen">
          <div className="flex-row-C61RwL">
            <div className="component-1-dXBp89">
              <img src={pic_2} style={{ height: "100px", margin: "-8%" }} />
            </div>
            <SideDrawerHc
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />
            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>

          <div className="hc-mobile-input-filter">
            <div className="hc-mobile-input-filter-in">
              <input
                type="text"
                placeholder="Email ID"
                style={{ width: "220px", margin: "50px 10px" }}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button className="btn-hc-payment" onClick={handleSubmit}>
                Filter
              </button>
            </div>
          </div>

          <div className={att_class}>
            <div className="group-211-C61RwL">
              <div className="rectangle-31-agT0Rh">
                <div className="rectangle-31-agT0Rh-02-7">
                  <div className="montserrat-semi-bold-gray-10px">
                    Payment Id
                  </div>
                  <div className="montserrat-semi-bold-gray-10px">
                    Student Name
                  </div>
                  <div className="montserrat-semi-bold-gray-10px">Date</div>
                  <div className="montserrat-semi-bold-gray-10px">Time</div>
                  <div className="montserrat-semi-bold-gray-10px">Amount</div>
                </div>
                {transactions.length > 0 ? (
                  transactions.map((item, index) => {
                    return (
                      <div key={item.id} className="rectangle-36-agT0Rh-8-on">
                        <div className="payga4wk-z-bshl-Sgr7kw-8-66 montserrat-semi-bold-blue-zodiac-10px-8">
                          {item && item.student_transaction_id}
                        </div>
                        <div className="montserrat-semi-bold-blue-zodiac-10px-8">
                          {item && item.student_name}
                        </div>
                        <div className="montserrat-semi-bold-blue-zodiac-10px-8">
                          {item && moment(item.created_on).format("DD-MM-YYYY")}
                        </div>
                        <div className=" montserrat-semi-bold-blue-zodiac-10px-8">
                          {item && moment(item.created_on).format("HH:mm:ss")}
                        </div>
                        <div className=" montserrat-semi-bold-blue-zodiac-10px-8">
                          {item && item.amount}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="rectangle-36-agT0Rh-8-on"
                    style={{ fontFamily: "'Montserrat', Helvetica" }}
                  >
                    No transaction found for this student.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Popup /> */}
    </>
  );
}

function Component1(props) {
  const { line5, line6 } = props;

  return (

    <div className="component-1 border-1px-blue-zodiac">
      <div className="overlap-group5-1">
        <img className="line-5" src={line5} />
        <img className="line-6" src={line6} />
      </div>
    </div>


  );
}

function Dashboard24px1(props) {
  const { src } = props;

  return (
    <div className="insertinvitation-24px-1">
      <img className="vector-16" src={src} />
    </div>
  );
}
const component1Data = {
  line5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60599271a8f3ed5507ddebbe/img/line-5-4@2x.png",
  line6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/line-6@2x.png",
};

const dashboard24px1Data = {
  src: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
};

const SalesData = {
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-35@2x.png",
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1c6a4e78cf803e37e7fd/img/vector-29@2x.png",
  vector3:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1d433ce41a6c7995fc03/img/vector-9@2x.png",
  vector4:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058d518334660f0a4896cec/img/vector-193@2x.png",
  vector6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-228@2x.png",
  vector8:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector9:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-237@2x.png",
  vector10:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector11:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1d433ce41a6c7995fc03/img/vector-64@2x.png",
  vector12:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1d433ce41a6c7995fc03/img/vector-60@2x.png",
  vector13:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1d433ce41a6c7995fc03/img/vector-61@2x.png",
  vector14:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1d433ce41a6c7995fc03/img/vector-60@2x.png",
  vector15:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1d433ce41a6c7995fc03/img/vector-62@2x.png",
  vector16:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-33@2x.png",
  totalIssues: "Total Issues -",
  number: "43",
  solvedIssues: "Solved Issues -",
  number2: "40",
  percentage: "Percentage -",
  percent: "97%",
  text1: "Number of Issues Per Day -",
  number3: "7",
  vector17:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector18:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-38@2x.png",
  ellipse2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/ellipse-2@2x.png",
  title: "Biba Sobti",
  bibasobtiGmailCom: "bibasobti@gmail.com",
  vector19:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-36@2x.png",
  status: "Status",
  completed: "Completed",
  paymentId: "Payment Id",
  amount: "Amount",
  place: "Date",
  time: "Time",
  transferredTo: "Transferred To",
  recentPayments: "Recent payments",
  pay_Ga4Wkzbshl: "pay_GA4wkZBshl",
  spanText: "4999.",
  spanText2: "00",
  date: "07 Dec 2020",
  x014009Pm: "01:40:09 pm",
  andb586: "ANDB*******586",
  openIssueList: "Open Issue List",
  number4: "1",
  number5: "2",
  vatsalAgarwal: "Vatsal Agarwal",
  inProgess: "In Progess",
  shreyansJain: "Shreyans Jain",
  pending: "Pending",
  kunalKashyap: "Kunal Kashyap",
  ticket: "Ticket",
  price: "SOS",
  price2: "SOS",
  status2: "Status",
  sNo: "S.No.",
  studentName: "Student Name",
  category: "Category",
  number6: "3",
  number7: "4",
  number8: "5",
  number9: "6",
  number10: "7",
  number11: "8",
  number12: "9",
  place2: "Standard",
  vector20:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1c6a4e78cf803e37e7fd/img/vector-26@2x.png",
  messages: "Messages",
  vector21:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605a1c6a4e78cf803e37e7fd/img/vector-25@2x.png",
  component1Props: component1Data,
  dashboard24px1Props: dashboard24px1Data,
};

export default HcPayments;
