import React, { useEffect, useState, useLayoutEffect } from "react";
import "./detail_style.css";
import { Link, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Button } from "react-bootstrap";
import {
  FaRegQuestionCircle,
  FaCalendarAlt,
  FaFileAlt,
  FaChartPie,
  FaStickyNote,
  FaPowerOff,
} from "react-icons/fa";
import { MdAssignment, MdDashboard, MdNotificationsNone } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import {
  studentAssignmentDetails,
  dashboardDoubts,
  submitAssignment,
} from "../../../crud/student.crud";
import Popup from "../../teacher/popup/popup";
import vector_4_2x from "./img/vector-4@2x.png";
import vector_7_2x from "./img/vector-7@2x.png";
import vector_3_2x from "./img/vector-3@2x.png";
import vector_351_2x from "./img/vector-351@2x.png";
import vector_193_2x from "./img/vector-193@2x.png";
import line_8_1_2x from "./img/line-8-1@2x.png";
import line_8_2x from "./img/line-8@1x.png";
import line5_2x from "./img/line-5@2x.png";
import line6_2x from "./img/line-6@2x.png";
import line5_1_2x from "./img/line-5-1@2x.png";
import line6_1_2x from "./img/line-6-1@2x.png";
import ellipse_2_2x from "./img/ellipse-2@2x.png";
import rectangle_7 from "./img/rectangle-7@1x.png";
import SideDrawerStudent from "../../../UI/sideDrawer/sideDrawerStudent";
import { S3saconfig } from "../../../s3/s3saconfig.js";
import S3FileUpload from "react-s3";
import { uploadFile } from "react-s3";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteStudent } from "../../../redux/actions/student";
import pic_2 from "../dashboard/img/pic_2.png";
// import { aws} from '../../keys';

function Studentassignmentdetail(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const user = useSelector((state) => state.studentDetails.student);
  const { id } = useParams();
  const [assignment, setAssignment] = useState(null);
  const [doubts, setDoubts] = useState([]);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [assignment_url, setAssignmentUrl] = useState("");

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  useEffect(() => {
    studentAssignmentDetails(id)
      .then((res) => {
        setAssignment(res);
        console.log(assignment);
      })
      .catch((err) => console.log(err));
  }, [isSubmitted]);

  useEffect(() => {
    dashboardDoubts(user.enrolled_student_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setDoubts(false);
        } else setDoubts(res);
      })
      .catch((err) => console.log(err));
  }, []);

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteStudent(reduxUser.enrolled_student_id));
    removeToken();
  };

  function upload(e) {
    console.log(e);
    console.log(e.target.files[0]);
    S3FileUpload.uploadFile(e.target.files[0], S3saconfig)
      .then((data) => {
        setAssignmentUrl(data.location);
      })
      .catch((err) => {
        alert(err);
      });
  }
  const handleSubmit = () => {
    if (assignment.student_response.status == "Submitted") {
      submitAssignment({
        ...assignment.student_response,
        solution_url: assignment_url,
      })
        .then((res) => {
          alert("Assignment Solution Updated Successfully!");
          setIsSubmitted(!isSubmitted);
        })
        .catch((err) => console.log(err));
    } else {
      submitAssignment({
        ...assignment.student_response,
        solution_url: assignment_url,
        status: "Submitted",
      })
        .then((res) => {
          alert("Assignment Submitted Successfully!");
          setIsSubmitted(!isSubmitted);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <form
        className="student-assignment-detail screen"
        name="form3"
        action="form3"
        method="post"
      >
        <div className="student-re-flex">
          <div className="component-1-C61RwLz-3">
            <img
              alt="image"
              src={pic_2}
              style={{ height: "115px", marginTop: "-8%" }}
            />
          </div>
          <div className="group-22-GyJUNH-student-dash">
            <div className="flex-col-vgli2x-student-dash">
              <div className="biba-sobti-h8V8ZR-student-dash">
                {user.first_name + " " + user.last_name}
              </div>
              <div className="bibasobtigmailcom-h8V8ZR-student-dash">
                {user && user.email}
              </div>
            </div>
            <img className="vector-vgli2x-student-dash" src={vector_3_2x} />
          </div>
        </div>
        <img className="rectangle-7-C61RwL" src={rectangle_7} />
        {/* <div className="notifications-24px-2-1-C61RwL">
          <div className="vector-CLdsug">
            <MdNotificationsNone size={28} />
          </div>
          <img className="ellipse-2-OSIBbx" src={ellipse_2_2x} />
        </div> */}
        <div className="group-46-C61RwL">
          <div className="rectangle-40-AvtzuR"></div>
          <a
            href={assignment && assignment.assignment_group.assignment_url}
            target="_blank"
          >
            <Button
              variant="outline-danger"
              className="rectangle-41-AvtzuR"
              style={{
                fontFamily: "'Montserrat', Helvetica",
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              View Assignment
            </Button>
          </a>
          <div className="rectangle-42-AvtzuR-10">
            <label for="File-Input" style={{ cursor: "pointer" }}>
              + Add Your Work
            </label>
            <input
              id="File-Input"
              style={{ display: "none" }}
              type="file"
              onChange={(e) => upload(e)}
            />
          </div>

          <Button
            disabled={
              assignment && assignment.student_response.status == "Graded"
            }
            onClick={() => handleSubmit()}
            variant="danger"
            className="rectangle-43-AvtzuR"
            style={{
              fontFamily: "'Montserrat', Helvetica",
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            Submit
          </Button>
          <div className="component-29-AvtzuR">
            <div className="assignment-24px-3-BZ3lUO">
              <img className="vector-Q0Px28" src={vector_4_2x} />
              <img className="vector-Pdmq9p" src={vector_7_2x} />
            </div>
          </div>
          <div
            className="assignment-2-AvtzuR montserrat-semi-bold-blue-zodiac-18px"
            style={{ color: "#dc3545" }}
          >
            {assignment &&
              assignment.assignment_group.assignment_name.toUpperCase()}
          </div>
          <div
            className="your-work-AvtzuR montserrat-semi-bold-blue-zodiac-18px"
            style={{ color: "#dc3545" }}
          >
            Your work
          </div>
          <div className="x10-marks-AvtzuR" style={{ color: "#dc3545" }}>
            {assignment && assignment.student_response.marks} Marks
          </div>
          <div className="mathematics-dr-yashna-sharma-AvtzuR">
            {assignment && assignment.meta.subject_id} (
            {assignment && assignment.meta.teacher_id})
          </div>
          <div className="assigned-AvtzuR">
            {assignment && assignment.student_response.status}
          </div>
        </div>
        <a href="javascript:history.back()">
          <div className="my-assignments-C61RwL">
            <span className="span0-wyakCJ" style={{ color: "#dc3545" }}>
              My
            </span>
            <span className="span1-wyakCJ" style={{ color: "#dc3545" }}>
              {" "}
              Assignments
            </span>
          </div>
        </a>
        <div className="x14-C61RwL">14</div>
        <div className="rectangle-43-C61RwL">
          <div className="doubt-forum-C61RwL montserrat-semi-bold-blue-zodiac-18px">
            Doubt Forum
          </div>
          <Link to="/student-doubt-list">
            <div className="view-all-C61RwL">View all</div>
          </Link>
          {doubts.length > 0 ? (
            doubts.slice(0, 4).map((doubt) => {
              return (
                <div className="doubt-row">
                  <FaRegQuestionCircle size={30} />
                  <Link>
                    <div className="montserrat-semi-bold-blue-zodiac-18px">
                      {doubt.doubt_text.length > 31
                        ? doubt.doubt_text.substring(0, 27) + "..."
                        : doubt.doubt_text}
                    </div>
                  </Link>
                  {/* <div className="view-forum-C61RwL montserrat-semi-bold-gray-16px">View</div> */}
                </div>
              );
            })
          ) : (
            <div />
          )}
        </div>

        <img className="line-8-C61RwL" src={line_8_2x} />
        <a href="javascript:history.back()">
          <div className="arrowback-24px-1-C61RwL">
            <img className="vector-ec7mDZ" src={vector_4_2x} />
            <img className="vector-wEJYwt" src={vector_193_2x} />
          </div>
        </a>
        <div className="rectangle-24-C61RwL-stu-s1">
          <Link to="/student-dashboard">
            <div data-tip="Dashboard">
              <MdDashboard size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-date-calendar">
            <div data-tip="Date Calendar">
              <FaCalendarAlt size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-assignment-list">
            <div data-tip="Assignments">
              <MdAssignment size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-quiz-list">
            <div data-tip="Quizzes">
              <RiQuestionnaireFill size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-curriculum-list">
            <div data-tip="Curriculums">
              <FaFileAlt size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-doubt-list">
            <div data-tip="Doubts">
              <FaRegQuestionCircle size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-performance" data-tip="Performance">
            <div>
              <FaChartPie size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-notes-list">
            <div data-tip="Lecture Notes">
              <FaStickyNote size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/" onClick={() => handleLogout()}>
            <FaPowerOff
              size={24}
              data-tip="Logout"
              color="rgba(179,179,179,1.0)"
            />
            <ReactTooltip />
          </Link>
        </div>
      </form>
      <form
        className="student-assignment-detail-mobile screen"
        name="form5"
        action="form5"
        method="post"
      >
        <div className="component-1-C61RwL">
          {/* <img className="line-5-9p2GBv" src={line5_1_2x} />
          <img className="line-6-9p2GBv" src={line6_1_2x} /> */}
          <img
            alt="image"
            src={pic_2}
            style={{ height: "90px", marginTop: "-8%" }}
          />
        </div>

        {/*This is the Hamnurger icon */}
        <SideDrawerStudent
          open={displaySideDrawer}
          closeSideDrawer={sideDrawerToggler}
        />
        <div className="component-6-C61RwL" onClick={sideDrawerToggler}>
          <div className="rectangle-48-5mfU7d"></div>
          <div className="rectangle-49-5mfU7d"></div>
          <div className="rectangle-50-5mfU7d"></div>
        </div>

        <div className="group-85-C61RwL">
          <div className="rectangle-44-rFX9Nh">
            {doubts.length > 0 ? (
              doubts.slice(0, 4).map((doubt) => {
                return (
                  <div className="doubt-row">
                    <FaRegQuestionCircle size={30} />
                    <Link>
                      <div className="montserrat-semi-bold-blue-zodiac-18px">
                        {doubt.doubt_text.length > 31
                          ? doubt.doubt_text.substring(0, 27) + "..."
                          : doubt.doubt_text}
                      </div>
                    </Link>
                    {/* <div className="view-forum-C61RwL montserrat-semi-bold-gray-16px">View</div> */}
                  </div>
                );
              })
            ) : (
              <div />
            )}
          </div>
          <div className="doubt-forum-rFX9Nh">Doubt Forum</div>
          <Link to="/student-doubt-list">
            <div className="view-all-rFX9Nh">View all</div>
          </Link>
          {/* <div className="what-is-the-si-unit-of-current-rFX9Nh montserrat-medium-blue-zodiac-16px">
          What is the SI unit of Current?
        </div>
        <div className="how-to-solve-this-rFX9Nh montserrat-medium-blue-zodiac-16px">How to solve this?</div>
        <div className="what-is-the-sol-f-this-question-rFX9Nh montserrat-medium-blue-zodiac-16px">
          What is the solution of this question?
        </div>
        <div className="view-forum-rFX9Nh montserrat-semi-bold-gray-14px">View Forum</div>
        <div className="view-forum-CNpLuU montserrat-semi-bold-gray-14px">View Forum</div>
        <div className="view-forum-CN8bZ4 montserrat-semi-bold-gray-14px">View Forum</div>
        <div className="join-7-rFX9Nh">
          <div className="group-wQSXdG">
            <FaRegQuestionCircle size={30} />
          </div>
        </div>
        <div className="join-8-rFX9Nh">
          <div className="group-TtUkiV">
            <FaRegQuestionCircle size={30} />
          </div>
        </div>
        <div className="join-9-rFX9Nh">
          <div className="group-3eHi1a">
            <FaRegQuestionCircle size={30} />
          </div>
        </div> */}
        </div>
        <div className="my-assignments-C61RwL">
          <span className="span0-wyakCJ" style={{ color: "#dc3545" }}>
            My
          </span>
          <span className="span1-wyakCJ" style={{ color: "#dc3545" }}>
            {" "}
            Assignments
          </span>
        </div>
        <div className="rectangle-40-C61RwL"></div>
        <div className="rectangle-42-C61RwL-0-1-p">
          <label for="2-in-input">+ Add Your Work</label>
          <input
            id="2-in-input"
            style={{ display: "none" }}
            type="file"
            onChange={(e) => upload(e)}
          />
        </div>

        <Button
          disabled={
            assignment && assignment.student_response.status == "Graded"
          }
          onClick={() => handleSubmit()}
          variant="danger"
          className="rectangle-43-C61RwL-20-8-p"
          style={{
            fontFamily: "'Montserrat', Helvetica",
            fontSize: 12,
            fontWeight: 600,
          }}
        >
          Submit
        </Button>
        <div className="assignment-2-C61RwL-10" style={{ color: "#dc3545" }}>
          {assignment &&
            assignment.assignment_group.assignment_name.toUpperCase()}
        </div>
        <div
          className="your-work-C61RwL-10p montserrat-semi-bold-blue-zodiac-18px"
          style={{ color: "#dc3545" }}
        >
          Your work
        </div>
        <div className="x10-marks-C61RwL" style={{ color: "#dc3545" }}>
          {assignment && assignment.student_response.marks} Marks
        </div>
        <div className="mathematics-dr-yashna-sharma-C61RwL-10">
          {assignment && assignment.meta.subject_id} (
          {assignment && assignment.meta.teacher_id})
        </div>
        <div className="assigned-C61RwL">
          {assignment && assignment.student_response.status}
        </div>
        <div className="group-97-C61RwL-109">
          <a
            href={assignment && assignment.assignment_group.assignment_url}
            target="_blank"
          >
            <Button
              variant="outline-danger"
              className="rectangle-41-ixNXIO-10-p-c"
              style={{
                fontFamily: "'Montserrat', Helvetica",
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              View Assignment
            </Button>
          </a>
        </div>
        {/* <div className="x-add-work-C61RwL montserrat-semi-bold-blue-zodiac-10px" style={{color: "#dc3545"}}>+ Add work</div> */}
        <img className="line-8-C61RwL" src={line_8_1_2x} />
        <div className="component-29-C61RwL">
          <div className="assignment-24px-3-5mYAOI">
            <img className="vector-VxFVxy" src={vector_4_2x} />
            <img className="vector-KPoobj" src={vector_351_2x} />
          </div>
        </div>
      </form>
      <Popup />
    </>
  );
}

export default Studentassignmentdetail;
