import { GET_SALES, DELETE_SALES } from '../actions/sales/types';

const initialState = {}

export default function getSalesDetailsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SALES:
            return {
                ...state,
                sales: action.payload
            };
        case DELETE_SALES:
            return {}
        default:
            return state
    }
}