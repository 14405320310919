import React, { useState, useLayoutEffect } from 'react';
import { Link, useHistory, Redirect } from "react-router-dom";
import Modal from 'react-modal';
import { Button } from 'react-bootstrap';
import { Form, Message } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { getStudent } from "../../../redux/actions/student";
import { login } from "../../../crud/auth.crud";
import { setToken } from "../../../utils";
import { Divider } from 'antd';
import './style.css';
import LoginModal from "../../../components/Login_modal/loginModal";
import Popup2 from "../../non_auth/new_popup/popup2";
import CustomNav from "../../../components/header/Navbar";
import Footer from '../../../components/Footer';
import BackDrop from "../../../UI/Backdrop/Backdrop";

Modal.setAppElement('#root');


const Health_recreation = () => {
    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, [])

    const [reasonValue, setReasonValue] = useState(0)

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isErrorShown, setIsErrorShown] = useState(false);
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const history = useHistory();
    const dispatch = useDispatch();
    let attclasses = ["login-modal-2", "Close-Landing"];

    if (isModalOpen) {
        attclasses = ["login-modal-2", "Open-Landing"];
    }

    const closeModal = () => setIsModalOpen(previousState => !previousState);


    const handleLogin = () => {
        setLoading(true);
        setIsErrorShown(false);

        if (!email || !password) {
            setLoading(false);
            setIsErrorShown(true);
        } else {
            login(email, password)
                .then(({ data: { access, refresh, email, user_type } }) => {
                    setLoading(false);
                    dispatch(getStudent({ access, email, user_type }, history));
                    setToken(access, refresh);
                    // history.replace("/student-dashboard");
                })
                .catch(() => {
                    setLoading(false);
                    setIsErrorShown(true);
                })
        }
    }

    return (
      <div>
        <div className="landing-page-layout-100">
          <CustomNav Open={closeModal} />

          <div className="heading_box">
            <p>HEALTH, FITNESS & RECREATION</p>
          </div>

          <div className="boxes_container content-class-health">
            <p style={{ margin: "20px" }}>
              The right guru not only ensure learners’ academic growth but also
              their mental growth. We provide different and diverse activities
              for the physical and mental wellness. Mental wellness is critical
              to overall physical health. Participation in such recreational
              activities helps to manage stress and reduce anxiety and
              depressions.
              <br />
              <br />
              These activities will provide different benefits to the learners.{" "}
              <br />
              <br />
              <ol className="text-weight">
                <li>1. Encourage their social interaction.</li>
                <li>2. Improve concentration and learning.</li>
                <li>3. Increase personal confidence and self-awareness.</li>
                <li>4. Reduce depression and anxiety</li>
                <li>5. Enhance self-esteem.</li>
                <li>6. Improve quality of life.</li>
              </ol>
              <br />
              <br />
              <ol className="text-weight" style={{ color: "red" }}>
                <li>1. Painting/drawing</li>
                <li>2. Music/singing</li>
                <li>3. Dancing</li>
                <li>4. Yoga/meditation</li>
                <li>5. Sports</li>
                <li>6. Fitness training</li>
                <li>7. Zumba</li>
                <li>8. Beauty-the school of makeup</li>
                <li>9. The kitchenette- a small cooking area</li>
              </ol>
              <br />
              <br />
              Want to have word with us book a trial class and come in{" "}
              <Link to="/contact-us"> contact us.</Link>
            </p>

            {/* <div className="row">
                        <Link to="/study-material-redirect"
                            style={{ marginRight: "30px", backgroundImage: "linear-gradient(rgb(15, 23, 97), rgb(40, 35, 119))" }}
                            className="color_box col-md">
                            <p>Class Notes</p>
                            <i className="fas fa-chevron-right"></i>
                        </Link>
                        <Link to="/study-material-redirect"
                            style={{ marginRight: "30px", backgroundImage: "linear-gradient(rgb(44, 45, 65), rgb(32, 26, 51))" }}
                            className="color_box col-md">
                            <p>Practical Notes</p>
                            <i className="fas fa-chevron-right"></i>
                        </Link>
                        <Link to="/study-material-redirect"
                            style={{ backgroundImage: "linear-gradient(rgb(55, 129, 88), rgb(23, 70, 50))" }}
                            className="color_box col-md">
                            <p>Books</p>
                            <i className="fas fa-chevron-right"></i>
                        </Link>
                    </div>
                    <div className="row">
                        <Link to="/study-material-redirect"
                            style={{ marginRight: "30px", backgroundImage: "linear-gradient(rgb(134, 33, 148), rgb(173, 32, 98))" }}
                            className="color_box col-md">
                            <p>Sample Papers</p>
                            <i className="fas fa-chevron-right"></i>
                        </Link>
                        <Link to="/study-material-redirect"
                            style={{ marginRight: "30px", backgroundImage: "linear-gradient(rgb(35, 114, 151), rgb(31, 100, 94))" }}
                            className="color_box col-md">
                            <p>Previous Year <br /> Papers</p>
                            <i className="fas fa-chevron-right"></i>
                        </Link>
                        <Link to="/study-material-redirect"
                            style={{ backgroundImage: "linear-gradient(rgb(197, 114, 59), rgb(155, 145, 60))" }}
                            className="color_box col-md">
                            <p>Formulas</p>
                            <i className="fas fa-chevron-right"></i>
                        </Link>
                    </div> */}
          </div>

          <div className="content-class-future" style={{ color: "red" }}>
            <p>
              {" "}
              <strong> STUDY MATERIAL WILL BE UPLOADED SOON...</strong>
            </p>
          </div>

          <Footer />
        </div>
        <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
        <Popup2 />
        {/* <div className="login_modal_layout_100">
          <BackDrop open={isModalOpen} clicked={closeModal} />
          <div
            // isOpen={isModalOpen}
            // onRequestClose={closeModal}
            className={attclasses.join(" ")}
          >
            <div className="login-modal-title-2-10x">Student LogIn</div>
            <Form className="form-login-modal-2" error>
              {isErrorShown ? (
                <Message
                  error
                  header="Wrong Credentials"
                  content="You entered a wrong email or password."
                  style={{
                    fontSize: "10px",
                    width: "80%",
                    textAlign: "center",
                  }}
                />
              ) : (
                <p style={{ margin: "0px", padding: "0px" }} />
              )}

              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                name="email"
                placeholder="Email Address"
                className="form-input-login-modal-2"
                onChange={(e) => setEmail(e.target.value)}
              />

              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                name="password"
                className="form-input-login-modal-2"
                onChange={(e) => setPassword(e.target.value)}
              />

              <Button
                className="button-login-modal"
                variant="danger"
                onClick={() => handleLogin()}
                disabled={loading}
              >
                Log in
              </Button>

              <p className="message-2-land-log">Forgot Password?</p>

              <Divider style={{ margin: "5px 5px" }} />

              <p className="message-2-land-log">
                Not Registered?{" "}
                <span style={{ color: "#e3241d" }}>
                  {" "}
                  Book A Free Trial Class
                </span>
              </p>
            </Form>
          </div>
        </div> */}
      </div>
    );
}

export default Health_recreation;
