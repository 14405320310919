import React, { useState, useEffect, useLayoutEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import {
  FaPowerOff,
} from "react-icons/fa";
import {
  MdAssignment,
  MdDashboard,
  MdNotificationsNone,
  MdSchool,
} from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { AiTwotoneBank } from "react-icons/ai";
import line_5_1_2x from "./img/line-5-1@2x.svg";
import line_6_1_2x from "./img/line-6-1@2x.svg";
import a58341_2x from "./img/5834-1@2x.png";
import ellipse_2_2x from "./img/ellipse-2@2x.svg";
import vector_3_2x from "./img/vector-3@2x.svg";
import line_5_2x from "./img/line-5@2x.svg";
import line_6_2x from "./img/line-6@2x.svg";
import a58341_1_2x from "./img/5834-1-1@2x.png";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import SideDrawerHc from "../../../UI/sideDrawer/sideDrawerHc";
import Popup from "../../teacher/popup/popup";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import ClassSharpIcon from "@material-ui/icons/ClassSharp";
import { deleteHc } from "../../../redux/actions/hc";
import { HCNewStudentList } from "../../../crud/highercommittee.crud";
import pic_2 from "../../student/dashboard/img/pic_2.png";
import pic_3 from "../../student/dashboard/img/pic_3.png";
function HcStudentList(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const user = useSelector((state) => state.hcDetails.hc);

  const [issues, setIssues] = useState([]);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);
  const [newstudents, setNewStudents] = useState({});
  const [handleFilter, setHandleFilter] = useState("");

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(deleteHc(user.hc_id));
    removeToken();
  };

  useEffect(() => {
    HCNewStudentList()
      .then((res) => {
        setNewStudents(res);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="higher-committee-dashboard"
      />
      <div className="container-center-horizontal">
        <div className="higher-committee-dashboard screen">
          <div className="overlap-group1-C61RwL-2323">
            <div className="rectangle-1-RH0WJ5-232-3"></div>

            <div className="component-1-RH0WJ5">
              <img
                alt="image"
                src={pic_3}
                style={{ height: "115px", marginTop: "0%", marginLeft: "14%" }}
              />
            </div>
            <Link to="/hc-dashboard">
              <div className="dashboard-RH0WJ5">Dashboard</div>
            </Link>
            <Link to="/hc-suggest-rm">
              <div className="students-teachers-RH0WJ5">Relational Manager</div>
            </Link>
            <Link to="/hc-classroom">
              <div className="payments-RH0WJ5">Classroom</div>
            </Link>
            <Link to="/hc-ticket-list">
              <div className="tickets-RH0WJ5">Ticket List</div>
            </Link>
            <Link to="/hc-payments">
              <div className="analytics-RH0WJ5">Payments</div>
            </Link>
            <Link to="/" onClick={() => handleLogout()}>
              <div className="logout-RH0WJ5">Logout</div>
            </Link>

            <div className="school-24px-1-RH0WJ5">
              <SupervisorAccountIcon
                size={24}
                style={{ color: "rgba(179,179,179,1.0)" }}
              />
            </div>
            <div className="vector-RH0WJ5">
              <MdDashboard size={24} color="#dc3545" />
            </div>
            <Link to="/hc-teachers-list">
              <div className="previous-issues-RH0WJ5">Teacher</div>
            </Link>
            <div className="historytoggleoff-24px-1-RH0WJ5">
              <div className="group-YrhztS">
                <MdSchool size={24} color="rgba(179,179,179,1.0)" />
              </div>
            </div>
            <div className="vector-s3i1G8">
              <FaPowerOff size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <div className="help-24px-1-RH0WJ5">
              <MdAssignment size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <div className="analytics-24px-3-RH0WJ5">
              <AiTwotoneBank size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <div className="accountbalance-24px-1-RH0WJ5">
              <ClassSharpIcon
                size={24}
                style={{ color: "rgba(179,179,179,1.0)" }}
              />
            </div>
          </div>
          <div className="flex-col-C61RwL-872">
            <div className="flex-row-0xy0vn">
              <a href="rm-statistics.html">
                <div className="group-14-GyJUNH">
                  <div className="rectangle-30-Uqx0kx"></div>
                  <div className="welcome-back-biba-Uqx0kx">
                    <span className="span0-70fs2O">Welcome back, </span>
                    <span className="span1-70fs2O">
                      {user && user.first_name}!
                    </span>
                  </div>
                  <div className="maindashimg">
                    <img className="x5834-1-Uqx0kx" src={a58341_2x} />
                  </div>
                </div>
              </a>

              <div className="username-container">
                <div className="group-22-GyJUNH">
                  <div className="biba-sobti-vgli2x">
                    {user && user.first_name} {user && user.last_name}
                  </div>
                  <div className="bibasobtigmailcom-vgli2x">
                    {user && user.emailID}
                  </div>
                </div>
                <img className="vector-GyJUNH" src={vector_3_2x} />
              </div>
            </div>

            <div className="list-of-current-issues-0xy0vn">
              <div>
                <span className="span0-mQtxrF">List of</span>
                <span className="span1-mQtxrF"> Current Students</span>
              </div>
              <input
                type="text"
                placeholder="Filter List"
                style={{ width: "210px", marginRight: "5px" }}
                onChange={(e) => setHandleFilter(e.target.value)}
              />
            </div>
            <div className="main-container">
              <div className="overlap-group2-0xy0vn">
                <div className="rectangle-20-WxqicF-chn-p-t">
                  <div className="roboto-medium-white-20px">S. No.</div>
                  <div className="roboto-medium-white-20px">Student Name</div>
                  <div className="roboto-medium-white-20px">Sales ID</div>
                  <div className="roboto-medium-white-20px">Trial Class</div>
                  <div className="roboto-medium-white-20px">Teacher ID</div>
                </div>
                {/* </div> */}
              </div>

              <div className="overlap-group-0xy0vn">
                <div className="rectangle-30-y1HRxC-in-chn-pw">
                  <div className="fnaj-sdnj23j11">
                    {newstudents.length > 0 ? (
                      newstudents
                        .filter((res) => {
                          const trial_name =
                            res.student.first_name +
                            " " +
                            res.student.last_name;
                          if (handleFilter === "") {
                            return res;
                          } else if (
                            trial_name
                              .toLowerCase()
                              .includes(handleFilter.toLowerCase())
                          ) {
                            return res;
                          }
                        })
                        .map((item, index) => {
                          return (
                            <Link
                              to={`/hc-student-detail/${
                                item &&
                                item.student &&
                                item.student.trial_student_id
                              }/${
                                item && item.contact && item.contact.salesID
                              }`}
                            >
                              <div className="group-56-y1HRxC-10-yu-s">
                                <div className="payga4wk-z-bshl-Sgr7kw-123 montserrat-semi-bold-blue-zodiac-10px">
                                  {index + 1}
                                </div>
                                <div className="x499900-Sgr7kw-123 montserrat-semi-bold-blue-zodiac-10px">
                                  {item &&
                                    item.student &&
                                    item.student.first_name}{" "}
                                  {item &&
                                    item.student &&
                                    item.student.last_name}
                                </div>
                                <div className="x07-dec-2020-Sgr7kw-123 montserrat-semi-bold-blue-zodiac-10px">
                                  {item && item.contact && item.contact.salesID}
                                </div>
                                <div className="x014009-pm-Sgr7kw-123 montserrat-semi-bold-blue-zodiac-10px">
                                  {item &&
                                    item.contact &&
                                    item.contact.trial_class_status}
                                </div>
                                <div className="andb586-Sgr7kw-123 montserrat-semi-bold-blue-zodiac-10px">
                                  {item
                                    ? item.contact
                                      ? item.contact.teacherID
                                      : ""
                                    : ""}
                                </div>
                              </div>
                            </Link>
                          );
                        })
                    ) : (
                      <div
                        className="group-56-y1HRxC"
                        style={{ fontFamily: "'Montserrat', Helvetica" }}
                      >
                        No new student found.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-center-horizontal">
        <div className="higher-committee-dashboard-mobile screen">
          <div className="flex-row-C61RwL">
            <div className="component-1-dXBp89">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "90px", marginTop: "-8%" }}
              />
            </div>

            <SideDrawerHc
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />

            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>
          {/* <div className="overlap-group-C61RwL" style={{marginTop: "50px"}}>
            <a href="rm-statistics.html">
              <div className="group-14-4eduM0">
                <div className="rectangle-30-Yw5tgq"></div>
              </div>
            </a>
            <img className="x5834-1-4eduM0" src={a58341_1_2x} />
            <div className="welcome-back-biba-4eduM0 montserrat-medium-blue-zodiac-18px">
              <span className="span0-I9MZRq">Welcome back, </span>
              <span className="span1-I9MZRq">{user && user.first_name}!</span>
            </div>
          </div> */}
          <div
            href="relational-manager-issue-detail.html"
            className="align-self-flex-end"
          >
            <div className="group-127-C61RwL">
              <div className="list-of-current-issues-2zqKZg montserrat-medium-blue-zodiac-20px">
                List of Current Students
              </div>
              <input
                type="text"
                placeholder="Filter List"
                style={{ width: "200px", marginLeft: "10px", marginTop: "5px" }}
                onChange={(e) => setHandleFilter(e.target.value)}
              />
              <div className="overlap-group2-2zqKZg">
                {/* <div className="dashboard-24px-1-Rbw72r">
                  <img className="vector-IrPvqm" src={vector_2_2x} />
                </div>
                <div className="group-45-Rbw72r"> */}
                <div className="rectangle-20-fx556c-intext-5">
                  {/* <div className="roboto-medium-white-10px-z">S. No.</div> */}
                  <div
                    className="roboto-medium-white-10px-z"
                    style={{ marginLeft: "15px" }}
                  >
                    Student Name
                  </div>
                  <div className="roboto-medium-white-10px-z">Sales ID</div>
                  <div className="roboto-medium-white-10px-z">Trial Class</div>
                  {/* <div className="roboto-medium-white-10px-z">Teacher ID</div> */}
                </div>
              </div>
              <div className="overlap-group1-2zqKZg">
                <div className="rectangle-30-759J8x-9392">
                  <div className="fnaj-sdnj23j11">
                    {newstudents.length > 0 ? (
                      newstudents
                        .filter((res) => {
                          const trial_name =
                            res.student.first_name +
                            " " +
                            res.student.last_name;
                          if (handleFilter === "") {
                            return res;
                          } else if (
                            trial_name
                              .toLowerCase()
                              .includes(handleFilter.toLowerCase())
                          ) {
                            return res;
                          }
                        })
                        .map((item, index) => {
                          return (
                            <Link
                              to={`/hc-student-detail/${
                                item &&
                                item.student &&
                                item.student.trial_student_id
                              }/${
                                item && item.contact && item.contact.salesID
                              }`}
                            >
                              <div className="group-56-759J8xp-9392">
                                {/* <div className="payga4wk-z-bshl-Sgr7kw-123 montserrat-semi-bold-blue-zodiac-09px-w">
                                  {index + 1}
                                </div> */}
                                <div className="x499900-Sgr7kw-123 montserrat-semi-bold-blue-zodiac-09px-w">
                                  {item &&
                                    item.student &&
                                    item.student.first_name}{" "}
                                  {item &&
                                    item.student &&
                                    item.student.last_name}
                                </div>
                                <div className="x07-dec-2020-Sgr7kw-123 montserrat-semi-bold-blue-zodiac-09px-w">
                                  {item && item.contact && item.contact.salesID}
                                </div>
                                <div className="x014009-pm-Sgr7kw-123 montserrat-semi-bold-blue-zodiac-09px-w">
                                  {item &&
                                    item.contact &&
                                    item.contact.trial_class_status}
                                </div>
                                {/* <div className="andb586-Sgr7kw-123 montserrat-semi-bold-blue-zodiac-09px-w">
                                  {item
                                    ? item.contact
                                      ? item.contact.teacherID
                                      : ""
                                    : ""}
                                </div> */}
                              </div>
                            </Link>
                          );
                        })
                    ) : (
                      <div
                        className="group-56-759J8x"
                        style={{ fontFamily: "'Montserrat', Helvetica" }}
                      >
                        No new student found.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Popup /> */}
    </>
  );
}

function Group222(props) {
  const { bibaSobti, bibasobtiGmailCom } = props;

  return (
    <div className="group-22">
      <div className="biba-sobti montserrat-bold-deep-blue-24px">
        {bibaSobti}
      </div>
      <div className="bibasobtigmailcom montserrat-normal-deep-blue-20px">
        {bibasobtiGmailCom}
      </div>
    </div>
  );
}

function Dashboard24px1(props) {
  const { src, className } = props;

  return (
    <div className={`dashboard-24px-1 ${className || ""}`}>
      <img className="vector-1" src={src} />
    </div>
  );
}

function Component1(props) {
  const { line5, line6 } = props;

  return (
    <div className="component-1 border-1px-wild-sand">
      <div className="overlap-group3-1">
        <img className="line-5" src={line5} />
        <img className="line-6" src={line6} />
      </div>
    </div>
  );
}
const group222Data = {
  bibaSobti: "Biba Sobti",
  bibasobtiGmailCom: "bibasobti@gmail.com",
};

const dashboard24px1Data = {
  src: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
};

const component1Data = {
  line5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/line-5-2@2x.png",
  line6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/line-6-2@2x.png",
};

const dashboard24px12Data = {
  src: "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
};

const RelationalmanagerdashboardData = {
  rectangle7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/rectangle-7-5@1x.png",
  className: "Class Name",
  studentName: "Student Name",
  place: "Date",
  time: "Time",
  next: "Next",
  number: "1",
  number2: "2",
  number3: "3",
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-38@2x.png",
  ellipse2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/ellipse-2@2x.png",
  spanText: "Welcome back, ",
  spanText2: "Biba!",
  spanText3: "Your have ",
  spanText4: "5",
  spanText5: (
    <>
      {" "}
      pending issues.
      <br />
      Your have{" "}
    </>
  ),
  spanText6: "0",
  spanText7: " emergency issues.",
  x58341:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6057904ad14e98ed36b4236c/img/5834-1@2x.png",
  vector3:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-454@2x.png",
  vector4:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605990a43ce41a6c7995f91f/img/vector-116@2x.png",
  vector6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-659@2x.png",
  vector7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058d518334660f0a4896cec/img/vector-193@2x.png",
  vector8:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector9:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-228@2x.png",
  vector10:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector11:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-237@2x.png",
  vector12:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector13:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-166@2x.png",
  vector14:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-162@2x.png",
  vector15:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-163@2x.png",
  vector16:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-162@2x.png",
  vector17:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-164@2x.png",
  vector18:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-33@2x.png",
  dashboard: "Dashboard",
  previousIssues: "Previous Issues",
  studentsTeachers: "Students/Teachers",
  tickets: "Tickets",
  analytics: "Analytics",
  payments: "Payments",
  logout: "Logout",
  sNo: "S. No.",
  studentClassroom: "Student/Classroom",
  issueStatus: "Issue Status",
  place2: "Date",
  category: "Category",
  spanText8: "List of",
  spanText9: " Current Issues",
  vector19:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-36@2x.png",
  group222Props: group222Data,
  dashboard24px1Props: dashboard24px1Data,
  component1Props: component1Data,
  dashboard24px12Props: dashboard24px12Data,
};

export default HcStudentList;
