import React, { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import LoginModal from "../../../components/Login_modal/loginModal";
import Footer from "../../../components/Footer";
import Popup2 from "../../non_auth/new_popup/popup2";
import CustomNav from "../../../components/header/Navbar";
import "./common.css";

Modal.setAppElement("#root");

const greData = [
  {
    id: 1,
    data1: "Exam name	",
    data2: "Graduate Record Examinations",
  },
  {
    id: 2,
    data1: "Commonly known as	",
    data2: "GRE",
  },
  {
    id: 3,
    data1: "Exam level	",
    data2: "International Level Exam",
  },
  {
    id: 4,
    data1: "GRE exam conducting body	",
    data2: "Educational Testing Service (ETS)",
  },
  {
    id: 5,
    data1: "GRE 2024 exam format",
    data2: "1. GRE General Test, 2. GRE Subject Tests",
  },
  {
    id: 6,
    data1: "GRE 2024 registration mode	",
    data2: "Online through an ETS account",
  },
  {
    id: 7,
    data1: "GRE exam modes	",
    data2: "Online/ Offline",
  },
  {
    id: 8,
    data1: "GRE General test duration	",
    data2: "Online - 3 hours and 45 min, Paper-based - 3 hours and 30 min",
  },
  {
    id: 9,
    data1: "GRE subject test duration	",
    data2: "2 hours and 50 min",
  },
  {
    id: 10,
    data1: "GRE cost - general	",
    data2: "Rs. 22,550",
  },
  {
    id: 11,
    data1: "GRE fee 2024 - subject test	",
    data2: "Rs. 14,500",
  },
  {
    id: 12,
    data1: "GRE exam fee payment options",
    data2: "Credit Card/Debit Card, UPI, and Bharat QR payments",
  },
];
const gmatData = [
  {
    id: 1,
    data1: "Official Website	",
    data2: "https://www.mba.com/exams/gmat-exam",
  },
  {
    id: 2,
    data1: "Exam name	",
    data2: "Graduate Management Admission Test",
  },
  {
    id: 3,
    data1: "Commonly known as	",
    data2: "GMAT",
  },
  {
    id: 4,
    data1: "Exam Level	",
    data2: "International Level Exam",
  },
  {
    id: 5,
    data1: "Conducting Body	",
    data2: "Graduate Management Admission Council (GMAC)",
  },
  {
    id: 6,
    data1: "Language	",
    data2: "English",
  },
  {
    id: 7,
    data1: "GMAT 2024 Registration mode	",
    data2: "Online",
  },
  {
    id: 8,
    data1: "GMAT 2024 Exam Mode	",
    data2: "Online",
  },
  {
    id: 9,
    data1: "GMAT Current version test duration	",
    data2: "3 hours and 7 min",
  },
  {
    id: 10,
    data1: "GMAT 2024 focus Edition test duration	",
    data2: "2 hours 15 min",
  },
  {
    id: 11,
    data1: "GMAT cost(approx.)	",
    data2: "Rs. 21000/-",
  },
  {
    id: 12,
    data1: "GMAT Focus Edition exam fee	",
    data2: "Rs. 21000/-",
  },
  {
    id: 13,
    data1: "GMAT exam fee payment options	",
    data2: "Credit Card and Debit Card	",
  },
];
const satData = [
  {
    id: 1,
    data1: "Official Website	",
    data2: "https://www.collegeboard.org/",
  },
  {
    id: 2,
    data1: "Name of the Exam	",
    data2: "SAT (Digital)",
  },
  {
    id: 3,
    data1: "Conducting Body	",
    data2: "College Board",
  },
  {
    id: 4,
    data1: "Frequency of Conduct	",
    data2: "5 times a year",
  },
  {
    id: 5,
    data1: "Exam Level	",
    data2: "International Level Exam",
  },
  {
    id: 6,
    data1: "Languages	",
    data2: "English",
  },
  {
    id: 7,
    data1: "Mode of Application	 ",
    data2: "Online",
  },
  {
    id: 8,
    data1: "Mode of Exam",
    data2: "Online and Offline ",
  },
  {
    id: 9,
    data1: "Exam Duration 	",
    data2: "2 Hour ",
  },
  {
    id: 10,
    data1: "SAT Fee for Indian Students	",
    data2: "Rs. 8,540 (approx.)",
  },
  {
    id: 11,
    data1: "Score Range	",
    data2: "On a 1600 scale point	",
  },
  {
    id: 12,
    data1: "SAT India Country Code	",
    data2: "260",
  },
];

function GreGmatSat() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section style={{ width: "100%", marginBottom: "3%" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              width: "80%",
              marginTop: "3%",
              height: "auto",
            }}
          >
            <h1 style={{ fontSize: 24, color: "#dc3545", fontWeight: 600 }}>
              GRE, GMAT and SAT Exams
            </h1>
            <p
              style={{
                fontSize: 16,
                marginTop: "1%",
                fontWeight: 500,
                lineHeight: "1.6",
              }}
            >
              Competitive exams play a pivotal role in evaluating skills and
              aptitude for various educational programs abroad. The GRE, GMAT,
              and SAT serve distinct purposes:
            </p>
            <h2
              style={{
                fontSize: 18,
                marginTop: "1%",
                fontWeight: 600,
                lineHeight: "1.6",
              }}
            >
              Here are the list of some lesser know government exam yet, offers
              decent career option:
            </h2>
            <div style={{ lineHeight: "1.6", marginTop: "1%" }}>
              <h2 style={{ fontSize: 16, marginTop: "1%", fontWeight: 600 }}>
                <span>1. </span>Purpose of Examination
              </h2>
              <p style={{ fontSize: 16, marginLeft: "1%" }}>
                <span style={{ fontWeight: 600 }}>- </span>GRE: Admission to
                master's and doctoral programs.
              </p>
              <p style={{ fontSize: 16, marginLeft: "1%" }}>
                <span style={{ fontWeight: 600 }}>- </span>GRE: Admission to
                GMAT: Admission to graduate management programs.
              </p>
              <p style={{ fontSize: 16, marginLeft: "1%" }}>
                <span style={{ fontWeight: 600 }}>- </span>SAT: Admission to
                undergraduate programs.
              </p>
            </div>
            <div style={{ lineHeight: "1.6", marginTop: "1%" }}>
              <h2 style={{ fontSize: 16, marginTop: "1%", fontWeight: 600 }}>
                <span>2. </span>Acceptance and Scope
              </h2>
              <p style={{ fontSize: 16, marginLeft: "1%" }}>
                <span style={{ fontWeight: 600 }}>- </span>GRE: Primarily used
                in the USA and a few other countries.
              </p>
              <p style={{ fontSize: 16, marginLeft: "1%" }}>
                <span style={{ fontWeight: 600 }}>- </span>GMAT: Widely accepted
                globally.
              </p>
              <p style={{ fontSize: 16, marginLeft: "1%" }}>
                <span style={{ fontWeight: 600 }}>- </span>SAT: Mainly used by
                US undergraduate colleges.
              </p>
            </div>

            <div style={{ lineHeight: "1.6", marginTop: "1%" }}>
              <h2 style={{ fontSize: 16, marginTop: "1%", fontWeight: 600 }}>
                <span>3. </span>Aspects Tested
              </h2>
              <p style={{ fontSize: 16, marginLeft: "1%" }}>
                <span style={{ fontWeight: 600 }}>- </span>GRE: Analytical
                writing, quantitative and verbal reasoning.
              </p>
              <p style={{ fontSize: 16, marginLeft: "1%" }}>
                <span style={{ fontWeight: 600 }}>- </span>GMAT: Quantitative
                and verbal reasoning, integrated reasoning, analytical writing.
              </p>
              <p style={{ fontSize: 16, marginLeft: "1%" }}>
                <span style={{ fontWeight: 600 }}>- </span>SAT: Writing,
                critical reading, mathematics. US undergraduate colleges.
              </p>
            </div>

            <div style={{ lineHeight: "1.6", marginTop: "1%" }}>
              <h2 style={{ fontSize: 16, marginTop: "1%", fontWeight: 600 }}>
                <span>4. </span>Test Formats and Durations
              </h2>
              <p style={{ fontSize: 16, marginLeft: "1%" }}>
                <span style={{ fontWeight: 600 }}>- </span>GRE: Computer-based
                or paper-based, approximately 3 hours and 45 minutes.
              </p>
              <p style={{ fontSize: 16, marginLeft: "1%" }}>
                <span style={{ fontWeight: 600 }}>- </span>GMAT: Completely
                computer-based, around 3 hours.
              </p>
              <p style={{ fontSize: 16, marginLeft: "1%" }}>
                <span style={{ fontWeight: 600 }}>- </span>SAT: Solely
                paper-based, 3 hours or 3 hours 50 minutes with essay.
              </p>
            </div>

            <div style={{ lineHeight: "1.6", marginTop: "1%" }}>
              <h2 style={{ fontSize: 16, marginTop: "1%", fontWeight: 600 }}>
                <span>5. </span>Scoring Criteria
              </h2>
              <p style={{ fontSize: 16, marginLeft: "1%" }}>
                <span style={{ fontWeight: 600 }}>- </span>GRE: Ranges from 260
                to 340, with varying section scores.
              </p>
              <p style={{ fontSize: 16, marginLeft: "1%" }}>
                <span style={{ fontWeight: 600 }}>- </span>GMAT: Ranges from 200
                to 800, with separate section scores.
              </p>
              <p style={{ fontSize: 16, marginLeft: "1%" }}>
                <span style={{ fontWeight: 600 }}>- </span>SAT: Ranges from 400
                to 1600, with individual section scores.
              </p>
            </div>

            <div style={{ lineHeight: "1.6", marginTop: "1%" }}>
              <h2 style={{ fontSize: 16, marginTop: "1%", fontWeight: 600 }}>
                <span>6. </span>Eligibility and Validity
              </h2>
              <p style={{ fontSize: 16, marginLeft: "1%" }}>
                <span style={{ fontWeight: 600 }}>- </span>GRE, GMAT, and SAT
                are valid for 5 years.
              </p>
              <p style={{ fontSize: 16, marginLeft: "1%" }}>
                <span style={{ fontWeight: 600 }}>- </span>Requirements vary
                from bachelor's degree graduates to high school students.
              </p>
            </div>

            <div style={{ marginTop: "3%" }}>
              <h1 style={{ fontSize: 20, color: "#dc3545", fontWeight: 600 }}>
                GRE Exam
              </h1>
              <div
                style={{
                  marginBottom: "2%",
                  marginTop: "2%",
                  overflowX: "auto",
                }}
              >
                <table style={{ width: "100%", margin: "0 auto" }}>
                  <tr>
                    <th style={{ textAlign: "center" }} className="heade">
                      Particulars
                    </th>
                    <th style={{ textAlign: "center" }} className="heade">
                      Details
                    </th>
                  </tr>
                  {greData.map((data) => {
                    return (
                      <tr>
                        <td style={{ textAlign: "center" }}>{data.data1}</td>
                        <td style={{ textAlign: "center" }}>{data.data2}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>

            <div style={{ marginTop: "3%" }}>
              <h1 style={{ fontSize: 20, color: "#dc3545", fontWeight: 600 }}>
                GMAT Exam
              </h1>
              <div
                style={{
                  marginBottom: "2%",
                  marginTop: "2%",
                  overflowX: "auto",
                }}
              >
                <table style={{ width: "100%", margin: "0 auto" }}>
                  <tr>
                    <th style={{ textAlign: "center" }} className="heade">
                      Particulars
                    </th>
                    <th style={{ textAlign: "center" }} className="heade">
                      Details
                    </th>
                  </tr>
                  {gmatData.map((data) => {
                    return (
                      <tr>
                        <td style={{ textAlign: "center" }}>{data.data1}</td>
                        <td style={{ textAlign: "center" }}>{data.data2}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>

            <div style={{ marginTop: "3%" }}>
              <h1 style={{ fontSize: 20, color: "#dc3545", fontWeight: 600 }}>
                SAT Exam
              </h1>
              <div
                style={{
                  marginBottom: "2%",
                  marginTop: "2%",
                  overflowX: "auto",
                }}
              >
                <table style={{ width: "100%", margin: "0 auto" }}>
                  <tr>
                    <th style={{ textAlign: "center" }} className="heade">
                      Particulars
                    </th>
                    <th style={{ textAlign: "center" }} className="heade">
                      Details
                    </th>
                  </tr>
                  {satData.map((data) => {
                    return (
                      <tr>
                        <td style={{ textAlign: "center" }}>{data.data1}</td>
                        <td style={{ textAlign: "center" }}>{data.data2}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
            <h2 style={{ fontSize: 18, fontWeight: 600, marginTop: "2%" }}>
              Achieve Your Goals with THE RIGHT GURU Tuition Centre
            </h2>
            <p style={{ fontSize: 16, marginTop: "1%" }}>
              Join us on a journey of growth, learning, and achievement. Whether
              you're aiming for top grades, entrance exams, or a bright future,
              we are here to provide the guidance and support you need to
              succeed.
            </p>
            <h2 style={{ fontSize: 16, marginTop: "1%" }}>
              For any query, Email us at:{" "}
              <span>
                <a href="mailto:contact@therightguru.com">
                  contact@therightguru.com
                </a>
              </span>
            </h2>
            <p style={{ fontSize: 16, marginTop: "1%", lineHeight: "1.6" }}>
              For a more personalized and detailed understanding, we encourage
              you to visit our centers and consult our dedicated counselors.
            </p>
          </div>
        </div>
      </section>

      <section>
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default GreGmatSat;
