import React, { useState, useEffect, useLayoutEffect } from "react";
import "./list_style.css";
import { Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {
  FaRegQuestionCircle,
  FaCalendarAlt,
  FaFileAlt,
  FaChartPie,
  FaStickyNote,
  FaPowerOff,
} from "react-icons/fa";
import { MdAssignment, MdDashboard, MdNotificationsNone } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import pic_2 from "../dashboard/img/pic_2.png";
import {
  studentAssignments,
  dashboardDoubts,
} from "../../../crud/student.crud";
import Popup from "../../teacher/popup/popup";
import SideDrawerStudent from "../../../UI/sideDrawer/sideDrawerStudent";

import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteStudent } from "../../../redux/actions/student";

// images
import vector_3_2x from "./img/vector-3@2x.png";
import vector_4_2x from "./img/vector-4@2x.png";

import vector_359_2x from "./img/vector-359@2x.png";
import line5_2x from "./img/line-5@2x.png";
import line6_2x from "./img/line-6@2x.png";
import line5_1_2x from "./img/line-5-1@2x.png";
import line6_1_2x from "./img/line-6-1@2x.png";
import rectangle_7 from "./img/rectangle-7@1x.png";
import ellipse_2_2x from "./img/ellipse-2@2x.png";

function Studentassignments(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const user = useSelector((state) => state.studentDetails.student);
  const [assignments, setAssignments] = useState([]);
  const [doubts, setDoubts] = useState([]);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  useEffect(() => {
    studentAssignments(user.enrolled_student_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setAssignments(false);
        } else setAssignments(res);
      })
      .catch((err) => console.log(err));
  }, []);

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteStudent(reduxUser.enrolled_student_id));
    removeToken();
  };

  useEffect(() => {
    dashboardDoubts(user.enrolled_student_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setDoubts(false);
        } else setDoubts(res);
      })
      .catch((err) => console.log(err));
  }, []);

  const subjectOptions = [
    {
      key: "_1",
      text: "English",
      value: "English",
    },
    {
      key: "_2",
      text: "Hindi",
      value: "Hindi",
    },
    {
      key: "_3",
      text: "Science",
      value: "Science",
    },
    {
      key: "_4",
      text: "Maths",
      value: "Maths",
    },
    {
      key: "_5",
      text: "Social Studies",
      value: "Social Studies",
    },
    {
      key: "_6",
      text: "English",
      value: "English",
    },
    {
      key: "_7",
      text: "Hindi",
      value: "Hindi",
    },
    {
      key: "_8",
      text: "Science",
      value: "Science",
    },
    {
      key: "_9",
      text: "Maths",
      value: "Maths",
    },
    {
      key: "_10",
      text: "Social Studies",
      value: "Social Studies",
    },
  ];

  const stateOptions = [
    {
      key: "_1",
      text: "English",
      value: "English",
    },
    {
      key: "_2",
      text: "Hindi",
      value: "Hindi",
    },
    {
      key: "_3",
      text: "Science",
      value: "Science",
    },
    {
      key: "_4",
      text: "Maths",
      value: "Maths",
    },
    {
      key: "_5",
      text: "Social Studies",
      value: "Social Studies",
    },
    {
      key: "_6",
      text: "English",
      value: "English",
    },
    {
      key: "_7",
      text: "Hindi",
      value: "Hindi",
    },
    {
      key: "_8",
      text: "Science",
      value: "Science",
    },
    {
      key: "_9",
      text: "Maths",
      value: "Maths",
    },
    {
      key: "_10",
      text: "Social Studies",
      value: "Social Studies",
    },
  ];
  return (
    <>
      <form
        className="student-assignments screen"
        name="form1"
        action="form1"
        method="post"
      >
        <div className="student-re-flex">
          <div className="component-1-C61RwLz-3">
            <img
              alt="image"
              src={pic_2}
              style={{ height: "115px", marginTop: "-8%" }}
            />
          </div>
          <div className="group-22-GyJUNH-student-dash">
            <div className="flex-col-vgli2x-student-dash">
              <div className="biba-sobti-h8V8ZR-student-dash">
                {user.first_name + " " + user.last_name}
              </div>
              <div className="bibasobtigmailcom-h8V8ZR-student-dash">
                {user && user.email}
              </div>
            </div>
            <img className="vector-vgli2x-student-dash" src={vector_3_2x} />
          </div>
        </div>
        <img className="rectangle-7-C61RwL" src={rectangle_7} />
        {/* <div className="notifications-24px-2-1-C61RwL">
          <div className="vector-CLdsug">
            <MdNotificationsNone size={28} />
          </div>
          <img className="ellipse-2-OSIBbx" src={ellipse_2_2x} />
        </div> */}
        <div className="group-46-C61RwL-001-2">
          {assignments.length > 0 ? (
            assignments.map((assgn) => {
              return (
                <Link
                  to={`/student-assignment-detail/${assgn.student_response.id}`}
                >
                  <div className="rectangle-40-AvtzuR">
                    <div className="component-29-AvtzuR">
                      <div className="assignment-24px-3-BZ3lUO">
                        <div className="vector-Pdmq9p">
                          <MdAssignment color="white" />
                        </div>
                      </div>
                    </div>
                    <div className="assgnTile-col">
                      <div className="montserrat-semi-bold-blue-zodiac-14px">
                        {assgn.assignment_data.assignment_name.toUpperCase()}
                      </div>
                      <div className="montserrat-medium-gray-10px">
                        {assgn.meta.subject_id} ({assgn.meta.teacher_id})
                      </div>
                    </div>
                    <div className="submitted-AvtzuR">
                      {assgn.student_response.status}
                    </div>
                  </div>
                </Link>
              );
            })
          ) : (
            <div className="montserrat-semi-bold-blue-zodiac-18px">
              You have no assignments.
            </div>
          )}
        </div>
        <div className="my-assignments-C61RwL">
          <span className="span0-wyakCJ">My</span>
          <span className="span1-wyakCJ"> Assignments</span>
        </div>
        <div className="x14-C61RwL">14</div>
        <div className="rectangle-43-C61RwL">
          <div className="doubt-forum-C61RwL montserrat-semi-bold-blue-zodiac-18px">
            Doubt Forum
          </div>
          <Link to="/student-doubt-list">
            <div className="view-all-C61RwL">View all</div>
          </Link>

          {doubts.length > 0 ? (
            doubts.slice(0, 4).map((doubt) => {
              return (
                <div className="doubt-row">
                  <FaRegQuestionCircle size={30} />
                  <Link>
                    <div
                      className="montserrat-semi-bold-blue-zodiac-18px"
                      style={{ color: "rgba(126,126,126,1.0)" }}
                    >
                      {doubt.doubt_text.length > 31
                        ? doubt.doubt_text.substring(0, 27) + "..."
                        : doubt.doubt_text}
                    </div>
                  </Link>
                  {/* <div className="view-forum-C61RwL montserrat-semi-bold-gray-16px">View</div> */}
                </div>
              );
            })
          ) : (
            <div />
          )}
        </div>

        {/* <div className="component-30-C61RwL-div">
          <Dropdown
            placeholder="Choose Subject"
            fluid
            selection
            options={subjectOptions}
          />
        </div>

        <div className="component-31-C61RwL">
          <Dropdown
            placeholder="Choose Status"
            fluid
            selection
            options={stateOptions}
          />
        </div> */}

        {/* <a href="javascript:SubmitForm('form1')">
          <div className="rectangle-41-C61RwL-ex18">
            <div className="apply-filters-C61RwL">Apply Filters</div>
          </div>
        </a> */}
        {/* <div className="apply-filters-C61RwL">Apply filters</div> */}
        <div className="rectangle-24-C61RwL-stu-s1">
          <Link to="/student-dashboard">
            <div data-tip="Dashboard">
              <MdDashboard size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-date-calendar">
            <div data-tip="Date Calendar">
              <FaCalendarAlt size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-assignment-list">
            <div data-tip="Assignments">
              <MdAssignment size={24} color="#dc3545" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-quiz-list">
            <div data-tip="Quizzes">
              <RiQuestionnaireFill size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-curriculum-list">
            <div data-tip="Curriculums">
              <FaFileAlt size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-doubt-list">
            <div data-tip="Doubts">
              <FaRegQuestionCircle size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/student-performance" data-tip="Performance">
            <div>
              <FaChartPie size={24} color="rgba(179,179,179,1.0)" />
            </div>{" "}
            <ReactTooltip />
          </Link>

          <Link to="/student-notes-list">
            <div data-tip="Lecture Notes">
              <FaStickyNote size={24} color="rgba(179,179,179,1.0)" />
            </div>
            <ReactTooltip />
          </Link>

          <Link to="/" onClick={() => handleLogout()}>
            <FaPowerOff
              size={24}
              data-tip="Logout"
              color="rgba(179,179,179,1.0)"
            />
            <ReactTooltip />
          </Link>
        </div>
      </form>
      <form
        className="student-assignment-list-mobile screen"
        name="form2"
        action="form2"
        method="post"
      >
        <div className="component-1-C61RwL">
          {/* <img className="line-5-9p2GBv" src={line5_1_2x} />
          <img className="line-6-9p2GBv" src={line6_1_2x} /> */}
          <img
            alt="image"
            src={pic_2}
            style={{ height: "90px", marginTop: "-8%" }}
          />
        </div>

        {/* This is the hamburger icon */}
        <SideDrawerStudent
          open={displaySideDrawer}
          closeSideDrawer={sideDrawerToggler}
        />

        <div className="component-6-C61RwL" onClick={sideDrawerToggler}>
          <div className="rectangle-48-5mfU7d"></div>
          <div className="rectangle-49-5mfU7d"></div>
          <div className="rectangle-50-5mfU7d"></div>
        </div>

        <div className="group-85-C61RwL">
          <div className="rectangle-44-rFX9Nh">
            {doubts.length > 0 ? (
              doubts.slice(0, 4).map((doubt) => {
                return (
                  <div className="doubt-row">
                    <FaRegQuestionCircle size={30} />
                    <Link>
                      <div className="montserrat-semi-bold-blue-zodiac-18px">
                        {doubt.doubt_text.length > 31
                          ? doubt.doubt_text.substring(0, 27) + "..."
                          : doubt.doubt_text}
                      </div>
                    </Link>
                    {/* <div className="view-forum-C61RwL montserrat-semi-bold-gray-16px">View</div> */}
                  </div>
                );
              })
            ) : (
              <div />
            )}
          </div>
          <div className="doubt-forum-rFX9Nh">Doubt Forum</div>
          <Link to="/student-doubt-list">
            <div className="view-all-rFX9Nh">View all</div>
          </Link>
          {/* <div className="what-is-the-si-unit-of-current-rFX9Nh montserrat-medium-blue-zodiac-16px">
          What is the SI unit of Current?
        </div>
        <div className="how-to-solve-this-rFX9Nh montserrat-medium-blue-zodiac-16px">How to solve this?</div>
        <div className="what-is-the-sol-f-this-question-rFX9Nh montserrat-medium-blue-zodiac-16px">
          What is the solution of this question?
        </div>
        <div className="view-forum-rFX9Nh montserrat-semi-bold-gray-14px">View Forum</div>
        <div className="view-forum-CNpLuU montserrat-semi-bold-gray-14px">View Forum</div>
        <div className="view-forum-CN8bZ4 montserrat-semi-bold-gray-14px">View Forum</div> */}
          {/* <div className="join-7-rFX9Nh">
          <div className="group-wQSXdG">
            <FaRegQuestionCircle size={30} />
          </div>
        </div>
        <div className="join-8-rFX9Nh">
          <div className="group-TtUkiV">
            <FaRegQuestionCircle size={30} />
          </div>
        </div>
        <div className="join-9-rFX9Nh">
          <div className="group-3eHi1a">
            <FaRegQuestionCircle size={30} />
          </div>
        </div> */}
        </div>
        {/* <div className="component-30-C61RwL-mob-view">
          <Dropdown
            placeholder="Choose Subject"
            fluid
            selection
            options={subjectOptions}
          />
        </div>

        <div className="component-31-C61RwL-mob-view">
          <Dropdown
            placeholder="Choose Status"
            fluid
            selection
            options={stateOptions}
          />
        </div>
        <a href="javascript:SubmitForm('form2')">
          <div className="group-95-C61RwL">
            <div className="group-94-IUHrKR">
              <div className="rectangle-41-g6la27-12">
                <div className="apply-filters-g6la27-10">Apply filters</div>
              </div>
            </div>
          </div>
        </a> */}
        <div className="my-assignments-C61RwL">
          <span className="span0-wyakCJ">My</span>
          <span className="span1-wyakCJ"> Assignments</span>
        </div>
        <div className="group-96-C61RwL-0901">
          {/* <div className="rectangle-40-3U5qE3"></div>
          <div className="assignment-1-3U5qE3 montserrat-semi-bold-blue-zodiac-14px">ASSIGNMENT 1</div>
          <div className="chemistry-ms-harpreet-kaur-3U5qE3 montserrat-medium-gray-10px">Chemistry (Ms. Harpreet Kaur)</div>
          <div className="assigned-3U5qE3 montserrat-semi-bold-crusta-12px">Assigned</div>
           */}
          {assignments.length > 0 ? (
            assignments.map((assgn) => {
              return (
                <Link
                  to={`/student-assignment-detail/${assgn.student_response.id}`}
                >
                  <div className="rectangle-40-3U5qE3">
                    <div className="component-29-AvtzuR">
                      <div className="assignment-24px-3-BZ3lUO">
                        <div className="vector-Pdmq9p">
                          <MdAssignment color="white" />
                        </div>
                      </div>
                    </div>
                    <div className="assgnTile-col">
                      <div className="montserrat-semi-bold-blue-zodiac-14px">
                        {assgn.assignment_data.assignment_name.toUpperCase()}
                      </div>
                      <div className="montserrat-medium-gray-10px">
                        {assgn.meta.subject_id} ({assgn.meta.teacher_id})
                      </div>
                    </div>
                    <div className="submitted-AvtzuR">
                      {assgn.student_response.status}
                    </div>
                  </div>
                </Link>
              );
            })
          ) : (
            <div className="montserrat-semi-bold-blue-zodiac-18px">
              You have no assignments.
            </div>
          )}
        </div>
      </form>
      <Popup />
    </>
  );
}

export default Studentassignments;
