import React from "react";
import Slider from "react-slick";

import "./studentReviews.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// images
import img1 from "./student_reviews/AnandKumar.png";
import img2 from "./student_reviews/Ankitsingh.png";
import img3 from "./student_reviews/GovindaRau.png";
import img4 from "./student_reviews/KanakVerma.png";
import img5 from "./student_reviews/PriyanshuSharma.png";
import img6 from "./student_reviews/PuneetSharma.png";
import img7 from "./student_reviews/RaviKuma.png";
import img8 from "./student_reviews/ShubhamSharma.png";
import img9 from "./student_reviews/SwatiSharma.png";
import img10 from "./student_reviews/RaviMishra.png";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        backgroundColor: "#dc3545",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
}

const StudentReviewsData = [
  {
    id: "1",
    img: img1,
  },
  {
    id: "2",
    img: img2,
  },
  {
    id: "3",
    img: img3,
  },
  {
    id: "4",
    img: img4,
  },
  {
    id: "5",
    img: img5,
  },
  {
    id: "6",
    img: img6,
  },
  {
    id: "7",
    img: img7,
  },
  {
    id: "8",
    img: img8,
  },
  {
    id: "9",
    img: img9,
  },
  {
    id: "10",
    img: img10,
  },
];

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        backgroundColor: "#dc3545",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
}

const StudentReviews = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="slid-contt" style={{ padding: "2%", width: "100%" }}>
        <Slider {...settings}>
          {StudentReviewsData.map((data) => (
            <div className="image-card" key={data.id}>
              <img className="image" src={data.img} alt="Student Reviews" />
              {/* <div className="cards">
                <div
                  style={{
                    fontSize: 25,
                    fontWeight: 700,
                    color: "#dc3545",
                    marginTop: "50%",
                    marginBottom: 15,
                  }}
                >
                 Name: 
                </div>
                <div
                  style={{ fontSize: 20, fontWeight: 600, color: "#dc3545" }}
                >
                 Subject: 
                </div>
              </div> */}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default StudentReviews;
